import { URL } from "../utils/constants/constants";

export async function getChatHistory({ influencerId, companyId, limit }) {

    try {
        const response = await fetch(`${URL}/chat/api/messages?limit=${limit}&planner_id=${companyId}&vendor_id=${influencerId}`);
        const data = await response.json();
        return data;
    }
    catch (e) {
        throw e;
    }
}