import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage } from 'react-intl';

import { DeleteAccountContainer } from "./DeleteAccountStyled";

import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import { UserContext2 } from "../../context/user/userContext";
import { UserAPI } from "../../api/user/user";

export default function DeleteAccount() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { setUserToken, setUserType, setUserData, setShowAlertDeleteAccount } = useContext(UserContext2);
    const [showAlertError, setShowAlertError] = useState(false);

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let userToken = urlParams.get('env');


    useEffect(() => {
        const deletionCode = urlParams.get('code');
        const userEmail = urlParams.get('email');

        if(deletionCode != null && userEmail != null) {
            UserAPI.deleteUser(deletionCode, userEmail).then((response) => {
                setIsLoading(false);
                setShowAlertDeleteAccount(true);    
                navigate('/');
            }).catch(() => {
                setIsLoading(false);
                setShowAlertError(true)
            });
        } else {
            setIsLoading(false);
            setShowAlertError(true)
        }
    }, [])

    return (
        <DeleteAccountContainer>
            {/* <p>Delete account</p> */}
            {(isLoading) && (<Spinner />)}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="delete_error" defaultMessage="Usuario no existe" />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="footer_links_1_text_1" defaultMessage="Home" />}
                onConfirm={() => { navigate('/'); }}
            />)}
        </DeleteAccountContainer>
    )
}