import React, { useEffect, useState, useContext } from "react";
import { TermsAndConditionsContainer } from "./TermsAndConditionsStyled";

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import back from './../../utils/images/arrow_back.svg';
import { Markup } from 'interweave';

import { LangContext } from "../../context/langContext";

import Spinner from './../../components/Spinner/Spinner';
import { StaticDataContext } from "../../context/staticDataContext";

export default function TermsAndConditions() {

    const navigate = useNavigate();

    const { legalTextList } = useContext(StaticDataContext);

    const [englishText, setEnglishText] = useState('');
    const [spanishText, setSpanishText] = useState('');

    const { locale } = useContext(LangContext);

    var URLactual = window.location.pathname.split('/');

    useEffect(() => {
        const legalText = legalTextList.filter((legal) => legal.path === URLactual.slice(-1)[0]);

        legalText.map((legal) => {
            if (legal.language === "SPANISH") {
                setSpanishText(legal.body);
            } else {
                setEnglishText(legal.body);
            }
        })
    }, [URLactual]);

    return (
        <TermsAndConditionsContainer>

            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={back} alt="go back" className="backIcon" />
                <p>
                    <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                </p>
            </div>

            <div className="termsContainer">
                <div className="privacyBody">
                    {(locale === 'es-CO') ? (<Markup content={spanishText} />) : (<Markup content={englishText} />)}
                </div>
            </div>

        </TermsAndConditionsContainer>
    )
}