const saveToken = (payload) => {
    return {
        type: "SAVE_TOKEN_STORE",
        payload
    }
}

const deleteToken = () => {
    return {
        type: "DELETE_TOKEN_STORE",
    }
}

export { saveToken, deleteToken };