import React, { useState, useEffect, useContext } from "react";
import Lottie from 'react-lottie';
import { useNavigate, useSearchParams } from "react-router-dom";
import * as animationData from './paypal-logo-effect.json';
import { PaypalContainer } from "./PaypalStyled";
import Alert from "../../components/Alert/Alert";
import { FormattedMessage } from 'react-intl';
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";

export default function Paypal() {

    const navigate = useNavigate();

    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const { userType, userToken, userData, setUserData } = useContext(UserContext2);


    let [searchParams] = useSearchParams();
    const [showAlertError, setShowAlertError] = useState(false);

    useEffect(() => {

        const origin = sessionStorage.getItem('paypalLoginOrigin');

        if (searchParams.get('success') === 'S' && userToken) {
            if (userType === UserTypes.INFLUENCER) {
                setUserData({ ...userData, payment_method: true });
                sessionStorage.removeItem('paypalLoginOrigin')
                if(origin.includes('mycollaborations')){
                    navigate(`/${origin}`);
                } else {
                    switch(origin){
                        case 'account':
                            navigate('/myaccount')
                        default:
                            navigate('/');
                    }
                }
            } else if (userType === UserTypes.COMPANY) {
                setUserData({ ...userData, payment_method: true });
                if (origin === 'cart') {
                    sessionStorage.removeItem('paypalLoginOrigin')
                    navigate('/mycart')
                } else {
                    sessionStorage.removeItem('paypalLoginOrigin')
                    navigate('/myaccount')
                }
            }
        }

        if (searchParams.get('success') === 'N' && userToken !== null) {
            setShowAlertError(true);
        }

    }, [searchParams, userData])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <PaypalContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_forward_title_2" defaultMessage="¡Error!" />}
                text={<FormattedMessage id="alert_error_paypal" defaultMessage="Ha ocurrido un  error, no pudimos conectar tu cuenta de paypal. Por favor intenta mas tarde." />}
                confirmText={<FormattedMessage id="alert_send_product_button" defaultMessage="¡Entendido!" />}
                onConfirm={() => {
                    if (origin === 'cart') {
                        sessionStorage.removeItem('paypalLoginOrigin')
                        navigate('/mycart')
                    } else {
                        sessionStorage.removeItem('paypalLoginOrigin')
                        navigate('/myaccount')
                    }
                }}
                setShowAlert={setShowAlertError} />)}
        </PaypalContainer>

    )
}