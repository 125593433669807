import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { LikeProductContainer } from "./LikeProductStyled";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { LikeAPI } from "../../api/user/like/like";

export default function LikeProduct({ product, companyId }) {

    const navigate = useNavigate();

    const [isFavorite, setIsFavorite] = useState(false);

    const { userData, setUserData, userToken, userType } = useContext(UserContext2);

    useEffect(() => {
        if (userType === UserTypes.INFLUENCER && userData) {
            setIsFavorite(
                userData?.likes_products?.find((productLike) => productLike.product_id === product.id) || false
            );
        } else {
            setIsFavorite(false);
        }
    }, [userData, product])

    const handlerLike = () => {
        if (!userToken) {
          navigate("/signin");
        } else {

            if(isFavorite) {
                LikeAPI.removeLikeProduct(product?.id).then((response) => {
                    setIsFavorite(!isFavorite);
                    const likeProducts = userData?.likes_products || [];
                    const likeProductsUpdated = likeProducts.filter((likeProduct) => likeProduct.product_id !== product?.id);
                    setUserData({...userData, likes_products: likeProductsUpdated});
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                LikeAPI.addlikeProduct(product?.id, companyId).then((response) => {
                    setIsFavorite(!isFavorite);
                    const likeProducts = userData?.likes_products || [];
                    likeProducts.push({product_id: product.id, company_id: companyId});
                    setUserData({...userData, likes_products: likeProducts});
                }).catch((error) => {
                    console.log(error);
                });
            
            }
        }
    };

    return (
        <LikeProductContainer onClick={handlerLike}>

            <svg xmlns="http://www.w3.org/2000/svg" width="45.682" height="39.971" viewBox="0 0 45.682 39.971">

                {(isFavorite) ? (<path id="Icon_awesome-heart" data-name="Icon awesome-heart" d="M41.247,4.981C36.357.814,29.086,1.564,24.6,6.194L22.841,8.005,21.083,6.194C16.6,1.564,9.324.814,4.434,4.981A12.811,12.811,0,0,0,3.551,23.53L20.815,41.356a2.8,2.8,0,0,0,4.042,0L42.121,23.53a12.8,12.8,0,0,0-.874-18.549Z" transform="translate(0.001 -2.248)" fill="#FF0000" /* fill="url(#grad1)" */ />) : (<path id="Icon_awesome-heart" data-name="Icon awesome-heart" d="M41.247,4.981C36.357.814,29.086,1.564,24.6,6.194L22.841,8.005,21.083,6.194C16.6,1.564,9.324.814,4.434,4.981A12.811,12.811,0,0,0,3.551,23.53L20.815,41.356a2.8,2.8,0,0,0,4.042,0L42.121,23.53a12.8,12.8,0,0,0-.874-18.549Z" transform="translate(0.001 -2.248)" fill="#fff" />)}

            </svg>

        </LikeProductContainer>
    )
}