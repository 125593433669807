
import { createContext, useEffect, useState } from "react";
import MessageSpanish from './../lang/es-CO.json';
import MessageEnglish from './../lang/en-US.json';
import { IntlProvider } from 'react-intl';

export const LangContext = createContext();

export default function LangProvider({ children }) {

    let localeDefault;
    let messageDefault;

    const lang = localStorage.getItem('lang');

    useEffect(() => {
        if (lang === null) {
            setLocale("es-CO");
            setMessages(MessageSpanish);
            localStorage.setItem('lang', "Spanish");
            setCountry("Spanish");
        }
    }, []);

    if (lang) {
        if (lang === "English") {
            localeDefault = "en-US";
            messageDefault = MessageEnglish;
        } else if (lang === "Spanish") {
            localeDefault = "es-CO";
            messageDefault = MessageSpanish;
        } else {
            localeDefault = "es-CO";
            messageDefault = MessageSpanish;
        }

    }

    const [messages, setMessages] = useState(messageDefault);
    const [locale, setLocale] = useState(localeDefault);

    let countryDefault;
    if (lang !== undefined) {
        countryDefault = lang;
    } else {
        countryDefault = "Spanish"
    }
    const [country, setCountry] = useState(countryDefault);

    const setLanguage = (language) => {

        switch (language) {
            case "English":
                setMessages(MessageEnglish);
                setLocale('en-US');
                localStorage.setItem('lang', "English");
                break;
            case "Spanish":
                setMessages(MessageSpanish);
                setLocale('es-CO');
                localStorage.setItem('lang', "Spanish");
                break;
            default:
                setMessages(MessageSpanish);
                setLocale('es-CO');
                localStorage.setItem('lang', "Spanish");
        }
    }

    return (
        <LangContext.Provider value={{ locale, setLanguage, country, setCountry }}>
            <IntlProvider locale={locale} messages={messages} >
                {children}
            </IntlProvider>
        </LangContext.Provider >
    )

};