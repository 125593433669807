import React, { useRef, useState } from "react";
import upload from './../../utils/images/upload.svg';
import { VideoInputContainer } from "./VideoInputStyled";
import deleteIcon from './../../utils/images/delete_pink.svg';
import { getBase64 } from "../../services/generalSevices";

export default function VideoInput({ status, source, setSource }) {


    const inputRef = useRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        getBase64({ file: file }).then((video) => {
            const blobUrl = URL.createObjectURL(file);
            setSource({ data_url: video, blob: blobUrl, file: file });
        })
    };
    

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const handleDelete = () => {
        inputRef.current.value = "";
        setSource(null);
    };

    return (
        <VideoInputContainer status={status}>

            <input
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                accept=".mov,.mp4, video/*"
            />

            {!source && <button onClick={handleChoose} disabled={status === 1} className="videoButton"><img src={upload} alt="video" className="uploadItem" /></button>}

            {(source) && (<button onClick={handleDelete} className="removeButton"><img src={deleteIcon} alt="remove" /></button>)}

            {source && (
                <video
                    className="video"
                    controls
                    src={source['blob']}
                />
            )}

        </VideoInputContainer>
    );
}