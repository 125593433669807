import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const SignInContainer = styled.div`

    background-color: ${colors.modalBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    min-height: calc(100vh - 575px);
    font-family: 'Open Sans', sans-serif;
    .signInHeader{
        display: flex;
        color: ${colors.darkGrey};
        margin-bottom: 50px;
        h1{
            font-weight: 600;
            font-size: 3rem;
        }
        .logo{
            width: 300px;
            padding-left: 20px;
        }
    }
    form{
        width: 525px;
        background-color: white;
        border-radius: 20px;
        padding: 70px 45px 40px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .title{
            align-self: center;
            color: ${colors.darkGrey};
            margin-bottom: 50px;
            font-size: 3rem;
        }
        .inputContainer{
            display: flex;
            border:1px solid ${colors.grey};
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            .icon{
                padding-right: 10px;
                width: 50px;
            }
            .seeContainer{
                display: flex;
                align-items: center;
                justify-content: center;
                .iconSeePassword{
                    padding-right: 10px;
                    width: 45px;
                }
            }
            
        }
        input[type="password"], input[type="email"], input[type="text"]{
            width: 100%;
            outline: none;
            border: none;
            font-size: 1.5rem;
            font-family: 'Open Sans', sans-serif;
            ::placeholder{
                font-weight: bold;
                color: rgba(112,112,112,0.51);
            }
        }
        .remember{
            padding: 8px 0;
            color: ${colors.greyLetter};
            color: rgba(112,112,112,0.51);
            font-size: 1.3rem;
            display: flex;
            align-items: center;
        }
        input[type='checkbox'] {
            margin-right: 10px;
            margin-left: 4px;
            -ms-transform: scale(2); /* IE */
            -moz-transform: scale(2); /* FF */
            -webkit-transform: scale(2); /* Safari y Chrome */
        }
        input[type='checkbox'] {
            //accent-color: ${colors.hightlight};
            //outline: 0.5px solid ${colors.greyLetter};
            //border:none;
            //margin-right: 12px;
            //margin-left: 8px;
            //-ms-transform: scale(2); /* IE */
            //-moz-transform: scale(2); /* FF */
            //-webkit-transform: scale(2); /* Safari y Chrome */
        }
        input[type='checkbox'] {
            -moz-appearance:none;
            -webkit-appearance:none;
            -o-appearance:none;
            outline: none;
            content: none;	
        }

        input[type='checkbox']:before {
            font-family: "FontAwesome";
            content: "\f00c";
            font-size: 10px;
            color: transparent !important;
            background: #ffffff;
            display: block;
            width: 10px;
            height: 10px;
            border:0.7px solid ${colors.grey};
            border-radius: 2px;
            //margin-right: 4px;
        }

        input[type='checkbox']:checked:before {
            color: ${colors.hightlight} !important;
        }
        
        
        .register{
            font-weight: bold;
            color: ${colors.darkGrey};
            font-size: 1rem;
            font-size: 1.3rem;
            text-align: center;
            a{
                color: #000000;
                text-decoration: underline;
                text-transform: uppercase;
                color: ${colors.darkGrey};
            }
        }
        .error{
            font-size: 1.1rem;
            color: red;
            text-align: center;
            padding: 5px 0;
            
        }
        .generalButton{
            padding: 10px 20px;
            background-color:  ${colors.grey};
            color: #ffffff;
            border-radius: 10px;
            border: none;
            font-size: 1rem;
            padding: 15px;
            font-size: 1.8rem;
            font-weight: 600;
            cursor: pointer;
            margin: 10px 0;
        }
        .active{
            background-color: ${colors.hightlight};
        }
    }
    .forgotPassword{
        margin-top: 5px;
        width: 520px;
        text-align: end;
        text-decoration: underline;
        font-size: 0.85rem;
        font-size: 1.1rem;
        cursor: pointer;
        color: ${colors.darkGrey};
    }
    #inactiveAccountAlertContainer{
        position: fixed;
        overflow-y: auto;
        top:0;
        left: 0; 
        height: 100vh;
        width: 100vw;
        background-color: transparent;
        display: flex;
        align-items: center;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;

        #inactiveAccountAlert{
            max-width: 50%;
            background-color: #ffffff;
            box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
            padding: 1em 2em 2em;
            border-radius: 1.25em;
            display: flex;
            flex-direction: column;
            align-items: center;

            .image{
                align-self: center;
            }
            
            .title{
                color: ${colors.hightlight};
                font-size: 1.5em;
                font-weight: 600;
                margin-top: -.5em
            }

            .text{
                color: #000;
                font-size: 1.0375em;   
                margin: 2em;
                margin-bottom: 3em;
                font-weight: 500;

                a{
                    color: #000;
                    text-decoration: underline;
                }
            }

            #inactiveAccountAlertButton{
                min-width: 9em;
                background-color: ${colors.hightlight};
                color: #ffffff;
                border-radius: 2em;
                border: none;
                font-size: 1.25rem;
                padding: .375em 1em;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    /**-----------Responsive----------- */
    ${media.largeDesktop}{
        .signInHeader{
            h1{
                font-size: 2.8rem;
            }
            .logo{
                width: 280px;
            }
        }
        form{
            width: 510px;
            padding: 75px 40px 38px;
            .title{
                margin-bottom: 40px;
                font-size: 2.8rem;
            }
            .inputContainer{
                padding: 15px;
                margin-bottom: 15px;
                
            }
            input[type="password"], input[type="email"], input[type="text"]{
                font-size: 1.4rem;
            }
        }
        .forgotPassword{
            width: 500px;
        }
    }

    ${media.specialScreen}{
        .signInHeader{
            h1{
                font-size: 2.4rem;
            }
            .logo{
                width: 240px;
            }
        }
        form{
            width: 420px;
            padding: 60px 35px 30px;
            .title{
                font-size: 2.2rem;
            }
            .inputContainer{
                padding: 10px;
                margin-bottom: 15px; 
                .icon{
                    width: 35px;
                }
                .seeContainer{
                    .iconSeePassword{
                        width: 35px;
                    }
                }
            }
            input[type="password"], input[type="email"], input[type="text"]{
                font-size: 1.1rem;
            }
            .remember{
                font-size: 0.9rem;
            }
            input[type='checkbox'] {
                margin-right: 6px;
                margin-left: 4px;
                -ms-transform: scale(1.5); /* IE */
                -moz-transform: scale(1.5); /* FF */
                -webkit-transform: scale(1.5); /* Safari y Chrome */
            }
            .register{
                font-size: 1rem;
            }
            .error{
                font-size: 0.85rem; 
            }
            .generalButton{  
                font-size: 1.3rem;
                padding: 10px;
            }
        }
        .forgotPassword{
            font-size: 0.9rem;
            width: 420px;
        }
    }

    ${media.desktop}{
        .signInHeader{
            h1{
                font-size: 2.2rem;
            }
            .logo{
                width: 220px;
            }
        }
        form{
            width: 400px;
            padding: 60px 35px 30px;
            .title{
                font-size: 2.2rem;
            }
            .inputContainer{
                padding: 10px;
                margin-bottom: 15px; 
                .icon{
                    width: 35px;
                }
            }
            input[type="password"], input[type="email"], input[type="text"]{
                font-size: 1rem;
            }
            .remember{
                font-size: 0.9rem;
            }
            input[type='checkbox'] {
                margin-right: 6px;
                margin-left: 4px;
                -ms-transform: scale(1.5); /* IE */
                -moz-transform: scale(1.5); /* FF */
                -webkit-transform: scale(1.5); /* Safari y Chrome */
            }
            .register{
                font-size: 1rem;
            }
            .error{
                font-size: 0.85rem; 
            }
            .generalButton{  
                font-size: 1.3rem;
                padding: 10px;
            }
        }
        .forgotPassword{
            font-size: 0.9rem;
            width: 400px;
        }
    }

    ${media.smallDesktop}{
        .signInHeader{
            h1{
                font-size: 2.2rem;
            }
            .logo{
                width: 220px;
            }
        }
        form{
            width: 400px;
            padding: 60px 35px 30px;
            .title{
                font-size: 2.2rem;
            }
            .inputContainer{
                padding: 10px;
                margin-bottom: 15px; 
                .icon{
                    width: 35px;
                }
            }
            input[type="password"], input[type="email"], input[type="text"]{
                font-size: 1rem;
            }
            .remember{
                font-size: 0.9rem;
            }
            .register{
                font-size: 1rem;
            }
            
            .generalButton{  
                font-size: 1.3rem;
                padding: 12px;
            }
        }
        .forgotPassword{
            font-size: 0.9rem;
        }
    }
    ${media.laptop}{
        /* min-height: 95vh;
        justify-content: center;
        .signInHeader{
            flex-direction: column;
            align-items: center;
            margin-bottom: 80px;
        }
        form{
            width: 50%;
            //min-height: 40vh;
            padding: 60px 30px 30px;
            justify-content: space-between;
        }
        .forgotPassword{
            width: 50%;
        } */

        #inactiveAccountAlertContainer{
            #inactiveAccountAlert{
                max-width: 80%;
                background-color: #ffffff;
                box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                padding: 1em 1.5em;
                border-radius: 1.25em;
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .title{
                    color: ${colors.hightlight};
                    font-size: 2em;
                    font-weight: 600;
                }
    
                .text{   
                    margin: 1em 1em 2em;
                    font-weight: bold;
                }
    
                #inactiveAccountAlertButton{
                    min-width: auto;
                    background-color: ${colors.hightlight};
                    color: #ffffff;
                    border-radius: 2em;
                    border: none;
                    font-size: 1.25rem;
                    padding: .625em 1em;
                    font-weight: 600;
                }
            }
        }
    }

    ${media.largeMobile}{
        background-color: #ffffff;
        min-height: 95vh;
        justify-content: center;
        .signInHeader{
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;
            h1{
                font-size: 1.5rem;
            }
            .logo{
                width: 150px;
            }
        }
        form{
            width: 100%;
            .title{
                margin-bottom: 50px;
                font-size: 1.8rem;
            }
            .inputContainer{
                padding: 10px;
                margin-bottom: 10px; 
                .icon{
                    width: 32px;
                }
                .seeContainer{
                    .iconSeePassword{
                        width: 32px;
                    }
                }
            }
            input[type="password"], input[type="email"], input[type="text"]{
                font-size: 1rem;
            }
            .remember{
                font-size: 0.85rem;
            }
            .register{
                font-size: 0.9rem;
            }
            
            .generalButton{  
                font-size: 1.2rem;
                padding: 10px;
            }
        }
        .forgotPassword{
            width: 100%;
            text-align: center;
            padding-right: 0px;
            font-size: 0.85rem;
        }
    }
    
`