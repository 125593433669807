import React, { useContext, useState, useEffect } from "react";
import { CurrencyContext } from "../../context/currencyContext";
import { LangContext } from "../../context/langContext";
import { formatNumber } from "../../services/generalSevices";
import { ServiceContext } from "../../context/serviceContext";

export default function ItemCollaboration({ item, quantity }) {

    const { currency, convertCurrency } = useContext(CurrencyContext);
    const { services } = useContext(ServiceContext);
    const [itemPrice, setItemPrice] = useState(null);
    const { locale } = useContext(LangContext);
    const service = services.find(service => service.id === item.service_id);

    useEffect(() => {
        setItemPrice(convertCurrency((item.price) * (quantity), currency.symbol).toFixed(2));
    }, [currency])

    return (
        <tr>
            <td><span className="itemName">{(locale === 'es-CO') ? service?.nombre : service?.name}</span> x {quantity}</td>
            <td>${formatNumber(itemPrice)}</td>
        </tr>
    )
}