import React, { useState, useEffect } from "react";
import { ModalDisputeContainer, ProfileImage } from "./ModalDisputeStyled";
import { FormattedMessage } from 'react-intl';

import avatar from './../../utils/images/avatar_fluincr.png';
import check from './../../utils/images/check_circle_darker.svg';
import star from './../../utils/images/star.svg';
import upload from './../../utils/images/upload.svg';
import arrowBack from './../../utils/images/arrow_back.svg';
import close from './../../utils/images/close_circle.svg';
import deleteIcon from './../../utils/images/delete_grey.svg';

import { fill, formatNumber, getBase64 } from "../../services/generalSevices";
import { formatDateLocal, toUTCString } from "../../services/dateServices";
import { useContext } from "react";
import { useParams } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import { CurrencyContext } from "../../context/currencyContext";
import ChargingSpinner from "../ChargingSpinner/ChargingSpinner";
import { uploadImageS3 } from "../../utils/common/s3";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";

export default function ModalDispute({ setShowDispute, collaboration, setCollaboration, setShowReceivedDispute }) {

    const { idCollaboration } = useParams();
    const { userData, userType } = useContext(UserContext2);
    const { currency, convertCurrency } = useContext(CurrencyContext);

    const [disputeDetail, setDisputeDetail] = useState('');

    const [base64File, setBase64File] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [auxProductPrice, setAuxProductPrice] = useState(null);

    const [file, setFile] = useState(null);
    const [showSmallSpinner, setShowSmallSpinner] = useState(false);

    useEffect(() => {
        const auxCurrency = currency.symbol;

        setAuxProductPrice(convertCurrency(collaboration?.product?.price, auxCurrency).toFixed(2));
    }, [currency])

    const showSpinner = () => {
        setShowSmallSpinner(true);
        setTimeout(() => {
            setShowSmallSpinner(false);
        }, 3000);
    }

    const sendDispute = () => {
        setIsLoading(true)

        const disputeBody = {
            description: disputeDetail,
            file: "",
            type: "",
            idColaboration: idCollaboration
        }

        if (userType === UserTypes.INFLUENCER) {
            disputeBody.type = UserTypes.INFLUENCER
        } else {
            disputeBody.type = UserTypes.COMPANY
        }

        if (base64File !== '') {
            const fileBody = {
                base64File: base64File.base64.split(',')[1],
                formatFile: base64File.type,
                address: ""
            }
            if (userType === UserTypes.INFLUENCER) {
                fileBody.address = `company/${userData?.account}/dispute`
            } else {
                fileBody.address = `fluincr/${userData?.account}/dispute`
            }

            //TODO: validar que se guarde bien con el segundo y tercer parametro
            uploadImageS3(fileBody.address, fileBody.formatFile, base64File.base64).then((fileLink) => {

                if (fileLink) {
                    disputeBody.file = fileLink

                    CollaborationAPI.disputeCollaboration(idCollaboration, fileLink).then((response) => {
                        setCollaboration(response);
                        setShowReceivedDispute(true);
                        setIsLoading(false)
                    })
                }
            })
        } else {
            CollaborationAPI.disputeCollaboration(idCollaboration).then((response) => {
                setCollaboration(response);
                setShowReceivedDispute(true);
                setIsLoading(false)
            })
        }
    }

    return (
        <ModalDisputeContainer>
            <div className="disputeContainer">

                <img src={close} alt="close" className="close" onClick={() => { setShowDispute(false) }} />

                <div className="goBack" onClick={() => setShowDispute(false)} >
                    <img src={arrowBack} alt="back" className="backIcon" />
                    <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
                </div>

                <h3 className="title"><FormattedMessage id="collaboration_details_text_15" defaultMessage="Detalle del convenio" /></h3>
                <div className="orderDetails">
                    <p className="order"><FormattedMessage id="collaboration_details_text_1" defaultMessage="Orden" /> #{(collaboration?.id) && fill(collaboration?.id, 8)}</p>

                    {(collaboration?.creation) && (<p className="date">{formatDateLocal(toUTCString(new Date(collaboration?.creation)))}</p>)}
                </div>

                <div className="disputeBody">
                    <section>
                        <div className="influencerDate">

                            <p className="influencerName"><FormattedMessage id="collaboration_details_text_2" defaultMessage="Colaboración con" /> {(userType === UserTypes.INFLUENCER) ? (<span className="name">{collaboration?.company?.nameCompany}</span>) : (<span className="name">{collaboration?.influincr?.givenName}</span>)}</p>

                            {(userType === UserTypes.INFLUENCER) ? 
                                (<ProfileImage image={collaboration?.company?.image_profile ?? avatar} />) : 
                                (<ProfileImage image={collaboration?.influencer?.image_profile ?? avatar} />)}

                            {((userType === UserTypes.INFLUENCER) && (collaboration?.influincer?.verified)) && (<img src={check} alt="check" className="check" />)}

                            {((userType === UserTypes.COMPANY) && (collaboration?.company?.verified)) && (<img src={check} alt="check" className="check" />)}

                            {(userType === UserTypes.COMPANY) && (<div className="rating">
                                <p className="score">{collaboration?.influencer?.rating  ?? 'N/R'}</p>
                                <img src={star} alt="star" className="star" />
                            </div>)}

                            {(userType === UserTypes.INFLUENCER) && (<div className="rating">
                                <p className="score">{collaboration?.company?.rating ?? 'N/R'}</p>
                                <img src={star} alt="star" className="star" />
                            </div>)}

                        </div>
                        <div className="productInformation">
                            <div className="productImage" style={{ backgroundImage: `url(${collaboration?.product?.image})` }}>

                            </div>
                            <div className="description">
                                <p className="productName">{collaboration?.product?.name}</p>
                                <p className="productPrice">$ {formatNumber(auxProductPrice)}</p>
                                <p className="productDescription">{collaboration.product?.description}</p>
                            </div>
                        </div>
                        {(collaboration?.comment) && (<>
                            <p className="commentsTitle"><FormattedMessage id="collaboration_details_text_6" defaultMessage="Requisitos/comentarios de la colaboración" /></p>
                            
                            <div className="commentsContainer">
                                <p className="comments">{collaboration?.comment}</p>
                            </div>
                        </>)}
                        {(collaboration.document) && (
                            <a target="_blank" href={collaboration.document} download={collaboration.document} >
                                <p className="file"><FormattedMessage id="collaboration_details_text_12" defaultMessage="Documento de colaboración /contrato" /></p>
                            </a>)}
                    </section>
                    <section>
                        <div className="influencerData">
                            <h3><FormattedMessage id="modal_dispute_text_1" defaultMessage="Hola, " /><span className="name">{userData?.name}</span></h3>
                            <ProfileImage image={userData?.imageProfile ?? avatar} />
                        </div>

                        <p className="text1"><FormattedMessage id="modal_dispute_text_2" defaultMessage="Lamentamos escuchar acerca de tu preocupación." /></p>
                        <p className="text2"><FormattedMessage id="modal_dispute_text_3" defaultMessage="Antes de iniciar una disputa, primero debes intentar comunicarte y resolver el problema con la otra parte, dentro de la ventana de disputa." /></p>
                        <p className="text3"><FormattedMessage id="modal_dispute_text_4" defaultMessage="Cuéntanos sobre tu disputa." /></p>
                        <p className="text4"><FormattedMessage id="modal_dispute_text_5" defaultMessage="¿Cómo trataste de resolver el problema con la otra parte?" /></p>
                        <textarea name="" id="" cols="30" rows="10" value={disputeDetail} onChange={(event) => { setDisputeDetail(event.target.value) }}></textarea>

                        <div className="uploadFile">

                            {(base64File === '') && (
                                <label htmlFor="dispute-file-input" className="fileLabel">
                                    <img src={upload} alt="upload" className="uploadIcon" />
                                    <p><FormattedMessage id="modal_dispute_text_6" defaultMessage="Subir archivo" /></p>
                                </label>
                            )}

                            {(showSmallSpinner) && (<div className="chargingContainer">
                                <ChargingSpinner />
                            </div>)}

                            {(base64File !== '' && showSmallSpinner === false) && (
                                <> <img src={deleteIcon} alt="upload" className="deleteIcon" onClick={() => {
                                    setBase64File('')
                                    setFile(null)
                                }} />
                                    <span className="fileName">{file.name}</span>
                                </>
                            )}

                            <input type="file" id="dispute-file-input"
                                accept=".pdf, .txt, application/pdf, .doc, .docx, application/msword, .xls,.xlsx, application/vnd.ms-excel, .ppt, .pptx, application/vnd.ms-powerpoint, text/plain,.png, .jpg, .jpeg, image/png, image/jpg" onChange={(e) => {
                                    if (e.target.files[0] !== undefined) {
                                        setFile(e.target.files[0])
                                        getBase64({ file: e.target.files[0] }).then((data) => {
                                            setBase64File({ base64: data, name: e.target.files[0].name, type: e.target.files[0].type.split('/')[1] });
                                        }
                                        );
                                        showSpinner()
                                    }
                                }} />
                        </div>
                        <p><FormattedMessage id="modal_dispute_text_7" defaultMessage="Al crear una disputa, entendemos que la colaboración  en su totalidad se suspenderá por el momento hasta que  la investigación sea completada y resuelta por parte de Fluincr." /></p>
                        <button className="sendDisputeButton" onClick={sendDispute}><FormattedMessage id="modal_dispute_button" defaultMessage="Enviar disputa" /></button>
                    </section>

                </div>


            </div>
            {(isLoading) && (<Spinner />)}
        </ModalDisputeContainer>
    )
}