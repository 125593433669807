import styled from "styled-components";
import { media } from "../../utils/styles/media";
import { colors } from './../../utils/styles/colors';

export const CompaniesContainer = styled.div`
    
    padding: 50px;
    .goBack{
        display: flex;
        .backIcon{
            margin-right: 10px;
        }
    }
    .companiesBody{
        display: flex;
        .menu{
            width: 20%;
            position: sticky;
            top: 10px;
            .filterTitle{
                padding: 10px 0;
                font-size: 1.1rem;
                color: ${colors.hightlight};
                font-weight: bold;
            }
            .filter{
                padding: 10px 0;
                font-size: 1.1rem;
                .filterName{
                    display: flex;
                    align-items: baseline;
                    img{
                        width: 15px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
                .filterName:hover{
                    color: ${colors.hightlight};
                    cursor: pointer;
                }
                .options{
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    label{
                            margin: 2px;
                            input[type='checkbox'] {
                                accent-color: ${colors.hightlight};
                                margin-right: 5px;                               
                            }
                        }
                }
            }

        }
        .companiesContent{
            width: 80%;
            .results{
                display: flex;
                justify-content: space-between;
                
                font-size: 1.1rem;
                .totalResults{
                    font-weight: 600;
                }
                .orderBy{
                    font-weight: bold;
                    color: ${colors.hightlight};
                    display: flex;
                    align-items: baseline;
                    img{
                        width: 15px;
                        margin-left: 10px;
                    } 
                }
            }
            .cards{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding-top: 10px;
            }
            .seeMore{
                margin-top: 20px;
                text-align: center;
                font-weight: 700;
                font-size: 1.4rem;
                color: ${colors.hightlight};
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
    }

    /**-------------Responsive--------------- */
    ${media.desktop}{

    }

    ${media.laptop}{
        padding: 30px;
        .companiesBody{
            display: flex;
            width: 100%;
            .menu{
                width: 28%;
            }
            .companiesContent{
                width:80%;
                padding-left: 2px;
            }
        }
    }

    ${media.largeMobile}{
        padding: 20px;
        .companiesBody{
            flex-direction: column;
            .menu{
                position: initial;
                width: 100%;
            }
            .companiesContent{
                width: 100%;
                .cards{
                    justify-content: space-between;
                }
            }
        }
    }



`