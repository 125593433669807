import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalSendCollaborationContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .closeOutside{
        display: block;
        position: absolute;
        top: 20px;
        right: 30px;
        width: 50px;
        cursor: pointer;
    }

    .confirmBody{
        padding: 40px;
        background-color: #ffffff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            height: 100px;
        }
        .text1{
            font-size: 1.5rem;
            font-weight: 750;
            width: 60%;
            text-align: center;
            margin-top: 10px;
        }
        .influencerName{
            font-size: 1.6rem;
            color: ${colors.hightlight};
            font-weight: 750;
            margin: 10px 0;
            text-transform: capitalize;
        }
        .text2{
            margin-top: 10px;
            font-weight: 700;
        }
        .text3{
            margin: 10px 0 20px;
        }
        .closeButton{
            background-color: ${colors.hightlight};
            height: 35px;
            padding: 0px 30px;
            border: none;
            border-radius: 10px;
            color: #ffffff;
            font-size: 1.4rem;
            font-weight: bold;
            cursor: pointer; 
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
        .closeInside{
            display: none;
        }
    }

    /**---------Responsive--------- */
    ${media.tablet}{
        .closeOutside{
            display: none;
        }
        .confirmBody{
            position: relative;
            padding: 30px 20px;
            width: 90%;
            .text1{
                width: 85%;
            }
            .closeInside{
                position: absolute;
                display: block;
                top: 15px;
                right: 15px;
                width: 30px;
                height: 30px;
            }
        }
    }

`