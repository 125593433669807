import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormattedMessage } from 'react-intl';

import './../../../Bootstrap.css';

const ModalSelectPublishDate = ({ open, setOpen, publishDate, setPublishDate, onConfirm, onEdit, edit}) => {
    const [selectedDate, setSelectedDate] = useState(dayjs().add(1, 'day'));
    const [error, setError] = useState(true);

    useEffect(() => {
        if (publishDate) {
            setSelectedDate(dayjs(publishDate));
        } else {
            setSelectedDate(dayjs().add(1, 'day'));
        }
    }, [publishDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        if (selectedDate) {
            if(edit)
                onEdit(selectedDate.toDate());
            else
                onConfirm(selectedDate.toDate());
            setOpen(false);
            setError(false);
        } else {
            setError(true);
        }
    };

    return (
        <Dialog id="" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="d-flex justify-content-center mw-auto">
                <FormattedMessage id="modalSelectPublishDateTitle" defaultMessage="Fecha de publicación" />
            </DialogTitle>
            <DialogContent className="my-2 w-auto">
                <LocalizationProvider dateAdapter={AdapterDayjs} className="w-100">
                    <DatePicker
                        className='w-100 px-3'
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={dayjs()}
                        renderInput={(params) => (
                            <TextField
                                {...params}                                
                                fullWidth
                                error={error}
                                helperText={error && (
                                    <FormattedMessage
                                        id="modalSelectPublicDateLabelError"
                                        defaultMessage="Seleccionar una fecha"
                                    />
                                )}
                            />
                        )}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions className="mb-2 me-2">
                <button onClick={handleClose} className="fl-button-secondary">
                    <FormattedMessage id="modalButtonCancel" defaultMessage="Cancelar" />
                </button>
                <button onClick={handleConfirm} className="fl-button-primary">
                    <FormattedMessage id="modalButtonConfirm" defaultMessage="Confirmar" />
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalSelectPublishDate;
