import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalSecurityCodeContainer = styled.div`
    
    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .closeOutside{
        display: none;
    }
    .securityBody{
        width: 60%;
        min-height: 40%;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .header{
            display: flex;
            .title{
                font-family: 'Open Sans',  sans-serif;
                font-weight: bold;
                font-size: 2.1rem;
                color: ${colors.darkGrey};
                width: calc(100% - 25px);
                text-align: center;
                
            }
            .close{
                width: 25px;
            }
        }
        .firstText{
            text-align: center;
            font-weight: 600;
        }

        .code{
            display: flex;
            justify-content: center;
            .codeNumber{
                width: 40px;
                height: 50px;
                margin: 10px;
                outline: none;
                text-align: center;
                font-size: 2rem;
                border: none;
                border-bottom: 2px solid ${colors.grey};
                color: ${colors.darkGrey};
            }
        }
        
        .secondText{
            text-align: center;
            font-weight: 600;
            .resend{
                color: ${colors.hightlight};
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    /**-----------Responsive----------- */
    ${media.bigmacdesktop}{
        .securityBody{
            width: 70%;
        }
    }
    ${media.laptop}{
        //position: relative;
        .closeOutside{
            display: block;
            position: absolute;
            top: 0;
            right: 50px;
            width: 40px;
        }
        .securityBody{
            //width: 70%;
            min-height: 50%;
            padding: 30px;
            .header{
                .title{
                    font-size: 2.5rem;
                    width: 100%;
                }
                .close{
                    display: none;
                }
            }
            .firstText, .secondText{
                font-size: 1.2rem;
            }  
        }
    }

    ${media.largeMobile}{

        .closeOutside{
            top: 20px;
            right: 20px;
            width: 40px;
        }
        .securityBody{
            width: 85%;
            min-height: 50%;
            padding: 30px;
            .header{
                .title{
                    font-size: 2rem;
                    margin-bottom: 20px;
                }
            }
            .firstText, .secondText{
                font-size: 1rem;
            }
            .code{
                .codeNumber{
                    width: 35px;
                    font-size: 1.8rem; 
                    margin: 5px;  
                }
            }
        }
    }

`