import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalPricesContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .pricesBody{
        width: 45em;
        background-color: #ffffff;
        border-radius: 15px;
        padding: 0 10px;
        position: relative;
        .close{
            width: 40px;
            align-self: flex-end;
            position: absolute;
            top:10px;
            right: 10px;
            cursor: pointer;
        }
        .headerTable{
            display: none;
        }
        table{
            padding: 10px 30px;
            tr{
                th{
                    vertical-align: bottom;
                    padding-bottom:20px ;
                    font-size: 1.1rem;
                }
                td{
                    //padding: 4px 8px;
                    text-align: center;
                    .type{
                        font-weight: 800;
                        text-align: left;
                        text-transform: capitalize;
                    }
                    .slider{
                        width: 250px;
                        margin: 0 10px;
                        color: ${colors.hightlight};
                        height: 8px;
                        .MuiSlider-rail{
                            color: grey;
                        }
                        .MuiSlider-thumb{
                            border: 1px solid #ffffff;
                        }
                    }
                    .inputPrice{
                        width: 80px;
                        border-radius: 5px;
                        padding: 4px;
                        border: 1px solid ${colors.grey};
                        outline: none;
                        text-align: center;
                    }
                }
            }
        }

        .help-text{
            font-size: .75em;
        }

        .discountGenerator{
            display: flex;
            padding: 40px 40px 0;
            justify-content: space-between;
            align-items: center;
            font-family: 'Open Sans', sans-serif;
            p{
                color: ${colors.greyLetter};
                font-weight: 700;
                font-size: 1.2rem;
            }
            .inputs{
                display: flex;
                flex-wrap: wrap;
                .porcent{
                    display: flex;
                    
                    input[type='number']{
                        width: 40px;
                    }
                    p{
                        align-self: center;
                        font-weight: 400;
                        font-size: 1rem;
                    }
                }
                
                input{
                    width: 100px;
                    margin: 0 8px;
                    border-radius: 5px;
                    //padding: 5px;
                    height: 30px;
                    border: 1px solid ${colors.grey};
                    outline: none;
                    text-align: center;
                    
                }
                input::placeholder{
                    //text-transform: uppercase;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: bold;
                    color: rgba(112,112,112,0.51);
                }
                
            }
            
            .generateButton{
                background-color: ${colors.blue};
                color: #ffffff;
                border: none;
                border-radius: 5px;
                padding: 5.5px 10px;
                //height: 29px;
                //padding: 0 10px;
                cursor: pointer;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Open Sans', sans-serif;
            }
        }
        .text{
            padding: 20px 20px;
            text-align: center;
        }
        .buttonContainer{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 20px 0;
            .saveButton{
                justify-self:center;
                background-color: ${colors.hightlight};
                color: #ffffff;
                border: none;
                border-radius: 5px;
                padding: 8px 12px;
                cursor: pointer;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Open Sans', sans-serif;
                font-size: 1rem;
            }
        }
        
    }

    /**--------Responsive---------- */
    
    ${media.tablet}{
        .pricesBody{
            height: auto;
            width: 100vw;
            margin: 0 2em;
            .close{
                right: 20px;
            }
            .headerTable{
                display: block;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .headerTableDesktop{
                display: none;
            }
            table{
                width: auto !important;
                text-align:center;
                tr{
                   
                    th{
                       div{
                        display: none;
                       }
                    }
                }
            }
            .discountGenerator{
                flex-direction: column;
                .inputs{
                    margin: 15px 0;
                }
                .generateButton{
                    padding: 10px 20px;
                    border-radius: 10px;
                    
                }
            }
            .buttonContainer{
                .saveButton{
                    padding: 10px 20px;
                    border-radius: 10px;
                }
            }

            .servicesPrices{
                width: 100% !important;
                margin: 0 auto;
            }
        }

    } 

    ${media.largeMobile}{
        .pricesBody{
            overflow-y: auto;
            table{
                padding: 10px 30px;
                padding: 0;
                tr{
                    td{
                        .type{
                            padding-left: 0px;
                            width: 90px;
                        }
                        .slider{
                            width: 120px;
                            
                        }
                    }
                }
                
            }
            .discountGenerator{
                padding: 10px 10px 0px;
                .generateButton{
                    border-radius: 15px;  
                    padding: 6px 15px;
                    font-size: 0.7rem;
                }
            }
            .buttonContainer{
                padding-bottom: 20px;
                .saveButton{
                    border-radius: 18px;
                    font-size: 0.9rem;
                }
            }
        }
    }

`

export const ProfileImage = styled.div`

    width: 100px; 
    height: 100px;
    background-image:url(${(props) => props.image});
    border-radius: 50%;
    background-position: 50%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    top:10px;
     /**--------Responsive---------- */
     ${media.table}{
        width: 120px; 
        height: 120px;

    }

`