import React, { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  HomeContainer,
  FirstSectionContainer,
  SectionsContainer,
  CenterSliderContainer,
  LastSliderContainer,
  LastSectionsContainer,
  ArrowContainer,
} from "./HomeStyled";
import { FormattedMessage } from "react-intl";

import Banner from "../../components/Banner/Banner";
import FilterBar from "../../components/FilterBar/FilterBar";
import InfluencerCard from "./../../components/InfluencerCard/InfluencerCar";
import CompanyCard from "./../../components/CompanyCard/CompanyCard";
import FilterCard from "../../components/FilterCard/FilterCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiltersContext } from "../../context/filtersContext";
import { settingSliderCategories, settingSliderNetwork } from "./HomeAux";
import { StaticDataContext } from "../../context/staticDataContext";
import { InfluencerContext } from "../../context/user/influencerContext";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { CompanyContext } from "../../context/user/companyContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { Skeleton } from "@mui/material";
import FluincrDropdown from "../../components/Dropdown/FluincrDropdown";
import { CompanyAPI } from "../../api/user/company/company";

export default function Home() {

  const navigate = useNavigate();

  const { userToken, userType } = useContext(UserContext2);
  const { influencersFeaturedList, setInfluencersFeaturedList, influencersNewList, setInfluencersNewList } = useContext(InfluencerContext);
  const { companyList, setCompanyList } = useContext(CompanyContext);
  const { categorySelectList, fluincrSocialNetworkOptions } = useContext(StaticDataContext);

  const {
    featuredFilter,
    setFeaturedFilter,
    newUserFilter,
    setNewUserFilter,
    setLanguageFilter,
    setRatingFilter,
    setVerifiedFilter,
    setGenderFilter,
    setAgeFilter,
    setNetworkFilter,
    setCategoryFilter,
    setCountryFilter,
    setPriceFilter,
    setQueryPrice,
    setQueryPriceId,
    setInputFilter,
    setOrderBy,
    resetFilters,
  } = useContext(FiltersContext);

  const [companies, setCompanies] = useState([]);

  const limit = 5; // Limit de influencers a mostrar

  useEffect(() => {
    if(influencersFeaturedList.length === 0) {
      InfluencerAPI.getInfluencersFeatured().then((response) => {
        setInfluencersFeaturedList(response);
      }).catch((error) => {
        console.error('Error al obtener influencers destacados:', error);
      });
    }

    if(influencersNewList.length === 0) {
      InfluencerAPI.getAllInfluencersFiltered({new: true, size: 5}).then((response) => {
        setInfluencersNewList(response.content);
      }).catch((error) => {
        console.error('Error al obtener influencers nuevos:', error);
      });
    }

    if(companyList.length === 0) {
      CompanyAPI.getCompaniesFeatured(5).then((response) => {
        setCompanyList(response);
      }).catch((error) => {
        console.error('Error al obtener companies:', error);
      });
    }
  }, []);

  useEffect(() => {
    resetFilters();
  }, []);

  return (
    <HomeContainer>
      <Banner />

      <FilterBar />

      <FirstSectionContainer>
        <div className="subtitle">
          <h2>
            <FormattedMessage
              id="home_title_1"
              defaultMessage="Influencers destacados"
            />
          </h2>
          <p
            className="seeMore"
            onClick={() => {
              setFeaturedFilter(true);
              navigate("/influencers");
            }}
          >
            <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
          </p>
        </div>

        <div className="content">
          <div className="cards influencers">
            {influencersFeaturedList
              .map((influencer, key) => (
                <InfluencerCard
                  influencer={influencer}
                  key={key}
                  page="home"
                />
            ))}
          </div>
          <p
            className="seeMoreMobile"
            onClick={() => {
              setFeaturedFilter(true);
              navigate("/influencers");
            }}
          >
            <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
          </p>
        </div>
      </FirstSectionContainer>

      <SectionsContainer>
        <div className="subtitle">
          <h2>
            <FormattedMessage
              id="home_title_2"
              defaultMessage="Empresas en Fluincr"
            />
          </h2>
          { !userToken ? (
            <Link to={"/signin"}>
              <FormattedMessage
                id="home_more_text"
                defaultMessage="Ver más +"
              />
            </Link>
          ) : userType === UserTypes.INFLUENCER ? (
            <Link to={"/companies"}>
              <FormattedMessage
                id="home_more_text"
                defaultMessage="Ver más +"
              />
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="content">
          <div className="cards companies">
            {companyList.length > 0 &&
              companyList.map((company) => (
              <CompanyCard company={company} key={company.id} page="home" />
            ))
            }
          </div>
          { !userToken ? (
            <Link to={"/signin"} className="seeMoreMobile">
              <FormattedMessage
                id="home_more_text"
                defaultMessage="Ver más +"
              />
            </Link>
          ) : userType === UserTypes.INFLUENCER ? (
            <Link to={"/companies"} className="seeMoreMobile">
              <FormattedMessage
                id="home_more_text"
                defaultMessage="Ver más +"
              />
            </Link>
          ) : (
            ""
          )}
        </div>
      </SectionsContainer>

      <CenterSliderContainer>
        <div className="subtitle">
          <h2>
            <FormattedMessage id="home_title_3" defaultMessage="Categorías" />
          </h2>
        </div>
        <div className="sliderContainer">
          <Slider {...settingSliderCategories}>
            {categorySelectList.map((category) => (
              <FilterCard
                item={category}
                key={category.value}
                type={"category"}
              />
            ))}
          </Slider>
        </div>
      </CenterSliderContainer>

      <SectionsContainer>
        <div className="subtitle">
          <h2>
            <FormattedMessage
              id="home_title_5"
              defaultMessage="Nuevos en Fluincr"
            />
          </h2>
          <p
            className="seeMore"
            onClick={() => {
              setNewUserFilter(true);
              navigate("/influencers");
            }}
          >
            <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
          </p>
        </div>

        <div className="content">
          <div className="cards influencers">
            {influencersNewList
              .map((influencer, key) => (
                <InfluencerCard
                  influencer={influencer}
                  key={key}
                  page="home"
                />
            ))}
          </div>
          <p
            className="seeMoreMobile"
            onClick={() => {
              setNewUserFilter(true);
              navigate("/influencers");
            }}
          >
            <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
          </p>
        </div>
      </SectionsContainer>

      <LastSliderContainer>
        <div className="subtitle">
          <h2>
            <FormattedMessage
              id="home_title_6"
              defaultMessage="Redes Sociales"
            />
          </h2>
        </div>

        <div className="sliderContainerNetwork">
          <Slider {...settingSliderNetwork}>
            {fluincrSocialNetworkOptions?.map((network, key) => (
              <FilterCard item={network} key={key} type={"network"} />
            ))}
          </Slider>
        </div>
      </LastSliderContainer>
    </HomeContainer>
  );
}