import React, { useContext, useState, useEffect } from "react";
import { MyProfileFirstSectionContainer } from "./MyProfileFirstSectionStyled";
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import gift from './../../utils/images/gift.svg';
import edit from './../../utils/images/edit.svg';
import ModalPrices from "../ModalPrices/ModalPrices";
import { formatNumber } from "../../services/generalSevices";
import { CurrencyContext } from "../../context/currencyContext";
import Select, { components } from 'react-select';
import { useRef } from "react";

import avatar from "../../utils/images/avatar_fluincr.png";
import { UserContext2 } from "../../context/user/userContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";

export default function MyProfileFirstSection() {

    const intl = useIntl();
    const location = useLocation();

    const collaboratorsRef = useRef(null);
    const [showModalPrices, setShowModalPrices] = useState(false);

    const { userData, setUserData } = useContext(UserContext2);
    const { currency, convertCurrency } = useContext(CurrencyContext);
    const [price, setPrice] = useState(null);

    const [collaboratorsOptions, setCollaboratorsOptions] = useState([]);

    const { Option } = components;

    const [showCollaboratorsSelect, setShowCollaboratorsSelect] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [collaboratorsImages, setCollaboratorsImages] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const complete = queryParams.get('complete');

        if (complete === 'rates' && userData?.rate_by_service_list.every(rate => rate.price === 0)) {
            setShowModalPrices(true);
        }
    }, [location.search]); 

    useEffect(() => {
        //Para que un colborador pueda salir como option debera la colaboracion estar en estado cerrada
        if (userData) {
            // TODO: llamar a endpoint que obtiene las opciones de collaboradores públicos del influencer
            // y guardarlas en collaboratorsOptions, pedirlos cuando abren el select
        }
    }, [userData])

    useEffect(() => {

        if (currency && userData) {
            const amount = userData?.rate_by_service_list?.filter(rate => rate?.price > 0)
                .reduce((precioMin, serviceActual) => {
                    return (serviceActual.price < precioMin || precioMin === 0) ? serviceActual.price : precioMin;
                }, 0);

            const auxCurrency = currency.symbol

            setPrice(convertCurrency(amount, auxCurrency).toFixed(2));
        }

    }, [currency, userData])

    const IconCollaboratorOption = props => {

        return (<Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={props.data?.company_image ?? (avatar)}
                    style={{ width: '40px', borderRadius: '50%', marginRight: 5, border: '1px solid #E4E6EB' }}
                    alt={props.data.label}
                />
                <span >{props.data?.company_name}</span>
            </div>
        </Option>)
    };

    const sendCollaborators = () => {

        setShowCollaboratorsSelect(false);
        const selectCollaboratorsId = selectedOptions.map((collaborator) => collaborator.company_id);

        InfluencerAPI.updateCollaborators(selectCollaboratorsId).then(() => {
            setUserData({
                ...userData,
                profile_data: {
                    ...userData.profile_data,
                    collaborators: selectedOptions
                }
            })
        })
    }

    const selectStyles = {
        control: (styles) => ({
            ...styles, borderRadius: '10px', boxShadow: 'none'
        }),
        option: (styles) => ({
            ...styles, display: 'flex', alignItems: 'center', background: 'transparent', color: '#000000', fontSize: '1.5rem',
            "@media(max-width: 1920px)": {
                fontSize: '1.3rem'
            },
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem',
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        input: (styles) => ({
            ...styles, fontSize: '1.5rem',
            "@media(max-width: 1920px)": {
                fontSize: '1.3rem'
            },
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: '1.5rem',
            "@media(max-width: 1920px)": {
                fontSize: '1.3rem'
            },
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }

        }),
        singleValue: (styles) => ({
            ...styles, fontSize: '1.5rem',
            "@media(max-width: 1920px)": {
                fontSize: '1.3rem'
            },
            "@media(max-width: 1540px))": {
                fontSize: '1.1rem',
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        dropdownIndicator: (styles) => ({
            ...styles, color: '#E8339E', width: '40px'
        }),
        clearIndicator: (styles) => ({
            ...styles, display: 'none'
        })
    };

    useEffect(() => {

        function handleClickOutside(event) {
            if (collaboratorsRef.current && !collaboratorsRef.current.contains(event.target)) {
                setShowCollaboratorsSelect(false);
            }

        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [collaboratorsRef])

    const emptyCollaborator = () => {
        const col = []
        for (let i = 0; i < (4 - collaboratorsImages.length); i++) {
            col.push(<div className="collaboration" key={`empty${i}`}></div>)
        }
        return (col);
    }

    return (
        <MyProfileFirstSectionContainer>

            <div className="collaborationsContainer" ref={collaboratorsRef} >
                <div className="editCollaborations">
                    <div className="collaborations">
                        {collaboratorsImages.map((image, index) => {
                            return (
                                <div className="collaboration" key={`collaborator${index}`}>
                                    <img src={image ?? avatar} alt="collaborator" />
                                </div>
                            )
                        })}
                        {emptyCollaborator()}
                    </div>
                    <img src={edit} alt="edit" className="editIcon" onClick={() => {
                        setShowCollaboratorsSelect(!showCollaboratorsSelect)
                    }} />
                </div>

                {/* <p className="collaborationText"><FormattedMessage id="my_profile_first_section_text_1" defaultMessage="Aquí estarán tus colaboradores" /></p> */}

                {(showCollaboratorsSelect) && (<div className="selectContainer">
                    <p className="apply" onClick={sendCollaborators}><FormattedMessage id="create_collaboration_text_6" defaultMessage="Aplicar" /></p>
                    {(collaboratorsOptions) && (
                        <Select
                            value={selectedOptions}
                            options={collaboratorsOptions}
                            defaultValue={[collaboratorsOptions[0]]}
                            components={{ Option: IconCollaboratorOption }}
                            isMulti
                            onChange={(e) => {
                                setSelectedOptions(e)
                            }}
                            isOptionDisabled={() => selectedOptions.length >= 4}
                            styles={selectStyles}
                            placeholder={intl.formatMessage({ id: "collaborators" })}

                    //defaultValue={statusOptions[statusIndex]} 
                                    /*
                                    onChange={(event) => {
                                        setSecurityQuestion(event)
                                        const auxBody = { ...body }
                                        auxBody.attributes = {
                                            ...body.attributes,
                                            securityQuestion: event.name,
                                            preguntaSeguridad: event.nombre
                                        };
                                        setBody(auxBody)
                                    }} *//>)}

                </div>)}


            </div>
            <div className="rateEdit">
                <div className="rateContainer">
                    <div className="rate">

                        <p className="rateTitle"><FormattedMessage id="my_profile_first_section_text_2" defaultMessage="Foto / Video" /></p>

                        <div className="rateInformation">
                            <p className="rateDescription"><FormattedMessage id="my_profile_first_section_text_5" defaultMessage="Desde" /> </p>
                            <div>
                                {(!price) ? (<p className="addRate" onClick={() => { setShowModalPrices(true) }}><FormattedMessage id="my_profile_first_section_text_4" defaultMessage="Edita tu precio" /> /</p>) : (<p className="value">{currency.symbol} {formatNumber(price)} /</p>)}

                                <img src={gift} alt="gift" />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={edit} alt="edit" className="editIcon" onClick={() => { setShowModalPrices(true) }} />
            </div>

            {(showModalPrices) && (<ModalPrices setShowModalPrices={setShowModalPrices} />)}

        </MyProfileFirstSectionContainer>
    )
}