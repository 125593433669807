import { createContext, useEffect, useState } from "react";
import { InfluencerAPI } from "../../api/user/influencer/influencer";

export const InfluencerContext = createContext();

export default function InfluencerProvider({ children }) {
    
    const [influencersList, setInfluencersList] = useState([]);
    const [influencersFeaturedList, setInfluencersFeaturedList] = useState([]);
    const [influencersNewList, setInfluencersNewList] = useState([]);
    const [influencersSearchList, setInfluencersSearchList] = useState([]);

    const [companiesList, setCompaniesList] = useState([]);

    return (
        <InfluencerContext.Provider value={{
            influencersList,
            setInfluencersList,
            influencersFeaturedList,
            setInfluencersFeaturedList,
            influencersNewList,
            setInfluencersNewList,
            influencersSearchList,
            setInfluencersSearchList,
            companiesList,
            setCompaniesList
        }}>
            {children}
        </InfluencerContext.Provider>
    );
}