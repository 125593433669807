import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const axiosInstance = axios.create({
  baseURL: API_GATEWAY_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('fluincrToken');

    if (token) {
      config.headers.Authorization = `Bearer ` + token.replace("\"", "");
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
