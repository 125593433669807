import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const ContentDetailsCompanyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    .statusContainer{
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .notContent{
        height: 80px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.grey};
        margin: 5px;
        img{
            width: 30px;
        }
    }
    .content{
        height: 80px;
        width: 100px;
        margin: 5px;
        .video{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    .editButton{
        cursor: pointer;
        margin-left: .25em;
    }
`

export const UploadImage = styled.div`
    height: 80px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    position: relative;
    .uploadButton{
        background-color: #ffffff;
        border: none;
    }
    .uploadItem{
        cursor: ${props => props.status === 1 ? 'not-allowed' : 'pointer'};
        width: 20px;
    }
    .image-item{
       
        margin: 0;
        background-color: yellow;
        width: 100%;
        height: 100%;
        .photo{
            width: 100%;
            height: 100%;
            margin: 0; 
        }
        .removeButton{
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: transparent;
            img{
                width: 15px;
                cursor: pointer;
            }
        }
    }
   
`
export const Photo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
     width: 100px;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

`

export const Image = styled.div`
    height: 100%;
    width: 100%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
        position: relative;
        cursor: pointer;
        margin: auto .25em .25em auto;
        z-index: 10;
        img{
            width: 1.5em;
        }
    }
`
export const Status = styled.p`
    color: ${(props) => props.status !== 4 ? colors.girlBackground : colors.darkGrey};
    font-weight: 600;
    cursor: ${(props) => props.status !== 4 ? 'pointer' : 'auto'};
` 