import React from "react";
import { BannerContainer } from "./BannerStyled";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { colors } from "../../utils/styles/colors";
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LangContext } from "../../context/langContext";
import { UserContext2 } from "../../context/user/userContext";


export default function Banner() {

    const { userToken } = useContext(UserContext2);
    const { locale } = useContext(LangContext)

    return (
        <BannerContainer locale={locale}>
            <Carousel
                emulateTouch={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                renderThumbs={() => []}
                autoPlay={true}
                interval={5000}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = { marginLeft: 20, color: 'white', backgroundColor: "white", cursor: "pointer", padding: '0 5px', borderRadius: '50%', fontSize: '0.8rem' };
                    const style = isSelected
                        ? { ...defStyle, backgroundColor: `${colors.hightlight}`, color: `${colors.hightlight}` }
                        : { ...defStyle };
                    return (
                        <span style={style}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}>
                            {index}
                        </span>

                    );
                }}

            >
                <div className="card1">
                    <div className="textContainer">
                        <h2 className="title">
                            <FormattedMessage id="banner_title_1" defaultMessage="Encuentra un influencer para impulsar tu marca en las redes sociales" />
                        </h2>
                        {(!userToken) && (<Link to='/signup'>
                            <button className="signUp" style={{ marginTop: '1em' }}>
                                <FormattedMessage id="banner_button_text" defaultMessage="Regístrate" />
                            </button>
                        </Link>)}
                    </div>
                </div>
                <div className="card2">
                    <div className="textContainer">
                        <h2 className="title">
                            <FormattedMessage id="banner_title_2" defaultMessage="Grandes colaboraciones comienzan aquí en Fluincr" />
                        </h2>
                        {(!userToken) && (<Link to='/signup'>
                            <button className="signUp" style={{ marginTop: '1em' }}>
                                <FormattedMessage id="banner_button_text" defaultMessage="Regístrate" />
                            </button>
                        </Link>)}

                    </div>
                </div>
                <div className="card3">
                    <div className="textContainer">
                        <h2 className="title">
                            <FormattedMessage id="banner_title_3" defaultMessage="Únete a otras grandes empresas en Fluincr." />
                        </h2>
                        {(!userToken) && (<Link to='/signup'>
                            <button className="signUp" style={{ marginTop: '1em' }}>
                                <FormattedMessage id="banner_button_text" defaultMessage="Regístrate" />
                            </button>
                        </Link>)}
                    </div>
                </div>
            </Carousel>
        </BannerContainer>
    )
}