import styled from 'styled-components';
import { media } from './../../utils/styles/media';
import CentraleSans from './../../utils/styles/CentraleSansLight.otf';


export const BannerContainer = styled.div`

    @font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }
  
    .control-dots{
       margin:35px 0;
       span{
           margin: 5px;
       } 
    }

    div.card1, div.card2, div.card3{
        background-size: 100% 100%;
        //background-size: contain;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        //height: 85vh;
        //height: 70vh;
        height: 900px;
        display: flex;
        
        .textContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            padding-left: 5em;
            width: 48%;
            color: #ffffff;
            .title{
                font-family: 'Open Sans',  sans-serif;
                font-size: 5rem;
                font-weight: 700;
                color: #ffffff;
                line-height : 100px;
            }
            .text{
                font-family: 'Centrale', sans-serif;
                font-size: 2rem;
                padding: 40px 0;
                font-weight: lighter;
            }
            a{
                .signUp{
                    padding: 15px 40px;
                    font-family: 'Montserrat', sans-serif;
                    border-radius: 30px;
                    background-color: rgb(255,255,255,0);
                    border: 1px solid #ffffff;
                    font-size: 2rem;
                    cursor: pointer;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                }
            }
            
        }
        
    }
 
    .card1{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_1_desktop.png');
    }
    .card2{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_2_desktop.png');
    }
    .card3{
        background-image: url(
            ${props => props.locale === 'es-CO' ? 
            'https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_3_desktop.png' : 
            'https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_3_desktop_en.png'}
        );
    } 
      

/**-----------Responsive---------- */
${media.largeDesktop}{
    div.card1, div.card2, div.card3{
        //height: 80vh;
        height: 800px;
        .textContainer{
            padding-left: 5em;
            .title{
                font-size: 4rem;
                line-height : 80px;
            }
            .text{
                font-size: 1.6rem;
            }
            a{
                .signUp{
                    padding: 12px 35px 14px;
                    font-size: 1.8rem;
                }
            }
            
        }
    }
}
${media.specialScreen}{
    div.card1, div.card2, div.card3{
        //height: 75vh;
        height: 750px;
    }
}
${media.bigmacdesktop}{
    div.card1, div.card2, div.card3{
        //height: 75vh;
        height: 650px;
        .textContainer{
            padding-left: 70px;
            .title{
                font-size: 3.8rem;
                line-height: 70px;
            }
            .text{
                font-size: 1.5rem;
                padding: 25px 0;
            }
            a{
                .signUp{
                    padding: 10px 30px;
                    font-size: 1.5rem;
                }
            }  
        }
    }
}
${media.macdesktop}{
    div.card1, div.card2, div.card3{
        //height: 75vh;
        height: 600px;
        .textContainer{
            padding-left: 70px;
            .title{
                font-size: 3.5rem;
                line-height: 65px;
            }
            .text{
                font-size: 1.3rem;
                padding: 25px 0;
            }
            a{
                .signUp{
                    padding: 10px 30px;
                    font-size: 1.3rem;
                }
            }  
        }
    } 
}

${media.desktop}{
    div.card1, div.card2, div.card3{
        //height: 75vh;
        height: 520px;
        .textContainer{
            padding-left: 60px;
            .title{
                font-size: 3.4rem;
                font-size: 3.2rem;
                line-height : 54px;
            }
            .text{
                font-size: 1.5rem;
                font-size: 1.3rem;
                padding: 25px 0;
            }
            a{
                .signUp{
                    padding: 10px 30px;
                    font-size: 1.5rem;
                    font-size: 1.3rem;
                }
            }
            
        }
    } 
}

${media.smallDesktop}{
    div.card1, div.card2, div.card3{
        //height: 75vh;
        height: 480px;
        .textContainer{
            padding-left: 40px;
            .title{
                font-size: 3.1rem;
                font-size: 2.8rem;
                line-height : 55px;
            }
            .text{
                font-size: 1.4rem;
                font-size: 1.2rem;
                padding: 25px 0;
            }
            a{
                .signUp{
                    font-size: 1.4rem;
                    font-size: 1.2rem;
                }
            }
            
        }
    }

}

${media.laptop}{
    div.card1, div.card2, div.card3{
        justify-content:center;
        height: 90vh;
        .textContainer{
            width: 80vw;
            text-align: center; 
            align-items: center;
            justify-content: space-evenly;
            padding: 50px;
            .title{
                font-size: 4rem;
                line-height : 70px;
            }
            .text{
                width: 70%;
                padding: 0;
            }
        }
    }
    .card1{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_1_tablet.png');
    }
    .card2{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_2_tablet.png');
    }
    .card3{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_3_tablet.png');
    }

}

${media.tablet}{

}

${media.largeMobile} {
    div.card1, div.card2, div.card3{
        height: 90vh;
        .textContainer{
            width: 100%;
            .title{
                font-size: 2.6rem;
                line-height : 43px;
            }
            .text{
                width: 60%;
                padding: 0;
                font-size: 1rem;
            }
        }
    }
    .card1{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_1_mobile.png');
    }
    .card2{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_2_mobile.png');
    }
    .card3{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_3_mobile.png');
    }

}

${"@media(max-width: 370px)"} {
    div.card1, div.card2, div.card3{
        .textContainer{
            padding: 40px;
            .title{
                font-size: 2.5rem;  
            }
            .text{
                width: 60%;
                padding: 0;
                font-size: 1rem;
            }
        }
    }
    .card1{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_1_tablet.png');
    }
    .card2{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_2_tablet.png');
    }
    .card3{
        background-image: url('https://fluincrmultimedia.s3.amazonaws.com/files/banner/banner_3_tablet.png');
    }

}
`