import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const StepperWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
z-index: 1;
`;

export const Circle = styled.div`
width: 14px;
height: 14px;
border-radius: 50%;
margin-right: -1px;
background-color: ${(props) => (props.completed ? colors.hightlight : "#fff")};
border-color: ${(props) => (props.completed ? colors.hightlight : colors.grey)};
border-style: solid;
border-width: 2px;
`;

export const Line = styled.div`
flex: 1;
height: 7px;
border-width: 2px;
border-top-style: solid;
border-bottom-style: solid;
border-color: ${(props) => (props.completed ? colors.hightlight : colors.grey)};
`;