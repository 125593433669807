import React from "react";
import { NetworkIconContainer } from "./NetworkIconStyled";

export default function TikTokIcon() {
    return (
        <NetworkIconContainer>
            <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_203_2)">
                    <path d="M9.79998 17C7.19998 17 5.09998 19.1 5.09998 21.7C5.09998 23.4 5.99998 24.8 7.39998 25.6C6.89998 24.9 6.69998 24.1 6.69998 23.2C6.69998 20.6 8.79998 18.5 11.4 18.5C11.9 18.5 12.4 18.6 12.9 18.7V13.5C12.4 13.4 11.9 13.4 11.4 13.4C11.4 13.4 11.4 13.4 11.3 13.4V17.2C10.8 17.1 10.3 17 9.79998 17Z" /* fill="#F00044" */ className="rosa" />
                    <path d="M21.1 1.5H19.7C20.1 3.4 21.2 5.1 22.7 6.3C21.7 4.9 21.1 3.3 21.1 1.5Z" /* fill="#F00044" */ className="rosa" />
                    <path d="M29 9.39999C28.5 9.39999 28 9.29999 27.5 9.29999L19.6 10.3L11.3 31.3C10.8 31.4 10.3 31.4 9.79999 31.4C7.59999 31.4 5.49999 30.7 3.79999 29.4C3.99999 29.5 4.19999 29.7 4.39999 30C6.29999 31.8 8.69999 32.8 11.3 32.8C11.8 32.8 12.3 32.8 12.8 32.7C14.9 32.4 16.8 31.5 18.3 30C20.1 28.2 21.2 25.8 21.2 23.2L21 11.7C21.9 12.4 22.8 12.9 23.9 13.4C25.5 14.1 27.2 14.4 28.9 14.4" /* fill="#F00044" */ className="rosa" />
                    <path d="M4.4 16.3C6.2 14.5 8.7 13.5 11.3 13.5V12.1C10.8 12 10.3 12 9.8 12C7.2 12 4.7 13 2.9 14.8C1 16.6 0 19.1 0 21.6C0 24.2 1 26.6 2.9 28.4C3.2 28.7 3.5 29 3.8 29.2C2.3 27.5 1.6 25.3 1.5 23C1.5 20.6 2.6 18.1 4.4 16.3Z" /* fill="#08FFF9" */ className="magenta" />
                    <path d="M27.5 9.19998V7.79998C25.7 7.79998 24.1 7.19998 22.7 6.19998C23.9 7.79998 25.5 8.79998 27.5 9.19998Z"/*  fill="#08FFF9" */ className="magenta" />
                    <path d="M11.1 27.7C11.7 27.7 12.3 27.6 12.8 27.5C14.7 26.9 16 25.2 16 23.1V15.4V1.39999H19.7C19.6 0.899994 19.6 0.399994 19.5 -0.100006H14.4V13.9V21.6C14.4 23.6 13.1 25.4 11.2 26C10.7 26.2 10.1 26.3 9.49999 26.2C8.69999 26.2 7.99999 25.9 7.39999 25.6C8.19999 26.8 9.49999 27.7 11.1 27.7Z" /* fill="#08FFF9" */ className="magenta" />
                    <path d="M11.3 31.2C13.4 30.9 15.3 30 16.8 28.5C18.6 26.7 19.7 24.3 19.7 21.7V10.3C20.6 11 21.5 11.5 22.6 12C24.2 12.7 25.9 13 27.6 13V9.19999C25.7 8.79999 24 7.69999 22.8 6.19999C21.3 5.09999 20.2 3.39999 19.8 1.39999H16V15.4V23.1C16 25.1 14.7 26.9 12.8 27.5C12.3 27.7 11.7 27.8 11.1 27.7C9.6 27.6 8.2 26.8 7.4 25.6C6.1 24.8 5.1 23.4 5.1 21.7C5.1 19.1 7.2 17 9.8 17C10.3 17 10.8 17.1 11.3 17.2V13.4C8.7 13.4 6.3 14.4 4.4 16.2C2.6 18 1.5 20.5 1.5 23C1.5 25.3 2.3 27.4 3.8 29.2C5.5 30.5 7.6 31.2 9.8 31.2C10.3 31.3 10.8 31.3 11.3 31.2Z" fill="white" className="principal" />
                </g>
                <defs>
                    <clipPath id="clip0_203_2">
                        <rect width="29" height="32.8" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </NetworkIconContainer>
    )
}