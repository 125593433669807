import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";


export const ModalResetPasswordMessageContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .messageBody{
        background-color: #ffffff;
        border-radius: 15px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 50%;
        .close{
            width: 25px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        .title{
            margin-bottom: 20px;
        }
        .firstText{
            width: 80%;
            text-align: center;
        }
        .middleText{
            margin: 20px 0;
            width: 75%;
            text-align: center;
        }
        .lastText{
            width: 100%;
            text-align: center;
        }
        .link{
            color: ${colors.hightlight};
            text-decoration: underline;
            cursor: pointer;
        }
    }

    /**--------Responsive---------- */
    ${media.tablet}{
        .messageBody{
            width: 70%;
        }
    }

    ${media.largeMobile}{
        .messageBody{
            width: 85%;
        }
    }
`