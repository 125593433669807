import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';


const BASE_URL = API_GATEWAY_URL + '/fluincr-social-network';

export const InstagramAPI = {

    manualInsert: async (userId, link, followers) => {
        const response = await axiosInstance.post(`${BASE_URL}/instagram/manual/${userId}?link=${link}&followers=${followers}`);
        return response.data;
    },

    revoke: async (userId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/instagram/revoke/${userId}`);
        return response.data;
    }
};