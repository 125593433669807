import { useState, useContext, useEffect } from 'react'
import {useNavigate} from 'react-router-dom';
import '../../Bootstrap.css'
import './ProfileSocialNetworksStyled.css'

import tiktokMobileIcon from '../../utils/images/networks/tiktok2.png';
import facebookMobileIcon from '../../utils/images/facebook_color.svg';
import instagramMobileIcon from '../../utils/images/instagram_color.svg';
import youtubeMobileIcon from '../../utils/images/youtube_color.svg';

import YoutubeIcon from "../../utils/images/NetworkIcon/YoutubeIcon";
import FacebookIcon from "../../utils/images/NetworkIcon/FacebookIcon";
import InstagramIcon from "../../utils/images/NetworkIcon/InstagramIcon";
import TikTokIcon from "../../utils/images/NetworkIcon/TikTokIcon";
import close from './../../utils/images/close_circle_outline.svg';
import check from './../../utils/images/check_circle_darker.svg';
import arrowExternal from './../../utils/images/arrow_external.png';

import FacebookProfileLogin from './components/FacebookProfileLogin/FacebookProfileLogin';
import InstagramProfileLogin from './components/InstagramProfileLogin/InstagramProfileLogin';
import YouTubeProfileLogin from './components/YouTubeProfileLogin/YouTubeProfileLogin';
import TikTokProfileLogin from './components/TikTokProfileLogin/TikTokProfileLogin';

import { TikTokAPI } from '../../api/social-network/tiktok';
import { InstagramAPI } from '../../api/social-network/instagram';
import { UserContext2 } from '../../context/user/userContext';
import { SocialNetworkId } from '../../utils/constants/socialNetwork';
import { FacebookAPI } from '../../api/social-network/facebook';
import { YouTubeAPI } from '../../api/social-network/youtube';


const ProfileSocialNetworks = ({userSocialNetworksAccounts, setIsLoading = () => {}, canEdit = false}) => {

    const { userData, setUserData } = useContext(UserContext2);
    const navigate = useNavigate();

    const [instagramProfileLink, setInstagramProfileLink] = useState('');
    const [tiktokProfileLink, setTiktokProfileLink] = useState('');
    const [youtubeChannelUrl, setYouTubeChannelUrl] = useState('');
    const [facebookPageLink, setFacebookPageLink] = useState('');

    const [tiktokFollowers, setTiktokFollowers] = useState(undefined);
    const [youtubeSuscribers, setYoutubeSuscribers] = useState(undefined);
    const [facebookFollowers, setFacebookFollowers] = useState(undefined);
    const [instagramFollowers, setInstagramFollowers] = useState(undefined);

    useEffect(() => {
        if(userSocialNetworksAccounts){
            updateSocialNetworks(userSocialNetworksAccounts);
        }
    }, [userSocialNetworksAccounts])

    const revokeTiktok = () => {
        setIsLoading(true);
        TikTokAPI.revoke(userData?.id).then(() => {
            removeSocialNetworkAccount(SocialNetworkId.TIKTOK);
            setTiktokFollowers(undefined);
            setTiktokProfileLink('');
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const revokeYoutube = () => {
        setIsLoading(true);
        YouTubeAPI.revoke(userData?.id).then(() => {
            removeSocialNetworkAccount(SocialNetworkId.YOUTUBE);
            setYouTubeChannelUrl('');
            setYoutubeSuscribers(undefined);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const revokeFacebook = () => {
        setIsLoading(true);
        FacebookAPI.revoke(userData?.id).then(() => {
            removeSocialNetworkAccount(SocialNetworkId.FACEBOOK);
            setFacebookFollowers(undefined);
            setFacebookPageLink('');
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const revokeInstagramData = () => {
        setIsLoading(true);
        InstagramAPI.revoke(userData?.id).then(() => {
            removeSocialNetworkAccount(SocialNetworkId.INSTAGRAM);
            setInstagramFollowers(undefined);
            setInstagramProfileLink('');
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const updateSocialNetworks = (socialNetworkAccountList) => {
        setInstagramFollowers(undefined);
        setInstagramProfileLink('');
        setFacebookFollowers(undefined);
        setFacebookPageLink('');
        setTiktokFollowers(undefined);
        setTiktokProfileLink('');
        setYoutubeSuscribers(undefined);
        setYouTubeChannelUrl('');

        socialNetworkAccountList.forEach((account) => {
            if(account.social_network === SocialNetworkId.INSTAGRAM){
                setInstagramFollowers(account.followers);
                setInstagramProfileLink(account.link)
            } else if(account.social_network === SocialNetworkId.FACEBOOK){
                setFacebookFollowers(account.followers);
                setFacebookPageLink(account.link)
            } else if(account.social_network === SocialNetworkId.TIKTOK){
                setTiktokFollowers(account.followers);
                setTiktokProfileLink(account.link)
            } else if(account.social_network === SocialNetworkId.YOUTUBE){
                setYoutubeSuscribers(account.followers);
                setYouTubeChannelUrl(account.link)
            }
        });
    }
    
    const removeSocialNetworkAccount = (socialNetworkId) => {
        const socialNetworkAccounts = userData?.social_networks?.filter((account) => account.social_network !== socialNetworkId);
        setUserData({
            ...userData,
            social_networks: socialNetworkAccounts
        });
    }

    const formatFollowers = (followers) => {
        if (followers >= 1000000) {
            return (followers / 1000000).toFixed(1) + 'M'
        } else if (followers >= 1000) {
            return (followers / 1000).toFixed(1) + 'K'
        } else {
            return followers
        }
    }

    return (
        <div className="socialNetworksContainer d-flex flex-column w-100">
            <div className={`socialNetwork ${!instagramProfileLink ? 'disconnected' : ''} ${!instagramProfileLink && !canEdit ? 'd-none' : ''} instagram`}>
                {
                    (instagramProfileLink) ?
                        <>
                            <div className="socialNetworkConnected d-flex w-100 align-items-center">
                                <hr className="w-50" />
                                <div className="socialNetworkIcon">
                                    <a href={instagramProfileLink} target="_blank" rel="noopener noreferrer">
                                        <InstagramIcon />
                                    </a>
                                </div>
                                {(instagramFollowers) && <p className="followersCount">{formatFollowers(instagramFollowers)}</p>}
                                {canEdit && <img className="revokeButton cursor-pointer" src={close} onClick={revokeInstagramData}/>}
                                {!canEdit && <a href={instagramProfileLink} target="_blank" rel="noopener noreferrer"><img className="externalLink" src={arrowExternal} width="13" height="13"/></a>}
                            </div>
                            <div className="socialNetworkConnectedMobile d-none">
                                <div className="d-flex position-relative">
                                    <a href={instagramProfileLink} target="_blank" rel="noopener noreferrer">
                                        <img src={instagramMobileIcon} />
                                    </a>
                                    {canEdit && <img className="revokeButton cursor-pointer position-absolute ms-4 mb-4" src={close} width="14" height="14" onClick={revokeInstagramData}/>}
                                </div>
                                {(instagramFollowers) && <p className="followersCountMobile d-none">{formatFollowers(instagramFollowers)}</p>}
                            </div>
                        </>
                        :
                        <div className="socialNetworkDisconnected d-flex align-items-end flex-row-reverse w-100 h-50 text-white fw-bold">
                            <InstagramProfileLogin></InstagramProfileLogin>
                        </div>
                        
                }
            </div>
            <div className={`socialNetwork ${!facebookPageLink ? 'disconnected' : ''} ${!facebookPageLink && !canEdit ? 'd-none' : ''} facebook`}>
                {
                    (facebookPageLink) ?
                        <>
                            <div className="socialNetworkConnected d-flex w-100 align-items-center">
                                <hr className="w-50" />
                                <div className="socialNetworkIcon bg-transparent">
                                    <a href={facebookPageLink} target="_blank" rel="noopener noreferrer">
                                        <FacebookIcon />
                                    </a>
                                </div>
                                {(facebookFollowers) && <p className="followersCount m-0 ms-3">{formatFollowers(facebookFollowers)}</p>}
                                {canEdit && <img className="revokeButton cursor-pointer" src={close} onClick={revokeFacebook}/>}
                                {!canEdit && <a href={facebookPageLink} target="_blank" rel="noopener noreferrer"><img className="externalLink" src={arrowExternal} width="13" height="13"/></a>}
                            </div>
                            <div className="socialNetworkConnectedMobile d-none">
                                <div className="d-flex position-relative">
                                    <a href={facebookPageLink} target="_blank" rel="noopener noreferrer">
                                        <img src={facebookMobileIcon} />
                                    </a>
                                    {canEdit && <img className="revokeButton cursor-pointer position-absolute ms-4 mb-4" src={close} width="14" height="14" onClick={revokeFacebook}/>}
                                </div>
                                {(facebookFollowers) && <p className="followersCountMobile d-none">{formatFollowers(facebookFollowers)}</p>}
                            </div>
                        </>
                        :
                        <div className="socialNetworkDisconnected d-flex align-items-end flex-row-reverse w-100 h-50 text-white fw-bold">
                            <FacebookProfileLogin></FacebookProfileLogin>
                        </div>
                }
            </div>
            <div className={`socialNetwork ${!tiktokFollowers ? 'disconnected' : ''} ${!tiktokFollowers && !canEdit ? 'd-none' : ''}  tikTok`}>
                {
                    (tiktokFollowers) ?
                        <>
                            <div className="socialNetworkConnected d-flex w-100 align-items-center">
                                <hr className="w-50" />
                                <div className="socialNetworkIcon">
                                    <a href={tiktokProfileLink} target="_blank" rel="noopener noreferrer">
                                        <TikTokIcon />
                                    </a>
                                </div>
                                <p className="followersCount m-0 ms-3">{formatFollowers(tiktokFollowers)}</p>
                                {canEdit && <img className="revokeButton cursor-pointer" src={close} onClick={revokeTiktok}/>}
                                {!canEdit && <a href={tiktokProfileLink} target="_blank" rel="noopener noreferrer"><img className="externalLink" src={arrowExternal} width="13" height="13"/></a>}
                                {!canEdit && <img className="externalLink ms-2" src={check} width={15} height={15}/>}
                            </div>
                            <div className="socialNetworkConnectedMobile d-none">
                                <div className="d-flex position-relative rounded-circle mt-1" style={{ backgroundColor: "#000" }}>
                                    <a href={tiktokProfileLink} target="_blank" rel="noopener noreferrer">
                                        <img className="p-2" src={tiktokMobileIcon} width={36} height={37}/>
                                    </a>
                                    {canEdit && <img className="revokeButton cursor-pointer position-absolute ms-4 mb-4" src={close} width="14" height="14" onClick={revokeTiktok}/>}
                                </div>
                                <p className="followersCountMobile mt-2 d-none">{formatFollowers(tiktokFollowers)}</p>
                            </div>
                        </>
                        :
                        <div className="socialNetworkDisconnected d-flex align-items-end flex-row-reverse w-100 h-50 text-white fw-bold">
                            <TikTokProfileLogin></TikTokProfileLogin>
                        </div>
                }
            </div>
            <div className={`socialNetwork ${!youtubeSuscribers ? 'disconnected' : ''} ${!youtubeSuscribers && !canEdit ? 'd-none' : ''} youTube`}>
                {
                    (youtubeSuscribers) ?
                        <>
                            <div className="socialNetworkConnected d-flex w-100 align-items-center">
                                <hr className="w-50" />
                                <div className="socialNetworkIcon">
                                    <a href={youtubeChannelUrl} target="_blank" rel="noopener noreferrer">
                                        <YoutubeIcon />
                                    </a>
                                </div>
                                <p className="followersCount m-0 ms-3">{formatFollowers(youtubeSuscribers)}</p>
                                {canEdit && <img className="revokeButton cursor-pointer" src={close} onClick={revokeYoutube}/>}
                                {!canEdit && <a href={youtubeChannelUrl} target="_blank" rel="noopener noreferrer"><img className="externalLink" src={arrowExternal} width="13" height="13"/></a>}
                                {!canEdit && <img className="checkIcon ms-2" src={check} width={15} height={15}/>}
                            </div>
                            <div className="socialNetworkConnectedMobile d-none">
                                <div className="d-flex position-relative">
                                    <a href={youtubeChannelUrl} target="_blank" rel="noopener noreferrer">
                                        <img src={youtubeMobileIcon} />
                                    </a>
                                    {canEdit && <img className="revokeButton cursor-pointer position-absolute ms-4 mb-4" src={close} width="14" height="14" onClick={revokeYoutube}/>}
                                </div>
                                <p className="followersCountMobile d-none">{formatFollowers(youtubeSuscribers)}</p>
                            </div>
                        </>
                        :
                        <div className="socialNetworkDisconnected d-flex align-items-end flex-row-reverse w-100 h-50 text-white fw-bold">
                            <YouTubeProfileLogin></YouTubeProfileLogin>
                        </div>
                }
            </div>
        </div>
    )
}

export default ProfileSocialNetworks