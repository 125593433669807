import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
  Switch,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { StaticDataContext } from "../../context/staticDataContext";
import { FiltersContext } from "../../context/filtersContext";
import { colors } from "../../utils/styles/colors";
import { PinkSwitch } from "../../components/SwitchPink/SwitchPink";
import { LangContext } from "../../context/langContext";
import { InfluencerContext } from "../../context/user/influencerContext";

const FilterMenuCompanies = () => {
  const { locale } = useContext(LangContext);
  const { companiesList } = useContext(InfluencerContext);
  const {
    categorySelectList,
    countrySelectList,
    languageSelectList,
  } = useContext(StaticDataContext);
  const {
    categoryFilter,
    setCategoryFilter,
    verifiedFilter,
    setVerifiedFilter,
    featuredFilter,
    setFeaturedFilter,
    countryFilter,
    setCountryFilter,
    languageFilter,
    setLanguageFilter,
    ratingFilter,
    setRatingFilter,
    favoriteFilter,
    setFavoriteFilter,
  } = useContext(FiltersContext);

  const [openOptions, setOpenOptions] = useState(new Set());

  const [categorySelectListAux, setCategorySelectListAux] = useState([]);
  const [countrySelectListAux, setCountrySelectListAux] = useState([]);
  const [languageSelectListAux, setLanguageSelectListAux] = useState([]);

  useEffect(() => {
    setCategorySelectListAux(categorySelectList);
    setCountrySelectListAux(
      countrySelectList.filter((item) =>
        companiesList?.some(
          (company) => company?.address?.country_id === item.value
        )
      )
    );
    setLanguageSelectListAux(
      languageSelectList.filter((item) =>
        companiesList?.some((influencer) =>
          influencer.languages.includes(item.value)
        )
      )
    );
  }, [companiesList]);

  const handleClick = (filterName) => {
    const openOptionsAux = new Set(openOptions);
    if (openOptionsAux.has(filterName)) {
      openOptionsAux.delete(filterName);
    } else {
      openOptionsAux.add(filterName);
    }
    setOpenOptions(openOptionsAux);
  };

  const handleOptionsFilter = (value, filterList, setFilterList) => {
    if (filterList.includes(value)) {
      setFilterList(filterList.filter((item) => item !== value));
    } else {
      setFilterList([...filterList, value]);
    }
  };

  const handleSwitchFilter = (value, filter, setFilter) => {
    setFilter(value);
  };

  const filtros = [
    {
      title: "Category",
      titulo: "Categoría",
      name: "category",
      type: "options",
      list: categorySelectListAux,
      filterList: categoryFilter,
      setFilterList: setCategoryFilter,
    },
    {
      title: "Language",
      titulo: "Idioma",
      name: "language",
      type: "options",
      list: languageSelectListAux,
      filterList: languageFilter,
      setFilterList: setLanguageFilter,
    },
    {
      title: "Country",
      titulo: "País",
      name: "country",
      type: "options",
      list: countrySelectListAux,
      filterList: countryFilter,
      setFilterList: setCountryFilter,
    },
    {
      title: "Verified",
      titulo: "Verificado",
      name: "verified",
      type: "switch",
      filter: verifiedFilter,
      setFilter: setVerifiedFilter,
    },

    {
      title: "Featured",
      titulo: "Destacados",
      name: "featured",
      type: "switch",
      filter: featuredFilter,
      setFilter: setFeaturedFilter,
    },
    {
      title: "My likes",
      titulo: "Me gustan",
      name: "favorites",
      type: "switch",
      filter: favoriteFilter,
      setFilter: setFavoriteFilter
    },
    {
      title: "Rating",
      titulo: "Calificación",
      type: "rating",
      filter: ratingFilter,
      setFilter: setRatingFilter,
    },
  ];

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {filtros.map((filtro) => (
        <React.Fragment key={filtro.name}>
          <ListItemButton
            sx={{ py: '.375em' }}
            onClick={
              filtro.type === "options"
                ? () => handleClick(filtro.name)
                : undefined
            }
          >
            <ListItemText
              primary={locale === "es-CO" ? filtro.titulo : filtro.title}
              primaryTypographyProps={{
                style: {
                  fontFamily: "'Open Sans', sans serif", // Cambia el tipo de fuente aquí
                  fontSize: "1.1em",
                  ":hover": {
                    color: colors.hightlight,
                  }
                },
              }}
            />
            {filtro.type === "options" ? (
              openOptions.has(filtro.name) ? (
                <ExpandLess sx={{ color: colors.hightlight }} />
              ) : (
                <ExpandMore sx={{ color: colors.hightlight }} />
              )
            ) : null}
            {filtro.type === "switch" && (
              <ListItemIcon>
                <PinkSwitch
                  edge="end"
                  checked={filtro.filter}
                  onChange={(event) =>
                    handleSwitchFilter(
                      event.target.checked,
                      filtro.filter,
                      filtro.setFilter
                    )
                  }
                />
              </ListItemIcon>
            )}

            {filtro.type === "rating" && (
              <Rating
                style={{ color: "#E8339E" }}
                name="simple-controlled"
                value={filtro.filter}
                onChange={(event, newValue) => {
                  filtro.setFilter(newValue);
                }}
              />
            )}
          </ListItemButton>
          {filtro.type === "options" && (
            <Collapse
              in={openOptions.has(filtro.name)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {filtro.list.map((item) => (
                  <ListItemButton
                    key={item.value}
                    sx={{ pl: 4, py: 0 }}
                    onClick={() =>
                      handleOptionsFilter(
                        item.value,
                        filtro.filterList,
                        filtro.setFilterList
                      )
                    }
                  >
                    <ListItemIcon sx={{ mr: '.5em', minWidth: 'auto' }}>
                      <Checkbox
                        edge="start"
                        checked={filtro.filterList.includes(item.value)}
                        style={{ paddingTop: ".25rem", paddingBottom: ".25rem" }}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": item.value }}
                        onChange={() =>
                          handleOptionsFilter(
                            item.value,
                            filtro.filterList,
                            filtro.setFilterList
                          )
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: colors.hightlight,
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={item.value}
                      primary={locale === "es-CO" ? item.nombre : item.name}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default FilterMenuCompanies;
