import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const SignUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  min-width:220px;
  form {
    max-width: 500px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    overflow-y: auto;
    min-width:220px;
    display: flex;
    flex-direction: column; /* Cambiar la dirección de flexión a columna */
    align-items: stretch;
    //min-height: 950px;
    max-height: 95%;
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      //border:  #BCBCBC 10px solid;
      //border-top: 25px solid transparent;
      //border-bottom: 25px solid transparent;
    }
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        font-size: 2.1rem;
        color: ${colors.darkGrey};
      }
      .close {
        width: 25px;
      }
    }
    .message {
      margin-bottom: 10px;
      color: ${colors.darkGrey};
    }
    .containerGrid, .containerGridGroup{
      display: grid;
      width: 100%;
      grid-gap: 8px;
      /* margin: 5px 0; */
      /* align-items: center; */
    }
    .items-center{
      align-items: center;
    }
    .flex-end{
      display: flex;
      justify-content: end;
    }
    .items-end{
      align-items: end;
    }
    .terms-conditions{
      margin-top: 8px;
    }
    .containerFlex{
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
    }
    .col-3{
      grid-template-columns: 1fr 1fr 1fr;
    }
    .col-2{
      grid-template-columns: 1fr 1fr;
    }
    .col-1{
      grid-template-columns: 1fr;
    }
    .span-2{
      grid-column: span 2;
    }
    .my-1{
      margin: 10px 0;
    }
    .mb-1{
      margin-bottom: 10px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      margin-bottom: 15px;
    }
    .registerButton {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
      .error {
        font-size: 0.7rem;
        color: red;
        padding-bottom: 10px;
      }
    }
    .language{
      margin-top:5px
    }
    .checklegalName, .checklegalNameCompany {
      height: 18px;
      width: 18px;
      margin-left:-19px;
    }

    .password{
      outline: none;
      border: none;
      width:100%;
      display:flex;
      gap:5px;
      font-size: 0.9rem;
    }

    .generalButton {
      padding: 10px 20px;
      background-color: ${colors.grey};
      color: #ffffff;
      border-radius: 10px;
      border: none;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
    .active {
      background-color: ${colors.hightlight};
    }
    .name {
      display: flex;
      gap:5px;
    }
    .name2 {
      display: flex;
      gap:5px;
      margin-bottom:5px;
    }
    .cityAndCountry{
      display: flex;
      gap:5px;
      width:100%;
    }
    .city{
      width:49%;
    }
    .countrySelect__menu{
      z-index: 101;
    }
    .DatePicker__calendarContainer{
      z-index: 110;
    }
    .right{
      width:49%;
    }
    .right2{
      width:49%;
      margin-top:6px;
      margin: 5px 0px;
    }
    .left {
      width:49.5%;
    }
    /* .phone{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .inputContainer{
                width: 82%;
            }
        } */

    .inputContainer {
      display:flex;
      width:100%;
      .icon {
        padding-right: 10px;
        width: 35px;

      }
    }

    .label {
      font-size: 10px;
      color:${colors.grey};
    }

    .typeId{
      min-height: 45px;
    }

    .labelCompany {
      font-size: 10px;
      color:${colors.grey};
    }

    .companyNameContainer {
      margin-top: 10px;
    }
    .error {
      color: red;
      font-size: 0.7rem;
      padding-bottom: 5px;
    }
    .influencerIdContainer,
    .companyIdContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* #react-select-2-listbox {
        z-index: 150;
      }
      #react-select-3-listbox {
        z-index: 150;
      }
      #react-select-4-listbox {
        z-index: 150;
      } */
    }

    .influencerIdContainer1,
    .companyIdContainer1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* #react-select-2-listbox {
        z-index: 150;
      }
      #react-select-3-listbox {
        z-index: 150;
      }
      #react-select-4-listbox {
        z-index: 150;
      } */
    }

    .email,
    .phone,
    .companyName,
    .legalName,
    .legalNameCompany,
    .mailingAdress {
      width: 100%;
      outline: none;
      border: none;
      font-size: 0.9rem;
      ::placeholder {
        font-size: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: rgba(112, 112, 112, 0.51);
      }
    }

    .influencerId,
    .influencerPronoun {
      padding: 10px;
      border-radius: 10px;
      outline: none;
      border: 1px solid ${colors.grey};
      margin: 5px 0;
      width:100%;
      font-size: 0.9rem;
      ::placeholder {
        font-size: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: rgba(112, 112, 112, 0.51);
      }
    }

    .influencerId2 {
      padding: 8px !important;
      border-radius: 10px;
      outline: none;
      border: 1px solid ${colors.grey};
      margin: 5px 0;
      font-size: 0.9rem;
      ::placeholder {
        font-size: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: rgba(112, 112, 112, 0.51);
      }
    }
    span{
      font-size:0.695rem;
    }

    .birthdayTitle {
      //padding-top: 5px;
      color: ${colors.darkGrey};
      /* margin-bottom: 10px; */
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      font-size: 0.9rem;
    }
    .labelTitle{
      color: ${colors.darkGrey};
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      font-size: 1rem;
    }
    .nameTitle {
      color: ${colors.darkGrey};
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      font-size: 0.9rem;
      /* margin-bottom:5px; */
    }
    .nameTitleCompany {
      color: ${colors.darkGrey};
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      font-size: 0.9rem;
    }
    .date {
      width: 100%;

      .DatePicker {
        width: 100%;
        z-index: 0 !important;
        /* margin-top:5px; */
        .inputPicker {
          font-size: 1rem;
          height: 45px;
          padding: 10px !important;
          ::placeholder {
            font-size: 1rem;
            font-family: "Open Sans", sans-serif;
            font-weight: bold;
            color: rgba(112, 112, 112, 0.51);
          }
        }
      }
    }
    .genderTitle,
    .relationshipTitle {
      /* padding-top: 4px; */
      color: ${colors.darkGrey};
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      font-size: 1rem;
    }
    .genders {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
      color: ${colors.greyLetter};
      gap: 10px;
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      .gender {
        padding: 11px 10px;
        border-radius: 10px;
        outline: none;
        border: 1px solid ${colors.grey};
        display: flex;
        justify-content: space-between;
        align-items: center;
        //font-size: 0.8rem;
        input[type="radio"] {
          accent-color: ${colors.hightlight};
        }
      }
    }
    .relationships {
      display: flex;
      justify-content: space-between;
      color: ${colors.greyLetter};
      font-size: 0.9rem;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      color: rgba(112, 112, 112, 0.51);
      .relationship {
        padding: 11px 10px;
        border-radius: 10px;
        outline: none;
        border: 1px solid ${colors.grey};
        margin: 5px 0;
        width: 32%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //font-size: 0.8rem;
        input[type="radio"] {
          accent-color: ${colors.hightlight};
        }
      }
    }
    .accept {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
      /* .inputAccept{
                margin-right: 5px;
                accent-color: ${colors.hightlight};
                margin-top: 2px;
            } */
      /* input[type='checkbox'] {
                -moz-appearance:none;
                -webkit-appearance:none;
                -o-appearance:none;
                outline: none;
                content: none;
                margin: 0;
                padding: 0;	
            } 

            input[type='checkbox']:before {
                font-family: "FontAwesome";
                content: "\f00c";
                font-size: 10px;
                color: transparent !important;
                background: #ffffff;
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10px;
                height: 10px;
                border:0.7px solid ${colors.grey};
                border-radius: 3px;
                margin-right: 3px;
                margin-top: 2px;
            }

            input[type='checkbox']:checked:before {
                color: ${colors.hightlight} !important;
            } */

      input[type="checkbox"] {
        margin-right: 8px;
        margin-left: 4px;
        margin-top: 4px; 
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari y Chrome */
      }
      input[type="checkbox"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        outline: none;
        content: none;
      }

      input[type="checkbox"]:before {
        font-family: "FontAwesome";
        content: "\f00c";
        font-size: 10px;
        color: transparent !important;
        background: #ffffff;
        display: block;
        width: 10px;
        height: 10px;
        border: 0.7px solid ${colors.grey};
        border-radius: 2px;
        //margin-right: 4px;
      }

      input[type="checkbox"]:checked:before {
        color: ${colors.hightlight} !important;
      }

      .acceptText {
        font-size: 0.69rem;
        color: ${colors.grey};
        a {
          //color: ${colors.darkGrey};
          color: ${colors.grey};
          text-decoration: underline;
        }
      }
    }

    .inputContainer2 {
      font-size: 12px;
      display:flex;
      flex:1;
      margin-right:5px;
      margin-top:-23px;
      margin-bottom:5px;
      justify-content:flex-end;
      color: ${colors.grey};
      gap:2px;
    }

    .inputContainer2, .inputContainer2Company {
      display: flex;
      align-items: flex-start;
      /* .inputAccept{
                margin-right: 5px;
                accent-color: ${colors.hightlight};
            } */
      /* input[type='checkbox'] {
                -moz-appearance:none;
                -webkit-appearance:none;
                -o-appearance:none;
                outline: none;
                content: none;
                margin: 0;
                padding: 0;	
                margin-top:1.5px;
            } 

            input[type='checkbox']:before {
                font-family: "FontAwesome";
                content: "\f00c";
                font-size: 10px;
                color: transparent !important;
                background: #ffffff;
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10px;
                height: 10px;
                border:0.7px solid ${colors.grey};
                border-radius: 3px;
                margin-right: 3px;
                margin-top:1.5px;
            }

            input[type='checkbox']:checked:before {
                color: ${colors.hightlight} !important;
            } */

      input[type="checkbox"] {
        margin-right: 8px;
        margin-left: 4px;
        margin-top:1.3px;
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari y Chrome */
      }
      input[type="checkbox"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        outline: none;
        content: none;
      }

      input[type="checkbox"]:before {
        font-family: "FontAwesome";
        content: "\f00c";
        font-size: 10px;
        color: transparent !important;
        background: #ffffff;
        display: block;
        width: 10px;
        height: 10px;
        border: 0.7px solid ${colors.grey};
        border-radius: 2px;
        //margin-right: 4px;
        margin-top:1.5px;
      }

      input[type="checkbox"]:checked:before {
        color: ${colors.hightlight} !important;
      }

      .acceptText {
        font-size: 0.69rem;
        color: ${colors.grey};
        a {
          //color: ${colors.darkGrey};
          color: ${colors.grey};
          text-decoration: underline;
        }
      }
    }
    
    .inputContainer2Company {
      font-size: 12px;
      display:flex;
      flex:1;
      margin-top:-20px;
      margin-bottom:7px;
      margin-right:2px;
      justify-content:flex-end;
      color: ${colors.grey};
      gap:5px
    }

    /* .inputAccept {
      color: rgb(232, 51, 158) !important;
    } */

  /**-----------Responsive----------- */
  /* Media Query para pantallas grandes de escritorio */
  @media (min-width: 1600px) {
    form {
      width: 500px;
      /* Otros estilos específicos para pantallas grandes de escritorio */
    }
  }

  /* Media Query para pantallas de escritorio */
  @media (min-width: 1200px) {
    /* Estilos personalizados para pantallas de escritorio */
  }

  /* Media Query para tabletas y pantallas más pequeñas */
  @media (max-width: 1024px) {
    form {
      /* Estilos para tabletas */
    }
    .buttons {
      /* Estilos personalizados para tabletas */
    }
  }

  /* Media Query para dispositivos móviles */
  @media (max-width: 768px) {
    form {
      /* Estilos para dispositivos móviles */
    }
    .buttons {
      /* Estilos personalizados para dispositivos móviles */
    }
    .generalButton {
      /* Estilos personalizados para dispositivos móviles */
    }
  }

  @media (max-width: 480px) {
    /* Estilos para dispositivos móviles (ejemplo) */
    form {
      padding: 10px;
    }
    .generalButton {
      width: 100%;
    }
  }

  ${media.smallMobile} {
    .col-2, .col-3{
      grid-template-columns: 1fr;
    }
    .span-2{
      grid-column: span 1;
    }
    .flex-end{
      justify-content: start;
    }
    .containerFlex{
      flex-direction: column;
    }
    .containerGridGroup{
      grid-gap: 3px;
    }
    .first-mobile{
      order: 0;
    }
    .second-mobile{
      order: 1;
    }
    .genders {
      grid-template-columns: 1fr;
    }
    form {

      .email,
      .phone,
      .companyName,
      .legalName,
      .legalNameCompany,
      .mailingAdress {
        font-size: 0.9rem;
        ::placeholder {
          font-size: 0.9rem;
        }
      }
      .influencerId,
      .influencerPronoun {
        font-size: 0.9rem;
        ::placeholder {
          font-size: 0.9rem;
        }
      }
      .date {
        .DatePicker {
          .inputPicker {
            position:relative;
            /* z-index:1; */
            font-size: 0.9rem;
            ::placeholder {
              font-size: 0.9rem;
            }
          }
        }
      }
      .genderTitle,
      .relationshipTitle {
        font-size: 0.9rem;
      }
      .genders,
      .relationships {
        .gender,
        .relationship {
          font-size: 0.9rem;
        }
      }
    }
  }

  ${"@media(max-width: 385px)"} {
    form {
      width:350px;
      .genders,
      .relationships {
        .gender,
        .relationship {
          font-size: 0.7rem;
        }
      }
    }
  }
`;

