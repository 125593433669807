import { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { AgeRangeAPI } from "../api/static-data/ageRange";
import { CategoryAPI } from "../api/static-data/category";
import { CountryAPI } from "../api/static-data/country";
import { FluincrSocialNetworkAPI } from "../api/static-data/fluincrSocialNetwork";
import { LanguageAPI } from "../api/static-data/language";
import { LegalTextAPI } from "../api/static-data/legalText";
import { SecurityQuestionAPI } from "../api/static-data/securityQuestion";
import { StaticPageAPI } from "../api/static-data/staticPage";
import { GenderAPI } from "../api/static-data/gender";
import { IdentificationTypeAPI } from "../api/static-data/identificationType";
import { LangContext } from "./langContext";
import { ServiceAPI } from "../api/product-and-service/service";
import { FluincrSocialNetworksAccountsAPI } from "../api/static-data/fluincrSocialNetworksAccounts";

export const StaticDataContext = createContext();

export default function StaticDataProvider({ children }) {

    const {locale} = useContext(LangContext);

    const [ageRangeList, setAgeRangeList] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [categorySelectList, setCategorySelectList] = useState([]);

    const [countryList, setCountryList] = useState([]);
    const [countrySelectList, setCountrySelectList] = useState([]);
    const [indicativesSelectList, setIndicativesSelectList] = useState([]);

    const [genderList, setGenderList] = useState([]);
    const [genderSelectList, setGenderSelectList] = useState([]);

    const [identificationTypeList, setIdentificationTypeList] = useState([]);
    const [identificationTypeSelectList, setIdentificationTypeSelectList] = useState([]);
    const [identificationTypeSelectCompanyList, setIdentificationTypeSelectCompanyList] = useState([]);

    const [languageList, setLanguageList] = useState([]);
    const [languageSelectList, setLanguageSelectList] = useState([]);
    const [languagePrimarySelectList, setLanguagePrimarySelectList] = useState([]);
    
    const [fluincrSocialNetworkList, setFluincrSocialNetworkList] = useState([]);
    const [fluincrSocialNetworkOptions, setFluincrSocialNetworkOptions] = useState([]);

    const [fluincrSocialNetworkAccountsList, setFluincrSocialNetworkAccountsList] = useState([]);

    const [legalTextList, setLegalTextList] = useState([]);
    const [legalTextsEspañol, setLegalTextsEspañol] = useState([]);
    const [legalTextsEnglish, setLegalTextsEnglish] = useState([]);

    const [securityQuestionList, setSecurityQuestionList] = useState([]);
    const [securityQuestionSelectList, setSecurityQuestionSelectList] = useState([]);

    const [staticPageList, setStaticPageList] = useState([]);
    const [staticPagesEspañol, setStaticPagesEspañol] = useState([]);
    const [staticPagesEnglish, setStaticPagesEnglish] = useState([]);

    const [fluincrServicesList, setFluincrServicesList] = useState([]);
    const [fluincrServicesSelectList, setFluincrServicesSelectList] = useState([]);


    useEffect(() => {
        StaticPageAPI.getStaticPageList().then((response) => {
            const staticPagesEspañol = response.filter(staticPage => staticPage.language === 'SPANISH');
            const staticPagesEnglish = response.filter(staticPage => staticPage.language === 'ENGLISH');
            
            setStaticPageList(response);
            setStaticPagesEspañol(staticPagesEspañol);
            setStaticPagesEnglish(staticPagesEnglish);
        });

        AgeRangeAPI.getAgeRangeList().then((response) => {
            setAgeRangeList(response);
        });

        CategoryAPI.getCategoryList().then((response) => {
            setCategoryList(response);
        });

        CountryAPI.getCountryList().then((response) => {
            setCountryList(response);
        });

        GenderAPI.getGenderList().then((response) => {
            setGenderList(response);
        });

        IdentificationTypeAPI.getIdentificationTypeList().then((response) => {
            setIdentificationTypeList(response);
        });

        FluincrSocialNetworkAPI.getFluincrSocialNetworkList().then((response) => {
            setFluincrSocialNetworkList(response);
        });

        FluincrSocialNetworksAccountsAPI.getFluincrSocialNetworksAccountsList().then((response) => {
            setFluincrSocialNetworkAccountsList(response);
        });

        LanguageAPI.getLanguageList().then((response) => {
            setLanguageList(response);
        });

        LegalTextAPI.getLegalTextList().then((response) => {
            const legalTextsEspañol = response.filter(legalText => legalText.language === 'SPANISH');
            const legalTextsEnglish = response.filter(legalText => legalText.language === 'ENGLISH');

            setLegalTextList(response);
            setLegalTextsEspañol(legalTextsEspañol);
            setLegalTextsEnglish(legalTextsEnglish);
        });

        SecurityQuestionAPI.getSecurityQuestionList().then((response) => {
            setSecurityQuestionList(response);
        });

        ServiceAPI.getServices().then((response) => {
            setFluincrServicesList(response);
        });
    }, []);

    useEffect(() => {
        setCategorySelectList(
            categoryList.map((category) => (
                { 
                    value: category.id, 
                    label: (locale === 'es-CO') ? category.nombre : category.name,
                    name: category.name,
                    nombre: category.nombre,
                    image: category.image
                }
        )));
    }, [locale, categoryList]);

    useEffect(() => {
        const orderCountryList = countryList.sort((a, b) => 
            (locale === "es-CO") ? 
            a.nombre.localeCompare(b.nombre) : 
            a.name.localeCompare(b.name)
        );

        setCountrySelectList(
            orderCountryList.map((country) => (
                { 
                    value: country.id, 
                    label: (locale === 'es-CO') ? country.nombre : country.name,
                    name: country.name,
                    nombre: country.nombre,
                    image: country.image,
                    flag: country.image
                }
            ))
        );

        setIndicativesSelectList(
            orderCountryList.map((country) => (
                { 
                    value: country.indicative,
                    label: country.indicative, 
                    image: country.image,
                    flag: country.image
                }
            ))
        );
    }, [locale, countryList]);

    useEffect(() => {
        setGenderSelectList(
            genderList.map((gender) => (
                {
                    ...gender,
                    value: gender.id,
                    label: (locale === 'es-CO') ? gender.nombre : gender.name,
                    name: gender.name,
                    nombre: gender.nombre
                }
            ))
        );
    }, [locale, genderList]);

    useEffect(() => {
        setIdentificationTypeSelectList(
            identificationTypeList.map((identificationType) => (
                {
                    value: identificationType.id,
                    label: (locale === 'es-CO') ? identificationType.nombre : identificationType.name,
                    name: identificationType.name,
                    nombre: identificationType.nombre
                }
            ))
        );

        setIdentificationTypeSelectCompanyList(
            identificationTypeList
                .filter(identificationType => identificationType.id !== 1)
                .map((identificationType) => (
                    {
                        value: identificationType.id,
                        label: (locale === 'es-CO') ? identificationType.nombre : identificationType.name,
                        name: identificationType.name,
                        nombre: identificationType.nombre
                    }
                ))
        );
    }, [locale, identificationTypeList]);

    useEffect(() => {
        setFluincrSocialNetworkOptions(
            fluincrSocialNetworkList.map((socialNetwork) => (
                {
                    value: socialNetwork.id,
                    label: socialNetwork.name,
                    image: socialNetwork.image
                }
            ))
        );

    }, [fluincrSocialNetworkList]);

    useEffect(() => {
        const orderedLanguageList = languageList.sort((a, b) => 
            (locale === "es-CO") ? 
            a.nombre.localeCompare(b.nombre) : 
            a.name.localeCompare(b.name)
        );

        setLanguageSelectList(
            orderedLanguageList.map((language) => (
                {
                    value: language.id,
                    label: (locale === 'es-CO') ? language.nombre : language.name,
                    name: language.name,
                    nombre: language.nombre,
                    convention: language.convention
                }
            ))
        );

        setLanguagePrimarySelectList(
            orderedLanguageList
                .filter(language => language.is_translatable)
                .map((language) => (
                    {
                        value: language.id,
                        label: (locale === 'es-CO') ? language.nombre : language.name,
                        name: language.name,
                        nombre: language.nombre,
                        convention: language.convention
                    }
                ))
        );
    }, [locale, languageList]);

    useEffect(() => {
        setFluincrServicesSelectList(
            fluincrServicesList.map((service) => (
                {
                    ...service,
                    label: (locale === 'es-CO') ? service.nombre : service.name
                }
            ))
        );
    }, [locale, fluincrServicesList]);

    useEffect(() => {
        setSecurityQuestionSelectList(
            securityQuestionList.map((securityQuestion) => (
                {
                    value: securityQuestion.id,
                    label: (locale === 'es-CO') ? securityQuestion.pregunta : securityQuestion.question
                }
            ))
        );
    }, [locale, securityQuestionList]);

    return (
        <StaticDataContext.Provider value={{ 
            ageRangeList, 
            categoryList,
            categorySelectList,
            countryList,
            countrySelectList,
            indicativesSelectList,
            genderList,
            genderSelectList,
            identificationTypeList,
            identificationTypeSelectList,
            identificationTypeSelectCompanyList,
            languageList, 
            languageSelectList,
            languagePrimarySelectList,
            fluincrSocialNetworkList,
            fluincrSocialNetworkAccountsList,
            fluincrSocialNetworkOptions,
            legalTextList,
            legalTextsEspañol,
            legalTextsEnglish,
            securityQuestionList,
            securityQuestionSelectList,
            staticPageList,
            staticPagesEspañol,
            staticPagesEnglish,
            fluincrServicesList, 
            fluincrServicesSelectList
         }}>
            {children}
        </StaticDataContext.Provider >
    )
};