import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";
import CentraleSans from './../../utils/styles/CentraleSansLight.otf';

export const MyCartContainer = styled.div`
@font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }

    padding: 0 50px 50px;
    .goBack{
        display: flex;
        align-items: center;
        width: 120px;
        cursor: pointer;
        margin-bottom: 10px;
        .backIcon{
            margin-right: 10px;
            width: 18px;
        }
    }
    .cartBody{
        color: ${colors.black};
        display: flex;
        justify-content: space-between;
        margin: 0 30px;
        .cartInformation{
            width: 60%;
            .cartTitle{
                font-size: 1.7rem;
                height: 50px;
                color: ${colors.greyInput};
                font-weight: 700;
            }
            .influencerData{
                display: flex;
                justify-content: space-between;
                border: 1px solid ${colors.grey};
                border-radius: 10px;
                padding: 15px;
                margin-bottom: 20px;
                font-size: 0.9rem;
                font-weight: 600;
                .address{
                    text-transform: capitalize;
                    .addressTitle{
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                }
                .paymentMethod{
                    .paymentTitle{
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .method{
                        display: flex;
                        align-items: center;
                        .cardIcon{
                            width: 25px;
                            margin-right: 10px;
                        }
                        .iconGift{
                            width: 20px;
                        }
                    }
                }
                .promotionalCode{
                    .codeTitle{
                        font-weight: 700;
                        //margin-bottom: 5px;
                    }
                    .codeInput{
                        padding: 8px 10px;
                        border: 1px solid ${colors.grey};
                        border-radius: 10px;
                        outline: none;
                        margin-right: 4px;
                        margin-top: 5px;
                    }
                    .applyButton{
                        background-color: ${colors.blue};
                        color: #ffffff;
                        border: none;
                        border-radius: 10px;
                        padding: 10px 15px;
                        cursor: pointer;
                        margin-top: 5px;
                        font-weight: bold;
                    }
                }
            }
            .cartItems{
                .item{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    .collaboration{
                        display: flex;
                        margin-bottom: 10px;
                        align-items: flex-start;
                        .collaborationDetails{
                            margin-left: 10px;
                            tr{
                                
                                th{
                                    padding-right: 15px;
                                    font-size: 1.2rem;
                                    color: ${colors.text};
                                    text-align: left;
                                }
                                td{
                                    font-size: 0.9rem;
                                    padding-right: 5px;
                                }
                                .firstColumn:first-child{
                                    width: 200px;
                                }
                                .freeProduct{
                                    font-weight: bold;
                                    .lessIcon{
                                    margin-left: -8px;
                                }
                                }
                                
                            }
                            .itemName{
                                text-transform: capitalize;
                            }
                        }
                        .editCollaboration{
                            margin: 10px 0 0 13px;
                            font-size: 0.7rem;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        .deleteCollaboration{
                            margin-left: 13px;
                            font-size: 0.7rem;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .influencerImage, .productImage{
                        width: 150px;
                        height: 150px;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: cover;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end; 
                        .influencerName, .productName{
                            background-color: rgba(155,155,155,0.5);
                            background-color: rgba(61, 61, 61, 0.5);
                            color: #ffffff;
                            text-transform: capitalize;
                            text-align: center;
                            font-size: 0.8rem;
                            //font-family: 'Centrale', sans-serif;
                            font-weight: bold;
                            padding: 0 5px;
                            p{
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .emptyCart{
                height: 400px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${colors.greyLetter};
                //background-color: red;
                width: 87vw;
                text-align: center;
            }
        }
        .cartCheckout{
            margin-top: 50px;
            width: 30%;
            .checkout{
                width: 100%;
                color: ${colors.greyInput};
                border-collapse: collapse;
                tr{
                    th{
                        text-align: left;
                        font-size: 1.1rem;
                        padding-bottom: 5px;
                        text-transform: uppercase;
                        font-weight: 800;
                    }
                    .price{
                        text-align: end;
                    }
                    .totalPrice{
                        text-align: end;
                        font-weight: bold;
                    }
                }
                .beforeTotal{
                    border-bottom: 1px solid ${colors.greyIcon};
                }
            }
            .mobilePromotionalCode{
                display: none;
            }
            .checkoutButton{
                margin-top: 20px;
                width: 100%;
                background-color: ${colors.blue};
                color: #ffffff;
                border: none;
                border-radius: 10px;
                padding: 10px 15px;
                font-size: 1.2rem;
                font-weight: 600;
                cursor: pointer;
            }
            .paypalInfo{
                font-size: 0.6rem;
                a{
                    color: ${colors.text};
                    text-decoration: underline;
                }
                a:visited{
                    color: ${colors.text};
                }
            }
        }
    }

    /**--------Responsive--------- */
    ${media.desktop}{
        .cartBody{
            .cartInformation{
                width: 65%;
                .cartItems{
                    .item{
                        .collaboration{
                            width: 75%;
                            .collaborationData{
                                width: 65%;
                            } 
                            .collaborationDetails{
                                width: 100%;
                            }
                        }
                    }
                    
                }
            }
        }
    }
    ${media.mediumDesktop}{
        .cartBody{
            .cartInformation{
                width: 65%;
                .cartItems{
                    .item{
                        .collaboration{
                            .collaborationData{
                                width: 65%;
                            }
                            .collaborationDetails{
                                tr{
                                        .firstColumn{
                                            font-size:1.1rem ;
                                    }
                                    .firstColumn:first-child{
                                            width:auto;
                                    }
                                }
                                
                            }
                        }
                        .influencerImage, .productImage{
                            width: 130px;
                            height: 130px;
                        }
                    }
                    
                }
            }
        }
    }

    ${media.laptop}{
        .cartBody{
            flex-direction: column;
            .cartInformation{
                width: 100%;
                .cartItems{
                    .item{
                        .influencerImage{
                            width: 120px;
                            height: 120px;    
                        }
                        .productImage{
                            width: 120px;
                            height: 120px;
                            
                        }
                    }
                }
            }
            .cartCheckout{
                margin-top: 30px;
                width: 50%;
                align-self: flex-end;
            }
        }
    }

    

    ${media.largeMobile}{
        padding: 0 15px 10px;
        .cartBody{
            flex-direction: column;
            margin: 0 10px;
            .cartInformation{
                .cartItems{
                    .item{
                        align-items: flex-end;
                        margin-bottom: 10px;
                        .collaboration{
                            flex-direction: column;
                            
                            .collaborationDetails{
                                margin-left: 0px;
                                tr{
                                    th{
                                        padding-right: 10px;
                                        font-size: 1rem;
                                        color: ${colors.text};
                                    }
                                    td{
                                        font-size: 0.8rem;
                                    }
                                    .firstColumn{
                                        width: 130px;
                                    }
                                }
                            }
                            .editCollaboration{
                                margin: 10px 0 0 3px;
                                font-size: 0.6rem;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                            .deleteCollaboration{
                                margin: 4px 0 0 3px;
                                font-size: 0.6rem;
                            }
                            
                        }
                        .influencerImage{
                            width: 70px;
                            height: 70px;
                            border-radius: 50%;
                            .influencerName{
                                display: none;
                            }
                            
                        }
                        .productImage{
                            width: 100px;
                            height: 100px;
                            
                        }
                    }
                }
                .influencerData{
                    width: 100%;
                    .promotionalCode{
                        display: none;
                    }
                }
            }
            .cartCheckout{
                margin-top: 30px;
                width: 100%;
                align-self: flex-end;
                .mobilePromotionalCode{
                    display: inherit;
                    margin-top: 10px;
                    .codeTitle{
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .codeInput{
                        padding: 8px 10px;
                        border: 1px solid ${colors.grey};
                        border-radius: 10px;
                        outline: none;
                        margin-right: 4px;
                        width: 70%;
                    }
                    .applyButton{
                        background-color: ${colors.blue};
                        color: #ffffff;
                        border: none;
                        border-radius: 10px;
                        padding: 10px 15px;
                        cursor: pointer;
                        width: 28%;
                    }
                }
            }
        }
    }

`