import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const CollaborationItemCardPublicProfileContainer = styled.div`

    width: 23%;
    height: 21vw;
    margin-bottom: 20px;
    margin-right: 2%;
    cursor: pointer;

    .cardCollaboration{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        position: relative;
        .typeIcon{
            position: absolute;
            top: 30px;
            right: 30px;
            width: 50px;
            height: 50px;
        }
        .photoIcon{
            position: absolute;
            top: 30px;
            right: 30px;
            width: 50px;
            height: 45px;
            border-radius: 10px;
            background-color: #ffffff;
        }
        .colaborator{
            width: 150px;
            height: 150px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            position: absolute;
            bottom: 30px;
            right: 30px;
            border-radius: 50%;
        }
    }
    

    /**------Responsive-------- */
    ${media.largeDesktop}{
        .cardCollaboration{
            .typeIcon{
                top: 20px;
                right: 20px;
                width: 40px;
                height: 40px;
            }
            .photoIcon{
                top: 20px;
                right: 20px;
                width: 40px;
                height: 35px;
                border-radius: 7px;
            }
            .colaborator{
                width: 100px;
                height: 100px;
                bottom: 20px;
                right: 20px;
            }
        }
    }

    ${media.specialScreen}{
        .cardCollaboration{
            .typeIcon{
                top: 20px;
                right: 20px;
                width: 30px;
                height: 30px;
            }
            .photoIcon{
                top: 20px;
                right: 20px;
                width: 30px;
                height: 25px;
                border-radius: 5px;
            }
            .colaborator{
                width: 80px;
                height: 80px;
            }
        }
    }

    ${media.desktop}{
        .cardCollaboration{
            .typeIcon{
                top: 15px;
                right: 15px;
                width: 25px;
                height: 25px;
            }
            .photoIcon{
                top: 15px;
                right: 15px;
                width: 25px;
                height: 20px;
                border-radius: 3px;
            }
            .colaborator{
                width: 70px;
                height: 70px;
                bottom: 15px;
                right: 15px;
            }
        }
    }

     ${media.smallDesktop}{
        .cardCollaboration{
            .typeIcon{
                top: 10px;
                right: 10px;
            }
            .photoIcon{
                top: 10px;
                right: 10px;
            }
            .colaborator{
                width: 60px;
                height: 60px;
                bottom: 10px;
                right: 10px;
            }
        }
    } 

    ${media.tablet}{
        width: 31%;
        height: 28vw;
        background-color: red;
        .cardCollaboration{
            .typeIcon{
                    top: 10px;
                    right: 10px;
                }
            .colaborator{
                width: 50px;
                height: 50px;
                bottom: 10px;
                right: 10px;
            }
        }
    }

    ${media.largeMobile}{
        width: 48%;
        height: 41vw;
        .cardCollaboration{
            .typeIcon{
                top: 8px;
                right: 8px;
                width: 16px;
                height: 16px;
            }
            .photoIcon{
                top: 8px;
                right: 8px;
                width: 16px;
                height: 12px;
                border-radius: 2px;
            }
            .colaborator{
                width: 40px;
                height: 40px;
                bottom: 8px;
                right: 8px;
            }
        }
    }
`