import React, { useState, useEffect, useContext, useRef } from "react";
import { FilterBarContainer } from "./FilterBarStyled";
import search from "./../../utils/images/search.png";
import { FormattedMessage } from "react-intl";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import "../../index.css";

import { LangContext } from "../../context/langContext";
import { useNavigate } from "react-router-dom";
import { FiltersContext } from "../../context/filtersContext";
import { CurrencyContext } from "../../context/currencyContext";
import { formatNumber } from "../../services/generalSevices";
import { StaticDataContext } from "../../context/staticDataContext";
import FluincrDropdown from "../Dropdown/FluincrDropdown";
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { colors } from "../../utils/styles/colors";
import { PinkSwitch } from "../SwitchPink/SwitchPink";

export default function FilterBar() {
  const navigate = useNavigate();

  const { locale } = useContext(LangContext);
  const {
    categorySelectList,
    countrySelectList,
    languageSelectList,
    genderSelectList,
  } = useContext(StaticDataContext);
  const {
    categoryFilter,
    setCategoryFilter,
    verifiedFilter,
    setVerifiedFilter,
    featuredFilter,
    setFeaturedFilter,
    newUserFilter,
    setNewUserFilter,
    countryFilter,
    setCountryFilter,
    languageFilter,
    setLanguageFilter,
    ratingFilter,
    setRatingFilter,
    genderFilter,
    setGenderFilter,
    inputFilter, 
    setInputFilter
  } = useContext(FiltersContext);

  const [categoryFilterAux, setCategoryFilterAux] = useState([]);

  const [openOptions, setOpenOptions] = useState(new Set());

  const handleClick = (filterName) => {
    const openOptionsAux = new Set(openOptions);
    if (openOptionsAux.has(filterName)) {
      openOptionsAux.delete(filterName);
    } else {
      openOptionsAux.add(filterName);
    }
    setOpenOptions(openOptionsAux);
  };

  const handleOptionsFilter = (value, filterList, setFilterList) => {
    if (filterList.includes(value)) {
      setFilterList(filterList.filter((item) => item !== value));
    } else {
      setFilterList([...filterList, value]);
    }
  };

  const handleSwitchFilter = (value, filter, setFilter) => {
    setFilter(value);
  };

  const filtros = [
    {
      title: "Language",
      titulo: "Idioma",
      name: "language",
      type: "options",
      list: languageSelectList,
      filterList: languageFilter,
      setFilterList: setLanguageFilter,
    },
    {
      title: "Gender",
      titulo: "Género",
      name: "gender",
      type: "options",
      list: genderSelectList,
      filterList: genderFilter,
      setFilterList: setGenderFilter,
    },
    {
      title: "Verified",
      titulo: "Verificado",
      name: "verified",
      type: "switch",
      filter: verifiedFilter,
      setFilter: setVerifiedFilter,
    },
  ];

  const countryFilterData = {
    title: "Country",
    titulo: "País",
    name: "country",
    type: "options",
    list: countrySelectList,
    filterList: countryFilter,
    setFilterList: setCountryFilter,
  };

  const categoryFilterData = {
    title: "Category",
    titulo: "Categoría",
    name: "category",
    type: "options",
    list: categorySelectList,
    filterList: categoryFilter,
    setFilterList: setCategoryFilter,
  }

  return (
    <FilterBarContainer>
      <div className="filters">
        <div className="filterContainer filterBy">
          <FluincrDropdown
            maxHeight="30em"
            fontSize="1.5rem"
            title={
              <p>
                <FormattedMessage
                  id="filter_title_1"
                  defaultMessage="Filtrar por"
                />
              </p>
            }
          >
            {filtros.map((filtro) => (
              <React.Fragment key={filtro.name}>
                <ListItemButton
                  sx={{ pl: 1, pr: 1, pt: 0, pb: 0}}
                  onClick={
                    filtro.type === "options"
                      ? () => handleClick(filtro.name)
                      : undefined
                  }
                >
                  <ListItemText
                    primary={locale === "es-CO" ? filtro.titulo : filtro.title}
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "'Open Sans', sans serif"
                      },
                    }}
                  />
                  {filtro.type === "options" ? (
                    openOptions.has(filtro.name) ? (
                      <ExpandLess sx={{ color: colors.hightlight }} />
                    ) : (
                      <ExpandMore sx={{ color: colors.hightlight }} />
                    )
                  ) : null}
                  {filtro.type === "switch" && (
                    <ListItemIcon>
                      <PinkSwitch
                        edge="end"
                        checked={filtro.filter}
                        onChange={(event) =>
                          handleSwitchFilter(
                            event.target.checked,
                            filtro.filter,
                            filtro.setFilter
                          )
                        }
                      />
                    </ListItemIcon>
                  )}
                </ListItemButton>
                {filtro.type === "options" && (
                  <Collapse
                    in={openOptions.has(filtro.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List sx={{ p: 0 }} component="div" disablePadding>
                      {filtro.list.map((item) => (
                        <ListItemButton
                          key={item.value}
                          sx={{ pl: 2, py: 0 }}
                          onClick={() =>
                            handleOptionsFilter(
                              item.value,
                              filtro.filterList,
                              filtro.setFilterList
                            )
                          }
                        >
                          <ListItemIcon sx={{ minWidth: "auto" }}>
                            <Checkbox
                              edge="start"
                              style={{ paddingTop: ".25rem", paddingBottom: ".25rem" }}
                              checked={filtro.filterList.includes(item.value)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": item.value }}
                              onChange={() =>
                                handleOptionsFilter(
                                  item.value,
                                  filtro.filterList,
                                  filtro.setFilterList
                                )
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: colors.hightlight,
                                },
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={item.value}
                            primary={
                              locale === "es-CO" ? item.nombre : item.name
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </FluincrDropdown>
        </div>

        <div className="filterContainer otherFilterContainer">
          <FluincrDropdown
            titleColor={colors.darkGrey}
            maxHeight="30em"
            fontSize="1.5rem"
            title={
              <p>
                <FormattedMessage
                  id="filter_title_2"
                  defaultMessage="Categoría"
                />
              </p>
            }
          >
            <List component="div" disablePadding>
              {categoryFilterData.list.map((item) => (
                <ListItemButton
                  key={item.value}
                  sx={{ p: 0 }}
                  onClick={() =>
                    handleOptionsFilter(
                      item.value,
                      categoryFilterData.filterList,
                      categoryFilterData.setFilterList
                    )
                  }
                >
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox
                      edge="start"
                      style={{ paddingTop: ".25rem", paddingBottom: ".25rem"}}
                      checked={categoryFilterData.filterList.includes(
                        item.value
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": item.value }}
                      onChange={() =>
                        handleOptionsFilter(
                          item.value,
                          categoryFilterData.filterList,
                          categoryFilterData.setFilterList
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: colors.hightlight,
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={item.value}
                    primary={locale === "es-CO" ? item.nombre : item.name}
                  />
                </ListItemButton>
              ))}
            </List>
          </FluincrDropdown>
        </div>

        <div className="filterContainer otherFilterContainer">
          <FluincrDropdown
            titleColor={colors.darkGrey}
            maxHeight="30em"
            fontSize="1.5rem"
            title={
              <p>
                <FormattedMessage id="filter_title_3" defaultMessage="País" />
              </p>
            }
          >
            <List component="div" disablePadding>
              {countryFilterData.list.map((item) => (
                <ListItemButton
                  key={item.value}
                  sx={{ p: 0 }}
                  onClick={() =>
                    handleOptionsFilter(
                      item.value,
                      countryFilterData.filterList,
                      countryFilterData.setFilterList
                    )
                  }
                >
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox
                      edge="start"
                      style={{ paddingTop: ".25rem", paddingBottom: ".25rem" }}
                      checked={countryFilterData.filterList.includes(
                        item.value
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": item.value }}
                      onChange={() =>
                        handleOptionsFilter(
                          item.value,
                          countryFilterData.filterList,
                          countryFilterData.setFilterList
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: colors.hightlight,
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={item.value}
                    primary={locale === "es-CO" ? item.nombre : item.name}
                  />
                </ListItemButton>
              ))}
            </List>
          </FluincrDropdown>
        </div>

        <div className="filterContainer">
          <div className="filter">
            <div className="textFilter">
              <input
                type="text"
                className="nameText"
                defaultValue={inputFilter}
                onChange={(event) => {
                  setInputFilter(event.target.value);
                }}
              />
              <img
                src={search}
                alt="search"
                className="search"
                onClick={() => navigate("/influencers")}
              />
            </div>
          </div>
        </div>
      </div>
    </FilterBarContainer>
  );
}
