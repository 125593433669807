import { S3API } from "../../api/utils/s3";

export function uploadImageS3(address, formatFile, file) {
    console.log("upload S3 file");
    console.log(address, formatFile, file);
    return new Promise((resolve, reject) => {
        S3API.getPresignedUrl(address, formatFile)
            .then((presignedUrl) => {
                S3API.uploadImageS3(presignedUrl, file, formatFile)
                    .then(() => {
                        let fileLink = presignedUrl.split("?")[0];
                        let fileName = fileLink.split(".com/")[1];

                        S3API.putObjectPublic(fileName)
                            .then(() => {
                                resolve(fileLink);
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            })
            .catch(reject);
    });
}