import React, { useRef } from "react";
import { useEffect } from "react";
import { AlertContainer } from "./AlertStyled";

export default function Alert({ image, title, subtitle, text, confirmText, cancelText, onConfirm, onCancel, setShowAlert }) {

    const divRef = useRef(null);

    useEffect(() => {

        function handleClickOutside(event) {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setShowAlert(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [divRef])

    return (
        <AlertContainer>

            <div className="card" ref={divRef}>

                {(image) && (<img src={image} alt="alert icon" className="image" />)}

                <h2 className="subtitle">{subtitle}</h2>
                <h2 className="title">{title}</h2>

                <p className="text">{text}</p>

                <div className="buttons">

                    {(confirmText) && (<button type='button' className="confirm" onClick={onConfirm}>{confirmText}</button>)}

                    {(cancelText) && (<button type='button' className="cancel" onClick={onCancel}>{cancelText}</button>)}

                </div>
            </div>

        </AlertContainer>
    )
}