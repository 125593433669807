import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-collaboration';

export const CollaborationAPI = {
  // Create
  createCollaboration: async (collaborationDTO) => {
    const response = await axiosInstance.post(`${BASE_URL}/collaboration`, collaborationDTO);
    return response.data;
  },

  // Read
  getCollaboration: async (id) => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration/${id}`);
    return response.data;
  },

  // List all collaborations by user id
  getCollaborationsByUserId: async (userId) => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration/users/${userId}`);
    return response.data;
  },
  
  // List all collaborations
  getCollaborations: async () => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration`);
    return response.data;
  },

  getCollaborationVoucher: async (id) => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration/voucher/${id}`);
    return response.data;
  },

  getCollaborationInvoice: async (id) => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration/invoice/${id}`);
    return response.data;
  },

  getCollaborationPendingToRate: async () => {
    const response = await axiosInstance.get(`${BASE_URL}/collaboration/rate-pending`);
    return response.data;
  },

  rateCollaboration: async (id, rating) => {
    const response = await axiosInstance.put(`${BASE_URL}/collaboration/rate-collaboration/${id}?rate=${rating}`);
    return response.data;
  },

  // Update
  updateCollaboration: async (id, collaborationDTO) => {
    const response = await axiosInstance.put(`${BASE_URL}/collaboration/${id}`, collaborationDTO);
    return response.data;
  },

  // Accept collaboration
  acceptCollaboration: async (id, influencerId) => {
    const response = await axiosInstance.put(`${BASE_URL}/collaboration/${id}/accept/${influencerId}`);
    return response.data;
  },

  // Decline collaboration
  declineCollaboration: async (id, influencerId) => {
    const response = await axiosInstance.put(`${BASE_URL}/collaboration/${id}/decline/${influencerId}`);
    return response.data;
  },

  // Dispute collaboration
  disputeCollaboration: async (id, documentLink = '') => {
    const response = await axiosInstance.put(`${BASE_URL}/collaboration/${id}/dispute?document=${documentLink}`);
    return response.data;
  },

  // Delete
  deleteCollaboration: async (id) => {
    const response = await axiosInstance.delete(`${BASE_URL}/collaboration/${id}`);
    return response.data;
  },
};