import styled from "styled-components";
import { colors } from '../../utils/styles/colors';
import { media } from './../../utils/styles/media';

export const MyCollaborationsContainer = styled.div`
    
    padding: 0 100px 50px;
    hr{
        border: 0.5px solid ${colors.grey};
        margin-bottom: 10px;
    }
    .goBack{
        display:inherit;
        display: flex;
        cursor: pointer;
        width: 130px;
        margin-bottom: 20px;
        margin-top: 30px;
        .backIcon{
            width: 24px;
            margin-right: 10px;
        }
        .backText{
            color: #000000;
            font-size: 1.6rem;
        }
    }
    section{
        h3{
            color: ${colors.darkGrey};
            font-size: 2.5rem;
            margin-bottom: 20px;
        }
      
        table{
            width: 100%;
            font-weight: 700;
            border-spacing: 5px 10px;
            font-size: 1.75rem;
            margin-bottom: 30px;
            .companyName{
                div{
                    margin-left: 0 !important;
                }
                display: flex;
                align-items: center;
                .name{
                    font-weight: 700;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
            .date{
                display: flex;
                justify-content: center;
            }
            .product{
                text-align: center;
                cursor: pointer;
            }
            .collaborationPrice{
                display: flex;
                align-items: center;
                position: relative;
                justify-content: flex-end;
                .collaborationButtonContainer{
                    display: flex;
                    flex-direction: column;

                    .notification{
                        background-color: ${colors.hightlight};
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        top: -.5em;
                        right: -.5em;
                    }
                    .collaborationButton{
                        margin: 0;
                        background-color: ${colors.blueButton};
                        border: none;
                        padding: 20px 25px;
                        color: #ffffff;
                        border-radius: 20px;
                        cursor: pointer;
                        font-size: 1.5rem;
                    }
                }
            }
        }
        table.pastCollaborations{
            opacity: 0.4;
            .notification{
                display: none;
            }

        }
        .notCollaborations{
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colors.greyLetter};
            font-size: 2rem;
        }
    }
    

    /**-------Responsive-------- */
    ${media.largeDesktop}{
        padding: 0 80px 50px;
        .goBack{
            width: 120px;
            .backIcon{
                width: 22px;
            }
            .backText{
                font-size: 1.5rem;
            }
        }
        section{
            h3{
                font-size: 2rem;
            }
            table{
                border-spacing: 5px 10px;
                font-size: 1.25rem;
                .collaborationPrice{
                    .collaborationButtonContainer{
                        .notification{
                            height: 25px;
                            width: 25px;
                        }
                        .collaborationButton{
                            padding: 15px 20px;
                            border-radius: 15px;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
            .notCollaborations{
                height: 250px;
                font-size: 1.5rem;
            }
        }
    }

    ${media.macdesktop}{
        padding: 0 50px 50px;
        .goBack{
            width: 120px;
            margin-bottom: 10px;
            .backIcon{
                width: 20px;
                margin-right: 10px;
            }
            .backText{
                font-size: 1.2rem;
            }
        }
        section{
            h3{
                font-size: 1.7rem;
            }
        
            table{
                border-spacing: 5px 10px;
                font-size: 1rem;
                .collaborationPrice{
                    .collaborationButtonContainer{
                        .notification{
                            height: 20px;
                            width: 20px;
                        }
                        .collaborationButton{
                            padding: 10px 15px;
                            border-radius: 10px;
                            font-size: 1rem;
                        }
                    }
                }
            }
            .notCollaborations{
                font-size: 1.2rem;
            }
        }
    }

    ${media.desktop}{
        padding: 0 50px 50px;
        .goBack{
            width: 120px;
            margin-bottom: 10px;
            .backIcon{
                width: 18px;
                margin-right: 10px;
            }
            .backText{
                font-size: 1rem;
            }
        }
        section{
            h3{
                font-size: 1.5rem;
            }
        
            table{
                border-spacing: 5px 10px;
                font-size: .75rem;
                .collaborationPrice{
                    .collaborationButtonContainer{
                        .notification{
                            height: 20px;
                            width: 20px;
                        }
                        .collaborationButton{
                            padding: 10px 15px;
                            border-radius: 10px;
                            font-size: 0.9rem;
                        }
                    }
                }
            }
            .notCollaborations{
                font-size: 1rem;
            }
        }
    }

    ${media.laptop}{
        hr{
            display:none;
        }
        section{
            table{ 
                .product{
                    display: none;
                }
                .collaborationPrice{
                    .price-detail{
                        display: none !important;
                    }
                }
            }
        }
    }

    ${media.largeMobile}{
        padding: 0 15px 20px;
        section{
            table{
                border-spacing: 0px 12px;
                .companyName{
                    .name{
                        font-size: 0.9rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: ${colors.text};
                    }
                }
                .date{
                    font-size: 0.6rem;
                    border: 1px solid ${colors.grey};
                    border-radius: 20px;
                    padding: 8px 4px;
                }
                .product{
                    display: none;
                }
                .collaborationPrice{
                    .price-detail{
                        display: none !important;
                    }
                    .collaborationButtonContainer{
                        .notification{
                            height: 15px;
                            width: 15px;
                            top: 0;
                            rigth: .25em;
                        }
                        .collaborationButton{
                            font-size: 0.6rem;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 80px;
                            margin: 5px 5px 5px 0px;
                            padding: 8px;
                            border-radius: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
`

export const ProfileImage = styled.div`

    width: 100px; 
    height: 100px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #ffffff;
    margin-right: 10px;
    margin-left: 70px;
    cursor: pointer;

    /**-----------Responsive---------- */
    ${media.largeDesktop}{
        width: 90px; 
        height: 90px;
        margin-left: 50px;
    }

    ${media.specialScreen}{
        width: 85px; 
        height: 85px;
    }

    ${media.bigmacdesktop}{
        width: 80px; 
        height: 80px;
    }

    ${media.macdesktop}{
        width: 75px; 
        height: 75px;
    }

    ${media.desktop}{
        width: 70px; 
        height: 70px;
    }

    ${media.tablet}{
        width: 60px; 
        height: 60px;
        margin-left: 20px;
    }

    ${media.largeMobile}{
        width: 50px; 
        height: 50px;
        margin-left: 0px;
    }
`