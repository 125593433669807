import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import PrincipalTemplate from './templates/PrincipalTemplate/PrincipalTemplate';
import ScrollToTop from './services/generalSevices';
import Error from './pages/Error/Error';
import Home from './pages/Home/Home';
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import MyAccount from "./pages/MyAccount/MyAccount";
import Companies from "./pages/Companies/Companies";
import Influencers from "./pages/Influencers/Influencers";
import MyProfile from "./pages/MyProfile/MyProfile";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NewPassword from "./pages/NewPassword/NewPassword";
import InfluencerProfile from "./pages/InfluencerProfile/InfluencerProfile";
import MyCart from "./pages/MyCart/MyCart";
import MyCollaborations from "./pages/MyCollaborations/MyCollaborations";
import CollaborationDetails from "./pages/CollaborationDetails/CollaborationDetails";
import Voucher from "./pages/Voucher/Voucher";
import Invoice from "./pages/Invoice/Invoice";
import TiktokRedirect from "./pages/RedirectNetwork/TiktokRedirect";
import YoutubeRedirect from "./pages/RedirectNetwork/YoutubeRedirect";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";
import FacebookRedirect from "./pages/RedirectNetwork/FacebookRedirect";
import InstagramRedirect from "./pages/RedirectNetwork/InstagramRedirect";

import InstagramFacebookRedirect from "./pages/RedirectNetwork/InstagramFacebookRedirect";
import TwitterRedirect from "./pages/RedirectNetwork/TwitterRedirect";

import ProtectRoute from "./templates/ProtectRoute/ProtectRoute";
import Paypal from "./pages/Paypal/Paypal";
import AdminBlockedRoute from "./templates/AdminRoute/AdminRoute";

import AdminLoginProfile from "./pages/AdminLogin/AdminLoginProfile";
import AdminLoginCollaboration from "./pages/AdminLogin/AdminLoginCollaboration";
import { useContext, useEffect, useState } from "react";
import StaticPage from "./pages/StaticPage/StaticPage";
import Spinner from "./components/Spinner/Spinner";
import { StaticDataContext } from "./context/staticDataContext";


function App() {

  const { legalTextList, staticPageList } = useContext(StaticDataContext);

  return (
    <Router>
      <PrincipalTemplate >
        <ScrollToTop />
        <Routes>
          <Route element={<AdminBlockedRoute />}>
            <Route path='/' element={<Home />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/companies' element={<Companies />} />
            <Route path='/influencers' element={<Influencers />} />
          </Route>

          <Route path='/resetpassword' element={<ResetPassword />} />
          <Route path='/resetpasswordsuccessinf' element={<NewPassword />} />
          <Route path='/resetpasswordsuccesscomp' element={<NewPassword />} />

          {legalTextList.map((legal, index) => {
            return (<Route
              key={index}
              path={`/legal/${legal.path}`}
              element={<TermsAndConditions />}
            />)
          })}

          <Route path="/static/*" element={<><Spinner /></>} />


          {staticPageList?.map((legal, index) => {
            return (<Route
              key={index}
              path={`/static/${legal.path}`}
              element={<StaticPage />}
            />)
          })}

          <Route path="/deleteAccount" element={<DeleteAccount />}></Route>

          <Route path='/auth/tiktok' element={<TiktokRedirect />} />
          <Route path='/auth/youtube' element={<YoutubeRedirect />} />
          <Route path='/auth/facebook' element={<FacebookRedirect />} />
          <Route path='/auth/instagram' element={<InstagramRedirect />} />
          <Route path='/auth/instagramfacebook' element={<InstagramFacebookRedirect />} />
          <Route path='/auth/twitter' element={<TwitterRedirect />} />

          <Route path='/auth/paypal' element={<Paypal />} />

          <Route path='/:username' element={<InfluencerProfile />} />

          {/* <Route path='/myprofile' element={<MyProfile />} /> */}
          {/* <Route path='/myaccount' element={<MyAccount />} /> */}

          <Route element={<ProtectRoute />}>
            <Route path='/myprofile' element={<MyProfile />} />
            <Route path='/myaccount' element={<MyAccount />} />
            <Route element={<AdminBlockedRoute />}>
              <Route path='/mycart' element={<MyCart />} />
            </Route>
            {/* <Route path='/mycart' element={<MyCart />} /> */}
            <Route path='/mycollaborations' element={<MyCollaborations />} />
            <Route path='/mycollaborations/:idCollaboration' element={<CollaborationDetails />} />
            <Route path='/mycollaborations/:idCollaboration/voucher' element={<Voucher />} />
            <Route path='/mycollaborations/:idCollaboration/invoice' element={<Invoice />} />
          </Route>

          {/* <Route path='/mycart' element={<MyCart />} /> */}

          {/* <Route path='/mycollaborations' element={<MyCollaborations />} /> */}
          {/* <Route path='/mycollaborations/:idCollaboration' element={<CollaborationDetails />} /> */}

          {/* <Route path='/mycollaborations/:idCollaboration/voucher' element={<Voucher />} /> */}
          {/* <Route path='/mycollaborations/:idCollaboration/invoice' element={<Invoice />} /> */}

          <Route path='/auth/admin' element={<AdminLoginProfile />} />
          <Route path='/collaboration/admin' element={<AdminLoginCollaboration />} />

          <Route path='/error' element={/*isLoading ? <Spinner /> :*/ <Error />} />
          <Route path="*" element={/*isLoading ? <Spinner /> :*/ <Error />}></Route>

        </Routes>
        <ScrollToTop />
      </PrincipalTemplate>
    </Router>
  );
}

export default App;
