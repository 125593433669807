import React from "react";
import { SignInContainer } from "./SignInStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import logo from './../../utils/images/name_logo_grey.svg';
import person from './../../utils/images/person.svg';
import lock from './../../utils/images/lock.svg';
import eye from './../../utils/images/eye.svg';
import slashEye from './../../utils/images/slash_eye.svg';
import warning from './../../utils/images/warning.png'
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Spinner from "../../components/Spinner/Spinner";

import { useDispatch } from 'react-redux';
import { deleteToken, saveToken } from '../../store/token/action';
import ModalSecurityCode from "../../components/ModalSecurityCode/ModalSecurityCode";
import Alert from "../../components/Alert/Alert";
import { URLplatform, fluincrCaresEN, fluincrCaresES, contactEN, contactES } from "../../utils/constants/constants";
import { LangContext } from "../../context/langContext";
import { SignInAPI } from "../../api/oauth/signin";
import { GenderAPI } from "../../api/static-data/gender";
import { UserContext2 } from "../../context/user/userContext";
import { UserAPI } from "../../api/user/user";

export default function SignIn() {

    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { locale } = useContext(LangContext);

    const [email, setEmail] = useState('');
    const [isEmptyEmail, setIsEmptyEmail] = useState(true);
    const [password, setPassword] = useState('');
    const [isEmptyPassword, setIsEmptyPassword] = useState(true);

    const [showLoginError, setShowLoginError] = useState(false);
    //const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [showLoginNoRegistered, setShowLoginNoRegistered] = useState(false);

    const [showSecurityCode, setShowSecurityCode] = useState(false);

    const [seePassword, setSeePassword] = useState(false);

    const [rememberMe, setRememberMe] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const { 
        userData,
        setUserData,
        userToken,
        setUserToken,
        userType,
        setUserType,
        setShowAlertInvalidToken
    } = useContext(UserContext2);

    const [showDeletedAccountMessage, setShowDeletedAccountMessage] = useState(false);
    const [showSuspendedAccountMessage, setShowSuspendedAccountMessage] = useState(false);


    useEffect(() => {
        setShowEmptyError(false);
        setShowLoginError(false);
        setShowLoginNoRegistered(false);
        if (email.length > 0) {
            setIsEmptyEmail(false)
        } else {
            setIsEmptyEmail(true)
        }

        if (password.length > 0) {
            setIsEmptyPassword(false)
        } else {
            setIsEmptyPassword(true)
        }

    }, [email, password])

    useEffect(() => {
        if (isEmailVerified) {
            //loginHandler({ mail: email.toLowerCase(), password: password })
        }
    }, [showSecurityCode])

    const loginUser = (event) => {
        //TODO: Validar verificación en dos pasos y si el usuario está suspendido o eliminado

        event.preventDefault();

        if (!isEmptyEmail && !isEmptyPassword) {
            setIsLoading(true);
            
            SignInAPI.signIn(email.toLowerCase(), password).then((response) => {
                const token = response.Authorization;
                setIsLoading(false);
                sessionStorage.setItem('fluincrToken', JSON.stringify(token));
                console.log(response);
                dispatch(saveToken(token));
                setUserToken(token);
                getUserData(token);

            }).catch((error) => {
                console.log(error.response);
                localStorage.removeItem('fluincrToken');
                sessionStorage.removeItem('fluincrToken');
                setShowLoginError(true);
                dispatch(deleteToken());
                setIsLoading(false);
            })
        } else {
            setShowEmptyError(true);
        }
    }

    const getUserData = (token) => {
        setIsLoading(true);

        UserAPI.getUserLoginData().then((response) => {
            const userTypeResponse = response['user_type']?.name;

            setUserData(response);
            setUserType(userTypeResponse);
            console.log(response);
            setIsLoading(false);

            console.log(userTypeResponse);

            sessionStorage.setItem('fluincrUser', JSON.stringify(userTypeResponse));

            if (rememberMe) {
                localStorage.setItem('fluincrToken', JSON.stringify(token));
                localStorage.setItem('fluincrUser', JSON.stringify(userTypeResponse));
            } else {
                localStorage.removeItem('fluincrToken');
                localStorage.removeItem('fluincrUser');
            }
            
            navigate(`/${response?.username}`);
        
        }).catch((error) => {
            console.log(error);
            setUserData(null);
            setUserType('');
            setUserToken(null);
            localStorage.removeItem('fluincrToken');
            sessionStorage.removeItem('fluincrToken');
            setShowLoginError(true);
            dispatch(deleteToken());
            setIsLoading(false);
            //setShowAlertInvalidToken(true);
            navigate('/signin');
        });

    }

    return (
        <SignInContainer>
            <div className="signInHeader">
                <h1><FormattedMessage id="signin_title" defaultMessage="Conoce las estrellas" /></h1>
                <img src={logo} alt="logo" className="logo" />
            </div>

            <form action="submit">
                <h2 className="title"><FormattedMessage id="signin_form_title" defaultMessage="Bienvenido" /></h2>

                <div className="inputContainer">
                    <img src={person} alt="email" className="icon" />
                    <input type="email" placeholder={intl.formatMessage({ id: "signin_input_1" })} value={email} onChange={(event) => { setEmail(event.target.value) }} />
                </div>
                <div className="inputContainer">
                    <img src={lock} alt="password" className="icon" />
                    <input type={(seePassword) ? "text" : "password"} placeholder={intl.formatMessage({ id: "signin_input_2" })} value={password} onChange={(event) => { setPassword(event.target.value) }} />
                    <div className="seeContainer">
                        <img src={(seePassword) ? eye : slashEye} alt="password" className="iconSeePassword" onClick={() => { setSeePassword(!seePassword) }} />
                    </div>

                </div>

                <label htmlFor="remember" className="remember">
                    <input type="checkbox" id="remember" name="remember" value="remember" onChange={(event) => { setRememberMe(event.target.checked) }} /> <FormattedMessage id="signin_checkbox" defaultMessage="Recordarme" />
                </label>

                {(showLoginError) && (<p className="error"><FormattedMessage id="change_password_text_4" defaultMessage="Contraseña/usuario incorrecta" /></p>)}
                {(showEmptyError) && (<p className="error"><FormattedMessage id="sign_up_error_message" defaultMessage="* Completar todos los campos" /></p>)}
                {(showLoginNoRegistered) && (<p className="error"><FormattedMessage id="signin_text_4" defaultMessage="Usuario no registrado" /></p>)}

                <button className="generalButton active" onClick={loginUser}>
                    <FormattedMessage id="signin_button" defaultMessage="Inicio de sesión" />
                </button>
                <p className="register"><FormattedMessage id="signin_text_1" defaultMessage="¿No tienes una cuenta?" />
                    <Link to='/signup'>
                        <span className="registerButton"> <FormattedMessage id="signin_text_2" defaultMessage="Regístrate aquí" /></span>
                    </Link>
                </p>
            </form>

            <p className="forgotPassword" onClick={() => navigate('/resetpassword')}><FormattedMessage id="signin_text_3" defaultMessage="Olvidé mi contraseña" /></p>


            {(showSecurityCode) && (<ModalSecurityCode setShowSecurityCode={setShowSecurityCode} page={'login'} body={{ mail: email.toLowerCase(), password: password }} setIsEmailVerified={setIsEmailVerified} />)}

            {(isLoading) && (<Spinner />)}

            {(showDeletedAccountMessage) && (<Alert
                title={<FormattedMessage id="Error_title" defaultMessage="¡Lo sentimos!" />}
                text={<span><FormattedMessage id="alert_deleted_account_text_a" defaultMessage="¡Oh, no! Parece que tu cuenta ha sido eliminada permanentemente. Para obtener más información, visita " /> <a href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN}><FormattedMessage id="alert_deleted_account_text_b" defaultMessage="Fluincr Cares" /></a>.</span>}
                setShowAlert={setShowDeletedAccountMessage}

            />)}

            {(showSuspendedAccountMessage) && (
                //TODO: Refactorizar componente Alert para utilizarlo en este modal
                <div id="inactiveAccountAlertContainer">
                    <div id="inactiveAccountAlert">
                        <img src={warning} alt="alert icon" className="image" width={120}/>
                        <span className="title"><FormattedMessage id="Error_title_2" defaultMessage="¡Lo sentimos!" /></span>
                        <div className="text">
                            <FormattedMessage id="alert_suspended_account_text_a" defaultMessage="Tu cuenta ha sido suspendida temporalmente, posiblemente debido a un problema de seguridad o un problema relacionado con los " />
                            <a href={`${URLplatform}/legal/terms`} target="_blank"><FormattedMessage id="alert_suspended_account_text_b" defaultMessage="Términos y Condiciones" /></a>
                            <FormattedMessage id="alert_suspended_account_text_c" defaultMessage=" de Fluincr o las " />
                            <a href={`${URLplatform}/legal/guidelines`} target="_blank"><FormattedMessage id="alert_suspended_account_text_d" defaultMessage="Directrices de la Comunidad" /></a>
                            <FormattedMessage id="alert_suspended_account_text_e" defaultMessage=". Para más información, visita " />
                            <a href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN} target="_blank"><FormattedMessage id="alert_suspended_account_text_f" defaultMessage="Fluincr Cares" /></a>
                            <FormattedMessage id="alert_suspended_account_text_g" defaultMessage=". Si eres menor de edad, " />
                            <a href={'/'} target="_blank"><FormattedMessage id="alert_suspended_account_text_h" defaultMessage="envíanos" /></a>
                            <FormattedMessage id="alert_suspended_account_text_i" defaultMessage=" el " />
                            <a href={'/'} target="_blank"><FormattedMessage id="alert_suspended_account_text_j" defaultMessage="formulario de aprobación del tutor" /></a>
                            <FormattedMessage id="alert_suspended_account_text_k" defaultMessage=" para que podamos activar tu cuenta de inmediato. ¡Gracias!" />
                        </div>
                        <button id="inactiveAccountAlertButton" onClick={() => setShowSuspendedAccountMessage(false)}>
                            <FormattedMessage id="alert_suspended_account_button_text" defaultMessage="Cerrar" />
                        </button>
                    </div>
                </div>
            )}

        </SignInContainer>

    )
}