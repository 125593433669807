import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, Input, InputLabel, InputAdornment } from '@mui/material';
import { useState, useContext } from 'react'

import './ModalInstagramStyled.css';
import '../../../../../Bootstrap.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { InstagramAPI } from '../../../../../api/social-network/instagram';
import { UserContext2 } from '../../../../../context/user/userContext';

const ModalInstagram = ({ open, setOpen }) => {

    const { userData, setUserData } = useContext(UserContext2);

    const intl = useIntl();

    const [instagramProfileLinkModal, setInstagramProfileLinkModal] = useState('');
    const [instagramFollowersModal, setInstagramFollowersModal] = useState(null);

    const saveInstagramData = (username, instagramFollowers) => {
        if(username && instagramFollowers) {
            const profileLink = `https://www.instagram.com/${username}`;

            InstagramAPI.manualInsert(userData?.id, profileLink, instagramFollowers).then((instagramSocialNetworkAccount) => {

                setUserData({
                    ...userData,
                    social_networks: [
                        ...userData?.social_networks,
                        instagramSocialNetworkAccount
                    ]
                });

                setOpen(false);
            });
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog id="fl-dialog-container" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className='p-3 pt-1' style={{minWidth: '22vw'}}>
                <DialogTitle id="fl-dialog-title">
                    <FormattedMessage id="modalInstagramAccountTitle" defaultMessage="Añadir usuario de Instagram" />
                </DialogTitle>
                <DialogContent className='pb-2'>
                    <FormControl id="fl-input" className="m-0" fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">{intl.formatMessage({ id: "modalInstagramAccountLinkInputLabel" })}</InputLabel>
                        <Input
                            id="standard-adornment-amount"
                            startAdornment={<InputAdornment position="start">@</InputAdornment>}
                            onChange={(e) => setInstagramProfileLinkModal(e.target.value)}
                        />
                    </FormControl>

                    <TextField
                        label={intl.formatMessage({ id: "modalInstagramAccountFollowersInputLabel" })}
                        startAdornment={<InputAdornment position="start">+</InputAdornment>}
                        autoFocus
                        margin="dense"
                        id="fl-input"
                        size="small"
                        variant="standard"                        
                        type="number"
                        fullWidth
                        required
                        onKeyPress={(e) => {
                            if (e.key === '.' || e.key === ',') {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => setInstagramFollowersModal(e.target.value)}
                        helperText={intl.formatMessage({ id: "modalInstagramAccountFollowersInputHelperText" })}
                        FormHelperTextProps={{ className: 'fl-helper-text' }}
                    />
                </DialogContent>
                <DialogActions className='p-2 pt-1'>
                    <button onClick={handleClose} className="fl-button-secondary">
                        <FormattedMessage id="modalInstagramAccountButtonCancel" defaultMessage="Cancelar" />
                    </button>
                    <button onClick={() => saveInstagramData(instagramProfileLinkModal, instagramFollowersModal)} className="fl-button-primary">
                        <FormattedMessage id="modalInstagramAccountButtonConfirm" defaultMessage="Confirmar" />
                    </button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default ModalInstagram