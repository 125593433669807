import imageCompression from "browser-image-compression";
//import { getBase64 } from "./generalSevices";

export async function handleImageUpload({ event, maxWidthOrHeight }) {

    const imageFile = event.target.files[0];

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: maxWidthOrHeight,
        //maxWidthOrHeight: 1920,
        /* onProgress: Function,
            useWebWorker: boolean,
            signal: AbortSignal,
            maxIteration: number,
            exifOrientation: number,
            fileType: string,
            initialQuality: number,
            alwaysKeepResolution: boolean */
    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        /* console.log(compressedFile);
        console.log(compressedFile.size / 1024 / 1024);
        getBase64({ file: compressedFile }).then((image) => {
            console.log(image)
        }) */
        return compressedFile;
    } catch (error) {
        console.log(error);
    }

}