import React, { useContext, useState, useRef } from "react";
import { MyProfileContainer, ProfileImage, NetworkCount } from "./MyProfileStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import avatar from './../../utils/images/avatar_fluincr.png';
import arrowBack from './../../utils/images/arrow_back_black.svg';
import check from './../../utils/images/check_circle_darker.svg';
import edit from './../../utils/images/edit_white.svg';
import star from './../../utils/images/star.svg';
import play from './../../utils/images/play_grey.svg';
import share from './../../utils/images/share_white.svg';

import MyProfileFirstSection from "../../components/MyProfileFirstSection/MyProfileFirstSection";
import MyProfileCollaborations from "../../components/MyProfileCollaborations/MyProfileCollaborations";
import MyProfileGallery from "../../components/MyProfileGallery/MyProfileGallery";
import { useNavigate } from 'react-router-dom';
import ModalUploadVideo from "../../components/ModalUploadVideo/ModalUploadVideo";

import { getBase64, toUpperCase } from "../../services/generalSevices";
import MyProfileProducts from "../../components/MyProfileProducts/MyProfileProducts";
import { useEffect } from "react";
import Select, { components } from 'react-select';
import { LanguageOption, CategoriesOption } from "./Options";

import Spinner from "../../components/Spinner/Spinner";

import SocialMedia from "../../components/SocialMedia/SocialMedia";
import { URL, URLplatform } from "../../utils/constants/constants";

import ImageCrop from "../../components/ImageCrop/ImageCrop";
import { handleImageUpload } from "../../services/compress";
import TooltipLight from "../../components/TooltipLight/TooltipLight";
import ProfileSocialNetworks from "../../components/ProfileSocialNetworks/ProfileSocialNetworks";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CompanyAPI } from "../../api/user/company/company";
import { uploadImageS3 } from "../../utils/common/s3";
import { UserAPI } from "../../api/user/user";

export default function MyProfile() {

    const navigate = useNavigate();
    const { countrySelectList, languageSelectList, categorySelectList } = useContext(StaticDataContext);
    const { userType, userData, setUserData } = useContext(UserContext2);
    const [userDataAux, setUserDataAux] = useState(userData);
    const [userAddressAux, setUserAddressAux] = useState(userData?.address);
  
    const languagesRef = useRef(null);
    const categoriesRef = useRef(null);
    const countriesRef = useRef(null);
    const descriptionRef = useRef(null);
    const intl = useIntl();
    const { Option } = components;

    const [isLoading, setIsLoading] = useState(false);
    const [showSocialMedia, setShowSocialMedia] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);

    const [showModalVideo, setShowModalVideo] = useState(false);
    const [showImageCrop, setShowImageCrop] = useState(false);

    const [showCategoriesOptions, setShowCategoriesOptions] = useState(false);
    const [showLanguagesOptions, setShowLanguagesOptions] = useState(false);
    const [showCountriesOptions, setShowCountriesOptions] = useState(false);
    const [showDescriptionArea, setShowDescriptionArea] = useState(false);

    const [cityAux, setCityAux] = useState(null);
    const [countryIdAux, setCountryIdAux] = useState(null);
    const [categoriesAux, setCategoriesAux] = useState(null);
    const [languagesAux, setLanguagesAux] = useState(null);
    const [descriptionAux, setDescriptionAux] = useState(null);


    const [imagesToSend, setImagesToSend] = useState([]);
    const [image, setImage] = useState('');

    const isAdmin = sessionStorage.getItem('isAdmin');

    useEffect(() => {
        if (userData) {
            setUserDataAux(userData);
            setUserAddressAux(userData?.address);
            setCityAux(cityAux ?? userData?.address?.city);
            setCountryIdAux(countryIdAux ?? userData?.address?.country_id);
            setCategoriesAux(categoriesAux ?? userData?.categories);
            setLanguagesAux(languagesAux ?? userData?.languages);
            setDescriptionAux(descriptionAux ?? userData?.description);
        }
    }, [userData]);

    useEffect(() => {

        function handleClickOutside(event) {
            if (languagesRef.current && !languagesRef.current.contains(event.target)) {
                setShowLanguagesOptions(false);
            }
            if (categoriesRef.current && !categoriesRef.current.contains(event.target)) {
                setShowCategoriesOptions(false);
            }
            if (countriesRef.current && !countriesRef.current.contains(event.target)) {
                setShowCountriesOptions(false);
            }
            if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
                setShowDescriptionArea(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [languagesRef, categoriesRef, countriesRef, descriptionRef])


    useEffect(() => {
        if (imagesToSend.length > 0) {
            setShowImageCrop(true)
        } else {
            setShowImageCrop(false)
        }
    }, [imagesToSend])


    const saveImage = (croppedImgSrc) => {

        setIsLoading(true);

        const imageBody = {
            base64File: croppedImgSrc.split(',')[1],
            formatFile: imagesToSend[0].file.type.split('/')[1],
        }
        if (userType === UserTypes.INFLUENCER) {
            //imageBody.address = `fluincr/${uniqueName}/profile`
            imageBody.address = `fluincr/${userData?.account}/profile`
        } else {
            //imageBody.address = `company/${uniqueName}/profile`
            imageBody.address = `company/${userData?.account}/profile`
        }

        uploadImageS3(imageBody.address, 'image/' + imageBody.formatFile, imagesToSend[0].file).then((fileLink) => {

            if (fileLink) {
                UserAPI.updateImageProfile(fileLink).then((response) => {
                    setUserData({ ...userData, image_profile: fileLink });
                    setShowImageCrop(false);
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
                setShowAlertError(true);
            }

        });
    }

    const sendLanguages = () => {
        const body = {
            ...userData,
            languages: languagesAux
        }

        saveUserData(body).then((response) => {
            setUserData(body);
        });
    }

    const sendCategories = () => {
        const body = {
            ...userData,
            categories: categoriesAux
        }

        saveUserData(body).then((response) => {
            setUserData(body);
            setShowCategoriesOptions(false);
        });
    }

    const sendAddress = () => {
        const body = {
            ...userData,
            address: {
                ...userData?.address,
                city: cityAux,
                country_id: countryIdAux
            }
        }

        saveUserData(body).then((response) => {
            setUserData(body);
            setShowCountriesOptions(false);

        });
    }

    const sendDescription = () => {
        const body = {
            ...userData,
            description: descriptionAux
        }

        saveUserData(body).then((response) => {
            setUserData(body);
            setShowDescriptionArea(false);
        });
    }

    const saveUserData = async (body) => {
        setIsLoading(true);
        
        try {
            if (userType === UserTypes.INFLUENCER) {
                const response = await InfluencerAPI.updateUserInfluencer(body);
                setIsLoading(false);
            } else {
                const response = await CompanyAPI.updateUserCompany(body);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const IconCountryOption = props => {
        return (<Option {...props} >
            <img
                src={props.data.image}
                style={{ width: 20, marginRight: 5 }}
                alt={props.data.label}
            />
            <span >{props.data.label}</span>

        </Option>)
    };

    const selectCountryStyles = {
        control: (styles) => ({
            ...styles, borderRadius: '10px',
            boxShadow: 'none', height: '45px', minHeight: '34px', flexWrap: 'no-wrap',
            "@media(max-width: 1540px)": {
                height: '40px'
            },
            "@media(max-width: 1366px)": {
                height: '30px'
            }
        }),
        option: (styles) => ({
            ...styles, display: 'flex', alignItems: 'center', background: 'transparent', color: '#000000', fontSize: '1.2rem',
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem',
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        input: (styles) => ({
            ...styles, fontSize: '1.2rem',
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: '1.2rem',
            "@media(max-width: 1540px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }

        }),
        singleValue: (styles) => ({
            ...styles, fontSize: '1.2rem',
            "@media(max-width: 1540px))": {
                fontSize: '1.1rem',
            },
            "@media(max-width: 1366px)": {
                fontSize: '0.9rem'
            }
        }),
        dropdownIndicator: (styles) => ({
            ...styles, color: '#E8339E', width: '40px'
        })
    };

    return (
      <MyProfileContainer>
        <div className="banner">
          <div className="goBack" onClick={() => navigate(-1)}>
            <img src={arrowBack} alt="back" className="backIcon" />
            <p className="backText">
              <FormattedMessage id="go_back" defaultMessage="ir atrás" />
            </p>
          </div>

          <div className="bannerInformation">
            <div className="networkContainer">
              <div className="profilePhotoContainer">
                <ProfileImage image={userData?.image_profile ?? avatar}>
                  <div className="imageEdit">
                    <label htmlFor="file-input" className="fileLabel">
                      <img src={edit} alt="edit" className="editPhoto" />
                    </label>

                    <input
                      className=""
                      type="file"
                      id="file-input"
                      accept="image/png, image/jpeg"
                      value={image}
                      onChange={(e) => {
                        setImage(e.target.value);
                        handleImageUpload({
                          event: e,
                          maxWidthOrHeight: 1024,
                        }).then((compressedFile) => {
                          getBase64({ file: compressedFile }).then((image) => {
                            setImagesToSend([
                              { data_url: image, file: e.target.files[0] },
                            ]);
                          });
                        });
                      }}
                    />
                  </div>

                  <div className="playIconContainer">
                    <img
                      src={play}
                      alt="avatar"
                      className="play"
                      onClick={() => {
                        setShowModalVideo(true);
                      }}
                    />
                  </div>
                </ProfileImage>
              </div>

              <div className="socialNetworksProfileContainer">
                <ProfileSocialNetworks
                  userSocialNetworksAccounts={userData?.social_networks}
                  canEdit={true}
                ></ProfileSocialNetworks>
              </div>
            </div>

            <div className="data">
              <div className="fileName">
                <p className="name">
                  {userType === UserTypes.INFLUENCER
                    ? toUpperCase({
                        fullName: `${userData?.name}  ${userData?.family_name}`,
                      })
                    : toUpperCase({ fullName: `${userData?.name}` })}
                </p>

                {userData?.verified && (
                  <div className="checkContainer">
                    <img src={check} alt="check" className="check" />
                  </div>
                )}
              </div>

              <div className="firstFile">
                <div className="item" ref={countriesRef}>
                  <p className="city">
                    {userAddressAux?.city ? (
                      <p>
                        {
                          countrySelectList.find(
                            (c) => c.value === userAddressAux?.country_id
                          )?.label
                        }
                        ,{` ${userAddressAux?.city}`}
                      </p>
                    ) : (
                      <p>
                        <FormattedMessage
                          id="my_profile_text_6"
                          defaultMessage="Edita tu ciudad/país"
                        />
                      </p>
                    )}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="edit"
                    onClick={() => {
                      setShowCountriesOptions(!showCountriesOptions);
                    }}
                  />

                  {showCountriesOptions && (
                    <div className="options">
                      <p className="apply" onClick={sendAddress}>
                        <FormattedMessage
                          id="create_collaboration_text_6"
                          defaultMessage="Aplicar"
                        />
                      </p>
                      <input
                        type="text"
                        placeholder={intl.formatMessage({ id: "address_city" })}
                        className="cityInput"
                        value={cityAux}
                        onChange={(event) => {
                          setCityAux(event.target.value);
                        }}
                      />

                      <Select
                        options={countrySelectList}
                        styles={selectCountryStyles}
                        defaultValue={countrySelectList.find(
                          (c) => c.value === countryIdAux
                        )}
                        placeholder={intl.formatMessage({
                          id: "address_country",
                        })}
                        onChange={(event) => {
                          setCountryIdAux(event.value);
                        }}
                        components={{ Option: IconCountryOption }}
                        //className='countrySelect'
                      />
                    </div>
                  )}
                </div>

                <div className="languageContainer">
                  <div className="item" ref={languagesRef}>
                    <p className="language">
                      {userData?.languages.length > 0 ? (
                        <span>
                          {userData?.languages.map((langId, index) => {
                            if(index < 4){
                              const lang = languageSelectList.find(
                                (lang) => lang.value == langId
                              );
                              const lastOne = userData?.languages.length - 1 === index || index === 3; 
                              return (
                                <TooltipLight
                                  title={lang.label}
                                  placement="top"
                                  key={langId}
                                >
                                  <span>{lang?.convention + ( lastOne ? "" : "/ ")}</span>
                                </TooltipLight>
                              );
                            }
                          })}
                        </span>
                      ) : (
                        <p>
                          <FormattedMessage
                            id="my_profile_text_7"
                            defaultMessage="Edita tus idiomas"
                          />
                        </p>
                      )}
                    </p>

                    <img
                      src={edit}
                      alt="edit"
                      className="edit"
                      onClick={() => {
                        setShowLanguagesOptions(!showLanguagesOptions);
                      }}
                    />

                    {showLanguagesOptions && (
                      <div className="options languagesOptions">
                        <div className="languagesOptionsHeader">
                          <p>Max. 4</p>
                          <p className="apply" onClick={sendLanguages}>
                            <FormattedMessage
                              id="create_collaboration_text_6"
                              defaultMessage="Aplicar"
                            />
                          </p>
                        </div>
                        <div className="optionsContainer">
                          {languageSelectList.map((language) => (
                            <LanguageOption
                              language={language}
                              languagesId={languagesAux}
                              setLanguagesId={setLanguagesAux}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="share">
                    <img
                      src={share}
                      alt="edit"
                      className="shareIcon"
                      onClick={() => {
                        setShowSocialMedia(!showSocialMedia);
                      }}
                    />
                    {showSocialMedia && (
                      <SocialMedia
                        shareUrl={`${URLplatform}/${userData?.username}`}
                      />
                    )}
                  </div>

                  <div className="scoreContainer">
                    <p className="score">{userData?.rating ?? "N/R"}</p>
                    <img src={star} alt="star" className="star" />
                  </div>
                </div>
              </div>

              <div className="descriptionContainer" ref={descriptionRef}>
                {showDescriptionArea ? (
                  <div className="writeDescription">
                    <p className="apply" onClick={sendDescription}>
                      <FormattedMessage
                        id="create_collaboration_text_6"
                        defaultMessage="Aplicar"
                      />
                    </p>
                    <textarea
                      maxLength={250}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      value={descriptionAux}
                      onChange={(event) => {
                        if (event.target.value.length < 251) {
                          setDescriptionAux(event.target.value);
                        }
                      }}
                    ></textarea>
                    <p className="maxDescription">
                      <FormattedMessage
                        id="add_product_text_8"
                        defaultMessage="Max 250 caracteres"
                      />
                    </p>
                  </div>
                ) : !userData?.description ? (
                  <div className="descriptionEmpty">
                    <p className="description">
                      <FormattedMessage
                        id="my_profile_text_8"
                        defaultMessage="Edita tu descripción"
                      />
                    </p>
                    <div className="item">
                      <hr />
                      <hr />
                      <hr />
                    </div>
                  </div>
                ) : (
                  <div className="descriptionContent">
                    <p>{userData?.description}</p>
                  </div>
                )}

                <img
                  src={edit}
                  alt="editar"
                  className="edit"
                  onClick={() => {
                    setShowDescriptionArea(!showDescriptionArea);
                  }}
                />
              </div>
              <div className="lastFile">
                <div className="item" ref={categoriesRef}>
                  <p className="categories">
                    {userData?.categories.length > 0 && (
                      <p>
                        {userData?.categories.map((categId, index) => {
                          if (index < 4) {
                            const category = categorySelectList.find(
                              (cat) => cat.value === categId
                            );
                            return `#${category?.label}   `;
                          }
                        })}
                      </p>
                    )}

                    {userData?.categories.length === 0 && (
                      <p>
                        <FormattedMessage
                          id="my_profile_text_9"
                          defaultMessage="Agrega tus categorías"
                        />
                      </p>
                    )}
                  </p>
                  <img
                    src={edit}
                    alt="edit"
                    className="edit"
                    onClick={() => {
                      setShowCategoriesOptions(!showCategoriesOptions);
                    }}
                  />

                  {showCategoriesOptions && (
                    <div className="options">
                      <p className="apply" onClick={sendCategories}>
                        <FormattedMessage
                          id="create_collaboration_text_6"
                          defaultMessage="Aplicar"
                        />
                      </p>
                      {categorySelectList.map((category) => (
                        <CategoriesOption
                            category={category}
                            categoriesId={categoriesAux}
                            setCategoriesId={setCategoriesAux}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="socialNetworksProfileContainerMobile">
              <p className="text">
                <FormattedMessage
                  id="my_profile_text_10"
                  defaultMessage="Conecta tus redes"
                />
              </p>
              <ProfileSocialNetworks
                userSocialNetworksAccounts={userData?.social_networks}
                canEdit
              ></ProfileSocialNetworks>
            </div>
          </div>
        </div>
        <div className="myProfileBody">
          {userType === UserTypes.COMPANY && <MyProfileProducts />}
          {userType === UserTypes.INFLUENCER && <MyProfileFirstSection />}
          <MyProfileCollaborations />
          {userType === UserTypes.INFLUENCER && <MyProfileGallery />}
        </div>

        {showModalVideo && (
          <ModalUploadVideo setShowModalVideo={setShowModalVideo} />
        )}

        {isLoading && <Spinner />}

        {showImageCrop && (
          <ImageCrop
            imgSrc={imagesToSend[0]}
            setShowImageCrop={setShowImageCrop}
            setImagesToSend={setImagesToSend}
            setImage={setImage}
            xRadio={9}
            yRadio={9}
            save={saveImage}
            isLoading={isLoading}
          />
        )}
      </MyProfileContainer>
    );
}