import React, { useState, useEffect } from "react";
import { InfluencerCardInvoiceContainer } from "./InfluencerCardInvoiceStyled";
import avatar from './../../utils/images/avatar_fluincr.png';
import { formatNumber, toUpperCase } from "../../services/generalSevices";
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import LikeInfluencer from "../LikeInfluencer/LikeInfluencer";
import { CurrencyContext } from "../../context/currencyContext";
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserTypes } from "../../utils/constants/user";

export default function InfluencerCardInvoice({ influencer, page }) {

    const navigate = useNavigate();

    const { userToken, userType } = useContext(UserContext2);
    const { currency, convertCurrency } = useContext(CurrencyContext);
    const { countrySelectList, categorySelectList } = useContext(StaticDataContext);

    const [country, setCountry] = useState(null);
    const [price, setPrice] = useState(null);

    useEffect(() => {
        if (influencer?.address?.country_id !== null) {
            const country = countrySelectList.find((element) => element.value === influencer?.address?.country_id);
            setCountry(country);
        }
    }, [influencer, countrySelectList])

    useEffect(() => {
        const amount = influencer?.rate_by_service_list?.filter(rate => rate?.price > 0)
            .reduce((precioMin, serviceActual) => {
                return (serviceActual.price < precioMin || precioMin === 0) ? serviceActual.price : precioMin;
            }, 0);

        const auxCurrency = currency.symbol;

        setPrice(convertCurrency(amount, auxCurrency).toFixed(2));
    }, [currency])

    return (
        <InfluencerCardInvoiceContainer page={page} >

            <div className="image" style={{ backgroundImage: `url(${(influencer.image_profile !== null) ? influencer.image_profile : avatar})` }} >

                <div className="favoriteContainer">
                    {(userType === UserTypes.COMPANY || !userToken) ? (<LikeInfluencer profileId={influencer.id}/>) : (<div className="favorite" />)}
                </div>

                <div className="data">
                    <p><span className="currencySymbol">{currency.symbol}</span> {formatNumber(price)}</p>
                </div>
            </div>

            <h3 className="name" onClick={() => {
                navigate(`/${influencer?.username}`)
            }}>{`${influencer?.name} ${influencer?.family_name}`}</h3>

            <div className="influencerData">
                <img src={country?.image} alt="" className="influencerCountry" />
                <div className="categories">
                    {
                        influencer?.categories?.map((categoryId, index) => {
                            if (index < 3) {
                                return (
                                    <span className="category">#{categorySelectList.find((element) => element.value === categoryId)?.label || ''}</span>
                                )
                            }
                        })

                    }
                </div>
            </div>
        </InfluencerCardInvoiceContainer>
    )
}