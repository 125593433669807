import { useEffect, useContext, createContext, useState } from "react";
import { StaticDataContext } from "./staticDataContext";


export const FiltersContext = createContext();

export default function FiltersProvider({ children }) {

    const { countrySelectList, categorySelectList } = useContext(StaticDataContext);

    const [featuredFilter, setFeaturedFilter] = useState(false);
    const [newUserFilter, setNewUserFilter] = useState(false);
    const [languageFilter, setLanguageFilter] = useState([]);
    const [ratingFilter, setRatingFilter] = useState(null);
    const [verifiedFilter, setVerifiedFilter] = useState(false);
    const [genderFilter, setGenderFilter] = useState([]);
    const [ageFilter, setAgeFilter] = useState([]);
    const [networkFilter, setNetworkFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [countryFilter, setCountryFilter] = useState([]);
    const [priceFilter, setPriceFilter] = useState([]);
    const [queryPrice, setQueryPrice] = useState([]);
    const [queryPriceId, setQueryPriceId] = useState([]);
    const [inputFilter, setInputFilter] = useState('');
    const [favoriteFilter, setFavoriteFilter] = useState(false);

    const [orderBy, setOrderBy] = useState('');

    //const [offset, setOffset] = useState(0);

    const resetFilters = () => {
        setFeaturedFilter(false);
        setNewUserFilter(false);
        setLanguageFilter([]);
        setRatingFilter(0);
        setVerifiedFilter(false);
        setGenderFilter([]);
        setAgeFilter([]);
        setNetworkFilter([]);
        setCategoryFilter([]);
        setCountryFilter([]);
        setPriceFilter([]);
        setQueryPrice([]);
        setQueryPriceId([]);
        setInputFilter('');
        setOrderBy('');
    }

    return (
      <FiltersContext.Provider
        value={{
          featuredFilter,
          setFeaturedFilter,
          newUserFilter,
          setNewUserFilter,
          languageFilter,
          setLanguageFilter,
          ratingFilter,
          setRatingFilter,
          verifiedFilter,
          setVerifiedFilter,
          genderFilter,
          setGenderFilter,
          ageFilter,
          setAgeFilter,
          networkFilter,
          setNetworkFilter,
          categoryFilter,
          setCategoryFilter,
          countryFilter,
          setCountryFilter,
          priceFilter,
          queryPrice,
          setQueryPrice,
          queryPriceId,
          setQueryPriceId,
          setPriceFilter,
          inputFilter,
          setInputFilter,
          orderBy,
          setOrderBy,
          resetFilters,
          favoriteFilter,
          setFavoriteFilter
        }}
      >
        {children}
      </FiltersContext.Provider>
    );

};