import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalCarouselContainer = styled.div`

    color: ${colors.greyLetter};
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100; 
    
    .carouselBody{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        width: 50%;
        background-color:#ffffff;
        //height: 80vh;
        max-height: 95vh;
        overflow-y: auto;
        min-height: 100px;
        //position: relative;
        border-radius: 2px;
        padding: 10px 15px;

        .carouselHeader{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 10px 0;
            .close{
                align-self: flex-end;
                width: 30px;
                cursor: pointer;
            }
        }

        .navButton{
            button{
                padding: 0;
                margin: 0;
                svg{
                    font-size :3rem ;
                    fill:${colors.greyIcon};
                }  
            }    
        }

        .imageContainer{
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 80vh;
            
            .image{
                max-height: 70%;
            }
            .video{
                height: 70%;
                padding: 0;
            }
            .collaborationInfo{
                margin-top: 20px;
                display: flex;
                align-items: center;
                width:100%;
                margin-left: 160px;
                .imageColaborator{
                    width: 100px; 
                    height: 100px;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: cover;
                    background-color: red;
                    margin-right: 10px;
                }
                .nameColaborator{
                    font-size: 2rem;
                    text-transform: capitalize;
                }
            }
            
            
        }
        .video{ 
            background-color: ${colors.backgroundSecondary};
            height: 400px;
            display: flex;
            align-items: center;
            padding: 60px;    
        }
    }


    /**-------Responseive------- */
    ${media.largeDesktop}{
        .carouselBody{
            .imageContainer{
                .collaborationInfo{
                    .imageColaborator{
                        width: 80px; 
                        height: 80px;
                    }
                    .nameColaborator{
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
    ${media.bigmacdesktop}{
        .carouselBody{
            .imageContainer{
                .collaborationInfo{
                    .imageColaborator{
                        width: 70px; 
                        height: 70px;
                    }
                    .nameColaborator{
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
    ${media.macdesktop}{
        .carouselBody{
            .imageContainer{
                .collaborationInfo{
                    .imageColaborator{
                        width: 60px; 
                        height: 60px;
                    }
                    .nameColaborator{
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    ${media.desktop}{
        .carouselBody{
            .imageContainer{
                .collaborationInfo{
                    .imageColaborator{
                        width: 50px; 
                        height: 50px;
                    }
                    .nameColaborator{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

    ${media.laptop} {
        z-index: 100; 
        .carouselBody{
            width:75% ;
        }
        
    }
    ${media.largeMobile} {
        .carouselBody{
            width:100% ;
            height: 100%;
            max-height: 100vh;
        }
        
    }

`