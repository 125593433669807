import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getVerifyToken } from "../../api/adminApi";
import Alert from "../../components/Alert/Alert";
import Spinner from "../../components/Spinner/Spinner";
import { AdminRedirectContainer } from "./AdminLoginStyled";
import { FormattedMessage } from 'react-intl';
import { useDispatch } from "react-redux";
import { saveToken } from '../../store/token/action';
import { UserContext2 } from "../../context/user/userContext";

export default function AdminLoginProfile() {

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    let userToken = urlParams.get('token');
    let userType = urlParams.get('userType');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const { setUserToken, setUserType } = useContext(UserContext2);

    useEffect(() => {

        if (userToken !== null && userType !== null) {

            getVerifyToken({ token: userToken, userType: userType }).then((response) => {

                if (response.admin === true) {
                    sessionStorage.setItem('fluincrToken', JSON.stringify(userToken));
                    sessionStorage.setItem('fluincrUser', JSON.stringify(userType));
                    sessionStorage.setItem('isAdmin', true);
                    dispatch(saveToken(userToken))
                    setUserToken(userToken);
                    setUserType(userType);
                    navigate('/myprofile');
                } else {
                    setShowErrorAlert(true);
                    setIsLoading(false);

                }
            })

        }
    }, [userType, userToken])

    return (
        <AdminRedirectContainer>

            {(isLoading) && (<Spinner />)}

            {(showErrorAlert) && (<Alert
                title={<FormattedMessage id="invalid_user_title" defaultMessage="Usuario invalido" />}
                text={<FormattedMessage id="invalid_user_text" defaultMessage="Por favor verifique e intente de nuevo" />}
                setShowAlert={setShowErrorAlert}
                confirmText={<FormattedMessage id="header_button_text" defaultMessage="Iniciar sesión" />}
                onConfirm={() => { navigate('/signin') }}
            />)}

        </AdminRedirectContainer>

    )
}