import { createContext, useEffect, useState } from "react";
import { CurrencyAPI } from "../api/static-data/currency";


export const CurrencyContext = createContext();

export default function CurrencyProvider({ children }) {

    const curre = localStorage.getItem('currency');
    const [currency, setCurrency] = useState(null);
    const [currenciesRates, setCurrenciesRates] = useState([]);
    const [currenciesList, setCurrenciesList] = useState([]);

    useEffect(() => {
        if (curre === null) {
            setCurrency({
                deleted: "0",
                id: 151,
                name: "United States Dollar",
                symbol: "USD",
                usedSymbol: true
            });
            localStorage.setItem('currency', JSON.stringify({
                deleted: "0",
                id: 151,
                name: "United States Dollar",
                symbol: "USD",
                usedSymbol: true
            }));
        } else {
            setCurrency(JSON.parse(curre));
        }

        CurrencyAPI.getRates().then((response) => {
            setCurrenciesRates(response);
        })

        CurrencyAPI.getCurrencies().then((usedCurrencies) => {
            setCurrenciesList(usedCurrencies);
        })
    }, [])


    const handlerCurrency = (currencyNew) => {
        setCurrency(currencyNew);
        localStorage.setItem('currency', JSON.stringify(currencyNew));
    }

    const convertCurrency = (amount = 0, toCurrencySymbol)  => {
        const currencyRate = currenciesRates.find((currencyRate) => currencyRate.name == toCurrencySymbol);
        return currencyRate ? amount * currencyRate?.rate : amount;
    }

    const reconvertCurrency = (amount = 0, fromCurrencySymbol)  => {
        const currencyRate = currenciesRates.find((currencyRate) => currencyRate.name == fromCurrencySymbol);
        return currencyRate ? amount / currencyRate?.rate : amount;
    }

    return (
        <CurrencyContext.Provider value={{ currency, currenciesList, setCurrency, handlerCurrency, convertCurrency, reconvertCurrency }}>
            {children}
        </CurrencyContext.Provider >
    )

};