import './../../Bootstrap.css';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PaymentAPI } from '../../api/payment/payment';

const ModalPayPalLogin = ({ open, setOpen, collaborationId }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        sessionStorage.setItem("paypalLoginOrigin", `mycollaborations/${collaborationId}`);
        PaymentAPI.getAuthorizarionUrl().then(response => {
            window.location = response?.url;
        }).catch(error => {
            console.error("Error getting PayPal authorization URL", error);
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className='p-3 pt-1' style={{minWidth: '22vw'}}>
                <DialogTitle className="fl-text-primary d-flex justify-content-center fs-4" >
                    <FormattedMessage id="modalPayPalLoginTitle" defaultMessage="Iniciar sesión con PayPal" />
                </DialogTitle>
                <DialogContent className='my-2'>
                    <p style={{maxWidth: '450px'}}>
                        <FormattedMessage id="modalPayPalLoginDescription" defaultMessage="Para aceptar una colaboración primero debes vincular tu cuenta de PayPal" />
                    </p>
                </DialogContent>
                <DialogActions className='p-2'>
                    <button onClick={handleClose} className="fl-button-secondary">
                        <FormattedMessage id="modalPayPalLoginButtonCancel" defaultMessage="Cancelar" />
                    </button>
                    <button onClick={handleLogin} className="fl-button-primary">
                        <FormattedMessage id="modalPayPalLoginButtonConfirm" defaultMessage="Iniciar sesión" />
                    </button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default ModalPayPalLogin