import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const AdminRedirectContainer = styled.div`
    
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`