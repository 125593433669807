import React, { useContext, useEffect } from "react";
import { MyProfileCollaborationsContainer } from "./MyProfileCollaborationsStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import camera from './../../utils/images/camera.svg';
import { useState } from "react";
import CollaborationItemCardPrivateProfile from "../CollaborationItemCardPrivateProfile/CollaborationItemCardPrivateProfile";
import { CartContext } from "../../context/cartContext";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { UserContext2 } from "../../context/user/userContext";

export default function MyProfileCollaborations() {

    const { userData, userType } = useContext(UserContext2);
    const [collaborationsItems, setCollaborationsItems] = useState([]);

    useEffect(() => {
        // TODO: Revisar
        if(userData){
            setCollaborationsItems(userData?.profile_data?.items || []);
        } else {
            setCollaborationsItems([]);
        }
    }, [userData])


    return (
        <MyProfileCollaborationsContainer>
            <h2 className="title"><FormattedMessage id="my_profile_subtitle_2" defaultMessage="Colaboraciones" /></h2>
            <div className="cardsContainer">

                {collaborationsItems.map((collaborationItem) => {
                    return (
                        <CollaborationItemCardPrivateProfile collaborationItem={collaborationItem} profileUserType={userType} />
                    )
                })}
                {(collaborationsItems.length < 1) && (<>
                    <div className="card" >
                        <img src={camera} alt="camera" />
                        <p><FormattedMessage id="my_profile_text_18" defaultMessage="Nuevas colaboraciones pronto" /></p>
                    </div>
                    <div className="card" >
                        <img src={camera} alt="camera" />
                        <p><FormattedMessage id="my_profile_text_18" defaultMessage="Nuevas colaboraciones pronto" /></p>
                    </div>
                    <div className="card" >
                        <img src={camera} alt="camera" />
                        <p><FormattedMessage id="my_profile_text_18" defaultMessage="Nuevas colaboraciones pronto" /></p>
                    </div>
                    <div className="card" >
                        <img src={camera} alt="camera" />
                        <p><FormattedMessage id="my_profile_text_18" defaultMessage="Nuevas colaboraciones pronto" /></p>
                    </div>
                </>)}

            </div>

        </MyProfileCollaborationsContainer>
    )
}