import styled from "styled-components";
import { media } from "../../utils/styles/media";
import { colors } from './../../utils/styles/colors';

export const InfluencersContainer = styled.div`
    
    padding: 50px;
    .goBack{
        display: flex;
        .backIcon{
            margin-right: 10px;
        }
    }
    .influencersBody{
        display: flex;
        .menu{
            width: 20%;
            //max-height: 250px;
            position: sticky;
            top: 10px; 
            
            .filter{
                padding: 10px 0;
                font-size: 1.1rem;
                .filterName{
                    display: flex;
                    align-items: baseline;
                    img{
                        width: 15px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    .selected{
                        color: ${colors.hightlight};
                    }
                }
                .filterName:hover{
                    color: ${colors.hightlight};
                    cursor: pointer;
                }
                .options{
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    margin-top: .5em;
                    margin-right: 3em;
                    label{
                        margin: 2px;
                        input[type='checkbox'] {
                            accent-color: ${colors.hightlight};
                            margin-right: 5px;                               
                        }
                    }
                    max-height: 20em;
                    overflow-y: scroll;
                }
            }
        }
        .menuMobile{
            display: none;
        }
        .influencersContent{
            width: 80%;
            .results{
                display: flex;
                justify-content: space-between;
                
                font-size: 1.1rem;
                .totalResults{
                    font-weight: 600;
                }
                .orderBy{
                    position: relative;
                    color: ${colors.darkGrey};
                    .orderByTitle{
                        font-weight: bold;
                        color: ${colors.hightlight};
                        display: flex;
                        align-items: baseline;
                        img{
                            width: 15px;
                            margin-left: 10px;
                            cursor: pointer;
                        } 
                    }
                    .menuOrderBy{
                        position: absolute;
                        background-color: #ffffff;
                        box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                        padding: 10px;
                        border-radius: 10px;
                        width: 150px;
                        right: 0;
                        label{
                            display: flex;
                            align-items: center;
                            margin: 5px 0px;
                            font-size: 1rem;
                            input{
                                height: 20px;
                                width: 20px;
                                margin-right: 8px;
                                accent-color: ${colors.hightlight};
                            }
                        }
                        label:hover{
                            color: ${colors.hightlight};
                            
                        }
                    }
                }
            }
            .cards{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding-top: 10px;
            }
            .noResults{
                min-height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .seeMore{
                margin-top: 20px;
                text-align: center;
                font-weight: 700;
                font-size: 1.4rem;
                color: ${colors.hightlight};
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
    }

    /**-------------Responsive--------------- */
    ${media.desktop}{

    }

    ${media.laptop}{
        padding: 30px;
        .influencersBody{
            display: flex;
            width: 100%;
            .menu{
                width: 28%;
                .filter{
                    .filterName{
                        font-size: 0.9rem;
                        img{
                        width: 13px;
                        margin-left: 8px;
                    }
                    }
                }
            }
            .influencersContent{
                width:80%;
                padding-left: 2px;
                .results{
                    .totalResults{
                        font-size: 1rem;
                    }
                    .orderBy{
                        .orderByTitle{
                            font-size: 1rem;
                            img{
                                width: 13px;
                                margin-left: 8px;
                            } 
                        }
                        .menuOrderBy{
                            position: absolute;
                            background-color: #ffffff;
                            box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                            padding: 10px;
                            border-radius: 10px;
                            width: 140px;
                            label{
                                display: flex;
                                align-items: center;
                                margin: 4px 0px;
                                font-size: 0.9rem;
                                input{
                                    height: 15px;
                                    width: 15px;
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                }
                .seeMore{
                    font-size: 1.2rem;
                }
            }
        }
    }

    ${media.largeMobile}{
        padding: 20px;
        .influencersBody{
            flex-direction: column;
            .menu{
                display: none;
            }
            .menuMobile{
                display: block;
                position: initial;
                width: 100%;
                margin-bottom: 20px;
                .filterTitle{
                    padding: 10px 0;
                    font-size: 1.1rem;
                    color: ${colors.hightlight};
                    font-weight: bold;
                    display: flex;
                    img{
                        width: 15px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
                .subFilter{
                    margin-left: 20px;
                    .filter{
                        margin-top: 10px;
                        .filterName{
                            font-size: 0.9rem;
                            img{
                                width: 13px;
                                margin-left: 8px;
                            }
                        }
                    }
                   
                    .options{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 10px;
                        margin-left: 10px;
                        label{
                            margin: 3px;
                            input[type='checkbox'] {
                                accent-color: ${colors.hightlight};
                                margin-right: 10px;                               
                            }
                        }  
                    }
                }  
            }
            .influencersContent{
                width: 100%;
                .cards{
                    justify-content: space-between;
                }
                .noResults{
                    min-height: 50vh;
                }
                .seeMore{
                    font-size: 1.1rem;
                }
                
            }
        }
    }



`