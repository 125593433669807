import styled from 'styled-components';
import { colors } from '../../utils/styles/colors';
import { media } from '../../utils/styles/media';

export const InfluencerCardInvoiceContainer = styled.div`
    
    //width: 10vw;
    width: 20%;
    padding: 10px;
    .image{
        width: 100%;
        //height: 250px;
        height: 9vw;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 8px;
        .favoriteContainer{
            width: 40px;
            height: 40px;
            .favorite{
                width: 40px;
                cursor: pointer;
            }
        }
        .data{
            display: flex;
            width: 100%;
            justify-content: space-between;
            p{
                background-color: rgb(61, 61, 61, 0.7);
                color: #ffffff;
                padding: 5px 10px;
                border-radius:15px;
                font-weight: 500;
                font-size: 1.2rem;
                font-family: 'Montserrat', sans-serif;
                .currencySymbol{
                    font-size: 0.9rem;
                }
            }
        }
    }

    .name{
        font-size: 2.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.text};
    }
    .name:hover{
        opacity: 0.8;
        cursor: pointer;
    }

    .influencerData{
        display: flex;
        align-items: baseline;
        font-size: 2rem;
        align-items: center;

        img.influencerCountry{
            height:30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 3px;
        }
        .categories{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-transform: lowercase;
            font-weight: bold;
            .category{
                padding-right:3px;
                font-size: 1.4rem; 
            }
        }
        
    }


    /**---------Responsive-----------*/
    ${media.largeDesktop}{
        padding: 8px;
        .image{
            .favoriteContainer{
                width: 35px;
                height: 35px;
                .favorite{
                    width: 35px;
                }
            }
            .data{
                p{
                    padding: 3px 8px;
                    font-size: 1.1rem;
                    .currencySymbol{
                        font-size: 0.8rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.6rem;
            
        }
        .influencerData{
            font-size: 1.2rem;
        }

    }

    ${media.bigmacdesktop}{
        padding: 6px;
        .image{
            .favoriteContainer{
                width: 35px;
                height: 35px;
            }
            .data{
                p{
                    padding: 2px 6px;
                    font-size: 1rem;
                    .currencySymbol{
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.1rem;
        }
        .influencerData{
            font-size: 0.8rem;
            img.influencerCountry{
                height:23px;
                width: 23px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 0.8rem; 
                }
            }
        }
    }

    ${media.macdesktop}{
        padding: 4px;
        .image{
            .favoriteContainer{
                width: 32px;
                height: 32px;
            }
            .data{
                p{
                    padding: 2px 6px;
                    font-size: 0.9rem;
                    .currencySymbol{
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
    
    ${media.desktop}{
        padding: 4px;
        .image{
            .favoriteContainer{
                width: 30px;
                height: 30px;
                .favorite{
                    width: 30px;
                }
            }
            .data{
                p{
                    padding: 2px 6px;
                    font-size: 0.8rem;
                    .currencySymbol{
                        font-size: 0.5rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.1rem;
        }
        .influencerData{
            font-size: 0.8rem;
            img.influencerCountry{
                height:23px;
                width: 23px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 0.8rem; 
                }
            }
        }
    }

    @media(max-width: 1112px){
        //width: 25%;
        /* :last-child{
            display: none;
        } */
        /* .name{
            font-size: 1rem;
        } */
    }

    ${media.smallDesktop}{
        width: 20%;
        .image{
            height: 16vw;
            .favoriteContainer{
                width: 30px;
                height: 30px;
                .favorite{
                    width: 30px;
                }
            }
        }
    }

    
    ${media.tablet}{
        padding: 4px;
        .image{
            .favoriteContainer{
                .favorite{
                    width: 20px;
                }
            }
            .data{
                p{
                    font-size: 0.8rem;
                }
            }
        }
        .name{
            font-size: 1rem;
        }
        .influencerData{
            align-items: center;
            img.influencerCountry{
                height:20px;
                width: 20px;
            }
            span{
                font-size: 0.7rem;
            }
        }
    }
    @media(max-width: 744px){
        .name{
            font-size: 0.9rem;
        }
    }
    @media(max-width: 659px){
        .name{
            font-size: 0.8rem;
        }
    }


    ${media.largeMobile}{
        width: 50%;
        
        .image{
            //height: 150px;
            height: 40vw;
            .favoriteContainer{
                width: 50px;
                height: 50px;
            }
            .data{
                p{
                    font-size: 0.8rem;
                    padding: 5px 10px;
                }
            }
        }
        :last-child{
            display: none ;
        } 
        .name{
            font-size: 1.2rem;
        }
        
    }
    
    
`