import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, ChatContainer, ProfileImage } from "./ChatStyled";

import avatar from './../../utils/images/avatar_fluincr.png';
import minus from './../../utils/images/minus_blue.svg';
import maxi from './../../utils/images/maximize_blue.svg';
import close from './../../utils/images/close_blue.svg';
import video from './../../utils/images/video_chat.svg';
import image from './../../utils/images/images_chat.svg';
import file from './../../utils/images/sticky_note_chat.svg';
import send from './../../utils/images/send.svg';
import smile from './../../utils/images/smile_chat.svg';

import EmojiPicker from "emoji-picker-react";

import { environment } from "./../../utils/constants/constants";
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getChatHistory } from "../../api/messagesApi";
import SmallSpinner from "../SmallSpinner/SmallSpinner";
import { getBase64 } from "../../services/generalSevices";
import ImageUploading from 'react-images-uploading';
import { formatDateLocal, formatTimeLocal, toUTCString } from "../../services/dateServices";
import { handleImageUpload } from "../../services/compress";
import { uploadImageS3 } from "../../utils/common/s3";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";


export default function Chat({ collaboration, setShowChat }) {

    const bottomRef = useRef(null);

    const { userData, userType } = useContext(UserContext2);

    const [messagesLimit, setMessagesLimit] = useState(20);
    const [isLoading, setIsLoading] = useState(true);

    const isAdmin = sessionStorage.getItem('isAdmin');

    let client = null;

    if (userType === UserTypes.INFLUENCER) {
        client = new W3CWebSocket(`wss://${environment}/chat/stocks/${userData?.id}/${collaboration?.company?.id}/1`);
    } else {
        client = new W3CWebSocket(`wss://${environment}/chat/stocks/${collaboration?.influincer?.id}/${userData?.id}/0`);
    }

    const [messages, setMessages] = useState([]);

    const [hideChat, setHideChat] = useState(false);
    const [showEmojiOptions, setShowEmojiOptions] = useState(false);

    const [inputChat, setInputChat] = useState('');

    const [base64Image, setBase64Image] = useState({});
    const [base64File, setBase64File] = useState({});
    const [base64Video, setBase64Video] = useState({});

    useEffect(() => {
        if (Object.keys(base64Image).length > 0) {
            sendImage();
        }
    }, [base64Image])

    useEffect(() => {
        if (Object.keys(base64Video).length > 0) {
            sendVideo();
        }
    }, [base64Video])

    useEffect(() => {
        if (Object.keys(base64File).length > 0) {
            sendFile();
        }
    }, [base64File])

    function onClick(emojiData, event) {
        //setSelectedEmoji(emojiData.unified);
        const newText = inputChat.concat(emojiData.emoji)
        setInputChat(newText)
    }

    function sendPong() {
        const payload = {
            message: 'PONG',
            type: '-1',
        };
        //client.onopen = () => client.send(JSON.stringify(payload));
        client.send(JSON.stringify(payload));
    }

    const getMessages = () => {

        if (userType === UserTypes.INFLUENCER) {
            getChatHistory({ influencerId: userData?.id, companyId: collaboration?.company?.id, limit: messagesLimit }).then((response) => {
                setMessages(response);
            })
        } else {
            getChatHistory({ influencerId: collaboration?.influincer?.id, companyId: userData?.id, limit: messagesLimit }).then((response) => {
                setMessages(response);
            })
        }

    };

    useEffect(() => {
        getMessages();

        client.onopen = (event) => {
            //console.log('WebSocket Client Connected', event);
            setIsLoading(false);
        }
        client.onerror = (event) => {
            //console.warn('An error occurred', event.data);
        }
        client.onmessage = (event) => {
            //console.log(event)
            if (event.data === 'PING') {
                sendPong();
            } else if (JSON.parse(event.data).tipo === '999') {
                getMessages();
            } else {
                //console.log('Llegando mensaje');
                getMessages();
            }
        }

        /* getRoom({ companyId: vendorChat.planner_id, plannerId: id }).then((response) => {
            console.log("ROOM", response)
            setIsVendorBlocked(response.block_planner)
        }) */


    }, [])

    const sendMessage = (event) => {
        event.preventDefault();

        const payload = {
            message: inputChat,
            type: '0',
        };

        if (inputChat.length > 0) {
            //client.onopen = () => client.send(JSON.stringify(payload))
            client.send(JSON.stringify(payload))
        } else {
            //console.log("No se puede")
        }
        setInputChat('')
    }

    const sendImage = () => {

        setIsLoading(true);

        if (Object.keys(base64Image).length > 0) {

            const fileBody = {
                base64File: base64Image.base64.split(',')[1],
                formatFile: base64Image.type,
                address: ""
            }
            if (userType === UserTypes.INFLUENCER) {
                fileBody.address = `fluincr/${userData?.account}/message`
            } else {
                fileBody.address = `company/${userData?.account}/message`
            }

            
            uploadImageS3(fileBody.address, fileBody.formatFile, base64Image.base64).then((fileLink) => {
                if (fileLink) {
                    const urlFile = fileLink.replace(/['"]+/g, '')

                    setIsLoading(false);
                    const auxArray = [urlFile]

                    const payload = {
                        message: JSON.stringify(auxArray),
                        type: '1',
                    };

                    client.send(JSON.stringify(payload));
                    setBase64Image({})
                }
            })
        }
    }


    const sendFile = () => {

        setIsLoading(true);

        if (Object.keys(base64File).length > 0) {

            const fileBody = {
                base64File: base64File.base64.split(',')[1],
                formatFile: base64File.type,
                address: ""
            }
            if (userType === UserTypes.INFLUENCER) {
                //fileBody.address = `fluincr/${uniqueName}/message`
                fileBody.address = `fluincr/${userData?.account}/message`
            } else {
                //fileBody.address = `company/${uniqueName}/message`
                fileBody.address = `company/${userData?.account}/message`
            }

            uploadImageS3(fileBody.address, fileBody.formatFile, base64Image.base64).then((fileLink) => {

                if (fileLink) {
                    const urlFile = fileLink.replace(/['"]+/g, '')
                    setIsLoading(false);
                    const auxArray = [urlFile]

                    const auxMessage = { uri: JSON.stringify(auxArray), file_name: JSON.stringify(base64File.name) }
                    const payload = {
                        message: JSON.stringify(auxMessage),
                        type: '2',
                    };

                    client.send(JSON.stringify(payload));
                    setBase64File({})
                }
            })
        }
    }

    const sendVideo = () => {
        setIsLoading(true);

        if (Object.keys(base64Video).length > 0) {

            const fileBody = {
                base64File: base64Video.base64.split(',')[1],
                formatFile: base64Video.type,
                address: ""
            }
            if (userType === UserTypes.INFLUENCER) {
                //fileBody.address = `fluincr/${uniqueName}/message`
                fileBody.address = `fluincr/${userData?.account}/message`
            } else {
                //fileBody.address = `company/${uniqueName}/message`
                fileBody.address = `company/${userData?.account}/message`
            }

            uploadImageS3(fileBody.address, fileBody.formatFile, base64Video.base64).then((fileLink) => {
                if (fileLink) {
                    const urlFile = fileLink.replace(/['"]+/g, '')

                    setIsLoading(false);
                    const auxArray = [urlFile]

                    /* const auxMessage = { uri: JSON.stringify(auxArray), file_name: JSON.stringify(base64Video.name) } */

                    const payload = {
                        //message: JSON.stringify(auxMessage),
                        message: JSON.stringify(auxArray),
                        type: '3',
                    };

                    client.send(JSON.stringify(payload));
                    setBase64Video({})
                }
            })
        }
    }

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages])

    return (
        <ChatContainer showEmojiOptions={showEmojiOptions} isAdmin={isAdmin}>
            <div className="chatHeader">
                <div className="userData">

                    {(userType === UserTypes.INFLUENCER) ? (<ProfileImage image={(collaboration?.company?.imageProfile !== '' && collaboration?.company?.imageProfile !== null) ? collaboration?.company?.imageProfile : avatar} />) : (<ProfileImage image={(collaboration?.influincr?.imageProfile !== '' && collaboration?.influincr?.imageProfile !== null) ? collaboration?.influincr?.imageProfile : avatar} />)}

                    <div className="textData">
                        {(userType === UserTypes.INFLUENCER) ? (<p className="userName"> <span>{collaboration?.company?.nameCompany}</span>  </p>) : (<p className="userName"> <span>{collaboration?.influincr?.givenName}</span>  </p>)}
                        {/* <p className="subtext">no se que va</p> */}
                    </div>

                </div>
                <div className="buttons">
                    {(hideChat) ? (<img src={maxi} alt="" onClick={() => setHideChat(!hideChat)} className="maxi" />) : (<img src={minus} alt="" onClick={() => setHideChat(!hideChat)} />)}


                    <img src={close} alt="" onClick={() => setShowChat(false)} />
                </div>
            </div>
            {(!hideChat) && (<>
                <div className="messages">

                    {(isLoading) && <SmallSpinner />}

                    {messages.map((mssg, index) => {
                        if (index + 1 === messages.length) {
                            //setTimeLastMessage(mssg.sending_date.toString())
                        }
                        return (
                            <Card key={mssg.id} typeUser={(mssg.tipoUsuario === 'PLANNER') ? UserTypes.INFLUENCER : UserTypes.COMPANY} myUser={userType}>
                                <div>
                                    {(userType === UserTypes.INFLUENCER && mssg.tipoUsuario === 'VENDOR') && (<ProfileImage image={(collaboration?.company?.imageProfile !== '' && collaboration?.company?.imageProfile !== null) ? collaboration?.company?.imageProfile : avatar} />)}


                                    {(userType === UserTypes.COMPANY && mssg.tipoUsuario === 'PLANNER') && (<ProfileImage image={(collaboration?.influincr?.imageProfile !== '' && collaboration?.influincr?.imageProfile !== null) ? collaboration?.influincr?.imageProfile : avatar} />)}
                                </div>

                                <div className="message">
                                    {(mssg.tipo === 0) ?
                                        (<p className="textMessage">{mssg.message}</p>) :
                                        (mssg.tipo === 1) ?
                                            (<div className="imagesContainer">
                                                {(JSON.parse(mssg.message)).map((item) => {
                                                    return (<>
                                                        <a href={item} target="_blank" download >
                                                            <img src={item} alt="chat phot" className='imageAttached' />
                                                        </a>
                                                    </>
                                                    )
                                                })}
                                            </div>) :
                                            (mssg.tipo === 2) ?
                                                (<a href={(JSON.parse(mssg.message).uri).slice(2, -2)} download={(JSON.parse(mssg.message).file_name)} >
                                                    <div className="file">
                                                        <div className="cover">
                                                            <img src={file} alt="file" />
                                                        </div>
                                                        <div className="name">
                                                            {/* <p>{JSON.parse(mssg.message)?.file_name}</p> */}
                                                            <p>{(JSON.parse(mssg.message).file_name).slice(1, -1)}</p>
                                                        </div>
                                                    </div>
                                                </a>) :
                                                (mssg.tipo === 3) &&
                                                (<div className="imagesContainer">
                                                    <video src={JSON.parse(mssg.message)[0]} alt="chat video" className='imageAttached' preload="auto" controls />

                                                </div>)
                                    }


                                    <p className='time'>{(new Date(new Date().toUTCString()).toDateString() !== new Date(mssg.sending_date).toDateString()) && (`${formatDateLocal(mssg.sending_date)} / `)}  {formatTimeLocal(mssg.sending_date)} </p>
                                </div>

                            </Card>
                        )
                    })}
                    <div ref={bottomRef} style={{ width: '10px', height: '10px' }} />

                </div>

                <div className="chatFooter">
                    <div className="chatMenu">
                        <label htmlFor="chat-image-input" className="fileLabel">
                            <img src={image} alt="send phot" className="imageFile" />
                        </label>

                        <input type="file" id="chat-image-input" accept="image/png, image/jpeg" onChange={(e) => {
                            handleImageUpload({ event: e, maxWidthOrHeight: 600 }).then((compressedFile) => {
                                getBase64({ file: compressedFile }).then((image) => {
                                    setBase64Image({ base64: image, name: e.target.files[0].name, type: e.target.files[0].type.split('/')[1] })
                                })
                            })
                        }} disabled={isAdmin} />

                        <label htmlFor="chat-video-input" className="fileLabel">
                            <img src={video} alt="send video" className="videoFile" />
                        </label>
                        <input type="file" id="chat-video-input" accept="video/mp4,video/x-m4v, video/mov, video/*" onChange={(e) => {
                            getBase64({ file: e.target.files[0] }).then((data) => {
                                setBase64Video({ base64: data, name: e.target.files[0].name, type: e.target.files[0].type.split('/')[1] });
                            });
                        }} disabled={isAdmin} />


                        <label htmlFor="chat-file-input" className="fileLabel">
                            <img src={file} alt="send file" className="file" />
                        </label>
                        <input type="file" id="chat-file-input" accept=".pdf, .txt, application/pdf, .doc, .docx, application/msword, .xls,.xlsx, application/vnd.ms-excel, .ppt, .pptx, application/vnd.ms-powerpoint, text/plain" onChange={(e) => {
                            getBase64({ file: e.target.files[0] }).then((data) => {
                                setBase64File({ base64: data, name: e.target.files[0].name, type: e.target.files[0].type.split('/')[1] });
                            }
                            );
                        }} disabled={isAdmin} />


                        <div className="inputContainer">
                            <form action="submit">
                                <input type="text" value={inputChat} onChange={(event) => {
                                    setInputChat(event.target.value)
                                }} disabled={isAdmin} />
                                <button type='submit' onClick={sendMessage} className='sendButton' disabled={isAdmin}><img src={send} alt="send" /></button>
                            </form>

                            <img src={smile} alt="" onClick={() => {
                                if (!isAdmin) {
                                    setShowEmojiOptions(!showEmojiOptions)
                                }
                            }} className='emoji' />
                        </div>
                    </div>
                    {(showEmojiOptions) && (<EmojiPicker
                        onEmojiClick={onClick}
                        searchDisabled
                        height={'35vh'}
                        skinTonesDisabled
                    />)}
                </div></>)}
        </ChatContainer>
    )
}