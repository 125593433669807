import styled from 'styled-components';
import { colors } from '../../../utils/styles/colors';


export const MobileMenuContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    z-index: 200;
    display: flex;
    .outsideMenu{
        height: 100%;
        width: 40%;
        background-color: #000000;
        opacity: 0.3;
    }
    .lateralMenu{
        height: 100%;
        width: 60%;  
        background-color: #ffffff;
        padding-top: 70px;
        color: ${colors.darkGrey};
        overflow-y: auto;
        .options{
            padding: 30px;
            a{
                color: ${colors.darkGrey};  
            }
            li{
                list-style: none;
                text-align: left;
                padding-bottom: 20px;
                font-size: 0.9rem; 
                cursor: pointer;
            }
            hr{
                margin-bottom: 20px;
                border-top: 1px solid ${colors.hightlight};
            }
            .settingsMenu{
                padding-left: 20px;
                .settingsOption{
                    padding-bottom: 10px ;
                    color: ${colors.darkGrey};
                    cursor: pointer;
                }
            }
        }  
    }
`