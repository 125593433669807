import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import Lottie from 'react-lottie';
import * as animationData from './twitter-logo-effect.json';

export default function TwitterRedirect() {

    const navigate = useNavigate();

    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    let [searchParams] = useSearchParams();

    const [showAlertError, setShowAlertError] = useState(false);

    /* Descomentar cuando se arregle  useEffect(() => {
 
         if (searchParams.get('succes') === 'S') {
             getMyFollowersNetworks({ userId: id, typeUser: userType }).then((response) => {
                 setTiktokFollowers(response[0].followers.tiktok)
                 setIsStopped(true);
                 navigate('/myprofile');
             })
         }
         if (searchParams.get('error') === 'S') {
             setShowAlertError(true);
         }
     }, [searchParams, id, userType]) */

    useEffect(() => {
        setTimeout(() => {
            navigate('/myprofile');
        }, 5000)
    }, [])

    //const navigate = useNavigate();
    //let { code, scopes, state } = useParams();

    /* const { userType, userToken, id, setTiktokFollowers } = useContext(UserContext);

    //const [isLoading, setIsLoading] = useState(true);
    

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let code = urlParams.get('code');
    let scopes = urlParams.get('scopes');
    let state = urlParams.get('state');

    const [showAlertError, setShowAlertError] = useState(false);*/

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    /*useEffect(() => {

        if (userType !== '' && id !== null) {

            validateStateTiktok({ typeUser: userType, token: userToken, state }).then((response) => {

                if (response.message === true) {

                    getAccessTokenTiktok({ typeUser: userType, token: userToken, code }).then((response) => {

                        if (response.message === 'success') {
                            getMyFollowersNetworks({ userId: id, typeUser: userType }).then((response) => {

                                setTiktokFollowers(response[0].followers.tiktok)
                                //setIsLoading(false);
                                setIsStopped(true);
                                navigate('/myprofile');
                            })
                        } else {
                            //setIsLoading(false);
                            setIsStopped(true);
                            setShowAlertError(true);
                        }
                    })
                } else {
                    //setIsLoading(false);
                    setIsStopped(true);
                    setShowAlertError(true);
                }
            })
        }
    }, [state, userType, id]) */

    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

            <p className="messageTwitter"><FormattedMessage id="error_twitter" defaultMessage="El servicio de Twitter se encuentra deshabilitado por el momento. Le informaremos cuando se restablezca." /></p>
            {/* <p>Redirect tiktok page</p>
            {(isLoading) && (<Spinner />)} */}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="alert_error_twitter_text" defaultMessage="No pudimos conectar su cuenta de Twitter, por favor intente mas tarde." />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="alert_error_network_button" defaultMessage="Ir a mi perfil" />}
                onConfirm={() => { navigate('/myprofile') }}
            />)}

        </NetworkRedirectContainer>

    )
}