import React, { useContext } from 'react'
import YouTubeLogo from "../../../../utils/images/networks/youtube.png";
import youtubeGrey from '../../../../utils/images/youtube_grey.svg';
import edit from '../../../../utils/images/edit_white.svg';

import '../../../../Bootstrap.css'
import './YouTubeProfileLoginStyled.css'
import { YouTubeAPI } from '../../../../api/social-network/youtube';
import { UserContext2 } from '../../../../context/user/userContext';

const YouTubeProfileLogin = () => {

    const { userToken, userType, userData } = useContext(UserContext2);

    const handlerConnectYouTube = () => {
        YouTubeAPI.getAuthUrl(userData?.id).then((authUrl) => {
            window.location.href = authUrl;
        });
    }

  return (
    <>
        <div className="youTubeButtonDesktop d-flex align-items-center gap-1">
            <span>+</span>
            <img
                className="mb-1"
                src={YouTubeLogo}
                alt="Conecta YouTube"
                onClick={handlerConnectYouTube}
                width={110}
                height={22}
            />
        </div>

        <div className="youTubeButtonMobile d-none rounded-circle cursor-pointer" onClick={handlerConnectYouTube}>
            <img src={youtubeGrey} width={22} height={22} />
            <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
        </div>
    </>
  )
}

export default YouTubeProfileLogin