export const replaceQuestionParams = (question, params) => {
    // Crea una expresión regular que coincida con los marcadores de posición en la forma {KEY}
    const regex = /\{\w+\}/g;

    // Itera sobre cada coincidencia encontrada por la expresión regular
    return question.replace(regex, match => {
        // Busca la clave dentro de los corchetes y obtiene su valor correspondiente del objeto params
        const key = match.slice(1, -1); // Elimina las llaves para obtener solo el nombre de la clave
        return params[key];
    });
};
