export const UserTypesId = {
    INFLUENCER: 1,
    COMPANY: 2,
    ADMIN: 3,
}

export const UserTypes = {
    INFLUENCER: 'INFLUENCER',
    COMPANY: 'COMPANY',
    ADMIN: 'ADMIN',
}