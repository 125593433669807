import React, { useEffect, useState, useContext } from "react";
import { StaticPageContainer } from "./StaticPageStyled";

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import back from './../../utils/images/arrow_back.svg';
import { LangContext } from "../../context/langContext";
import { StaticDataContext } from "../../context/staticDataContext";

export default function StaticPage() {

    const navigate = useNavigate();

    const { staticPageList } = useContext(StaticDataContext);

    const [englishText, setEnglishText] = useState('');
    const [spanishText, setSpanishText] = useState('');

    const { locale } = useContext(LangContext);

    var URLactual = window.location.pathname.split('/');

    useEffect(() => {
        const staticPage = staticPageList.filter((sp) => sp.path === URLactual.slice(-1)[0]);

        staticPage.map((sp) => {
            if (sp.language === "SPANISH") {
                setSpanishText(sp.body);
            } else {
                setEnglishText(sp.body);
            }
        })
    }, [URLactual]);

    return (
        <StaticPageContainer>

            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={back} alt="go back" className="backIcon" />
                <p>
                    <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                </p>
            </div>

            <div className="termsContainer">
                <div className="privacyBody" dangerouslySetInnerHTML={{ __html: (locale === 'es-CO') ? spanishText : englishText }} />

            </div>
        </StaticPageContainer>
    )
}