import { API_GATEWAY_URL } from '../../../utils/constants/constants';
import axiosInstance from '../../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user/like';

export const LikeAPI = {

    addlikeProduct: async (productId, companyId) => {
        const response = await axiosInstance.put(`${BASE_URL}/add-product-like`, { product_id: productId, company_id: companyId });
        return response.data;
    },

    removeLikeProduct: async (productId) => {
        const response = await axiosInstance.put(`${BASE_URL}/remove-product-like/${productId}`);
        return response.data;
    }
}