import styled from 'styled-components';
import { colors } from './../../utils/styles/colors';
import { media } from '../../utils/styles/media';

export const FilterBarContainer = styled.div`

    box-shadow: 0px 20px 34px 10px rgba(112,112,112,0.65);
    width: 80vw;
    height: 100px;
    border-radius:15px;
    position: relative;
    bottom: 60px;
    left: 10vw; 
    
    .filters{
        background-color:#ffffff;
        width: 100%;
        height: 100%;
        border-radius:15px;
        display: flex;
        padding: 0 40px;
        justify-content: space-between;
        
        .filterContainer{
            margin: 2px;
            justify-self: center;
            align-self: center;
            padding: 5px 10px;
           
            .filter{
                position: relative;
                .filterBy{
                    display: flex;
                    color: ${colors.hightlight};
                    //font-size: 1.2rem;
                    font-weight: 700;
                    line-height:1;
                    p{
                        font-size: 1.8rem;
                    }
                    
                    .down{
                        height: 10px;
                        margin-left: 10px;
                        align-self: center;
                    }
                }
                .subFilter{
                    
                    position: absolute;
                    margin-top: 10px;
                    padding: 10px;
                    border-radius: 5px;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 23px 3px rgba(0,0,0,0.13);
                    //width: 220px;
                    min-width: 200px;
                    max-height: 400px;
                    overflow-y: auto;
                    .subfilterBy{
                        display: flex;
                        margin-bottom: 10px;
                        align-items: center;
                        .down{
                            height: 8px;
                            margin-left: 10px;
                            align-self: center;
                        }                      
                    }
                    .options{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 10px;
                        label{
                            margin: 3px;
                            input[type='checkbox'] {
                                accent-color: ${colors.hightlight};
                                margin-right: 10px;                               
                            }
                        }  
                    }
                    .categoryOptions, .countryOptions{
                        width: 210px;
                    }
                }
                .textFilter{
                    display: flex;
                    .nameText{
                        outline: 0;
                        border-width: 0 0 2px;
                        border-color: ${colors.hightlight};
                        width: 85%;
                        margin-right: 20px;
                        font-size: 1.2rem;
                        color: ${colors.darkGrey};
                        
                    }
                    img.search{
                        width:20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
                
                .otherFilter{
                    color: ${colors.darkGrey};
                }
            }
        }
        .filterContainer:last-child{
            width: 50%;
        }  
    } 
    

    /**------------Responsive----------- */
    ${media.largeDesktop}{
        .filters{
            .filterContainer{
                .filter{
                    .filterBy{
                        p{
                            font-size: 1.6rem;
                        }
                    }
                    .textFilter{
                        .nameText{
                            width: 90%;
                        }
                    }
                }
                
            }
            .filterContainer:last-child{
                width: 50%;
            } 
        }
    }

    @media(max-width: 1555px){
        width: 84vw;
        left: 8vw; 
        .filters{
            padding: 0px 30px;
            .filterContainer{
                .filter{
                    .filterBy{
                        p{
                            font-size: 1.5rem;
                        }
                    }
                    .textFilter{
                        .nameText{
                            width: 90%;
                        }
                    }
                }
                
            }
            .filterContainer:last-child{
                width: 50%;
            } 
        }
    }


    ${media.desktop}{
        height: 90px; 
        .filters{
            padding: 0 30px;
            .filterContainer{
                .filter{
                    .filterBy{
                        p{
                            font-size: 1.2rem;
                        }
                    }
                    .textFilter{
                        .nameText{
                            width: 80%;
                        }
                    }
                }
            }
            .filterContainer:last-child{
                width: 44%;
            }
        }
    }

     @media(max-width: 1187px){
        .filters{
            padding: 0 10px;
            .filterContainer{
                padding: 0 5px;
            }
            .filterContainer:last-child{
                width: 38%;
            }
        }
    } 

    ${media.smallDesktop}{
        width: 86vw;
        height: 90px;
        left: 7vw;
        .filters{
            padding: 0 20px;
            .filterContainer{
                .filter{
                    .textFilter{
                        .nameText{
                            width: 70%;
                            margin-right: 15px;
                        }
                    }
                }
            }
            .filterContainer:last-child{
                width: 38%;
            }
        }

    }

    ${media.laptop}{
        width: 86vw;
        left: 7vw;
        height: 80px;
        .filters{
            padding: 0 20px;
            
            .filterContainer{
                .filter{
                    .filterBy{
                        font-size: 0.9rem;
                        .down{
                            height: 6px;
                            margin-left: 6px;
                            align-self: center;
                        }
                    }
                    .textFilter{
                        .nameText{
                            outline: 0;
                            border-width: 0 0 2px;
                            border-color: ${colors.hightlight};
                            //width: 180px;
                            width: 80%;
                            //margin-right: 20px;
                        }
                        img.search{
                            width:20px;
                            height: 20px;
                        }
                        .otherFilter{
                            color: ${colors.darkGrey};
                        }
                    }

                }
            }
        } 
    }

    ${media.tablet}{
        .filters{
            padding: 0 20px;
            .filterContainer{
                padding: 5px;
                margin: 0px;
                .filter{
                    .textFilter{
                        .nameText{
                            width: 85%;
                            margin-right: 0px;
                            
                        }
                    }
                }
            }
            .filterContainer:last-child{
                width: 36%;
            }
        }
    }
    ${media.smallTablet}{
        .filters{
            .filterContainer{
                .filter{
                    .nameText{
                        width: 70%;
                    }
                }
            }
            .filterContainer:last-child{   
                width: 35%;
            }
        }
    }

    ${media.largeMobile}{
        margin: 0;
        height: 60px;
        .filters{
            display: flex;
            justify-content: space-around;
            padding: 0px 10px;
            .filterContainer{
                .filter{
                        .textFilter{
                            .nameText{
                            width: 75%;
                            margin-right: 12px;
                        }
                    }
                    .filterBy{
                        p{
                            font-size: 1rem;
                        }
                    }
                    .textFilter{
                        align-items: center;
                        .nameText{                          
                            width: 90%;
                        }
                        img.search{
                            width:15px;
                            height: 15px;
                        }
                    }  
                }    
            }
            .filterContainer:last-child{
            width: 65%;
        }
            .otherFilterContainer{
                display: none;
            }
            /* .p-treeselect{
                font-size: 1.1rem;
                .p-placeholder{
                    font-size: 1.2rem;
                }   
            }
            .p-dropdown{
                display: none;
            }
            input{
                display: none;
            } 
            img.search{
                width:20px;
            } */ 
        }
    }

`