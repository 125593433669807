
import { postRestURL } from "../utils/constants/constants";


export async function postEmailToNewsletter({ body }) {

    try {
        const response = await fetch(`${postRestURL}/newsletter`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               
            },
            body: JSON.stringify(body)
        });

        return response;
    } catch (e) {
        window.alert('We could not complete your request at the moment, please try again later')
        window.location.reload();
        throw (e);
    }
}


export async function getNewsletterEmailToVerified({ email }) {
    try {
        const response = await fetch(`${postRestURL}/newsletter?deleted=eq.0&email=eq.${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               
            }
        });
        const data = await response.json();
        return data;
    } catch (e) {
        throw (e);
    }
}