import React, { useState, useContext } from "react";
import { ModalReceivedDisputeContainer } from "./ModalReceivedDisputeStyled";


import { FormattedMessage, useIntl } from 'react-intl';

import close from './../../utils/images/close_circle_outline.svg';
import hourglass from './../../utils/images/hourglass.svg';
import { LangContext } from "../../context/langContext";
import { fluincrCaresEN, fluincrCaresES } from "../../utils/constants/constants";

export default function ModalReceivedDispute({ setShowReceivedDispute }) {

    const { locale } = useContext(LangContext);

    return (
        <ModalReceivedDisputeContainer>

            {/* <img src={close} alt="close" className="close" onClick={() => { setShowReceivedDispute(false) }} /> */}

            <div className="disputeBody">

                <img src={hourglass} alt="time" className="timeIcon" />

                <h2 className="title"><FormattedMessage id="received_dispute_title" defaultMessage="Hemos recibido tu disputa" /></h2>

                <p className="subtitle"><FormattedMessage id="received_dispute_subtitle" defaultMessage="¿Y ahora, qué puedes hacer?" /></p>

                <p className="text"><FormattedMessage id="received_dispute_text" defaultMessage="Ahora solo resta esperar que Fluincr revise la disputa. Por el momento tu colaboración quedará en espera. Si necesitas más información comunícate en " />
                    <span><a href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN}><FormattedMessage id="alert_deleted_account_text_b" defaultMessage="Fluincr Cares" /></a></span>.
                </p>

                <button className='perfectButton' onClick={() => { setShowReceivedDispute(false) }}>
                    <FormattedMessage id="received_dispute_button" defaultMessage="¡Perfecto!" />
                </button>

            </div>


        </ModalReceivedDisputeContainer>
    )
}