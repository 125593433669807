import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl';

import TikTokLogo2 from "../../../../utils/images/networks/tiktok2.png";
import tikTokGrey from '../../../../utils/images/tiktok_grey.svg';
import edit from '../../../../utils/images/edit_white.svg';

import '../../../../Bootstrap.css'
import './TikTokProfileLoginStyled.css'
import { TikTokAPI } from '../../../../api/social-network/tiktok';
import { UserContext2 } from '../../../../context/user/userContext';

const TikTokProfileLogin = () => {

    const { userToken, userType, userData } = useContext(UserContext2);

    const handlerConnectTiktok = () => {
        TikTokAPI.getAuthUrl(userData?.id).then((authUrl) => {
            window.location.href = authUrl;
        });

        /*tiktokConect({ typeUser: userType, token: userToken }).then((response) => {

            if (response.status === 200) {
                window.location.href = response.message
            }
        })*/
    }

    return (
        <>
            <div className="tikTokButtonDesktop d-flex align-items-center gap-1 cursor-pointer" onClick={handlerConnectTiktok}>
                <span>+</span>
                <img src={TikTokLogo2} className="mb-1" width={21} height={23} />
                <FormattedMessage id="my_profile_text_3" defaultMessage="Conecta tu TikTok" />
            </div>

            <div className="tikTokButtonMobile d-none rounded-circle cursor-pointer" onClick={handlerConnectTiktok}>
                <img src={tikTokGrey} width={20} height={20} />
                <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
            </div>
        </>
    )
}

export default TikTokProfileLogin