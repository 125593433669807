export const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      boxShadow: "none",
      height: "30px",
      minHeight: "34px",
      flexWrap: "no-wrap"
    }),
    menu: (styles) => ({
      ...styles,
      display: "flex"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#E8339E"
    })
  };
  
export const selectPhoneStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      boxShadow: "none",
      height: "30px",
      minHeight: "34px",
      flexWrap: "no-wrap"
    }),
    menu: (styles) => ({
      ...styles,
      display: "flex",
      width: "100px"
    }),
    option: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#E8339E"
    })
  };

export const selectCountryStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      boxShadow: "none",
      height: "30px",
      minHeight: "34px",
      flexWrap: "no-wrap",
      minWidth: "180px"
    }),
    menu: (styles) => ({
      ...styles,
      display: "flex",
      width: "180px"
    }),
    option: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#E8339E"
    })
  };

export const selectIndentificationStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      boxShadow: "none",
      height: "30px",
      minHeight: "34px",
      flexWrap: "no-wrap" /* width: '100%', */ /* backgroundColor: 'red' */
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    placeholder: (styles) => ({
      ...styles,
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1.3rem",
      "@media(max-width: 2400px)": {
        fontSize: "1.2rem"
      },
      "@media(max-width: 1440px) and (max-height: 1920px)": {
        fontSize: "1.1rem"
      },
      "@media(max-width: 1366px)": {
        fontSize: "1rem"
      }
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#E8339E"
    })
  };