import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import FacebookLogin from 'react-facebook-login';
import Lottie from 'react-lottie';
import * as animationData from './facebook-logo-effect.json';
import facebook from "../../utils/images/facebook.svg";
import { URLplatform } from "../../utils/constants/constants";

export default function InstagramFacebookRedirect() {

    const [isStopped, setIsStopped] = useState(true);
    const [isPaused, setIsPaused] = useState(true);

    const [showAlertError, setShowAlertError] = useState(false);

    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const responseFacebookInstagram = (response) => {

        setIsStopped(false);
        setIsPaused(false);
        if (response?.error) {
            setShowAlertError(true);
        } else {
            /*getAccessFacebookInstagram({ token: userToken, userType: userType, facebookToken: response.accessToken, facebookId: response.userID }).then((responseAccess) => {
                if (responseAccess.status === 200) {
                    getMyFollowersNetworks({ userId: id, typeUser: userType }).then((responseFollowers) => {
                        setInstagramFollowers(responseFollowers[0].followers.instagram)
                        setIsStopped(true);
                        //navigate('/myprofile');
                        window.location.replace(`${URLplatform}/myprofile`);
                    })
                } else {
                    setShowAlertError(true)
                }
            })*/

        }
    }



    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />


            <FacebookLogin
                reAuthenticate={true}
                appId="570796258294358"
                fields="name,email,picture"
                cssClass="facebookButton"
                icon={<img src={facebook} alt="facebook" className="loginLogo" />}
                textButton='Sign in with Facebook'
                //redirectUri="https://pruebas.tmsolutions.com.co/auth/instagramfacebook"
                redirectUri={`${URLplatform}/auth/instagramfacebook`}
                scope="public_profile,pages_show_list,pages_read_engagement,instagram_basic"
                callback={(response) => { responseFacebookInstagram(response) }}
                cookiePolicy={'none'}
                cookie={false}
            //returnScopes={true}
            />


            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="alert_error_facebook_text" defaultMessage="No pudimos conectar su cuenta de facebook, por favor intente mas tarde." />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="alert_error_network_button" defaultMessage="Ir a mi perfil" />}
                onConfirm={() => { navigate('/myprofile') }}
            />)}

        </NetworkRedirectContainer>

    )
}