import React, { useState, useContext } from "react";
import { ModalAddProductContainer } from "./ModalAddProductStyled";

import { FormattedMessage, useIntl } from 'react-intl';

import close from './../../utils/images/close_circle_outline.svg';
import back from './../../utils/images/arrow_back.svg';
import logo from './../../utils/images/name_logo_grey.svg';
import camera from './../../utils/images/camera.svg';
import editIcon from './../../utils/images/edit_pink.svg';
import deleteIcon from './../../utils/images/delete_pink.svg';

import ImageUploading from 'react-images-uploading';
import { useEffect } from "react";
import Spinner from "../Spinner/Spinner";
import ImageCrop from "../ImageCrop/ImageCrop";
import { uploadImageS3 } from "../../utils/common/s3";
import { ProductAPI } from "../../api/product-and-service/product";
import { UserContext2 } from "../../context/user/userContext";

export default function ModalAddProduct({ setShowAddProduct }) {

    const intl = useIntl();

    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productLink, setProductLink] = useState('');
    const [showInProfile, setShowInProfile] = useState(true);

    const [image, setImage] = useState('');
    const [imagesToSend, setImagesToSend] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [showImageCrop, setShowImageCrop] = useState(false);

    const { userData, setUserData } = useContext(UserContext2);

    useEffect(() => {
        if (productName && productPrice && imagesToSend.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [productPrice, imagesToSend])

    const onChange = (imageList, addUpdateIndex) => {
        setImagesToSend(imageList);
        setImage(imageList[0].data_url);
    };


    useEffect(() => {
        if (image.length > 0) {
            setShowImageCrop(true)
        } else {
            setShowImageCrop(false)
        }
    }, [image])

    const handleInputPrice = (value) => {
        if (/^\d*$/.test(value)) {
            setProductPrice(value);
        }
    };

    const saveImage = (croppedImgSrc) => {
        setIsLoading(true)
        const auxImage = [...imagesToSend];
        auxImage[0].data_url = croppedImgSrc;
        setImagesToSend(auxImage);
        setImage('');
        setIsLoading(false);
        setShowImageCrop(false);
    }

    const saveProduct = () => {

        setIsLoading(true);

        const imageBody = {
            base64File: imagesToSend[0].data_url.split(',')[1],
            formatFile: imagesToSend[0].file.type.split('/')[1],
            //address: `company/${uniqueName}/product`
            address: `company/${userData?.account}/product`
        }

        uploadImageS3(imageBody?.address, 'image/' + imageBody?.formatFile, imagesToSend[0]?.file).then((fileLink) => {

            const productBody = {
                name: productName,
                description: productDescription,
                image: fileLink,
                price: productPrice,
                show_in_profile: showInProfile,
                link: productLink,
                company_id: userData?.id
            }

            ProductAPI.createProduct(productBody).then((productSaved) => {
                const companyProducts = [...userData?.profile_data?.products];
                companyProducts.push(productSaved);

                setUserData({
                    ...userData,
                    profile_data: {
                        ...userData?.profile_data,
                        products: companyProducts
                    }
                });

                setIsLoading(false)
                setShowAddProduct(false);
            }).catch((error) => {
                console.log('Error creating product: ' + error);
                setIsLoading(false)
            });
        }).catch((error) => {
            console.log('Error uploading image: ' + error);
            setIsLoading(false)
        });
    }

    return (
        <ModalAddProductContainer>

            <img src={close} alt="close" className="close" onClick={() => { setShowAddProduct(false) }} />

            <div className="productBody">

                <div className="productHeader">
                    <div className="goBack" /* onClick={() => navigate(-1)} */ onClick={() => { setShowAddProduct(false) }}>
                        <img src={back} alt="go back" className="backIcon" />
                        <p>
                            <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                        </p>
                    </div>
                    <h3 className="addHeaderTitle"><FormattedMessage id="add_product_title" defaultMessage="Añadir producto para promocionar en" /> <img src={logo} alt="logo" className="logo" /></h3>
                </div>

                <div className="information">

                    <div className="productData">
                        <input type="text" maxLength="50"
                            placeholder={intl.formatMessage({ id: "add_product_text_1" })} className="productName" value={productName} onChange={(event) => { setProductName(event.target.value) }} />

                        <div className="productPrice">
                            <label htmlFor="price">
                                <FormattedMessage id="add_product_text_2" defaultMessage="US $ " />
                                {/* <input type="number" id="price" value={productPrice} onChange={(event) => { setProductPrice(event.target.value) }} /> */}
                                <input type="text" id="price" value={productPrice} onChange={(event) => { handleInputPrice(event.target.value) }} />
                            </label>
                            <p className="value"><FormattedMessage id="add_product_text_3" defaultMessage="Valor del producto/ servicio" /></p>
                        </div>

                        <textarea name="" maxLength="250" id="" cols="30" rows="10" className="productDescription" value={productDescription} onChange={(event) => { if (event.target.value.length < 251) { setProductDescription(event.target.value) } }}></textarea>
                        <p className="maxDescription"><FormattedMessage id="add_product_text_8" defaultMessage="Max 250 caracteres" /></p>

                        <input type="text" placeholder={intl.formatMessage({ id: "add_product_text_4" })} value={productLink} onChange={(event) => { setProductLink(event.target.value) }} />

                        <div className="extraDetails">
                            <p className="deleteProduct" onClick={() => { setShowAddProduct(false) }}><FormattedMessage id="add_product_text_5" defaultMessage="Eliminar producto" /></p>

                            <div className="appear">
                                <label className="switch">
                                    <input type="checkbox" checked={showInProfile} onChange={(event) => { setShowInProfile(event.target.checked) }} />
                                    <div className="slider"></div>
                                </label>
                                <p className="appearText"><FormattedMessage id="add_product_text_6" defaultMessage="Mostrar en el perfil" /></p>

                            </div>

                        </div>
                    </div>

                    <div className="productImage">

                        <ImageUploading
                            value={imagesToSend}
                            onChange={onChange}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (

                                <div className="upload__image-wrapper">

                                    {(imagesToSend.length === 0) && (<button type='button'
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        className="addImageButton"
                                    >
                                        <FormattedMessage id="add_product_text_7" defaultMessage="Cargar producto/ Servicio" />
                                        <img src={camera} alt="camera" />
                                    </button>)}

                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item">
                                            <div className="image-item__btn-wrapper">
                                                <button onClick={() => {
                                                    onImageUpdate(index)
                                                    setImagesToSend([])
                                                }} className="modify">
                                                    <img src={editIcon} alt="remove" /></button>
                                                <button onClick={() => {
                                                    onImageRemove(index)
                                                    setImagesToSend([])
                                                }} className="delete">
                                                    <img src={deleteIcon} alt="remove" />
                                                </button>
                                            </div>
                                            {/*  <div className="productImage" >
                                                <img src={image['data_url']} alt="product" />
                                            </div> */}

                                        </div>
                                    ))}
                                </div>
                            )
                            }
                        </ImageUploading>

                        {(imagesToSend.length > 0) && (<div className="imageContainer" >
                            <img src={imagesToSend[0].data_url} alt="product" />
                        </div>)}

                        <button className={`saveButton ${disabled && 'buttonDisabled'}`} onClick={saveProduct} disabled={disabled}>
                            <FormattedMessage id="add_product_button" defaultMessage="Guardar producto" />
                        </button>
                    </div>

                </div>
                <button className={`saveButtonMobile ${disabled && 'buttonDisabled'}`} onClick={saveProduct} disabled={disabled}>
                    <FormattedMessage id="add_product_button" defaultMessage="Guardar producto" />
                </button>
            </div>


            {(showImageCrop) && (<ImageCrop imgSrc={imagesToSend[0]} setShowImageCrop={setShowImageCrop} setImagesToSend={setImagesToSend} setImage={setImage} xRadio={9} yRadio={10} save={saveImage} isLoading={isLoading} />)}

            {(isLoading) && (<Spinner />)}
        </ModalAddProductContainer>
    )
}