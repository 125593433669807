import React, { useState, useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useContext } from "react";
import { CartContext } from "../../context/cartContext";
import Spinner from "../../components/Spinner/Spinner";
import { CartAPI } from "../../api/collaboration/cart";

export default function Checkout({ amount, setShowCollaborationSent }) {

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const { cart, setCart } = useContext(CartContext);

    const [isLoading, setIsLoading] = useState(false);

    const onCreateOrder = (data, actions) => {

        return CartAPI.createOrder(cart.id).then((paymentOrderExternalId) => {
            return paymentOrderExternalId;
        });
    };

    const onApproveOrder = (data, actions) => {
        setIsLoading(true);
        
        actions.order.authorize().then(function (authorization) {
            var authorizationID = authorization.purchase_units[0]
                    .payments.authorizations[0].id;

            return CartAPI.saveAuthorization(cart.id, authorizationID).then((response) => {
                setCart({ collaborations: [] });
                setIsLoading(false);
                setShowCollaborationSent(true);
            });
        });
    }

    const buttonStyles = {
        layout: "vertical",
        //layout: "horizontal",
        //color: "gold",
        //color: "silver",
        //color: "white",
        //color: "black",
        color: "blue",
        shape: "rect",
        //label: "paypal",
        //label: "installment",
        tagline: false,
    };
    const label = `Pay ${amount}`;
    const button = {
        label: `Pay ${amount}`,
        style: {
            layout: 'vertical',
            color: 'gold',
            shape: 'rect',
            label: 'pay',
            height: 40,
        },
    };
    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <div style={{ paddingTop: '10px' }}>
                    {/* <select value={currency} onChange={onCurrencyChange}>
                        <option value="USD">💵 USD</option>
                        <option value="EUR">💶 Euro</option>
                    </select> */}
                    <PayPalButtons
                        style={buttonStyles}
                        createOrder={onCreateOrder}
                        onApprove={(data, actions) => {
                            onApproveOrder(data, actions)
                        }}
                        forceReRender={[amount]}
                        onCancel={(data, actions) => {
                            //console.log("CANCEL", data)
                            //Esto me devuleve el orderID
                        }}
                        onError={(data, actions) => {
                            //console.log("ERROR", data)
                            window.location.href = "/error";
                        }}
                    //label={`Pay ${amount}`}
                    //label={label} 
                    //amount={amount}
                    //currency="USD"
                    //button={button}
                    />
                </div>
            )}
            {(isLoading) && <Spinner />}
        </div>
    );
}