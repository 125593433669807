import styled from "styled-components";
import { colors } from './../../utils/styles/colors';
import { media } from './../../utils/styles/media';
import { networkRanges } from "../../utils/styles/networks";

export const MyProfileContainer = styled.div`

    font-family: 'Open Sans',  sans-serif;
    .banner{
        background-color: #000000;
        display: flex;
        flex-direction: column;
        padding: 30px 50px ;
        min-height: 680px;
        .goBack{
            display: flex;
            margin-bottom: 20px;
            margin-left: 50px;
            cursor: pointer;
            width: 230px;
            .backIcon{
                width: 40px;
                margin-right: 15px;
            }
            .backText{
                color: #ffffff;
                font-size: 2.3rem;
                font-weight: 500;
            }
        }
        .bannerInformation{
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 50px;
            .networkContainer{
                width: 50%;
                color: #ffffff;
                display: flex;

                a{
                    text-decoration:none;
                    color:#ffffff;
                }

                .socialNetworksProfileContainer{
                    display: flex;
                    flex-direction: row-reverse;
                    width: 100%;
                    min-height: 220px;
                    padding-left: 23%;
                    padding-right: 2em;
                }
            }

            .socialNetworksProfileContainerMobile{
                display: none;
            }

            .data{
                width: 45%;
                font-family: 'Open Sans',  sans-serif;
                .fileName{
                    display: flex;
                    //align-items: center;
                    align-items: baseline;
                    margin-bottom: 2px;
                    .name{
                            color: #ffffff;
                            font-size: 4rem;
                            font-weight: bold;
                    }
                    .checkContainer{
                            background-color: #000000;
                            width: 35px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 15px; 
                            .check{
                                width: 100%;
                                height: 100%;
                            }
                        }                 

                }
                .firstFile, .lastFile{
                    display: flex;
                    position: relative; 
                    .languageContainer{
                        display: flex;
                    }
                    .item{
                        display: flex;
                        .city{
                            color: ${colors.hightlight};
                            font-size: 2.3rem;
                            font-weight: 600;
                        }
                        .language{
                            color: #ffffff;
                            font-size: 2.3rem;
                            font-weight: 600;
                            span{
                                white-space: nowrap;
                            }
                        }
                        .categories{
                            color: ${colors.hightlight};
                            font-size: 2.2rem;
                            font-weight: 600;
                            text-transform: lowercase;
                        }
                        .edit{
                            margin-top: 5px;
                            margin-left: 10px;
                            margin-right: 25px;
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                            align-self: flex-start;
                        }
                        .languagesOptions{
                            max-height: 20em;
                            overflow-y: scroll;
                            padding 1em .5em !important;

                            .languagesOptionsHeader{
                                display: flex;
                                color: ${colors.hightlight};
                                justify-content: space-between;
                                margin-left: 1em;
                                font-size: .875em;
                            }

                            .optionsContainer{
                                display: flex;
                                flex-direction: column;
                                padding: .5em 1em;
                                heihgt: 100%;
                                overflow-y: scroll;
                            }
                        }
                        .options{
                            color: #000000;
                            position: absolute;
                            z-index: 150;
                            top: 60px;
                            background-color: #ffffff;
                            padding: 20px 30px;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            width: 400px;
                            .apply{                                        
                                font-size: 1.3rem;
                                color: ${colors.hightlight};
                                text-decoration: underline;
                                position: sticky;
                                top:0;
                                cursor: pointer;
                                align-self: flex-end;
                                margin-bottom: 5px;
                            }
                            .cityInput{
                                padding: 6px 8px;
                                border-radius: 10px;
                                border: 1px solid ${colors.grey};
                                outline: none;
                                margin: 10px 0;
                                font-size: 1.2rem;
                                font-family: 'Open Sans', sans-serif;
                                ::placeholder{
                                    font-size: 1.2rem; 
                                }
                            }

                            .option{
                                margin-bottom: 5px;
                                font-size: 1.2rem;
                                input[type="checkbox"]{
                                    margin-right: 15px;
                                    -ms-transform: scale(2); /* IE */
                                    -moz-transform: scale(2); /* FF */
                                    -webkit-transform: scale(2); /* Safari y Chrome */
                                    accent-color: ${colors.hightlight};
                                }
                            }
                        }
                    }
                    .share{
                        margin-right: 20px;
                        margin-top: 5px;
                        .shareIcon{
                            width: 35px;
                            height: 35px;
                            cursor: pointer;
                        }
                    }
                    .scoreContainer{
                        display: flex;
                        align-items: flex-start;
                        .score{
                            color: #ffffff;
                            font-size: 2.3rem;
                            font-weight: 600;
                        }
                        .star{
                            margin-top: 9px;
                            margin-left: 8px;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
                .descriptionContainer{
                    margin-top: 15px;
                    display: flex;
                    align-items: flex-end;
                    color: #ffffff;
                    .writeDescription{
                        background-color: #ffffff;
                        width: 100%;
                        padding: 20px 30px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 10px;
                        .apply{
                            font-size: 1.3rem;
                            color: ${colors.hightlight};
                            text-decoration: underline;
                            position: sticky;
                            top:0;
                            cursor: pointer;
                            align-self: flex-end; 
                            margin-bottom:5px;  
                        }
                        textarea{
                            width: 100%;
                            height: 120px;
                            padding: 20px;
                            outline: none;
                            border-radius: 10px;
                            border: 1px solid ${colors.grey};
                            font-size: 1.2rem;
                            font-family: 'Open Sans', sans-serif;
                        }
                        .maxDescription{
                            color: ${colors.darkGrey};
                            font-size: 1rem;
                            //text-align: end;
                        }
                    }
                    .descriptionEmpty{
                        width: 90%;
                        .description{
                            font-size: 2.2rem;
                        }
                        .item{
                            hr{
                                margin-top: 40px;  
                            }
                            hr:first-child{
                                margin-top: 10px;  
                            }
                        }

                    }
                    .descriptionContent{
                        max-height: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        inline-size: 100%;
                        overflow-wrap: break-word;
                        p{
                            font-weight: lighter;
                            font-size: 2.2rem;
                        }
                    } 
                    .edit{
                        margin: 0 10px;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                    }                   
                }
                .lastFile{
                    margin-top: 25px;
                }

            }
            .networkMobile{
                display: none;
            }
        }
        
    }
    .myProfileBody{
        min-height: 600px;
        padding:30px 95px 30px 95px ;
    }

    /**----------Responsive------------ */
    ${media.extralargeDesktop}{
        .banner{
            min-height: 580px;
            .bannerInformation{
                margin-top: 30px;

                .data{
                    .fileName{
                        .name{
                            font-size: 3.5rem;
                        }
                        .checkContainer{
                            width: 30px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.9rem;
                            }
                            .language{
                                font-size: 1.9rem;
                            }
                            .categories{
                                font-size: 1.8rem;
                            }
                            .edit{
                                width: 28px;
                                height: 28px;
                            }
                        }
                        .share{
                            .shareIcon{
                                width: 30px;
                                height: 30px;
                            }
                        }
                        .scoreContainer{
                            .score{
                                font-size: 1.9rem;
                            }
                            .star{
                                width: 28px;
                                height: 28px;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    ${media.largeDesktop}{
        .banner{
            padding: 30px 30px ;
            min-height: 480px;
            .goBack{
                width: 180px;
                margin-left: 50px;
                .backIcon{
                    width: 35px;
                }
                .backText{
                    font-size: 1.8rem;
                }
            }
            .bannerInformation{
                margin-top: 30px;

                .data{
                    .fileName{
                        .name{
                            font-size: 3.3rem;
                        }
                        .checkContainer{
                            width: 30px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.8rem;
                            }
                            .language{
                                font-size: 1.8rem;
                            }
                            .categories{
                                font-size: 1.7rem;
                            }
                            .edit{
                                margin-left: 8px;
                                margin-right: 15px;
                                width: 25px;
                                height: 25px;
                            }
                            .options{
                                top: 45px;
                                padding: 15px 25px;
                                width: 350px;
                                /* .apply{                                        
                                    font-size: 1.3rem;
                                } */
                                .cityInput{
                                    padding: 6px 8px;
                                    //font-size: 1.2rem;
                                }
                                .option{
                                    //font-size: 1.2rem;
                                    input[type="checkbox"]{
                                        -ms-transform: scale(1.5); /* IE */
                                        -moz-transform: scale(1.5); /* FF */
                                        -webkit-transform: scale(1.5); /* Safari y Chrome */
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }
                        .share{
                            margin-right: 15px;
                            margin-top: 5px;
                            .shareIcon{
                                width: 27px;
                                height: 27px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            .score{
                                color: #ffffff;
                                font-size: 1.8rem;
                                font-weight: 600;
                            }
                            .star{
                                margin-top: 5px;
                                margin-left: 8px;
                                width: 27px;
                                height: 27px;
                            }
                        }
                    }
                    .descriptionContainer{
                         .writeDescription{
                            padding: 10px 20px;
                            .apply{
                                font-size: 1.1rem;
                            }
                             textarea{
                                font-size: 1.2rem;
                                height: 90px;
                            }
                            .maxDescription{
                                font-size: 0.9rem;
                            }
                        } 
                        .descriptionEmpty{
                            width: 90%;
                            .description{
                                font-size: 1.8rem;
                            }
                            .item{
                                hr{
                                    margin-top: 35px;  
                                }
                            }

                        }
                        .descriptionContent{
                            max-height: 120px;
                            p{
                                font-size: 1.8rem;
                            }
                        } 
                        .edit{
                            margin: 0 10px;
                            width: 30px;
                            cursor: pointer;
                        }                   
                    }
                    .lastFile{
                        margin-top: 25px;
                    }
                }
            }
        }
        .myProfileBody{
            padding:30px 70px 30px 70px ;
        } 
    }

    ${media.specialScreen}{
        .banner{
            min-height: 400px;
            .goBack{
                width: 160px;
                .backIcon{
                    width: 28px;
                }
                .backText{
                    font-size: 1.4rem;
                }
            }
            .bannerInformation{
                margin-top: 20px;

                .data{
                    .fileName{
                        .name{
                            font-size: 2.5rem;
                        }
                        .checkContainer{
                            width: 25px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.4rem;
                            }
                            .language{
                                font-size: 1.4rem;
                            }
                            .categories{
                                font-size: 1.3rem;
                            }
                            .edit{
                                margin-left: 8px;
                                margin-right: 15px;
                                width: 20px;
                                height: 20px;
                            }
                            .options{
                                top: 35px;
                                padding: 10px 20px;
                                width: 300px;
                                /* .apply{                                        
                                    font-size: 1rem;
                                } */
                                .cityInput{
                                    padding: 6px 8px;
                                    //font-size: 1.1rem;
                                }
                                .option{
                                    //font-size: 1.1rem;
                                    input[type="checkbox"]{
                                        margin-right: 8px;
                                        -ms-transform: scale(1.2); /* IE */
                                        -moz-transform: scale(1.2); /* FF */
                                        -webkit-transform: scale(1.2); /* Safari y Chrome */
                                    }
                                }
                            }
                        }
                        .share{
                            margin-right: 15px;
                            margin-top: 5px;
                            .shareIcon{
                                width: 23px;
                                height: 23px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            .score{
                                color: #ffffff;
                                font-size: 1.4rem;
                                font-weight: 600;
                            }
                            .star{
                                margin-top: 4px;
                                margin-left: 6px;
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                    .descriptionContainer{
                         .writeDescription{
                            padding: 10px 15px;
                            .apply{
                                font-size: 1rem;
                            }
                            textarea{
                                font-size: 1.1rem;
                                height: 80px;
                            } 
                            .maxDescription{
                                font-size: 0.8rem;
                            }
                        } 
                        .descriptionEmpty{
                            width: 90%;
                            .description{
                                font-size: 1.3rem;
                            }
                            .item{
                                hr{
                                    margin-top: 30px;  
                                }
                            }

                        }
                        .descriptionContent{
                            p{
                                font-size: 1.3rem;
                            }
                        } 
                        .edit{
                            margin: 0 10px;
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }                   
                    }
                    .lastFile{
                        margin-top: 25px;
                    }
                }
            }
        }
        .myProfileBody{
            padding:20px 80px 30px 80px ;
        } 
    }

    ${media.bigmacdesktop}{
        .banner{
            min-height: 360px;
            .goBack{
                width: 140px;
                margin-left: 40px;
                .backIcon{
                    width: 26px;
                }
                .backText{
                    font-size: 1.2rem;
                }
            }
            .bannerInformation{
                .data{
                    //background-color: orange;
                    .fileName{
                        .name{
                            font-size: 2.4rem;
                        }
                        .checkContainer{
                            width: 25px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.3rem;
                            }
                            .language{
                                font-size: 1.3rem;
                            }
                            .categories{
                                font-size: 1.1rem;
                            }
                            .edit{
                                margin-left: 8px;
                                margin-right: 18px;
                                width: 18px;
                                height: 18px; 
                            }
                            .options{
                                top:30px;
                                padding: 8px 10px;
                                width: 250px;
                                .apply{                                        
                                    font-size: 0.9rem;
                                }
                                .cityInput{
                                    font-size: 1.1rem;
                                    ::placeholder{
                                        font-size: 1.1rem; 
                                    }
                                }
                                .option{
                                    font-size: 1.1rem;
                                    input[type="checkbox"]{
                                        margin-right: 8px;
                                        -ms-transform: scale(1); /* IE */
                                        -moz-transform: scale(1); /* FF */
                                        -webkit-transform: scale(1); /* Safari y Chrome */
                                    }
                                }
                            }
                        }
                        .share{
                            .shareIcon{
                                width: 21px;
                                height: 21px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            margin-top: 2px;
                            .score{
                                font-size: 1.2rem;
                            }
                            .star{
                                margin-top: 5px;
                                margin-left: 6px;
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                    .descriptionContainer{
                        .writeDescription{
                            padding:10px;
                            .apply{
                                font-size: 0.9rem;
                            }
                            textarea{
                                font-size: 1.1rem;
                                padding: 15px;
                                height: 70px;
                            }
                            .maxDescription{
                                font-size: 0.7rem;
                            }
                        }
                        .edit{
                            width: 18px;
                            height: 18px;
                        }
                    }
                    
                }
            }
        }
        .myProfileBody{
            padding:30px 80px 30px 80px ;
        }
    }

    ${media.macdesktop}{
        .banner{
            min-height: 340px;
            .goBack{
                width: 130px;
                margin-left: 40px;
                .backIcon{
                    width: 24px;
                }
                .backText{
                    font-size: 1.1rem;
                }
            }
            .bannerInformation{
                .data{
                    .fileName{
                        .name{
                            font-size: 2.4rem;
                        }
                        .checkContainer{
                            width: 25px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.3rem;
                            }
                            .language{
                                font-size: 1.3rem;
                            }
                            .categories{
                                font-size: 1.1rem;
                            }
                            .edit{
                                margin-left: 8px;
                                margin-right: 20px;
                                width: 18px;
                                height: 18px; 
                            }
                            .options{
                                top:30px;
                                padding: 8px 10px;
                                width: 250px;
                                /* .apply{                                        
                                    font-size: 0.8rem;
                                } */
                                /* .cityInput{
                                    font-size: 0.9rem;
                                } */
                                .option{
                                    //font-size: 0.9rem;
                                    input[type="checkbox"]{
                                        margin-right: 8px;
                                        -ms-transform: scale(1); /* IE */
                                        -moz-transform: scale(1); /* FF */
                                        -webkit-transform: scale(1); /* Safari y Chrome */
                                    }
                                }
                            }
                        }
                        .share{
                           // background-color: green;
                            .shareIcon{
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            margin-top: 1px;
                            .score{
                                font-size: 1.2rem;
                            }
                            .star{
                                margin-top: 5px;
                                margin-left: 4px;
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                    .descriptionContainer{   
                        .descriptionContent{
                            max-height: 110px;
                        } 
                    }
                }
            }
        }

        .myProfileBody{
            padding:30px 70px 30px 70px ;
        }
    }

    ${media.desktop}{
        .banner{
            padding: 20px 20px ;
            min-height: 300px;
            .goBack{
                width: 120px;
                margin-left: 40px;
                .backIcon{
                    width: 20px;
                }
                .backText{
                    font-size: 1rem;
                }
            }
            
            .bannerInformation{
                margin-top: 10px;

                .data{
                    .fileName{
                        .name{
                            font-size: 2.4rem;
                        }
                        .checkContainer{
                            width: 23px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.2rem;
                            }
                            .language{
                                font-size: 1.2rem;
                            }
                            .categories{
                                font-size: 1.1rem;
                            }
                            .edit{
                                margin-left: 6px;
                                margin-right: 16px;
                                width: 18px;
                                height: 18px; 
                            }
                            .options{
                                top:30px;
                                padding: 8px 10px;
                                width: 250px;
                                .apply{                                        
                                    font-size: 0.8rem;
                                }
                                .cityInput{
                                    font-size: 0.9rem;
                                    ::placeholder{
                                        font-size: 0.9rem; 
                                    }
                                }
                                .option{
                                    font-size: 0.9rem;
                                    input[type="checkbox"]{
                                        margin-right: 8px;
                                        -ms-transform: scale(1); /* IE */
                                        -moz-transform: scale(1); /* FF */
                                        -webkit-transform: scale(1); /* Safari y Chrome */
                                    }
                                }
                            }
                        }
                        .share{
                            margin-right: 12px;
                            .shareIcon{
                                width: 18px;
                                height: 18px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            .score{
                                font-size: 1.2rem;
                            }
                            .star{
                                margin-top: 5px;
                                margin-left: 4px;
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                    .descriptionContainer{
                        margin-top: 0px;
                        .writeDescription{
                            .apply{
                                font-size: 0.8rem;
                            }
                            textarea{
                                font-size: 0.9rem;
                                height: 60px;
                                padding: 10px;
                            }
                            .maxDescription{
                                font-size: 0.6rem;
                            }
                        }
                        .descriptionEmpty{
                            width: 90%;
                            .description{
                                font-size: 1.1rem;
                            }
                            .item{
                                hr{
                                    margin-top: 20px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            p{
                                font-size: 1.1rem;
                            }
                        } 
                        .edit{
                            width: 18px;
                            height: 18px;
                        }                   
                    }
                    .lastFile{
                        margin-top: 5px;
                    }
                }
            }     
        }

        .myProfileBody{
            padding:30px 60px 30px 60px ;
        } 

    }

    ${media.mediumDesktop}{
        .banner{
            min-height: 280px;
            .bannerInformation{
                .networkContainer{ 
                    width: 55%;
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 2.2rem;
                        }
                        .checkContainer{
                            width: 22px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.1rem;
                            }
                            .language{
                                font-size: 1.1rem;
                            }
                            .categories{
                                font-size: 1rem;
                            }
                            .edit{
                                margin-left: 6px;
                                margin-right: 12px;
                            }
                        }
                        .share{
                            .shareIcon{
                                width: 18px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            .score{
                                font-size: 1.2rem;
                            }
                            .star{
                                margin-top: 5px;
                                margin-left: 7px;
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                    .descriptionContainer{
                        margin-top: 0px;
                        /* .writeDescription{
                            .apply{
                                font-size: 0.8rem;
                            }
                            textarea{
                                font-size: 0.9rem;
                                height: 60px;
                            }
                        } */
                        .descriptionEmpty{
                            width: 90%;
                            .description{
                                font-size: 1.1rem;
                            }
                            .item{
                                hr{
                                    margin-top: 20px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            max-height: 120px;
                            p{
                                font-size: 1.1rem;
                            }
                        } 
                        .edit{
                            width: 18px;
                            height: 18px;
                        }                   
                    }
                    .lastFile{
                        margin-top: 5px;
                    }
                }
            }     
        }

        .myProfileBody{
            padding:30px 60px 30px 60px ;
        } 

    }
    
    ${media.smallDesktop}{
        .banner{
            .bannerInformation{
                .data{
                    .fileName{
                        .name{
                            font-size: 2.1rem;
                        }
                        .checkContainer{
                            width: 22px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1rem;
                            }
                            .language{
                                font-size: 1rem;
                            }
                            .categories{
                                font-size: 0.9rem;
                            }
                            .edit{
                                margin-left: 6px;
                                margin-right: 12px;
                                width: 16px;
                                height: 16px; 
                            }
                            .options{
                                top:30px;
                                padding: 8px 10px;
                                width: 230px;
                                /* .apply{                                        
                                    font-size: 0.8rem;
                                } */
                                /* .cityInput{
                                    font-size: 0.9rem;
                                    ::placeholder{
                                        font-size: 0.9rem; 
                                    }
                                } */
                                .option{
                                    font-size: 0.9rem;
                                    input[type="checkbox"]{
                                        margin-right: 8px;
                                        -ms-transform: scale(1); /* IE */
                                        -moz-transform: scale(1); /* FF */
                                        -webkit-transform: scale(1); /* Safari y Chrome */
                                    }
                                }
                            }
                        }
                        .share{
                            .shareIcon{
                                width: 17px;
                                height: 17px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            align-items: flex-start;
                            //margin-top: 1px;
                            .score{
                                font-size: 1rem;
                            }
                            .star{
                                margin-top: 3px;
                                margin-left: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                    .descriptionContainer{
                        margin-top: 0px;
                        /* .writeDescription{
                            .apply{
                                font-size: 0.8rem;
                            }
                            textarea{
                                font-size: 0.9rem;
                                height: 60px;
                            }
                        } */
                        .descriptionEmpty{
                            width: 90%;
                            .description{
                                font-size: 0.9rem;
                            }
                            .item{
                                hr{
                                    margin-top: 15px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            p{
                                font-size: 0.9rem;
                            }
                        } 
                        .edit{
                            width: 17px;
                            height: 17px;
                        }                   
                    }
                    .lastFile{
                        margin-top: 5px;
                    }
                }               
            }
        }
        .myProfileBody{
            padding:30px 30px 30px 30px ;
        } 
        
    }

    ${media.laptop}{
        .banner{
            .bannerInformation{
                flex-direction: column;
                align-items: center;
                .networkContainer{
                    flex-direction: column;
                    justify-content: center;
                    
                    .socialNetworksProfileContainer{
                        display: none;
                    }
                }

                .socialNetworksProfileContainerMobile{
                    width: fit-content;
                    font-size: 1em;
                    padding: 0;
                    margin-top: 1em;
                    display: flex;
                    color: #ffffff;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: .5em;
                }

                .data{
                    margin-top: 40px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .fileName{
                        margin-bottom: 10px;
                        .name{
                            font-size: 2.3rem;
                        }
                        .checkContainer{
                            width: 24px;
                        }                 
                    }
                    .firstFile{
                        .languageContainer{
                            .scoreContainer{
                                align-items: center;
                                margin-top: 0;
                                .star{
                                    margin-top: 0;
                                    margin-left: 5px;
                                }
                            }
                        }
                        .share{
                            margin-top: 2px;
                            .shareIcon{
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }

                    .firstFile, .lastFile{
                        .item{
                            .edit{
                                width: 15px;
                                height: 15px; 
                            }
                            
                        }
                    }
                    .descriptionContainer{
                        width: 60%;
                        display: flex;
                        justify-content: center;
                         .writeDescription{
                            textarea{
                                height: 70px;
                            }
                        }
                        .edit{
                            width: 15px;
                            height: 15px;
                        }
                        .descriptionContent{
                            p{
                               text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .myProfileBody{
            padding:30px;
        }     
    }

    ${media.largeMobile}{
        .banner{
            .bannerInformation{
                .data{
                    .fileName{
                        margin-bottom: 10px;
                        .name{
                            font-size: 1.6rem;
                        }
                        .checkContainer{
                            width: 18px;
                            height: 18px;
                            margin-left: 8px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        flex-direction: column;
                        align-items: center;
                        .item{
                            .city{
                                font-size: 1rem;
                            }
                            .language{
                                font-size: 1rem;
                            }
                            .categories{
                                font-size: 0.9rem;
                            }
                            .edit{
                                margin-left: 3px;
                                margin-right: 8px;
                                width: 12px;
                                height: 12px; 
                            }
                            
                        }
                        .share{
                            margin-right: 10px;
                            .shareIcon{
                                width: 16px;
                                height: 16px;
                            }
                        }
                        .scoreContainer{
                            display: flex;
                            //align-items: flex-start;
                            .score{
                                font-size: 1rem;
                            }
                            .star{
                                //margin-top: 4px;
                                margin-left: 2px;
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                    .descriptionContainer{
                        width: 80%;
                        .edit{
                            height: 12px;
                            width: 12px;
                        }
                    }
                } 
            }
        }
        .myProfileBody{
            padding:30px 20px ;
        }     
    }

`

export const ProfileImage = styled.div`

    display: flex;
    flex-direction: column;
    margin: auto;
    width: 650px; 
    height: 650px;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-position: 50%;
    position: absolute;
    z-index: 50;

    .imageEdit{
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        padding-right: 22%;
        .editPhoto{
            cursor: pointer;
            width: 60px;
        }
        input[type="file"]{
            display: none;
        }
    }

    .playIconContainer{
        display: flex;
        width : 100%;
        height: 100%;
        justify-content: center;
        align-items: end;
    }
               
    .play{
        border-radius: 50%;
        cursor: pointer;
        width : 120px;
        height: 120px;
        margin-bottom: -3.5em;
    }

    /**--------Responsive-------- */
    ${media.extralargeDesktop}{
        width: 550px; 
        height: 550px;

        .imageEdit{
            .editPhoto{
                width: 45px;
            }
        }
        .play{
            width : 100px;
            height: 100px;
            margin-bottom: -3em;
        }

        
    }

    ${media.largeDesktop}{
        width: 450px; 
        height: 450px;

        .imageEdit{
            .editPhoto{
                width: 40px;
            }
        }
        .play{
            width : 90px;
            height: 90px;
            margin-bottom: -2.75em;
        }
    }

    ${media.specialScreen}{
        width: 380px; 
        height: 380px;
        
        .imageEdit{
            .editPhoto{
                width: 35px;
            }
        }
        .play{
            width : 70px;
            height: 70px;
            margin-bottom: -2.25em;
        }
    }

    ${media.bigmacdesktop}{
        width: 360px; 
        height: 360px;
        
        .imageEdit{
            .editPhoto{
                width: 33px;
            }
        }
        .play{
            width : 60px;
            height: 60px;
            margin-bottom: -1.75em;
        }
    }

    ${media.macdesktop}{
        width: 340px; 
        height: 340px;

        .imageEdit{
            .editPhoto{
                width: 28px;
            }
        }
        .play{
            width : 55px;
            height: 55px;
            margin-bottom: -1.5em;
        }
    }

    ${media.desktop}{
        width: 300px; 
        height: 300px;

        .imageEdit{
            .editPhoto{
                width: 23px;
            }
        }
        .play{
            width : 50px;
            height: 50px;
        }
    }

    ${media.mediumDesktop}{
        width: 290px; 
        height: 290px;
    }

    ${media.smallDesktop}{
        width: 280px; 
        height: 280px;

        .imageEdit{
            .editPhoto{
                width: 25px;
            }
        }
    }

    ${media.laptop}{
        position: initial;

        .profilePhotoContainer{
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
        
        .imageEdit{
            .editPhoto{
                width: 15px;
                height: 15px;
            }
        }
    }

    ${media.largeMobile}{
        width: 180px;
        height: 180px;
        
        .imageEdit{
            .editPhoto{
                width: 12px;
            }
        }
        .play{
            width : 40px;
            height: 40px;
            margin-bottom: -1.25em;
        }
    }
`

export const NetworkCount = styled.div`

        position: relative;
        text-align: end;
        width: ${(props) => props.count < networkRanges.rango1 ? '58%' : props.count < networkRanges.rango2 ? '66%' : props.count < networkRanges.rango3 ? '74%' : props.count < networkRanges.rango4 ? '82%' : props.count < networkRanges.rango5 ? '91%' : '100%'};
        height: 80px;
        margin-top: 10px;
        .networkEmpty{
            cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};
            .network{
                cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};   
            }
            .facebookButton{
                cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};
            }
        }

        /**----------Responsive---------- */
        ${media.extralargeDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '64%' : props.count < networkRanges.rango2 ? '71%' : props.count < networkRanges.rango3 ? '78%' : props.count < networkRanges.rango4 ? '85%' : props.count < networkRanges.rango5 ? '92%' : '100%'};
            height: 70px;
        }

        ${media.largeDesktop}{
            height: 65px;
        }

        ${media.specialScreen}{
            width: ${(props) => props.count < networkRanges.rango1 ? '70%' : props.count < networkRanges.rango2 ? '76%' : props.count < networkRanges.rango3 ? '82%' : props.count < networkRanges.rango4 ? '88%' : props.count < networkRanges.rango5 ? '94%' : '100%'};
            height: 42px;
        }

        ${media.bigmacdesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '58%' : props.count < networkRanges.rango2 ? '66%' : props.count < networkRanges.rango3 ? '74%' : props.count < networkRanges.rango4 ? '82%' : props.count < networkRanges.rango5 ? '91%' : '100%'};
            height: 40px;
        }

        ${media.macdesktop}{
            height: 38px;
        }

        ${media.desktop}{
            height: 35px; 
            margin-top: 5px;
        }

        ${media.mediumDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '65%' : props.count < networkRanges.rango2 ? '72%' : props.count < networkRanges.rango3 ? '79%' : props.count < networkRanges.rango4 ? '86%' : props.count < networkRanges.rango5 ? '93%' : '100%'};
            height: 33px;
        }

        ${media.smallDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '70%' : props.count < networkRanges.rango2 ? '76%' : props.count < networkRanges.rango3 ? '82%' : props.count < networkRanges.rango4 ? '88%' : props.count < networkRanges.rango5 ? '94%' : '100%'};
            height: 31px;
        }
                    
`