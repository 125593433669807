import React, { useState } from "react";
import { ModalCarouselContainer } from "./ModalCarouselStyled";

import close from './../../utils/images/close_circle.svg';
import photoVideo from './../../utils/images/cameraError.svg';
import Carousel from 'react-material-ui-carousel';
import { ContentTypes } from "../../utils/constants/item";

export default function ModalCarousel({ setShowModalCarousel, content, activeIndex = 0, page, contentType = 'IMAGE' }) {

    const [auxActiveIndex, setAuxActiveIndex] = useState(activeIndex);

    return (
        <ModalCarouselContainer>
            <div className="carouselBody">
                <div className="carouselHeader">
                    <img src={close} alt="close" onClick={() => { setShowModalCarousel(false) }} className="close" />
                </div>
                <Carousel
                    next={(next, active) => {
                        setAuxActiveIndex(next)
                    }}
                    prev={(prev, active) => {
                        setAuxActiveIndex(prev)
                    }}
                    autoPlay={false}
                    navButtonsAlwaysVisible={content.length > 1}
                    indicators={false}
                    cycleNavigation={true}
                    swipe={true}
                    className='navButton'
                    index={auxActiveIndex}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'transparent',
                            color: '#ffffff',

                        },
                        button: {
                            margin: "0 10px",
                            position: "relative",
                            backgroundColor: "red",
                            top: "calc(50% - 20px) !important",
                            color: "white",
                            fontSize: "100px",
                            transition: "200ms",
                            cursor: "pointer",
                            '&:hover': {
                                opacity: "0.6 !important"
                            },
                        },
                    }}
                >
                    {
                        content?.map((contentItem, key) => {
                            return (
                                <div className="imageContainer" key={key}>
                                    {
                                        (page === 'profile') ? 
                                            (<img src={contentItem} alt="cover" className='imageCarousel' />) 
                                            : 
                                            (contentType === ContentTypes.IMAGE) ? 
                                                (<img src={contentItem} alt="cover" className='imageCarousel' />) 
                                                :
                                                (<video className="videoCarousel" controls src={contentItem} />)
                                    }
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        </ModalCarouselContainer>
    )
}