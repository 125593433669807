import React, { useContext, useEffect, useState } from "react";
import { ModalCarouselContainer } from "./ModalCarouselStyled";

import close from "./../../utils/images/close_circle.svg";
import avatar from "./../../utils/images/avatar_fluincr.png";

import Carousel from "react-material-ui-carousel";
import { UserTypes } from "../../utils/constants/user";
import { StaticDataContext } from "../../context/staticDataContext";
import { ContentTypes } from "../../utils/constants/item";

export default function ModalCarouselCollaborations({
  content,
  influencer,
  company,
  setShowModalCarousel,
  profileUserType,
}) {
  const { fluincrServicesList } = useContext(StaticDataContext);

  const [activeImage, setActiveimage] = useState(1);

  const [imageProfile, setImageProfile] = useState("");

  const [nameCollaborator, setNameCollaborator] = useState("");

  useEffect(() => {
    if (profileUserType === UserTypes.COMPANY) {
      setImageProfile(influencer?.image_profile);
      setNameCollaborator(`${influencer?.name} ${influencer?.family_name}`);
    } else {
      setImageProfile(company?.image_profile);
      setNameCollaborator(company?.name);
    }
  }, []);

  return (
    <ModalCarouselContainer>
      <div className="carouselBody">
        <div className="carouselHeader">
          <img
            src={close}
            alt="close"
            onClick={() => {
              setShowModalCarousel(false);
            }}
            className="close"
          />
        </div>

        <Carousel
          next={(next, active) => {
            setActiveimage(next + 1);
          }}
          prev={(prev, active) => {
            setActiveimage(prev + 1);
          }}
          autoPlay={false}
          navButtonsAlwaysVisible={true}
          indicators={false}
          cycleNavigation={false}
          swipe={true}
          className="navButton"
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              color: "#ffffff",
            },
            button: {
              margin: "0 10px",
              position: "relative",
              backgroundColor: "red",
              top: "calc(50% - 20px) !important",
              color: "white",
              fontSize: "100px",
              transition: "200ms",
              cursor: "pointer",
              "&:hover": {
                opacity: "0.6 !important",
              },
            },
          }}
        >
          {content?.map((item, index) => {
            const contentType = fluincrServicesList.find(service => service.id === item.content_id)?.type;

            return (
              <div className="imageContainer" key={index}>
                {contentType === ContentTypes.IMAGE ? (
                  <img src={item.content} alt="cover" className="image" />
                ) : (
                  <video controls className="video">
                    <source src={item.content} type="video/mp4" />
                  </video>
                )}

                <div className="collaborationInfo">
                  <div
                    className="imageColaborator"
                    style={{
                      backgroundImage: `url(${item?.collaborator?.image_profile ?? avatar})`,
                    }}
                  ></div>

                  <p className="nameColaborator">{item?.collaborator?.name ?? ''}</p>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </ModalCarouselContainer>
  );
}
