import React, { useState, useContext, useEffect } from "react";
import { PrincipalTemplateContainer } from "./PrincipalTemplateStyled";
import { CollaborationsContext } from "../../context/collaborationsContext";

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import ModalScoreCollaboration from "../../components/ModalScoreCollaboration/ModalScoreCollaboration";
import ModalCompleteProfileReminder from "../../components/ModalCompleteProfileReminder/ModalCompleteProfileReminder";


export default function PrincipalTemplate({ children }) {

    const { ratePending } = useContext(CollaborationsContext);
    const [showRatePending, setShowRatePending] = useState(false);

    useEffect(() => {
        if (ratePending) {
            setShowRatePending(true)
        } else {
            setShowRatePending(false)
        }
    }, [ratePending])


    return (
        <PrincipalTemplateContainer>

            <Header />
            {children}
            <Footer />

            {(showRatePending) && (<ModalScoreCollaboration setShowRatePending={setShowRatePending} />)}

            <ModalCompleteProfileReminder />
        </PrincipalTemplateContainer>

    )
}