import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const MyProfileProductsContainer = styled.div`

    margin-top: 250px;
    margin-bottom: 30px;
    .title{
        color: ${colors.title};
        margin-bottom: 20px;
        font-size: 3.5rem;
    }

    .cardsContainer{
        display: flex;
        flex-wrap: wrap;
        .productContainer{
            width: 23%;
            height: 21vw;
            margin-bottom: 20px;
            margin-right: 2%;
            position: relative;
            .edit{
                width: 50px;
                position: absolute;
                top: 30px;
                right: 20px;
                cursor: pointer;
            }
        }
        .card{
            width: 23%;
            height: 21vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${colors.greyIcon};
            margin-bottom: 20px;
            margin-right: 2%;
            padding: 80px;
            text-align: center;
            font-size: 2rem;
            cursor: pointer;
            img{
                width: 100px;
                margin-bottom: 5px;
            }
            
        }
    }

    /**--------Responsive--------- */
    ${media.largeDesktop}{
        margin-top: 200px;
        .title{
            font-size: 2.6rem;
        }
        .cardsContainer{
            .productContainer{
                .edit{
                    width: 40px;
                    top: 25px;
                    right: 20px;
                }
            }
            .card{
                font-size: 1.5rem;
                img{
                    width: 80px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    ${media.specialScreen}{
        margin-top: 150px;
        .cardsContainer{
            .productContainer{
                .edit{
                    width: 30px;
                    top: 20px;
                    right: 15px;
                }
            }
            .card{
                font-size: 1.1rem;
                img{
                    width: 60px;
                    margin-bottom: 5px;
                }
                        
            }
        }
    }

    ${media.desktop}{
        margin-top: 100px;
        .title{
            font-size: 2.3rem;
        }
        .cardsContainer{
            .productContainer{
                .edit{
                    width: 25px;
                    top: 20px;
                    right: 15px;
                }
            }
            .card{
                font-size: 1rem;
                img{
                    width: 50px;
                    margin-bottom: 5px;
                }
                        
            }
        }
    }

    ${media.smallDesktop}{
        .title{
            font-size: 2.1rem;
        }
        .cardsContainer{
            .productContainer{
                .edit{
                    width: 23px;
                    top: 15px;
                    right: 10px;
                }
            }
            .card{
                padding: 50px;
                img{
                    width: 45px;
                }
                        
            }
        }
    }
   
    ${media.laptop}{
        margin-top: 20px;
    }

    ${media.tablet}{
        .title{
            font-size:2.3rem;
        }
        .cardsContainer{
            .productContainer{
                width: 31%;
                height: 28vw;
            }
            .card{
                width: 31%;
                height: 28vw;
                padding: 40px;
                margin-bottom: 10px;
            }
            .last{
                display:none
            }
        }

    }
    ${media.largeMobile}{
        .title{
            font-size:1.8rem;
        }
        .cardsContainer{
            .productContainer{
                width: 48%;
                height: 40vw;
                .edit{
                    width: 20px;
                    top: 10px;
                    right: 8px;
                }
            }
            .card{
                width: 48%;
                height: 40vw;
                padding: 30px;
                font-size: 0.9rem;
                img{
                    width: 35px;
                    margin-bottom: 10px;
                }
            }
            .last{
                display: inherit;

            }
            /* .card:nth-child(3){
                display: none;
            } */
        }

    }

`

export const ProductCard = styled.div`
    background-image:url(${(props) => props.image});    
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    margin: 0.5%;
`