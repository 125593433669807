import axios from 'axios';
import { API_GATEWAY_URL } from '../../../utils/constants/constants';
import axiosInstance from '../../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user';

export const CompanyAPI = {

    createCompany: async (userData) => {
        const response = await axios.post(`${BASE_URL}/company/create`, userData);
        return response.data;
    },

    getAllCompanies: async () => {
        const response = await axios.get(`${BASE_URL}/company`);
        return response.data;
    },

    // Devuelve un objeto Pageable con los companies en el atributo content
    getCompaniesFiltered: async (filters) => {
        try {
            const response = await axios.get(`${BASE_URL}/company/filters`, {
              params: filters
            });
            return response.data;
          } catch (error) {
            console.error('Error al obtener companies:', error);
            throw error;
          }
    },

    getCompaniesFeatured: async (count) => {
        try {
            const response = await axios.get(`${BASE_URL}/company/featured?count=${count}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener companies destacados:', error);
            throw error;
        }
    },

    updateUserCompany: async (data) => {
        const response = await axiosInstance.put(`${BASE_URL}/company`, data);
        return response.data;
    },

    likes: async (influencerId) => {
        const response = await axiosInstance.put(`${BASE_URL}/company/likes/${influencerId}`);
        return response.data;
    },

    updateVideoProfile: async (videoProfile) => {
        const response = await axiosInstance.put(`${BASE_URL}/company/video-profile?${videoProfile}`);
        return response.data;
    }
};