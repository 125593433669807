import { useEffect } from "react";
import { useContext } from "react";
import { createContext, useState } from "react";
import { CollaborationAPI } from "../api/collaboration/collaboration";
import { UserTypes } from "../utils/constants/user";
import { UserContext2 } from "./user/userContext";


export const CollaborationsContext = createContext();

export default function CollaborationsProvider({ children }) {

    const { userData, userToken } = useContext(UserContext2);
    const [collaborationNotificationCount, setCollaborationNotificationCount] = useState(0);

    const [ratePending, setRatePending] = useState(null);

    const [userCollaborations, setUserCollaborations] = useState([]);
    const [currentCollaboration, setCurrentCollaboration] = useState(null);
    
    const [userActiveCollaborations, setUserActiveCollaborations] = useState([]);
    const [userFinishedCollaborations, setUserFinishedCollaborations] = useState([]);
    const ACTIVE_COLLABORATION_STATUS = [1, 3, 4];
    const FINISH_COLLABORATION_STATUS = [2, 5];

    useEffect(() => {
        setUserActiveCollaborations(userCollaborations.filter(collaboration => ACTIVE_COLLABORATION_STATUS.includes(collaboration.status.id)));
        setUserFinishedCollaborations(userCollaborations.filter(collaboration => FINISH_COLLABORATION_STATUS.includes(collaboration.status.id)));
    }, [userCollaborations]);

    useEffect(() => {
        if(userToken)
            CollaborationAPI.getCollaborationPendingToRate().then((response) => {
                if(response != null ){
                    setRatePending(response);
                }
            }).catch((error) => {
                console.error(error);
                setRatePending(null);
            });
    }, [userToken]);

    const loadCollaborations = (setIsLoading = () => null) => {
        if (userData?.id) {
            setIsLoading(true);
            CollaborationAPI.getCollaborationsByUserId(userData?.id).then((response) => {
                const collaborationsSorted = response.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));

                setUserCollaborations(response);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
        }
    }

    const clearCollaborations = () => {
        setUserCollaborations([]);
        setUserActiveCollaborations([]);
        setUserFinishedCollaborations([]);
    }

    return (
        <CollaborationsContext.Provider value={{ 
            collaborationNotificationCount, 
            setCollaborationNotificationCount, 
            ratePending, 
            setRatePending, 
            userCollaborations, 
            userActiveCollaborations, 
            userFinishedCollaborations, 
            loadCollaborations, 
            clearCollaborations,
            currentCollaboration,
            setCurrentCollaboration
        }}>
            {children}
        </CollaborationsContext.Provider >
    )

};