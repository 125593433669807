import React from "react";
import { CheckIcon, Circle, InputWrapper, ShowIcon, StyledInput } from "./InputStyled";
import { useState } from "react";

const Input = ({ isValid, type, classNameContainer, ...props }) => {
  const [showPwd, setShowPwd] = useState(false);
  const handleShowPwd = () => setShowPwd((state)=>!state);
  return (
    <InputWrapper isValid={isValid} className={classNameContainer}>
      <StyledInput type={showPwd ? "text": type} {...props} />
      {type === 'password' && <ShowIcon onClick={handleShowPwd} className={showPwd ? "fa-solid fa-eye": "fa-solid fa-eye-slash"} />}
      {isValid && <Circle isValid={isValid}>
        <CheckIcon className="fas fa-check" />
      </Circle>
      }
    </InputWrapper>
  );
};

export default Input;