import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const NetworkRedirectContainer = styled.div`
    
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .facebookContent{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .facebookMessage{
            margin-top: 10px;
        }
    }
    .facebookButton{
        margin-top: 15px;
        display: flex;
        align-items: center;
        background-color: ${colors.facebookButton};
        color: #ffffff;
        padding: 4px 20px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        .loginLogo{
            margin-right: 10px;
            width: 15px;
        }
    }

    .continueButton{
        cursor: pointer;
        border-radius: 10px;
        border: none;
        height: 35px;
        padding: 4px 20px;
        background: ${colors.hightlight};
        color: #ffffff; 
    }

    .messageTwitter{
        margin-top: 20px;
    }
`