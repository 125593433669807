import React, { useContext, useState, useEffect, useRef } from "react";
import { InfluencerProfileContainer, ProfileImage } from "./InfluencerProfileStyled";
import { FormattedMessage } from 'react-intl';
import ModalInfluencerVideo from "../../components/ModalInfluencerVideo/ModalInfluencerVideo";
import InfluencerProfileFirstSection from "../../components/InfluencerProfileFirstSection/InfluencerProfileFirstSection";

import { useNavigate, useParams } from 'react-router-dom';

import avatar from './../../utils/images/avatar_fluincr.png';
import arrowBack from './../../utils/images/arrow_back_black.svg';
import check from './../../utils/images/check_circle_darker.svg';
import star from './../../utils/images/star.svg';
import play from './../../utils/images/play_grey.svg';
import share from './../../utils/images/share_white.svg';

import LikeCompany from "../../components/LikeCompany/LikeCompany";
import LikeProduct from "../../components/LikeProduct/LikeProduct";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import LikeInfluencer from "../../components/LikeInfluencer/LikeInfluencer";
import ModalCarousel from "../../components/ModalCarousel/ModalCarousel";
import Spinner from "../../components/Spinner/Spinner";
import CollaborationItemCardPublicProfile from "../../components/CollaborationItemCardPublicProfile/CollaborationItemCardPublicProfile";
import TooltipLight from "../../components/TooltipLight/TooltipLight";
import ProfileSocialNetworks from "../../components/ProfileSocialNetworks/ProfileSocialNetworks";
import { UserAPI } from "../../api/user/user";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";

export default function InfluencerProfile() {

    const navigate = useNavigate();
    const shareRef = useRef(null);
    const { languageSelectList, countrySelectList, categorySelectList } = useContext(StaticDataContext);
    const { userData, userToken, userType } = useContext(UserContext2);

    const { username } = useParams();

    const [userDataPublic, setUserDataPublic] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    
    const [profileId, setProfileId] = useState('');
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [countryId, setCountryId] = useState('');
    const [account, setAccount] = useState('');
    const [description, setDescription] = useState('');
    const [verified, setVerified] = useState(false);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [profileUserType, setProfileUserType] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [profileVideo, setProfileVideo] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [rating, setRating] = useState(null);
    const [collaborationsItems, setCollaborationsItems] = useState([]);
    const [socialNetworksAccounts, setSocialNetworksAccounts] = useState([]);

    // Only for companies
    const [products, setProducts] = useState([])

    // Only for influencers
    const [gallery, setGallery] = useState([]);
    const [ratesByService, setRatesByService] = useState([]);
    const [collaboratorsInfluencer, setCollaboratorsInfluencer] = useState([]);

    useEffect(() => {
        const userType = userDataPublic?.user_type?.name;
        const items = userDataPublic?.profile_data?.items;
        const filterItems = items?.filter((item) => (userType === UserTypes.COMPANY && item.public_profile_company) || (userType === UserTypes.INFLUENCER && item.public_profile_influencer));

        setProfileId(userDataPublic?.id);
        setName(userDataPublic?.name);
        setFamilyName(userDataPublic?.family_name);
        setCity(userDataPublic?.address?.city);
        setCountryId(userDataPublic?.address?.country_id);
        setAccount(userDataPublic?.account);
        setDescription(userDataPublic?.description);
        setVerified(userDataPublic?.verified);
        setCategories(userDataPublic?.categories);
        setLanguages(userDataPublic?.languages);
        setProfileUserType(userType);
        setProfileImage(userDataPublic?.image_profile);
        setProfileVideo(userDataPublic?.video_profile);
        setRating(userDataPublic?.rating);
        setCollaborationsItems(filterItems);
        setSocialNetworksAccounts(userDataPublic?.social_networks);

        if(userDataPublic?.user_type?.name === UserTypes.INFLUENCER){
            setGallery(userDataPublic?.profile_data?.gallery);
            setRatesByService(userDataPublic?.rate_by_service_list);
            setCollaboratorsInfluencer(userDataPublic?.profile_data?.collaborators);
        } else if(userDataPublic?.user_type?.name === UserTypes.COMPANY){
            setProducts(userDataPublic?.profile_data?.products);

        }

    }, [userDataPublic]);

    const [auxGallery, setAuxGallery] = useState([]);
    const [limitGallery, setLimitGallery] = useState(8);
    const [offsetGallery, setOffsetGallery] = useState(0);
    const [prices, setPrices] = useState([]);

    const [auxProducts, setAuxProducts] = useState([]);
    const [limitProducts, setLimitProducts] = useState(4);
    const [offsetProducts, setOffsetProducts] = useState(0);

    const [showModalVideo, setShowModalVideo] = useState(false);
    const [showSocialMedia, setShowSocialMedia] = useState(false);
    const url = window.location.href;

    let countProductsToShow = 0;

    //const [image, setImage] = useState([]);
    const [content, setContent] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showCarousel, setShowCarousel] = useState(false);


    const handlerMoreGallery = () => {
        setLimitGallery(limitGallery + 4)
        setOffsetGallery(limitGallery)
    }

    useEffect(() => {
        const newGallery = [...auxGallery].concat(gallery?.slice(offsetGallery, limitGallery))
        setAuxGallery(newGallery)
    }, [limitGallery, offsetGallery])

    const handlerMoreProducts = () => {
        setLimitProducts(limitProducts + 4)
        setOffsetProducts(limitProducts)
    }

    useEffect(() => {
        const newProducts = [...auxProducts].concat(products?.slice(offsetProducts, limitProducts))
        setAuxProducts(newProducts)
    }, [limitProducts, offsetProducts])

    useEffect(() => {
        setIsLoading(true);

        if(userData && userData?.username === username) {
            setUserDataPublic(userData);
            setIsLoading(false);
            return;
        }

        UserAPI.getPublicUserData(username, true).then((response) => {
            setIsLoading(false);
            console.log(response);
            setUserDataPublic(response);
        })
    }, [username, userToken])

    useEffect(() => {
        if (profileUserType === UserTypes.COMPANY) {
            if (!userToken) {
                navigate('/signin')
            }
            if ((userData?.username) && (userData?.username !== username)) {
                if (userType === UserTypes.COMPANY) {
                    navigate('/error')
                }
            }
        }
    }, [profileUserType, userData, username])

    useEffect(() => {
        function handleClickOutside(event) {

            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setShowSocialMedia(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [shareRef])


    return (
        <InfluencerProfileContainer>
            <div className="banner">
                <div className="goBack" onClick={() => navigate(-1)}>
                    <img src={arrowBack} alt="back" className="backIcon" />
                    <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
                </div>
                <div className="bannerInformation">
                    <div className="networkContainer">
                        <div>
                            <ProfileImage image={profileImage ?? avatar} >
                                <div className="playIconContainer">
                                    <img src={play} alt="avatar" className="play" onClick={() => { setShowModalVideo(true) }} />
                                </div>
                            </ProfileImage>
                        </div>

                        <div className="socialNetworksProfileContainer">
                            <ProfileSocialNetworks userSocialNetworksAccounts={socialNetworksAccounts}></ProfileSocialNetworks>
                        </div>


                    </div>
                    <div className="data">
                        <div className="fileName">
                            {(profileUserType === UserTypes.INFLUENCER) ? (<p className="name">{(`${name} ${familyName}`)}</p>) : (<p className="name">{name}</p>)}

                            {(verified) && (<div className="checkContainer">
                                <img src={check} alt="check" className="check" />
                            </div>)}


                            {((userType === UserTypes.COMPANY && profileUserType !== UserTypes.COMPANY) || userToken === null) ? (
                                <div className="likeInfluencerContainer"><LikeInfluencer profileId={profileId} /></div>) : (<div className="favorite" />)}

                            {(userType === UserTypes.INFLUENCER && profileUserType !== UserTypes.INFLUENCER) && (<div className="likeCompanyContainer"><LikeCompany profileId={profileId} /></div>)}
                        </div>

                        <div className="firstFile">
                            <div className="item">
                                <p className="city">
                                    {(city !== null) && (<span>
                                        {countrySelectList.find(country => country.value === countryId)?.label},
                                        {` ${city}`}
                                    </span>)}
                                </p>
                            </div>
                            <div className="items">
                                <div className="subItem">
                                    <p className="language">
                                        {(languages?.length > 0) &&
                                            (<span>
                                                {languages?.map((languageId, index, key) => {
                                                    if(index < 4){
                                                        const language = languageSelectList.find(language => language?.value === languageId);
                                                        const lastOne = (userData?.languages.length - 1 === index) || index === 3; 
                                                        return (
                                                            <TooltipLight title={language?.label} placement="top" key={languageId}>
                                                                <span>{ language?.convention + '/ '}</span>
                                                            </TooltipLight>
                                                        )
                                                    }
                                                })}
                                            </span>)
                                        }
                                    </p>
                                </div>

                                <div className="share" ref={shareRef}>
                                    <img src={share} alt="edit" className="shareIcon" onClick={() => { setShowSocialMedia(!showSocialMedia) }} />
                                    {(showSocialMedia) && (<SocialMedia shareUrl={url} />)}
                                </div>


                                <div className="subItem">
                                    {
                                        (rating) &&
                                            <>
                                                <p className="score">{rating ?? 'N/R'}</p>
                                                <img src={star} alt="star" className="star" />
                                            </>
                                    }
                                    
                                    {
                                        (collaborationsItems) && (
                                            <p className="language">{`(${collaborationsItems?.length})`}</p>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="descriptionContainer" >

                            {(!description) ?
                                (<div className="descriptionEmpty">
                                    <div className="item">
                                        <hr />
                                        <hr />
                                        <hr />
                                    </div>
                                </div>) :
                                (<div className="descriptionContent">
                                    <p>{description}</p>
                                </div>)}
                        </div>
                        <div className="lastFile" >
                            <p className="categories">
                                {categories?.length > 0 && (
                                    <>
                                        {categories?.map((categId, index) => {
                                        if (index < 4) {
                                            const category = categorySelectList.find(
                                            (cat) => cat.value === categId
                                            );
                                            return `#${category?.label} `;
                                        }
                                        })}
                                    </>
                                )}
                            </p>
                        </div>

                    </div>
                    
                    <div className="socialNetworksProfileContainerMobile">
                        <ProfileSocialNetworks userSocialNetworksAccounts={socialNetworksAccounts} ></ProfileSocialNetworks>
                    </div>
                </div>

            </div>
            <div className="myProfileBody">

                {(profileUserType === UserTypes.INFLUENCER) && (<InfluencerProfileFirstSection influencerPublicData={userDataPublic} />)}

                {(profileUserType === UserTypes.COMPANY) && (<div className="productsCardsContainer">
                    <div className="title">
                        <h3><FormattedMessage id="influencer_profile_subtitle_1" defaultMessage="Productos" /></h3>
                    </div>
                    <div className="productsCards">
                        {products?.map((product) => {
                            if (product.show_in_profile) {
                                countProductsToShow += 1;
                                let productLink = product?.link;
                                if(productLink && !productLink.startsWith('http://') && !productLink.startsWith('https://')){
                                    productLink = `https://${productLink}`;
                                }
                                return (
                                    <a 
                                        className="front" 
                                        style={{ backgroundImage: `url(${product.image})` }} 
                                        key={product.id}
                                        href={productLink} 
                                        target="_blank" 
                                        rel="noreferrer"
                                        onClick={(e) => {
                                            if (!productLink) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                            {(userData?.username !== username) && (
                                                <div className='likeProductContainer' onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                    <LikeProduct product={product} companyId={profileId}/>
                                                </div>
                                            )}

                                            <div className="productName"><p>{product.name}</p></div>
                                    </a>
                                )
                            }

                        })}
                    </div>
                    {(countProductsToShow < 1) && (<div className="noContent" onClick={handlerMoreProducts}>
                        <p ><FormattedMessage id="influencer_profile_text_1" defaultMessage="No hay productos" /></p>
                    </div>)}
                </div>)}

                <div className="collaborationsCardsContainer">
                    <div className="title">
                        <h3><FormattedMessage id="influencer_profile_subtitle_2" defaultMessage="Colaboraciones" /></h3>
                    </div>


                    <div className="collaborationCards">

                        {
                            collaborationsItems?.map((collaborationItem) => {
                                return (<CollaborationItemCardPublicProfile key={collaborationItem?.id} collaborationItem={collaborationItem} profileUserType={profileUserType} />)
                            })
                        }

                        {(!collaborationsItems || collaborationsItems.length == 0) &&
                            <div className="notContent">
                                <p><FormattedMessage id="public_profile_text_1" defaultMessage="Contenido no disponible" /></p>
                            </div> 
                        }    

                    </div>
                </div>

                {(profileUserType === UserTypes.INFLUENCER) && (
                    <div className="galeryCardsContainer">
                        <div className="title">
                            <h3><FormattedMessage id="my_profile_subtitle_3" defaultMessage="Contenido" /></h3>
                        </div>

                        <div className="galeryCards">
                            {(gallery.length > 0) ? 
                                (gallery.map((galleryItem, index) => 
                                    <div className="front " style={{ backgroundImage: `url(${galleryItem?.url})` }} onClick={() => {
                                        setContent([galleryItem?.url]);
                                        setShowCarousel(true)
                                    }}></div>
                                )) 
                                : 
                                (<div className="notContent">
                                    <p><FormattedMessage id="public_profile_text_1" defaultMessage="Contenido no  disponible" /></p>
                                 </div>)
                            }

                        </div>
                        {/*(auxGallery < gallery) && (<div className="more" onClick={handlerMoreGallery}>
                            <p ><FormattedMessage id="home_more_text" defaultMessage="Ver más +" /></p>
                        </div>)*/}

                    </div>)}

            </div>

            {(showModalVideo) && (<ModalInfluencerVideo setShowModalVideo={setShowModalVideo} video={profileVideo} />)}

            {(showCarousel) && (<ModalCarousel setShowModalCarousel={setShowCarousel} content={content} page='profile' />)}

            {(isLoading) && (<Spinner />)}

        </InfluencerProfileContainer>
    )
}