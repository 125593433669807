import React, { useState, useContext, useEffect } from "react";
import { InfluencerProfileFirstSectionContainer } from "./InfluencerProfileFirstSectionStyled";
import { FormattedMessage } from 'react-intl';

import gift from './../../utils/images/gift.svg';
import avatar from './../../utils/images/avatar_fluincr.png';
import ModalCreateCollaboration from "../ModalCreateCollaboration/ModalCreateCollaboration";
import { formatNumber } from "../../services/generalSevices";
import { CurrencyContext } from "../../context/currencyContext";

export default function InfluencerProfileFirstSection({ influencerPublicData }) {

    const [showCreateCollaboration, setShowCreateCollaboration] = useState(false);
    const { currency, convertCurrency } = useContext(CurrencyContext);
    const [price, setPrice] = useState(null);

    useEffect(() => {

        if (currency && influencerPublicData) {
            const amount = influencerPublicData?.rate_by_service_list?.filter(rate => rate?.price > 0)
                .reduce((precioMin, serviceActual) => {
                    return (serviceActual.price < precioMin || precioMin === 0) ? serviceActual.price : precioMin;
                }, 0);

            const auxCurrency = currency.symbol

            setPrice(convertCurrency(amount, auxCurrency)?.toFixed(2));
        }

    }, [currency, influencerPublicData])

    return (
        <InfluencerProfileFirstSectionContainer>

            <div className="collaborationsContainer">
                <div className="collaborations">

                    {influencerPublicData?.collaborators?.map((collaborator, index) => {
                        return (
                            <div className="collaboration" key={index}>
                                <img src={collaborator?.image_profile ?? avatar} alt="collaborator" />
                            </div>
                        )
                    })}
                    {/* {emptyCollaborator()} */}

                </div>
                <p className="collaborationText"><FormattedMessage id="my_profile_first_section_text_6" defaultMessage="Colaboradores" /></p>
            </div>
            <div className="rateContainer">
                <div className="rate">
                    <p className="rateTitle"><FormattedMessage id="my_profile_first_section_text_2" defaultMessage="Foto / Video" /></p>
                    <div className="rateInformation">
                        <p className="rateDescription"><FormattedMessage id="my_profile_first_section_text_5" defaultMessage="Desde" /></p>
                        <div>
                            <p className="value">{currency?.symbol} {formatNumber(price)} /  </p>
                            <img src={gift} alt="gift" />
                        </div>
                    </div>
                </div>

                <button className="startCollaboration" onClick={() => { setShowCreateCollaboration(true) }}><FormattedMessage id="my_profile_first_section_text_7" defaultMessage="Iniciar colaboración" /></button>

            </div>

            {(showCreateCollaboration) && (<ModalCreateCollaboration influencerPublicData={influencerPublicData} setShowCreateCollaboration={setShowCreateCollaboration}/>)}

        </InfluencerProfileFirstSectionContainer>
    )
}