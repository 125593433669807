import React from "react";
import { ModalInfluencerVideoContainer } from "./ModalInfluencerVideoStyled";
import camera from './../../utils/images/camera.svg';
import close from './../../utils/images/close_circle_outline.svg';

export default function ModalInfluencerVideo({ setShowModalVideo, video }) {
    return (
        <ModalInfluencerVideoContainer>

            <img src={close} alt="close" onClick={() => { setShowModalVideo(false) }} className="close" />
            {(video === null) ? (<div className="notVideoContainer">
                <img src={camera} alt="camera" className="cameraIcon" />
                <p className="subTitle">Mi video</p>
                <p>Proximamente disponible</p>
            </div>) : (<div className="videoContainer">
                <video src={video} controls={true} className="video"></video>
            </div>)}


        </ModalInfluencerVideoContainer>
    )
}