import styled from 'styled-components';
import { colors } from '../../utils/styles/colors';
import { media } from '../../utils/styles/media';

export const CompanyCardVoucherContainer = styled.div`
    
    width: 20%;
    //width: 10vw;
    padding: 12px;
    .image{
        width: 100%;
        //height: 250px;
        height: 9vw;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 8px;
        .data{
            display: flex;
            width: 100%;
            justify-content: space-between;
            p{
                background-color: rgb(61, 61, 61, 0.7);
                color: #ffffff;
                padding: 5px 10px;
                border-radius:15px;
                font-weight: 500;
                font-size: 1.5rem;
                font-family: 'Montserrat', sans-serif;
            }
        }
    }

    .name{
        font-size: 2.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.text};
    }
    .name:hover{
        opacity: 0.8;
        cursor: pointer;
    }

    .companyData{
        display: flex;
        align-items: baseline;
        font-size: 2rem;
        align-items: center;
        font-weight: 100;
        img.companyCountry{
            height:30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 3px;
        }
        .categories{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-transform: lowercase;
            font-weight: bold;
            .category{
                padding-right:3px;
                font-size: 1.2rem; 
            }
        }
        
    }


    /**---------Responsive-----------*/
    ${media.largeDesktop}{
        padding: 10px;
        .image{
            //height: 180px;
        }
        .name{
            //font-size: 2rem;
            font-size: 1.6rem;
            
        }
        .companyData{
            //font-size: 1.5rem;
            font-size: 1.2rem;
        }

    }

    ${media.bigmacdesktop}{
        padding: 6px;
        .name{
            font-size: 1.1rem;
        }
        .companyData{
            font-size: 0.8rem;
            img.companyCountry{
                height:23px;
                width: 23px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 0.9rem; 
                }
            }
        }
    }
    
    ${media.desktop}{
        padding: 4px;
        .image{
            //height: 130px;
        }
        .name{
            font-size: 1.1rem;
        }
        .companyData{
            font-size: 0.8rem;
            img.companyCountry{
                height:23px;
                width: 23px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 0.8rem; 
                    
                }
            }
        }
    }

    /* @media(max-width: 1112px){
        .name{
            font-size: 1.3rem;
        }
    } */

    ${media.smallDesktop}{
        width: 20%;
        .image{
            height: 16vw;
            .favorite{
                width: 30px;
            }
        }
    }

    
    ${media.laptop}{
        padding: 4px;
        .image{
            .data{
                p{
                    font-size: 0.8rem;
                }
            }
        }
        .name{
            font-size: 1rem;
        }
        .influencerData{
            align-items: center;
            img.influencerCountry{
                height:20px;
                width: 20px;
            }
            span{
                font-size: 0.7rem;
            }
        }
    }
    @media(max-width: 744px){
        .name{
            font-size: 0.9rem;
        }
    }
    @media(max-width: 659px){
        .name{
            font-size: 0.8rem;
        }
    }


    ${media.largeMobile}{
        width: 50%;
        
        .image{
            //height: 150px;
            height: 40vw;
        }
        :last-child{
            display: none;
        }
        .name{
            font-size: 1.2rem;
        }
        
    }
    
    
`