import React, { useState, useEffect } from "react";
import { SignUpContainer } from "./SignUpStyled";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import close from "./../../utils/images/close_circle.svg";

import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { utils } from "@hassanmojab/react-modern-calendar-datepicker";
import Select, { components } from "react-select";
import { getAge } from "../../services/dateServices";
import Alert from "../../components/Alert/Alert";
import Input from "../../components/Input/Input";

import ModalSecurityCode from "../../components/ModalSecurityCode/ModalSecurityCode";
import Stepper from "../../components/Stepper/Stepper";
import Checkbox from "../../components/Checkbox/Checkbox";
import { LangContext } from "../../context/langContext";
import { useContext } from "react";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserTypes } from "../../utils/constants/user";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CompanyAPI } from "../../api/user/company/company";

export default function SignUp() {
  const [activeUser, setActiveUser] = useState(UserTypes.INFLUENCER);
  const intl = useIntl();
  const navigate = useNavigate();

  //Influencer fields
  const [influencerName, setInfluencerName] = useState(sessionStorage.getItem("InfluencerName") || "");
  const [validInfluencerName, setValidInfluencerName] = useState(false);

  const [influencerLastName, setInfluencerLastName] = useState(sessionStorage.getItem("InfluencerLastName") || "");
  const [validInfluencerLastName, setValidInfluencerLastName] = useState(false);

  const [showLegalName, setShowLegalName] = useState(false);
  const [legalName, setLegalName] = useState("");
  const [validLegalName, setValidLegalName] = useState(false);

  const [email, setEmail] = useState(sessionStorage.getItem("Email") || "");
  const [validEmail, setValidEmail] = useState(false);

  const [repetEmail, setRepetEmail] = useState(sessionStorage.getItem("RepetEmail") || "");
  const [validRepetEmail, setValidRepetEmail] = useState(false);

  const [password, setPassword] = useState(sessionStorage.getItem("Password") || "");
  const [validPassword, setValidPassword] = useState(false);

  const [mailingAdress, setMailingAdress] = useState(sessionStorage.getItem("MailingAdress") || "");
  const [validMailingAdress, setValidMailingAdress] = useState(false);

  const [city, setCity] = useState(sessionStorage.getItem("City") || "");
  const [validCity, setValidCity] = useState(false);

  const [country, setCountry] = useState(sessionStorage.getItem("Country") || "");
  const [validCountry, setValidCountry] = useState(false);

  const [state, setState] = useState(sessionStorage.getItem("State") || "");
  const [validState, setValidState] = useState(false);

  const [postalCode, setPostalCode] = useState(sessionStorage.getItem("PostalCode") || "");
  const [validPostalCode, setValidPostalCode] = useState(false);

  const [influencerPhone, setInfluencerPhone] = useState("");
  const [influencerIndicativePhone, setInfluencerIndicativePhone] = useState("");
  const [validInfluencerPhone, setValidInfluencerPhone] = useState(false);

  const [typeInfluencerId, setTypeInfluencerId] = useState(sessionStorage.getItem("TypeInfluencerId") || "");
  const [influencerId, setInfluencerId] = useState(sessionStorage.getItem("InfluencerId") || "");
  const [validInfluencerId, setValidInfluencerId] = useState(false);

  const [typeInfluencerLanguage, setTypeInfluencerLanguage] = useState(sessionStorage.getItem("TypeInfluencerLanguage") || "");

  const [selectedDay, setSelectedDay] = useState(JSON.parse(sessionStorage.getItem("SelectedDay")) || null);
  const [isUnderAge, setIsUnderAge] = useState(false);

  const [gender, setGender] = useState(sessionStorage.getItem("Gender") || "");
  const [otherGender, setOtherGender] = useState("");

  const [validGender, setValidGender] = useState("");
  const [showCustomPronoun, setShowCustomPronoun] = useState(sessionStorage.getItem("Gender") === "other" || false);

  //Legal representative fields
  const [legalRepresentativeName, setLegalRepresentativeName] = useState("");
  const [validLegalRepresentativeName, setValidLegalRepresentativeName] = useState(false);

  const [legalRepresentativeLastName, setLegalRepresentativeLastName] = useState("");
  const [validLegalRepresentativeLastName, setValidLegalRepresentativeLastName] = useState(false);

  const [legalRepresentativeEmail, setLegalRepresentativeEmail] = useState("");
  const [validLegalRepresentativeEmail, setValidLegalRepresentativeEmail] = useState(false);

  const [legalRepresentativePhone, setLegalRepresentativePhone] = useState("");
  const [legalRepresentativeIndicativePhone, setLegalRepresentativeIndicativePhone] = useState("");
  const [validLegalRepresentativePhone, setValidLegalRepresentativePhone] = useState(false);

  const [legalRepresentativeIdType, setLegalRepresentativeIdType] = useState(sessionStorage.getItem("LegalRepresentativeIdType"));
  const [legalRepresentativeId, setLegalRepresentativeId] = useState(sessionStorage.getItem("LegalRepresentativeId") ?? "");
  const [validLegalRepresentativeId, setValidLegalRepresentativeId] = useState(false);

  const [relationship, setRelationship] = useState("");

  const [affirmation, setAffirmation] = useState("");
  
  //Company fields
   const [companyName, setCompanyName] = useState("");
  const [validCompanyName, setValidCompanyName] = useState(false);

  const [showLegalNameCompany, setShowLegalNameCompany] = useState(false);
  const [legalNameCompany, setLegalNameCompany] = useState("");
  const [validLegalNameCompany, setValidLegalNameCompany] = useState(false);

  const [companyEmail, setCompanyEmail] = useState("");
  const [validCompanyEmail, setValidCompanyEmail] = useState(false);

  const [companyRepetEmail, setCompanyRepetEmail] = useState("");
  const [validCompanyRepetEmail, setValidCompanyRepetEmail] = useState(false);

  const [companyPassword, setCompanyPassword] = useState(sessionStorage.getItem("CompanyPassword") || "");
  const [validCompanyPassword, setValidCompanyPassword] = useState(false);
  
  const [billingAdressCompany, setBillingAdressCompany] = useState(sessionStorage.getItem("BillingAdressCompany") || "");
  const [validBillingAdressCompany, setValidBillingAdressCompany] = useState(false);
  
  const [cityCompany, setCityCompany] = useState(sessionStorage.getItem("CityCompany") || "");
  const [validCityCompany, setValidCityCompany] = useState(false);
  
  const [countryCompany, setCountryCompany] = useState(sessionStorage.getItem("CountryCompany") || "");
  const [validCountryCompany, setValidCountryCompany] = useState(false);
  
  const [stateCompany, setStateCompany] = useState(sessionStorage.getItem("StateCompany") || "");
  const [validStateCompany, setValidStateCompany] = useState(false);
  
  const [postalCodeCompany, setPostalCodeCompany] = useState(sessionStorage.getItem("PostalCodeCompany") || "");
  const [validPostalCodeCompany, setValidPostalCodeCompany] = useState(false);

  const [typeCompanyId, setTypeCompanyId] = useState(sessionStorage.getItem("TypeCompanyId") || null);
  const [validTypeCompanyId, setValidTypeCompanyId] = useState();

  const [typeCompanyLanguage, setTypeCompanyLanguage] = useState(sessionStorage.getItem("TypeCompanyLanguage") || "");

  const [companyId, setCompanyId] = useState(sessionStorage.getItem("CompanyId") || "");
  const [validCompanyId, setValidCompanyId] = useState(false);
  
  
  //Commons fields - data
  const { countryList, identificationTypeList, languageList, genderList } = useContext(StaticDataContext);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [indicativesOptions, setIndicativesOptions] = useState([]);
  const [identificationTypesInfluencer, setIdentificationTypesInfluencer] = useState([]);
  const [identificationTypesCompany, setIdentificationTypesCompany] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);

  const [policyChecked, setPolicyChecked] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [body, setBody] = useState({});
  const [showEmptyFieldsMessage, setShowEmptyFieldsMessage] = useState(false);
  const [showErrorPasswordMessage, setShowErrorPasswordMessage] = useState(false);
  const [showErrorCompanyPasswordMessage, setShowErrorCompanyPasswordMessage] = useState(false);
  const { locale } = useContext(LangContext);
  const { Option } = components;

  useEffect(() => {
      const auxCountries = countryList
        .sort((a, b) => 
          (locale === "es-CO") ? 
            a.nombre.localeCompare(b.nombre) : 
            a.name.localeCompare(b.name)
        )
        .map((country) => {
          return {
            value: country.id,
            label: (locale === "es-CO") ? country.nombre : country.name,
            nombre: country.nombre,
            name: country.name,
            flag: country.image,
            indicative: country.indicative
          };
        });

      setCountriesOptions(auxCountries);

      setIndicativesOptions(auxCountries.map(country => 
        { 
          return {
              label: country.indicative,
              value: country.indicative,
              flag: country.flag
          }
        }
      ));
  }, [countryList]);

  useEffect(() => {
    setIdentificationTypesCompany(
      identificationTypeList
        .filter(type => type.for_company)
        .map(type => {
          return {
            value: type.id,
            label: (locale === "es-CO") ? type.nombre : type.name
          }
        })
    );

    setIdentificationTypesInfluencer(
      identificationTypeList
        .map(type => {
          return {
            value: type.id,
            label: (locale === "es-CO") ? type.nombre : type.name
          }
        })
    );
  }, [identificationTypeList]);

  useEffect(() => {
    setLanguagesOptions(
      languageList
        .filter(language => language.active && language.is_translatable)
        .map(language => {
          return {
            value: language.id,
            label: (locale === "es-CO") ? language.nombre : language.name
          }
        })
    )
  }, [languageList]);

  const handleCheckboxChange = () => {
    setShowLegalName(!showLegalName);
  };

  const handleCheckboxChangeCompany = () => {
    setShowLegalNameCompany(!showLegalNameCompany);
  }

  const handleInputChange = (event) => {
    setOtherGender(event.target.value);
    sessionStorage.setItem("OtherGender", event.target.value);
  };
  
  const [showAlertSuccessful, setShowAlertSuccessful] = useState(false);
  const [showAlertUserExists, setShowAlertUserExists] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepCompany, setCurrentStepCompany] = useState(0);
  const totalStepsInfluencer = 4;
  const totalStepsCompany = 4;

  useEffect(() => {
    setShowEmptyFieldsMessage(false);

    const validateInfluencerFields = () => {
      setValidInfluencerId(!!influencerId);
      setValidInfluencerName(!!influencerName);
      setValidInfluencerLastName(!!influencerLastName);
      setValidMailingAdress(!!mailingAdress);
      setValidCity(!!city);
      setValidCountry(!!country);
      setValidState(!!state);
      setValidPostalCode(!!postalCode);
      setValidInfluencerPhone(!!influencerPhone);
      setValidGender(!!gender);
      setValidLegalRepresentativeName(!!legalRepresentativeName);
      setValidLegalRepresentativeLastName(!!legalRepresentativeLastName);
      setValidLegalRepresentativeId(!!legalRepresentativeId);
      setValidLegalRepresentativePhone(legalRepresentativePhone.length > 8);
    };

    validateInfluencerFields();

    let newStep = currentStep;

    if (!validInfluencerName || !validInfluencerLastName || !validEmail || !validRepetEmail || !validPassword) {
      newStep = 0; // Retrocede al primer paso
    } else if (!validMailingAdress || !validCity || !validState || !validPostalCode || !validInfluencerPhone) {
      newStep = 1; // Retrocede al segundo paso
    } else if (!selectedDay || !validGender) {
      newStep = 2; // Retrocede al tercer paso
    } else if (!policyChecked || !validInfluencerId || !typeInfluencerLanguage) {
      newStep = 3; // Retrocede al cuarto paso
    }

    if (newStep !== currentStep) {
      setCurrentStep(newStep);
    }

  }, [
    influencerName,
    validInfluencerName,
    mailingAdress,
    influencerId,
    validInfluencerId,
    influencerLastName,
    city,
    country,
    state,
    postalCode,
    influencerPhone,
    gender,
    validGender,
    selectedDay,
    typeInfluencerLanguage,
    validCity,
    validState,
    validInfluencerLastName,
    validMailingAdress,
    legalRepresentativeName,
    legalRepresentativeLastName,
    legalRepresentativeId,
    legalRepresentativePhone,
    policyChecked,
    validEmail,
    validRepetEmail,
    validPassword,
    currentStep,
  ]);

  useEffect(() => {
    setShowEmptyFieldsMessage(false);

    const validateCompanyFields = () => {
      setValidCompanyId(!!companyId);
      setValidTypeCompanyId(!!typeCompanyId);
      setValidCompanyName(!!companyName);
      setValidBillingAdressCompany(!!billingAdressCompany);
      setValidCityCompany(!!cityCompany);
      setValidStateCompany(!!stateCompany);
      setValidCountryCompany(!!countryCompany);
      setValidPostalCodeCompany(!!postalCodeCompany);
      setValidCompanyEmail(!!companyEmail);
      setValidCompanyRepetEmail(!!companyRepetEmail);
    };

    validateCompanyFields();

    let newStep = currentStepCompany;

    if (!validCompanyName || !validCompanyEmail || !validCompanyRepetEmail) {
      newStep = 0; // Retrocede al primer paso
    } else if (!validCompanyPassword) {
      newStep = 1; // Retrocede al segundo paso
    } else if (!validBillingAdressCompany || !validCityCompany || !validPostalCodeCompany) {
      newStep = 2; // Retrocede al tercer paso
    } else if (!policyChecked || !validTypeCompanyId || !validCompanyId || !typeCompanyLanguage) {
      newStep = 3; // Retrocede al cuarto paso
    }

    // Actualiza el paso solo si ha cambiado
    if (newStep !== currentStepCompany) {
      setCurrentStepCompany(newStep);
    }
  }, [
    billingAdressCompany,
    companyId,
    typeCompanyId,
    validTypeCompanyId,
    validCompanyPassword,
    companyEmail,
    validCompanyEmail,
    companyRepetEmail,
    validCompanyRepetEmail,
    validCompanyId,
    validBillingAdressCompany,
    cityCompany,
    countryCompany,
    stateCompany,
    postalCodeCompany,
    validPostalCodeCompany,
    typeCompanyLanguage,
    validCityCompany,
    validStateCompany,
    companyName,
    validCompanyName,
    policyChecked,
    currentStepCompany,
  ]);

  useEffect(() => {
    setShowEmptyFieldsMessage(false);
    const expRegEmail = new RegExp("[a-z0-9]+@[a-z]+[.][a-z]{2,3}");
    if (expRegEmail.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    if (expRegEmail.test(companyEmail)) {
      setValidCompanyEmail(true);
    } else {
      setValidCompanyEmail(false);
    }
    if (expRegEmail.test(legalRepresentativeEmail)) {
      setValidLegalRepresentativeEmail(true);
    } else {
      setValidLegalRepresentativeEmail(false);
    }
  }, [email, companyEmail, legalRepresentativeEmail]);

  useEffect(() => {
    setShowEmptyFieldsMessage(false);
    if (validEmail) {
      if (email === repetEmail) {
        setValidRepetEmail(true);
      } else {
        setValidRepetEmail(false);
      }
    }
    if (validCompanyEmail) {
      if (companyEmail === companyRepetEmail) {
        setValidCompanyRepetEmail(true);
      } else {
        setValidCompanyRepetEmail(false);
      }
    }
  }, [validEmail, repetEmail, validCompanyEmail, companyRepetEmail, email, companyEmail]);

  useEffect(() => {
    setShowEmptyFieldsMessage(false);
    const expRegPassword = new RegExp(
      "^(?=.*?[A-ZÑÁÉÍÓÚ])(?=.*?[a-zñáéíóú])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$"
    );
  
    if (expRegPassword.test(password)) {
      setValidPassword(true);
      setShowErrorPasswordMessage(false);
    } else {
      setValidPassword(false);
    }
    if (expRegPassword.test(companyPassword)) {
      setValidCompanyPassword(true);
      setShowErrorCompanyPasswordMessage(false);
    } else {
      setValidCompanyPassword(false);
    }
  }, [password, companyPassword]);

  const validateUnderAge = () => {
    const date = `${selectedDay?.year}/${selectedDay?.month}/${selectedDay?.day}`;
    const age = getAge(date);
    if (age < 18) {
      setIsUnderAge(true);
      return true;
    } else {
      setIsUnderAge(false);
      return false;
    }
  };

  const registerInfluencer = () => {
    if (!validPassword) {
      setShowErrorPasswordMessage(true);
    } else {
      setShowErrorPasswordMessage(false);
    }
    if (
      validInfluencerName &&
      validInfluencerLastName &&
      validInfluencerId &&
      city &&
      country &&
      state &&
      postalCode &&
      validInfluencerPhone &&
      validEmail &&
      validRepetEmail &&
      validPassword &&
      mailingAdress &&
      influencerId?.length > 0 &&
      selectedDay !== null &&
      gender?.length > 0 &&
      validGender &&
      policyChecked &&
      typeInfluencerLanguage &&
      (!showLegalName || (showLegalName && legalName))
    ) {
      const isUnderAge = validateUnderAge();
      if (!isUnderAge) {
        const influencerBody2 = {
          mail: email.toLowerCase(),
          password: password,
          gender: gender,
          idCountry: country,
          city,
          state,
          postalCode,
          address: mailingAdress,
          indicative: influencerIndicativePhone.toString(),
          phoneNumber: influencerPhone,
          birthday_: `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month
            }-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`,
          givenName: influencerName.trim(),
          familyName: influencerLastName.trim(),
          legalName: showLegalName ? legalName : undefined,
          attributes: {
            typeUser: UserTypes.INFLUENCER,
            typeId: typeInfluencerId.toString(),
            primarylanguage: typeInfluencerLanguage,
            numberId: influencerId,
            active: true,
            status: "ACTIVO",
            verified: false,
            twoStepVerification: false,
            paypal: false,
          },
          notification: {
            colaborations: false,
            promotions: true,
          },
        };

        const influencerBody = {
          "email": email.toLowerCase(),
          "password": password,
          "user_type_id": 2,
          "country_id": country,
          "city": city,
          "state": state,
          "postal_code": postalCode,
          "address": mailingAdress,
          "name": influencerName.trim(),
          "legal_name": showLegalName ? legalName.trim() : influencerName.trim(),
          "family_name": influencerLastName.trim(),
          "identification_type_id": typeInfluencerId,
          "identification_number": influencerPhone,
          "primary_language_id": typeInfluencerLanguage, 
          "gender_id": gender,
          "birthday":`${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.year}`,
          "indicative": influencerIndicativePhone,
          "phone_number": influencerPhone,
          "legal_representative": null
        }

        setBody(influencerBody);
        setShowSecurityCode(true);
      }
    } else {
      setShowEmptyFieldsMessage(true);
    }
  };

  const registerUnderAgeInfluencer = () => {
    if (!validPassword) {
      setShowErrorPasswordMessage(true);
    } else {
      setShowErrorPasswordMessage(false);
    }
    if (isUnderAge) {
      if (
        validInfluencerName &&
        validInfluencerLastName &&
        (!showLegalName || (showLegalName && legalName)) &&
        validInfluencerId &&
        validEmail &&
        validRepetEmail &&
        validPassword &&
        validMailingAdress &&
        validCity &&
        validCountry &&
        validState &&
        validPostalCode &&
        influencerId.length > 0 &&
        selectedDay !== null &&
        gender.length > 0 &&
        validGender &&
        validLegalRepresentativeName &&
        validLegalRepresentativeLastName &&
        validLegalRepresentativeEmail &&
        validLegalRepresentativeId &&
        validLegalRepresentativePhone &&
        affirmation &&
        relationship.length > 0 &&
        typeInfluencerLanguage
      ) {
        const influencerBody2 = {
          mail: email.toLowerCase(),
          password: password,
          gender: gender,
          idCountry: country,
          city,
          state,
          postalCode,
          address: mailingAdress,
          indicative: influencerIndicativePhone.toString(),
          phoneNumber: influencerPhone,
          legalName: showLegalName ? legalName : undefined,
          birthday_: `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month
            }-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`,
          givenName: influencerName.trim(),
          familyName: influencerLastName.trim(),
          attributes: {
            typeUser: UserTypes.INFLUENCER,
            typeId: typeInfluencerId.toString(),
            primarylanguage: typeInfluencerLanguage,
            numberId: influencerId,
            active: true,
            verified: false,
            twoStepVerification: false,
            paypal: false,
          },
          notification: {
            colaborations: false,
            promotions: true,
          },
          givenNameL: legalRepresentativeName.trim(),
          familyNameL: legalRepresentativeLastName.trim(),
          mailL: legalRepresentativeEmail.toLowerCase(),
          phoneNumberL: legalRepresentativePhone,
          indicativeL: legalRepresentativeIndicativePhone.toString(),
          relationship: relationship,
          legalrepresentativeidtype: legalRepresentativeIdType,
          legalrepresentativeid: legalRepresentativeId,
          legalrepresentativeaffirmation: affirmation
        };

        const influencerBody = {
          "email": email.toLowerCase(),
          "password": password,
          "user_type_id": 2,
          "country_id": country,
          "city": city,
          "state": state,
          "postal_code": postalCode,
          "address": mailingAdress,
          "name": influencerName.trim(),
          "legal_name": showLegalName ? legalName.trim() : influencerName.trim(),
          "family_name": influencerLastName.trim(),
          "identification_type_id": typeInfluencerId,
          "identification_number": influencerPhone,
          "primary_language_id": typeInfluencerLanguage, 
          "gender_id": gender,
          "birthday":`${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.year}`,
          "indicative": influencerIndicativePhone,
          "phone_number": influencerPhone,
          "legal_representative":{
            "name": legalRepresentativeName.trim(),
            "family_name": legalRepresentativeLastName.trim(),
            "email": legalRepresentativeEmail.toLowerCase(),
            "indicative": legalRepresentativeIndicativePhone.toString(),
            "phone_number": legalRepresentativePhone,
            "relationship": relationship,
            "affirmation": affirmation,
            "identification_type_id": legalRepresentativeIdType,
            "identification_number": legalRepresentativeId
          }
        }
        
        setBody(influencerBody);
        setShowSecurityCode(true);
      } else {
        setShowEmptyFieldsMessage(true);
      }
    }
  };

  const registerCompany = () => {
    if (!validCompanyPassword) {
      setShowErrorCompanyPasswordMessage(true);
    } else {
      setShowErrorCompanyPasswordMessage(false);
    }
    if (
      validCompanyEmail &&
      validBillingAdressCompany &&
      validPostalCodeCompany &&
      validCityCompany &&
      validStateCompany &&
      validCountryCompany &&
      validCompanyRepetEmail &&
      validCompanyPassword &&
      validCompanyName &&
      validCompanyId &&
      validTypeCompanyId &&
      policyChecked &&
      typeCompanyLanguage &&
      (!showLegalNameCompany || (showLegalNameCompany && legalNameCompany))
    ) {
      const companyBody2 = {
        mail: companyEmail.toLowerCase(),
        password: companyPassword,
        nameCompany: companyName.trim(),
        idCountry: countryCompany,
        city: cityCompany,
        state: stateCompany,
        postalCode: postalCodeCompany,
        address: billingAdressCompany,
        legalName: showLegalNameCompany ? legalNameCompany : companyName.trim(),
        attributes: {
          typeId: typeCompanyId.toString(),
          numberId: companyId,
          typeUser: UserTypes.COMPANY,
          active: true,
          primarylanguage: typeCompanyLanguage,
          verified: false,
          twoStepVerification: false,
          paypal: false,
        },
        notification: {
          colaborations: false,
          promotions: true,
        },
      };

      const companyBody = {
        "email": companyEmail.toLowerCase(),
        "password": companyPassword,
        "user_type_id": 3,
        "country_id": countryCompany,
        "city": cityCompany,
        "state": stateCompany,
        "postal_code": postalCodeCompany,
        "address": billingAdressCompany,
        "name": companyName.trim(),
        "legal_name": showLegalNameCompany ? legalNameCompany : companyName.trim(),
        "identification_type_id": typeCompanyId,
        "identification_number": companyId,
        "primary_language_id": typeCompanyLanguage,
      }
      
      setBody(companyBody);
      setShowSecurityCode(true);
    } else {
      setShowEmptyFieldsMessage(true);
    }
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder={intl.formatMessage({ id: "signup_datepicker" })}
      value={
        selectedDay
          ? `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`
          : ""
      }
      style={{
        padding: "9px",
        border: "1px solid #BCBCBC",
        borderRadius: "10px",
        width: "100%",
        outline: "none",
        fontFamily: "Open Sans"
      }}
      className="inputPicker"
    />
  );

  const changeUser = () => {
    if (activeUser === UserTypes.INFLUENCER) {
      setActiveUser(UserTypes.COMPANY);
    } else {
      setActiveUser(UserTypes.INFLUENCER);
    }
  };

  /**REVISAR */
  const selectIndentificationStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      boxShadow: "none",
      height: "46px",
      minHeight: "46px",
      flexWrap: "no-wrap",
      minWidth: "240px",
      "@media(max-width: 1540px)": {
        minWidth: "220px",
        height: "46px"
      },
      "@media(max-width: 1366px)": {
        minWidth: "200px",
        height: "46px"
      },
      "@media(max-width: 620px)": {
        minWidth: "180px",
        height: "46px"
      },
      "@media(max-width: 370px)": {
        minWidth: "170px",
        height: "46px"
      }
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1rem",
      fontWeight: "bold",
      color: "rgba(112,112,112)",
      opacity: "0.5"
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "1rem"
    }),
    menu: (base) => ({ ...base }),
    menuList: (styles) => ({
      ...styles,
      fontSize: "1rem",
      color: "rgba(112,112,112,0.51)"
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#E8339E",
      "@media(max-width: 620px)": {
        padding: "4px"
      }
    }),
    valueContainer: (styles) => ({
      ...styles,
      "@media(max-width: 370px)": {
        paddingRight: "0px"
      }
    })
  };

  const selectIndentificationStylesId = {
    ...selectIndentificationStyles,
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1rem",
      fontWeight: "bold",
      color: "rgba(112,112,112)",
      opacity: "0.5"
    }),
  };

  const IconPhoneOption = (props) => {
    return (
      <Option {...props}>
        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
        <img
          src={props.data.flag}
          style={{ width: 20, marginRight: 8 }}
          alt={props.data.label}
        />
        <span style={{fontSize: '1em'}}>+{props.data.label}</span>
        </div>
        
      </Option>
    );
  };

  return (
    <SignUpContainer>
      <form action="submit" autoComplete="off">
        <div className="header">
          {isUnderAge ? (
            <h2 className="title">
              <FormattedMessage
                id="underAge_modal_title"
                defaultMessage="Por ser menor de edad"
              />
            </h2>
          ) : (
            <h2 className="title">
              <FormattedMessage id="signup_title" defaultMessage="Regístrate" />
            </h2>
          )}

          <Link to="/">
            <img src={close} alt="close" className="close" />
          </Link>
        </div>
        {isUnderAge ? (
          <p className="relationshipTitle">
            <FormattedMessage
              id="underAge_modal_text_1"
              defaultMessage="Debemos solicitar datos extra"
            />
          </p>
        ) : (
          <p className="message">
            <FormattedMessage
              id="signup_text_1"
              defaultMessage="Es rápido y sencillo"
            />
          </p>
        )}
        <div className="containerGrid col-2">
          <button
            type="button"
            className={`generalButton ${activeUser === UserTypes.INFLUENCER && "active"
              }`}
            onClick={changeUser}
          >
            <FormattedMessage
              id="signup_button_1"
              defaultMessage="Influencer"
            />
          </button>
          <button
            type="button"
            className={`generalButton ${activeUser === UserTypes.COMPANY && "active"}`}
            onClick={changeUser}
          >
            <FormattedMessage id="signup_button_2" defaultMessage="Empresa" />
          </button>
          {activeUser === UserTypes.INFLUENCER ? (
            <>
              {isUnderAge ? (
                <>
                  <p className="genderTitle span-2">
                    <FormattedMessage
                      id="underAge_modal_text_2"
                      defaultMessage="Nombre de un representante legal"
                    />
                  </p>

                  {/* <div className="name"> */}
                  <Input
                    type="text"
                    isValid={validLegalRepresentativeName}
                    placeholder={intl.formatMessage({ id: "signup_input_1" })}
                    onChange={(event) => {
                      setLegalRepresentativeName(event.target.value);
                      sessionStorage.setItem("LegalRepresentativeName", event.target.value);
                    }}
                    value={legalRepresentativeName}
                  />

                  <Input
                    type="text"
                    isValid={validLegalRepresentativeLastName}
                    placeholder={intl.formatMessage({ id: "signup_input_2" })}
                    onChange={(event) => {
                      setLegalRepresentativeLastName(event.target.value);
                      sessionStorage.setItem("LegalRepresentativeLastName", event.target.value);
                    }}
                    value={legalRepresentativeLastName}
                  />
                  {/* </div> */}

                  <Input
                    type="email"
                    classNameContainer="span-2"
                    isValid={validLegalRepresentativeEmail}
                    placeholder={intl.formatMessage({ id: "signup_input_4" })}
                    onChange={(event) => {
                      setLegalRepresentativeEmail(event.target.value);
                      sessionStorage.setItem("LegalRepresentativeEmail", event.target.value);
                    }}
                    value={legalRepresentativeEmail}
                  />

                  <>
                    <Select options={identificationTypesInfluencer} styles={selectIndentificationStyles}
                      placeholder={intl.formatMessage({
                        id: "myAccount_text_6"
                      })}
                      onChange={(event) => {
                        setLegalRepresentativeIdType(event.value);
                        sessionStorage.setItem("LegalRepresentativeIdType", event.value);
                      }}
                      value={identificationTypesInfluencer.filter(c => c.value === +sessionStorage.getItem("LegalRepresentativeIdType"))}
                    />
                    <Input type="text" isValid={validLegalRepresentativeId}
                      placeholder={intl.formatMessage({
                        id: "signup_input_7"
                      })}
                      onChange={(event) => {
                        setLegalRepresentativeId(event.target.value);
                        sessionStorage.setItem("LegalRepresentativeId", event.target.value);
                      }}
                      value={legalRepresentativeId}
                    />
                  </>

                  {/* <div className="phone"> */}
                  <Select
                    options={indicativesOptions}
                    styles={selectIndentificationStyles}
                    placeholder={intl.formatMessage({ id: "myAccount_text_5" })}
                    // defaultValue={indicativesOptions[0]}
                    value={indicativesOptions.filter(c => c.value === +sessionStorage.getItem("LegalRepresentativeIndicativePhone"))}
                    onChange={(event) => {
                      setLegalRepresentativeIndicativePhone(event.value);
                      sessionStorage.setItem("LegalRepresentativeIndicativePhone", event.value);
                    }}
                    components={{ Option: IconPhoneOption }}
                  // defaultInputValue={legalRepresentativeIndicativePhone}
                  />
                  <Input
                    type="number"
                    isValid={validLegalRepresentativePhone}
                    placeholder={intl.formatMessage({
                      id: "underAge_modal_input_4"
                    })}
                    onChange={(event) => {
                      setLegalRepresentativePhone(event.target.value);
                      sessionStorage.setItem("LegalRepresentativePhone", event.target.value);
                    }}
                    value={legalRepresentativePhone}
                  />
                  {/* </div> */}

                  <p className="relationshipTitle">
                    <FormattedMessage
                      id="underAge_modal_text_3"
                      defaultMessage="Parentesco"
                    />
                  </p>

                  <div className="relationships span-2">
                    <div className="relationship">
                      <label htmlFor="mother">
                        <FormattedMessage
                          id="underAge_modal_text_3_subtext_1"
                          defaultMessage="Madre"
                        />
                      </label>
                      <input
                        type="radio"
                        id="mother"
                        name="relationship"
                        value="mother"
                        onClick={(e) => {
                          setRelationship(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relationship">
                      <label htmlFor="father">
                        <FormattedMessage
                          id="underAge_modal_text_3_subtext_2"
                          defaultMessage="Padre"
                        />
                      </label>
                      <input
                        type="radio"
                        id="male"
                        name="relationship"
                        value="father"
                        onClick={(e) => {
                          setRelationship(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relationship">
                      <label htmlFor="other">
                        <FormattedMessage
                          id="underAge_modal_text_3_subtext_3"
                          defaultMessage="Otro"
                        />
                      </label>
                      <input
                        type="radio"
                        id="other"
                        name="relationship"
                        value="other"
                        onClick={(e) => {
                          setRelationship(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <Input
                    type="text"
                    classNameContainer="span-2"
                    isValid={!!affirmation}
                    placeholder={intl.formatMessage({
                      id: "underAge_modal_affirmation"
                    })}
                    onChange={(event) => {
                      setAffirmation(event.target.value);
                    }}
                    value={affirmation}
                  />
                </>
              ) : (
                <>
                  <Stepper className="span-2" steps={totalStepsInfluencer} currentStep={currentStep} style={{ position: 'sticky', top: -22, backgroundColor: '#fff', marginBottom: 1, paddingTop: 10, marginTop: -5 }} />
                  <div className="span-2">
                    <div className="containerFlex">
                      <p className="labelTitle">
                        <FormattedMessage id="display_name_1" defaultMessage="Nombre visible" />
                      </p>
                      <div className="flex-end">
                        <Checkbox label={<FormattedMessage id="display_name" />} defaultChecked onChange={handleCheckboxChange} />
                      </div>
                    </div>
                    <div className="containerGrid col-2">
                      <Input type="text" placeholder={intl.formatMessage({ id: "signup_input_1" })}
                        onChange={(event) => {
                          setInfluencerName(event.target.value);
                          sessionStorage.setItem("InfluencerName", event.target.value);
                        }}
                        value={influencerName}
                        isValid={validInfluencerName}
                      />
                      <Input type="text" placeholder={intl.formatMessage({ id: "signup_input_2" })}
                        onChange={(event) => {
                          setInfluencerLastName(event.target.value);
                          sessionStorage.setItem("InfluencerLastName", event.target.value);
                        }}
                        value={influencerLastName}
                        isValid={validInfluencerLastName}
                      />
                    </div>
                  </div>
                  {showLegalName &&
                    <Input classNameContainer="span-2" type="text" placeholder={intl.formatMessage({ id: "signup_input_3" })}
                      onChange={(event) => {
                        setLegalName(event.target.value);
                        sessionStorage.setItem("LegalName", event.target.value);
                        setValidLegalName(!!event.target.value);
                      }}
                      value={legalName}
                      isValid={validLegalName}
                    />
                  }
                  <Input classNameContainer="span-2" type="email" placeholder={intl.formatMessage({ id: "signup_input_4" })}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      sessionStorage.setItem("Email", event.target.value);
                    }}
                    autoComplete="off"
                    value={email}
                    isValid={validEmail}
                  />

                  {validEmail && (
                    <Input autoComplete="off" classNameContainer="span-2" type="email" placeholder={intl.formatMessage({ id: "signup_input_5" })}
                      onChange={(event) => {
                        setRepetEmail(event.target.value);
                        sessionStorage.setItem("RepetEmail", event.target.value);
                      }}
                      value={repetEmail}
                      isValid={validRepetEmail}
                    />
                  )}

                  <Input
                    type="password"
                    autoComplete="off"
                    classNameContainer="span-2"
                    isValid={validPassword}
                    placeholder={intl.formatMessage({ id: "signin_input_2" })}
                    onChange={(event) => {
                      setPassword(event.target.value);
                      sessionStorage.setItem("Password", event.target.value);
                    }}
                    value={password}
                  />

                  {showErrorPasswordMessage && (
                    <p className="error span-2">
                      {" "}
                      <FormattedMessage
                        id="sign_up_error_password_message"
                        defaultMessage="La contraseña debe contener al menos una mayúscula, una minúscula, un número y tener entre 8 y 10 caracteres"
                      />{" "}
                    </p>
                  )}

                  {validInfluencerName && validInfluencerLastName && validEmail && validRepetEmail && validPassword && (
                    <>
                      <Input type="text" classNameContainer="span-2" placeholder={intl.formatMessage({ id: "mailing_adress" })}
                        onChange={(event) => {
                          setMailingAdress(event.target.value);
                          sessionStorage.setItem("MailingAdress", event.target.value);
                        }}
                        value={mailingAdress}
                        isValid={validMailingAdress}
                      />
                      <Input isValid={validCity} type="text" placeholder={intl.formatMessage({ id: "city_adress" })}
                        onChange={(event) => {
                          setCity(event.target.value);
                          sessionStorage.setItem("City", event.target.value);
                        }}
                        value={city}
                      />

                      <Select classNamePrefix="countrySelect" options={countriesOptions}
                        styles={selectIndentificationStyles}
                        placeholder={intl.formatMessage({ id: "filter_title_3" })}
                        value={countriesOptions.filter(c => c.value === +sessionStorage.getItem("Country"))}
                        onChange={(event) => {
                          setCountry(event.value);
                          sessionStorage.setItem("Country", event.value);
                        }}
                      />
                      <Input type="text"
                        placeholder={intl.formatMessage({ id: "address_state" })}
                        onChange={(event) => {
                          setState(event.target.value);
                          sessionStorage.setItem("State", event.target.value);
                        }}
                        value={state}
                        isValid={validState}
                      />
                      <Input type="text"
                        placeholder={intl.formatMessage({ id: "address_code" })}
                        onChange={(event) => {
                          setPostalCode(event.target.value);
                          sessionStorage.setItem("PostalCode", event.target.value);
                        }}
                        value={postalCode}
                        isValid={validPostalCode}
                      />

                      <Select
                        options={indicativesOptions}
                        styles={selectIndentificationStyles}
                        placeholder={intl.formatMessage({ id: "myAccount_text_5" })}
                        value={indicativesOptions.filter(c => c.value === +sessionStorage.getItem("InfluencerIndicativePhone"))}
                        onChange={(event) => {
                          setInfluencerIndicativePhone(event.value);
                          sessionStorage.setItem("InfluencerIndicativePhone", event.value);
                        }}
                        components={{ Option: IconPhoneOption }}
                      />
                      <Input
                        type="number"
                        isValid={validInfluencerPhone}
                        placeholder={intl.formatMessage({
                          id: "underAge_modal_input_4"
                        })}
                        onChange={(event) => {
                          setInfluencerPhone(event.target.value);
                          sessionStorage.setItem("InfluencerPhone", event.target.value);
                        }}
                        value={influencerPhone}
                      />
                    </>
                  )}

                  <span className="labelTitle">
                    <FormattedMessage id="signup_text_2" defaultMessage="Fecha de nacimiento" />
                  </span>
                  <div className="span-2">
                    <div className="date">
                      <DatePicker value={selectedDay} onChange={(data) => {
                        setSelectedDay(data)
                        sessionStorage.setItem("SelectedDay", JSON.stringify(data));
                      }}
                        renderInput={renderCustomInput} colorPrimary="#E8339E" maximumDate={utils().getToday()}
                        isValid={selectedDay}
                      />
                    </div>
                  </div>
                  <span className="labelTitle">
                    <FormattedMessage id="signup_text_3" defaultMessage="Género" />
                  </span>
                  <div className="span-2">
                    <div className="genders">
                      {
                        genderList.map((genderItem) => {
                          return (
                            <div className="gender" key={genderItem.id}>
                              <label htmlFor={genderItem.name}>
                                {(locale === "en-US") ? genderItem.name : genderItem.nombre}
                              </label>
                              <input type="radio" id={genderItem.name} name="gender" defaultChecked={gender == genderItem.id} value={genderItem.id}
                                onClick={(e) => {
                                  setGender(e.target.value);
                                  sessionStorage.setItem("Gender", e.target.value);
                                  setShowCustomPronoun(false);
                                }}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  {showCustomPronoun && (
                    <Input classNameContainer="span-2" type="text" placeholder={intl.formatMessage({ id: "signup_select" })}
                      value={otherGender}
                      onChange={handleInputChange}
                      isValid={otherGender.trim() !== ""}
                    />
                  )}

                  {validInfluencerName && validInfluencerLastName && validEmail && validPassword && validMailingAdress && validCity && validState && validPostalCode && selectedDay && setGender && (
                    <>
                      <Select options={identificationTypesInfluencer} styles={selectIndentificationStyles}
                        placeholder={intl.formatMessage({
                          id: "myAccount_text_6"
                        })}
                        onChange={(event) => {
                          setTypeInfluencerId(event.value);
                          sessionStorage.setItem("TypeInfluencerId", event.value);
                        }}
                        value={identificationTypesInfluencer.filter(c => c.value === +sessionStorage.getItem("TypeInfluencerId"))}
                      // defaultInputValue={typeInfluencerId}
                      />
                      <Input type="text" isValid={validInfluencerId}
                        placeholder={intl.formatMessage({
                          id: "signup_input_7"
                        })}
                        onChange={(event) => {
                          setInfluencerId(event.target.value);
                          sessionStorage.setItem("InfluencerId", event.target.value);
                        }}
                        value={influencerId}
                      />
                      <Select className="span-2" options={languagesOptions} styles={selectIndentificationStyles} isValid={selectedDay}
                        placeholder={intl.formatMessage({
                          id: "signup_input_9"
                        })}
                        onChange={(event) => {
                          setTypeInfluencerLanguage(event.value);
                          sessionStorage.setItem("TypeInfluencerLanguage", event.value);
                        }}
                        value={languagesOptions.filter(c => c.value === +sessionStorage.getItem("TypeInfluencerLanguage"))}
                      // defaultInputValue={typeInfluencerLanguage}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Stepper className="span-2" steps={totalStepsCompany} currentStep={currentStepCompany} style={{ position: 'sticky', top: -22, backgroundColor: '#fff', marginBottom: 1, paddingTop: 10, marginTop: -5 }} />
              <div className="span-2">
                <div className="containerFlex">
                  <p className="nameTitleCompany">
                    <FormattedMessage id="display_name_1" defaultMessage="Nombre visible" />
                  </p>
                  <Checkbox label={<FormattedMessage id="display_name" className="text" />} defaultChecked onChange={handleCheckboxChangeCompany} />
                </div>
                <div className="containerGrid">
                  <Input classNameContainer="span-2" type="text" placeholder={intl.formatMessage({ id: "signup_input_8" })}
                    onChange={(event) => {
                      setCompanyName(event.target.value);
                      sessionStorage.setItem("CompanyName", event.target.value);
                    }}
                    value={companyName} isValid={validCompanyName}
                  />
                </div>
              </div>

              {showLegalNameCompany &&
                <Input classNameContainer="span-2" type="text" placeholder={intl.formatMessage({ id: "signup_input_3_1" })}
                  onChange={(event) => {
                    setLegalNameCompany(event.target.value);
                    sessionStorage.setItem("LegalNameCompany", event.target.value);
                    setValidLegalNameCompany(!!event.target.value);
                  }}
                  value={legalNameCompany} isValid={validLegalNameCompany}
                />
              }

              <Input classNameContainer="span-2" type="email" placeholder={intl.formatMessage({ id: "signup_input_4" })}
                onChange={(event) => {
                  setCompanyEmail(event.target.value);
                  sessionStorage.setItem("CompanyEmail", event.target.value);
                }}
                autoComplete="off"
                value={companyEmail} isValid={validCompanyEmail}
              />

              <Input classNameContainer="span-2" type="email" placeholder={intl.formatMessage({ id: "signup_input_5" })}
                onChange={(event) => {
                  setCompanyRepetEmail(event.target.value);
                  sessionStorage.setItem("CompanyRepetEmail", event.target.value);
                }}
                value={companyRepetEmail} isValid={validCompanyRepetEmail}
              />

              <Input 
                type="password" 
                classNameContainer="span-2" 
                placeholder={intl.formatMessage({ id: "signin_input_2" })}
                onChange={(event) => {
                  setCompanyPassword(event.target.value);
                  sessionStorage.setItem("CompanyPassword", event.target.value);
                }}
                autoComplete="off"
                value={companyPassword}
                isValid={validCompanyPassword}
              />

              {showErrorCompanyPasswordMessage && (
                <p className="error span-2">
                  {" "}
                  <FormattedMessage id="sign_up_error_password_message" defaultMessage="La contraseña debe contener al menos una mayuscula a minuscula un numero y entre 8 y 16 caracteres" />{" "}
                </p>
              )}

              {validCompanyName && validCompanyEmail && validCompanyRepetEmail && validCompanyPassword && (
                <>
                  <Input classNameContainer="span-2" type="text" placeholder={intl.formatMessage({ id: "billing_adress" })}
                    onChange={(event) => {
                      setBillingAdressCompany(event.target.value);
                      sessionStorage.setItem("BillingAdressCompany", event.target.value);
                    }}
                    value={billingAdressCompany}
                    isValid={validBillingAdressCompany}
                  />
                  <Input type="text" placeholder={intl.formatMessage({ id: "city_adress" })}
                    onChange={(event) => {
                      setCityCompany(event.target.value);
                      sessionStorage.setItem("CityCompany", event.target.value);
                    }}
                    value={cityCompany}
                    isValid={validCityCompany}
                  />
                  <Select classNamePrefix="countrySelect" options={countriesOptions}
                        styles={selectIndentificationStyles}
                        placeholder={intl.formatMessage({ id: "filter_title_3" })}
                        value={countriesOptions.filter(c => c.value === +sessionStorage.getItem("countryCompany"))}
                        onChange={(event) => {
                          setCountryCompany(event.value);
                          sessionStorage.setItem("countryCompany", event.value);
                        }}
                      />
                  <Input type="text" placeholder={intl.formatMessage({ id: "address_state" })}
                    onChange={(event) => {
                      setStateCompany(event.target.value);
                      sessionStorage.setItem("StateCompany", event.target.value);
                    }}
                    value={stateCompany}
                    isValid={validStateCompany}
                  />
                  <Input type="text" placeholder={intl.formatMessage({ id: "address_code" })}
                    onChange={(event) => {
                      setPostalCodeCompany(event.target.value);
                      sessionStorage.setItem("PostalCodeCompany", event.target.value);
                    }}
                    value={postalCodeCompany}
                    isValid={validPostalCodeCompany}
                  />
                </>
              )}

              {validCompanyName && validCompanyEmail && validCompanyRepetEmail && validCompanyPassword && validBillingAdressCompany && validCityCompany && validStateCompany && validPostalCodeCompany && (
                <>
                  <Select options={identificationTypesCompany} styles={selectIndentificationStylesId}
                    placeholder={intl.formatMessage({
                      id: "myAccount_text_6_1"
                    })}
                    onChange={(event) => {
                      console.log(event);
                      setTypeCompanyId(event.value);
                      sessionStorage.setItem("TypeCompanyId", event.value);
                    }}
                    value={identificationTypesCompany.filter(c => c.value === +sessionStorage.getItem("TypeCompanyId"))}
                  />
                  <Input type="text" placeholder={intl.formatMessage({ id: "signup_input_7_1" })}
                    onChange={(event) => {
                      setCompanyId(event.target.value);
                      sessionStorage.setItem("CompanyId", event.target.value);
                    }}
                    value={companyId}
                    isValid={validCompanyId}
                  />
                  <Select className="span-2" options={languagesOptions} styles={selectIndentificationStyles} placeholder={intl.formatMessage({ id: "signup_input_9" })}
                    onChange={(event) => {
                      setTypeCompanyLanguage(event.value);
                      sessionStorage.setItem("TypeCompanyLanguage", event.value);
                    }}
                    value={languagesOptions.filter(c => c.value === +sessionStorage.getItem("TypeCompanyLanguage"))}
                  // defaultInputValue={typeCompanyLanguage}
                  />
                </>
              )}
            </>
          )}
        </div>

        {/* <p className="accept"> <FormattedMessage id="signup_text_4" defaultMessage="Al registrarte, aceptas los Términos y condiciones, las Directrices de la comunidad y la Política de privacidad de Fluincr." /></p> */}
        <Checkbox classNameContainer="terms-conditions" align="top" label={
          <>
            <span>
              <FormattedMessage
                id="signup_text_4"
                defaultMessage={`Al registrarte, aceptas los {termsLink}, las {guidelinesLink}, la {privacyLink} de Fluincr y autorizas el {dataPolicy}.`}
                values={{
                  termsLink: (
                    <Link to="/legal/terms">
                      {intl.formatMessage({ id: "terms_link_text" })}
                    </Link>
                  ),
                  guidelinesLink: (
                    <Link to="/legal/guidelines">
                      {intl.formatMessage({ id: "guidelines_link_text" })}
                    </Link>
                  ),
                  privacyLink: (
                    <Link to="/legal/policy">
                      {intl.formatMessage({ id: "privacy_link_text" })}
                    </Link>
                  ),
                  dataPolicy: (
                    <Link to="/legal/data">
                      {intl.formatMessage({ id: "data_policy_link_text" })}
                    </Link>
                  )
                }}
              />
            </span>
          </>
        } checked={policyChecked} onChange={(e) => {
          setPolicyChecked(e.target.checked);
        }} />

        <div className="registerButton">
          {isUnderAge ? (
            <>
              {showEmptyFieldsMessage && (
                <p className="error">
                  <FormattedMessage
                    id="sign_up_error_message"
                    defaultMessage="Completar todos los campos"
                  />
                </p>
              )}
              <button
                className="generalButton active"
                type="button"
                onClick={registerUnderAgeInfluencer}
              >
                <FormattedMessage
                  id="underAge_modal_button_3"
                  defaultMessage="Finalizar registro"
                />
              </button>
            </>
          ) : (
            <>
              {showEmptyFieldsMessage && (
                <p className="error">
                  <FormattedMessage
                    id="sign_up_error_message"
                    defaultMessage="Completar todos los campos"
                  />
                </p>
              )}
              <button
                className="generalButton active register"
                type="button"
                onClick={() => {
                  if (activeUser === UserTypes.INFLUENCER) {
                    registerInfluencer();
                  }
                  if (activeUser === UserTypes.COMPANY) {
                    registerCompany();
                  }
                }}
              >
                <FormattedMessage
                  id="signup_button_3"
                  defaultMessage="Regístrate"
                />
              </button>
            </>
          )}
        </div>
      </form>

      {
        showSecurityCode && (
          <ModalSecurityCode
            setShowSecurityCode={setShowSecurityCode}
            body={body}
            activeUser={activeUser}
            setShowAlertSuccessful={setShowAlertSuccessful}
            setShowAlertUserExists={setShowAlertUserExists}
            preferenceLanguage={body['primary_language_id']}
          />
        )
      }

      {
        showAlertSuccessful && (
          <Alert
            title={
              <FormattedMessage
                id="alert_register_title"
                defaultMessage="¡Felicitaciones!"
              />
            }
            text={
              <FormattedMessage
                id="alert_register_text"
                defaultMessage="Tu cuenta fue creada exitosamente, da click a continuación para iniciar sesión."
              />
            }
            confirmText={
              <FormattedMessage
                id="header_button_text"
                defaultMessage="Iniciar sessión"
              />
            }
            setShowAlert={setShowAlertSuccessful}
            onConfirm={() => {
              navigate("/signin");
            }}
          />
        )
      }

      {
        showAlertUserExists && (
          <Alert
            title={
              <FormattedMessage
                id="alert_user_exists_title"
                defaultMessage="Advertencia"
              />
            }
            text={
              <FormattedMessage
                id="alert_user_exists_text"
                defaultMessage="Este correo ya está registrado. ¿Desea ingresar o crear una nueva cuenta?"
              />
            }
            confirmText={
              <FormattedMessage
                id="header_button_text"
                defaultMessage="Iniciar sessión"
              />
            }
            onConfirm={() => {
              navigate("/signin");
            }}
            cancelText={
              <FormattedMessage
                id="banner_button_text"
                defaultMessage="Regístrate"
              />
            }
            onCancel={() => {
              setShowAlertUserExists(false);
              setIsUnderAge(false);
              navigate("/signup");
            }}
            setShowAlert={setShowAlertUserExists}
          />
        )
      }
    </SignUpContainer >
  );
}

