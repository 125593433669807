import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


export default function ProtectRoute({ children }) {

    const result = useSelector((state) => state.tokenReducer.token)
    //console.log("🎲", result)

    if (result?.token === null) {
        return <Navigate to="/signin" />
    }

    return (
        <>
            {children ? children : <Outlet />}
        </>
    )
}

//export default connect(mapStateToProps, { saveToken })(ProtectRoute);