import styled from "styled-components";

export const LikeProductContainer = styled.div`
    
    
    svg{
        cursor: pointer;
        width: 100%;
        height: 100%;
        /* .stop1{
            stop-color: rgb(255,255,255);
            opacity: 0;
        }
        .stop2{
            stop-color: rgb(255,255,255);
            stop-opacity: 1;
        } */
    }
    /* svg:hover{
        .stop1:hover{
            stop-color: rgb(255,255,255);
            opacity: 0;
        }
        .stop2:hover{
            stop-color: rgb(0,0,255);
            stop-opacity: 1;
        }
    } */
    svg:hover{
        path{
            fill:red;
        }  
        //fill:  radial-gradient(circle at 31.22% 90.28%, #0091ff 0, #008aff 3.57%, #1883ff 7.14%, #657bff 10.71%, #8c72ff 14.29%, #a968f3 17.86%, #c05de5 21.43%, #d352d6 25%, #e347c5 28.57%, #ef3cb3 32.14%, #f833a1 35.71%, #fd2d8f 39.29%, #ff2c7c 42.86%, #ff306a 46.43%, #ff3858 50%, #fb4246 53.57%, #f44d35 57.14%, #ec5721 60.71%, #e26105 64.29%, #d76b00 67.86%, #ca7300 71.43%, #bc7b00 75%, #ad8200 78.57%, #9d8900 82.14%, #8c8e00 85.71%, #799300 89.29%, #659700 92.86%, #4d9b00 96.43%, #2c9d05 100%);
        
    }
`