import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, Input, InputLabel, InputAdornment } from '@mui/material';
import { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import './ModalCompleteProfileReminderStyled.css';
import './../../Bootstrap.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserContext } from './../../context/userContext';

import checkIcon from './../../utils/images/icon_verified_black.png';
import bellIcon from './../../utils/images/bell.png';
import addIcon from './../../utils/images/add_icon_2.png';
import { handleImageUpload } from '../../services/compress';
import { getBase64 } from '../../services/generalSevices';
import ImageCrop from '../ImageCrop/ImageCrop';
import { uploadImageS3 } from '../../utils/common/s3';
import { UserTypes } from '../../utils/constants/user';
import { UserAPI } from '../../api/user/user';

//TODO: Refactorizar
const ModalCompleteProfileReminder = () => {

    const navigate = useNavigate();
    const imageInputRef = useRef();
    const intl = useIntl();

    const {
        userType,
        userToken,
        account,
        uniqueName,
        imageProfile,
        setImageProfile,
        personalFare,
        followers,
        imageProfilePending,
        setImageProfilePending,
        socialNetworkPending,
        setSocialNetworkPending,
        ratePending,
        setRatePending,
        showCompleteProfileModalReminder, 
        setShowCompleteProfileModalReminder,
        showCompleteProfileModalReminderSetted,
        setShowCompleteProfileModalReminderSetted
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showImageCrop, setShowImageCrop] = useState(false);
    const [image, setImage] = useState('');
    const [imagesToSend, setImagesToSend] = useState([]);

    const isAdmin = sessionStorage.getItem('isAdmin');

    const handleShortcutRates = () => {
        navigate(`/myprofile?complete=rates`);
        setShowCompleteProfileModalReminder(false);
    }

    const handleShortcutSocialNetwork = () => {
        navigate(`/myprofile`);
        setShowCompleteProfileModalReminder(false);
    }

    const handleClose = () => {
        setShowCompleteProfileModalReminder(false);
    };

    const pendingList = [
        {
            id: 1, 
            text: intl.formatMessage({id: "modalCompleteProfileReminderImageProfile", defaultMessage: "Añade una imagen de perfil"}), 
            pending: imageProfilePending,
            allowedUserTypes: [UserTypes.INFLUENCER, UserTypes.COMPANY],
            queryParam: 'imageProfile',
            onClick: () => imageInputRef.current.click()
        },
        {
            id: 2, 
            text: intl.formatMessage({id: "modalCompleteProfileReminderRate", defaultMessage: "Define las tarifas de tus servicios"}), 
            pending: ratePending,
            allowedUserTypes: [UserTypes.INFLUENCER],
            queryParam: 'rates',
            onClick: () => handleShortcutRates()
        },
        {
            id: 3, 
            text: intl.formatMessage({id: "modalCompleteProfileReminderSocialNetwork", defaultMessage: "Añade una red social"}), 
            pending: socialNetworkPending,
            allowedUserTypes: [UserTypes.INFLUENCER, UserTypes.COMPANY],
            queryParam: 'socialNetwork',
            onClick: () => handleShortcutSocialNetwork()
        }
    ]

    useEffect(() => {
        if (imagesToSend.length > 0) {
            setShowImageCrop(true)
        } else {
            setShowImageCrop(false)
        }
    }, [imagesToSend]);

    const saveImage = (croppedImgSrc) => {

        setIsLoading(true);

        const imageBody = {
            base64File: croppedImgSrc.split(',')[1],
            formatFile: imagesToSend[0].file.type.split('/')[1],
        }
        if (userType === UserTypes.INFLUENCER) {
            //imageBody.address = `fluincr/${uniqueName}/profile`
            imageBody.address = `fluincr/${account}/profile`
        } else {
            //imageBody.address = `company/${uniqueName}/profile`
            imageBody.address = `company/${account}/profile`
        }

        const bodyDelete = {
            url: imageProfile
        }

        uploadImageS3(imageBody.address, 'image/' + imageBody.formatFile, imagesToSend[0].file).then((fileLink) => {
            if (fileLink) {
                UserAPI.updateImageProfile(fileLink).then((response) => {
                    setImageProfile(fileLink);
                    setImagesToSend([]);
                    setImage('');
                    setIsLoading(false);
                    setShowImageCrop(false);
                    navigate('/myprofile');
                    setShowCompleteProfileModalReminder(ratePending || socialNetworkPending)
                }).catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });
            }
        })
    }

    useEffect(() => {
        if(
            userToken != null && !showCompleteProfileModalReminderSetted &&
            personalFare !== undefined && followers !== undefined && imageProfile !== undefined && !isAdmin
        ) {
            sessionStorage.setItem('reminderShowed', true);
            setShowCompleteProfileModalReminderSetted(true);
            setShowCompleteProfileModalReminder(validateReminderProfileFields());
        } else if(userToken != null && !isAdmin) {
            validateReminderProfileFields();
        }
    }, [personalFare, imageProfile, followers]);

    useEffect(() => {
        if(
            userToken != null && !showCompleteProfileModalReminderSetted && 
            userType === UserTypes.COMPANY && imageProfile !== undefined && followers !== undefined && !isAdmin
        ) {
            sessionStorage.setItem('reminderShowed', true);
            setShowCompleteProfileModalReminderSetted(true);
            setShowCompleteProfileModalReminder(validateReminderProfileFields());

        } else if(imageProfilePending && !isAdmin){
            validateReminderProfileFields();
        }
    }, [imageProfile, followers]);

    const validateReminderProfileFields = () => {
        let imageProfilePendingAux = imageProfile === '' || imageProfile === null;
        let socialNetworkPendingAux = Object.keys(followers).length === 0 || 
            Object.values(followers).every(value => value === null);
        let ratePendingAux = userType === UserTypes.INFLUENCER && 
            (personalFare?.length === 0 || personalFare?.every((service) => service.rate == 0));

        setImageProfilePending(imageProfilePendingAux)
        setSocialNetworkPending(socialNetworkPendingAux);
        setRatePending(ratePendingAux);

        return imageProfilePendingAux || socialNetworkPendingAux || ratePendingAux;
    };

    const handleOnCloseCrop = () => {
        setShowCompleteProfileModalReminder(validateReminderProfileFields());
    }

    return (
        <>
            <Dialog id="fl-dialog-container" open={showCompleteProfileModalReminder} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className='p-4 pb-3 pt-3' style={{minWidth: '22vw', maxWidth: '45vw'}}>
                    <DialogTitle id="fl-dialog-title" className="d-flex flex-column justify-content-center align-items-center fs-3">
                        <img src={bellIcon} width={50} className=""/>
                        <FormattedMessage id="modalCompleteProfileReminderTitle" defaultMessage="¡Recuerda completar tu perfil!"/>
                    </DialogTitle>
                    <DialogContent className='pb-2'>
                        <p className="text-center fw-bold mb-3">
                            <FormattedMessage id="modalCompleteProfileReminderText" defaultMessage="Completa tu perfil para mejorar tu visibilidad con marcas y recibir mas colaboraciones."/>
                        </p>
                        
                        <div className="my-3 mx-auto" style={{width: "fit-content"}}>
                            {
                                pendingList
                                    .sort((a, b) => (b.pending === true) - (a.pending === true))
                                    .filter((item) => item.allowedUserTypes.includes(userType))
                                    .map((item, key) => {
                                        return (
                                            <div className="d-flex gap-2 align-items-center p-2" key={key}>
                                                {
                                                    (!item.pending) ?
                                                        <>
                                                            <img src={checkIcon} width={22} className=""/> 
                                                            
                                                        </>
                                                        : 
                                                        <button className="fl-button-secondary p-0 border-0 d-flex align-items-center" onClick={item.onClick}>
                                                            <img src={addIcon} width={22} className=""/>
                                                        </button>
                                                }
                                                <span className="ms-2">{item.text}</span>
                                            </div>
                                        )
                                    }
                                )
                            }
                            
                        </div>

                        <input ref={imageInputRef} className="d-none" type="file" id="file-input" accept="image/png, image/jpeg" value={image} onChange={(e) => {
                            setImage(e.target.value)
                            setShowCompleteProfileModalReminder(false);
                            handleImageUpload({ event: e, maxWidthOrHeight: 1024 }).then((compressedFile) => {
                                getBase64({ file: compressedFile }).then((image) => {
                                    setImagesToSend([{ data_url: image, file: e.target.files[0] }]);
                                })
                            })
                        }} />
                    </DialogContent>
                    <DialogActions className='d-flex p-2'>
                        <button onClick={handleClose} className="fl-button-secondary border-0 p-0 fw-bold fs-6">
                            <FormattedMessage id="modalCompleteProfileReminderButton" defaultMessage="Omitir"/>
                        </button>
                    </DialogActions>
                </div>
            </Dialog>

            {(showImageCrop) && (
                <ImageCrop 
                    imgSrc={imagesToSend[0]} 
                    setShowImageCrop={setShowImageCrop} 
                    setImagesToSend={setImagesToSend} 
                    setImage={setImage} 
                    xRadio={9} yRadio={9} 
                    save={saveImage} 
                    isLoading={isLoading}
                    handleOnClose={handleOnCloseCrop} 
                />)}
        </>
    )
}

export default ModalCompleteProfileReminder;