import styled from "styled-components";
import colombia from './../../utils/images/colombia_flag.png';
import usa from './../../utils/images/unitedstates_flag.png';
import { media } from "./../../utils/styles/media";
import { colors } from "./../../utils/styles/colors";

export const TranslateContainer = styled.div`
    //width: 60px;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    /* .flag{
        background-image:url(${(props) => props.country === "English" ? usa : colombia});
        background-size: 50px 35px;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        
    } */
    .translate{
        display: flex;
        align-items: center;
        cursor: pointer;
        .translateText{
            color: ${colors.text};
            font-weight: bold;
            font-size: 1.1rem;
        }

        .arrow{
            height: 10px; 
            cursor: pointer;
            margin-left: 15px;
            position: relative;
            //top: 5px;
        }
        .arrow{
            transform:rotate(${(props) => props.showList && '180deg'});
        }
        .arrow:hover{
            height:11px;
        } 
    }
    
    .dropDown{
        //width: 400px;
        //height: 190px;
        border-radius: 5% ;
        background-color: #ffffff;
        position: absolute;
        top:80px;
        right: 40px;
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 30px;
        font-size: 1.1rem;
        z-index: 150;
        color:${colors.darkGrey};
        box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
        .separator{
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        label{
            display: flex;
            align-items: center;
            margin-top: 10px;
            input{
                height: 20px;
                width: 20px;
                margin-right: 10px;
                accent-color: ${colors.hightlight};
            }
        }
    }

    /**---------Responsive--------- */
   
    ${media.desktop}{
        //width: 45px;
    
    /* .flag{ 
        background-size: 40px 25px;
        height: 25px;
        width: 25px;
        
    } */
    .translate{
        .arrow{
            height: 7px;
            margin-left:12px ;
        }
        .arrow:hover{
            height:8px;
        }
    }
    .dropDown{
        //width: 200px;
        //height: 130px;
        top:70px;
        right: 35px;
        padding: 20px;
        font-size: 1rem;
        
        
        label{
            input{
                height: 20px;
                width: 20px;
                
            }
        }
    }
    }

    ${media.tablet}{
        .translate{
            .translateText{
                font-size: 1rem;
            }
        }
    }


    ${media.largeMobile}{
        //width: 45px;
        margin-right: 0px;
        /* .flag{
                background-size: 30px 25px;
                height: 25px;
                width: 25px;
            } */
        .translate{
            .translateText{
                font-size: 0.95rem;
            }

            .arrow{
                margin-left:4px ;
            }
        }
        .dropDown{
            //width: 60vw;
            top:50px;
            right: 20px;
        }
    }
    
    ${"@media screen and (max-height: 500px) and (orientation: landscape)"} {
        //width: 45px;
        /* .flag{
            background-size: 30px 25px;
            height: 25px;
            width: 25px;
        } */
        .dropDown{
            //width: 200px;
            //height: 100px;
            right: 30px;
            //top:40px;
            top: 80px;
            z-index: 50;
            //font-size: 0.8rem;
            
        }
    }

    
`