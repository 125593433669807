import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const BASE_URL = API_GATEWAY_URL + '/fluincr-utils';

export const S3API = {

    getPresignedUrl: async (address, formatFile) => {
        const response = await axios.get(`${BASE_URL}/s3/generate-presigned-url?address=${address}&contentType=${formatFile}`);
        return response.data;
    },

    putObjectPublic: async (fileName) => {
        const response = await axios.put(`${BASE_URL}/s3/set-object-public?fileName=${fileName}`);
        return response.data;
    },

    uploadVideoS3: async (presignedUrl, file, setPorcentajeCarga) => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
      
          xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              const percentComplete = Math.round((event.loaded / event.total) * 100);
              setPorcentajeCarga(percentComplete)
            }
          });
      
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                console.log('Archivo subido con éxito');
                resolve(xhr.response);
              } else {
                console.error('Error al subir el archivo:', xhr.status);
                reject(new Error('Error al subir el archivo'));
              }
            }
          };
      
          xhr.open('PUT', presignedUrl);
          xhr.setRequestHeader('Content-Type', 'video/' + file.type.split('/')[1]);
          xhr.send(file);
        });
    },

    uploadImageS3: async (presignedUrl, file, formatFile) => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
      
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                console.log('Archivo subido con éxito');
                resolve(xhr.response);
              } else {
                console.error('Error al subir el archivo:', xhr.status);
                reject(new Error('Error al subir el archivo'));
              }
            }
          };
      
          xhr.open('PUT', presignedUrl);
          xhr.setRequestHeader('Content-Type', (formatFile || 'image/png'));
          xhr.send(file);
        });
    }
};