import { backendURL } from "../utils/constants/constants";

export async function getVerifyToken({ token, userType }) {

    try {
        const response = await fetch(`${backendURL}/admin/validateAdminToken/${userType}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        const data = await response.json();
        return data;
    } catch (e) {
        throw (e);
    }
}