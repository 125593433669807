import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalCreateCollaborationContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    
    .close{
        width: 45px;
        align-self: flex-end;
        position: absolute;
        top:16px;
        right: 20px;
        cursor: pointer;
    }
    

    .collaborationBody{
        background-color: #ffffff;
        border-radius: 15px;
        padding: 30px 40px;
        display: flex;
        width: 1000px;
        overflow-y: auto;
        .goBack{
            display:none;
        }
        .mobileTitle{
            display: none;
        }
        .influencerData{
            display: flex;
            flex-direction: column;
            width: 48%;
            .profile{
                position: relative;
                .avatar{
                    width: 90%;
                    border-radius: 35px;
                }
                .likeProfile{
                    position: absolute;
                    right: 60px;
                    top: 20px;
                    width: 40px;
                    height: 40px;
                }
                .networks{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: -14px;
                    top: 10px;
                    .network{
                        display: flex;
                        align-items: center;
                        color: #ffffff;
                        //background-color: red;
                        img{
                        height: 35px;
                        margin: 8px 0;
                        opacity: 0.8;
                        //margin-right: 5px;
                        cursor: pointer;
                        z-index: 50;
                        }
                        img:hover{
                            opacity: 1;
                        }
                        p{
                            font-weight: 600;
                            background-color: rgba(155,155,155,0.5);
                            margin-left: -6px;
                            padding: 3px 10px 3px 12px;
                            border-radius: 0 10px 10px 0;
                        }
                    }
                    
                }
                .seeProfile{
                    position: absolute;
                    bottom: 20px;
                    right: 55px;
                    border: none;
                    border-radius: 8px;
                    padding:5px 15px;
                    color: ${colors.title};
                    font-size: 1rem;
                    font-weight: 600;
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
            .dataProfile{
                width: 90%;
                .fileName{
                    display: flex;
                    color: ${colors.title};
                    .name{
                        font-size: 1.6rem;
                        font-weight: 800;
                        text-overflow: ellipsis;
                        text-wrap: nowrap;
                    }
                    .check{
                        width: 20px;
                        margin-left:10px;
                    }
                    .subItem{
                        display: flex;
                        //align-items: center;
                        margin-left:10px;
                        align-items: flex-end;
                        .score{
                            margin-bottom: 4px;
                        }
                        .star{
                            width: 15px;
                            margin-left: 3px;
                            margin-bottom: 8px;
                        }
                    }
                }
                .fileCountry{
                    display: flex;
                    align-items: baseline;
                    .city{
                        color: ${colors.hightlight};
                        font-weight: 700;
                        font-size: 1.1rem;
                    }
                    .languages{
                        margin: 0 10px;
                        white-space: nowrap;
                    }
                    .share{
                        img{
                            //width: 20px;
                            width: 15px;
                        }
                    }
                }
                .fileCategories{
                    margin-top: 5px;
                    color: ${colors.greyIcon};
                    text-transform: lowercase;
                    font-weight: bold;
                }
            }      
        }
        .collaborationData{
            width: 52%; 
            //background-color: red;
            .collaborationQuantity{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 100%;
                .quantity{
                    /* padding-left: 5%;
                    padding-right: 5%; */
                    padding-left: 2%;
                    padding-right: 2%;
                    .collaborationTitle{
                        font-size: 1.45rem;
                        font-weight: 800;
                        text-align: center;
                        display: flex;
                        align-self: center;
                        margin-bottom: 20px;
                        .logo{
                            width: 100px;
                            margin-left: 6px;
                        }
                    }
                    .notServices{
                        height: 160px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            color: ${colors.greyLetter};
                            font-size: 0.9rem;
                        }
                    }
                    .file{
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;
                        .type{
                            font-weight: 800;
                            text-transform: capitalize;
                        }
                        .right{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .buttons{
                                display: flex;
                                justify-content: space-between;
                                width: 100px;
                                button{
                                    border: none;
                                    color: ${colors.greyIcon};
                                    font-weight: 600;
                                    //font-size: 1.5rem;
                                    height: 20px;
                                    width: 30px;
                                    padding: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    background-color: #eeeeee;
                                    padding: 0;
                                    margin: 0;
                                    .addIcon{
                                        width: 12px;
                                    }
                                }
                                span{
                                    margin: 0 10px;

                                }
                                
                            }
                            .price{
                                color: ${colors.hightlight};
                                font-weight: 800;
                                font-size: 1rem;
                                //text-align: center;
                                align-self: center;
                            }
                        }
                    }
                }
            }
            .aditionalInfo{
                hr{
                    border: 0.5px solid ${colors.grey};
                    margin-bottom: 10px;
                    /* margin-left: 5%;
                    margin-right: 5%; */
                    margin-left: 2%;
                    margin-right: 2%;
                }
                .giftContainer{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    //padding-left: 5%;
                    //padding-right: 5%;
                    padding-left: 2%;
                    padding-right: 2%;
                    .giftInfo{    
                        input[type='checkbox'] {
                            -moz-appearance:none;
                            -webkit-appearance:none;
                            -o-appearance:none;
                            outline: none;
                            content: none;
                            margin: 0;
                            padding: 0;	  
                        }
                        input[type='checkbox']:before {
                            font-family: "FontAwesome";
                            content: "\f00c";
                            font-size: 16px;
                            color: transparent !important;
                            background: #ffffff;
                            display: block;
                            width: 18px;
                            height: 18px;
                            border:0.7px solid ${colors.grey};
                            border-radius: 5px;
                            margin-top: 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        input[type='checkbox']:checked:before {
                            color: ${colors.hightlight} !important;
                        }
                        .giftIcon{
                            width: 18px;
                            margin-left: 6px;
                        }
                        .giftText{
                            font-size: 0.8rem;
                            margin-left: 5px;
                        }
                    }
                    .giftPrice{
                        color: ${colors.hightlight};
                        font-weight: 800;
                        font-size: 0.8rem;
                        text-align: end;
                        display: flex;
                        align-items: center;
                    }
                }
                .commentContainer{
                    //padding-left: 5%;
                    //padding-right: 5%;
                    padding-left: 2%;
                    padding-right: 2%;
                    .addComments{
                        display: flex;
                        margin-bottom: 8px;
                        align-items: flex-end;
                        button{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #ffffff;
                            border: 1px solid ${colors.greyIcon};
                            border-radius: 5px;
                            font-weight: 600;
                            font-size: 1.5rem;
                            color: ${colors.greyIcon};
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                            margin: 0;
                            padding: 0;
                            .plusIcon{
                                width: 12px;
                                height: 12px;
                            }
                        }
                        .commentText{
                            font-size: 0.8rem;
                            margin-left: 5px;
                        }
                    }
                    .comments{
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        textarea{
                            border-radius: 10px;
                            height: 80px;
                            width: 90%;
                            margin-right: 10px;
                            padding: 10px;
                            outline: none;
                        }
                        .uploadIcon{
                            width: 25px;
                            cursor: pointer;
                        }
                        .chargingContainer{
                            width: 25px;
                            height: 25px;
                        }
                        input[type="file"]{
                            display: none;
                        }
                        .fileName{
                            font-size: .625rem;
                            width: 60px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
                .promotionalTitle{
                    font-size: 0.8rem;
                    font-weight: 600;
                    margin-bottom: 8px;
                    /* padding-left: 5%;
                    padding-right: 5%; */
                    padding-left: 2%;
                    padding-right: 2%;
                } 
                .promotionalInputContainer{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    /* padding-left: 5%;
                    padding-right: 5%; */
                    padding-left: 2%;
                    padding-right: 2%;
                    input{
                        width: 80%;
                        padding: 8px;
                        border: 1px solid ${colors.greyIcon};
                        border-radius: 5px;
                        outline: none;
                    }
                    input::placeholder{
                        font-family: 'Open Sans',  sans-serif;
                        font-weight: bold;
                        color: rgba(112,112,112,0.51);
                    }
                    button{
                        width: 19%;
                        background-color: ${colors.hightlight};
                        border: none;
                        color: #ffffff;
                        border-radius: 5px;
                        font-family: 'Open Sans',  sans-serif;
                        font-weight: bold;
                    }
                }
                .addShoppingCart{
                    background-color: ${colors.blueButton};
                    border: none;
                    border-radius: 10px;
                    color: #ffffff;
                    font-size: 1.2rem;
                    font-weight: 700;
                    height: 45px;
                    cursor: pointer;
/*                     margin-left: 5%;
                    margin-right: 5%; */
                    margin-left: 2%;
                    margin-right: 2%;
                    margin-top: 4px;
                    width: 90%;
                    width: 96%;
                   /*  padding-left: 5%;
                    padding-right: 5%; */
                    padding-left: 2%;
                    padding-right: 2%;
                    .cart{
                        width: 20px;
                        margin: 0 5px;
                    }
                } 
                .disabledButton{
                    cursor: not-allowed;
                }
                .requiredText{
                    font-size: 0.8rem;
                    text-align: end;
                    //margin-right: 5%;
                    margin-right: 2%;
                    font-weight: 300;
                }
            }
        }
    }

    /**--------Responsive---------- */
    ${media.desktop}{
        /* .collaborationBody{   
            width: 65vw;
        } */
    }
    
    ${media.smallDesktop}{
        .collaborationBody{   
            //width: 80vw;
        }
    }
    
    ${media.tablet}{

        width: 100vw;
        height: 100vh; 
        .close{
           display: none;
        }
    
        .collaborationBody{   
            width: 100vw;
            height: 100vh;
            flex-direction: column;
            border-radius: 0;
            padding: 50px;
            .goBack{
                display:inherit;
                display: flex;
                cursor: pointer;
                width: 120px;
                margin-bottom: 30px;
                .backIcon{
                    width: 18px;
                    margin-right: 10px;
                }
                .backText{
                    color: #000000;
                }
            }
            .mobileTitle{
                display: inherit;
                font-size: 1.8rem;
                font-weight: 800;
                text-align: center;
                margin-bottom: 20px;
                .logo{
                    width: 110px;
                    margin-left: 10px;
                }
            }

            .influencerData{
                flex-direction: row;
                width: 100%;
                .profile{
                    width: 50%;
                }
                .dataProfile{
                    width:50%;
                    .fileName{
                        .subItem{
                            .star{
                                width: 15px;
                                margin-left: 3px;
                            }
                        }
                    }
                }      
            }
            .collaborationData{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top:20px ;
                .collaborationQuantity{
                    .quantity{
                        margin-top: 20px;
                        padding-left: 0;
                        padding-right: 0;
                            .collaborationTitle{
                            display:none;
                        }
                    }
                }
                .aditionalInfo{
                    hr{
                        margin-bottom: 20px;
                        margin-left: 0;
                        margin-right: 0;
                    }
                    .giftContainer, .commentContainer, .promotionalTitle, .promotionalInputContainer{
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .addShoppingCart{
                        margin-left: 0;
                        margin-right: 0;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }
        
    } 

    ${media.largeMobile}{

        .collaborationBody{   
            padding: 30px;
            overflow-y: auto;
            .goBack{
                margin-bottom: 15px; 
            }
            .mobileTitle{
                font-size: 1.5rem;
                margin-bottom: 10px;
                flex-direction: column;
                align-items: center;
                .logo{
                    width: 100px;
                    margin-left: 4px;
                }
            }

            .influencerData{
                flex-direction: column;
                width: 100%;
                .profile{
                    width: 100%;
                    .avatar{
                        width: 100%;
                    }
                    .seeProfile{
                        bottom: 25px;
                        right: 25px;
                    }
                } 
                .dataProfile{
                    width: 100%;
                }     
            }
            .collaborationData{
                .promotionalInputContainer{
                    input{
                        width: 75%;
                    }
                    button{
                        width: 24%;
                    }
                }

            }
        }
       
    }

`

export const ProfileImage = styled.div`

    width: 300px; 
    height: 380px;
    width: 90%;
    border-radius: 35px;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #ffffff;

    /**--------Responsive-------- */
    ${media.largeDesktop}{
        height: 390px;
    }

    ${media.tablet}{
        height: 330px;
    }

    ${media.largeMobile}{
        height: 380px;
        width: 100%;
    }

    ${"@media(max-width: 380px)"}{
        height: 350px;
    }
`
