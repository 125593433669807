import React, { useState, useRef } from 'react';
import { ImageCropContainer } from './ImageCropStyled';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner/Spinner';
import Cropper from "react-cropper";

import close from './../../utils/images/close_circle.svg';

import "cropperjs/dist/cropper.css";


export default function ImageCrop({ imgSrc, setShowImageCrop, setImagesToSend, setImage, xRadio, yRadio, save, isLoading, handleOnClose = () => null}) {

    const [imageSrc, setImageSrc] = useState(imgSrc.data_url);
    const [croppedImgSrc, setCroppedImgSrc] = useState();

    const cropper = useRef(null);

    const handleCropChange = () => {
        const croppedImgData = cropper.current.cropper
            .getCroppedCanvas()
            .toDataURL();
        setCroppedImgSrc(croppedImgData)
    }

    const handleSaveClick = () => {
        if(croppedImgSrc)
            save(croppedImgSrc);
        else{
            const croppedImgData = cropper.current.cropper
                .getCroppedCanvas()
                .toDataURL();
            save(croppedImgData);
        }      
    }

    return (
        <ImageCropContainer>
            <div className='pruebaBody'>
                <img src={close} alt="" onClick={() => {
                    setShowImageCrop(false)
                    setImagesToSend([])
                    setImage('')
                    handleOnClose()
                }} className='closeButton' />

                <Cropper
                    style={{ height: "350px" }}
                    ref={cropper}
                    src={imageSrc}
                    aspectRatio={xRadio / yRadio}
                    cropend={() => handleCropChange()}
                />

                <button className='saveButton' onClick={() => handleSaveClick()}>
                    <FormattedMessage id="myAccount_button_3" defaultMessage="Guardar" />
                </button>
            </div>
            {(isLoading) && (<Spinner />)}
        </ImageCropContainer>
    )
}