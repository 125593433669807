import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";
import CentraleSans from './../../utils/styles/CentraleSansBold.otf';

export const CollaborationsDetailsContainer = styled.div`
    
    @font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }
    
    padding: 0 50px 50px;
    hr{
        border: 1px solid ${colors.grey};
    }
    display: flex;
    flex-direction: column;
    .collaborationTop{
        display: flex;
        align-items: center;
        margin-top: 15px;
        .goBack{
            display: flex;
            align-items: center;
            width: 120px;
            cursor: pointer;
            margin: 20px 0;
            font-size: 1.2rem;
            .backIcon{
                margin-right: 10px;
                width: 20px;
            }
        }
        .collaborationOrder {
            display: flex;
            .order, .date{
                color: ${colors.grey};
                margin-left: 30px;
                font-size: 1.2rem;
            }
        }
        
    }
    .collaborationHeader{
        display: flex;
        justify-content: space-between;
        //margin-bottom: 50px;
        .influencerDate{
            display: flex;
            align-items: center;
            .influencerName{
                color: ${colors.greyInput};
                font-size: 2rem;
                font-family: 'Centrale', 'sans-serif';
                margin-right: 10px;
                span{
                    text-transform: capitalize;
                }
            }
            .check{
                width: 20px;
                margin-left: 10px;
            }
            .rating{
                display: flex;
                align-items: center;
                margin-left: 10px;
                .star{
                    width: 15px;
                    margin-left: 5px;
                }
            }
        }
        .collaborationStatus{
            display: flex;
            align-items: center;
            .countdown{
                color: ${colors.hightlight};
                font-weight: 800;
                font-size: 1.1rem;
            }
            .declineButton{
                color: ${colors.greyLetter};
                cursor: pointer;
            }
            /* .statusButton{
                background-color: ${colors.grey};
                border-radius: 10px;
                border: none;
                padding: 8px 20px;
                color: #ffffff;
                display: flex;
                align-items: center;
                font-size: 1.1rem;
                font-family: 'Centrale', sans-serif;
                margin-left: 20px;
                cursor: pointer;
                .giftContainer{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                    top: 10px;
                    .giftIcon{
                        width: 20px;
                        display: block;
                        margin: 0 auto;
                    }
                    .giftPrice{
                        font-size: 0.5rem;
                        background-color: red;
                    }
                }
                
            } */
            .selectContainer{
                margin-left:10px;
            }
            .collaborationButtonContainer{
                margin-left: 1.25em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: end;
            }
            .buttonAditional{
                text-align: right;
                font-size: 0.6rem;
                color: ${colors.greyInput};
                a{
                    color: ${colors.greyInput};
                    text-decoration: underline;
                }
            }
        }
    }
    .trackingContainer{
        display: flex;
        align-items: center;
        .trackingData{
            margin-right: 10px;
            p{
                font-size: 0.8rem;
                font-weight: bold;
                color: ${colors.greyInput};
                margin-bottom: 2px;
            }
            .inputTracking{
                width: 100%;
                padding: 5px 10px ;
                border-radius: 8px;
                border: 1px solid ${colors.grey};
                outline: none;
                ::placeholder{
                    font-weight: bold;
                    font-size: 0.8rem;
                }
            }
            .buttonsContainer{
                margin-top: 5px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .editButton{
                    padding: 3px 0px;
                    border-radius: 10px;
                    font-size: 0.6rem;
                    background: transparent;
                    border: none;
                    color: ${colors.greyIcon};
                    text-decoration: underline;
                    cursor: pointer;
                }
                .saveButton{
                    padding: 4px 10px;
                    border-radius: 10px;
                    font-size: 0.6rem;
                    background: ${colors.hightlight};
                    border: none;
                    color: #ffffff;
                    cursor: pointer; 
                }
            }
        }
        .influencerData{
            
            p{
                font-size: 0.6rem;
                font-weight: bold;
                color: ${colors.greyInput};
            }
            .influencerName{
                text-transform: capitalize;
            }
        }
    }
    .collaborationBody{
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        section{
            width: 47%;
            .productInformation{
                display: flex;
                color: ${colors.greyInput};
                .productContainer{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    .productImage{
                        width: 40%;
                       // min-height: 250px;
                        height: 250px;
                        background-repeat: no-repeat;
                        background-position: 50% 50%; 
                        background-size: cover;
                        //background-size: contain;
                    }
                    .description{
                        width: 58%;
                        //text-align: justify;
                        .productName{
                            font-size: 1.5rem;
                            font-family: 'Centrale', 'sans-serif';
                            text-transform: capitalize;
                        }
                        .productPrice{
                            font-size: 1.1rem;
                            font-family: 'Centrale', 'sans-serif';
                            margin: 5px 0 10px;
                        }
                        .productDescription{
                            font-size: 0.9rem;
                            display: block;
                            display: -webkit-box;
                            max-width: 100%;
                            margin: 0 auto;
                            line-height: 1;
                            -webkit-line-clamp: 11;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .collaborationStatusMobile{
                    display: none;
                }
            }
            .commentsTitle{
                margin-top: 0.625em;

                span{
                    color: ${colors.greyInput};
                    font-size: 1rem;
                    font-family: 'Centrale', 'sans-serif';
                }
            }
            .commentsContainer{
                border: 1px #bcbcbc solid;
                padding: 0.625em;
                border-radius: 0.5em;
                margin-top: 0.75em;

                .comments{
                    color: ${colors.greyInput};
                    text-align: justify;
                    font-size: 0.9rem;
                }
            }
            .fileContainer{
                display: flex;
                align-items: center;
                margin: 15px 0 10px;
            }
            .file{
                color: ${colors.hightlight};
                font-weight: 800;
                cursor: pointer;
                margin-right: 5px;
            }
            .fileAditional{
                font-weight: bold;
            }
            
            .voucher{
                //margin-top: 10px;
                margin-top: 20px;
                color: ${colors.darkGrey};
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
            }
            table{
                width: 100%;
                border-spacing: 0 20px;
                border-collapse: collapse;
                tr{
                    td{
                        vertical-align: top;
                        margin-bottom: 20px;
                        .unitPrice{
                            font-weight: bold;
                            height: 80px;
                            margin: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span{
                                margin-right: 2px;
                            }
                        }
                        .collaborationItem{
                            font-weight: 600;
                            height: 80px;
                            margin: 5px 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-transform: capitalize;
                        }
                        .statusTextContainer{
                            flex-grow: 1;
                            text-align: center;
                            height: 80px;
                            margin: 5px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .adminSelect{
                            display: flex;
                            align-items: center;
                        }
                        .subtotal{
                            margin: 5px;
                            height: 80px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .status{
                            color: ${colors.darkGrey};
                            font-weight: 600;
                        }
                        /* .empty{
                            height: 22px;
                        } */
                        .statusButton{
                            color: ${colors.hightlight};
                            font-weight: 600;
                            cursor: pointer;
                        }
                        .image{
                            height: 80px;
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: ${colors.grey};
                            margin: 5px;
                        }
                        
                        .imageUpload{
                            height: 80px;
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #ffffff;
                            margin: 5px;
                            .upload{
                                width: 20px;
                                cursor: pointer;
                            }
                        }
                        
                        .totalName{
                            font-weight: bold;
                        }
                        .totalPrice{
                            font-weight: bold;
                            margin: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;                    
                        }
                    }
                }

                tr:nth-last-child(2) td{
                    padding-bottom: 0.75em;
                }

                tr:last-child {
                    border-top: 2px solid ${colors.grey};
                    margin-top: 1.5em;
                    td {
                        vertical-align: middle;   
                    }         
                }
            }
            .aditionalInfo{
                font-size: 0.8rem;
                color: ${colors.greyInput};
            }
             
        }
        
    }
    .chatButton{
        align-self: flex-end;
        justify-self: flex-end;
        margin-top: 20px;
        background: linear-gradient( 45deg, ${colors.blueButton},${colors.blueDark});
        color: #ffffff;
        border: none;
        border-radius: 20px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        cursor: pointer;
        .chatIcon{
            width: 20px;
            margin-left: 15px;
        }
    }
    .disabled{
        cursor: not-allowed;
    }

    /**---------Responsive---------- */
    ${media.desktop}{
        .collaborationTop{
            .goBack{
                font-size: 1rem;
                .backIcon{
                    width: 18px;
                }
            }
            .order, .date{
                font-size: 1.1rem;
            }
        }
        .collaborationHeader{
            .influencerDate{
                .influencerName{
                    font-size: 1.6rem;
                }
            }
        }
    }

    ${media.laptop}{
        hr{
            display: none;
        }
        .collaborationHeader{
            //margin-bottom: 30px;
            .collaborationStatus{
                display: none;
            }

            .collaborationButtonContainer{
                margin-left: 0.625em;
            }
        }
        .collaborationBody{
            margin-top: 30px;
            flex-direction: column;
            section{
                width: 100%;
                .productInformation{
                    .productContainer{
                        width: 100%;
                    }
                    .collaborationStatusMobile{
                        //min-width: 50%;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        .countdown{
                            color: ${colors.hightlight};
                            font-weight: 800;
                            font-size: 1.1rem;
                            text-align: right;
                        }
                        .declineButton{
                            text-align: right;
                            cursor: pointer;
                        }
                        .statusButton{
                            align-self: flex-end;
                            background-color: ${colors.grey};
                            border-radius: 20px;
                            border: none;
                            padding: 10px 20px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            font-size: 1.1rem;
                            font-family: 'Centrale', sans-serif;
                            margin-bottom: 10px;
                            cursor: pointer;
                        }
                        .selectContainer{
                            align-self: flex-end;
                        }
                    }
                }
            }
        }
    }

    ${media.largeMobile}{
        padding: 0 20px 20px;
        
        .collaborationTop{
            align-items: end;
            justify-content: space-between;
            margin-bottom: 1em;

            .collaborationOrder{
                flex-direction: column;
                align-items: end;
                .date, .order{
                    font-size: 1em;
                }
            }
            
        }

        .collaborationHeader {
            .influencerDate{
                gap: .5em;
                flex-direction: column;
                align-items: flex-start;

                .influencerName {
                    font-size: 1.25em;
                }
            }
        }
        
        .trackingContainer{
            margin-top: 10px;
            flex-direction: column;
            align-items: flex-start;
            .influencerData{
                margin-left: 0;
            }
        }
        .collaborationBody{
            flex-direction: column;
            section{
                width: 100%;
                .productInformation{
                    flex-direction: column;
                    .productContainer{
                        width: 100%;
                        flex-direction: column-reverse;
                        gap: 1em;

                        .productImage{
                            width: 60%;
                                height: auto;
                        }
                        .description{
                            width: 100%;

                            .productName{
                                font-size: 1.25em;
                            }

                            .productPrice {
                                font-size: .75em;
                                
                            }
                        }
                    }
                    .collaborationStatusMobile{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 20px;
                        .countdown{
                            color: ${colors.hightlight};
                            font-weight: 800;
                            font-size: 1.1rem;
                            text-align: center;
                        }
                        .declineButton{
                            text-align: center;
                            
                        }
                        .statusButton{
                            align-self: center;
                            background-color: ${colors.grey};
                            border-radius: 20px;
                            border: none;
                            padding: 10px 20px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            font-size: 1.1rem;
                            font-family: 'Centrale', sans-serif;
                            margin-bottom: 10px;
                            cursor: pointer;
                            .giftIcon{
                                width: 20px;
                            }
                        }
                    }
                }
                table{
                    tr{
                        font-size: 0.9rem;
                        td:nth-child(3){
                            width: 20%;
                        }
                    }
                }
            }
        }
    }

`

export const ProfileImage = styled.div`

    width: 50px; 
    height: 50px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #ffffff;
`

export const CollaborationButton = styled.button`
    min-width: 10em;
    background-color:${(props) => (props.status === 1) ? colors.blueButton : colors.grey};
    border-radius: 10px;
    border: none;
    //padding: 10px 20px;
    padding: 10px 20px 5px;
    height: 55px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-family: 'Centrale', sans-serif;
    cursor:${(props) => (props.status === 1) ? 'pointer' : 'not-allowed'};
    .buttonPrice{
        margin-left: 8px;
    }
    .giftContainer{
        display: flex;
        //flex-direction: column;
        //align-items: center;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        position: relative;
        height: 100%;
        .subtotalGift{
            font-size: 0.8rem;
        }
        .plusGift{
            margin-left: 5px;
            margin-right: 5px;
        }
        .giftIconContainer{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: .125em;
        }
        .giftIcon{
            width: 10px;
        }
        .giftPrice{
            font-size: 0.8rem;
            margin-left: 4px;
            //position: absolute;
            //bottom: 5px;
        }
    }

    /**---------Responsive-------- */
    ${media.desktop}{
        height: 50px;
    }

    ${media.laptop}{
        border-radius: 20px;
        margin-bottom: 10px;
        align-self: flex-end;
                            
    }
    ${media.tablet}{
        border-radius: 20px;
        margin-bottom: 10px;
        //align-self: center;
    }
    ${media.largeMobile}{
        align-self: center;
    }
`

export const UploadImage = styled.div`
    height: 80px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 5px;
    .uploadContainer{
        .uploadItem{
            cursor: ${props => props.status === '1' ? 'not-allowed' : 'pointer'};
            width: 20px;
        }
        input[type="file"]{
            display: none;
        }
    }
`