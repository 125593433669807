import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const BASE_URL = API_GATEWAY_URL + '/fluincr-static-data';

const usedCurrencies = [
    {
      deleted: "0",
      id: 28,
      name: "Canadian Dollar",
      symbol: "CAD",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 8,
      name: "Australian Dollar",
      symbol: "AUD",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 20,
      name: "Brazilian Real",
      symbol: "BRL",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 47,
      name: "Euro",
      symbol: "EUR",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 103,
      name: "Mexican Peso",
      symbol: "MXN",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 34,
      name: "Colombian Peso",
      symbol: "COP",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 151,
      name: "United States Dollar",
      symbol: "USD",
      usedSymbol: true
    },
    {
      deleted: "0",
      id: 50,
      name: "British Pound Sterling",
      symbol: "GBP",
      usedSymbol: true
    }
  ];

const rates = [
    {
        deleted: "0",
        id: 17,
        name: "USD",
        rate: 1.00
    },
    {
        deleted: "0",
        id: 1402,
        name: "CAD",
        rate: 1.37
    },
    {
        deleted: "0",
        id: 1403,
        name: "AUD",
        rate: 1.52
    },
    {
        deleted: "0",
        id: 1404,
        name: "BRL",
        rate: 5.07
    },
    {
        deleted: "0",
        id: 1405,
        name: "EUR",
        rate: 0.93
    },
    {
        deleted: "0",
        id: 1406,
        name: "MXN",
        rate: 16.91
    },
    {
        deleted: "0",
        id: 1407,
        name: "COP",
        rate: 3889.00
    },
    {
        deleted: "0",
        id: 1408,
        name: "GBP",
        rate: 0.80
    }
]

export const CurrencyAPI = {
    // Read
    getCurrencies: async () => {
        //const response = await axios.get(`${BASE_URL}/currency/currencies`);
        //return response.data;
        return usedCurrencies;
    },

    getRates: async () => {
        //const response = await axios.get(`${BASE_URL}/currency/rates`);
        //return response.data;
        return rates;
    }
};