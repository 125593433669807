import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-product-and-service';

export const RateByServiceAPI = {
    updateRates: async (userId, rates) => {
        const response = await axiosInstance.put(`${BASE_URL}/rate-by-service/update/${userId}`, rates);
        return response.data;
    }
};