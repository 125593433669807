import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-collaboration/collaboration-survey';

export const CollaborationSurveyAPI = {
    saveCollaborationSurveyResponse: async (collaborationId, collaborationSurveyResponseDTO) => {
        const response = await axiosInstance.post(`${BASE_URL}/${collaborationId}`, collaborationSurveyResponseDTO);
        return response.data;
    }
}