import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { contentCollaborationsStatusOptions } from '../../utils/constants/general';
import { useIntl } from 'react-intl';
import ModalConfirmacion from './ModalConfirmacion/ModalConfirmacion';
import Spinner from '../Spinner/Spinner';


const ItemStatusSelect = ({item}) => {

    const [auxStatusId, setAuxStatusId ] = useState();

    const [showModalAdminStatusChangeConfirmation, setShowModalAdminStatusChangeConfirmation] = useState(false);

    const [onConfirmStatus, setOnConfirmStatus] = useState(null);

    const [statusOptions, setStatusOptions] = useState([]);

    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setAuxStatusId(item?.status?.id);
    }, [item]);

    useEffect(() => {
        const statusOptionsAllowed = contentCollaborationsStatusOptions
            .map((statusOption) => ({...statusOption, isDisabled: !statusOption.previousStatusAllowed.includes(auxStatusId)}));
    
        setStatusOptions(statusOptionsAllowed);
    }, [auxStatusId])
    
    const selectStyles = {
        control: (styles) => ({
            ...styles, borderRadius: '10px',
            boxShadow: 'none', height: '30px', minHeight: '34px', flexWrap: 'no-wrap', width: '180px'

        }),
        menu: (styles) => ({
            ...styles, display: 'flex'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        input: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        placeholder: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        singleValue: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        dropdownIndicator: (styles) => ({
            ...styles, color: '#E8339E'
        }),
        menuList: (styles) => ({
            ...styles, width: '180px'
        })
    };

    const adminChangeStatus = ({ newStatus }) => {
        setIsLoading(true);
        
        //TODO: Aquí se debe hacer la llamada a la API para cambiar el status del item

    }

    return (
        <>
            <Select options={statusOptions}
                styles={selectStyles}
                placeholder={intl.formatMessage({ id: "collaboracion_status_title" })}
                value={statusOptions.find(option => option.value === auxStatusId)}
                onChange={(event) => {
                    //Si el status es Terminado (8) o Cancelado (9) se debe abrir el modal de confirmación
                    if([8,9].includes(event.value)){
                        setOnConfirmStatus(event.value);
                        setShowModalAdminStatusChangeConfirmation(true);
                    } else {
                        adminChangeStatus({ newStatus: event.value })
                    }
                }} 
            />

            <ModalConfirmacion 
                    open={showModalAdminStatusChangeConfirmation} 
                    setOpen={setShowModalAdminStatusChangeConfirmation} 
                    handleConfirm={adminChangeStatus} 
                    confirmParam={{newStatus: onConfirmStatus}}
                    titleId={"modalConfirmationChangeStatusAdminTitle"}
                    textId={"modalConfirmationChangeStatusAdminText"}
            ></ModalConfirmacion>

            {(isLoading) && (<Spinner />)}
        </>
    )
}

export default ItemStatusSelect