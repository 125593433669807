import styled from "styled-components";
import { media } from "../../utils/styles/media";
import { colors } from "../../utils/styles/colors";
import { networkRanges } from "../../utils/styles/networks";

export const InfluencerProfileContainer = styled.div`

    font-family: 'Open Sans',  sans-serif;
    .banner{
        background-color: #000000;
        display: flex;
        flex-direction: column;
        padding: 30px 50px ;
        min-height: 600px;
        .goBack{
            display: flex;
            margin-bottom: 20px;
            margin-left: 50px;
            cursor: pointer;
            width: 230px;
            .backIcon{
                width: 40px;
                margin-right: 15px;
            }
            .backText{
                color: #ffffff;
                font-size: 2.5rem;
                font-weight: 500;
            }
        }
        .bannerInformation{
            display: flex;
            width: 100%;
            justify-content: space-between;
            .networkContainer{
                width: 52%;
                color: #ffffff;
                display: flex;

                .socialNetworksProfileContainer{
                    display: flex;
                    flex-direction: row-reverse;
                    width: 100%;
                    min-height: 220px;
                    padding-left: 23%;
                    padding-right: 2em;
                }
            }

            .socialNetworksProfileContainerMobile{
                display: none;
            }

            .data{
                padding-left: 3em;
                margin-right: 50px;
                width: 45%;
                font-family: 'Open Sans',  sans-serif;
                .fileName{
                    display: flex;
                    //align-items: center;
                    align-items: baseline;
                    .name{
                        color: #ffffff;
                        font-size: 4rem;
                        font-weight: bold;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 85%;
                    }
                    .checkContainer{
                        background-color: #000000;
                        width: 35px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 25px; 
                        .check{
                            width: 100%;
                            height: 100%;
                        }
                    }  
                    .likeInfluencerContainer{
                        margin-left: 25px;
                        width: 60px;
                        height: 60px;
                    }
                    .likeCompanyContainer{
                        width: 40px;
                        display: flex;
                        align-items: flex-end;
                        margin-left: 20px;
                    }               

                }
                .firstFile, .lastFile{
                    display: flex;
                    //align-items: center;
                    position: relative;
                    margin-top: 5px;
                    .star{
                        margin-left: 10px;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                    }
                    
                    .item{
                        display: flex;
                        align-items: center;
                        .city{
                            color: ${colors.hightlight};
                            //font-size: 2.5rem;
                            font-size: 2.3rem;
                            font-weight: 600;
                            margin-right: 10px;
                        }
                    }
                    .items{
                        display: flex;
                        align-items: flex-start;
                        //align-items: center;
                        .share{
                            position: relative;
                            margin-left: 25px;
                            margin-top: 7px;
                            cursor: pointer;
                            .shareIcon{
                                width: 35px;
                                height: 35px;
                                cursor: pointer;
                            }
                        }
                        .subItem{
                            display: flex;
                            position: relative;
                            align-items: center;
                            .language{
                                color: #ffffff;
                                //margin-right: 20px;
                                margin-left: 10px;
                                font-size: 2.5rem;
                                span{
                                    white-space: nowrap
                                }
                                
                            }
                            .score{
                                color: #ffffff;
                                font-size: 2.5rem;
                                margin-left: 25px;
                            }
                        }
                        
                    }

                    .categories{
                        color: ${colors.hightlight};
                        text-align: center;
                        text-transform: lowercase;
                        font-weight: 600;
                        font-size: 2.4rem;
                    }

                }
                .descriptionContainer{
                    margin: 15px 0;
                    display: flex;
                    align-items: flex-end;
                    color: #ffffff;
                    
                    .descriptionEmpty{
                        width: 100%;
                        .item{
                            hr{
                                margin-top: 40px;  
                            }
                            hr:first-child{
                                margin-top: 5px;  
                            }
                        }

                    }
                    .descriptionContent{
                        max-height: 160px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        inline-size: 100%;
                        overflow-wrap: break-word; 
                        p{
                            font-weight: lighter;
                            font-size: 2.4rem;
                        }
                    }                   
                }
                .lastFile{
                    margin-top: 5px;
                }

            }
            .networkMobile{
                display: none;
            }
        }
    }
    .myProfileBody{
        min-height: 600px;
        padding:30px 95px 30px 95px ;
        
        .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{
            margin-top: 30px;
            .title{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                h3{
                    font-size: 3.5rem;
                    color: ${colors.title};
                }
                /* .more{
                    align-self: flex-end;
                    font-weight: bold;
                    font-size: 1.1rem;
                    color: ${colors.text};
                    text-decoration: underline;
                    font-family: 'Centrale', sans-serif;
                } */
            }
            
            .collaborationCards, .galeryCards, .productsCards{
                display: flex;
                flex-wrap: wrap;
                cursor: pointer;
                .front{
                    width: 23%;
                    height: 21vw;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                    position: relative;
                    margin: 0 2% 2% 0;
                    .companyIcon{
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        border-radius: 50%;
                    }
                    .likeProductContainer{
                        position: absolute;
                        top: 25px;
                        right: 25px;
                        width: 60px;
                        height: 60px;
                        
                    }                   
                    .productName{
                        width: 100%;
                        background-color: rgba(61, 61, 61, 0.5);
                        text-align: center;
                        color: #ffffff;
                        padding: 10px 12px;
                        text-transform: capitalize;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        //font-size: 2.6rem;
                        //font-size: 2.4rem;
                        font-size: 2.2rem;
                        font-weight: bold;
                        a{
                            color: #ffffff;
                        }
                        a:visited{
                            color: #ffffff;
                        }
                        p{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                        }
                    }
                }
                .notContent{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 400px;
                    width: 100%;
                    p{
                        font-size: 1.8rem;
                    }
                    
                }
            }
            /* .galeryCards{
                -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
                -webkit-mask-size: 100% 100%;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: left top,  left bottom;
            } */
            .noContent{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height:150px;
            }
            .more{
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 1.1rem;
                    color: ${colors.hightlight};
                    text-decoration: underline;
                    font-family: 'Centrale', sans-serif;
            }
            
        }
        .productsCardsContainer{
            //margin-top: 250px;
            margin-top: 200px;
                  
        }
    }

    /**----------Responsive---------- */
    ${media.extralargeDesktop}{
        .banner{
            min-height: 550px;
            .bannerInformation{
                margin-top: 30px;
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 3.5rem;
                        }
                    }
                }
            }
        }
        
    }

    ${media.largeDesktop}{
        .banner{
            min-height: 450px;
            padding: 30px 30px ;
            .goBack{
                width: 180px;
                margin-left: 50px;
                .backIcon{
                    width: 35px;
                }
                .backText{
                    font-size: 1.8rem;
                }
            }
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 3.3rem;
                        }
                        .checkContainer{
                            width: 30px;
                        }  
                        .likeInfluencerContainer{
                            width: 45px;
                            height: 45px;
                        }
                        .likeCompanyContainer{
                            width: 35px;
                        }               
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                //font-size: 1.9rem;
                                font-size: 1.8rem;
                            }
                            
                        }
                        .items{
                            .share{
                                margin-top: 5px;
                                .shareIcon{
                                    width: 29px;
                                    height: 29px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.9rem;
                                font-size: 1.8rem;
                                }
                                .score{
                                    font-size: 1.9rem;
                                    font-size: 1.8rem;
                                }
                                .star{
                                    width: 25px;
                                    height: 25px;
                                }
                            }
                        }
                        .categories{
                            font-size: 1.8rem;
                            font-size: 1.8rem;
                        }
                    }
                    .descriptionContainer{
                        .descriptionEmpty{
                            width: 100%;
                            .item{
                                hr{
                                    margin-top: 30px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            max-height: 117px;
                            p{
                                font-size: 1.8rem;
                                font-size: 1.7rem;
                            }
                        } 
                    }
                }
            }
        }
        .myProfileBody{
            padding:30px 80px 30px 80px;
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .title{
                    h3{
                        font-size: 2.6rem;
                    }
                }
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .likeProductContainer{
                            top: 20px;
                            right: 20px;
                            width: 50px;
                            height: 50px;
                            
                        }
                        .productName{
                            padding: 8px 10px;
                            //font-size: 2rem;
                            font-size: 1.8rem;
                        }
                    }
                    .notContent{
                        height: 350px;
                        p{
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .productsCardsContainer{
                //margin-top: 200px;
                margin-top: 190px;   
            }
        }
    }

    ${media.specialScreen}{
        .banner{
            min-height: 400px;
            padding: 30px 30px ;
            .goBack{
                width: 160px;
                .backIcon{
                    width: 28px;
                }
                .backText{
                    font-size: 1.4rem;
                }
            }
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 2.5rem;
                        }
                        .checkContainer{
                            width: 25px;
                        }  
                        .likeInfluencerContainer{
                            width: 40px;
                            height: 40px;
                        }
                        .likeCompanyContainer{
                            width: 40px;
                        }               
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.4rem;
                            }
                            
                        }
                        .items{
                            .share{
                                .shareIcon{
                                    width: 21px;
                                    height: 21px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.4rem;
                                }
                                .score{
                                    font-size: 1.4rem;
                                }
                                .star{
                                    width: 21px;
                                    height: 21px;
                                }
                            }
                        }
                        .categories{
                            font-size: 1.3rem;
                        }
                    }
                    .descriptionContainer{
                        .descriptionEmpty{
                            width: 100%;
                            .item{
                                hr{
                                    margin-top: 30px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            p{
                                font-size: 1.3rem;
                            }
                        } 
                    }
                }
            }
        }
        .myProfileBody{
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .likeProductContainer{
                            top: 15px;
                            right: 15px;
                            width: 40px;
                            height: 40px;   
                        }
                        .productName{
                            padding: 5px 8px;
                            //font-size: 1.8rem;
                            font-size: 1.6rem;
                        }
                    }
                    .notContent{
                        height: 300px;
                        p{
                            font-size: 1.3rem;
                        }
                    }
                }
            }
            .productsCardsContainer{
                margin-top: 150px;
                    
            }
        }
    }

    ${media.bigmacdesktop}{
        .banner{
            min-height: 380px;
            .goBack{
                width: 140px;
                margin-left: 40px;
                .backIcon{
                    width: 26px;
                }
                .backText{
                    font-size: 1.2rem;
                }
            }
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 2.4rem;
                        }
                        .checkContainer{
                            width: 24px;
                        }  
                        .likeInfluencerContainer{
                            width: 38px;
                            height: 38px;
                        }
                        .likeCompanyContainer{
                            width: 38px;
                        }               
                    }
                }
            }
        }
        .myProfileBody{
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .productName{
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .productsCardsContainer{
                margin-top: 120px;
                    
            }
        }
        
    }

    ${media.macdesktop}{
        .banner{
            min-height: 350px;
            .goBack{
                width: 130px;
                margin-left: 40px;
                .backIcon{
                    width: 24px;
                }
                .backText{
                    font-size: 1.1rem;
                }
            }
            .bannerInformation{
                .networkContainer{
                    
                    }
                }
                .data{
                    .firstFile, .lastFile{ 
                        .item{
                            .city{
                                font-size: 1.3rem;
                            }
                            
                        }
                         .items{
                            .share{
                                margin-left: 20px;
                                margin-top: 2px;
                                .shareIcon{
                                    width: 19px;
                                    height: 19px;
                                }
                            }
                            .subItem{
                                .language{
                                    font-size: 1.3rem;
                                }
                                .score{
                                    margin-left: 20px;
                                } 
                                .star{
                                    width: 21px;
                                    height: 21px;
                                    margin-left: 6px;
                                    margin-right: 0px;
                                }
                            }
                        } 
                        .categories{
                            font-size: 1.2rem;
                        } 
                    }
                    .descriptionContainer{   
                        .descriptionContent{
                            max-height: 115px;
                        } 
                    }
                }
            }
        }
        .myProfileBody{
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .title{
                    h3{
                        //font-size: 2.4rem;
                    }
                }
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .productName{
                            //font-size: 1.6rem;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
            .productsCardsContainer{
                margin-top: 80px;
                    
            }
        }
    }

    ${media.desktop}{
        .banner{
            min-height: 310px;
            padding: 20px 20px ;
            .goBack{
                width: 120px;
                margin-left: 40px;
                .backIcon{
                    width: 20px;
                }
                .backText{
                    font-size: 1rem;
                }
            }
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        .name{
                            font-size: 2.4rem;
                        }
                        .checkContainer{
                            width: 23px;
                        }
                        .likeInfluencerContainer{
                            width: 35px;
                            height: 35px;
                        }
                        .likeCompanyContainer{
                            width: 35px;
                        }                 
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.2rem;
                            }
                        }
                        .items{
                            .share{
                                .shareIcon{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.2rem;
                                }
                                .score{
                                    font-size: 1.1rem;
                                }
                                .star{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .categories{
                            font-size: 1.1rem;
                        }
                    }
                    .descriptionContainer{
                        .descriptionEmpty{
                            width: 100%;
                            .item{
                                hr{
                                    margin-top: 20px;  
                                }
                                hr:first-child{
                                    margin-top: 5px;  
                                }
                            }

                        }
                        .descriptionContent{
                            p{
                                font-size: 1.1rem;
                            }
                        } 
                    }
                }
            }
            
        }

        .myProfileBody{
            padding:30px 70px 30px 70px ;
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .title{
                    h3{
                        font-size: 2.3rem;
                    }
                }
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .likeProductContainer{
                            top: 15px;
                            right: 15px;
                            width: 30px;
                            height: 30px;   
                        }
                        .productName{
                            //font-size: 1.4rem;
                            font-size: 1.3rem;
                        }
                    }
                    .notContent{
                        height: 250px;
                        p{
                            font-size: 1.1rem;
                        }
                    }
                }
            }
            .productsCardsContainer{
                margin-top: 100px;
                    
            }
        }

    }

    ${media.mediumDesktop}{

        .banner{
            .bannerInformation{
                .networkContainer{
                    width: 55%;
                }
                .data{
                    padding-left: 0;
                    margin-right: 20px;
                    .fileName{
                        .name{
                            font-size: 2.3rem;
                        }
                        .checkContainer{
                            width: 22px;
                        } 
                        .likeInfluencerContainer{
                            width: 32px;
                            height: 32px;
                        }
                        .likeCompanyContainer{
                            width: 32px;
                        }                
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.1rem;
                            }
                        }
                        .items{
                            .share{
                                .shareIcon{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.1rem;
                                }
                                .score{
                                    font-size: 1.1rem;
                                }
                                .star{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .categories{
                            font-size: 1rem;
                        }
                    }
                    .descriptionContainer{
                        .descriptionContent{
                            p{
                                font-size: 1rem;
                            }
                        } 
                    }
                }
            }
        }
        .myProfileBody{
            padding:30px 40px 30px 40px ;
        }
    }

    ${media.smallDesktop}{

        .banner{
            padding: 20px 20px ;
            
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    margin-right: 20px;
                    .fileName{
                        .name{
                            font-size: 2.2rem;
                        }
                        .checkContainer{
                            width: 22px;
                        } 
                        .likeInfluencerContainer{
                            width: 32px;
                            height: 32px;
                        }
                        .likeCompanyContainer{
                            width: 32px;
                        }                
                    }
                    .firstFile, .lastFile{
                        .item{
                            .city{
                                font-size: 1.1rem;
                            }
                        }
                        .items{
                            .share{
                                .shareIcon{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.1rem;
                                }
                                .score{
                                    font-size: 1.1rem;
                                }
                                .star{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .categories{
                            font-size: 1rem;
                        }
                    }
                    .descriptionContainer{
                        .descriptionContent{
                            p{
                                font-size: 1rem;
                            }
                        } 
                    }
                }
            }
            
        }

        .myProfileBody{
            padding:30px 40px 30px 40px ;
            .collaborationsCardsContainer, .galeryCardsContainer,  .productsCardsContainer{ 
                .title{
                    h3{
                        font-size: 2.1rem;
                    }
                }
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        .likeProductContainer{
                            top: 12px;
                            right: 12px;
                            width: 28px;
                            height: 28px;   
                        }
                        .productName{
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }

    }


    ${media.laptop}{
        .banner{
            .goBack{
                margin-left: 30px;
            }
            .bannerInformation{
                flex-direction: column;
                .networkContainer{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    
                    .socialNetworksProfileContainer{
                        display: none;
                    }
                    
                }

                .socialNetworksProfileContainerMobile{
                    width: 100%;
                    font-size: 1em;
                    padding: 0;
                    margin-top: 1em;
                    display: flex;
                    color: #ffffff;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: .5em;
                }

                .data{
                    width: 100%;
                    margin-right: 0px;
                    align-self: center;
                    margin-top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .fileName{
                        //align-items: center;
                        justify-content: center;
                        text-align: center;
                        width: 100%;
                        .name{
                            font-size: 2.3rem;
                        }
                        .checkContainer{
                            width: 24px;
                            height: 24px;
                        }
                        .likeInfluencerContainer{
                            width: 34px;
                            height: 34px;
                        }
                        .likeCompanyContainer{
                            width: 34px;
                            height: 34px;
                        }
                        
                    }
                    .firstFile, .lastFile{
                        flex-direction: column;
                        font-size: 1.2rem;
                        .star{
                            margin-left: 10px;
                            margin-right: 25px;
                            width: 18px;
                            height: 18px;
                        }
                        .item{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 10px;
                            .city{
                                color: ${colors.hightlight};
                                font-size: 1.3rem;
                                font-weight: 600;
                            }
                        }
                        .items{
                            .share{
                                margin-top: 2px;
                                .shareIcon{
                                    width: 17px;
                                    height: 17px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.3rem;
                                }
                                .score{
                                    font-size: 1.3rem;
                                }
                                .star{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .categories{
                            color: ${colors.hightlight};
                        }
                    }
                    .descriptionContainer{
                        margin: 10px 0;
                        .descriptionEmpty{
                            .item{
                                display: flex;
                                width: 60vw;
                                flex-direction: column;
                                hr{
                                    margin-bottom: 10px;
                                    
                                }     
                            }
                        }
                        .descriptionContent{
                            width: 80vw;
                            p{
                                font-size: 1rem;
                                text-align: center;
                            }
                        }
                    }
                    .lastFile{
                        flex-direction: row;
                    }

                }
            }
        }  
        .myProfileBody{
            .productsCardsContainer{
                margin-top: 10px;
                  
            }
            
        }  
        
    }

    ${media.tablet}{
        .myProfileBody{
            padding:30px;
            .collaborationsCardsContainer, .productsCardsContainer, .galeryCardsContainer{
                .title{
                    h3{
                        font-size: 2.3rem;
                    }
                }
                .collaborationCards, .galeryCards, .productsCards{
                    .front{
                        width: 31%;
                        height: 28vw;
                        .likeProductContainer{
                            top: 10px;
                            right: 10px;
                        } 
                    }
                } 
            }
        }
    }

    ${media.largeMobile}{
        .banner{
            .goBack{
                margin-left: 10px;
            }
            .bannerInformation{
                .networkContainer{
                    
                }
                .data{
                    .fileName{
                        margin-bottom: 15px;
                        width: 90vw;
                        .name{
                            font-size: 1.6rem;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            //width: 100%;
                        }
                        .checkContainer{
                            width: 18px;
                            height: 18px;
                            margin-left: 10px;
                        }
                        .likeInfluencerContainer{
                            width: 28px;
                            height: 28px;
                            margin-left: 10px;
                        }
                        .likeCompanyContainer{
                            width: 28px;
                            height: 28px;
                            margin-left: 10px;
                        }
                    }
                    .firstFile, .lastFile{
                        flex-direction: column;
                        .star{
                            margin-left: 6px;
                            width: 16px;
                            height: 16px;
                        }
                        .item{
                            .city{
                                font-size: 1.1rem;
                            }
                        }
                        .items{
                            .share{
                                .shareIcon{
                                    width: 18px;
                                }
                            }
                            .subItem{
                                .language{
                                font-size: 1.1rem;
                                }
                                .score{
                                    font-size: 1.1rem;
                                }
                                .star{
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        .categories{
                            font-size:1rem;
                        }
                    }
                    .descriptionContainer{
                        margin: 10px 0;
                        .descriptionEmpty{
                            .item{
                                display: flex;
                                width: 60vw;
                                flex-direction: column;
                                hr{
                                    margin-bottom: 5px;
                                    
                                }     
                            }
                        }
                        .descriptionContent{
                            p{
                                font-size: 1rem;
                                display: block;
                                display: -webkit-box;
                                max-width: 100%;
                                max-height: 100px;
                                margin: 0 auto;
                                line-height: 1.3;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
        .myProfileBody{
            padding:30px 20px 30px 20px ;
            .collaborationsCardsContainer, .productsCardsContainer, .galeryCardsContainer{
                .title{
                    h3{
                        font-size: 1.8rem;
                        color: ${colors.title};
                    }
                }
                .collaborationCards, .productsCards, .galeryCards{
                    display: flex;
                    .front{
                        width: 48%;
                        height: 41vw;
                        .likeProductContainer{
                            top: 8px;
                            right: 8px;
                            width: 22px;
                            height: 22px;
                        }
                        .productName{
                            font-size: 1rem;
                        }
                        .companyIcon{
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }

`

export const ProfileImage = styled.div`

    display: flex;
    flex-direction: column;
    margin: auto;
    width: 650px; 
    height: 650px;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-position: 50%;
    position: absolute;
    z-index: 50;

    .imageEdit{
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        padding-right: 22%;
        .editPhoto{
            cursor: pointer;
            width: 60px;
        }
        input[type="file"]{
            display: none;
        }
    }

    .playIconContainer{
        display: flex;
        width : 100%;
        height: 100%;
        justify-content: center;
        align-items: end;
    }
               
    .play{
        border-radius: 50%;
        cursor: pointer;
        width : 120px;
        height: 120px;
        margin-bottom: -3.5em;
    }

    /**--------Responsive-------- */
    ${media.extralargeDesktop}{
        width: 550px; 
        height: 550px;

        .imageEdit{
            .editPhoto{
                width: 45px;
            }
        }
        .play{
            width : 100px;
            height: 100px;
            margin-bottom: -3em;
        }

        
    }

    ${media.largeDesktop}{
        width: 450px; 
        height: 450px;

        .imageEdit{
            .editPhoto{
                width: 40px;
            }
        }
        .play{
            width : 90px;
            height: 90px;
            margin-bottom: -2.75em;
        }
    }

    ${media.specialScreen}{
        width: 380px; 
        height: 380px;
        
        .imageEdit{
            .editPhoto{
                width: 35px;
            }
        }
        .play{
            width : 70px;
            height: 70px;
            margin-bottom: -2.25em;
        }
    }

    ${media.bigmacdesktop}{
        width: 360px; 
        height: 360px;
        
        .imageEdit{
            .editPhoto{
                width: 33px;
            }
        }
        .play{
            width : 60px;
            height: 60px;
            margin-bottom: -1.75em;
        }
    }

    ${media.macdesktop}{
        width: 340px; 
        height: 340px;

        .imageEdit{
            .editPhoto{
                width: 28px;
            }
        }
        .play{
            width : 55px;
            height: 55px;
            margin-bottom: -1.5em;
        }
    }

    ${media.desktop}{
        width: 300px; 
        height: 300px;

        .imageEdit{
            .editPhoto{
                width: 23px;
            }
        }
        .play{
            width : 50px;
            height: 50px;
        }
    }

    ${media.mediumDesktop}{
        width: 290px; 
        height: 290px;
    }

    ${media.smallDesktop}{
        width: 280px; 
        height: 280px;

        .imageEdit{
            .editPhoto{
                width: 25px;
            }
        }
    }

    ${media.laptop}{
        position: initial;

        .profilePhotoContainer{
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
        
        .imageEdit{
            .editPhoto{
                width: 15px;
                height: 15px;
            }
        }
    }

    ${media.largeMobile}{
        width: 180px;
        height: 180px;
        
        .imageEdit{
            .editPhoto{
                width: 12px;
            }
        }
        .play{
            width : 40px;
            height: 40px;
            margin-bottom: -1.25em;
        }
    }
`

export const NetworkCount = styled.div`
        position: relative;
        text-align: end;
        width: ${(props) => props.count < networkRanges.rango1 ? '40%' : props.count < networkRanges.rango2 ? '50%' : props.count < networkRanges.rango3 ? '60%' : props.count < networkRanges.rango4 ? '70%' : props.count < networkRanges.rango5 ? '80%' : '90%'};
        height: 80px;
        margin-top: 10px;
        

        /**----------Responsive---------- */
        ${media.extralargeDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '50%' : props.count < networkRanges.rango2 ? '58%' : props.count < networkRanges.rango3 ? '66%' : props.count < networkRanges.rango4 ? '74%' : props.count < networkRanges.rango5 ? '82%' : '90%'};
            height: 70px;
        }

        ${media.largeDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '45%' : props.count < networkRanges.rango2 ? '53%' : props.count < networkRanges.rango3 ? '61%' : props.count < networkRanges.rango4 ? '69%' : props.count < networkRanges.rango5 ? '76%' : '90%'};
            height: 65px;
        }

        ${media.specialScreen}{
            width: ${(props) => props.count < networkRanges.rango1 ? '40%' : props.count < networkRanges.rango2 ? '50%' : props.count < networkRanges.rango3 ? '60%' : props.count < networkRanges.rango4 ? '70%' : props.count < networkRanges.rango5 ? '80%' : '90%'};
            height: 42px;
        }

        ${media.bigmacdesktop}{
            height: 40px;
        }

        ${media.macdesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '38%' : props.count < networkRanges.rango2 ? '48%' : props.count < networkRanges.rango3 ? '58%' : props.count < networkRanges.rango4 ? '68%' : props.count < networkRanges.rango5 ? '78%' : '90%'};
            height: 38px;
        }

        ${media.desktop}{

            height: 35px; 
            margin-top: 5px;
        }
        ${media.mediumDesktop}{
            width: ${(props) => props.count < networkRanges.rango1 ? '44%' : props.count < networkRanges.rango2 ? '53%' : props.count < networkRanges.rango3 ? '61%' : props.count < networkRanges.rango4 ? '69%' : props.count < networkRanges.rango5 ? '76%' : '90%'};
            height: 33px;
        }

        ${media.smallDesktop}{
            height: 31px;
        }
                    
`