import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const TermsAndConditionsContainer = styled.div`

    border-top: 1px solid ${colors.grey};
    padding: 20px;
    .goBack{
        display: none;
    }
     .termsHeader{
        padding: 0 50px;
         .title{
            font-size: 2rem;
            margin-bottom: 10px;
            color: ${colors.title};
        }
        .update{
            background-color: ${colors.hightlight};
            color: #ffffff;
            width:350px;
            padding: 8px;
            border-radius: 5px;
            margin-bottom: 20px;
            text-align: center;
        } 
    } 
    .termsContainer{
        padding: 10px 80px;
        .privacyBody{
            color: ${colors.text};
            span{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-wrap: wrap;
                h2{
                    margin: 20px 0;
                }
                div{
                    background-color: ${colors.hightlight};
                    color: #ffffff;
                    padding: 8px 25px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    text-align: center;
                    align-self: flex-start;
                }
                h3{
                    margin-bottom: 20px;
                    margin-top: 30px;
                }
                p{
                    margin: 10px 0; 
                    text-align: justify;
                }
                h4{
                    font-weight: 700;
                }
                table, td, th {
                    border: 1px solid;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                td{
                    padding: 10px;
                }
                .subIndex{
                    margin-left: 20px; 
                }
            }

            p:has(> img) {
                display: flex;
                justify-content: center;
                width: 100%;

                img {
                    width: 60%;
                }
            }
        }
        /* .menu{
            position: sticky;
            top: 10px;
            background-color: ${colors.modalBackground};
            padding: 10px;
            border-radius: 10px;
            height: initial;
            width: 30%;
            padding: 20px;
            color: ${colors.greyLetter};
            .option{
                cursor: pointer;
                padding: 3px 0;
                font-weight: 600;
                font-size: 1rem;
            }
            .option:hover{
                color: ${colors.hightlight};
            }
            h4.option{
                padding-left: 20px;
            }

        }
        .content{
            color: ${colors.text};
            width: 80%;
            h3{
                margin-bottom: 20px;
                margin-top: 30px;
            }
            p{
                margin: 10px 0;
                
            }
            h4{
                font-weight: 600;
            }
        } */
    }
    

    /**-----------Responsive----------- */
    ${media.largeDesktop}{
        .termsHeader{
            padding: 0 60px;
        }
        .termsContainer{
            padding: 20px 60px;
            
        }
    }

    ${media.desktop}{
        .termsHeader{
            padding: 0 40px;
        }
        .termsContainer{
            padding: 10px 40px;
            
        }
    }

    ${media.smallDesktop}{
        .termsHeader{
            padding: 0 25px;
        }
        .termsContainer{
            padding: 0px 25px;
            
        }
    }


    ${media.laptop}{
        .goBack{
            display: flex;
            padding-left: 30px;
            padding-bottom: 10px;
            cursor: pointer;
            .backIcon{
                padding-right: 10px;
            }
        }

        .termsContainer{
            display: block;
            .menu{
                float: right;
            }
            
        }
    }

    ${media.largeMobile}{
        
        .goBack{
            padding-left: 10px;
        }
        .termsHeader{
            padding: 0 10px;
            .title{
                font-size: 1.7rem;
            }
            .update{ 
                width:200px;
                font-size: 0.7rem;
            }
        } 
        .termsContainer{
            padding: 7px ;
            display: block;
            /* .menu{    
                float: right;
                width: 100%;
                margin-bottom: 20px;
            } */
            .privacyBody{
                span{
                    table{
                        font-size:0.8rem;
                    }
                    td{
                        padding: 2px;
                    }
                }
            }
        }
    }
`