import React from "react";
import { ModalSendCollaborationContainer } from "./ModalSendCollaborationStyled";
import { FormattedMessage } from 'react-intl';

import hourglass from './../../utils/images/hourglass.svg';
import closeoutside from './../../utils/images/close_circle_outline.svg';
import close from './../../utils/images/close_circle.svg';
import { useNavigate } from 'react-router-dom';

export default function ModalSendCollaboration() {

    const navigate = useNavigate();

    return (
        <ModalSendCollaborationContainer>

            <div className="confirmBody">

                <img src={hourglass} alt="time icon" />
                <p className="text1"><FormattedMessage id="modal_send_collaboration_text_1" defaultMessage="Hemos enviado tu solicitud de colaboración" /></p>
                <p className="text2"><FormattedMessage id="modal_send_collaboration_text_2" defaultMessage="¡Recibirás noticias en 2 días o menos!" /></p>
                <p className="text3"><FormattedMessage id="modal_send_collaboration_text_3" defaultMessage="¡Mientras espera, consulte y compre colaboraciones con otros influencers!" /></p>
                <button className="closeButton" onClick={() => {
                    navigate(`/mycollaborations`)
                }}><FormattedMessage id="modal_send_collaboration_button" defaultMessage="Cerrar" /></button>

            </div>

        </ModalSendCollaborationContainer>
    )
}