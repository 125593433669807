import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const BASE_URL = API_GATEWAY_URL + '/fluincr-oauth';

export const SignInAPI = {
    signIn: async (email, password) => {
        const response = await axios.post(`${BASE_URL}/login`, {
            username: email,
            password: password
        });
        return response.data;
    },
};