import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import FacebookLogin from 'react-facebook-login';
import Lottie from 'react-lottie';
import * as animationData from './instagram-logo-effect.json';
import facebook from "../../utils/images/facebook.svg";
import { UserContext2 } from "../../context/user/userContext";

export default function InstagramRedirect() {

    const navigate = useNavigate();

    const { userType, userToken} = useContext(UserContext2);

    //const [isLoading, setIsLoading] = useState(true);
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const [showAlertError, setShowAlertError] = useState(false);
    const [showFacebookButtom, setShowFacebookButtom] = useState(false);

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let code = urlParams.get('code');


    useEffect(() => {

        if (userType !== '' && userToken !== null && code !== null) {
            /*getAccessInstagram({ token: userToken, userType: userType, instagramCode: code }).then((responseInstagram) => {
                if (responseInstagram.status === 200) {
                    //PENDING: Si es cuenta profesional redirigir a login de Facebook para obtener sus seguidores
                    setInstagramUserId(true);
                    setInstagramAccountType(responseInstagram.descripcion);
                    setShowInstagramFacebookModal(true);
                    navigate('/myprofile');
                }
            })*/
        }

    }, [userType, userToken])



    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

            {
                //(showFacebookButtom) && (
                //<div className="facebookContent">
                //  <p className="facebookMessage">
                //    <FormattedMessage id="facebook_complete" defaultMessage="Para completar el proceso deberas loguearte con tu cuenta de facebook asociada a Instagram" />
                //  </p>
                //  <button className="continueButton" onClick={() => { navigate('/auth/instagramfacebook') }}><FormattedMessage id="continue" defaultMessage="Continuar" /></button>
                //</div>)
            }

        </NetworkRedirectContainer>

    )
}