import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalAddProductContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .close{
            width: 45px;
            align-self: flex-end;
            position: absolute;
            top:20px;
            right: 20px;
            cursor: pointer;
        }

    .productBody{
        background-color: #ffffff;
        border-radius: 15px;
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        width: 50%;
        .productHeader{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            color: ${colors.darkGrey};
            .goBack{
                display: flex;
                align-items: center;
                width: 120px;
                cursor: pointer;
                .backIcon{
                    margin-right: 10px;
                    width: 18px;
                }
            }
            .addHeaderTitle{
                margin-left: 10%;
           }
            .logo{
                width: 90px;
                margin-left: 5px;
            }
        }
        .information{
            display: flex;
            justify-content: space-between;
            .productData{
                display: flex;
                flex-direction: column;
                width: 50%;
                color: ${colors.grey};
                input, textarea{
                    border: 1px solid ${colors.grey};
                    border-radius: 5px;
                    padding: 10px;
                    outline: none;
                    font-family: 'Open Sans', sans-serif;
                    ::placeholder{
                        color: ${colors.grey};
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 600;
                    }
                }
                input[type="number"]{
                    margin: 0 5px;
                }
                .productName{
                    margin-bottom: 15px;
                }
                .productPrice{
                    display: flex;
                    align-items: center;
                    label{
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        input{
                            width: 70%;
                            margin-left: 5px;
                        }
                    }
                    .value{
                        font-size: 0.8rem;
                    }
                    
                }
                .productDescription{
                    //margin: 15px 0;
                    margin-top: 15px;
                    height: 150px;
                }
                .maxDescription{
                    font-size: 0.8rem;
                    text-align: end;
                    margin-bottom: 15px;
                }
                .extraDetails{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 50px;
                    .deleteProduct{
                        color: ${colors.hightlight};
                        text-decoration: underline;
                        font-size: 0.8rem;
                        cursor: pointer;
                    }
                    .appear{
                        display: flex;
                        .switch {
                            position: relative;
                            display: inline-block;
                            width: 36px;
                            height: 18px;
                        }

                        .switch input {
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }

                        .switch .slider {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #ccc;
                            transition: .4s;
                            border-radius: 18px;
                        }

                        .switch .slider:before {
                            position: absolute;
                            content: "";
                            height: 12px;
                            width: 12px;
                            left: 3px;
                            bottom: 3px;
                            background-color: white;
                            transition: .4s;
                            border-radius: 50%;
                        }

                        input:checked + .slider { 
                            background-color: ${colors.hightlight}; 
                        }  
                        input:checked + .slider:before {
                            transform: translateX(16px);
                        }
                        .appearText{
                            margin-left: 5px;
                            font-size: 0.7rem;
                        }
                    }
                }
            }
            .productImage{
                width: 45%;
                display: flex;
                flex-direction: column;
                justify-content: space-between; 
                .addImageButton{
                    height: 250px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: #ffffff;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: bold;
                    color: ${colors.darkGrey};
                    img{
                        width: 50px;
                        margin-top: 10px;
                    }
                }
                .image-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    //height: 250px;
                    .image-item__btn-wrapper{
                        display: flex;
                        align-self: flex-end;
                        .modify, .delete{
                            border: none;
                            background-color: #ffffff;
                            img{
                                height: 20px;
                                cursor: pointer;
                            }
                        }
                        
                    }
                    .productImage{
                        margin-top: 5px;
                        height: 210px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            max-height: 100%;
                            max-width: 100%;
                        }
                        
                    }
                }
                
                .imageContainer{
                    height: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
                .saveButton{
                    align-self: flex-end;
                    outline: none;
                    cursor: pointer;
                    border-radius: 10px;
                    border: none;
                    height: 35px;
                    padding: 10px 30px;
                    font-size: 1rem;
                    background: ${colors.hightlight};
                    color: #ffffff; 
                    font-weight: bold;
                    font-family: 'Montserrat', sans-serif;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .buttonDisabled{
                    background: ${colors.grey};
                    color: #ffffff; 
                    cursor: not-allowed;
                }
            }
        }
        .saveButtonMobile{
            display: none;
        }
     
    }

    /**--------Responsive---------- */

    ${media.bigmacdesktop}{
        .productBody{
            padding: 30px;
            width: 55%;
            .information{
                .productData{
                    .maxDescription{
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }

    ${media.desktop}{
        .productBody{
            padding: 30px;
            width: 60%;
            .productHeader{
                margin-bottom: 30px;
                .addHeaderTitle{
                    margin-left: 0;
                }
            }
            .information{
                .productData{
                    .productName{
                        margin-bottom: 10px;
                    }
                    .productDescription{
                        margin-top: 10px ;
                        height: 130px;
                    }
                    .maxDescription{
                        font-size: 0.6rem;
                    }
                    .extraDetails{
                    margin-top: 30px;
                    }
                }
            }
            
        }
    }

    ${media.mediumDesktop}{
        .productBody{
            width: 70%;
        }
    }
    
    ${media.smallDesktop}{
        .productBody{
            width: 75%;
        }
    }

    ${media.laptop}{
        .productBody{
            width: 80%;
        }
    }
    
    ${media.tablet}{
        .close{
            display: none;
        }
        .productBody{
            height: 100vh;
            width: 100vw;
            overflow-y: auto;
            .productHeader{   
                flex-direction: column;
                align-items: center;
                .goBack{
                    align-self: flex-start;
                }
                .addHeaderTitle{
                    margin-left: 0;
                    width: 40%;
                    text-align: center;
                }
            }

            .information{
                flex-direction: column-reverse;
                padding: 30px 50px;
                .productData{
                    width: 100%;


                    .extraDetails{
                        .deleteProduct{
                            font-size: 1rem;
                        }
                        .appear{
                            .appearText{
                                font-size: 1rem;
                            }
                        }
                    }
                }
                .productImage{
                    width: 100%;
                    margin-bottom: 30px;
                    .addImage{
                        height: 250px;
                        border: 2px dashed ${colors.grey};
                        border-radius: 20px;
                        img{
                            width: 80px;
                            margin-top: 15px;
                        }
                    }
                    .saveButton{
                        display:none;
                    }
                }
            }
            .saveButtonMobile{
                display: block;
                align-self: center;
                outline: none;
                cursor: pointer;
                border-radius: 10px;
                border: none;
                height: 35px;
                padding: 10px 30px;
                font-size: 1rem;
                background: ${colors.hightlight};
                color: #ffffff; 
                margin-top: 30px;
            }
            .buttonDisabled{
                background: ${colors.grey};
                color: #ffffff; 
                cursor: not-allowed;
            }
        }
    } 

    ${media.largeMobile}{
        .productBody{
            overflow-y: auto;
            .productHeader{   
                margin-bottom: 0;
                .addHeaderTitle{
                    margin-top: 10px;
                    width: 60%;
                    .logo{
                        width: 130px;
                        margin-top: 4px;
                    }
                }
                
            }
            .information{
                padding: 10px 0px;
            }
        }
    }

`

