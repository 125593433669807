import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { CurrencyContext } from "../../context/currencyContext";
import { formatNumber } from "../../services/generalSevices";

export default function Product({ product }) {

    const { currency, convertCurrency } = useContext(CurrencyContext);
    const auxCurrency = currency.symbol;

    const [auxPrice, setAuxPrice] = useState(0);

    useEffect(() => {
        setAuxPrice(convertCurrency(product.price, auxCurrency).toFixed(2));
    }, [currency])

    return (<div className="product">
        <p className="productName">{product.name}</p>
        <img src={product.image} alt="product" className="productImage" />
        <p className="productPrice">{currency.symbol} {formatNumber(auxPrice)}</p>
    </div>)
}