import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-collaboration';

export const CartAPI = {
    // Create
    createCart: async (companyId) => {
        const response = await axiosInstance.post(`${BASE_URL}/cart/${companyId}`);
        return response.data;
    },

    // Read
    getCart: async (companyId) => {
        const response = await axiosInstance.get(`${BASE_URL}/cart/${companyId}`);
        return response.data;
    },

    // Apply discount code
    applyDiscountCode: async (cartId, discountCode) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/apply-coupon/${cartId}?code=${discountCode}`);
        return response.data;
    },

    removeDiscountCode: async (cartId) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/remove-coupon/${cartId}`);
        return response.data;
    },

    // Confirm collaboration without payment
    confirmCart: async (cartId) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/confirm-cart/${cartId}`);
        return response.data;
    },

    // Create payment order
    createOrder: async (cartId) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/create-order/${cartId}`);
        return response.data;
    },

    // Save payment authorization
    saveAuthorization: async (cartId, authorizationCode) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/save-authorization/${cartId}`, authorizationCode);
        return response.data;
    },

    // Update
    updateCart: async (cartId) => {
        const response = await axiosInstance.put(`${BASE_URL}/cart/${cartId}`);
        return response.data;
    },

    // Delete
    deleteCart: async (id) => {
        const response = await axiosInstance.delete(`${BASE_URL}/cart/${id}`);
        return response.data;
    },
};