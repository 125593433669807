import React, { useState, useContext } from "react";
import { MobileMenuContainer } from "./MobileMenuStyled";

import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { UserContext2 } from "../../../context/user/userContext";
import { UserTypes } from "../../../utils/constants/user";



export default function MobileMenu({ setShowMenu }) {


    let navigate = useNavigate();
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);

    const { setUserToken, userType, setUserType, userData, setUserData } = useContext(UserContext2);

    const isAdmin = sessionStorage.getItem('isAdmin');

    const handlerCloseMenu = () => {
        setShowMenu(false)
    }

    const handlerLogout = () => {
        setShowSettingsMenu(false);
        localStorage.removeItem('fluincrToken');
        localStorage.removeItem('fluincrUser');
        sessionStorage.removeItem('fluincrToken');
        sessionStorage.removeItem('fluincrUser');
        setUserToken(null);
        setUserType(null);
        setUserData(null);
        navigate('/');
    }

    return (
        <MobileMenuContainer>
            <div className="outsideMenu" onClick={handlerCloseMenu}>

            </div>
            <div className="lateralMenu">
                <ul className="options">
                    <Link to={`/${userData?.username}`}>
                        <li><FormattedMessage id="burger_menu_option_1" defaultMessage="Mi perfil" /></li>
                    </Link>

                    {(userType === UserTypes.COMPANY && !isAdmin) && (<><hr />
                        <Link to={'/mycart'}>
                            <li><FormattedMessage id="burger_menu_option_2" defaultMessage="Mi carrito" /></li>
                        </Link></>)}


                    <hr />
                    <Link to={'/mycollaborations'}>
                        <li ><FormattedMessage id="burger_menu_option_3" defaultMessage="Colaboraciones" /></li>
                    </Link>

                    <hr />
                    <li onClick={() => { setShowSettingsMenu(!showSettingsMenu) }}><FormattedMessage id="burger_menu_option_4" defaultMessage="Configuración" /></li>
                    <hr />
                    {(showSettingsMenu) && (
                        <div className="settingsMenu" >
                            <Link to='myprofile'>
                                <p className="settingsOption" onClick={() => { setShowSettingsMenu(false) }}><FormattedMessage id="settings_menu_option_1" defaultMessage="Editar mi perfil" /></p>
                            </Link>

                            <hr />
                            <Link to='myaccount'>
                                <p className="settingsOption" onClick={() => { setShowSettingsMenu(false) }}><FormattedMessage id="settings_menu_option_2" defaultMessage="Editar mi cuenta" /></p>
                            </Link>
                            <hr />
                            
                            <p className="settingsOption" onClick={handlerLogout}><FormattedMessage id="settings_menu_option_5" defaultMessage="Cerrar sesión" /></p>
                        </div>
                    )}

                </ul>
            </div>
        </MobileMenuContainer>
    )
}