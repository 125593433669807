import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const ModalVideoCoverSelectorContainer = styled.div`
    position: fixed;
    overflow-y: auto;
    top:0;
    left: 0; 
    height: 100vh;
    width: 100vw;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    align-items: center;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 40px;
    
    .card{
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
        padding: 1em 2em 2em;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 33em;
        min-width: 25em;
        width: 40%;

        .cardHeader {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-around;

            .cardTitleContainer {
                border-bottom: 1px solid #000; 
                width: 50%; 
                padding-bottom: 1em; 
                justify-content: center; 
                align-items: center; 
                display: flex;

                .cardTitle {
                    font-size: 1em;
                    color: #000;
                }
            }
                
            .uploadCoverButtonContainer {
                display: flex;
                flex-direction: row-reverse;
                padding-bottom: 1em;
                width: 50%;

                .uploadCoverButton {
                    font-size: 1em;
                    padding: 0;
                    text-transform: none;    
                    color: ${colors.hightlight};
                    font-weight: bold;
                }
            }
        }

        .text {
            font-size: 0.75em;
            padding-top: .75em;

        }

        .saveButton {
            background-color: ${colors.blueButton};
            width: 350px;
            max-width: 100%;
            text-transform: none; 
            font-wight: bold;
            padding: 1.5em auto;  
            border-radius: 8px;
        }
    }
`