import React, { useRef, useState, useEffect } from 'react';
import { Slider, Box, Typography } from '@mui/material';
import ReactPlayer from 'react-player/lazy';
import { colors } from '../../utils/styles/colors';

const VideoThumbnailSelector = ({ source, onCapture }) => {
  const [selectedTime, setSelectedTime] = useState(0); // Selecciona el segundo 00:01 por defecto
  const [capturedImage, setCapturedImage] = useState('');
  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);
  const canvasRef = useRef(null);

  const handleSliderChange = (event, newValue) => {
    setSelectedTime(newValue);
  };

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(selectedTime, 'seconds');
    }
  }, [selectedTime]);

  const captureFrame = () => {
    if (playerRef && playerRef.current) {
      const video = playerRef.current.getInternalPlayer();
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Comprueba si el video se ha cargado antes de intentar acceder a sus propiedades
      if (video && video.videoWidth && video.videoHeight) {
        // Ajusta el tamaño del canvas para que coincida con el tamaño del video
        canvas.width = 320;
        canvas.height = 320;

        context.drawImage(video, 0, 0, 320, 320);
        const imageData = canvas.toDataURL();
        setCapturedImage(imageData);

        // Llama a la función de callback con la imagen capturada
        if (onCapture) {
          onCapture(imageData);
        }
      }
    }
  };

  // Captura el frame del segundo 00:01 cuando el video está listo
  const handleReady = () => {
    captureFrame();
    setDuration(Math.round(playerRef.current.getDuration()));
  };

  // Formatea el valor del slider como una cadena de tiempo
  const formatSliderValue = (value) => {
    const minutes = Math.floor(value / 60);
    const seconds = Math.round(value % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div style={{margin: '1em 3em'}}>
      <ReactPlayer
        ref={playerRef}
        url={source?.data_url}
        playing={false}
        controls={false} // Oculta los controles del video
        style={{ display: 'none' }}
        width="0" // Oculta el video
        height="0" // Oculta el video
        onSeeked={captureFrame}
        onReady={handleReady} // Añade un manejador para el evento onReady

      />
      {capturedImage && <img src={capturedImage} className="thumbnail-preview" alt="Captured frame"/>}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
       
        <Slider
          value={selectedTime}
          onChange={handleSliderChange}
          aria-labelledby="continuous-slider"
          max={duration}
          style={{ marginLeft: 10, marginRight: 10, color: colors.hightlight }}
          valueLabelDisplay="auto" // Muestra el tooltip
          valueLabelFormat={formatSliderValue} // Formatea el valor del tooltip
        />
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <Typography variant="caption">00:00</Typography>
            <Typography variant="caption">{new Date(duration * 1000).toISOString().substr(14, 5)}</Typography>
        </div>
      </Box>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      
    </div>
  );
};

export default VideoThumbnailSelector;
