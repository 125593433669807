import React, { useState, useEffect } from "react";
import { MyCartContainer } from "./MyCartStyled";
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';

import arrowBack from './../../utils/images/arrow_back.svg';
import cash from './../../utils/images/cash.svg';

import { formatNumber, toUpperCase } from "../../services/generalSevices";
import ModalSendCollaboration from "../../components/ModalSendCollaboration/ModalSendCollaboration";
import { useContext } from "react";

import Spinner from "./../../components/Spinner/Spinner";
import { CartContext } from "../../context/cartContext";
import ModalEditCollaboration from "../../components/ModalEditCollaboration/ModalEditCollaboration";
import { CurrencyContext } from "../../context/currencyContext";
import CollaborationCart from "./CollaborationCart";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "./Checkout";
import Alert from "../../components/Alert/Alert";
import gift from './../../utils/images/gift.svg';
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { CartAPI } from "../../api/collaboration/cart";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { PaymentAPI } from "../../api/payment/payment";



export default function MyCart() {

    const navigate = useNavigate();

    const intl = useIntl();

    const { currency, convertCurrency } = useContext(CurrencyContext);

    const { userToken, userType, userData } = useContext(UserContext2);

    const { cart, setCart } = useContext(CartContext);

    const { countrySelectList } = useContext(StaticDataContext);

    const { 
        sub_total: subTotal, 
        total_discount: discount,
        total_comision: totalFee,
        total_taxes: taxes,
        total_amount: total,
        cupon_code: cuponCode
    } = cart;

    const {
        id,
        name,
        payment_method,
        address: {
            city,
            state,
            street,
            postal_code: postalCode,
            country_id: countryId
        },
        user_contact: {
            phone_number: phone,
            indicative
        }
    } = userData || { address: {}, user_contact: {} };
    
    const [auxSubTotal, setAuxCartTotal] = useState(0);
    const [auxDiscount, setAuxDiscount] = useState(0);
    const [auxTotalFee, setAuxTotalFee] = useState(0);
    const [auxTaxes, setAuxTaxes] = useState(0);

    const [showCollaborationSent, setShowCollaborationSent] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    
    const [cuponCodeAux, setCuponCodeAux] = useState('');

    //const auxCurrency = currency.symbol
    const [showPaypalAlert, setShowPaypalAlert] = useState(false);

    const [showDiscountAlert, setShowDiscountAlert] = useState(false);

    const [hasGifts, setHasGifts] = useState(false);

    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID/* "AQ3VAwI9-jkXYElVGhrBsEq5nGmuqsuAuM9hwZR5Dlm2mn1LI2EcFlvq3V5Pf2DzH84UsHf1g924M22A" */,
        currency: "USD",
        intent: "authorize",
        //intent: "capture",
    };


    useEffect(() => {
        console.log("cart", cart)
        const gifts = cart?.collaborations.some(collaboration => collaboration.gift);
        setHasGifts(gifts);

        setCuponCodeAux(cuponCode || '');
    }, [cart])


    useEffect(() => {

        if (userToken !== null) {
            /*setCart([]);
            getCartCollaboration({ token: userToken }).then((response) => {
                setIsLoading(false)
                setShoppingCartCount(response.length)
                setCart(response);
                setShowCollaborationCart(true);
                setInfluencerName(response[0]?.influincr?.givenName)
                let suma = 0
                for (let i = 0; i < response.length; i++) {
                    suma += response[i].totalPrice
                }
                setCartTotal(suma);
            })*/
        }

    }, [userToken])

    useEffect(() => {
        updateAuxPrice(subTotal, setAuxCartTotal);
    }, [currency, subTotal])

    useEffect(() => {
        updateAuxPrice(discount, setAuxDiscount);
    }, [currency, discount])

    useEffect(() => {
        updateAuxPrice(taxes, setAuxTaxes);
    }, [currency, taxes])

    useEffect(() => {
        if(totalFee != 0){
            updateAuxPrice(totalFee, setAuxTotalFee);
        } else {
            setAuxTotalFee(0);
        }
    }, [currency, totalFee])

    const payCollaboration = () => {
        CartAPI.confirmCart(cart.id).then((response) => {
            setCart({ collaborations: []});
            setShowCollaborationSent(true);
        });
    }


    const handlerDeleteCollaboration = (collaborationId) => {
        if(userType === UserTypes.COMPANY){
            setIsLoading(true)

            CollaborationAPI.deleteCollaboration(collaborationId).then((response) => {
                CartAPI.updateCart(cart?.id).then((updatedCart) => {
                    setCart(updatedCart);
                    setIsLoading(false);
                });
            });
        }
    }

    const applyCouponCode = () => {
        CartAPI.applyDiscountCode(cart.id, cuponCodeAux).then((response) => {
            setCart(response);
        }).catch((error) => {
            handleCuponError();
        });
    }

    const removeCouponCode = () => {
        CartAPI.removeDiscountCode(cart.id).then((response) => {
            setCart(response);
        });
    }

    const handleCuponError = () => {
        setShowDiscountAlert(true);
    }

    function updateAuxPrice(amount = 0, setter) {
        const auxCurrency = currency?.symbol;
        setter(convertCurrency(amount, auxCurrency).toFixed(2));
    }

    return (
        <MyCartContainer>
            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={arrowBack} alt="back" className="backIcon" />
                <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
            </div>
            <div className="cartBody">
                <section className="cartInformation">
                    <h3 className="cartTitle"><FormattedMessage id="cart_title" defaultMessage="Carrito de compras" /></h3>

                    {(cart && cart.collaborations.length > 0) ? (<><div className="influencerData">
                        <div className="address">
                            <p className="addressTitle"><FormattedMessage id="cart_text_1" defaultMessage="Dirección" /></p>
                            <p>{name}</p>
                            <p>{street && `${street}, `}{city ?? ''}</p>
                            <p>
                                {state && `${state}, `}
                                {countrySelectList.find(country => country.value === countryId)?.label}
                                {postalCode && `, ${postalCode}`}
                            </p>
                            <p>{(phone && indicative) && `(+${indicative}) ${phone}`}</p>

                        </div>
                        <div className="paymentMethod">
                            <p className="paymentTitle"><FormattedMessage id="cart_text_2" defaultMessage="Método de pago" /></p>
                            <div className="method">
                                {
                                    ((subTotal) > 0) ?
                                    <>
                                        <img src={cash} alt="card" className="cardIcon" />
                                        <span><FormattedMessage id="cart_text_3_a" defaultMessage="Efectivo" /> {(hasGifts) && (<FormattedMessage id="cart_text_3_b" defaultMessage=" + regalo" />)}
                                        </span>
                                    </> :
                                    <>
                                        <img src={gift} alt="card" className="cardIcon iconGift" />
                                        <span><FormattedMessage id="cart_text_3_c" defaultMessage="Regalo" />
                                        </span>
                                    </>            
                                }
                            </div>
                        </div>
                        <div className="promotionalCode">
                            <p className="codeTitle"><FormattedMessage id="cart_text_4" defaultMessage="Código de descuento" /></p>
                            <div>
                                <input type="text" className="codeInput" /* placeholder= {intl.formatMessage({ id: "cart_input" })} */ value={cuponCodeAux} onChange={(event) => {
                                    setCuponCodeAux(event.target.value)
                                }} />
                                <button className="applyButton" onClick={() => {
                                    applyCouponCode()
                                }}><FormattedMessage id="cart_text_button_1" defaultMessage="Aplicar" /></button>
                            </div>
                        </div>
                    </div>
                        <div className="cartItems">
                            {(cart) && cart.collaborations.map((collaboration) => {
                                return (<CollaborationCart key={collaboration.id} collaboration={collaboration} handlerDeleteCollaboration={handlerDeleteCollaboration} />)
                            })}
                        </div></>) : <div className="emptyCart"><p><FormattedMessage id="cart_text_14" defaultMessage="Aún no has añadido colaboraciones al carrito" /></p></div>}
                </section>

                {(cart && cart.collaborations.length > 0) && (<section className="cartCheckout">
                    <table className="checkout">
                        <tbody>
                        <tr>
                            <th><FormattedMessage id="cart_text_8" defaultMessage="Total" /></th>
                            <th className="price">{currency.symbol}</th>
                            <th className="price">USD</th>
                        </tr>
                        <tr>
                            <td><FormattedMessage id="cart_text_9" defaultMessage="Subtotal" /></td>
                            <td className="price">$ {formatNumber(auxSubTotal)}</td>
                            <td className="price">$ {formatNumber(subTotal)}</td>
                        </tr>
                        <tr>
                            <td><FormattedMessage id="cart_text_10" defaultMessage="Descuento" /></td>
                            <td className="price">$ {formatNumber(auxDiscount)}</td>
                            <td className="price">$ {formatNumber(discount)}</td>
                        </tr>
                        <tr>
                            <td><FormattedMessage id="cart_text_15" defaultMessage="Tarifa" /></td>
                            <td className="price">$ {formatNumber(auxTotalFee)}</td>
                            <td className="price">$ {formatNumber(totalFee)}</td>
                        </tr>
                        <tr className="beforeTotal">
                            <td><FormattedMessage id="cart_text_11" defaultMessage="Impuestos" /></td>
                            <td className="price">$ {formatNumber(auxTaxes)}</td>
                            <td className="price">$ {formatNumber(taxes)}</td>
                        </tr>
                        <tr >
                            <td></td>
                            <td ></td>
                            <td className="totalPrice">$ {formatNumber(total)}</td>
                        </tr>

                        </tbody>
                    </table>
                    <div className="mobilePromotionalCode">
                        <p className="codeTitle"><FormattedMessage id="cart_text_4" defaultMessage="Código de descuento" /></p>
                        <div>
                            <input type="text" className="codeInput" placeholder={intl.formatMessage({ id: "cart_input" })} value={cuponCodeAux} onChange={(event) => {
                                setCuponCodeAux(event.target.value)
                            }} />
                            <button className="applyButton" onClick={() => {
                                applyCouponCode()
                            }}><FormattedMessage id="cart_text_button_1" defaultMessage="Aplicar" /></button>
                        </div>
                    </div>

                    {((total) > 0) ?
                        (payment_method) ? 
                            (<PayPalScriptProvider options={initialOptions}>
                                <Checkout amount={total} setShowCollaborationSent={setShowCollaborationSent} />
                            </PayPalScriptProvider>)
                            : 
                            (<button className="checkoutButton" onClick={() => setShowPaypalAlert(true)}> <FormattedMessage id="cart_text_button_2" defaultMessage="Pagar" /> USD $ {formatNumber(total)}</button>) 
                        : 
                        (<button className="checkoutButton" onClick={payCollaboration}> <FormattedMessage id="cart_text_button_2" defaultMessage="Pagar" /> USD $ 0</button>)}

                    <p className="paypalInfo"><FormattedMessage id="cart_paypal_information_1" defaultMessage="*Facturado en USD (otras monedas son sólo estimaciones). Más información sobre tarifas de cambio de moneda y transacciones no reembolsables en efectivo en " />
                        {/* TODO: Reemplazar link por constante */}
                        <a href={'https://fluincr.com/static/freeplansandpricing'} target="_blank" rel="noreferrer"><FormattedMessage id="cart_paypal_information_2" defaultMessage="tarifas" /></a>
                        <FormattedMessage id="cart_paypal_information_3" defaultMessage="." />
                    </p>
                </section>)}

            </div>

            {(showCollaborationSent) && (<ModalSendCollaboration/>)}

            {(isLoading) && (<Spinner />)}


            {(showPaypalAlert) && (<Alert
                title={<FormattedMessage id="Error_title" defaultMessage="¡Lo sentimos!" />}
                text={<FormattedMessage id="alert_connect_paypal" defaultMessage="Para continuar con esta operación deberas primero vincular tu cuenta de Paypal" />}
                confirmText={<FormattedMessage id="alert_button_paypal" defaultMessage="Conectar Paypal" />}
                onConfirm={() => {
                    sessionStorage.setItem('paypalLoginOrigin', 'cart')
                    PaymentAPI.getAuthorizarionUrl().then(response => {
                        window.location = response?.url;
                    }).catch(error => {
                        console.error("Error getting PayPal authorization URL", error);
                    });
                    /* const myHeader = userToken;
                    const myHeaderKey = 'Authorization';
                    document.cookie = myHeaderKey + '= Bearer ' + myHeader; */
                }}
                setShowAlert={setShowPaypalAlert}
            />)}

            {(showDiscountAlert) && (<Alert
                title={<FormattedMessage id="Error_title" defaultMessage="¡Lo sentimos!" />}
                text={<FormattedMessage id="alert_discount_paypal" defaultMessage="Este cupón ya no se encuentra disponible." />}
                setShowAlert={setShowDiscountAlert}

            />)}

        </MyCartContainer>
    )
}