import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import Lottie from 'react-lottie';
import * as animationData from './youtube-logo-effect.json';
import { YouTubeAPI } from "../../api/social-network/youtube";
import { UserContext2 } from "../../context/user/userContext";

export default function YoutubeRedirect() {

    const navigate = useNavigate();

    const { userData, setUserData, userToken } = useContext(UserContext2);

    //const [isLoading, setIsLoading] = useState(true);
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let code = urlParams.get('code');
    let scopes = urlParams.get('scopes');
    let state = urlParams.get('state');

    const [showAlertError, setShowAlertError] = useState(false);



    useEffect(() => {
        if(userToken){
            YouTubeAPI.validateState(userData?.id, state).then((response) => {
                YouTubeAPI.accessToken(userData?.id, code).then((youtubeSocialNetworkAccount) => {
                    setUserData({
                        ...userData,
                        social_networks: [
                            ...userData?.social_networks,
                            youtubeSocialNetworkAccount
                        ]
                    })
                    navigate(`/${userData?.username}`)
                }).catch(() => {
                    setIsStopped(true);
                    setShowAlertError(true);
                });
            }).catch(() => {
                setIsStopped(true);
                setShowAlertError(true);
            });
        }
    }, [state, userToken])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

            {/* <p>Redirect youtube page</p> */}

            {/*  {(isLoading) && (<Spinner />)} */}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="alert_error_youtube_text" defaultMessage="No pudimos conectar su cuenta de youtube, por favor intente mas tarde." />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="alert_error_network_button" defaultMessage="Ir a mi perfil" />}
                onConfirm={() => { navigate('/myprofile') }}
            />)}

        </NetworkRedirectContainer>

    )
}