import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const MyProfileFirstSectionContainer = styled.div`

    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    //background-color: red;
    .collaborationsContainer{
        display: flex;
        flex-direction: column;
        justify-content: first baseline;
        align-items: center;
        //padding-left: 600px;
        padding-left: 28%;
        position: relative;
        .editCollaborations{
            display: flex;
            align-items: flex-start;
            padding: 0 25px;
            position: relative;
            .collaborations{
                display: flex;
                .collaboration{
                    width: 130px;
                    height: 130px;
                    border-radius:50%;
                    background-color: ${colors.greyIcon};
                    margin: 0 5px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius:50%;
                    }
                }
            }
            .editIcon{
                width: 30px;
                cursor: pointer;
                position: absolute;
                right: 0;
            }
        }
        
        .collaborationText{
            margin-top: 5px;
            color: ${colors.hightlight};
            font-size: 2rem;
            //background-color: green;
        }

        .selectContainer{
            position: absolute;
            top: 200px;
            box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
            border-radius:8px;
            padding: 10px;
            z-index:100;
            width:530px;
            background: #ffffff;
            .apply{                                        
                font-size: 1.3rem;
                color: ${colors.hightlight};
                text-decoration: underline;
                text-align:right;
                position: sticky;
                top:0;
                cursor: pointer;
                align-self: flex-end;
                margin-bottom: 5px;
            } 
        }
    }
    .rateEdit{
        width: 1000px;
        display: flex;
        align-items: flex-start;
        .rateContainer{
            min-width: 95%;
            .rate{
                border-radius: 15px;
                border: 1px solid ${colors.darkGrey};
                padding: 14px 22px;
                margin-bottom: 10px;
                //background-color: blueviolet;
                .rateTitle{
                    font-size: 2.2rem;
                    font-weight: bolder;
                }
                .rateInformation{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .rateDescription{
                        font-size: 1.5rem;
                    }
                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            margin-left: 10px;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                        .addRate{
                            cursor: pointer;
                            text-decoration: underline;
                            font-size: 1.6rem;
                            font-weight: bold;
                        }
                        .value{
                            font-size: 1.6rem;
                            font-weight: bold;
                        }
                        img{
                            width: 40px;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
        .editIcon{
            width: 30px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    
        
     /**----------Responsive------------ */
    ${media.extralargeDesktop}{
        .collaborationsContainer{
            //padding-left: 500px;
            padding-left: 27%;
            .editCollaborations{            
                padding: 0 25px;
                .collaborations{
                    .collaboration{
                        width: 100px;
                        height: 100px;
                    }
                }
                .editIcon{
                    width: 25px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1.7rem;
            }

            .selectContainer{
                top: 160px;
                width:430px;
                /* .apply{                                        
                    font-size: 1.2rem;
                } */ 
            }
        }
        .rateEdit{
            width: 840px;
            .rateContainer{
                min-width: 95%;
                .rate{
                    padding: 12px 20px;
                    margin-bottom: 10px;
                    .rateTitle{
                        font-size: 1.8rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 1.2rem;
                        }
                        div{
                            p{
                                margin-left: 10px;
                                font-size: 0.9rem;
                            }
                            .addRate{
                                font-size: 1.4rem;
                            }
                            .value{
                                font-size: 1.4rem;
                            }
                            img{
                                width: 35px;
                            }
                        }
                    }
                }
            }
            .editIcon{
                width: 25px;
                margin-left: 10px;
            }
        }
    }

    ${media.largeDesktop}{
        .collaborationsContainer{
            //padding-left: 400px;
            padding-left: 24%;
            .editCollaborations{            
                padding: 0 25px;
                .collaborations{
                    .collaboration{
                        width: 95px;
                        height: 95px;
                    }
                }
                .editIcon{
                    width: 25px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1.7rem;
            }
            .selectContainer{
                top: 150px;
                width:400px;
                .apply{                                        
                    font-size: 1.1rem;
                } 
            }
        }
        .rateEdit{
            width: 770px;
            .rateContainer{
                min-width: 95%;
                .rate{
                    padding: 12px 20px;
                    margin-bottom: 10px;
                    .rateTitle{
                        font-size: 1.8rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 1.2rem;
                        }
                        div{
                            p{
                                margin-left: 10px;
                                font-size: 0.9rem;
                            }
                            .addRate{
                                font-size: 1.4rem;
                            }
                            .value{
                                font-size: 1.4rem;
                            }
                            img{
                                width: 30px;
                            }
                        }
                    }
                }
            }
            .editIcon{
                width: 25px;
                margin-left: 10px;
            }
        }
    }
       
    ${media.specialScreen}{
        .collaborationsContainer{
            //padding-left: 340px;
            .editCollaborations{            
                padding: 0 20px;
                .collaborations{
                    .collaboration{
                        width: 75px;
                        height: 75px;
                    }
                }
                .editIcon{
                    width: 20px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1.4rem;
            }

            .selectContainer{
                top: 140px;
                width: 330px;
                .apply{                                        
                    font-size: 1rem;
                } 
            }
        }
        .rateEdit{
            width: 610px;
            .rateContainer{
                min-width: 95%;
                .rate{
                    padding: 7px 16px;
                    margin-bottom: 10px;
                    .rateTitle{
                        font-size: 1.4rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 1rem;
                        }
                        div{
                            .addRate{
                                font-size: 1.1rem;
                            }
                            .value{
                                font-size: 1.1rem;
                            }
                            img{
                                width: 25px;
                            }
                        }
                    }
                }
            }
            .editIcon{
                width: 20px;
                margin-left: 5px;
            }
        }
    }

    ${media.bigmacdesktop}{
        .collaborationsContainer{
            //padding-left: 300px;
            .editCollaborations{            
                padding: 0 20px;
                .collaborations{
                    .collaboration{
                        width: 65px;
                        height: 65px;
                    }
                }
                .editIcon{
                    width: 20px;
                }
            }  
            .collaborationText{
                font-size: 1.2rem;
            }   
            .selectContainer{
                width:280px;
                .apply{                                        
                    font-size: 0.9rem;
                } 
            }     
        }

        .rateEdit{
            width: 580px;
            //background-color: orange;
            .rateContainer{
                .rate{
                    padding: 7px 16px;
                    .rateTitle{
                        font-size: 1.4rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 1rem;
                        }
                        div{
                            .value{
                                font-size: 1.1rem;
                            }
                            img{
                                width: 25px;
                            }
                        }
                    }
                }
                .startCollaboration{
                    height: 75px;
                    font-size: 1.6rem;
                }
            } 
        } 
    }

    ${media.macdesktop}{
        .collaborationsContainer{
            //padding-left: 270px;
            .editCollaborations{            
                padding: 0 20px;
                .collaborations{
                    .collaboration{
                        width: 62px;
                        height: 62px;
                    }
                }
                .editIcon{
                    width: 20px;
                }
            }  
            .collaborationText{
                font-size: 1.2rem;
            }  
            .selectContainer{
                width:270px;
                .apply{                                        
                    font-size: 0.9rem;
                } 
            }       
        }

        .rateEdit{
            width: 575px;
            //background-color: yellow;
            .rateContainer{
                .rate{
                    padding: 7px 16px;
                    .rateTitle{
                        font-size: 1.4rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 1rem;
                        }
                        div{
                            .value{
                                font-size: 1.1rem;
                            }
                            img{
                                width: 25px;
                            }
                        }
                    }
                }
                .startCollaboration{
                    height: 75px;
                    font-size: 1.6rem;
                }
            } 
        } 
    }

    ${media.desktop}{
        .collaborationsContainer{
            //padding-left: 240px;
            .editCollaborations{            
                padding: 0 20px;
                .collaborations{
                    .collaboration{
                        width: 55px;
                        height: 55px;
                    }
                }
                .editIcon{
                    width: 15px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1rem;
            }
            .selectContainer{
                top: 90px;
                width: 250px;
                .apply{                                        
                    font-size: 0.8rem;
                } 
            }
        }
        .rateEdit{
            width: 525px;
            .rateContainer{
                min-width: 95%;
                .rate{
                    padding: 8px 16px;
                    margin-bottom: 10px;
                    .rateTitle{
                        font-size: 1rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 0.7rem;
                        }
                        div{
                            .addRate{
                                font-size: 0.9rem;
                            }
                            .value{
                                font-size: 0.9rem;
                            }
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .editIcon{
                width: 15px;
                margin-left: 5px;
            }
        }
    }

    ${media.mediumDesktop}{
        .collaborationsContainer{
            //padding-left: 240px;
            .editCollaborations{            
                padding: 0 15px;
                .collaborations{
                    .collaboration{
                        width: 50px;
                        height: 50px;
                        margin: 0 3px;
                    }
                }
                .editIcon{
                    width: 13px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1rem;
            }
        }
        .rateEdit{
            width: 490px;
        }
    }


    ${media.smallDesktop}{
        .collaborationsContainer{
            //padding-left: 240px;
            .editCollaborations{            
                padding: 0 15px;
                .collaborations{
                    .collaboration{
                        width: 50px;
                        height: 50px;
                        margin: 0 3px;
                    }
                }
                .editIcon{
                    width: 13px;
                }
            }
            
            .collaborationText{
                margin-top: 5px;
                font-size: 1rem;
            }
        }
        .rateEdit{
            width: 450px;
            .rateContainer{
                min-width: 95%;
                .rate{
                    padding: 4px 10px;
                    margin-bottom: 10px;
                    .rateTitle{
                        font-size: 1rem;
                    }
                    .rateInformation{
                        .rateDescription{
                            font-size: 0.7rem;
                        }
                        div{
                            .addRate{
                                font-size: 0.9rem;
                            }
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .editIcon{
                width: 13px;
                margin-left: 5px;
            }
        }
    }

    ${media.laptop}{
        flex-direction: column;
        .collaborationsContainer{
            padding-left: 0px;
            margin-bottom: 20px;
            .editCollaborations{            
                .editIcon{
                    width: 15px;
                }
            }
        }
        .rateEdit{
            width: 100%;
            justify-content: center;
            .rateContainer{
                min-width: 60%;
            }
            .editIcon{
                width: 15px;
            }
        }
    }

    ${media.largeMobile}{
        
        .rateEdit{
            width: 100%;
            justify-content: center;
            .rateContainer{
                min-width: 90%;
                .rate{
                    .rateInformation{
                        .rateDescription{
                            width:auto;
                        }
                    }
                }
            }
            .editIcon{
                width: 15px;
            }
        }
    }
`