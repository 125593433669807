import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getVerifyToken } from "../../api/adminApi";
import Spinner from "../../components/Spinner/Spinner";
import { AdminRedirectContainer } from "./AdminLoginStyled";
import { FormattedMessage } from 'react-intl';
import Alert from "../../components/Alert/Alert";
import { saveToken } from '../../store/token/action';
import { useDispatch } from "react-redux";
import { UserContext2 } from "../../context/user/userContext";

export default function AdminLoginCollaboration() {

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    let paramUserToken = urlParams.get('token');
    let paramUserType = urlParams.get('userType');
    let idCollaboration = urlParams.get('id_colaboration');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [showErrorAlert, setShowErrorAlert] = useState(false);


    const { setUserToken, setUserType } = useContext(UserContext2);

    useEffect(() => {
        if (paramUserToken !== null && paramUserType !== null) {

            getVerifyToken({ token: paramUserToken, userType: paramUserType }).then((response) => {
                if (response.admin === true) {
                    sessionStorage.setItem('fluincrToken', JSON.stringify(paramUserToken));
                    sessionStorage.setItem('fluincrUser', JSON.stringify(paramUserType));
                    sessionStorage.setItem('isAdmin', true);
                    dispatch(saveToken(paramUserToken))
                    setUserToken(paramUserToken);
                    setUserType(paramUserType);
                    navigate(`/mycollaborations/${idCollaboration}`);
                } else {
                    setShowErrorAlert(true);
                    setIsLoading(false);
                }
            })

        }
    }, [paramUserType, paramUserToken])

    return (
        <AdminRedirectContainer>

            {(isLoading) && (<Spinner />)}

            {(showErrorAlert) && (<Alert
                title={<FormattedMessage id="invalid_user_title" defaultMessage="Usuario invalido" />}
                text={<FormattedMessage id="invalid_user_text" defaultMessage="Por favor verifique e intente de nuevo" />}
                setShowAlert={setShowErrorAlert}
                confirmText={<FormattedMessage id="header_button_text" defaultMessage="Iniciar sesión" />}
                onConfirm={() => { navigate('/signin') }}
            />)}

        </AdminRedirectContainer>

    )
}