import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ImageCropContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 250;
    .pruebaBody{
        background-color: #ffffff;
        height: 90vh;
        //width: 80vw;
        min-width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        .closeButton{
            align-self: flex-end;
            width: 30px;
            cursor: pointer;
        }
        .saveButton{
            outline: none;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            height: 35px;
            padding: 10px 30px;
            font-size: 1rem;
            background: ${colors.hightlight};
            color: #ffffff; 
        }
    }

    /**---------Responsive--------- */
    ${media.tablet}{
        .pruebaBody{
            min-width: 80vw;
        }
    }
`