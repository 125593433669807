import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-social-network';

export const YouTubeAPI = {

    getAuthUrl: async (userId) => {
        const response = await axiosInstance.get(`${BASE_URL}/youtube/auth-url/${userId}`);
        return response.data;
    },

    validateState: async (userId, state) => {
        const response = await axiosInstance.get(`${BASE_URL}/youtube/validate-state/${userId}?state=${state}`);
        return response.data;
    },

    accessToken: async (userId, code) => {
        const response = await axiosInstance.post(`${BASE_URL}/youtube/access-token/${userId}?code=${code}`);
        return response.data;
    },

    revoke: async (userId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/youtube/revoke/${userId}`);
        return response.data;
    }
};