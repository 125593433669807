import React from "react";
import { NetworkIconContainer } from "./NetworkIconStyled";

export default function YoutubeIcon() {
    return (
        <NetworkIconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="42.22" height="29.686" viewBox="0 0 42.22 29.686">
                <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M42.388,9.145a5.305,5.305,0,0,0-3.733-3.757C35.363,4.5,22.16,4.5,22.16,4.5s-13.2,0-16.5.888A5.305,5.305,0,0,0,1.932,9.145C1.05,12.459,1.05,19.373,1.05,19.373s0,6.914.882,10.228a5.226,5.226,0,0,0,3.733,3.7c3.293.888,16.5.888,16.5.888s13.2,0,16.5-.888a5.226,5.226,0,0,0,3.733-3.7c.882-3.314.882-10.228.882-10.228s0-6.914-.882-10.228ZM17.842,25.651V13.1l11.035,6.278L17.842,25.651Z" transform="translate(-1.05 -4.5)" fill="#fff" />
            </svg>
        </NetworkIconContainer>
    )
}