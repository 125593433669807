import React, { useState, useEffect } from "react";
import { ModalSecurityCodeContainer } from "./ModalSecurityCodeStyled";
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";

import close from './../../utils/images/close_circle.svg';
import AuthCode from 'react-auth-code-input';

import Alert from "../Alert/Alert";
import Spinner from './../../components/Spinner/Spinner';
import { SignUpAPI } from "../../api/user/signup";
import { UserAPI } from "../../api/user/user";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CompanyAPI } from "../../api/user/company/company";


export default function ModalSecurityCode({ setShowSecurityCode, body, setShowAlertSuccessful, setShowAlertUserExists, page, setIsEmailVerified, preferenceLanguage }) {

    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const intl = useIntl();

    const [showAlertNewCode, setShowAlertNewCode] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);

    const handleOnChange = (res) => {
        setCode(res);
    };

    useEffect(() => {
        if (code.length > 5) {
            setIsLoading(true);
            UserAPI.validateRegisterEmailVerificationCode(body.email, code).then((response) => {

                if (page === 'MyAccount') {
                    setIsLoading(false);
                    setIsEmailVerified(true);
                    setShowSecurityCode(false);

                } else if (page === 'login') {
                    setIsLoading(false);
                    setIsEmailVerified(true);
                    setShowSecurityCode(false);
                } else {
                    if(body?.user_type_id === 2 /* Influencer */){
                        InfluencerAPI.createInfluencer(body).then((response) => {
                            setIsLoading(false);
                            setShowSecurityCode(false);
                            setShowAlertSuccessful(true);
                            navigate('/signin');
                        
                        }).catch((error) => {
                            setIsLoading(false);
                            setShowSecurityCode(false);
                            setShowAlertUserExists(true);
                            navigate('/signup');
                        })
                    } else {
                        CompanyAPI.createCompany(body).then((response) => {
                            setIsLoading(false);
                            setShowSecurityCode(false);
                            setShowAlertSuccessful(true);
                            navigate('/signin');
                        }).catch((error) => {
                            setIsLoading(false);
                            setShowSecurityCode(false);
                            setShowAlertUserExists(true);
                            navigate('/signup');
                        });
                    }
                }
            }).catch((error) => {
                setIsLoading(false);
                setShowAlertError(true);
            });
        }
    }, [code])

    const sendCodeToEmail = () => {
        setIsLoading(true);
        UserAPI.sendRegisterEmailVerificationCode(body?.email, preferenceLanguage).then((response) => {
            setIsLoading(false);
            setShowAlertNewCode(true);
        }).catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {
        UserAPI.sendRegisterEmailVerificationCode(body?.email, preferenceLanguage)
            .then((response) => {})
            .catch((error) => {
                console.log(error);
            });    
    }, [])

    return (
        <ModalSecurityCodeContainer>

            <img src={close} alt="close" className="closeOutside" onClick={() => { setShowSecurityCode(false) }} />

            <form action="" className="securityBody">
                <div className="header">
                    <h2 className="title"><FormattedMessage id="securityCode_title" defaultMessage="Ingresa tu código de seguridad" /></h2>

                    <img src={close} alt="close" className="close" onClick={() => { setShowSecurityCode(false) }} />
                </div>
                <p className="firstText">
                    <FormattedMessage id="securityCode_text_1" defaultMessage="Hemos enviado un código a " />
                    {body.email}
                    <FormattedMessage id="securityCode_text_2" defaultMessage=", que deberías recibir en los próximos 3 minutos. Por favor, introduzca el código de abajo:" />
                </p>


                <div className="code">
                    <AuthCode onChange={handleOnChange} inputClassName='codeNumber' />
                </div>



                <p className="secondText">
                    <FormattedMessage id="securityCode_text_3" defaultMessage="¿No has recibido el código?" />
                    <span className="resend" onClick={sendCodeToEmail}>
                        <FormattedMessage id="securityCode_text_4" defaultMessage="Reenviar mensaje" />
                    </span>
                </p>

            </form>

            {(showAlertNewCode) && (<Alert
                title={<FormattedMessage id="alert_forward_title" defaultMessage="¡Exitoso!" />}
                text={<FormattedMessage id="alert_forward_text" defaultMessage="Se ha enviado un nuevo código a su correo" />}
                setShowAlert={setShowAlertNewCode}
            />)}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_code_title" defaultMessage="Código incorrecto" />}
                text={<FormattedMessage id="alert_error_code_text" defaultMessage="Por favor verifique e intente de nuevo" />}
                setShowAlert={setShowAlertError}
            />)}

            {(isLoading) && (<Spinner />)}

        </ModalSecurityCodeContainer>
    )
}