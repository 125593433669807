export function validateUserEdit(body, isCompany = false) {
    const {
        username,
        name,
        family_name,
        legal_name,
        gender_id,
        identification_type_id,
        identification_number,
        language_id,
        account,
        birthday,
        user_contact: {
            email,
            second_email,
            indicative,
            phone_number
        },
        address: {
            street,
            city,
            state,
            country_id,
            postal_code
        }
    } = body;

    // Verificar que todos los campos requeridos no sean null ni vacíos
    if (!isCompany && (
        !username || !name || !family_name || !legal_name || !gender_id ||
        !identification_type_id || !identification_number || !language_id ||
        !account || !birthday || !email || !indicative || !phone_number ||
        !street || !city || !state || !country_id || !postal_code)
    ) {
        return false;
    } else if (isCompany && (
        !name || !legal_name || !username || !language_id || !account || 
        !email || !street || !city || !state || !country_id || !postal_code || 
        !identification_type_id || !identification_number )
    ){
        return false;
    }

    // Validar el formato de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) || (second_email && !emailRegex.test(second_email))) {
        return false;
    }

    return true;
}