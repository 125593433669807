import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import Lottie from 'react-lottie';
import * as animationData from './facebook-logo-effect.json';
import { URLplatform } from "../../utils/constants/constants";
import { UserContext2 } from "../../context/user/userContext";


export default function FacebookRedirect() {

    const navigate = useNavigate();

    const { userToken } = useContext(UserContext2);

    //const [isLoading, setIsLoading] = useState(true);
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const [showAlertError, setShowAlertError] = useState(false);
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let code = urlParams.get('code');

    useEffect(() => {

        if (userToken) {
            setTimeout(() => {
                // TODO: validar bien esto
                navigate('/myprofile')
                //window.location.replace(`${URLplatform}/myprofile`);
            }, 2000)

        } else {
            navigate('/error')
        }

    }, [userToken])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

        </NetworkRedirectContainer>

    )
}