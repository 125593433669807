import { FormattedMessage } from 'react-intl';

/* export const securityQuestionOptions = [
    { value: 'p1', label: 'pregunta 1?' },
    { value: 'p2', label: 'pregunta 2?' },
    { value: 'p3', label: 'pregunta 3?' }
] */

export const addressOptionsViejo = [
    { value: 'street', label: <FormattedMessage id="address_option_1" defaultMessage="Calle" /> },
    { value: 'race', label: <FormattedMessage id="address_option_2" defaultMessage="Carrera" /> },
    { value: 'avenue', label: <FormattedMessage id="address_option_3" defaultMessage="Avenida" /> },
    { value: 'diagonal', label: <FormattedMessage id="address_option_4" defaultMessage="Diagonal" /> }
]

export const addressOptions = [
    { value: 'street', label: <FormattedMessage id="address_option_1" defaultMessage="Calle" />, name: 'Street', nombre: 'Calle' },
    { value: 'race', label: <FormattedMessage id="address_option_2" defaultMessage="Carrera" />, name: 'Race', nombre: 'Carrera' },
    { value: 'avenue', label: <FormattedMessage id="address_option_3" defaultMessage="Avenida" />, name: 'Avenue', nombre: 'Avenida' },
    { value: 'diagonal', label: <FormattedMessage id="address_option_4" defaultMessage="Diagonal" />, name: 'Diagonal', nombre: 'Diagonal' }
]

export const identificationTypes = [
    { value: 'nationalId', label: <FormattedMessage id="signup_input_7_type_1" defaultMessage="Documento nacional" /> },
    { value: 'driverLicence', label: <FormattedMessage id="signup_input_7_type_2" defaultMessage="Licencia de conducción" /> },
    { value: 'passport', label: <FormattedMessage id="signup_input_7_type_3" defaultMessage="Pasaporte" /> },
    { value: 'other', label: <FormattedMessage id="signup_input_7_type_4" defaultMessage="Otro" /> }
]

export const identificationTypesCompany = [
    { value: 'nationalId', label: <FormattedMessage id="signup_input_7_type_1" defaultMessage="Documento nacional" /> },
    { value: 'other', label: <FormattedMessage id="signup_input_7_type_4" defaultMessage="Otro" /> }
]

export const primaryLanguageTypes = [
    { value: 'EN', label: <FormattedMessage id="signup_input_9_type_1" defaultMessage="Inglés" /> },
    { value: 'ES', label: <FormattedMessage id="signup_input_9_type_2" defaultMessage="Español" /> },
]

export const contentCollaborationsStatusInfluencer = [
    { value: 1, label: <FormattedMessage id="content_collaborations_status_influencer_1" defaultMessage="Aceptación pendiente" /> },
    { value: 2, label: <FormattedMessage id="content_collaborations_status_influencer_2" defaultMessage="Declinado (cerrado)" /> },
    { value: 3, label: <FormattedMessage id="content_collaborations_status_influencer_3" defaultMessage="Subir contenido" /> },
    { value: 4, label: <FormattedMessage id="content_collaborations_status_influencer_4" defaultMessage="Aprobar contenido" /> },
    { value: 5, label: <FormattedMessage id="content_collaborations_status_influencer_5" defaultMessage="Publicar contenido" /> },
    { value: 6, label: <FormattedMessage id="content_collaborations_status_influencer_6" defaultMessage="Ventana de disputa" /> },
    { value: 7, label: <FormattedMessage id="content_collaborations_status_influencer_7" defaultMessage="En espera" /> },
    { value: 8, label: <FormattedMessage id="content_collaborations_status_influencer_8" defaultMessage="Terminado" /> },
    { value: 9, label: <FormattedMessage id="content_collaborations_status_influencer_9" defaultMessage="Cancelado" /> },
    { value: 10, label: <FormattedMessage id="content_collaborations_status_influencer_10" defaultMessage="Otro" /> }
]

export const contentCollaborationsStatusCompany = [
    { value: 1, label: <FormattedMessage id="content_collaborations_status_company_1" defaultMessage="Aceptación pendiente" /> },
    { value: 2, label: <FormattedMessage id="content_collaborations_status_company_2" defaultMessage="Declinado (cerrado)" /> },
    { value: 3, label: <FormattedMessage id="content_collaborations_status_company_3" defaultMessage="Subir contenido" /> },
    { value: 4, label: <FormattedMessage id="content_collaborations_status_company_4" defaultMessage="Aprobar contenido" /> },
    { value: 5, label: <FormattedMessage id="content_collaborations_status_company_5" defaultMessage="Publicar contenido" /> },
    { value: 6, label: <FormattedMessage id="content_collaborations_status_company_6" defaultMessage="Ventana de disputa" /> },
    { value: 7, label: <FormattedMessage id="content_collaborations_status_company_7" defaultMessage="En espera" /> },
    { value: 8, label: <FormattedMessage id="content_collaborations_status_company_8" defaultMessage="Terminado" /> },
    { value: 9, label: <FormattedMessage id="content_collaborations_status_company_9" defaultMessage="Cancelado" /> },
    { value: 10, label: <FormattedMessage id="content_collaborations_status_company_10" defaultMessage="Otro" /> }
]

export const contentCollaborationsStatusOptions = [
    { value: 1, label: <FormattedMessage id="content_collaborations_status_company_1" defaultMessage="Aceptación pendiente" />, previousStatusAllowed: [] },
    { value: 2, label: <FormattedMessage id="content_collaborations_status_company_2" defaultMessage="Declinado (cerrado)" />, previousStatusAllowed: [] },
    { value: 3, label: <FormattedMessage id="content_collaborations_status_company_3" defaultMessage="Subir contenido" />, previousStatusAllowed: [4,5,6,7,10] },
    { value: 4, label: <FormattedMessage id="content_collaborations_status_company_4" defaultMessage="Aprobar contenido" />, previousStatusAllowed: [3,5,6,7,10] },
    { value: 5, label: <FormattedMessage id="content_collaborations_status_company_5" defaultMessage="Publicar contenido" />, previousStatusAllowed: [3,4,6,7,10] },
    { value: 6, label: <FormattedMessage id="content_collaborations_status_company_6" defaultMessage="Ventana de disputa" />, previousStatusAllowed: [3,4,5,7,10] },
    { value: 7, label: <FormattedMessage id="content_collaborations_status_company_7" defaultMessage="En espera" />, previousStatusAllowed: [3,4,5,6,10]},
    { value: 8, label: <FormattedMessage id="content_collaborations_status_company_8" defaultMessage="Terminado" />, previousStatusAllowed: [3,4,5,6,7] },
    { value: 9, label: <FormattedMessage id="content_collaborations_status_company_9" defaultMessage="Cancelado" />, previousStatusAllowed: [3,4,5,6,7] },
    { value: 10, label: <FormattedMessage id="content_collaborations_status_company_10" defaultMessage="Otro" />, previousStatusAllowed: [3,4,5,6,7] }
]

export const collaborationsStatusOptions = [
    { value: 1, label: <FormattedMessage id="collaboration_status_option_1" defaultMessage="Aceptación pendiente" /> },
    { value: 2, label: <FormattedMessage id="collaboration_status_option_2" defaultMessage="Declinado (Cerrado)" /> },
    { value: 3, label: <FormattedMessage id="collaboration_status_option_3" defaultMessage="Aceptado (abierto)" /> },
    { value: 4, label: <FormattedMessage id="collaboration_status_option_4" defaultMessage="En espera" /> },
    { value: 5, label: <FormattedMessage id="collaboration_status_option_5" defaultMessage="Cerrado" /> }
]