import { createContext, useEffect, useState } from "react";

import Alert from "../components/Alert/Alert";
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { saveToken, deleteToken } from '../store/token/action';
import { useDispatch } from 'react-redux';
import { UserAPI } from "../api/user/user";


export const UserContext = createContext()

/* export default */function UserProvider({ children, saveToken }) {

    const dispatch = useDispatch();

    const [userData, setUserData] = useState({});

    const [userToken, setUserToken] = useState(null);
    const [userType, setUserType] = useState('');


    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyId, setCompanyId] = useState(-1);
    const [companyName, setCompanyName] = useState('');

    const [imageProfile, setImageProfile] = useState();
    const [videoProfile, setVideoProfile] = useState('');

    const [account, setAccount] = useState('');
    const [active, setActive] = useState(true);
    const [verified, setVerified] = useState(false);
    const [statusAccount, setStatusAccount] = useState('');
    const [uniqueName, setUniqueName] = useState('');
    const [auxUniqueName, setAuxUniqueName] = useState('');
    const [email, setEmail] = useState('');
    const [auxEmail, setAuxEmail] = useState('');
    const [secondEmail, setSecondEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoStepVerification, setTwoStepVerification] = useState(false);
    const [securityQuestion, setSecurityQuestion] = useState('');
    const [securityQuestionAnswer, setSecurityQuestionAnswer] = useState('');
    const [indicativePhone, setIndicativePhone] = useState('');
    const [phone, setPhone] = useState('');
    const [typeIdentification, setTypeIdentification] = useState('');
    const [identification, setIdentification] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [legalName, setLegalName] = useState(null);
    const [payment, setPayment] = useState(false);
    const [primaryLanguage, setPrimaryLanguage] = useState('');
    const [colaborations, setColaborations] = useState(false);
    const [promotions, setPromotions] = useState(false);

    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesNombre, setCategoriesNombre] = useState([]);
    const [description, setDescription] = useState(null);
    const [rating, setRating] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState({});
    const [countryCompany, setCountryCompany] = useState({});
    const [state, setState] = useState(null);

    const [products, setProducts] = useState([]);
    const [personalFare, setPersonalFare] = useState();

    const [gallery, setGallery] = useState([]);

    //Social networks
    const [youtubeSuscribers, setYoutubeSuscribers] = useState();
    const [twitterFollowers, setTwitterFollowers] = useState();
    const [instagramUserId, setInstagramUserId] = useState();
    const [facebookFollowers, setFacebookFollowers] = useState();
    const [facebookPageLink, setFacebookPageLink] = useState('');
    const [instagramFollowers, setInstagramFollowers] = useState();
    const [tiktokFollowers, setTiktokFollowers] = useState();
    const [tiktokProfileLink, setTiktokProfileLink] = useState();
    const [instagramAccountType, setInstagramAccountType] = useState();
    const [showInstagramFacebookModal, setShowInstagramFacebookModal] = useState(false);
    const [instagramProfileLink, setInstagramProfileLink] = useState();
    const [followers, setFollowers] = useState();

    const [showAlertInvalidToken, setShowAlertInvalidToken] = useState(false);

    const [collaborators, setCollaborators] = useState([]);

    const [imageProfilePending, setImageProfilePending] = useState(false);
    const [socialNetworkPending, setSocialNetworkPending] = useState(false);
    const [ratePending, setRatePending] = useState(false);
    const [showCompleteProfileModalReminder, setShowCompleteProfileModalReminder] = useState(false);
    const [showCompleteProfileModalReminderSetted, setShowCompleteProfileModalReminderSetted] = useState(sessionStorage.getItem('reminderShowed') || false);

    useEffect(() => {

        /*const type = localStorage.getItem('fluincrUser');
        if (type === null) {
            const typeSession = sessionStorage.getItem('fluincrUser');
            if (typeSession !== null) {
                setUserType(JSON.parse(typeSession));
            }
        } else {
            setUserType(JSON.parse(type));
        }*/
    }, []);


    useEffect(() => {
        if (userToken !== null) {
            /*if (userType === 'influencer') {
                getMyInfluencerAccountData({ token: userToken }).then((response) => {
                    if (response.status === 403) {
                        sessionStorage.removeItem('fluincrToken');
                        localStorage.removeItem('fluincrToken');
                        sessionStorage.removeItem('fluincrUser');
                        localStorage.removeItem('fluincrUser');
                        sessionStorage.removeItem('isAdmin');
                        dispatch(deleteToken());
                        setUserToken(null);
                        setUserType(response.attributes?.typeUser || response.attributes?.userType);
                        setShowAlertInvalidToken(true);
                    } else {
                        setId(response.id);
                        setName(response.givenName);
                        setLastName(response.familyName);
                        setCompanyName(response.nameCompany);
                        setAccount(response.account);
                        setActive(response.attributes?.active);
                        setVerified(response.attributes?.verified);
                        setStatusAccount(response.attributes?.status);
                        setUniqueName(response.username);
                        setAuxUniqueName(response.username);
                        setEmail(response.mail);
                        setAuxEmail(response.mail);
                        setSecondEmail(response.secondMail);
                        setPassword(response.password);
                        setTwoStepVerification(response.attributes?.twoStepVerification);
                        setSecurityQuestion({ name: response.attributes?.securityQuestion, nombre: response.attributes.preguntaSeguridad });
                        setSecurityQuestionAnswer(response.attributes?.securityAnswer);
                        setIndicativePhone(response.indicative);
                        setPhone(response.phoneNumber);
                        setTypeIdentification(response.attributes?.typeId);
                        setIdentification(response.attributes?.numberId);
                        setGender(response.gender);
                        setBirthday(response.birthday);
                        setAddress(response.address);
                        setZipCode(response.postalCode);
                        setLegalName(response.legalName);
                        setPayment(response.attributes.paypal);
                        setColaborations(response.notification?.colaborations);
                        setPromotions(response.notification?.promotions);
                        setPrimaryLanguage(response.attributes.primarylanguage);
                        setCity(response.city);
                        setState(response.state);
                        setCountry(response.country);
                        setRating(response.rating);
                        setImageProfile(response.imageProfile);
                        setVideoProfile(response.videoProfile);

                        if (response.gallery !== null) {
                            setGallery(response.gallery);
                        }

                        setFollowers(response.followers);

                        setTiktokFollowers(response.followers.tiktok);
                        setTiktokProfileLink(response.attributes.tiktok_profile_link);
                        setTwitterFollowers(response.followers.twitter)
                        setYoutubeSuscribers(response.followers.youtube)
                        setFacebookFollowers(response.followers.facebook)
                        
                        setInstagramFollowers(response.followers.instagram)
                        setInstagramUserId(response.idSocialNetwork.instagram);
                        setInstagramAccountType(response.attributes.instagram_account_type);
                        setInstagramProfileLink(response.attributes.instagram_profile_link);
                        setFacebookPageLink(response.attributes.facebook_page_link);

                        getServicesByInfluencer({ influencerId: response.id }).then((responseService) => {
                            setPersonalFare(responseService);
                        });

                        if (response.attributes.collaborators) {
                            setCollaborators(response.attributes?.collaborators);
                        }
                    }
                });
            } else if (userType === 'company') {
                getMyCompanyAccountData({ token: userToken }).then((response) => {
                    if (response.status === 403) {
                        sessionStorage.removeItem('fluincrToken');
                        localStorage.removeItem('fluincrToken');
                        sessionStorage.removeItem('fluincrUser');
                        localStorage.removeItem('fluincrUser');
                        sessionStorage.removeItem('isAdmin');
                        dispatch(deleteToken());
                        setUserToken(null);
                        setUserType(response.attributes?.userType);
                        setShowAlertInvalidToken(true);
                    } else {
                        setId(response.id);
                        setCompanyId(response.id);
                        setCompanyName(response.nameCompany);
                        setAccount(response.account);
                        setActive(response.attributes.active);
                        setVerified(response.attributes.verified);
                        setStatusAccount(response.attributes.status);
                        setUniqueName(response.username);
                        setAuxUniqueName(response.username);
                        setEmail(response.mail);
                        setAuxEmail(response.mail);
                        setSecondEmail(response.secondMail);
                        setPassword(response.password);
                        setTwoStepVerification(response.attributes.twoStepVerification);
                        setSecurityQuestion({ name: response.attributes.securityQuestion, nombre: response.attributes.preguntaSeguridad });
                        setSecurityQuestionAnswer(response.attributes.securityAnswer);
                        setIndicativePhone(response.indicative);
                        setPhone(response.phoneNumber);
                        setTypeIdentification(response.attributes.typeId);
                        setIdentification(response.attributes.numberId);
                        setGender(response.gender);
                        setBirthday(response.birthday);
                        setAddress(response.address);
                        setZipCode(response.postalCode);
                        setLegalName(response.legalName)
                        setPayment(response.attributes.paypal);
                        setColaborations(response.notification.colaborations);
                        setPromotions(response.notification.promotions);
                        setPrimaryLanguage(response.attributes.primarylanguage);
                        setCity(response.city);
                        setState(response.state);
                        setCountry(response.country);
                        setCountryCompany(response.countryCompany);
                        setRating(response.rating);
                        setProducts(response.product);
                        setVideoProfile(response.videoProfile);
                        setTiktokFollowers(response.followers.tiktok);
                        setTiktokProfileLink(response.attributes.tiktok_profile_link);
                        setTwitterFollowers(response.followers.twitter)
                        setYoutubeSuscribers(response.followers.youtube)
                        setFacebookFollowers(response.followers.facebook)
                        setInstagramFollowers(response.followers.instagram)

                        setInstagramUserId(response.idSocialNetwork.instagram);
                        setInstagramAccountType(response.attributes.instagram_account_type);
                        setInstagramProfileLink(response.attributes.instagram_profile_link);
                        setFacebookPageLink(response.attributes.facebook_page_link);

                        setImageProfile(response.imageProfile);
                        setFollowers(response.followers);
                    }
                });
            }*/
        }
    }, [userToken]);


    return (
        <UserContext.Provider value={{
            userToken, setUserToken,
            userType, setUserType,
            id, setId,
            name, setName,
            lastName, setLastName,
            companyName, setCompanyName,
            companyId, setCompanyId,
            imageProfile, setImageProfile,
            account, setAccount,
            active, setActive,
            verified, setVerified,
            statusAccount, setStatusAccount, 
            uniqueName, setUniqueName, 
            auxUniqueName, setAuxUniqueName, 
            email, setEmail, 
            auxEmail, setAuxEmail, 
            secondEmail, setSecondEmail, 
            password, setPassword, 
            twoStepVerification, setTwoStepVerification, 
            securityQuestion, setSecurityQuestion, 
            securityQuestionAnswer, setSecurityQuestionAnswer, 
            indicativePhone, setIndicativePhone, 
            phone, setPhone, 
            typeIdentification, setTypeIdentification, 
            identification, setIdentification, 
            gender, setGender, 
            birthday, setBirthday, 
            address, setAddress, 
            zipCode, setZipCode, 
            legalName, setLegalName, 
            payment, setPayment, 
            promotions, setPromotions, 
            colaborations, setColaborations, 
            languages, setLanguages, 
            categories, setCategories, 
            categoriesNombre, setCategoriesNombre, 
            description, setDescription, 
            rating, setRating, 
            city, setCity, 
            country, setCountry, 
            countryCompany, setCountryCompany, 
            products, setProducts, 
            personalFare, setPersonalFare, 
            videoProfile, setVideoProfile, 
            gallery, setGallery, 
            followers, setFollowers,
            tiktokFollowers, setTiktokFollowers, 
            twitterFollowers, setTwitterFollowers, 
            youtubeSuscribers, setYoutubeSuscribers,
            facebookFollowers, setFacebookFollowers, 
            instagramFollowers, setInstagramFollowers, 
            primaryLanguage, setPrimaryLanguage, 
            collaborators, setCollaborators, 
            state, setState, 
            instagramUserId, setInstagramUserId, 
            instagramAccountType, setInstagramAccountType, 
            showInstagramFacebookModal, setShowInstagramFacebookModal, 
            instagramProfileLink, setInstagramProfileLink, 
            facebookPageLink, setFacebookPageLink, 
            tiktokProfileLink, setTiktokProfileLink, 
            showCompleteProfileModalReminder, setShowCompleteProfileModalReminder,
            showCompleteProfileModalReminderSetted, setShowCompleteProfileModalReminderSetted,
            imageProfilePending, setImageProfilePending,
            socialNetworkPending, setSocialNetworkPending,
            ratePending, setRatePending
        }}>
            {children}
            {(showAlertInvalidToken) && (<Alert
                title={<FormattedMessage id="invalid_user_title" defaultMessage="Usuario invalido" />}
                text={<FormattedMessage id="invalid_user_text" defaultMessage="Por favor verifique e intente de nuevo" />}
                setShowAlert={setShowAlertInvalidToken}
            />)}
        </UserContext.Provider>
    )

}

const mapStateToProps = (state) => {
    return {
        token: state.tokenReducer.token
    }
}

const mapDispatchToProps = {
    saveToken,
    deleteToken
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProvider)