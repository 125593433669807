import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FooterContainer } from "./FooterStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import logo from './../../utils/images/name_logo_white.png';
import facebook from './../../utils/images/networks/facebook.png';
import instagram from './../../utils/images/networks/instagram.png';
import tiktok from './../../utils/images/networks/tiktok.png';
import twitter from './../../utils/images/networks/twitter.png';
import x from './../../utils/images/networks/x.png';
import youtube from './../../utils/images/networks/youtube-white-icon.png';
import linkedin from './../../utils/images/networks/linkedin-white-icon.png';

import dropDown from './../../utils/images/arrow_dropdown.png';
import { LangContext } from "../../context/langContext";
import { getNewsletterEmailToVerified, postEmailToNewsletter } from "../../api/generalApi";
import { toUTCString } from "../../services/dateServices";
import Alert from "../../components/Alert/Alert";
import { FiltersContext } from "../../context/filtersContext";
import { CurrencyContext } from "../../context/currencyContext";
import { contactEN, contactES, fluincrCaresEN, fluincrCaresES } from "../../utils/constants/constants";
import { StaticDataContext } from "../../context/staticDataContext";
import { SocialNetworkName } from "../../utils/constants/socialNetwork";

export default function Footer() {

    const { locale, setLanguage, setCountry } = useContext(LangContext);
    const { countryFilter, setCountryFilter } = useContext(FiltersContext);
    const { currency, setCurrency, handlerCurrency, currenciesList } = useContext(CurrencyContext);
    const { staticPagesEspañol, staticPagesEnglish, legalTextsEspañol, legalTextsEnglish, fluincrSocialNetworkAccountsList } = useContext(StaticDataContext);

    const intl = useIntl();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

    const [showAlertNewsLetter, setShowAlertNewsLetter] = useState(false);
    const [showAlertNewsLetterFail, setShowAlertNewsLetterFail] = useState(false);
    //const [selectedCurrency, setSelectedCurrency] = useState("COP - Colombian Peso");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const [showAlertInvalidEmail, setShowAlertInvalidEmail] = useState(false);

    const [showCountriesOptions, setShowCountriesOptions] = useState(false);

    const [showLenguagesOptions, setShowLenguagesOptions] = useState(false);
    const [selectdLenguage, setSelectedLenguage] = useState(<FormattedMessage id="footer_lenguage_1" defaultMessage="Español" />);

    const [showCurrencyOptions, setShowCurrencyOptions] = useState(false);
    /* const [selectdCurrency, setSelectedCurrency] = useState(<FormattedMessage id="footer_currency_1" defaultMessage="COP- Colombian Peso" />); */


    const [showDataPolicy, setShowDataPolicy] = useState(false);

    const lenguages = [<FormattedMessage id="footer_lenguage_1" defaultMessage="Español" />, <FormattedMessage id="footer_lenguage_2" defaultMessage="Ingles" />];

    /* const currencies = [<FormattedMessage id="footer_currency_1" defaultMessage="Peso col - COP" />, <FormattedMessage id="footer_currency_2" defaultMessage="Dólar can - CAD" />, <FormattedMessage id="footer_currency_3" defaultMessage="Dólar us -USD" />]; */


    useEffect(() => {
        const expRegEmail = new RegExp('[a-z0-9]+@[a-z]+[.][a-z]{2,3}');
        if (expRegEmail.test(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }, [email])

    const sendEmail = (e) => {
        e.preventDefault();
        if (validEmail) {
            getNewsletterEmailToVerified({ email }).then((response) => {
                if (response.length > 0) {
                    setShowAlertNewsLetterFail(true);
                } else {
                    postEmailToNewsletter({
                        body:
                        {
                            deleted: 0,
                            email: email,
                            creation: toUTCString(new Date())
                        }
                    }).then((response) => {
                        if (response.status === 201) {
                            setShowAlertNewsLetter(true);
                        }
                    })
                }
            })
        } else {
            setShowAlertInvalidEmail(true)
        }
    }

    useEffect(() => {
        if (locale === 'es-CO') {
            setSelectedLenguage(<FormattedMessage id="footer_lenguage_1" defaultMessage="Español" />)
        } else {
            setSelectedLenguage(<FormattedMessage id="footer_lenguage_2" defaultMessage="Inglés" />)
        }
    }, [locale])

    const select1Ref = useRef(null);
    const select2Ref = useRef(null);
    const select3Ref = useRef(null);

    useEffect(() => {

        function handleClickOutside(event) {
            if (select1Ref.current && !select1Ref.current.contains(event.target)) {
                setShowLenguagesOptions(false);
            }
            if (select2Ref.current && !select2Ref.current.contains(event.target)) {
                setShowCurrencyOptions(false);
            }
            if (select3Ref.current && !select3Ref.current.contains(event.target)) {
                setShowCountriesOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [select1Ref, select2Ref, select3Ref])

    const handlerCountryFilter = (country) => {
        const auxCountryFilter = [...countryFilter];
        if (auxCountryFilter.includes(country)) {
            const newCountry = auxCountryFilter.filter(count => count !== country);
            setCountryFilter(newCountry);
            navigate('/influencers');
        } else {
            auxCountryFilter.push(country);
            setCountryFilter(auxCountryFilter);
            navigate('/influencers');
        }
    }

    const getSocialNetworkLink = (socialNetwork) => {
        return fluincrSocialNetworkAccountsList?.find(account => account.name === socialNetwork)?.url;
    }

    return (
        <FooterContainer>
            <div className="top">
                <div className="subscribe">
                    <img src={logo} alt="logo" className="logo" />
                    <p>
                        <FormattedMessage id="footer_text" defaultMessage="Compra colaboraciones con influencers profesionales de manera eficiente y segura" />
                    </p>
                    <form action="submit" onSubmit={sendEmail}>
                        <input type="text" value={email} placeholder={intl.formatMessage({ id: "footer_input" })} onChange={(e) => {
                            setEmail(e.target.value)
                        }} />
                        <button>
                            <FormattedMessage id="footer_button _text" defaultMessage="Suscribirme" />
                        </button>
                    </form>
                </div>
                <div className="linksContainer">
                    <div className="links">
                        <h3 className="title">
                            <FormattedMessage id="footer_links_1" defaultMessage="Navegar por Fluincr" />
                        </h3>
                        <div className="list">
                            <p><Link to={"/"}><FormattedMessage id="footer_links_1_text_1" defaultMessage="Home" /></Link></p>
                            <p><Link to={"/signup"}><FormattedMessage id="footer_links_1_text_2" defaultMessage="Regístrate" /></Link></p>
                            <p><Link to={"/signin"}><FormattedMessage id="footer_links_1_text_3" defaultMessage="Inicia sesión" /></Link></p>
                            {/*  <p><Link to={"#"}><FormattedMessage id="footer_links_1_text_4" defaultMessage="Favoritos" /></Link></p> */}
                        </div>
                    </div>
                    <div className="links">
                        <h3 className="title">
                            <FormattedMessage id="footer_links_2" defaultMessage="General" />
                        </h3>
                        <div className="list">
                            {/* <p><Link to={"#"}><FormattedMessage id="footer_links_2_text_1" defaultMessage="Acerca de" /></Link></p> */}
                            <p style={{ textTransform: 'none' }}><a target="_blank" rel="noreferrer" href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN}><FormattedMessage id="footer_links_2_text_2" defaultMessage="Fluincr Cares (ayuda)" /></a></p>

                            <p><a target="_blank" rel="noreferrer" href={(locale === 'es-CO') ? contactES : contactEN}><FormattedMessage id="footer_links_2_text_3" defaultMessage="Contacto" /></a></p>

                            {(locale === 'es-CO') ? (staticPagesEspañol.map((page, key) => {
                                return (
                                    <p key={key}>
                                        <Link to={`/static/${page.path}`}>{page.title}</Link>
                                    </p>
                                )
                            })) : (staticPagesEnglish.map((page, key) => {
                                return (
                                    <p key={key}>
                                        <Link to={`/static/${page.path}`}>{page.title}</Link>
                                    </p>
                                )

                            }))}
                            {/* <p><Link to={"#"}><FormattedMessage id="footer_links_2_text_4" defaultMessage="Venta de publicidad" /></Link></p> */}
                        </div>
                    </div>
                    <div className="links">
                        <h3 className="title">
                            <FormattedMessage id="footer_links_3" defaultMessage="Legal" />
                        </h3>
                        <div className="list">
                            {(locale === 'es-CO') ? (legalTextsEspañol.map((legal, key) => {
                                return (
                                    <p key={key}>
                                        <Link to={`/legal/${legal.path}`}>{legal.title}</Link>
                                    </p>
                                )

                            })) : (legalTextsEnglish.map((legal, key) => {
                                return (
                                    <p key={key}>
                                        <Link to={`/legal/${legal.path}`}>{legal.title}</Link>
                                    </p>
                                )

                            }))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="functionalityContainer">

                <div className="networks">
                    <a href={getSocialNetworkLink(SocialNetworkName.FACEBOOK)} target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href={getSocialNetworkLink(SocialNetworkName.INSTAGRAM)} target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="instagram" />
                    </a>
                    <a href={getSocialNetworkLink(SocialNetworkName.TIKTOK)} target="_blank" rel="noopener noreferrer">
                        <img src={tiktok} alt="linkedin" className="tiktok" />
                    </a>
                    <a href={getSocialNetworkLink(SocialNetworkName.TWITTER)} target="_blank" rel="noopener noreferrer">
                        <img src={x} alt="twitter" width={30}/>
                    </a>
                    <a href={getSocialNetworkLink(SocialNetworkName.YOUTUBE)} target="_blank" rel="noopener noreferrer">
                        <img src={youtube} alt="YouTube" className="youtube" width={37}/>
                    </a>
                    <a href={getSocialNetworkLink(SocialNetworkName.LINKEDIN)} target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" width={30}/>
                    </a>
                </div>


                    <form action="submit">

                        <div className="selectBox" ref={select1Ref}>
                            <div className="select" id="select" onClick={() => setShowLenguagesOptions(!showLenguagesOptions)}>
                                <div className="selected" >
                                    <p className="country">{selectdLenguage}</p>
                                </div>
                                <img src={dropDown} alt="dropdown" className="dropdown" />
                            </div>
                            {
                                (showLenguagesOptions) && (
                                    <div className="options" id="options">
                                        {(lenguages.map((lenguage) => (
                                            <div className="option" key={lenguage} onClick={() => {
                                                setSelectedLenguage(lenguage);
                                                setShowLenguagesOptions(!showLenguagesOptions)
                                                if (lenguage.props.defaultMessage === 'Español') {
                                                    setLanguage('Spanish');
                                                    setCountry('Spanish');
                                                } else {
                                                    setLanguage('English');
                                                    setCountry('English');
                                                }
                                            }}
                                            >
                                                <p className="country">{lenguage}</p>
                                            </div>
                                        )))}
                                    </div>
                                )
                            }
                        </div>

                        <div className="selectBox" ref={select2Ref}>
                            <div className="select" id="select" onClick={() => setShowCurrencyOptions(!showCurrencyOptions)}>
                                <div className="selected" >
                                    {/* <p className="country">{selectedCurrency}</p> */}
                                    <p className="country">{currency?.symbol} - {currency?.name}</p>

                                </div>
                                <img src={dropDown} alt="dropdown" className="dropdown" />
                            </div>
                            {
                                (showCurrencyOptions) && (
                                    <div className="options" id="options">
                                        {(currenciesList.map((currency) => {
                                            if (currency.usedSymbol === true) {
                                                return (
                                                    <div className="option" key={currency} onClick={() => {
                                                        setSelectedCurrency(`${currency.symbol} - ${currency.name}`)
                                                        setShowCurrencyOptions(!showCurrencyOptions)
                                                        handlerCurrency(currency)
                                                    }}>
                                                        <p className="country">{currency.symbol} - {currency.name}</p>
                                                    </div>
                                                )
                                            }
                                        }


                                        ))}
                                    </div>
                                )
                            }

                        </div>
                        {/* <div className="selectBox" ref={select3Ref}>
                            <div className="select" id="select" onClick={() => setShowCountriesOptions(!showCountriesOptions)}>
                                <div className="selected" >
                                    <img src={selectedCountry.image} alt={selectedCountry.name} className="flag" />
                                    <p className="country">{selectedCountry.name}</p>
                                </div>
                                <img src={dropDown} alt="dropdown" className="dropdown" />
                            </div>
                            {
                                (showCountriesOptions) && (
                                    <div className="options" id="options">

                                        {(countriesOptions.map((country) => (
                                            <div className="option" key={country.id} onClick={() => {
                                                setSelectedCountry(country)
                                                setShowCountriesOptions(!showCountriesOptions)
                                                handlerCountryFilter(country.id)
                                            }}>
                                                <img src={country.image} alt='country flag' className="flag" />

                                                <p className="country">{(locale === 'es-CO') ? (country.nombre) : (country.name)}</p>
                                            </div>
                                        )))}
                                    </div>
                                )
                            }
                        </div> */}
                    </form>
                </div>

                <p className="copyright">Copyright © 2024 Fluincr</p>

            </div>

            {(showAlertNewsLetter) && (<Alert
                title={<FormattedMessage id="alert_forward_title" defaultMessage="¡Exitoso!" />}
                text={<FormattedMessage id="alert_newsletter_text_1" defaultMessage="El correo ha sido registrado" />}
                setShowAlert={setShowAlertNewsLetter}
            />)}

            {(showAlertNewsLetterFail) && (<Alert
                title={<FormattedMessage id="alert_forward_title_2" defaultMessage="¡Error!" />}
                text={<FormattedMessage id="alert_newsletter_text_2" defaultMessage="Este correo ya se encuentra registrado" />}
                setShowAlert={setShowAlertNewsLetterFail}
            />)}

            {(showAlertInvalidEmail) && (<Alert
                title={<FormattedMessage id="alert_forward_title_2" defaultMessage="¡Error!" />}
                text={<FormattedMessage id="alert_newsletter_text_3" defaultMessage="Ha ingresado una cuenta de correo invalido" />}
                setShowAlert={setShowAlertInvalidEmail}
            />)}

        </FooterContainer>
    )
}