import React, { useState, useContext } from "react";
import { ModalEditProductContainer } from "./ModalEditProductStyled";

import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import close from './../../utils/images/close_circle_outline.svg';
import back from './../../utils/images/arrow_back.svg';
import logo from './../../utils/images/name_logo_grey.svg';
import camera from './../../utils/images/camera.svg';
import editIcon from './../../utils/images/edit_pink.svg';
import deleteIcon from './../../utils/images/delete_pink.svg';

import ImageUploading from 'react-images-uploading';
import { useEffect } from "react";
import Spinner from "../Spinner/Spinner";
import ImageCrop from "../ImageCrop/ImageCrop";
import { uploadImageS3 } from "../../utils/common/s3";
import { UserContext2 } from "../../context/user/userContext";
import { ProductAPI } from "../../api/product-and-service/product";

export default function ModalEditProduct({ setShowEditProduct, product }) {

    const navigate = useNavigate();
    const intl = useIntl();

    const [productName, setProductName] = useState(product.name);
    const [productPrice, setProductPrice] = useState(product.price);
    const [productDescription, setProductDescription] = useState(product.description);
    const [productLink, setProductLink] = useState(product.link);
    const [showInProfile, setShowInProfile] = useState(product.show_in_profile);
    const [productImage, setProductImage] = useState(product.image);

    const [initialImage, setInitialImage] = useState([product.image]);
    const [image, setImage] = useState('');
    const [newImagesToSend, setNewImagesToSend] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [showImageCrop, setShowImageCrop] = useState(false);

    const { userData, setUserData } = useContext(UserContext2);

    const [products, setProducts] = useState(userData?.profile_data?.products || []);

    useEffect(() => {
        if (products !== userData?.profile_data?.products) {
            setUserData({
                ...userData,
                profile_data: {
                    ...userData.profile_data,
                    products: products
                }
            });
        }
    }, [products]);

    useEffect(() => {
        if (productName !== '' && productPrice !== null && newImagesToSend.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [productImage, productPrice, newImagesToSend])

    const onChange = (imageList, addUpdateIndex) => {
        setNewImagesToSend(imageList);
        setImage(imageList[0].data_url);
    };

    useEffect(() => {
        if (image.length > 0) {
            setShowImageCrop(true)
        } else {
            setShowImageCrop(false)
        }
    }, [image])

    const handleInputPrice = (value) => {
        if (/^\d*$/.test(value)) {
            setProductPrice(value);
        }
    };

    const saveImage = (croppedImgSrc) => {
        setIsLoading(true)
        const auxImage = [...newImagesToSend];
        auxImage[0].data_url = croppedImgSrc;
        setNewImagesToSend(auxImage);
        setIsLoading(false);
        setShowImageCrop(false);
    }

    const saveProduct = () => {

        setIsLoading(true);

        const productBody = {
            id: product.id,
            name: productName,
            description: productDescription,
            image: productImage,
            price: productPrice,
            show_in_profile: showInProfile,
            link: productLink,
            company_id: product?.company_id
        }

        if (newImagesToSend.length > 0) {

            const imageBody = {
                base64File: newImagesToSend[0].data_url.split(',')[1],
                formatFile: newImagesToSend[0].file.type.split('/')[1],
                address: `company/${userData?.account}/product`
            }

            uploadImageS3(imageBody.address, 'image/' + imageBody.formatFile, newImagesToSend[0].file).then((fileLink) => {

                    productBody.image = fileLink;

                    ProductAPI.updateProduct(productBody).then((response) => {
                        const auxProducts = products.filter((prod) => prod.id !== product.id);
                        auxProducts.push(response);
                        setProducts(auxProducts);
                        setUserData({
                            ...userData,
                            profile_data: {
                                ...userData.profile_data,
                                products: auxProducts
                            }
                        });
                        setShowEditProduct(false);
                        setIsLoading(false);
                        setProductImage(fileLink);
                    }).catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                    });

            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
        } else {
            ProductAPI.updateProduct(productBody).then((response) => {
                const auxProducts = products.filter((prod) => prod.id !== product.id);
                auxProducts.push(response);
                setProducts(auxProducts);
                setUserData({
                    ...userData,
                    profile_data: {
                        ...userData.profile_data,
                        products: auxProducts
                    }
                });
                setShowEditProduct(false);
                setIsLoading(false);
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
        }

    }

    const handlerDeleteProduct = () => {
        setIsLoading(true)

        ProductAPI.deleteProduct(product.id).then((response) => {
            const newProducts = products.filter((prod) => prod.id !== product.id);
            setProducts(newProducts)
            setUserData({
                ...userData,
                profile_data: {
                    ...userData.profile_data,
                    products: newProducts
                }
            });
            setShowEditProduct(false)
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            setShowEditProduct(false);
            console.error(error);
        });
    }

    return (
        <ModalEditProductContainer>

            <img src={close} alt="close" className="close" onClick={() => { setShowEditProduct(false) }} />

            <div className="productBody">

                <div className="productHeader">
                    <div className="goBack" /* onClick={() => navigate(-1)} */ onClick={() => { setShowEditProduct(false) }}>
                        <img src={back} alt="go back" className="backIcon" />
                        <p>
                            <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                        </p>
                    </div>
                    <h3><FormattedMessage id="edit_product_title" defaultMessage="Editar producto para promocionar en" /> <img src={logo} alt="logo" className="logo" /></h3>
                </div>

                <div className="information">

                    <div className="productData">
                        <input type="text" maxLength="50"
                            placeholder={intl.formatMessage({ id: "add_product_text_1" })} className="productName" value={productName} onChange={(event) => { setProductName(event.target.value) }} />

                        <div className="productPrice">
                            <label htmlFor="price">
                                <FormattedMessage id="add_product_text_2" defaultMessage="COP $" />
                                {/*  <input type="number" id="price" value={productPrice} onChange={(event) => { setProductPrice(event.target.value) }} /> */}
                                <input type="text" id="price" value={productPrice} onChange={(event) => { handleInputPrice(event.target.value) }} />
                            </label>
                            <p className="value"><FormattedMessage id="add_product_text_3" defaultMessage="Valor del producto/ Servicio" /></p>
                        </div>

                        <textarea name="" id="" maxLength="250" cols="30" rows="10" className="productDescription" value={productDescription} onChange={(event) => { setProductDescription(event.target.value) }}></textarea>
                        <p className="maxDescription"><FormattedMessage id="add_product_text_8" defaultMessage="Max 250 caracteres" /></p>

                        <input type="text" placeholder={intl.formatMessage({ id: "add_product_text_4" })} value={productLink} onChange={(event) => { setProductLink(event.target.value) }} />

                        <div className="extraDetails">
                            <p className="deleteProduct" onClick={handlerDeleteProduct}><FormattedMessage id="add_product_text_5" defaultMessage="Eliminar producto" /></p>

                            <div className="appear">
                                <label className="switch">
                                    <input type="checkbox" checked={showInProfile} onClick={(event) => { setShowInProfile(event.target.checked) }} />
                                    <div className="slider"></div>
                                </label>
                                <p className="appearText"><FormattedMessage id="add_product_text_6" defaultMessage="Aparecer en el perfil" /></p>

                            </div>

                        </div>
                    </div>



                    <div className="productImage">

                        <ImageUploading
                            multiple
                            value={newImagesToSend}
                            onChange={onChange}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (

                                <div className="upload__image-wrapper">

                                    {(initialImage.length === 0 && newImagesToSend.length === 0) && (<button type='button'
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        className="addImageButton"
                                    >
                                        <FormattedMessage id="add_product_text_7" defaultMessage="Cargar producto/ Servicio" />
                                        <img src={camera} alt="camera" />
                                    </button>)}



                                    {(initialImage.length > 0 && newImagesToSend.length < 1) && (<div className="image-item">

                                        <div className="image-item__btn-wrapper">
                                            <button onClick={() => onImageUpdate(0)} className="modify">
                                                <img src={editIcon} alt="remove" />
                                            </button>
                                            <button onClick={() => setInitialImage([])} className="delete">
                                                <img src={deleteIcon} alt="remove" />
                                            </button>

                                        </div>
                                        <div className="productImage" >
                                            <img src={initialImage[0]} alt="product" />
                                        </div>

                                    </div>)}
                                    {(newImagesToSend) && (<div>{imageList.map((image, index) => (
                                        <div key={index} className="image-item">

                                            <div className="image-item__btn-wrapper">
                                                <button onClick={() => onImageUpdate(index)} className="modify">
                                                    <img src={editIcon} alt="remove" />
                                                </button>
                                                <button onClick={() => onImageRemove(index)} className="delete">
                                                    <img src={deleteIcon} alt="remove" />
                                                </button>
                                            </div>
                                            {/* <div className="productImage" >
                                                <img src={image['data_url']} alt="product" />
                                            </div> */}

                                        </div>
                                    ))}</div>)}

                                    {/* {imageList.map((image, index) => (
                                        <div key={index} className="image-item">

                                            <div className="image-item__btn-wrapper">
                                                <button onClick={() => onImageUpdate(index)} className="modify">
                                                    <img src={editIcon} alt="remove" /></button>
                                                <button onClick={() => onImageRemove(index)} className="delete">
                                                    <img src={deleteIcon} alt="remove" />
                                                </button>
                                            </div>
                                            <div className="productImage" >
                                                <img src={image['data_url']} alt="product" />
                                            </div>

                                        </div>
                                    ))} */}
                                </div>
                            )
                            }
                        </ImageUploading>

                        {(newImagesToSend.length > 0) && (<div className="imageContainer" >
                            <img src={newImagesToSend[0].data_url} alt="product" />
                        </div>)}

                        <button className={`saveButton`} onClick={saveProduct} /* disabled={disabled} */>
                            <FormattedMessage id="add_product_button" defaultMessage="Guardar producto" />
                        </button>
                    </div>

                </div>
                <button className={`saveButtonMobile ${disabled && 'buttonDisabled'}`} onClick={saveProduct} /* disabled={disabled} */>
                    <FormattedMessage id="add_product_button" defaultMessage="Guardar producto" />
                </button>
            </div>

            {(showImageCrop) && (<ImageCrop imgSrc={newImagesToSend[0]} setShowImageCrop={setShowImageCrop} setImagesToSend={setNewImagesToSend} setImage={setImage} xRadio={9} yRadio={10} save={saveImage} isLoading={isLoading} />)}

            {(isLoading) && (<Spinner />)}

        </ModalEditProductContainer>
    )
}