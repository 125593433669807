import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { CollaborationButton, CollaborationsDetailsContainer, ProfileImage } from "./CollaborationDetailsStyled";
import { FormattedMessage, useIntl } from 'react-intl';
import arrowBack from './../../utils/images/arrow_back.svg';
import avatar from './../../utils/images/avatar_fluincr.png';
import check from './../../utils/images/check_circle_darker.svg';
import star from './../../utils/images/star.svg';
import giftIcon from './../../utils/images/gift_white.svg';
import chat from './../../utils/images/chat.svg';
import { countdown, newFormat } from "../../services/dateServices";
import { fill, formatNumber } from "../../services/generalSevices";
import ModalDispute from "../../components/ModalDispute/ModalDispute";
import ModalReceivedDispute from "../../components/ModalReceivedDispute/ModalReceivedDispute";
import Spinner from "../../components/Spinner/Spinner";
import Chat from "../../components/Chat/Chat";
import { CurrencyContext } from "../../context/currencyContext";
import CollaborationContent from "./CollaborationContent";
import Select from 'react-select';
import { collaborationsStatusOptions } from "../../utils/constants/general";
import { LangContext } from "../../context/langContext";
import { URLplatform } from "../../utils/constants/constants";
import ModalPayPalLogin from "../../components/ModalPayPalLogin/ModalPayPalLogin";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { StaticDataContext } from "../../context/staticDataContext";
import { CollaborationsContext } from "../../context/collaborationsContext";

export default function CollaborationDetails() {

    const navigate = useNavigate();
    const intl = useIntl();
    const { locale } = useContext(LangContext);
    const { idCollaboration } = useParams();
    const { userToken, userType, userData } = useContext(UserContext2);
    const { currency, convertCurrency } = useContext(CurrencyContext);
    const { countrySelectList } = useContext(StaticDataContext);
    const { userCollaborations, setCurrentCollaboration } = useContext(CollaborationsContext);

    const [timeLeft, setTimeLeft] = useState(0);
    const [showDispute, setShowDispute] = useState(false);
    const [showReceivedDispute, setShowReceivedDispute] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showChat, setShowChat] = useState(false);
    const isAdmin = sessionStorage.getItem('isAdmin');
    const [statusOptions, setStatusOptions] = useState(collaborationsStatusOptions);
    const [collaborationValue, setCollaborationValue] = useState();
    const [openModalPayPalLogin, setOpenModalPayPalLogin] = useState(false);


    const [collaboration, setCollaboration] = useState({});
    const {
        id,
        items = [],
        company = {},
        influencer = {},
        product = {},
        status = {},
        comment,
        document: documentUrl,
        creation_date,
        gift,
        gift_value,
        base_amount,
        total_amount, 
    } = collaboration;

    const [auxCompany, setAuxCompany] = useState({});
    const [auxInfluencer, setAuxInfluencer] = useState({});
    const [auxProduct, setAuxProduct] = useState({});

    const {
        id: idCompany,
        address: addressCompany,
        name: nameCompany,
        username: usernameCompany,
        image_profile: imageProfileCompany,
        rating: ratingCompany,
        verified: verifiedCompany,
        primary_language_id: primaryLanguageCompany,
    } = auxCompany;

    const {
        id: idInfluencer,
        address: adressInfluencer,
        name: nameInfluencer,
        family_name: familyNameInfluencer,
        username: usernameInfluencer,
        image_profile: imageProfileInfluencer,
        rating: ratingInfluencer,
        verified: verifiedInfluencer,
        primary_language_id: primaryLanguageInfluencer,
    } = auxInfluencer;

    const {
        id: idProduct,
        name: nameProduct,
        description: descriptionProduct,
        image: imageProduct,
        price: priceProduct,
        link: linkProduct,
    } = auxProduct;

    const [auxGiftValue, setAuxGiftValue] = useState(0);
    const [auxBaseAmount, setAuxBaseAmount] = useState(0);
    const [auxBaseAmountFinal, setAuxBaseAmountFinal] = useState(0);
    const [auxTotalAmount, setAuxTotalAmount] = useState(0);
    const [auxProductPrice, setAuxProductPrice] = useState(0);

    useEffect(() => {
        if (userToken) {
            setIsLoading(true);

            /*
            // TODO: Refactorizar para obtener la colaboración de la lista de colaboraciones del contexto si ya se ha cargado
            // Y solamente pedir el producto que es la información que no se tiene
            const currentCollaboration = userCollaborations.find(collaboration => collaboration?.id === idCollaboration);
            if (currentCollaboration) {
                setCollaboration(currentCollaboration);
                setAuxCompany(currentCollaboration?.company);
                setAuxInfluencer(currentCollaboration?.influencer);
                setAuxProduct(currentCollaboration?.product);
                setIsLoading(false);
                setCurrentCollaboration(response);
            } 
            */
               
            CollaborationAPI.getCollaboration(idCollaboration).then((response) => {
                console.log("response", response)
                setCollaboration(response);
                setCurrentCollaboration(response);
                setAuxCompany(response?.company);
                setAuxInfluencer(response?.influencer);
                setAuxProduct(response?.product);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error)
                navigate('/error')
            });
        }
    }, [userToken])

    useEffect(() => {

        const statusUpdate = [];
        setCollaborationValue(statusOptions[status.id - 1])
        statusOptions.map((status, index) => {
            if ((status.id === 1) && (index === 3 || index === 4)) {
                statusUpdate.push({ ...status, isDisabled: true })
            } else if (status.id === 3 && (index < 2 || index === 4)) {
                statusUpdate.push({ ...status, isDisabled: true })
            } else if ((status.id === 4) && (index < 2 || index === 4)) {
                statusUpdate.push({ ...status, isDisabled: true })
            } else if (status.id === 5 && index !== 4) {
                statusUpdate.push({ ...status, isDisabled: true })
            } else if (status.id === 0 || status.id === 2) {
                statusUpdate.push({ ...status, isDisabled: true })
            } else {
                statusUpdate.push({ ...status })
            }
        })
        setStatusOptions(statusUpdate);

    }, [status.id])

    useEffect(() => {
        const baseAmount = gift && gift_value > base_amount ? gift_value : base_amount;
        const totalAmount = baseAmount - gift_value > 0 ? baseAmount - gift_value : 0;

        setAuxGiftValue(convertCurrency(gift_value, currency?.symbol).toFixed(2));
        setAuxBaseAmount(convertCurrency(base_amount, currency?.symbol).toFixed(2));
        setAuxBaseAmountFinal(convertCurrency(baseAmount, currency?.symbol).toFixed(2));
        setAuxTotalAmount(convertCurrency(totalAmount, currency?.symbol)?.toFixed(2));
        setAuxProductPrice(convertCurrency(priceProduct, currency?.symbol).toFixed(2));
    }, [collaboration, currency])
   
    const onChangeContent = async (status, index) => {
        if (userToken !== null) {
            setIsLoading(true);

            CollaborationAPI.getCollaboration(idCollaboration).then((response) => {
                console.log("response", response)
                setCollaboration(response);
                setAuxCompany(response?.company);
                setAuxInfluencer(response?.influencer);
                setAuxProduct(response?.product);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error)
                navigate('/error')
            });
        }
    }

    const handlerDecline = () => {
        setIsLoading(true)
        if(userType === UserTypes.INFLUENCER){
            CollaborationAPI.declineCollaboration(id, userData?.id).then((response) => {
                setCollaboration(response);
                setIsLoading(false)
            }).catch((error) => {
                console.error("Error al declinar la colaboración:", error);
                setIsLoading(false)
            });     
        }
    }

    const handlerAccept = () => {
        if((userData?.payment_method || auxTotalAmount < 1 || !auxTotalAmount) && (userType === UserTypes.INFLUENCER)){
            setIsLoading(true)
            CollaborationAPI.acceptCollaboration(id, userData?.id).then((response) => {
                setCollaboration(response);
                setIsLoading(false);
            }).catch((error) => {
                console.error("Error al aceptar la colaboración:", error);
                setIsLoading(false);
            });
        } else {
            setOpenModalPayPalLogin(true);
        }
    }

    //TODO: Refactorizar
    const adminChangeStatus = (newStatus) => {
        /*setIsLoading(true);

        if (newStatus === 4) {
            putCollaborationStatus({ collaborationId: idCollaboration, newStatus: newStatus, token: userToken }).then((response) => {
                if (response.status === 200) {
                    setCollaborationStatus(4);
                } else if (response.status === 409) {
                    setIsLoading(false);
                    window.alert("You don't have permissions to do that action");
                }
            });
        } else {
            putCollaborationStatus({ collaborationId: idCollaboration, newStatus: newStatus, token: userToken }).then((response) => {
                if (response.status === 200) {
                    setCollaborationStatus(newStatus);

                    const collaborationBody = {};

                    if (userType === UserTypes.INFLUENCER) {
                        collaborationBody.readcompany = false;
                    } else {
                        collaborationBody.readinfluencer = false;
                    }

                    patchCollaboration({ collaborationId: idCollaboration, collaborationBody: collaborationBody }).then((response) => {
                        setIsLoading(false);
                    });
                } else if (response.status === 409) {
                    setIsLoading(false);
                    window.alert("You don't have permissions to do that action");
                }
            });
        }*/
    }

    const getInfluencerFormatAddress = () => {
        const { street, city, state, country_id, postal_code } = adressInfluencer;
        const countryName = countrySelectList.find((country) => country.value === country_id)?.label;
        return `${street}, ${city}, ${state}, ${countryName}, ${postal_code}`;
    } 

    const selectStyles = {
        control: (styles) => ({
            ...styles, borderRadius: '10px',
            boxShadow: 'none', height: '30px', minHeight: '34px', flexWrap: 'no-wrap', width: '180px'

        }),
        menu: (styles) => ({
            ...styles, display: 'flex'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        input: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        placeholder: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        singleValue: (styles) => ({
            ...styles, fontSize: '1.3rem', "@media(max-width: 2400px)": {
                fontSize: '1.2rem'
            },
            "@media(max-width: 1440px) and (max-height: 1920px)": {
                fontSize: '1.1rem'
            },
            "@media(max-width: 1366px)": {
                fontSize: '1rem'
            }
        }),
        dropdownIndicator: (styles) => ({
            ...styles, color: '#E8339E'
        }),
        menuList: (styles) => ({
            ...styles, width: '180px'
        })
    };

    return (
        <CollaborationsDetailsContainer>
            <hr />
            <div className="collaborationTop">
                <div className="goBack" onClick={() => navigate(-1)}>
                    <img src={arrowBack} alt="back" className="backIcon" />
                    <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
                </div>
                <div className="collaborationOrder">
                    <p className="order"><FormattedMessage id="collaboration_details_text_1" defaultMessage="Orden" /> #{(id !== undefined) && fill(id, 8)}</p>
                    {(creation_date !== undefined) && (<p className="date">{/* {formatDateLocal(fromUTCtoLocal(collaborationDetails?.creation))} */}{newFormat(creation_date)}</p>)}
                </div>
                

            </div>

            <div className="collaborationHeader">
                {(userType === UserTypes.INFLUENCER) ? (<div className="influencerDate">
                    <p className="influencerName"><FormattedMessage id="collaboration_details_text_2" defaultMessage="Colaboración con" /> <span>{nameCompany}</span>  </p>

                    <div style={{display: 'flex'}}>
                        <a href={`${URLplatform}/${usernameCompany}`}>
                            <ProfileImage image={imageProfileCompany ?? avatar} />
                        </a>

                        {(verifiedCompany) && (<img src={check} alt="check" className="check" />)}

                        {
                            (ratingCompany) &&
                                <div className="rating">
                                    <p className="score">{(ratingCompany?.average) ?? 'N/R'}</p>
                                    <img src={star} alt="star" className="star" />
                                </div>
                        }
                    </div>
                    
                </div>) : (<div className="influencerDate">
                    <p className="influencerName"><FormattedMessage id="collaboration_details_text_2" defaultMessage="Colaboración con" /> <span>{nameInfluencer + " " + familyNameInfluencer}</span>  </p>

                    <div style={{display: 'flex'}}>
                        <a href={`${URLplatform}/${usernameInfluencer}`}>
                            <ProfileImage image={imageProfileInfluencer ?? avatar} />
                        </a>

                        {(verifiedInfluencer) && (<img src={check} alt="check" className="check" />)}

                        {
                            (ratingInfluencer) &&
                                <div className="rating">
                                    <p className="score">{(ratingInfluencer?.average) ?? 'N/R'}</p>
                                    <img src={star} alt="star" className="star" />
                                </div>
                        }
                    </div>
                </div>)}

                <div className="collaborationStatus">

                    <div>
                        {/* TODO: validar que se muestre el contador */}
                        {(status.id == 0) && (<p className="countdown">{countdown({ date: creation_date, days: timeLeft })}</p>)}

                        {(userType === UserTypes.INFLUENCER && status.id === 1 && !JSON.parse(isAdmin)) && (<p className="declineButton" onClick={handlerDecline}><FormattedMessage id="collaboration_details_button_3" defaultMessage="Declinar" /></p>)}

                        {(status.id === 3) && (<p className="declineButton" onClick={() => { setShowDispute(true) }}><FormattedMessage id="collaboration_details_button_5" defaultMessage="Disputa" /></p>)}

                    </div>
                    <div className="collaborationButtonContainer">

                        {(userType === UserTypes.INFLUENCER && !JSON.parse(isAdmin)) ? (<CollaborationButton status={status.id} disabled={status.id !== 1} onClick={handlerAccept}>

                            <p> {(status.id === 1) ? (<FormattedMessage id="collaboration_details_button_2_influencer_1" defaultMessage="Aceptar" />) : ((status.id === 2) ? (<FormattedMessage id="collaboration_details_button_2_influencer_2" defaultMessage="Declinado" />) : (status.id === 3) ? (<FormattedMessage id="collaboration_details_button_2_influencer_3" defaultMessage="Aceptado" />) : (status.id === 4) ? (<FormattedMessage id="collaboration_details_button_2_influencer_4" defaultMessage="En espera" />) : (<FormattedMessage id="collaboration_details_button_2_influencer_5" defaultMessage="Cerrado" />))}
                                <span className="buttonPrice">${formatNumber((gift && auxGiftValue > auxBaseAmountFinal) ? auxGiftValue : auxBaseAmountFinal)}</span>
                            </p>

                            {(gift && gift_value) && (<div className="giftContainer">

                                <span className="subtotalGift">${formatNumber(auxTotalAmount)}</span>
                                <span className="plusGift">+</span>
                                <span className="giftIconContainer"><img src={giftIcon} alt="gift" className="giftIcon" /></span>
                                <span className="giftPrice"> {formatNumber(auxGiftValue)}</span>

                            </div>)}


                        </CollaborationButton>) : (userType === UserTypes.COMPANY && JSON.parse(isAdmin) !== true) ?

                            (<CollaborationButton >
                                <p>
                                    {(status.id === 1) ? (<FormattedMessage id="collaboration_details_button_2_company_1" defaultMessage="Aceptación pendiente" />) : ((status.id === 2) ? (<FormattedMessage id="collaboration_details_button_2_company_2" defaultMessage="Declinado" />) : (status.id === 3) ? (<FormattedMessage id="collaboration_details_button_2_company_3" defaultMessage="Aceptado" />) : (status.id === 4) ? (<FormattedMessage id="collaboration_details_button_2_company_4" defaultMessage="En espera" />) : (<FormattedMessage id="collaboration_details_button_2_company_5" defaultMessage="Cerrado" />))}
                                    <span className="buttonPrice">${formatNumber(auxBaseAmountFinal)}</span>
                                </p>


                                {(gift && gift_value) && (<div className="giftContainer">
                                    <span className="subtotalGift">${formatNumber(auxTotalAmount)}</span>
                                    <span className="plusGift">+</span>
                                    <span className="giftIconContainer"><img src={giftIcon} alt="gift" className="giftIcon" /></span>
                                    <span className="giftPrice"> {formatNumber(auxGiftValue)}</span>
                                </div>)}

                            </CollaborationButton>) : <></>}

                        {/** TODO: PENDING */}
                        {(status.id !== undefined && JSON.parse(isAdmin) === true) && (<div className="selectContainer">
                            <Select options={statusOptions}
                                styles={selectStyles}
                                placeholder={intl.formatMessage({ id: "collaboracion_status_title" })}
                                value={collaborationValue}
                                onChange={(event) => {
                                    adminChangeStatus(event.value);
                                }} />
                        </div>)}
                        
                        <p className="buttonAditional">
                            *<FormattedMessage id="collaboration_button_aditional_info_1" defaultMessage="Conozca las " />
                            <a href={(locale === 'es-CO') ? 'https://fluincr.com/static/planesgratisyprecios' : 'https://fluincr.com/static/freeplansandpricing'} target="_blank" rel="noreferrer"><FormattedMessage id="collaboration_button_aditional_info_2" defaultMessage="tarifas" /></a>
                            <FormattedMessage id="collaboration_button_aditional_info_3" defaultMessage="por pago en efectivo." />
                        </p>
                    </div>
                </div>

            </div>

            {(userType === UserTypes.COMPANY && status.id === 3 && (gift && gift_value)) && (<div className="trackingContainer">
                <div className="influencerData">
                    <p><FormattedMessage id="collaboration_details_traking_text_1" defaultMessage="Destinatario" />: <span className="influencerName">{nameInfluencer} {familyNameInfluencer}</span></p>
                    <p><FormattedMessage id="collaboration_details_traking_text_2" defaultMessage="Dirección" />: <span>{getInfluencerFormatAddress()}</span></p>
                </div>
            </div>)}

            <div className="collaborationBody">
                <section>
                    <div className="productInformation">
                        <div className="productContainer">
                            <img className="productImage" src={imageProduct}/>

                            <div className="description">
                                <p className="productName">{nameProduct}</p>
                                <p className="productPrice">$ {formatNumber(auxProductPrice)}</p>
                                <p className="productDescription">{descriptionProduct}</p>
                            </div>
                        </div>

                        <div className="collaborationStatusMobile">

                            {(userType === UserTypes.INFLUENCER && JSON.parse(isAdmin) !== true) ? (<CollaborationButton status={status.id} disabled={status.id !== 1} onClick={handlerAccept}>
                                <p>
                                    {(status.id === 1) ? (<FormattedMessage id="collaboration_details_button_2_influencer_1" defaultMessage="Aceptar" />) : 
                                    ((status.id === 2) ? (<FormattedMessage id="collaboration_details_button_2_influencer_2" defaultMessage="Declinado" />) : 
                                    (status.id === 3) ? (<FormattedMessage id="collaboration_details_button_2_influencer_3" defaultMessage="Aceptado" />) : 
                                    (status.id === 4) ? (<FormattedMessage id="collaboration_details_button_2_influencer_4" defaultMessage="En espera" />) : 
                                    (<FormattedMessage id="collaboration_details_button_2_influencer_5" defaultMessage="Cerrado" />))} 
                                    <span className="buttonPrice">${formatNumber(auxBaseAmountFinal)}</span>
                                </p>

                                {(gift && (gift_value)) && (<div className="giftContainer">
                                    <span className="subtotalGift">${formatNumber(auxTotalAmount)}</span>
                                    <span className="plusGift">+</span>
                                    <span className="giftIconContainer"><img src={giftIcon} alt="gift" className="giftIcon" /></span>
                                    <span className="giftPrice"> {formatNumber(auxGiftValue)}</span>
                                </div>)}

                            </CollaborationButton>) : (userType === UserTypes.COMPANY && JSON.parse(isAdmin) !== true) ?

                                (<CollaborationButton >
                                    <p>
                                        {(status.id === 1) ? (<FormattedMessage id="collaboration_details_button_2_company_1" defaultMessage="Aceptación pendiente" />) : 
                                        ((status.id === 2) ? (<FormattedMessage id="collaboration_details_button_2_company_2" defaultMessage="Declinado" />) : 
                                        (status.id === 3) ? (<FormattedMessage id="collaboration_details_button_2_company_3" defaultMessage="Aceptado" />) : 
                                        (status.id === 4) ? (<FormattedMessage id="collaboration_details_button_2_company_4" defaultMessage="En espera" />) : 
                                        (<FormattedMessage id="collaboration_details_button_2_company_5" defaultMessage="Cerrado" />))} 
                                        <span className="buttonPrice">${formatNumber(auxBaseAmountFinal)}</span>
                                    </p>

                                    {(gift && (gift_value)) && (<div className="giftContainer">
                                        <span className="subtotalGift">${formatNumber(auxTotalAmount)}</span>
                                        <span className="plusGift">+</span>
                                        <span className="giftIconContainer"><img src={giftIcon} alt="gift" className="giftIcon" /></span>
                                        <span className="giftPrice"> {formatNumber(auxGiftValue)}</span>
                                    </div>)}

                                </CollaborationButton>) : <></>}

                            {(status.id !== undefined && JSON.parse(isAdmin) === true) && (<div className="selectContainer">
                                <Select options={statusOptions}
                                    styles={selectStyles}
                                    placeholder={intl.formatMessage({ id: "collaboracion_status_title" })}
                                    value={collaborationValue}
                                    onChange={(event) => {
                                        adminChangeStatus(event.value);
                                    }} />
                            </div>)}

                            <div>
                                {(status.id === 1) && (<p className="countdown">{countdown({ date: creation_date, days: timeLeft })}</p>)}

                                {(userType === UserTypes.INFLUENCER && status.id === 1 && JSON.parse(isAdmin) !== true) && (<p className="declineButton" onClick={handlerDecline}><FormattedMessage id="collaboration_details_button_3" defaultMessage="Declinar" /></p>)}

                                {(status.id > 2) && (<p className="declineButton" onClick={() => { setShowDispute(true) }}><FormattedMessage id="collaboration_details_button_5" defaultMessage="Disputa" /></p>)}
                            </div>
                        </div>
                    </div>

                    {(comment) && (<>
                        <p className="commentsTitle">
                            <span><FormattedMessage id="collaboration_details_text_6" defaultMessage="Requisitos / comentarios de la colaboración" /></span>
                            <b><FormattedMessage id="collaboration_details_text_6_b" defaultMessage=" (leer detalladamente antes de aceptar)" /></b>
                        </p>
                        <div className="commentsContainer">
                            <p className="comments">{comment}</p>
                        </div>
                    </>)}

                    {(documentUrl) && (
                        <div className="fileContainer">
                            <p>
                                <a className="file" target="_blank" href={documentUrl} download={documentUrl} >
                                    <FormattedMessage id="collaboration_details_text_12" defaultMessage="Documento de colaboración /contrato" />
                                </a>
                                <span className="fileAditional"><FormattedMessage id="collaboration_details_text_12_b" defaultMessage="(leer atentamente antes de aceptar)" /></span>
                            </p>
                        </div>
                    )}

                    {(userType === UserTypes.INFLUENCER && (status.id > 2)) &&
                        (<p className="voucher" onClick={() => { navigate('voucher') }}><FormattedMessage id="collaboration_details_text_14" defaultMessage="Ver voucher" /></p>)}


                    {(userType === UserTypes.COMPANY && (status.id > 2)) && (<p className="voucher" onClick={() => { navigate('invoice') }}><FormattedMessage id="collaboration_details_text_13" defaultMessage="Ver factura" /></p>)}

                </section>
                <section>

                    <table>
                        {(items.length > 0) && (items.map((item) => {
                            return (
                                <CollaborationContent 
                                    item={item} 
                                    setShowDispute={setShowDispute} 
                                    key={item.id} 
                                    collaborationStatus={status.id} 
                                    onChangeItem={onChangeContent} 
                                />
                            )
                        }))}
                        <tr>
                            <td>
                                <p className="totalName"><FormattedMessage id="cart_text_8" defaultMessage="Total" /></p>
                            </td>
                            <td></td>
                            <td>
                                <span className="totalPrice">$ {formatNumber(auxBaseAmount)}</span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <p className="aditionalInfo">*<FormattedMessage id="collaboration_total_aditional_info" defaultMessage="Fluincr no es responsable por contenido perdido. pro favor siempre guarda una copia de tu contenido." /></p>

                </section>

            </div>
            <button className={`${!isAdmin && status.id !== 3 && 'disabled'} chatButton`} onClick={() => setShowChat(!showChat)} disabled={!isAdmin && status.id !== 3}>
                <FormattedMessage id="collaboration_details_button_4" defaultMessage="Chat" />
                <img src={chat} alt="chat" className="chatIcon" />
            </button>

            {(showDispute) && (<ModalDispute setShowDispute={setShowDispute} collaboration={collaboration} setCollaboration={setCollaboration} setShowReceivedDispute={setShowReceivedDispute} />)}

            {(showReceivedDispute) && (<ModalReceivedDispute setShowReceivedDispute={setShowReceivedDispute} />)}

            {(isLoading) && (<Spinner />)}

            {(showChat) && (<Chat setShowChat={setShowChat} collaboration={collaboration} />)}

            <ModalPayPalLogin open={openModalPayPalLogin} setOpen={setOpenModalPayPalLogin} collaborationId={idCollaboration}></ModalPayPalLogin>
        </CollaborationsDetailsContainer>
    )
}