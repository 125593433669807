import React from "react";
import { StepperWrapper, Circle, Line } from "./StepperStyled";

const Stepper = ({ steps, currentStep, ...others }) => {
    return (
        <StepperWrapper {...others}>
            {Array.from({ length: steps }, (_, index) => (
                <React.Fragment key={index}>
                    <Circle completed={index <= currentStep} />
                    {index < steps - 1 && <Line completed={index < currentStep} />}
                </React.Fragment>
            ))}
        </StepperWrapper>
    );
};

export default Stepper;