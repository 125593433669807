import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const InfluencerProfileFirstSectionContainer = styled.div`

    display: flex;
    justify-content: space-between;
    .collaborationsContainer{
        display: flex;
        flex-direction: column;
        justify-content: first baseline;
        align-items: center;
       // padding-left: 600px;
       padding-left: 25%;
        .collaborations{
            display: flex;
            .collaboration{
                width: 50px;
                height: 50px;
                width: 130px;
                height: 130px;
                border-radius:50%;
                background-color: ${colors.greyIcon};
                margin: 0 5px;
                img{
                        width: 100%;
                        height: 100%;
                        border-radius:50%;
                    }
            }
        }
        .collaborationText{
            display: none;
            font-size: 2rem;
            margin-top: 5px;
            color: ${colors.grey};
        }
    }
    .collaborationsContainer:hover{
        .collaborationText{
            display: block;
            
            
        }
    }

    .rateContainer{
        width: 1000px;
        .rate{
            border-radius: 10px;
            border: 1px solid ${colors.darkGrey};
            padding: 10px 18px;
            padding: 15px 25px;
            margin-bottom: 10px;
            .rateTitle{
                font-weight: bold;
                font-size: 2.2rem;
            }
            .rateInformation{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .rateDescription{
                    font-size: 0.7rem;
                    font-size: 1.6rem;
                    font-weight: lighter;
                }
                div{
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    .value{
                        font-weight: bold;
                        font-size: 1.6rem;
                    }
                    img{
                        width: 40px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .startCollaboration{
            background-color: ${colors.blueButton};
            border: none;
            border-radius: 10px;
            width: 100%;
            height: 52px;
            height: 120px;
            color: #ffffff;
            font-size: 1.4rem;
            font-size: 2.5rem;
            font-weight: 800;
            cursor: pointer;
        }
    }
        
     /**----------Responsive------------ */
    ${media.extralargeDesktop}{
        .collaborationsContainer{
            //padding-left: 500px;
            .collaborations{
                .collaboration{
                    width: 100px;
                    height: 100px;
                }
            }
            .collaborationText{
                font-size: 1.8rem;
            }
        }

        .rateContainer{
            width: 830px;
            .rate{
                padding: 8px 20px;
                .rateTitle{
                    font-size: 1.8rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 1.2rem;
                    }
                    div{
                    .value{
                        font-size: 1.4rem;
                    }
                    img{
                        width: 35px;
                    }
                }
                }
            }
            .startCollaboration{
                height: 90px;
                font-size: 2rem;
            }
        }  
    }

    ${media.largeDesktop}{
        .collaborationsContainer{
            //padding-left: 400px;
            .collaborations{
                .collaboration{
                    width: 95px;
                    height: 95px;
                }
            }
            .collaborationText{
                font-size: 1.8rem;
            }
        }

        .rateContainer{
            width: 760px;
            .rate{
                padding: 8px 20px;
                .rateTitle{
                    font-size: 1.8rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 1.2rem;
                    }
                    div{
                        .value{
                            font-size: 1.4rem;
                        }
                        img{
                            width: 30px;
                        }
                    }
                }
            }
            .startCollaboration{
                height: 90px;
                font-size: 2rem;
            }
        }  
    }
       
    ${media.specialScreen}{
        .collaborationsContainer{
            //padding-left: 300px;
            .collaborations{
                .collaboration{
                    width: 75px;
                    height: 75px;
                }
            }
            .collaborationText{
                font-size: 1.4rem;
            }
        }

        .rateContainer{
            width: 590px;
            .rate{
                padding: 7px 16px;
                .rateTitle{
                    font-size: 1.4rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 1rem;
                    }
                    div{
                        .value{
                            font-size: 1.1rem;
                        }
                        img{
                            width: 25px;
                        }
                    }
                }
            }
            .startCollaboration{
                height: 75px;
                font-size: 1.6rem;
            }
        }  
    }

    ${media.bigmacdesktop}{
        .collaborationsContainer{
            //padding-left: 295px;
            .collaborations{
                .collaboration{
                    width: 65px;
                    height: 65px;
                }
            }
            .collaborationText{
                font-size: 1.2rem;
            }
        }

        .rateContainer{
            width: 560px;
            //background-color: pink;
            .rate{
                padding: 7px 16px;
                .rateTitle{
                    font-size: 1.4rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 1rem;
                    }
                    div{
                        .value{
                            font-size: 1.1rem;
                        }
                        img{
                            width: 25px;
                        }
                    }
                }
            }
            .startCollaboration{
                height: 75px;
                font-size: 1.6rem;
            }
        }  
    }

    ${media.macdesktop}{
        .collaborationsContainer{
            //padding-left: 285px;
            .collaborations{
                .collaboration{
                    width: 62px;
                    height: 62px;
                }
            }
            .collaborationText{
                font-size: 1.1rem;
            }
        }

        .rateContainer{
            width: 530px;
            //background-color: green;
            .rate{
                padding: 7px 16px;
                .rateTitle{
                    font-size: 1.4rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 1rem;
                    }
                    div{
                        .value{
                            font-size: 1.1rem;
                        }
                        img{
                            width: 25px;
                        }
                    }
                }
            }
            .startCollaboration{
                height: 75px;
                font-size: 1.6rem;
            }
        }  
    }
      

    ${media.desktop}{
        .collaborationsContainer{
            //padding-left: 260px;
            .collaborations{
                .collaboration{
                    width: 55px;
                    height: 55px;
                }
            }
            .collaborationText{
                font-size: 1rem;
            }
        }

        .rateContainer{
            width: 510px;
            .rate{
                padding: 6px 15px;
                .rateTitle{
                    font-size: 1rem;
                }
                .rateInformation{
                    .rateDescription{
                        font-size: 0.7rem;
                    }
                    div{
                        .value{
                            font-size: 0.9rem;
                        }
                        img{
                            width: 20px;
                        }
                    }
                }
            }
            .startCollaboration{
                height: 55px;
                font-size: 1.4rem;
            }
        }  
    }

    ${media.mediumDesktop}{
        .collaborationsContainer{
            //padding-left: 240px;
            .collaborations{
                .collaboration{
                    margin: 0 3px;
                }
            }
        }
        .rateContainer{
            width: 470px;
        }
    }

    ${media.smallDesktop}{
        .collaborationsContainer{
            //padding-left: 240px;
            .collaborations{
                .collaboration{
                    margin: 0 3px;
                }
            }
        }
        .rateContainer{
            width: 450px;
            .rate{
                .rateInformation{
                    .rateDescription{
                        font-size: 0.7rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        //width: 55%;
                    }
                }
            }
        }






    }
   
    ${media.laptop}{
        flex-direction: column;
        align-items: center;
        .collaborationsContainer{
            padding: 0px;
            margin-bottom: 20px;
        }
        .rateContainer{
            width:70%;
            .rate{
                border-radius: 15px;
                padding: 5px 10px;
                margin-bottom: 10px;
                .rateTitle{
                    font-weight: 600;
                }
                .rateInformation{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    /* .rateDescription{
                        width: 60%;
                    } */
                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            margin-left: 10px;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                        img{
                            width: 20px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    ${media.largeMobile}{

        .collaborationsContainer{
            .collaborations{
                .collaboration{
                    width: 60px;
                    height: 60px;
                }
            }

        }
        .rateContainer{
            width:100%;
            .rate{
                border-radius: 15px;
                padding: 5px 10px;
                margin-bottom: 10px;
                .rateTitle{
                    font-weight: 600;
                    font-size: 0.9rem;
                }
                .rateInformation{
                    //display: flex;
                   // align-items: center;
                    justify-content: space-between;
                    .rateDescription{
                        font-size: 0.7rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        //width: 60%;
                    }
                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            margin-left: 10px;
                            font-size: 0.8rem;
                            font-weight: 600;
                        }
                        img{
                            width: 20px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
    

`