import styled from "styled-components";

export const NetworkIconContainer = styled.div`

	.magenta{
        fill:#FFFFFF;
        stroke: #FFFFFF;
        stroke-width: 1px;
    } 
    .principal{
        stroke: #FFFFFF;
        stroke-width: 1px;
    }
`