import React, { useEffect, useState } from "react";
import { CompanyCardContainer } from "./CompanyCardStyled";
import avatar from './../../utils/images/avatar_fluincr.png';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { StaticDataContext } from "../../context/staticDataContext";

export default function CompanyCard({ company, page }) {

    const navigate = useNavigate();

    const { userToken, userType } = useContext(UserContext2);
    const { countrySelectList, categorySelectList } = useContext(StaticDataContext);

    const [country, setCountry] = useState(null);

    useEffect(() => {
        if (company?.address?.country_id !== null) {
            const country = countrySelectList?.find((element) => element.value === company?.address?.country_id);
            setCountry(country);
        }
    }, [company, countrySelectList]);

    const handlerSeeCompany = () => {
        if (userToken === null) {
            navigate(`/signin`)
        } else {
            if (userType === UserTypes.INFLUENCER) {
                navigate(`/${company.username}`)
            }
        }

    }

    return (
        <CompanyCardContainer page={page}>
            <div 
                className="logo" 
                style={{ backgroundImage: `url(${company?.image_profile ?? avatar})` }}
                onClick={handlerSeeCompany}
            ></div>

            <h3 className="name" onClick={handlerSeeCompany}>{company?.name}</h3>

            <div className="companyData">
                <img src={country?.image} alt="" className="companyCountry" />
                <div className="categories">
                    {
                        company?.categories?.map((categoryId, index) => {
                            if (index < 3) {
                                return (
                                    <span className="category" key={categoryId}>#{categorySelectList.find((element) => element.value === categoryId)?.label}</span>
                                )
                            }
                        })
                    }
                </div>
            </div>

        </CompanyCardContainer>
    )
}