/* export const networkRanges = {
    rango1: 100,
    rango2: 500,
    rango3: 1000,
    rango4: 2000,
    rango5: 5000
} */
export const networkRanges = {
    rango1: 50000,
    rango2: 99999,
    rango3: 499999,
    rango4: 999999,
    rango5: 5000000
} 