import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const BASE_URL = API_GATEWAY_URL + '/fluincr-static-data';

export const LegalTextAPI = {
    // Read
    getLegalTextList: async () => {
        const response = await axios.get(`${BASE_URL}/legal-text`);
        return response.data;
    },
};