import styled from 'styled-components';
import { media } from './../../utils/styles/media';
import { colors } from '../../utils/styles/colors';

export const CompanyCardContainer = styled.div`
    
    width: ${(props) => props.page === "home" ? '20%' : '25%'};
    padding: 15px;
    cursor: pointer;
    .logo{
        width: 100%;
        height: 420px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 15px;
    }

    .name{
        font-size: 2.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.text};
        text-transform: capitalize;
        margin-top: 8px;
    }
    .name:hover{
        opacity: 0.8;
        cursor: pointer;
    }

    .companyData{
        display: flex;
        align-items: center;
        .companyCountry{
            height:30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 3px;
        }
        .categories{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-transform: lowercase;
            font-weight: 600;
            .category{
                padding-right:3px;
                font-size: 1.6rem; 
                font-weight: bold; 
            }
        }
        
    }
    


    /**---------Responsive-------- */
    ${media.largeDesktop}{
        .logo{
            height: 16vw;
        }
        .name{
            font-size: 1.6rem;
        }
        .companyData{
            .categories{
                .category{
                    font-size: 1.2rem; 
                }
            }
        }
    }

    ${media.macdesktop}{
        padding: 12px;
        .logo{
            //height: 15vw;
        }
        .name{
            font-size: 1.3rem;
        }
        .companyData{
            .companyCountry{
                height:25px;
                width: 25px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 1rem; 
                }
            }
        }

    }
    
    ${media.desktop}{
        padding: 12px;
        .logo{
            height: 230px;
        }
        .name{
            font-size: 1.5rem;
        }
        .companyData{
            .companyCountry{
                height:25px;
                width: 25px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 1rem; 
                }
            }
        }

    }
    ${media.smallDesktop}{
        padding: 10px;
        .logo{
            height: 160px;
        }
    }

    ${media.laptop}{
        padding: 8px;
        .logo{
            height: 15.5vw;
        }
        .name{
            font-size: 1rem;
        }
        .companyData{
            align-items:center;
            .companyCountry{
                height:20px;
                width: 20px;
            }
            .categories{
                .category{
                    font-size: 0.8rem; 
                }
            }
        }

    }

    @media(max-width: 744px){
        .name{
            font-size: 0.9rem;
        }
    }
    @media(max-width: 659px){
        .name{
            font-size: 0.8rem;
        }
    }

    ${media.largeMobile}{
        width: 50%;
        .logo{
            height: 165px;
        }
        .name{
            font-size: 1rem;
            //margin-top: 6px;
        }
        :last-child{
            display: ${(props) => props.page === "home" ? 'none' : 'block'};
        }
    }
`