import React, { useState, useContext, useEffect } from "react";

import avatar from "./../../utils/images/avatar_fluincr.png";

import ModalCarouselCollaborations from "../ModalCarouselCollaboration/ModalCarouselCollaboration";
import { CollaborationItemCardPrivateProfileContainer } from "./CollaborationItemCardPrivateProfileStyled";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { ItemAPI } from "../../api/collaboration/item";

export default function CollaborationItemCardPrivateProfile({
  collaborationItem,
  influencer,
  company,
  profileUserType,
}) {
  const { userType } = useContext(UserContext2);

  const [showContentModal, setShowContentModal] = useState(false);

  const [showInProfile, setShowInProfile] = useState();

  const [imageProfile, setImageProfile] = useState("");

  useEffect(() => {
    if (userType === UserTypes.INFLUENCER) {
      setImageProfile(company?.image_profile);
    } else {
      setImageProfile(influencer?.image_profile);
    }
  }, []);

  useEffect(() => {
    if (userType === UserTypes.INFLUENCER) {
      setShowInProfile(collaborationItem.public_profile_influencer);
    } else {
      setShowInProfile(collaborationItem.public_profile_company);
    }
  }, [userType]);

  const handlerShowInProfile = (checked) => {
    if (userType === UserTypes.INFLUENCER) {
      ItemAPI.setItemPublicInfluencer(collaborationItem.id, checked).then(
        (response) => {
          setShowInProfile(checked);
        }
      );
    } else {
      ItemAPI.setItemPublicCompany(collaborationItem.id, checked).then(
        (response) => {
          setShowInProfile(checked);
        }
      );
    }
  };

  return (
    <CollaborationItemCardPrivateProfileContainer>
      <div className="switchContainer">
        <label className="switch">
          <input
            type="checkbox"
            checked={showInProfile}
            onClick={(event) => {
              handlerShowInProfile(event.target.checked);
            }}
          />
          <div className="slider"></div>
        </label>
      </div>

      <div
        className="cardCollaboration"
        style={{
          backgroundImage: `url(${
            collaborationItem.cover ?? collaborationItem.content
          })`,
        }}
        onClick={() => {
          setShowContentModal(true);
        }}
      >
        <div
          className="colaborator"
          style={{ backgroundImage: `url(${imageProfile ?? avatar})` }}
        ></div>
      </div>

      {showContentModal && (
        <ModalCarouselCollaborations
          content={[collaborationItem]}
          influencer={influencer}
          company={company}
          setShowModalCarousel={setShowContentModal}
          profileUserType={profileUserType}
        />
      )}
    </CollaborationItemCardPrivateProfileContainer>
  );
}
