import styled, { css } from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const SelectProductContainer = styled.div`
    position: relative;
    .select{
        border-radius: 5px;
        border: 1px solid ${colors.greyIcon};
        display: flex;
        justify-content: space-between;
        padding: 5px 10px; 
        .selected{
            .required{
                color: ${colors.hightlight};
            }
        } 
    }
    .required{
        text-align: end;
        color: ${colors.hightlight};
        font-size: 0.8rem;
        margin-bottom: 20px;
    }
    .options{
        position: absolute;
        background-color: #ffffff;
        //width: 405px;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        .option{
            padding: 0px 10px 2px;
            .product{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                padding: 5px 0;
                border-bottom:0.5px solid ${colors.grey};
                font-size: 0.9rem;
                cursor: pointer;
                .productName{
                    width: 50%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .productImage{
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
                .productPrice{
                    font-weight: bold;
                }
            }
            .product:hover{
                background-color: ${colors.greyChat};
            }
           
        }
        
        .add{
            margin: 5px 10px;
            color: ${colors.hightlight};
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .addText{
                text-decoration:underline;
            }
            .plus{
                font-size: 1.8rem;
            }
        }
        .add:hover{
            background-color: ${colors.greyChat};
        }
    }

    ${(props) =>
        props.highlightSelect &&
        css`
            .select{
                border: 2px solid #de2814;
            }
            .required {
                color: #de2814 !important;
            }
            .dropdown {
                filter: hue-rotate(50deg) saturate(90%) !important;
            }
        `}


    /**-----------Responsive----------- */
    /* ${media.laptop}{
        .options{
            width: 100%;
        }
    }
    ${media.tablet}{
        .options{
            width: 83%;
        }
    }
    ${media.smallTablet}{
        .options{
            width: 82%;
        }
    }
    ${media.largeMobile}{
        .options{
            width: 86%;
        }
    } */
`