import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ErrorContainer = styled.div`
    
    padding: 100px 70px;
    background-color: ${colors.errorBackground};
    display: flex;
    justify-content: space-between;
    section{
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3{
            font-size: 3.2rem;
            margin-bottom: 30px;
            color: ${colors.greyInput};
        }
        button{
            width: 50%;
            background-color: ${colors.hightlight};
            border-radius: 30px;
            border: none;
            color: #ffffff;
            padding: 10px 20px;
            font-size: 1.4rem;
            margin: 25px 0;
            cursor: pointer;
            font-weight: bold;
        }
        p{
            font-size: 1.4rem;
            color: ${colors.darkGrey};
        }
        a{
            text-decoration: underline;
            color: ${colors.hightlight};
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 1.4rem;
        }
        .errorLogo{
            display: flex;
            justify-content: center;
            img{
                width: 250px;
            }
        }
        .errorMessage{
            text-align: center;
            font-weight: bold;
            color: ${colors.darkGrey};
            font-size: 1.7rem;
        }
    }

    /**-------------Responsive--------------- */
    ${media.macdesktop}{
        section{
            h3{
                font-size: 2.2rem;
                margin-bottom: 20px;
            }
            p{
                font-size: 1.1rem;
            }
            button{
                width: 70%;
               
                border-radius: 20px;
                padding: 8px 10px;
                font-size: 1.1rem;
            }
            a{
                text-decoration: underline;
                color: ${colors.hightlight};
                margin-bottom: 15px;
                font-size: 1.1rem;
            }
            .errorLogo{
                img{
                    width: 150px;
                }
            }
            .errorMessage{
                font-size: 1.4rem;
            }
        }
    }

    ${media.laptop}{
        flex-direction: column-reverse;
        section{
            width: 100%;
            text-align: center;
            h3{
                margin-top: 30px;
            }
            button{
                align-self: center;
                width: 60%;
            }
        }
    }

    ${media.largeMobile}{
        padding: 80px 50px;
        section{
            p, a{
                font-size: 0.9rem;
            }
            button{
                width: 100%;   
            }
            .errorLogo{
                img{
                    width: 110px;
                }
            }  
            .errorMessage{
                font-size: 1.2rem;
            }  
        }
    }

`