import React, { useState, useContext, useEffect, useRef } from "react";
import { HeaderContainer, ProfileImage } from "./HeaderStyled";
import nameLogo from './../../utils/images/name_logo_black.png';
import Translate from './../../components/translate/Translate';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from "react-router-dom";

import avatar from './../../utils/images/avatar_fluincr.png';
import shoppingCart from './../../utils/images/shopping_cart.svg';
import hands from './../../utils/images/handshake.svg';
import settings from './../../utils/images/settings.svg';

import burgerMenu from './../../utils/images/burger_menu.png';
import dropDown from './../../utils/images/drop_down_pink.svg';
import MobileMenu from "./MobileMenu/MobileMenu";
import { LangContext } from "../../context/langContext";
import { CartContext } from "../../context/cartContext";
import { CollaborationsContext } from "../../context/collaborationsContext";
import { FiltersContext } from "../../context/filtersContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import SearchDropdown from "./SearchDropdown/SearchDropdown";

export default function Header() {

    const navigate = useNavigate();

    const [showList, setShowList] = useState(false);
    const location = useLocation();

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showCategoryMenu, setShowCategoryMenu] = useState(false);

    const {
        categorySelectList,
        staticPagesEspañol, 
        staticPagesEnglish
    } = useContext(StaticDataContext);

    const {  
      setUserToken, 
      userType, 
      setUserType,
      userData,
      setUserData
    } = useContext(UserContext2);
    const { locale } = useContext(LangContext);

    const settingRef = useRef(null);
    const categoryRef = useRef(null);

    const { shoppingCartCount } = useContext(CartContext);

    const { collaborationNotificationCount } = useContext(CollaborationsContext);
    const { categoryFilter, setCategoryFilter } = useContext(FiltersContext);

    const isAdmin = sessionStorage.getItem('isAdmin');

    const handlerLogout = () => {
        setShowSettingsMenu(false);
        localStorage.removeItem('fluincrToken');
        localStorage.removeItem('fluincrUser');
        sessionStorage.removeItem('fluincrToken');
        sessionStorage.removeItem('fluincrUser');
        setUserToken(null);
        setUserType('');
        setUserData(null);
        navigate('/');
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (settingRef.current && !settingRef.current.contains(event.target)) {
                setShowSettingsMenu(false);
            }
            if (categoryRef.current && !categoryRef.current.contains(event.target)) {
                setShowCategoryMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [categoryRef, settingRef])

    

    const handlerCategoryFilter = (event, category) => {
        const auxCategoryFilter = [...categoryFilter];
        if (event.target.checked) {
            auxCategoryFilter.push(category);
            setCategoryFilter(auxCategoryFilter);
        } else {
            const newCategory = auxCategoryFilter.filter(cat => cat !== category);
            setCategoryFilter(newCategory)
        }
    }



    return (
      <HeaderContainer>
        {/* <Link to='/'> */}
        <img
          src={nameLogo}
          alt="logo"
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {/* </Link> */}

        <div className="menu">
          {userData && !isAdmin && (
            <div className="categoryContainer" ref={categoryRef}>
              <div
                className="category"
                onClick={() => {
                  setShowCategoryMenu(!showCategoryMenu);
                }}
              >
                <p>
                  <FormattedMessage
                    id="filter_title_2"
                    defaultMessage="Categoría"
                  />
                </p>
                <img src={dropDown} alt="drop down" className="arrow" />
              </div>

              {showCategoryMenu && categorySelectList && (
                <div className="categoryMenu">
                  {categorySelectList.map((category) => (
                    <label htmlFor={category.label} className="option">
                      {" "}
                      <input
                        type="checkbox"
                        id={category.value}
                        name="category"
                        value={category.value}
                        checked={categoryFilter.includes(category.value)}
                        onChange={(event) => {
                          handlerCategoryFilter(event, category.value);
                          navigate("/influencers");
                        }}
                      />{" "}
                      {category.label}
                    </label>
                  ))}
                </div>
              )}
            </div>
          )}

          {userData ? (
            <>
              {!isAdmin && (<SearchDropdown/>)}

              <div className="navOptions">
                <ProfileImage
                  image={
                    userData?.image_profile ?? avatar
                  }
                  onClick={() => {
                    navigate(`/${userData?.username}`);
                  }}
                />

                {(userType === UserTypes.COMPANY) && !isAdmin && (
                  <Link to={"/mycart"}>
                    <div className="option">
                      {shoppingCartCount > 0 && (
                        <div className="count">
                          <p>{shoppingCartCount}</p>
                        </div>
                      )}
                      <img src={shoppingCart} alt="shopping cart" />
                    </div>
                  </Link>
                )}

                <Link to={"/mycollaborations"}>
                  <div className="option">
                    {/* <div className="active"></div> */}
                    {collaborationNotificationCount > 0 && (
                      <div className="count">
                        <p>{collaborationNotificationCount}</p>
                      </div>
                    )}
                    <img src={hands} alt="collaborations" />
                  </div>
                </Link>

                <div className="option" ref={settingRef}>
                  {/* <div className="active"></div> */}
                  <img
                    src={settings}
                    alt="settings"
                    onClick={() => {
                      setShowSettingsMenu(!showSettingsMenu);
                    }}
                    className="settings"
                  />
                  {showSettingsMenu && (
                    <div className="settingsMenu">
                      <Link to="myprofile">
                        <p
                          className="settingsOption"
                          onClick={() => {
                            setShowSettingsMenu(false);
                          }}
                        >
                          <FormattedMessage
                            id="settings_menu_option_1"
                            defaultMessage="Editar mi perfil"
                          />
                        </p>
                      </Link>

                      <hr />
                      <Link to="myaccount">
                        <p
                          className="settingsOption"
                          onClick={() => {
                            setShowSettingsMenu(false);
                          }}
                        >
                          <FormattedMessage
                            id="settings_menu_option_2"
                            defaultMessage="Editar mi cuenta"
                          />
                        </p>
                      </Link>
                      <hr />

                      {/* {(!isAdmin) && (<><p className="settingsOption" onClick={() => { setShowSettingsMenu(false) }}><FormattedMessage id="settings_menu_option_3" defaultMessage="Mis favoritos" /></p>
                                        <hr /></>)} */}

                      {/* <p className="settingsOption" onClick={() => { setShowSettingsMenu(false) }}><FormattedMessage id="settings_menu_option_4" defaultMessage="Mis pagos" /></p>
                                    <hr /> */}
                      <p className="settingsOption" onClick={handlerLogout}>
                        <FormattedMessage
                          id="settings_menu_option_5"
                          defaultMessage="Cerrar sesión"
                        />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            location.pathname !== "/signin" && (
              <>
                <div className="headerStaticPages">
                  {locale === "es-CO"
                    ? staticPagesEspañol.map((page, key) => {
                        return (
                          <p key={key}>
                            <Link to={`/static/${page.path}`}>
                              {page.title}
                            </Link>
                          </p>
                        );
                      })
                    : staticPagesEnglish.map((page, key) => {
                        return (
                          <p key={key}>
                            <Link to={`/static/${page.path}`}>
                              {page.title}
                            </Link>
                          </p>
                        );
                      })}
                </div>
                <Link to="/signin">
                  <button className="signIn">
                    <FormattedMessage
                      id="header_button_text"
                      defaultMessage="Iniciar sesión"
                    />
                  </button>
                </Link>
              </>
            )
          )}

          {userData && (
            <div className="responsiveMenu">
              <img
                src={burgerMenu}
                alt="menu"
                className="burgerMenu"
                onClick={() => {
                  setShowMobileMenu(true);
                }}
              />
            </div>
          )}

          <Translate showList={showList} setShowList={setShowList} />

          {showMobileMenu && <MobileMenu setShowMenu={setShowMobileMenu} />}
        </div>
      </HeaderContainer>
    );
}