import React, { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../context/currencyContext";
import { LangContext } from "../../context/langContext";
import { useParams } from 'react-router-dom';
import { formatNumber } from "../../services/generalSevices";
import ContentDetailsInfluencer from "../../components/ContentDetailsInfluencer/ContentDetailsInfluencer";
import ContentDetailsCompany from "../../components/ContentDetailsCompany/ContentDetailsCompany";
import ModalCarousel from "../../components/ModalCarousel/ModalCarousel";
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserTypes } from "../../utils/constants/user";

export default function CollaborationContent({ item, collaborationStatus, setShowDispute, onChangeItem }) {
    
    const { userType } = useContext(UserContext2);
    
    const { currency, convertCurrency } = useContext(CurrencyContext);
    
    const { fluincrServicesList } = useContext(StaticDataContext);
    
    const { locale } = useContext(LangContext);
    
    const { idCollaboration } = useParams();
    
    const [contentPrice, setContentPrice] = useState(null);

    const [showCarousel, setShowCarousel] = useState(false);

    const {
        id,
        content,
        cover,
        price,
        service_id: serviceId,
    } = item;

    const service = fluincrServicesList.find(service => service.id === serviceId);


    useEffect(() => {
        setContentPrice(convertCurrency(price, currency?.symbol).toFixed(2));
    }, [currency, item, idCollaboration]);

    return (
        <>
            <tbody>
                <tr key={item.id}>
                    <td>
                        <div className="collaborationItem">{(service?.name)}
                        </div>
                    </td>

                    <td>
                        <div className="collaborationItem"> #{id}
                        </div>
                    </td>

                    <td>
                        <p className="unitPrice"><span>$ </span>{formatNumber(contentPrice)}</p>
                        {/* <p className="subtotal">$ {formatNumber(contentPrice * item.data.length)}</p> */}
                    </td>

                    <td>
                        {
                            (userType === UserTypes.INFLUENCER) &&
                                <ContentDetailsInfluencer
                                    item={item}
                                    onChangeItem={onChangeItem} 
                                    collaborationStatus={collaborationStatus}
                                    setShowDispute={setShowDispute}
                                />
                        }
                        {
                            (userType === UserTypes.COMPANY) &&
                                <ContentDetailsCompany 
                                    item={item}
                                    onChangeItem={onChangeItem} 
                                    collaborationStatus={collaborationStatus}
                                    setShowCarousel={setShowCarousel}
                                    setShowDispute={setShowDispute}
                                />
                        }
                    </td>
                </tr>
            </tbody>

            {(showCarousel) && (<ModalCarousel setShowModalCarousel={setShowCarousel} content={[content]} page='collaborationDetails' contentType={service.type} />)}
        </>
    );
}
