import styled from "styled-components";

export const VideoInputContainer = styled.div`
    height: 80px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    input[type="file"]{
        display: none;
    }
    .videoButton{
        background-color: #ffffff;
        border: none;
    }
    .removeButton{
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 5px;
            border: none;
            background-color: transparent;
            z-index: 100;
            img{
                width: 15px;
                cursor: pointer;
            }
        }
    .video{ 
        width: 100%;
        height: 100%;
    }
        
    
`