import React, { useState, useContext, useEffect, useRef } from "react";
import { ModalCreateCollaborationContainer, ProfileImage } from "./ModalCreateCollaborationStyled";

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber, getBase64, toUpperCase } from "../../services/generalSevices";
import Select from 'react-select';

import close from './../../utils/images/close_circle_outline.svg';
import back from './../../utils/images/arrow_back.svg';
import logo from './../../utils/images/name_logo_dark.svg';
import plus from './../../utils/images/plus.png';
import less from './../../utils/images/less.png';
import avatar from './../../utils/images/avatar_fluincr.png';
import tiktok from './../../utils/images/tiktok_opacity.svg';
import facebook from './../../utils/images/facebook_color.svg';
import instagram from './../../utils/images/instagram_color.svg';
import youtube from './../../utils/images/youtube_color.svg';
import twitter from './../../utils/images/twitter_color.svg';
import check from './../../utils/images/check_circle_darker.svg';
import star from './../../utils/images/star.svg';
import share from './../../utils/images/share_new.svg';
import gift from './../../utils/images/gift.svg';
import upload from './../../utils/images/upload.svg';
import shoppingCart from './../../utils/images/shopping_cart_white.svg';
import deleteIcon from './../../utils/images/delete_grey.svg';
import fileIcon from './../../utils/images/file_primary.png';

import bell from './../../utils/images/bell.png';
import Spinner from "../Spinner/Spinner";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import { CurrencyContext } from "../../context/currencyContext";
import LikeInfluencer from "../LikeInfluencer/LikeInfluencer";
import SelectProduct from "../SelectProduct/SelectProduct";
import SmallSpinner from "../SmallSpinner/SmallSpinner";
import ChargingSpinner from "../ChargingSpinner/ChargingSpinner";
import Alert from "../Alert/Alert";
import TooltipLight from "../TooltipLight/TooltipLight";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { StaticDataContext } from "../../context/staticDataContext";
import { uploadImageS3 } from "../../utils/common/s3";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { CartContext } from "../../context/cartContext";
import { CartAPI } from "../../api/collaboration/cart";
import { SocialNetworkId } from "../../utils/constants/socialNetwork";
import { formatFollowers } from "../../utils/common/format";

export default function ModalCreateCollaboration({ setShowCreateCollaboration, influencerPublicData = {} }) {

    const shareRef = useRef(null);
    const { getCartId, setCart } = useContext(CartContext);
    const { userData, userToken, userType } = useContext(UserContext2);
    const { categorySelectList, countrySelectList, languageSelectList, fluincrServicesSelectList } = useContext(StaticDataContext)
    const { currency, convertCurrency } = useContext(CurrencyContext);

    const navigate = useNavigate();
    const intl = useIntl();

    const {
        id: influencerId,
        username: influencerUsername,
        name: influencerName,
        family_name: influencerFamilyName,
        address: influencerAddress,
        account: influencerAccount,
        description: influencerDescription,
        categories: influencerCategories,
        languages: influencerLanguages,
        verified: influencerVerified,
        image_profile: influencerImageProfile,
        rating: influencerRating,
        rate_by_service_list: influencerServices,
        social_networks: influencerSocialNetworks
    } = influencerPublicData;

    const [selectedProduct, setSelectedProduct] = useState();
    const [sendGift, setSendGift] = useState(false);

    const [showCommentsArea, setShowCommentsArea] = useState(false);
    const [comments, setComments] = useState('');
    const [base64File, setBase64File] = useState('');

    const [collaborationItems, setCollaborationItems] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showSmallSpinner, setShowSmallSpinner] = useState(false);

    const [collaborationTotal, setCollaborationTotal] = useState(null);
    const [auxCollaborationTotal, setAuxCollaborationTotal] = useState(null);

    const [alreadyShowAdd, setAlreadyShowAdd] = useState(false);
    const [showAlertGiftProduct, setShowAlertGiftProduct] = useState(false);
    const [highlightSelect, setHighlightSelect] = useState(false);

    const [showSocialMedia, setShowSocialMedia] = useState(false);
    const url = window.location.href;
    const auxCurrency = currency.symbol;

    const [showTiktok, setShowTiktok] = useState(false);
    const [showInstagram, setShowInstagram] = useState(false);
    const [showFacebook, setShowFacebook] = useState(false);
    const [showYoutube, setShowYoutube] = useState(false);

    const addItem = (influencerService) => {
        setCollaborationItems([
            ...collaborationItems, influencerService
        ]);
        //setInfluencerServices(auxServices)
    }

    const removeItem = (influencerService) => {
        const indexToRemove = collaborationItems.findIndex(item => item.service_id === influencerService.service_id);
        if (indexToRemove !== -1) {
            const newItems = [...collaborationItems];
            newItems.splice(indexToRemove, 1);
            setCollaborationItems(newItems);
        }
    }

    useEffect(() => {
        let total = 0;
        
        collaborationItems.map((item) => {
            total = total + item?.price;
        })

        if (sendGift) {
            if (selectedProduct) {
                if (selectedProduct?.price < total) {
                    total = total - selectedProduct?.price;
                } else {
                    total = 0;
                }
            }
        }

        setCollaborationTotal(total);
    }, [collaborationItems, sendGift, selectedProduct])

    useEffect(() => {
        if (collaborationTotal !== null) {
            setAuxCollaborationTotal(convertCurrency(collaborationTotal, auxCurrency).toFixed(0));
        }
    }, [collaborationTotal, auxCurrency])

    const sendCart = () => {
        getCartId().then((cartId) => {


            const createCollaborationBody = {
                cart_id: cartId,
                influencer_id: influencerId,
                company_id: userData?.id,
                product_id: selectedProduct?.id,
                items: collaborationItems,
                comment: comments,
                gift: sendGift || false,
                gift_value: selectedProduct?.price ?? 0,
            }

            console.log(createCollaborationBody);

            setIsLoading(true);

            if (base64File !== '') {

                const fileBody = {
                    base64File: base64File.base64,
                    formatFile: base64File.type,
                    address: `company/${userData?.account}/collaboration`
                }

                console.log("Enviando archivo")

                uploadImageS3(fileBody.address, fileBody.formatFile, fileBody.base64File).then((fileLink) => {
                    if(fileLink){
                        createCollaborationBody.document = fileLink;
                        CollaborationAPI.createCollaboration(createCollaborationBody).then((response) => {
                            CartAPI.getCart(userData?.id).then((cartUpdated) => {
                                setIsLoading(false);
                                setShowCreateCollaboration(false);
                                navigate('/mycart');
                                setCart(cartUpdated);
                            }).catch((error) => {
                                setIsLoading(false);
                                setShowCreateCollaboration(false);
                                navigate('/mycart');
                            });
                        })
                    }
                })
            } else {
                CollaborationAPI.createCollaboration(createCollaborationBody).then((response) => {
                    CartAPI.getCart(userData?.id).then((cartUpdated) => {
                        setIsLoading(false);
                        setShowCreateCollaboration(false);
                        navigate('/mycart');
                        setCart(cartUpdated);
                    }).catch((error) => {
                        setIsLoading(false);
                        setShowCreateCollaboration(false);
                        navigate('/mycart');
                    });
                    
                })
            }
        }); 
    }

    useEffect(() => {
        function handleClickOutside(event) {

            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setShowSocialMedia(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [shareRef])

    const showSpinner = () => {
        setShowSmallSpinner(true);
        setTimeout(() => {
            setShowSmallSpinner(false);
        }, 3000);
    }

    const getInfluencerSocialNetworkFollowers = (socialNetworkId) => {
        const socialNetwork = influencerSocialNetworks.find((socialNetwork) => socialNetwork.social_network === socialNetworkId);
        return socialNetwork?.followers;
    }

    const getInfluencerSocialNetworkLink = (socialNetworkId) => {
        const socialNetwork = influencerSocialNetworks.find((socialNetwork) => socialNetwork.social_network === socialNetworkId);
        return socialNetwork?.link;
    }

    return (
        <ModalCreateCollaborationContainer>

            <img src={close} alt="close" className="close" onClick={() => { setShowCreateCollaboration(false) }} />

            <div className="collaborationBody">

                <div className="goBack" onClick={() => { setShowCreateCollaboration(false) }}>
                    <img src={back} alt="back" className="backIcon" />
                    <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
                </div>
                <h3 className="mobileTitle"><FormattedMessage id="create_collaboration_title" defaultMessage="Crea tu colaboración " /> <img src={logo} alt="logo" className="logo" /></h3>

                <div className="influencerData">

                    <div className="profile">
                        <div className="likeProfile">
                            {(!userToken) && (<LikeInfluencer profileId={influencerId}/>)}
                        </div>

                        <ProfileImage image={influencerImageProfile ?? avatar} />

                        <div className="networks">
                            {(getInfluencerSocialNetworkFollowers(SocialNetworkId.TIKTOK)) && (<div className="network">
                                <img src={tiktok} alt="tiktok" className="networkIcon" onMouseOver={() => { setShowTiktok(true) }} onMouseOut={() => { setShowTiktok(false) }} />
                                {(showTiktok) && (<p>{formatFollowers(getInfluencerSocialNetworkFollowers(SocialNetworkId.TIKTOK))}</p>)}
                            </div>)}

                            {(getInfluencerSocialNetworkFollowers(SocialNetworkId.INSTAGRAM)) && (<div className="network">
                                <img src={instagram} alt="instagram" className="network" onMouseOver={() => { setShowInstagram(true) }} onMouseOut={() => { setShowInstagram(false) }} />
                                {(showInstagram) && (<p>{formatFollowers(getInfluencerSocialNetworkFollowers(SocialNetworkId.INSTAGRAM))}</p>)}

                            </div>)}

                            {(getInfluencerSocialNetworkFollowers(SocialNetworkId.FACEBOOK)) && (<div className="network">
                                <img src={facebook} alt="facebook" className="network" onMouseOver={() => { setShowFacebook(true) }} onMouseOut={() => { setShowFacebook(false) }} />
                                {(showFacebook) && (<p>{formatFollowers(getInfluencerSocialNetworkFollowers(SocialNetworkId.FACEBOOK))}</p>)}
                            </div>)}

                            {(getInfluencerSocialNetworkFollowers(SocialNetworkId.YOUTUBE)) && (<div className="network">
                                <img src={youtube} alt="youtube" className="network" onMouseOver={() => { setShowYoutube(true) }} onMouseOut={() => { setShowYoutube(false) }} />
                                {(showYoutube) && (<p>{formatFollowers(getInfluencerSocialNetworkFollowers(SocialNetworkId.YOUTUBE))}</p>)}
                            </div>)}

                        </div>
                        <button className="seeProfile" onClick={() => { navigate(`/${influencerUsername}`) }}>
                            <FormattedMessage id="create_collaboration_text_8" defaultMessage="Ver perfil" />
                        </button>
                    </div>
                    <div className="dataProfile">
                        <div className="fileName">
                            <p className="name">{ `${influencerName} ${influencerFamilyName}` }</p>

                            {(influencerVerified) && (<img src={check} alt="check" className="check" />)}

                            {(influencerRating > 0) && (<div className="subItem"><p className="score">{(influencerRating)}</p><img src={star} alt="star" className="star" /></div>)}
                        </div>
                        <div className="fileCountry">
                            <p className="city">
                                {(influencerAddress) && (<p>
                                    {countrySelectList.find((country) => country.value === influencerAddress.country_id)?.label},
                                    {` ${influencerAddress.city}`}
                                </p>)
                                }
                            </p>
                            <p className="languages"> {
                                influencerLanguages?.map((languageId, index) => {
                                    const languageConvention = languageSelectList.find((language) => language.value === languageId)?.convention;
                                    if (index < 1) {
                                        return (languageConvention)
                                    } else {
                                        return (`/ ${languageConvention}`)
                                    }
                                })}
                            </p>

                            <div className="share" ref={shareRef}>
                                <img src={share} alt="edit" className="shareIcon" onClick={() => { setShowSocialMedia(!showSocialMedia) }} />
                                {(showSocialMedia) && (<SocialMedia shareUrl={url} />)}
                            </div>
                        </div>
                        <p className="fileCategories">
                            {
                                influencerCategories?.map((categoryId, index) => {
                                    if (index < 4) {
                                        const categoryName = categorySelectList.find((cat) => cat.value === categoryId)?.label;
                                        return `#${categoryName} `;
                                    }
                                })
                            }
                        </p>
                    </div>
                </div>

                <div className="collaborationData">
                    <div className="collaborationQuantity">
                        <div className="quantity">
                            <h3 className="collaborationTitle"><FormattedMessage id="create_collaboration_title" defaultMessage="Crea tu colaboración" /> <img src={logo} alt="logo" className="logo" /></h3>

                            <SelectProduct selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} highlightSelect={highlightSelect}/>

                            {(!influencerServices) && (<div className="notServices"><p><FormattedMessage id="create_collaboration_text_1" defaultMessage="El influencer no tiene ningún servicio" /></p></div>)}

                            {influencerServices?.filter((service) => service?.price && service?.price > 0)
                                .map((serviceInfluencer, index) => {
                                    const serviceName = fluincrServicesSelectList.find((service) => service.id === serviceInfluencer?.service_id)?.label;
                                    const quantity = collaborationItems.filter((item) => item?.service_id === serviceInfluencer?.service_id).length;
                                    return (
                                        <div className="file" key={serviceInfluencer?.service_id}>
                                            <p className="type">{serviceName}</p>
                                            <div className="right">
                                                <div className="buttons">
                                                    <button onClick={() => { removeItem(serviceInfluencer) }}><img src={less} alt="less" className="addIcon" /></button>
                                                    <span>{quantity}</span>
                                                    <button onClick={() => { addItem(serviceInfluencer) }}>
                                                        <img src={plus} alt="add" className="addIcon" />
                                                    </button>
                                                </div>
                                                <p className="price">{currency?.symbol} {formatNumber(convertCurrency(serviceInfluencer?.price, currency?.symbol))}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                        <div className="aditionalInfo">
                            <hr />
                            <div className="giftContainer">
                                <div className="giftInfo">
                                    <input type="checkbox" name="gift" checked={sendGift} onChange={(event) => { setSendGift(event.target.checked) }} />
                                    <img src={gift} alt="gift" className="giftIcon" />
                                    <span className="giftText"><FormattedMessage id="create_collaboration_text_2" defaultMessage="Envio completo del producto al influencer" /></span>
                                </div>
                                {(sendGift) && (<p className="giftPrice">{(selectedProduct) && `(${currency.symbol} -${formatNumber(selectedProduct?.price)})`}</p>)}
                            </div>

                            <div className="commentContainer">
                                <div className="addComments">
                                    <button onClick={() => { setShowCommentsArea(!showCommentsArea) }}>
                                        <img src={plus} alt="add" className="plusIcon" />
                                    </button>
                                    <p className="commentText"><FormattedMessage id="create_collaboration_text_3" defaultMessage="Comentarios / Agregar archivo" /></p>
                                </div>
                                {(showCommentsArea) && (
                                    <div className="comments">
                                        <textarea name="" id="" cols="30" rows="10" value={comments} onChange={(event) => { setComments(event.target.value) }}></textarea>

                                        {(base64File === '') && (<label htmlFor="collaboration-file-input" className="fileLabel">
                                            <img src={upload} alt="upload" className="uploadIcon" />
                                        </label>)}

                                        {(showSmallSpinner) && (<div className="chargingContainer">
                                            <ChargingSpinner />
                                        </div>)}

                                        {(base64File !== '' && showSmallSpinner === false) && (
                                            <div>
                                                <div style={{display: 'flex', gap: '.25em'}}>
                                                    <TooltipLight title={base64File.name} placement="top">
                                                        <img src={fileIcon} alt="file" className="uploadIcon" />
                                                    </TooltipLight>
                                                    <img src={deleteIcon} alt="upload" className="uploadIcon" onClick={() => { setBase64File('') }} />
                                                </div>
                                                <p className="fileName">{base64File.name}</p>
                                            </div>
                                        )}

                                        <input type="file" id="collaboration-file-input" accept=".pdf, .txt, application/pdf, .doc, .docx, application/msword, .xls,.xlsx, application/vnd.ms-excel, .ppt, .pptx, application/vnd.ms-powerpoint, text/plain,.png, .jpg, .jpeg, image/png, image/jpg" onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file !== undefined) {
                                                setBase64File({ base64: file, name: file.name, type: file.type || file.name.split('.').reverse()[0] });
                                            }
                                            showSpinner();
                                            }} 
                                        />
                                    </div>
                                )}
                            </div>

                            <button className={`addShoppingCart ${!(selectedProduct && collaborationItems.length > 0) && 'disabledButton'}`} onClick={() => {
                                if( collaborationItems.length > 0){
                                    if (!sendGift && !alreadyShowAdd) {
                                        setAlreadyShowAdd(true);
                                        setShowAlertGiftProduct(true);
                                    } else {
                                        sendCart()
                                    }
                                }else{
                                    setHighlightSelect(true);
                                }
                            }}>
                                <span><FormattedMessage id="create_collaboration_text_7" defaultMessage="Añadir al carrito" />
                                    <img src={shoppingCart} alt="shopping cart" className="cart" /></span>
                                <span>{currency.symbol} {formatNumber(auxCollaborationTotal)} </span>
                                {
                                    (userType === UserTypes.INFLUENCER) && 
                                    (<span><FormattedMessage id="create_collaboration_text_10" defaultMessage="(solo empresas)" /></span>)
                                }
                            </button>
                            <p className="requiredText">*<FormattedMessage id="create_collaboration_text_9" defaultMessage="Inicio de sesión requerido para Agregar al carrito" /></p>
                        </div>
                    </div>

                </div>

            </div>
            {(isLoading) && (<Spinner />)}
            {(showAlertGiftProduct) && (<Alert
                image={bell}
                title={<FormattedMessage id="alert_send_product_title" defaultMessage="¡Recordatorio!" />}
                text={<><p style={{ textAlign: 'center' }}><FormattedMessage id="alert_send_product_text_1" defaultMessage="Nos hemos dado cuenta de que no estás enviando producto(s) gratuito(s) para esta colaboración y de que " /><span style={{ textTransform: 'capitalize' }}>{influencerName}</span><FormattedMessage id="alert_send_product_text_2" defaultMessage=" puede no tener acceso a tu producto para crear contenido." /></p><p style={{ textAlign: 'center' }}><FormattedMessage id="alert_send_product_text_3" defaultMessage="Por favor, haz clic en “Entiendo” a continuación para confirmar." /></p></>}
                confirmText={<span style={{ fontWeight: 'bold' }}><FormattedMessage id="alert_send_product_button" defaultMessage="¡Entendido!" /></span>}
                onConfirm={() => { setShowAlertGiftProduct(false) }}
                setShowAlert={setShowAlertGiftProduct} />)}
        </ModalCreateCollaborationContainer>
    )
}