import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-social-network';

export const FacebookAPI = {

    manualInsert: async (userId, link, followers) => {
        const response = await axiosInstance.post(`${BASE_URL}/facebook/manual/${userId}?link=${link}&followers=${followers}`);
        return response.data;
    },

    accessToken: async (userId, facebookShortToken, facebookUserId) => {
        const response = await axiosInstance.post(`${BASE_URL}/facebook/auth/${userId}?short_tfb=${facebookShortToken}&id_user=${facebookUserId}`);
        return response.data;
    },

    revoke: async (userId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/facebook/revoke/${userId}`);
        return response.data;
    }
};