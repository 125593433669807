import React, { useContext, useState } from "react";
import { FormattedMessage } from 'react-intl';
import avatar from './../../utils/images/avatar_fluincr.png';

import { CurrencyContext } from "../../context/currencyContext";
import { useEffect } from "react";
import ItemCollaboration from "./ItemColaboration";
import { formatNumber } from "../../services/generalSevices";
import ModalEditCollaboration from "../../components/ModalEditCollaboration/ModalEditCollaboration";


export default function CollaborationCart({ collaboration, handlerDeleteCollaboration }) {

    const { currency, convertCurrency } = useContext(CurrencyContext);

    const auxCurrency = currency.symbol;

    const [totalPrice, setTotalPrice] = useState(collaboration?.base_amount);

    const [giftValue, setGiftValue] = useState(0);

    const [showEditCollaboration, setShowEditCollaboration] = useState(false);

    useEffect(() => {
        setTotalPrice(convertCurrency(collaboration?.base_amount, auxCurrency)?.toFixed(2));

        if (collaboration.gift && collaboration.gift_value) {
            setGiftValue(convertCurrency(collaboration.gift_value, auxCurrency)?.toFixed(2));
        }

    }, [currency, collaboration])

    const listWithQuantity = Object.values(
        collaboration.items.reduce((acc, item) => {
          if (!acc[item.service_id]) {
            acc[item.service_id] = { item, quantity: 1 };
          } else {
            acc[item.service_id].quantity += 1;
          }
          return acc;
        }, {})
    );


    return (<div className="item">
        <div className="collaboration">
            <div className="influencerImage" style={{ backgroundImage: `url(${collaboration?.influencer?.image_profile ?? avatar})` }}>
                <div className="influencerName"><p>{collaboration?.influincer?.name} {collaboration?.influincer?.family_name}</p></div>
            </div>
            <div className="collaborationData">
                <table className="collaborationDetails">
                    <tbody>
                        <tr>
                            <th className="firstColumn"><FormattedMessage id="cart_text_5" defaultMessage="Colaboración" /></th>
                            <th className="firstColumn">{currency.symbol} ${formatNumber(totalPrice)}</th>
                        </tr>
                    
                        {listWithQuantity.map(({item, quantity}) => {
                            return (
                                <ItemCollaboration item={item} quantity={quantity} key={item.id} />
                            )
                        })}
                        {(collaboration?.gift) && (<tr>
                            <td ><FormattedMessage id="cart_text_12" defaultMessage="Servicio/ producto gratis" /></td>
                            <td className="freeProduct"><span className="lessIcon">- </span>${formatNumber(giftValue)}</td>
                        </tr>)}
                    </tbody>
                </table>
                <p className="editCollaboration" onClick={() => {
                    setShowEditCollaboration(true);
                }}><FormattedMessage id="cart_text_7" defaultMessage="Editar colaboración" /></p>

                <p className="deleteCollaboration" onClick={() => {
                    handlerDeleteCollaboration(collaboration.id)
                }}><FormattedMessage id="cart_text_13" defaultMessage="Eliminar colaboración" /></p>
            </div>


        </div>

        <div className="productImage" style={{ backgroundImage: `url(${collaboration.product?.image ?? avatar})` }}>
            <div className="productName">
                <p>{collaboration.product?.name}</p>
            </div>
        </div>

        {(showEditCollaboration) && (<ModalEditCollaboration setShowEditCollaboration={setShowEditCollaboration} collaboration={collaboration} />)}
    </div>)
}