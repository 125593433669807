export function getAge(dateString) {
    let now = new Date()
    let birthday = new Date(dateString)
    let age = now.getFullYear() - birthday.getFullYear()
    let diferenciaMeses = now.getMonth() - birthday.getMonth()
    if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && now.getDate() < birthday.getDate())
    ) {
        age--
    }
    return age;
}


export const toUTCString = (date) => {
    return date.toISOString().replace('T', ' ').substr(0, 19);
};

export const formatDateLocal = (stringDate, monthValue = 'short') => {
    let formatedDate = '';
    const addZ = stringDate.includes('Z') ? '' : 'Z';
    if (stringDate) {
        try {
            formatedDate = new Date(`${stringDate}${addZ}`).toLocaleDateString( /* 'en-US', 'es-CO',*/undefined, {
                day: 'numeric',
                month: monthValue,
                year: 'numeric',
            });
        } catch (error) { }
    }
    return formatedDate;
};

// TODO: Intercambiar nombres con el de arriba
export const formatDateTimeLocal = (stringDateTime) => {
    let formattedDate = '';
    if (stringDateTime) {
        try {
            const date = new Date(stringDateTime);
            // Ajustar la fecha para evitar desfase por zona horaria
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            formattedDate = date.toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
        } catch (error) {
            console.error(error);
        }
    }
    return formattedDate;
}

export const formatTimeLocal = (stringTime) => {
    let formatedDate = '';
    if (stringTime) {
        try {
            formatedDate = new Date(`${stringTime}Z`).toLocaleTimeString({
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
        } catch (error) { }
    }
    return formatedDate;
};

export const formatDateISO8601 = (date) => {
    if(date && date instanceof Date){
        // Obtener los componentes de la fecha
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses son indexados desde 0
        const day = String(date.getDate()).padStart(2, '0');

        // Formatear la fecha en el formato YYYY/MM/DD
        return  `${day}/${month}/${year}`;
    }
}


export const countdown = ({ date, days }) => {

    function sumarDias(date, dias) {
        const fecha = new Date(date)
        fecha.setDate(fecha.getDate() + dias)
        return fecha;

    }

    const auxDate = sumarDias(date, days)
    const difference = new Date(auxDate) - new Date(toUTCString(new Date()));

    if (difference > 0) {

        return <p><span>{Math.floor(difference / (1000 * 60 * 60 * 24))}d</span> <span>{Math.floor((difference / (1000 * 60 * 60)) % 24)}h</span> <span>{Math.floor((difference / 1000 / 60) % 60)}m</span> {/* <span>{Math.floor((difference / 1000) % 60)}s</span> */}
        </p>

    };
}


export const fromUTCtoLocal = (stringDate) => {

    const fechaUTC = new Date(stringDate);

    // Paso 2: Obtener la diferencia en minutos entre la zona horaria local y UTC
    const diferenciaZonaHoraria = fechaUTC.getTimezoneOffset();

    // Paso 3: Obtener la fecha y hora local
    const anioLocal = fechaUTC.getFullYear();
    const mesLocal = fechaUTC.getMonth();
    const diaLocal = fechaUTC.getDate();
    const horaLocal = fechaUTC.getHours();
    const minutosLocal = fechaUTC.getMinutes();
    const segundosLocal = fechaUTC.getSeconds();

    // Paso 4: Ajustar la fecha y hora usando la diferencia de zona horaria
    const fechaLocal = new Date(anioLocal, mesLocal, diaLocal, horaLocal, minutosLocal - diferenciaZonaHoraria, segundosLocal);

    return fechaLocal;
};

export const newFormat = (bddate) => {
    const date = new Date(fromUTCtoLocal(bddate));
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
}

