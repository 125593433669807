export const media = {
    extralargeDesktop: "@media(max-width: 2400px)",
    largeDesktop: "@media(max-width: 1920px)",
    specialScreen: "@media(max-width: 1680px)",
    bigmacdesktop: "@media(max-width: 1540px)",
    macdesktop: "@media(max-width: 1440px)",

    desktop: "@media(max-width: 1366px)",
    mediumDesktop: "@media(max-width: 1200px)",
    smallDesktop: "@media(max-width: 1024px)",

    laptop: "@media(max-width: 980px)",
    tablet: "@media(max-width: 800px)",
    smallTablet: "@media(max-width: 720px)",

    largeMobile: "@media(max-width: 620px)",
    mobile: "@media(max-width: 500px)",
    smallMobile: "@media(max-width: 420px)",
};  
