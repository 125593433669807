export const ContentTypes = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
}

export const ItemStatusId = {
    CART: 0,
    ACCEPTANCE_PENDING: 1,
    DECLINED: 2,
    UPLOAD_CONTENT: 3,
    APPROVE_CONTENT: 4,
    PUBLISH_CONTENT: 5,
    DISPUTE_WINDOW: 6,
    ON_HOLD: 7,
    COMPLETED: 8,
    CANCELLED: 9,
    OTHER: 10
}