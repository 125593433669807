import React, { useState, useContext, useEffect } from "react";
import { InfluencersContainer } from "./InfluencersStyled";
import { FormattedMessage } from "react-intl";

import InfluencerCard from "./../../components/InfluencerCard/InfluencerCar";
import Spinner from "./../../components/Spinner/Spinner";
import { useRef } from "react";
import { FiltersContext } from "../../context/filtersContext";
import FilterMenu from "./FilterMenu";
import { Divider, Radio } from "@mui/material";
import { InfluencerContext } from "../../context/user/influencerContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import FluincrDropdown from "../../components/Dropdown/FluincrDropdown";
import { colors } from "../../utils/styles/colors";
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { getCategoriesByInput, getCountriesByInput } from "../../utils/common/filter";

export default function Influencers() {
  const orderByRef = useRef(null);

  const { userData } = useContext(UserContext2);
  const { influencersList, setInfluencersList } = useContext(InfluencerContext);
  const { categorySelectList, countrySelectList } = useContext(StaticDataContext);
  const {
    featuredFilter,
    newUserFilter,
    languageFilter,
    ratingFilter,
    verifiedFilter,
    genderFilter,
    ageFilter,
    networkFilter,
    categoryFilter,
    countryFilter,
    priceFilter,
    setPriceFilter,
    queryPrice,
    inputFilter,
    orderBy,
    setOrderBy,
    favoriteFilter,
  } = useContext(FiltersContext);

  const [influencers, setInfluencers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const CARDS_PER_PAGE = 12;
  const [offset, setOffset] = useState(0);

  const [total, setTotal] = useState(0);

  const [showOrderByMenu, setShowOrderByMenu] = useState(false);

  const seeMore = () => {
    const newOffset = offset + 1;
    setOffset(newOffset);
    updateInfluencers(newOffset);
  };

  useEffect(() => {
    if(offset > 0){
      setOffset(0);
    }
    updateInfluencers(0);
  }, [
    newUserFilter,
    featuredFilter,
    languageFilter,
    ratingFilter,
    verifiedFilter,
    genderFilter,
    ageFilter,
    networkFilter,
    categoryFilter,
    countryFilter,
    priceFilter,
    favoriteFilter,
    orderBy,
  ]);

  useEffect(() => {
    if(inputFilter && inputFilter.length > 2 || inputFilter === ''){
      if(offset > 0){
        setOffset(0);
      }
      updateInfluencers(0);
    }
  }, [inputFilter])

  useEffect(() => {
    function handleClickOutside(event) {
      if (orderByRef.current && !orderByRef.current.contains(event.target)) {
        setShowOrderByMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [orderByRef]);

  const updateInfluencers = (page) => {
    console.log("updateInfluencers: ", inputFilter);
    setIsLoading(true);
    const languageQueryString =
      languageFilter.length > 0 ? languageFilter.join(",") : null;
    const categoryQueryString =
      categoryFilter.length > 0 ? categoryFilter.join(",") : null;
    const countryQueryString =
      countryFilter.length > 0 ? countryFilter.join(",") : null;
    const genderQueryString =
      genderFilter.length > 0 ? genderFilter.join(",") : null;
    const favoriteQueryString =
      favoriteFilter ? (userData?.likes ? userData?.likes?.join(",") : []) : null;

    let countryQueryStringInput;
    let categoryQueryStringInput;
    
    if(inputFilter.length > 2){
      const categories = getCategoriesByInput(categorySelectList, inputFilter);
      const countries = getCountriesByInput(countrySelectList, inputFilter);
      categoryQueryStringInput = categories.join(",");
      countryQueryStringInput = countries.join(",");
    }

    let filters = {
      ...(featuredFilter && { featured: featuredFilter }),
      ...(newUserFilter && { new: newUserFilter }),
      ...(languageQueryString && { languages: languageQueryString }),
      ...(countryQueryString && { countries: countryQueryString }),
      ...(categoryQueryString && { categories: categoryQueryString }),
      ...(genderQueryString && { genders: genderQueryString }),
      ...(ratingFilter && { rating: ratingFilter }),
      ...(verifiedFilter && { verified: verifiedFilter }),
      ...(favoriteFilter && { likes: favoriteQueryString }),
      ...(inputFilter && inputFilter.length > 2 && { searchText: inputFilter }),
      ...(categoryQueryStringInput && { categoriesSearch: categoryQueryStringInput }),
      ...(countryQueryStringInput && { countriesSearch: countryQueryStringInput }),
      page: page,
      size: CARDS_PER_PAGE,
    };

    InfluencerAPI.getAllInfluencersFiltered(filters)
      .then((response) => {
        const influencersListUpdated = page === 0 ? response.content : [...influencersList, ...response.content];
        setInfluencers(influencersListUpdated);
        setInfluencersList(influencersListUpdated);
        setTotal(response.totalElements);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  return (
    <InfluencersContainer>
      <div className="influencersBody">
        <div className="menu">
          <FilterMenu />
        </div>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ marginX: 2 }}
        />

        <div className="influencersContent">
          <div className="results">
            <p className="totalResults">
              {total}{" "}
              <FormattedMessage
                id="companies_text_1"
                defaultMessage="resultados"
              />
            </p>
            <div className="orderBy" ref={orderByRef}>
              <div className="orderByTitle">
                <FluincrDropdown
                  title={
                    <FormattedMessage
                      id="companies_text_2"
                      defaultMessage="Ordenar por"
                    />
                  }
                >
                  <div style={{gap: 1}}>
                    <Radio
                      value="name"
                      checked={orderBy === "name"}
                      onChange={(event) => {
                        setOrderBy(event.target.value);
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: colors.hightlight,
                        },
                        p: 0, pr: 1, pb: ".25em"
                      }}
                    />
                    <label htmlFor="lowerRange">
                      <FormattedMessage
                        id="companies_subtext_2_1"
                        defaultMessage="Nombre"
                      />
                    </label>
                  </div>
                  <div>
                    <Radio
                      value="lowerRange"
                      checked={orderBy === "lowerRange"}
                      onChange={(event) => {
                        setOrderBy(event.target.value);
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: colors.hightlight,
                        },
                        p: 0, pr: 1, pb: ".25em"
                      }}
                    />
                    <label htmlFor="lowerRange">
                      <FormattedMessage
                        id="companies_subtext_2_2"
                        defaultMessage="Menor rango"
                      />
                    </label>
                  </div>
                  <div>
                    <Radio
                      value="higherRange"
                      checked={orderBy === "higherRange"}
                      onChange={(event) => {
                        setOrderBy(event.target.value);
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: colors.hightlight,
                        },
                        p: 0, pr: 1
                      }}
                    />
                    <label htmlFor="higherRange">
                      <FormattedMessage
                        id="companies_subtext_2_3"
                        defaultMessage="Mayor rango"
                      />
                    </label>
                  </div>
                </FluincrDropdown>
              </div>
            </div>
          </div>

          <div className="cards">
            {influencers.length > 0 &&
              influencers?.map((influencer) => (
                <InfluencerCard influencer={influencer} key={influencer.id} />
              ))}
          </div>

          {total === "0" && (
            <div className="noResults">
              <p>
                <FormattedMessage
                  id="influncer_no_results"
                  defaultMessage="No se han encontrado resultados"
                />
              </p>
            </div>
          )}

          {(CARDS_PER_PAGE * (offset + 1) < total) && (<p className="seeMore" onClick={seeMore}><FormattedMessage id="companies_text_3" defaultMessage="Ver más +" /></p>)}
        </div>
      </div>
      {isLoading && <Spinner />}
    </InfluencersContainer>
  );
}
