import React, { useState, useContext, useEffect } from "react";
import { InfluencerCardContainer } from "./InfluencerCardStyled";
import { useNavigate } from 'react-router-dom';

import avatar from './../../utils/images/avatar_fluincr.png';
import noCountry from './../../utils/images/world.png';

import { toUpperCase, lowerFare3, formatNumber } from "../../services/generalSevices";
import LikeInfluencer from "../LikeInfluencer/LikeInfluencer";
import { CurrencyContext } from "../../context/currencyContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { getSocialNetworkIconBySocialNetworkId } from "../../utils/constants/socialNetwork";
import { capitalizeFirstLetters } from "../../utils/common/format";


export default function InfluencerCar({ influencer, page }) {

    const navigate = useNavigate();

    const { categorySelectList, countrySelectList } = useContext(StaticDataContext);
    const { userType, userData } = useContext(UserContext2);
    const { currency, convertCurrency } = useContext(CurrencyContext);

    const [country, setCountry] = useState(null);
    const [price, setPrice] = useState(null);
    const [maxFollowers, setMaxFollowers] = useState(null);

    useEffect(() => {
        const amount = influencer?.rate_by_service_list?.filter(rate => rate?.price > 0)
            .reduce((precioMin, serviceActual) => {
                return (serviceActual.price < precioMin || precioMin === 0) ? serviceActual.price : precioMin;
            }, 0);
        const auxCurrency = currency.symbol

        setPrice(convertCurrency(amount, auxCurrency).toFixed(2));
    }, [currency])

    useEffect(() => {
        if (influencer?.address?.country_id !== null) {
            const country = countrySelectList.find((element) => element.value === influencer?.address?.country_id);
            setCountry(country);
        }
    }, [influencer, countrySelectList])

    useEffect(() => {
        const mostFollowed = influencer?.social_networks?.reduce((max, network) => {
            const followers = network.followers || 0; // Si followers es null, lo consideramos como 0
            return followers > max.followers ? network : max;
        }, { followers: 0, social_network: 0 });

        setMaxFollowers(mostFollowed);
    }, [])

    const formatFollowers = (followers) => {
        if (followers >= 1000000) {
            return (followers / 1000000).toFixed(1) + 'M'
        } else if (followers >= 1000) {
            return (followers / 1000).toFixed(1) + 'K'
        } else {
            return followers
        }
    }

    return (
        <InfluencerCardContainer page={page} >

            <div 
                className="image" 
                style={{ backgroundImage: `url(${(influencer.image_profile ? influencer.image_profile : avatar)})` }} 
                onClick={() => {
                    navigate(`/${influencer.username}`);
                }}    
            >

                <div className="likeContainer">
                    {(userType != UserTypes.INFLUENCER) ? (<LikeInfluencer profileId={influencer?.id}/>) : (<div className="favorite" />)}
                </div>


                {
                    <div className="data">
                        {
                            (price && price > 0) ?
                            <p style={{maxWidth: '60%'}}><span className="currencySymbol">{currency.symbol}</span> {formatNumber(price)}</p> 
                            : <p style={{backgroundColor: 'transparent'}}></p>
                        }
                        {
                            (maxFollowers?.followers > 0) && 
                            <p className="followers">
                                <span>{formatFollowers(maxFollowers?.followers)}</span>
                                <img src={getSocialNetworkIconBySocialNetworkId(maxFollowers?.social_network)} width={16} style={{margin: 'auto'}}/>
                            </p>}
                    </div>
                }
            </div>

            <h3 className="name" onClick={() => {
                navigate(`/${influencer.username}`);
            }}>{capitalizeFirstLetters(`${influencer?.name} ${influencer?.family_name}`)}</h3>

            <div className="influencerData">
                <img src={country?.image ?? noCountry} alt="" className="influencerCountry" />
                
                <div className="categories">
                    {
                        influencer?.categories?.map((categoryId, index) => {
                            if (index < 3) {
                                return (
                                    <span className="category" key={categoryId}>#{categorySelectList.find((element) => element.value === categoryId)?.label || ''}</span>
                                )
                            }
                        })

                    }
                </div>
            </div>
        </InfluencerCardContainer>
    )
}