import styled from 'styled-components';
import CentraleSans from './../../utils/styles/CentraleSansBold.otf';
import { media } from './../../utils/styles/media';


export const FilterCardContainer = styled.div`
    
    @font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }

    //display: inline-block;
    margin: 2%;
    width: 96%; 
    height: 150px;
    background-image:url(${(props) => props.image});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 15px;
    cursor: pointer;
    .degrade{
        width: 100%;
        height: 100%;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient( #05193799, #004d7a99, #00879399, #00bf7299, #a8eb1299);
        background: linear-gradient(#E8339E66, #741A4F);
        .text{
            color: #ffffff;
            font-family: 'Centrale', sans-serif;
            font-size: 2.8rem;
            font-weight: bold;
            text-align: center; 
            padding: 0 5px;
        }
    }

    .degrade:hover {
        background: linear-gradient( #e1a46266, #ee1446);   
    }
    
    :nth-child(2) .degrade:hover, :nth-child(4) .degrade:hover{
        background: linear-gradient( #49b6c566, #03c374);
    }


    /**----------Responsive--------- */
    ${media.largeDesktop}{
        height: 120px;
        .degrade{
            .text{
                font-size: 2rem;
                padding: 0 8px;
            }
        }
    }
    ${media.desktop}{

        height: 100px;
        .degrade{
            .text{
                font-size: 1.8rem;
            }
        }
    }
    ${media.smallDesktop}{
        height: 80px;
        .degrade{
            .text{
                font-size: 1.4rem;
                padding: 0 8px;
            }
        }
    }

    ${media.tablet}{
        height: 75px;
        .degrade{
            p{
                font-size: 1.6rem;
            }
        }
    }


`