import React, { useState, useEffect, useContext, useRef } from "react";
import { LangContext } from "../../context/langContext";
import { CustomInput, MyAccountContainer, ProfileImage } from "./MyAccountStyled";

import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import back from "./../../utils/images/arrow_back.svg";
import avatar from "./../../utils/images/avatar_fluincr.png";
import share from "./../../utils/images/share_new.svg";
import edit from "./../../utils/images/edit.svg";

import Select, { components } from "react-select";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { utils } from "@hassanmojab/react-modern-calendar-datepicker";


import Spinner from "./../../components/Spinner/Spinner";
import Alert from "./../../components/Alert/Alert";
import ModalSecurityCode from "../../components/ModalSecurityCode/ModalSecurityCode";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import { toUpperCase, capitalize } from "../../services/generalSevices";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import { URLplatform } from "../../utils/constants/constants";
import {
  selectCountryStyles,
  selectIndentificationStyles,
  selectPhoneStyles,
  selectStyles
} from "./MyAccountSelectStyles"
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CompanyAPI } from "../../api/user/company/company";
import { validateUserEdit } from "./MyAccountUserDataValidation";
import { UserTypes } from "../../utils/constants/user";
import { UserAPI } from "../../api/user/user";
import { PaymentAPI } from "../../api/payment/payment";
import { Box, Typography } from "@mui/material";

export default function MyAccount() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LangContext);
  const {
    countrySelectList,
    indicativesSelectList,
    genderSelectList,
    identificationTypeSelectList,
    identificationTypeSelectCompanyList,
    languagePrimarySelectList,
    securityQuestionSelectList
  } = useContext(StaticDataContext);

  const birthdayRef = useRef(null);
  const shareRef = useRef(null);
  const { Option } = components;

  const [isLoading, setIsLoading] = useState(false);
  const [showAlertChangeSuccessful, setShowAlertChangeSuccessful] =
    useState(false);
  const [showAlertChangeFail, setShowAlertChangeFail] = useState(false);
  const [showAlertDeleteAccount, setShowAlertDeleteAccount] = useState(false);
  const [showDeletePayment, setShowDeletePayment] = useState(false);

  const handleCheckboxChange = () => {
    if (showEdit) {
      setIsCheckboxChecked(!isCheckboxChecked);
    }
  };

  const { userData, setUserData, userType, setUserType, setUserToken } = useContext(UserContext2);

  const [userDataAux, setUserDataAux] = useState(userData);
  const [userAddressAux, setUserAddressAux] = useState(userData?.address);
  const [userContactAux, setUserContactAux] = useState(userData?.user_contact);
  const [userSecurityQuestionAux, setUserSecurityQuestionAux] = useState(userData?.security_question_answer ? userData?.security_question_answer[0] : null);

  const [editingFields, setEditingFields] = useState(new Set());

  useEffect(() => {
    if(userData){
      setUserDataAux(userData);
      setUserAddressAux(userData?.address);
      setUserContactAux(userData?.user_contact);
      setUserSecurityQuestionAux(userData?.security_question_answer ? userData?.security_question_answer[0] : null);
    }
  }, [userData]);

  const handleEditField = (fieldName) => {
    const updatedEditingFields = new Set(editingFields);
    if (updatedEditingFields.has(fieldName)) {
      updatedEditingFields.delete(fieldName)
    } else {
      updatedEditingFields.add(fieldName);
    }
    setEditingFields(updatedEditingFields);
  };

  useEffect(() => {
    setUserDataAux({
      ...userDataAux,
      address: userAddressAux
    });
  }, [userAddressAux]);

  useEffect(() => {
    setUserDataAux({
      ...userDataAux,
      user_contact: userContactAux
    });
  }, [userContactAux]);

  useEffect(() => {
    setUserDataAux({
      ...userDataAux,
      security_question_answer: [userSecurityQuestionAux]
    });
  }, [userSecurityQuestionAux]);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(userDataAux?.name === userDataAux?.legal_name);

  const [selectedDay, setSelectedDay] = useState(null);
  const [paymentMethod] = useState(userData?.payment_method);

  const [editBirthday, setEditBirthday] = useState(false);

  const [isUniqueNameAvailable, setIsUniqueNameAvailable] = useState(true);
  const [isUniqueNameEmpty, setIsUniqueNameEmpty] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [showEdit, setShowEdit] = useState(false);

  const toggleEdit = () => {
    if (showEdit) {
      save();
    } else {
      setShowEdit(true);
    }
  };

  const [showEditPassword, setShowEditPassword] = useState(false);

  const [showAlertConfirmDelete, setShowAlertConfirmDelete] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);

  const [showAlertConfirmDesactivateAccount, setShowAlertConfirmDesactivateAccount] = useState(false);
  const [showAlertAccountDesactivated, setShowAlertAccountDesactivated] = useState(false);

  const url = `${URLplatform}/${userDataAux?.username}`;

  //TODO: PENDIENTE
  const [promotions, setPromotions] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (birthdayRef.current && !birthdayRef.current.contains(event.target)) {
        setEditBirthday(false);
      }
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShowSocialMedia(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [birthdayRef, shareRef]);

  const IconPhoneOption = (props) => {
    return (
      <Option {...props}>
        <img
          src={props.data.flag}
          style={{ width: 20, marginRight: 5 }}
          alt={props.data.label}
        />
        <span>+{props.data.label}</span>
      </Option>
    );
  };

  const IconCountryOption = (props) => {
    return (
      <Option {...props}>
        <img
          src={props.data.image}
          style={{ width: 20, marginRight: 5 }}
          alt={props.data.label}
        />
        <span>{props.data.label}</span>
      </Option>
    );
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder={intl.formatMessage({
        id: "signup_datepicker",
        defaultMessage: "Elige tu fecha de nacimiento"
      })}
      value={
        selectedDay
          ? `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`
          : ""
      }
      style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: 500,
        fontFamily: "'Open Sans', sans serif",
        border: "1px solid #BCBCBC",
        borderRadius: "10px",
        // width: '100%',
        outline: "none",
        width: "250px"
      }}
      className="my-custom-input-class"
    />
  );

  const checkUniqueName = () => {
    const usernameAux = userDataAux?.username;
    if (usernameAux) {
      setIsUniqueNameEmpty(false);
      UserAPI.verifyUsernameExisting(usernameAux).then((usernameExist) => {
        setIsUniqueNameAvailable(!usernameExist); 
      }).catch((error) => {
        console.log(error);
        setIsUniqueNameAvailable(false);
        setUserDataAux({
          ...userDataAux,
          username: userData?.username
        });
      });
    } else {
      setUserDataAux({
        ...userDataAux,
        username: userData?.username
      });
      setIsUniqueNameEmpty(true);
    }
  };

  const checkEmail = () => {
    // Asegúrate de que auxEmail esté definida y tenga un valor
    if (userContactAux['email']) {
      const lowercaseAuxEmail = userContactAux?.email?.toLowerCase(); // Llevar a minúsculas una vez

      if (lowercaseAuxEmail !== userData?.email?.toLowerCase()) {
        UserAPI.verifyEmailExisting(lowercaseAuxEmail).then((emailExist) => {
          setIsEmailAvailable(!emailExist); 
          if (!emailExist) {
            setShowSecurityCode(true);
          }
        }).catch((error) => {
          console.log(error);
          setIsEmailAvailable(false);
        });
      } else {
        setIsEmailAvailable(true);
      }
    }
  };


  const save = () => {
    setIsLoading(true);
    setShowEdit(false);

    if(userContactAux['email'] !== userData?.user_contact?.email && !isEmailVerified){
      setUserContactAux({
        ...userContactAux,
        email: userData?.user_contact?.email
      });
    }

    if(!validateUserEdit(userDataAux, userType === UserTypes.COMPANY)){
      setIsLoading(false);
      setShowAlertChangeFail(true);
      return;
    }

    if(userType === UserTypes.INFLUENCER){
      InfluencerAPI.updateUserInfluencer(userDataAux).then((response) => {
        setIsLoading(false);
        setShowAlertChangeSuccessful(true);
        setUserData(userDataAux)
      }).catch((error) => {
        setShowAlertChangeFail(true);
        setIsLoading(false);
        console.log(error);
      });
    } else{
      CompanyAPI.updateUserCompany(userDataAux).then((response) => {
        setIsLoading(false);
        setShowAlertChangeSuccessful(true);
        setUserData(userDataAux)
      }).catch((error) => {
        setShowAlertChangeFail(true);
        setIsLoading(false);
        console.log(error);
      });
    }
  };

  const deleteAccount = () => {
    setShowAlertConfirmDelete(false);
    setIsLoading(true);

    UserAPI.generateUserDeletionEmail(userData?.user_contact?.email).then((response) => {
        setShowAlertDeleteAccount(true);
        setIsLoading(false);
    }).catch((error) => {
        setIsLoading(false);
        console.log(error);
    });
  };

  const desactivateAccount = () => {
    setShowAlertConfirmDesactivateAccount(false);
    setIsLoading(true);

    UserAPI.desactivateUser().then((response) => {
        setShowAlertAccountDesactivated(true);
        setIsLoading(false);
    }).catch((error) => {
        setIsLoading(false);
        console.log(error);
    });
  }

  const deletePayment = () => {
    setIsLoading(true);
    PaymentAPI.disconectPaymentMethod(userData?.id).then((response) => {
      setIsLoading(false);
      setShowDeletePayment(false);
      setUserData({
        ...userData,
        payment_method: null
      });
    }).catch((error) => {
      setIsLoading(false);
      console.log(error);
    });
  };

  const getUserAddressFotmated = () => {
    if (userAddressAux && countrySelectList) {
      const country = countrySelectList.find((country) => country?.value === userAddressAux?.country_id);
      const countryName = country?.label;
      return `${userAddressAux.street},\n\n${userAddressAux.city}, ${userAddressAux.state}, ${userAddressAux.postal_code}\n${countryName}`;
    }
    return '';
  }

  const [userAddressAuxFormatted, setUserAddressAuxFormatted] = useState('');

  useEffect(() => {
    setUserAddressAuxFormatted(getUserAddressFotmated());
  }, [userAddressAux, countrySelectList]);


  return (
    <>
      { userData && userDataAux && Object.values(userDataAux).length > 0 && 
        <MyAccountContainer>
          <div className="goBack" onClick={() => navigate(-1)}>
            <img src={back} alt="go back" className="backIcon" />
            <p className="backText">
              <FormattedMessage id="go_back" defaultMessage="Ir atrás" />
            </p>
          </div>
          <div className="myAccountInformation">
            <h2 className="title">
              <FormattedMessage id="myAccount_title" defaultMessage="Mi cuenta" />
            </h2>
            <div className="profile">
              <ProfileImage
                image={userData?.image_profile ?? avatar} />

              <div className="profileMainData">
                <p className="name">

                  {userType === UserTypes.INFLUENCER && (
                    <><div className="inputContainer2">
                      <p className="nameTitle">
                        <FormattedMessage
                          id="display_name_1_1"
                          defaultMessage="Nombre visible:" />
                      </p>
                      <label className="label" onClick={handleCheckboxChange}></label>
                      <input
                        type="checkbox"
                        className="inputAccept"
                        checked={isCheckboxChecked}
                        disabled={!showEdit}
                        // disabled={!showEdit}
                        onClick={handleCheckboxChange} />
                      <FormattedMessage id="display_name" className="text" />
                    </div></>
                  )}

                  {userType === UserTypes.COMPANY && (
                    <>
                      <div className="inputContainer2Company">
                        <p className="nameTitle">
                          <FormattedMessage
                            id="display_name_1_1"
                            defaultMessage="Nombre visible:" />
                        </p>
                        <label className="label" onClick={handleCheckboxChange}></label>
                        <input
                          type="checkbox"
                          className="inputAccept"
                          checked={isCheckboxChecked}
                          // disabled={!showEdit}
                          onClick={handleCheckboxChange} />
                        <FormattedMessage id="display_name" className="text" />
                      </div>
                    </>
                  )}

                  {/* {(userType === 'influencer') ? (toUpperCase({ fullName: `${name} ${lastName}` })) : (toUpperCase({ fullName: `${companyName}` }))} */}
                  {/* <img src={share} alt="edit" className="share" /> */}

                  {
                    (editingFields.has('name') ? (
                      <>
                        <input
                          type="text"
                          value={userDataAux?.name}
                          onChange={(event) => {
                            setUserDataAux({
                              ...userDataAux,
                              name: event.target.value.trim()
                            })
                          }}
                          onBlur={() => {
                            handleEditField('name');
                          }}
                          placeholder={userType === UserTypes.INFLUENCER ? intl.formatMessage({ id: "signup_input_1" }) : intl.formatMessage({ id: "signup_input_8" })}
                          style={editingFields.has('name') ? { borderBottom: '1px solid', marginRight: 10, textAlign: 'center' } : { textAlign: 'left' }}
                        />
                        {
                          userType === UserTypes.INFLUENCER &&
                          <input
                            type="text"
                            value={userDataAux?.family_name}
                            onChange={(event) => {
                              setUserDataAux({
                                ...userDataAux,
                                name: event.target.value.trim()
                              })
                            }}
                            onBlur={() => {
                              handleEditField('name');
                            }}
                            placeholder={intl.formatMessage({ id: "signup_input_2" })}
                            style={editingFields.has('name') ? { borderBottom: '1px solid', textAlign: 'center' } : { textAlign: 'left' }}
                          />
                        }
                      </>
                    ) : (
                      userType === UserTypes.INFLUENCER ?
                        capitalize(userDataAux?.name + ' ' + userDataAux?.family_name) :
                        capitalize(userDataAux?.name)
                    )
                    )
                  }
                  {showEdit && (
                    <img
                      src={edit}
                      alt="edit"
                      className="edit"
                      onClick={() => handleEditField('name')} />
                  )}

                </p>
                <p className="account">
                  <FormattedMessage
                    id="myAccount_subtitle_1"
                    defaultMessage="Cuenta #" />
                  {userData?.account}
                  {" ("}
                  {false && (
                    <span>
                      <FormattedMessage
                        id="myAccount_state_1"
                        defaultMessage="Activa" />
                    </span>
                  )}

                  {false === "ELIMINADO" && (
                    <span>
                      <FormattedMessage
                        id="myAccount_state_4"
                        defaultMessage="Eliminado" />
                    </span>
                  )}

                  {false === "SUSPENDIDO" && (
                    <span>
                      <FormattedMessage
                        id="myAccount_state_5"
                        defaultMessage="Suspendido" />
                    </span>
                  )}

                  {userData?.verified ? (
                    <span>
                      {" / "}
                      <FormattedMessage
                        id="myAccount_state_2"
                        defaultMessage="Verificada" />
                    </span>
                  ) : (
                    <span>
                      {" / "}
                      <FormattedMessage
                        id="myAccount_state_3"
                        defaultMessage="No Verificada" />
                    </span>
                  )}
                  {") "}
                </p>
              </div>
            </div>
            <div className="informationContainer">
              {(userType === UserTypes.INFLUENCER || userType === UserTypes.COMPANY) && (
                <div className="information">
                  {!isCheckboxChecked ? (
                    <p className="subtitle">
                      <FormattedMessage id="signup_input_2_1" />
                    </p>
                  ) : null}

                  {!isCheckboxChecked && (
                    <input type="text" value={userDataAux?.legal_name} readOnly={editingFields.has('legalName')} placeholder={
                      !isCheckboxChecked ?
                        (userType === UserTypes.INFLUENCER ? '' : intl.formatMessage({ id: 'signup_input_3_2' })) :
                        ''
                    }
                      onChange={(event) => {
                        if (editingFields.has('legalName')) {
                          setUserDataAux({
                            ...userDataAux,
                            legalName: event.target.value.trim()
                          });
                        }
                      }}
                      style={!isCheckboxChecked && editingFields.has('legalName') ? { borderBottom: '1px solid', textAlign: 'center', maxWidth: '90%' } : { textAlign: 'left' }}
                    />
                  )}
                </div>
              )}

              {showEdit && !isCheckboxChecked && (
                <img
                  src={edit}
                  alt="edit"
                  className="editNewFields"
                  onClick={() => handleEditField('legalName')}
                />
              )}

            </div>
            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage
                    id="myAccount_subtitle_2"
                    defaultMessage="Nombre de usuario:" />
                </p>
                <div>
                  <p>
                    fluincr.com/
                    {
                      editingFields.has('username') ? (
                        <input
                          type="text"
                          value={userDataAux?.username}
                          onChange={(event) => {
                            setUserDataAux({
                              ...userDataAux,
                              username: event.target.value.trim()
                            })
                          }}
                          onBlur={() => {
                            handleEditField('username');
                            checkUniqueName();
                          }}
                          style={editingFields.has('username') ? { borderBottom: '1px solid' } : { textAlign: 'left' }}
                        />
                      ) : (userDataAux && userDataAux?.username)
                    }
                  </p>

                  {!isUniqueNameAvailable && (
                    <p className="error">
                      <FormattedMessage
                        id="myAccount_error_1"
                        defaultMessage="Este user name no está disponible" />
                    </p>
                  )}
                  {isUniqueNameEmpty && (
                    <p className="error">
                      <FormattedMessage
                        id="myAccount_error_6"
                        defaultMessage="El user name no puede ser vacío" />
                    </p>
                  )}
                </div>

                <div className="share" ref={shareRef}>
                  <img
                    src={share}
                    alt="edit"
                    className="shareIcon"
                    onClick={() => {
                      setShowSocialMedia(!showSocialMedia);
                    }} />
                  {showSocialMedia && <SocialMedia shareUrl={url} />}
                </div>

                {/* <img src={share} alt="edit" className="share" /> */}
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => { handleEditField('username') }}
                />
              )}
            </div>

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage id="myAccount_subtitle_3" defaultMessage="Email:" />
                </p>
                <div>
                  {
                    editingFields.has('email') ? (
                      <input
                        type="email"
                        value={userContactAux['email']}
                        onChange={(event) => {
                          setUserContactAux({
                            ...userContactAux,
                            email: event.target.value.trim()
                          });
                        }}
                        onBlur={() => {
                          handleEditField('email');
                          checkEmail();
                        }}
                        style={editingFields.has('email') ? { borderBottom: '1px solid' } : { textAlign: 'left' }}
                      />

                    ) : (<p>{userContactAux && userContactAux['email']}</p>)
                  }
                  {!isEmailAvailable && (
                    <p className="error">
                      <FormattedMessage
                        id="myAccount_error_2"
                        defaultMessage="Este email no está disponible" />
                    </p>
                  )}
                </div>
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => { handleEditField('email') }}
                />
              )}
            </div>

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage id="myAccount_subtitle_4" defaultMessage="Email secundario:" />
                </p>
                <div>
                  {editingFields.has('second_email') ? (
                    <input
                      type="email"
                      value={userContactAux['second_email']}
                      onChange={(event) => {
                        setUserContactAux({
                          ...userContactAux,
                          second_email: event.target.value.trim()
                        });
                      }}
                      onBlur={() => {
                        handleEditField('second_email');
                      }}
                      style={editingFields.has('second_email') ? { borderBottom: '1px solid' } : { textAlign: 'left' }}
                    />
                  ) : (
                    <p>{userContactAux && userContactAux['second_email']}</p>
                  )}
                  {!isEmailValid && (
                    <p className="error">
                      <FormattedMessage
                        id="myAccount_error_3"
                        defaultMessage="Este email no es válido" />
                    </p>
                  )}
                </div>
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => { handleEditField('second_email') }}
                />
              )}
            </div>

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage
                    id="myAccount_subtitle_5"
                    defaultMessage="Contraseña:" />
                </p>
                <div className="password">
                  <div>
                    <p>xxxxxxxxxx</p>
                  </div>

                  <div className="verification">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDataAux?.two_step_verification}
                        disabled={!showEdit}
                        onClick={(event) => {
                          setUserDataAux({
                            ...userDataAux,
                            two_step_verification: event.target.checked
                          });
                        }} />
                      <div className="slider"></div>
                    </label>

                    <FormattedMessage
                      id="myAccount_text_1"
                      defaultMessage="Vefificación de dos pasos" />
                  </div>
                </div>
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit" /* onClick={() => { setEditPassword(!editPassword) }} */
                  onClick={() => {
                    setShowEditPassword(true);
                  }} />
              )}
            </div>

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage id="myAccount_subtitle_6" defaultMessage="Pregunta de seguridad:" />
                </p>

                {editingFields.has('security_question') ? (
                  <div className="securityQuestionContainer">
                    <Select
                      options={securityQuestionSelectList}
                      styles={selectStyles}
                      defaultValue={
                        securityQuestionSelectList
                          .find(sq => (sq.value === userSecurityQuestionAux?.security_question_id))
                      }
                      placeholder={intl.formatMessage({ id: "myAccount_text_7" })}
                      onChange={(event) => {
                        setUserSecurityQuestionAux({
                          ...userSecurityQuestionAux,
                          security_question_id: event.value
                        });
                      }}
                    />
                  </div>
                ) : (
                  <p>
                    {
                      securityQuestionSelectList
                        .find(sq => sq.value === userSecurityQuestionAux?.security_question_id)?.label
                    }
                  </p>
                )}
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => { handleEditField('security_question') }}
                />
              )}
            </div>

            {
              editingFields.has('security_question') && (
                <div className="informationContainer">
                  <div className="information">
                    <p className="subtitle">
                      <FormattedMessage id="myAccount_subtitle_13" defaultMessage="Respuesta" />
                    </p>
                    <input
                      type="text"
                      value={userSecurityQuestionAux?.answer}
                      onChange={(event) => {
                        setUserSecurityQuestionAux({
                          ...userSecurityQuestionAux,
                          answer: event.target.value
                        });
                      }}
                      onBlur={() => {
                        handleEditField('security_question');
                      }}
                      style={editingFields.has('security_question') ? { borderBottom: '1px solid' } : { textAlign: 'left' }}
                    />
                  </div>
                </div>
              )
            }

            { 
              (userType === UserTypes.INFLUENCER || userType === UserTypes.COMPANY) && (
                <div className="informationContainer">
                <div className="information">
                  <p className="subtitle">
                    <FormattedMessage
                      id="myAccount_subtitle_7"
                      defaultMessage="Teléfono:" />
                  </p>
                  <div>
                    {editingFields.has('phone') ? (
                      <div className="phoneContainer">
                        <Select
                          options={indicativesSelectList}
                          styles={selectPhoneStyles}
                          placeholder={intl.formatMessage({ id: "myAccount_text_5" })}
                          defaultValue={
                            indicativesSelectList.find(i => i.value === userContactAux['indicative']) || ''
                          }
                          onChange={(event) => {
                            setUserContactAux({
                              ...userContactAux,
                              indicative: event?.value
                            });
                          }}
                          components={{ Option: IconPhoneOption }} />
                        <input
                          type="number"
                          defaultValue={userContactAux['phone_number'] || ''}
                          onChange={(event) => {
                            if (/^\d*$/.test(event.target.value)) {
                              setUserContactAux({
                                ...userContactAux,
                                phone_number: event.target.value
                              });
                            }
                          }}
                          onBlur={() => {
                            handleEditField('phone');
                          }}
                          style={editingFields.has('phone') ? { borderBottom: '1px solid', textAlign: 'center' } : { textAlign: 'left' }} />
                      </div>
                    ) : (
                      <p>
                        {userContactAux && (
                          <>
                            {userContactAux['indicative'] && <span>{`(+${userContactAux['indicative']})`}</span>}
                            {userContactAux['phone_number'] && <span> {userContactAux['phone_number']}</span>}
                          </>
                        )}
                      </p>
                    )}
                    {!isPhoneValid && (
                      <p className="error">
                        <FormattedMessage
                          id="myAccount_error_5"
                          defaultMessage="Este télefono no es válido" />
                      </p>
                    )}
                  </div>
                </div>
                {showEdit && (
                  <img
                    src={edit}
                    alt="edit"
                    className="edit"
                    onClick={() => { handleEditField('phone') }} />
                )}
                </div>
              )
            }

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  <FormattedMessage
                    id="signup_input_7_2"
                    defaultMessage="ID/Identificación" />
                </p>

                {editingFields.has('identification') ? (
                  <div className="identificationContainer">
                    <div className="identificationType">
                      {(
                        <Select
                          options={userType === UserTypes.INFLUENCER ? identificationTypeSelectList : identificationTypeSelectCompanyList}
                          styles={selectIndentificationStyles}
                          defaultValue={identificationTypeSelectList.find(idType => idType.value === userDataAux?.identification_type_id)}
                          placeholder={intl.formatMessage({
                            id: "myAccount_text_6"
                          })}
                          onChange={(event) => {
                            setUserDataAux({
                              ...userDataAux,
                              identification_type_id: event.value
                            });
                          }} />
                      )}
                    </div>

                    <input
                      type="text"
                      value={userDataAux?.identification_number}
                      onChange={(event) => {
                        setUserDataAux({
                          ...userDataAux,
                          identification_number: event.target.value
                        });
                      }}
                      onBlur={() => {
                        handleEditField('identification');
                      }}
                      className="identification"
                      style={editingFields.has('identification') ? { borderBottom: '1px solid', textAlign: 'center' } : { textAlign: 'left' }}
                    />
                  </div>
                ) : (
                  <p>{`${identificationTypeSelectList.find(idType => idType.value === userDataAux?.identification_type_id)?.label} ${userDataAux['identification_number']}`}</p>
                )}
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => {
                    handleEditField('identification');
                  }}
                />
              )}
            </div>

            {
              userType === UserTypes.INFLUENCER && (
                <>
                  <div className="informationContainer">
                    <div className="information">
                      <p className="subtitle">
                        <FormattedMessage id="myAccount_subtitle_8" defaultMessage="Género:" />
                      </p>
                      {editingFields.has('gender') ? (
                        <div className="gendersContainer">
                          <div className="genders">
                            {genderSelectList.map((genderItem) => (
                              <div className="gender" key={genderItem.id}>
                                <label htmlFor={genderItem.code}>
                                  {locale === "es-CO" ? genderItem?.nombre : genderItem?.name}
                                </label>
                                <input
                                  type="radio"
                                  id={genderItem.code}
                                  name="gender"
                                  value={genderItem.id}
                                  onChange={(event) => {
                                    setUserDataAux({
                                      ...userDataAux,
                                      gender_id: event.target.value
                                    })
                                  }}
                                  checked={userDataAux?.gender_id === genderItem.id}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <p className="genderName">
                          {
                            (locale === "es-CO") ?
                              genderSelectList.find(genderItem => genderItem.value === userDataAux?.gender_id)?.nombre :
                              genderSelectList.find(genderItem => genderItem.value === userDataAux?.gender_id)?.name
                          }
                        </p>
                      )}
                    </div>
                    {showEdit && (
                      <img
                        src={edit}
                        alt="edit"
                        className="edit"
                        onClick={() => {
                          handleEditField('gender')
                        }} />
                    )}
                  </div>

                  <div className="informationContainer" ref={birthdayRef}>
                    <div className="information">
                      <p className="subtitle">
                        <FormattedMessage
                          id="myAccount_subtitle_9"
                          defaultMessage="Fecha de nacimiento:" />
                      </p>

                      {editingFields.has('birthday') ? (
                        <DatePicker
                          value={selectedDay}
                          onChange={
                            (value) => {
                              setSelectedDay(value);
                              setUserDataAux({
                                ...userDataAux,
                                birthday: `${value.year}-${value.month}-${value.day}`
                              });

                            }
                          }
                          //calendarClassName="custom-calendar"
                          renderInput={renderCustomInput}
                          colorPrimary="#E8339E"
                          maximumDate={utils().getToday()} />
                      ) : (
                        <p>{userDataAux.birthday}</p>
                      )}
                    </div>
                    {showEdit && (
                      <img
                        src={edit}
                        alt="edit"
                        className="edit"
                        onClick={() => {
                          handleEditField('birthday');
                        }} />
                    )}
                  </div>
                </>
              )
            }

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle">
                  {userType === UserTypes.INFLUENCER ? (
                    <FormattedMessage
                      id="myAccount_subtitle_10_a"
                      defaultMessage="Dirección de envío:" />
                  ) : (
                    <FormattedMessage
                      id="myAccount_subtitle_10_b"
                      defaultMessage="Dirección:" />
                  )}
                </p>
                {editingFields.has('address') ? (
                  <div className="address">
                    <CustomInput
                      type="text"
                      style={{ textAlign: 'center', borderBottom: '1px solid' }}
                      placeholder={intl.formatMessage({ id: "address_details" })}
                      value={userAddressAux['street']}
                      className="addressInput span-2"
                      onChange={(event) => {
                        setUserAddressAux({
                          ...userAddressAux,
                          street: event.target.value
                        })
                      }} />
                    <Select
                      options={countrySelectList}
                      styles={selectCountryStyles}
                      placeholder={intl.formatMessage({ id: "address_country" })}
                      defaultValue={countrySelectList.find(country => country.value === userAddressAux['country_id'])}
                      onChange={(event) => {
                        setUserAddressAux({
                          ...userAddressAux,
                          country_id: event.value
                        })
                      }}
                      components={{ Option: IconCountryOption }} />

                    <CustomInput
                      type="text"
                      style={{ textAlign: 'center', borderBottom: '1px solid' }}
                      placeholder={intl.formatMessage({ id: "address_city" })}
                      value={userAddressAux['city']}
                      onChange={(event) => {
                        setUserAddressAux({
                          ...userAddressAux,
                          city: event.target.value
                        })
                      }}
                      onBlur={() => {
                        handleEditField('address');
                      }}
                    />
                    <CustomInput
                      type="text"
                      style={{ textAlign: 'center', borderBottom: '1px solid' }}
                      placeholder={intl.formatMessage({ id: "address_state" })}
                      value={userAddressAux['state']}
                      onChange={(event) => {
                        setUserAddressAux({
                          ...userAddressAux,
                          state: event.target.value
                        })
                      }}
                      onBlur={() => {
                        //setEditAddress(false);
                        //setAddress(`${addressDetails}, ${selectCountry.label}-${city}`)
                      }}
                      className="state" />
                    <CustomInput
                      type="text"
                      style={{ textAlign: 'center', borderBottom: '1px solid' }}
                      placeholder={intl.formatMessage({ id: "address_code" })}
                      value={userAddressAux['postal_code']}
                      onChange={(event) => {
                        const newValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                        setUserAddressAux({
                          ...userAddressAux,
                          postal_code: newValue
                        })
                      }}
                      onBlur={() => {
                        //setEditAddress(false);
                        //setAddress(`${addressDetails}, ${selectCountry.label}-${city}`)
                      }}
                    />
                  </div>
                ) : (
                  <div className="addressInfo">
                    {userAddressAuxFormatted}
                  </div>
                )}
              </div>
              {showEdit && (
                <img
                  src={edit}
                  alt="edit"
                  className="edit"
                  onClick={() => {
                    handleEditField('address');
                  }} />
              )}
            </div>

            <div className="informationContainer">
              <div className="information">
                <p className="subtitle"><FormattedMessage id="signup_input_9_1" defaultMessage="Idioma de preferencia:" /></p>

                {
                  (!editingFields.has('language')) ?
                    <span className="paymentMethod">
                      {
                        languagePrimarySelectList.find(lang => lang.value === userDataAux?.language_id)?.label
                      }
                    </span> :
                    <Select options={languagePrimarySelectList}
                      styles={selectStyles}
                      placeholder={intl.formatMessage({ id: "signup_input_9" })}
                      defaultValue={languagePrimarySelectList.find(lang => lang.value === userDataAux?.language_id)}
                      onChange={(event) => {
                        setUserDataAux({
                          ...userDataAux,
                          language_id: event.value
                        });
                      }}
                    />
                }

              </div>
              {(showEdit) && (
                <img src={edit} alt="edit" className="editNewFields2" onClick={() => {
                  handleEditField('language');
                }} />
              )}
            </div>

            
              <div className="informationContainer">
                <div className="information">
                  <p className="subtitle">
                    <FormattedMessage
                      id="myAccount_subtitle_11"
                      defaultMessage="Método de pago:" />
                  </p>
                  <span className="paymentMethod">{userData?.payment_method ? 'PayPal' : '-'}</span>
                </div>
                {showEdit && (
                  <img
                    src={edit}
                    alt="edit"
                    className="edit"
                    onClick={() => {
                      if (userData?.payment_method) {
                        setShowDeletePayment(true);
                      } else {
                        sessionStorage.setItem("paypalLoginOrigin", "account");
                        PaymentAPI.getAuthorizarionUrl().then(response => {
                            window.location = response?.url;
                        }).catch(error => {
                            console.error("Error getting PayPal authorization URL", error);
                        });
                      }
                    }} />
                )}
              </div>
            
            {
              
              <div className="informationContainer">
                <div className="information">
                  <p className="subtitle">
                    <FormattedMessage
                      id="myAccount_subtitle_12"
                      defaultMessage="Notificaciones:" />
                  </p>
                  <div className="notifications">
                    <label htmlFor="promotions">
                      {" "}
                      <input
                        type="checkbox"
                        className="inputAccept"
                        value="promotions"
                        checked={promotions}
                        disabled={!showEdit}
                        onChange={(event) => {
                          setPromotions(event.target.checked);
                          /* TODO: Implementar lógica para mostrar el método de pago cuando venga y suscripción
                          setPromotions(event.target.checked);
                          const auxBody = { ...body };
                          auxBody.notification = {
                            ...body.notification,
                            promotions: event.target.checked
                          };
                          setBody(auxBody);
                          */
                        }} />{" "}
                      <FormattedMessage
                        id="myAccount_text_4"
                        defaultMessage="Enviarme emails sobre promociones" />
                    </label>
                  </div>
                </div>
              </div>
            }
          </div >

          <div className="buttons">
            <Box className="desactivateButtons" display="flex" gap={1}>
              <button
                className="deactivate"
                onClick={() => setShowAlertConfirmDelete(true)}
              >
                {/*  <FormattedMessage id="myAccount_button_1_a" defaultMessage="Desactivar" /> / */}
                <span>
                  <FormattedMessage
                    id="myAccount_button_1_b"
                    defaultMessage="Borrar mi cuenta" />
                </span>
              </button>
              <button
                className="deactivate"
                onClick={() => setShowAlertConfirmDesactivateAccount(true)}
              >
                {/*  <FormattedMessage id="myAccount_button_1_a" defaultMessage="Desactivar" /> / */}
                <span>
                <FormattedMessage
                    id="alert_desactivate_account_title"
                    defaultMessage="Desactivar cuenta" />
                </span>
              </button>
            </Box>
            <button className={showEdit ? "save" : "edit"} onClick={toggleEdit}>
              <FormattedMessage
                id={showEdit ? "myAccount_button_3" : "myAccount_button_2"}
                defaultMessage={showEdit ? "Guardar" : "Editar mi cuenta"}
              />
            </button>
          </div>

          {isLoading && <Spinner />}

          {
            showAlertChangeSuccessful && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_forward_title"
                    defaultMessage="¡Exitoso!"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_change_successful_text"
                    defaultMessage="Los cambios se han realizado con éxito."
                  />
                }
                setShowAlert={setShowAlertChangeSuccessful}
              />
            )
          }

          {
            showAlertChangeFail && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_forward_title_2"
                    defaultMessage="¡Error!"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_change_successful_text_2"
                    defaultMessage="No se han guardado los cambios."
                  />
                }
                setShowAlert={setShowAlertChangeFail}
              />
            )
          }

          {
            showAlertDeleteAccount && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_delete_account_email_title"
                    defaultMessage="Revisa tu correo"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_delete_account_email_text"
                    defaultMessage="Se ha enviado un mensaje a tu correo para completar el proceso."
                  />
                }
                setShowAlert={setShowAlertDeleteAccount}
              />
            )
          }

          {
            showSecurityCode && (
              <ModalSecurityCode
                setShowSecurityCode={setShowSecurityCode}
                body={{ mail: userContactAux["email"] && userContactAux["email"].toLowerCase() }}
                setIsEmailVerified={setIsEmailVerified}
                page="MyAccount" /* setShowAlertUserExists={setShowAlertUserExists} */
              />
            )
          }

          {
            showEditPassword && (
              <ModalChangePassword setShowEditPassword={setShowEditPassword} />
            )
          }

          {
            showAlertConfirmDelete && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_delete_account_title"
                    defaultMessage="¡Eliminar!"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_delete_account_text"
                    defaultMessage="¿Estas seguro de que quieres eliminar tu cuenta?"
                  />
                }
                confirmText={
                  <FormattedMessage
                    id="alert_delete_account_confirm_button"
                    defaultMessage="Eliminar"
                  />
                }
                cancelText={
                  <FormattedMessage
                    id="alert_delete_account_cancel_button"
                    defaultMessage="Cancelar"
                  />
                }
                onConfirm={deleteAccount}
                onCancel={() => setShowAlertConfirmDelete(false)}
                setShowAlert={setShowAlertConfirmDelete}
              />
            )
          }

          {
            showAlertConfirmDesactivateAccount && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_desactivate_account_title"
                    defaultMessage="Desactivar cuenta"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_desactivate_account_text"
                    defaultMessage="¿Estás seguro de que deseas desactivar tu cuenta?"
                  />
                }
                confirmText={
                  <FormattedMessage
                    id="alert_desactivate_account_confirm_button"
                    defaultMessage="Confirmar"
                  />
                }
                cancelText={
                  <FormattedMessage
                    id="alert_desactivate_account_cancel_button"
                    defaultMessage="Cancelar"
                  />
                }
                onConfirm={desactivateAccount}
                onCancel={() => setShowAlertConfirmDelete(false)}
                setShowAlert={setShowAlertConfirmDelete}
              />
            )
          }

          {
            showAlertAccountDesactivated && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_desactivated_account_title"
                    defaultMessage="Cuenta desactivada"
                  />
                }
                text={
                  <Typography textAlign={'center'}>
                    <FormattedMessage
                      id="alert_desactivated_account_text"
                      defaultMessage="Tu cuenta ha sido desactivada exitosamente."
                    />
                  </Typography>
                }
                confirmText={
                  <FormattedMessage
                    id="alert_desactivated_account_button"
                    defaultMessage="Cerrar"
                  />
                }
                onConfirm={() => setShowAlertAccountDesactivated(false)}
                setShowAlert={setShowAlertConfirmDelete}
              />
            )
          }

          {
            showDeletePayment && (
              <Alert
                title={
                  <FormattedMessage
                    id="alert_delete_account_title"
                    defaultMessage="¡Eliminar!"
                  />
                }
                text={
                  <FormattedMessage
                    id="alert_delete_paypal"
                    defaultMessage="¿Deseas eliminar la cuenta de paypal que tienes actualmente asociada a Fluincr?"
                  />
                }
                confirmText={
                  <FormattedMessage
                    id="alert_delete_account_confirm_button"
                    defaultMessage="Eliminar"
                  />
                }
                cancelText={
                  <FormattedMessage
                    id="alert_delete_account_cancel_button"
                    defaultMessage="Cancelar"
                  />
                }
                onConfirm={deletePayment}
                onCancel={() => setShowDeletePayment(false)}
                setShowAlert={setShowDeletePayment}
              />
            )
          }
        </MyAccountContainer >
      }
    </>
  );
}
