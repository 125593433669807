import React, { useState } from "react";
import { ResetPasswordContainer } from "./ResetPasswordStyled";

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../components/Spinner/Spinner";
import arrow from './../../utils/images/arrow_back_pink.svg';
import ModalResetPasswordMessage from "../../components/ModalResetPasswordMessage/ModalResetPasswordMessage";
import { useEffect } from "react";
import { UserAPI } from "../../api/user/user";

export default function ResetPassword() {

    const intl = useIntl();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [showMessage, setShowMessage] = useState(false);
    const [showError, setShowError] = useState(false);

    const resetPassword = (event) => {
        event.preventDefault()
        setIsLoading(true)
        UserAPI.sendResetPasswordEmail(email).then((response) => {
            setIsLoading(false);
            setShowMessage(true);
        }).catch((error) => {
            setIsLoading(false);
            setShowError(true);
            console.log(error);
        });
    }

    useEffect(() => {
        setShowError(false)
    }, [email])

    return (
        <ResetPasswordContainer>

            <div className="resetHeader">
                <h2><FormattedMessage id="reset_password_title_a" defaultMessage="Reestablece tu " />
                    <strong><FormattedMessage id="reset_password_title_b" defaultMessage="contraseña" /></strong></h2>
            </div>

            <form action="submit">
                <p className="firstText"><FormattedMessage id="reset_password_text_1" defaultMessage="Ingresa el correo electrónico con el que te registraste en la página web y enviaremos un correo con instrucciones para recuperar tu contraseña." /></p>

                <input type="email" placeholder={intl.formatMessage({ id: "signin_input_1" })} value={email} onChange={(event) => { setEmail(event.target.value) }} className="email" />

                {(showError) && (<p className="error"><FormattedMessage id="reset_password_error" defaultMessage="El usuario no existe" /></p>)}

                <button className="resetButton" onClick={resetPassword}>
                    <FormattedMessage id="reset_password_button" defaultMessage="Restablecer contraseña" />
                </button>

            </form>
            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={arrow} alt="go back" />
                <p ><FormattedMessage id="reset_password_text_3" defaultMessage="Volver atrás" /></p>
            </div>


            {(isLoading) && (<Spinner />)}

            {(showMessage) && (<ModalResetPasswordMessage email={email} setShowMessage={setShowMessage} />)}

        </ResetPasswordContainer>
    )
}