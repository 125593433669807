import styled from 'styled-components';
import { colors } from '../../utils/styles/colors';
import { media } from '../../utils/styles/media';

export const InfluencerCardContainer = styled.div`
    
    width: ${(props) => (props.page === "home") ? '20%' : '25%'};
    padding: 15px;
    .image{
        width: 100%;
        height: 420px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 10px 20px;
        cursor: pointer;
        
        .likeContainer{
            width: 75px;
            height: 75px;
        }
        
        .data{
            display: flex;
            width: 100%;
            justify-content: space-between;
            p{
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: rgb(61, 61, 61, 0.7);
                color: #ffffff;
                padding: 5px 10px;
                border-radius:15px;
                font-weight: 500;
                font-size: 1.7rem;
                font-family: 'Montserrat', sans-serif;
                .currencySymbol{
                    font-size: 1rem;
                }
            }

            .followers{
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    } 

    .name{
        font-size: 2.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.text};
        margin-top: 8px;
    }
    .name:hover{
        opacity: 0.8;
        cursor: pointer;
    } 

    .influencerData{
        display: flex; 
        align-items: center;
        .influencerCountry{
            height:30px;
            width: 30px;
            border-radius: 50%;                                        
            margin-right: 3px;
        }
        .categories{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-transform: lowercase;
            font-weight: 600;
            .category{
                padding-right:3px;
                font-size: 1.6rem;
                font-weight: bold; 
            }
        } 
        
    } 

    /**---------Responsive-----------*/
    ${media.largeDesktop}{
        .image{
            height: 16vw;
            padding: 12px;
            .likeContainer{
                width: 50px;
                height: 50px;
            }
            .data{
                p{   
                    font-size: 1.3rem;
                    .currencySymbol{
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.6rem;
        }
        .influencerData{
            .categories{
                .category{
                    font-size: 1.2rem; 
                }
            }
        }
    }

    ${media.bigmacdesktop}{
        .image{
            .data{
                p{   
                    font-size: 1.2rem;
                    .currencySymbol{
                        font-size: 0.8rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.4rem;
        }
        .influencerData{
            .categories{
                .category{
                    font-size: 1.1rem; 
                }
            }
        }
    }

    ${media.macdesktop}{
        .image{
            //height: 15vw;
            padding: 11px;
            .likeContainer{
                width: 45px;
                height: 45px;
            }
            .data{
                p{   
                    font-size: 1rem;
                    .currencySymbol{
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.3rem;
        }
        .influencerData{
            .categories{
                .category{
                    font-size: 1rem; 
                }
            }
        }
    }
    
    ${media.desktop}{
        padding: 12px;
        .image{
            height: 230px;
            padding: 10px;
            .likeContainer{
                width: 40px;
                height: 40px;
            }
            .data{
                p{
                    font-size: 0.9rem;
                    .currencySymbol{
                        font-size: 0.6rem;
                    }
                }
            }
        }
        .name{
            font-size: 1.3rem;
        }
        .influencerData{
            font-size: 1rem;
            .influencerCountry{
                height:25px;
                width: 25px;
            }
            .categories{
                .category{
                    padding-right:3px;
                    font-size: 1rem; 
                }
            }
        }
    }

    @media(max-width: 1112px){
        .name{
            font-size: 1.3rem;
        }
    }

    ${media.smallDesktop}{
        padding: 10px;
        .image{
            height: 160px;
            .likeContainer{
                width: 35px;
                height: 35px;
            }
        }
    }

    
    ${media.laptop}{
        padding: 8px;
        .image{
            height: 15.5vw;
            padding: 5px;
            .likeContainer{
                width: 30px;
                height: 30px;
            }
            .data{
                p{
                    font-size: 0.7rem;
                    padding: 2px 4px;
                    .currencySymbol{
                        font-size: 0.5rem;
                    }
                }
            }
        }
        .name{
            font-size: 1rem;
        }
        .influencerData{
            align-items: center;
            .influencerCountry{
                height:20px;
                width: 20px;
            }
            .categories{
                .category{
                    font-size: 0.8rem; 
                }
            }
        }
    }
    @media(max-width: 744px){
        .name{
            font-size: 0.9rem;
        }
    }
    @media(max-width: 659px){
        .name{
            font-size: 0.8rem;
        }
    }


    ${media.largeMobile}{
        width: 50%;
        .image{
            height: 165px;
            .likeContainer{
                width: 32px;
                height: 32px;
            }
            .data{
                p{
                    font-size: 0.8rem;
                    padding: 2px 6px;
                }
            }
        }
        .name{
            font-size: 1rem;
            //margin-top: 6px;
        }
        :last-child{
            display: ${(props) => props.page === "home" ? 'none' : 'block'};
        }
        
    }
  
`