import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const AlertContainer = styled.div`
    
    position: fixed;
    overflow-y: auto;
    top:0;
    left: 0; 
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    
    .card{
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        .image{
            align-self: center;
            width: 100px;
            margin: 20px 0;
        }
        .title{
            color: ${colors.hightlight};
        }
        .subtitle{
            font-size: 1.4rem;
            font-weight: 700;
        }
        .text{
            padding: 10px 0;
            a{
                //color: ${colors.hightlight};
                color: ${colors.grey};
                text-decoration: underline;
            }
            a:visited {
                //color: ${colors.hightlight};
                color: ${colors.grey};
            }
        }
        .buttons{
            display: flex;
            justify-content: center;
            button{
                outline: none;
                cursor: pointer;
                border-radius: 10px;
                border: none;
                height: 35px;
                padding: 10px 30px;
                font-size: 1rem;
                margin: 10px;
            }
            .confirm{
                background: ${colors.hightlight};
                color: #ffffff;  
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
            .cancel{
                background-color: #ffffff;
                box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                color:${colors.grey};
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
        }
    }

`