import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { ContentDetailsInfluencerContainer, UploadImage, Photo, Image, Status } from "./ContentDetailsInfluencerStyled";
import { FormattedMessage } from 'react-intl';
import photoVideo from './../../utils/images/photo_video.svg';
import upload from './../../utils/images/upload.svg';
import deleteIcon from './../../utils/images/delete_pink.svg';
import ImageUploading from 'react-images-uploading';
import VideoInput from "../VideoInput/VideoInput";
import Spinner from "../Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import ModalVideoCoverSelector from "../ModalVideoCoverSelector/ModalVideoCoverSelector";
import LinearWithValueLabel from "./LinearProgressBar/LinearProgressBar";
import ItemStatusSelect from "../ItemStatusSelect/ItemStatusSelect";
import { ServiceContext } from "../../context/serviceContext";
import { ItemAPI } from "../../api/collaboration/item";
import { ContentTypes, ItemStatusId } from "../../utils/constants/item";
import { collaborationStatusId } from "../../utils/constants/collaboration";
import { S3API } from "../../api/utils/s3";
import { uploadImageS3 } from "../../utils/common/s3";
import ModalCarousel from "../ModalCarousel/ModalCarousel";
import { UserContext2 } from "../../context/user/userContext";
import downloadCircleIcon from './../../utils/images/download_circle_blue.png';
import { formatDateLocal, formatDateTimeLocal } from "../../services/dateServices";
import { getBase64 } from "../../services/generalSevices";

export default function ContentDetailsInfluencer({ item = {}, onChangeItem, collaborationStatus, setShowDispute }) {

    //posiciones [0]=id, [1]=content, [2]=status, [3]=readinfluencer, [4]=readcompany
    const inputRef = useRef();
    const { idCollaboration } = useParams();
    const [images, setImages] = useState([]);
    const maxImages = 1;
    const [source, setSource] = useState();
    const { userToken, userData } = useContext(UserContext2);
    const { services } = useContext(ServiceContext);
    const [showAlertNotContent, setShowAlertNotContent] = useState(false);
    const isAdmin = sessionStorage.getItem('isAdmin');
    const [showModalCoverSelector, setShowModalCoverSelector] = useState(false);
    const [showCarousel, setShowCarousel] = useState(false);

    const {
        id,
        collaboration_id: collaborationId,
        status: statusItem,
        content: contentItem,
        cover: coverItem,
        price: priceItem,
        service_id: serviceId,
        modification_date: modificationDateItem,
        publishing_date: publishingDateItem,
    } = item;

    const [serviceType, setServiceType] = useState();

    const contentUploadLimit = 1;
    const [auxContentItem, setAuxContentItem] = useState();
    const [auxCoverItem, setAuxCoverItem] = useState();
    const [auxStatusItemId, setAuxStatusItemId] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [porcentajeCarga, setPorcentajeCarga] = useState(0);
    const [showLinearLoader, setShowLinearLoader] = useState(false)

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const [uploadContentDate, setUploadContentDate] = useState(null);
    const [approveContentDate, setApproveContentDate] = useState(null);
    const [disputeWindowDate, setDisputeWindowDate] = useState(null);

    useEffect(() => {
        updateDeadlines(modificationDateItem);       
    }, [])

    useEffect(() => {
        console.log("item", item)
        setAuxContentItem(contentItem);
        setAuxCoverItem(coverItem);
        setAuxStatusItemId(statusItem.id);
        setServiceType(services.find(service => service.id === serviceId)?.type);
    }, [item])
    

    const handlerUpload = () => {
        if (collaborationStatus !== collaborationStatusId.CANCELLED) {
            if (images.length > 0 || source !== undefined) {

                if (serviceType === ContentTypes.IMAGE) {
                    setIsLoading(true)
                } else {
                    setShowLinearLoader(true)
                }

                let fileBody = {}

                if (serviceType === ContentTypes.IMAGE) {
                    fileBody = {
                        base64File: images[0].data_url.split(',')[1],
                        formatFile: images[0].file.type.split('/')[1],
                        address: `fluincr/${userData?.account}/collaboration`
                    }
                } else {
                    fileBody = {
                        base64File: source.data_url.split(',')[1],
                        formatFile: source.file.type.split('/')[1] || 'mp4',
                        address: `fluincr/${userData?.account}/collaboration`
                    }
                }

                if(serviceType === ContentTypes.IMAGE){
                    uploadImageS3(fileBody.address, 'image/' + fileBody.formatFile, images[0].file).then((fileLink) => {
                        const itemDTO = {
                            ...item,
                            content: fileLink,
                            cover: fileLink
                        }
                        
                        ItemAPI.setItemContent(id, itemDTO).then((itemDTOResponse) => {
                            setAuxStatusItemId(itemDTOResponse.status.id);
                            setAuxContentItem(fileLink)
                            setAuxCoverItem(fileLink)
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            setIsLoading(false);
                        })
                    }).catch((error) => {
                        console.error(error);
                        setIsLoading(false);    
                    });
                } else {
                    S3API.getPresignedUrl(fileBody.address, `video/${fileBody.formatFile}`).then(presignedUrl => {
                        if(presignedUrl){
                            S3API.uploadVideoS3(presignedUrl, source.file, setPorcentajeCarga).then(res => {

                                let fileLink = presignedUrl.split("?")[0];
                                let fileName = fileLink.split(".com/")[1];

                                S3API.putObjectPublic(fileName).then(res2 => {
                                    const itemDTO = {
                                        ...item,
                                        content: fileLink,
                                        cover: auxCoverItem
                                    }

                                    setAuxContentItem(fileLink)
                                    
                                    ItemAPI.setItemContent(id, itemDTO).then((response) => {
                                        setAuxStatusItemId(ItemStatusId.APPROVE_CONTENT);
                                        setShowLinearLoader(false);
                                    }).catch(err => {
                                        console.log(err);
                                        setShowLinearLoader(false);
                                    })
                                })
                            })
                        }
                    })
                }
            } else {
                setShowAlertNotContent(true)
                setIsLoading(false);
            }
        }
    }

    const handlerPostedContent = () => {
        if (collaborationStatus !== collaborationStatusId.CANCELLED) {
            setIsLoading(true)
            ItemAPI.publishContent(id, item).then((itemDTOResponse) => {
                setAuxStatusItemId(itemDTOResponse.status.id);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }

    }

    const handleVideoFileChange = (event) => {
        const file = event.target.files[0];
        getBase64({ file: file }).then((video) => {
            const blobUrl = URL.createObjectURL(file);
            setSource({ data_url: video, blob: blobUrl, file: file });
        })
    };

    useEffect(() => {
        if (source && serviceType === ContentTypes.VIDEO) {
            setShowModalCoverSelector(true);
        }
    }, [source])

    const updateDeadlines = (date) => {
        const uploadDate = new Date(date);
        uploadDate.setDate(uploadDate.getDate() + 10);
        setUploadContentDate(formatDateLocal(uploadDate.toISOString()));

        const approveDate = new Date(date);
        approveDate.setDate(approveDate.getDate() + 3)
        setApproveContentDate(formatDateLocal(approveDate.toISOString()));

        const disputeWindowDate = new Date(date);
        disputeWindowDate.setDate(disputeWindowDate.getDate() + 3);
        setDisputeWindowDate(formatDateLocal(disputeWindowDate.toISOString()));
    }

    return (
        <ContentDetailsInfluencerContainer>
            {(JSON.parse(isAdmin) === true) ? (<div className="adminSelect">
                <ItemStatusSelect item={item}></ItemStatusSelect>
            </div>) : (<>
                {(auxStatusItemId === 1) ? (
                    <div className="statusTextContainer" >
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_1" defaultMessage="Aceptación pendiente" /></p>
                    </div>
                ) : (auxStatusItemId === 2) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_2" defaultMessage="Declinado (cerrado)" /></p>
                    </div>
                ) : (auxStatusItemId === 3) ? (
                    <div className="statusTextContainer">
                        {
                            (!source && images.length < 1) ?
                            <div style={{width: '100%'}}>
                                {(serviceType === ContentTypes.IMAGE) ? (
                                    <ImageUploading value={images} onChange={onChange} maxNumber={maxImages} dataURLKey="data_url">
                                        {({onImageUpload}) => (
                                            <Status onClick={onImageUpload} status={collaborationStatus}><FormattedMessage id="collaboration_details_status_influencer_3" defaultMessage="Subir contenido" /></Status>
                                        )}
                                    </ImageUploading>
                                ) : (
                                <label>
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        onChange={handleVideoFileChange}
                                        accept=".mov,.mp4, video/*"
                                        style={{ display: 'none' }}
                                    />
                                    <Status status={collaborationStatus}><FormattedMessage id="collaboration_details_status_influencer_3" defaultMessage="Subir contenido" /></Status>
                                </label>)}
                            </div>
                            :
                            <Status status={collaborationStatus} onClick={handlerUpload}><FormattedMessage id="collaboration_details_status_influencer_3_a" defaultMessage="Enviar contenido" /></Status>
                        }
                        {<p className="status"><FormattedMessage id="collaboration_details_status_influencer_3_b" defaultMessage="Subir antes del " />{uploadContentDate}</p>}
                    </div>
                ) : (auxStatusItemId === 4) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_4" defaultMessage="Aprobar contenido" /></p>
                        {<p className="status"><FormattedMessage id="collaboration_details_status_influencer_4_b" defaultMessage="hasta " />{approveContentDate}</p>}
                    </div>
                ) : (auxStatusItemId === 5) ? (
                    <div className="statusTextContainer">
                        <p className="status statusButton" onClick={handlerPostedContent}><FormattedMessage id="collaboration_details_status_influencer_5" defaultMessage="Publicar contenido y click" /></p>
                        {<p className="status">
                            {
                                publishingDateItem ?
                                    <>
                                        <FormattedMessage id="collaboration_details_status_company_5_b" defaultMessage="Fecha de publicación: " />
                                        {formatDateTimeLocal(publishingDateItem)}
                                    </>
                                    :
                                    <FormattedMessage id="collaboration_details_status_company_5_c" defaultMessage="Consultar" />
                            }
                        </p>}
                    </div>
                ) : (auxStatusItemId === 6) ? (
                    <div className="statusTextContainer" >
                        <p className="status statusButton"><FormattedMessage id="collaboration_details_status_influencer_6" defaultMessage="Ventana de disputa" /></p>
                        {<p className="status"><FormattedMessage id="collaboration_details_status_influencer_6_b" defaultMessage="hasta " />{disputeWindowDate}</p>}
                    </div>
                ) : (auxStatusItemId === 7) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_7" defaultMessage="En espera" /></p>
                    </div>
                ) : (auxStatusItemId === 8) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_8" defaultMessage="Terminado" /></p>
                    </div>
                ) : (auxStatusItemId === 9) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_9" defaultMessage="Cancelado" /></p>
                    </div>
                ) : (auxStatusItemId === 10) ? (
                    <div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_influencer_10" defaultMessage="Otro" /></p>
                    </div>
                ) : null}
            </>)}

            {/**Opción para ver contenido ((status === 1 || status === 2 || status === 3) && content === 'empty' )*/}

            {(auxStatusItemId === 1 || auxStatusItemId === 2 || auxStatusItemId === 3) ? (
                <UploadImage status={auxStatusItemId} >
                    {(serviceType === ContentTypes.IMAGE) ? (
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            maxNumber={contentUploadLimit}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (

                                <div className="upload__image-wrapper">
                                    {(images.length < 1) && (<button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        disabled={auxStatusItemId === 1 || auxStatusItemId === 2}
                                        className="uploadButton"
                                    >
                                        <img src={upload} alt="content" className="uploadItem" />
                                    </button>)}

                                    &nbsp;
                                    {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item">

                                            {/* <img src={image['data_url']} alt="" className="photo" /> */}

                                            <Photo image={image['data_url']} />

                                            <div className="image-item__btn-wrapper">
                                                {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                                <button onClick={() => onImageRemove(index)} className="removeButton"><img src={deleteIcon} alt="remove" /></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ImageUploading>
                    ) : (<VideoInput status={auxStatusItemId} source={source} setSource={setSource} />)}

                </UploadImage>

            ) : (auxContentItem) ? (
                <div className="content" >
                    {(serviceType != ContentTypes.VIDEO || (serviceType == ContentTypes.VIDEO && auxCoverItem) ) ? (<>{/* <a href={content} download> */}
                        <Image image={auxCoverItem ?? auxContentItem} onClick={() => {
                            setShowCarousel(true);
                        }} >
                            <a href={auxContentItem} download onClick={(event) => event.stopPropagation()}>
                                <img src={downloadCircleIcon} alt="video" onClick={(event) => {event.stopPropagation()}} />
                            </a>
                        </Image>
                        {/* </a> */} </>) : (
                        <>
                            {/* <a href={content} download> */}
                            <video
                                className="video"
                                //controls
                                src={auxContentItem}
                                onClick={() => {
                                    setShowCarousel(true);
                                }}
                            />
                            {/* </a>  */}
                        </>
                    )}

                </div>
            ) : (<div className="notContent">
                <img src={photoVideo} alt="video" />
            </div>)}

            {(isLoading) && (<Spinner />)}

            {(showAlertNotContent) && (<Alert
                title={<FormattedMessage id="alert_not_content_title" defaultMessage="Sin contenido!" />}
                text={<FormattedMessage id="alert_not_content_text" defaultMessage="Por favor carga un archivo en ⬆️ y presiona el botón Sube contenido" />}

                setShowAlert={setShowAlertNotContent}

            />)}

            {(showModalCoverSelector) && <ModalVideoCoverSelector source={source} account={userData?.account} userToken={userToken} itemId={id} setShowModalVideoCoverSelector={setShowModalCoverSelector} setItemCover={setAuxCoverItem}/>}

            {(showLinearLoader) && <LinearWithValueLabel porcentajeCarga={porcentajeCarga}></LinearWithValueLabel>}

            {(showCarousel) && (<ModalCarousel setShowModalCarousel={setShowCarousel} content={[auxContentItem]} page='collaborationDetails' contentType={serviceType} />)}
        
        </ContentDetailsInfluencerContainer>
    )
}