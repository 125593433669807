import React, { useState } from "react";
import { ModalResetPasswordMessageContainer } from "./ModalResetPasswordMessageStyled";
import { FormattedMessage } from 'react-intl';

import close from './../../utils/images/close_circle.svg';
import Spinner from "../Spinner/Spinner";
import { UserAPI } from "../../api/user/user";

export default function ModalResetPasswordMessage({ email, setShowMessage }) {

    const [isLoading, setIsLoading] = useState(false);

    const resentMessage = () => {
        setIsLoading(true)
        UserAPI.sendResetPasswordEmail(email).then((response) => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            window.alert('We could not complete your request at the moment, please try again later');
            console.log(error);
        });
    }

    return (
        <ModalResetPasswordMessageContainer>

            <div className="messageBody">
                <img src={close} alt="close" className="close" onClick={() => { setShowMessage(false) }} />

                <h2 className="title"><FormattedMessage id="modal_reset_password_title" defaultMessage="¡Revisa tu correo!" /></h2>

                <p className="firstText"><span><FormattedMessage id="modal_reset_password_text_1_a" defaultMessage="Hemos enviado un mensaje a " /></span>{email}<span ><FormattedMessage id="modal_reset_password_text_1_b" defaultMessage=" para recuperar tu contraseña" /></span></p>

                <p className="middleText"><span><FormattedMessage id="modal_reset_password_text_2_a" defaultMessage="No has recibido el mensaje? Espera 3 minutos y luego " /></span> <span className="link" onClick={resentMessage}><FormattedMessage id="modal_reset_password_text_2_b" defaultMessage="reenviar mensaje" /></span></p>

                <p className="lastText"><span><FormattedMessage id="modal_reset_password_text_3_a" defaultMessage="¿Ingresaste tu correo electrónico incorrectamente? " /></span > <span className="link" onClick={() => { setShowMessage(false) }}><FormattedMessage id="modal_reset_password_text_3_b" defaultMessage="Inténtalo de nuevo" /></span></p>

            </div>
            {(isLoading) && (<Spinner />)}

        </ModalResetPasswordMessageContainer>
    )
}