import React, { useContext, useEffect } from "react";
import { NetworkRedirectContainer } from "./NetworkRedirectStyled";
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import Lottie from 'react-lottie';
import * as animationData from './tiktok-icon.json';
import { UserContext2 } from "../../context/user/userContext";
import { TikTokAPI } from "../../api/social-network/tiktok";

export default function TiktokRedirect() {

    const navigate = useNavigate();
    //let { code, scopes, state } = useParams();

    const { userData, setUserData, userToken } = useContext(UserContext2);

    //const [isLoading, setIsLoading] = useState(true);
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let code = urlParams.get('code');
    let scopes = urlParams.get('scopes');
    let state = urlParams.get('state');

    const [showAlertError, setShowAlertError] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if(userToken){
            TikTokAPI.validateState(userData?.id, state).then((response) => {
                TikTokAPI.accessToken(userData?.id, code).then((tiktokSocialNetworkAccount) => {
                    if(tiktokSocialNetworkAccount?.followers === null || tiktokSocialNetworkAccount?.link === null){
                        setIsStopped(true);
                        setShowAlertError(true);
                    } else {
                        setUserData({
                            ...userData,
                            social_networks: [
                                ...userData?.social_networks,
                                tiktokSocialNetworkAccount
                            ]
                        })
                        navigate(`/${userData?.username}`);
                    }
                }).catch(() => {
                    setIsStopped(true);
                    setShowAlertError(true);
                });
            }).catch(() => {
                setIsStopped(true);
                setShowAlertError(true);
            });
        }
    }, [state, userToken])

    return (
        <NetworkRedirectContainer>

            <Lottie options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused} />

            {/* <p>Redirect tiktok page</p>
            {(isLoading) && (<Spinner />)} */}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="alert_error_tiktok_text" defaultMessage="No pudimos conectar su cuenta de tiktok, por favor intente mas tarde." />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="alert_error_network_button" defaultMessage="Ir a mi perfil" />}
                onConfirm={() => { navigate('/myprofile') }}
            />)}

        </NetworkRedirectContainer>

    )
}