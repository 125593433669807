import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LikeInfluencerContainer } from "./LikeInfluencerStyled";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { CompanyAPI } from "../../api/user/company/company";
import starColor from './../../utils/images/star_color.png';
import starWhite from './../../utils/images/star_white.png';

export default function LikeInfluencer({ profileId }) {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);

  const { userData, setUserData, userToken, userType } = useContext(UserContext2);

  useEffect(() => {
    if (userType === UserTypes.COMPANY && userData) {
      setIsFavorite(
        userData?.likes?.find((userId) => userId === profileId) || false
      );
    } else {
      setIsFavorite(false);
    }
  }, [userData, profileId]);

  const handlerLike = (e) => {
    if (!userToken) {
      navigate("/signin");
    } else {
      e.stopPropagation();
      CompanyAPI.likes(profileId).then((response) => {
        setIsFavorite(!isFavorite);
        const likes = userData?.likes || [];
        if (likes.includes(profileId)) {
          likes.splice(likes.indexOf(profileId), 1);
        } else {
          likes.push(profileId);
        }
        setUserData({ ...userData, likes: likes });
      });
    }
  };

  return (
    <LikeInfluencerContainer onClick={handlerLike}>
      <img
        src={isFavorite ? starColor : starWhite}
        alt="star"
        className={isFavorite ? "favorite" : ""}
      />
    </LikeInfluencerContainer>
  );
}
