import styled from 'styled-components';
import { colors } from '../../utils/styles/colors';
import { media } from './../../utils/styles/media';

export const HeaderContainer = styled.nav`

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 80px;
    .logo{
        //height:40px;
        width: 300px;
        cursor: pointer;
        margin-bottom: -1em;
    }
    .menu{
        display: flex;
        width: 80%;
        justify-content: flex-end;
        .signIn{
            color: #ffffff;
            margin-right: 15px;
            border: none;
            border-radius: 5px;
            background-color: ${colors.hightlight};
            padding: 8px 20px ;
            font-size: 1.1rem;
            cursor: pointer;
            font-weight: bold;
            a{
                    color: #ffffff;
                }
        }
        .categoryContainer{
            position: relative;
            margin-right: 150px;
            width: 220px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .category{
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 1.8rem;
                color: ${colors.text};
                .arrow{
                    margin-left: 8px;
                    margin-top: 4px;
                    width: 15px;
                    height: 15px;
                }
            }
            .categoryMenu{
                position: absolute;
                z-index: 100;
                top:40px;
                right: 0px;
                background-color: #ffffff;
                padding: 12px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 10px;
                max-height: 300px;
                width: 235px;
                overflow-y: auto;
                box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                label{
                    margin: 3px;
                    input[type='checkbox'] {
                        accent-color: ${colors.hightlight};
                        margin-right: 5px;                               
                    }
                }
            }
        }

        .searchContainer{
            align-self: center;
            border: 1px solid ${colors.grey};
            border-radius: 12px;
            padding: 2px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 150px;
            width: 50%;
            .inputSearch{
                border: none;
                outline: none;
                width: 90%;
                font-size: 1rem;
            }
            .searchButton{
                background-color: #ffffff;
                border: none;
                cursor: pointer;
                .searchIcon{
                    height:20px;
                }
            }
        }
            
        .navOptions{
            display: flex;
            align-items: center;
            margin-right: 25px;
            .option{
                margin-left: 25px;
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 5px;
                .active, .count{
                    background-color: ${colors.hightlight};
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    p{
                        color: #ffffff;
                        font-size: 0.5rem;
                        text-align: center;
                        font-weight: 600;
                    }
                }
                img{
                    width: 40px;
                    height: 45px;
                }
                .settings{
                    cursor: pointer;
                }
                .settingsMenu{
                    position: absolute;
                    z-index: 100;
                    top:40px;
                    right: 0px;
                    width: 170px;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
                    .settingsOption{
                        padding: 10px 0;
                        color: ${colors.darkGrey};
                        cursor: pointer;
                    }
                    .settingsOption:hover{
                        color: ${colors.hightlight};
                    }
                }
            }
        }
        .headerStaticPages{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            gap: 1em;
            margin: 0 1em;
            font-weight: bold;
            font-size: 1.1em;
            a{
                color: #000;
            }
        }
        .responsiveMenu{
            display: none;
        }
    }


    /**---------Responsive---------- */
    ${media.largeDesktop}{
        padding: 20px 80px;
        .logo{
            width: 260px;
        }
        .menu{
            .categoryContainer{
                margin-right: 50px;
                .category{
                    font-size: 1.6rem;
                    .arrow{
                        width: 14px;
                        height: 14px;
                    }
                }
            } 
            .searchContainer{
                margin-right: 100px;
                padding: 2px 12px;
            } 
            .navOptions{
                .option{
                    img{
                        width: 35px;
                        height: 40px;
                    }
                }
            }   
        }
    }

    ${media.specialScreen}{
       .menu{
        .searchContainer{
                margin-right: 50px;
                padding: 2px 10px;
            }
            .categoryContainer{
                margin-right: 0px;
                .category{
                    font-size: 1.4rem;
                }
            }
        }
    }

    ${media.bigmacdesktop}{
        .logo{
            width: 240px;
        }
    }

    ${media.macdesktop}{
        padding: 20px 70px;
        .logo{
            width: 230px;
        }
        .menu{
            margin-left: 10px;
            .categoryContainer{
                margin-right: 0px;
                .category{
                    font-size: 1.4rem;
                    .arrow{
                        margin-left: 4px;
                        width: 12px;
                        height: 12px;
                    }
                }
            } 
            .searchContainer{
                margin-right: 30px;
                padding: 0 8px;
            } 
            .navOptions{
                .option{
                    img{
                        width: 30px;
                        height: 35px;
                    }
                }
            }  
        }
    }

    ${media.desktop}{
        padding: 20px 60px;
        .logo{
            width: 220px;
        }
        .menu{
            margin-left: 10px;
            .categoryContainer{
                margin-right: 0px;
                .category{
                    font-size: 1.3rem;
                    .arrow{
                        margin-left: 4px;
                        width: 12px;
                        height: 12px;
                    }
                }
            } 
            .searchContainer{
                margin-right: 30px;
                padding: 0 8px;
            } 
            .navOptions{
                .option{
                    img{
                        width: 30px;
                        height: 35px;
                    }
                }
            }  
        }
    }
    ${media.smallDesktop}{
        padding: 20px 40px;
        .logo{
            width: 180px;
        }
        .menu{
            .categoryContainer{
                .category{
                    font-size: 1.2rem;
                    .arrow{
                        width: 11px;
                        height: 11px;
                    }
                }
            }
        }
    }
    ${media.laptop}{
        .logo{
            width: 180px;
        }
        .menu{
            .categoryContainer{
                display: none;
            }
            .searchContainer{
                border: none;
                margin-right: 10px;
                .inputSearch{
                    border-bottom: 1px solid ${colors.greyIcon}; 
                }
            }
            .navOptions{
                display: none;
            }
            .responsiveMenu{
                display: block;
                display: flex;
                align-items: center;
                margin-right: 20px;
                .burgerMenu{
                    height:20px;
                    
                }
            }
        }
    }
    ${media.largeMobile}{
        padding: 10px 20px;
        .logo{
            width: 110px;
        }
        .menu{
            .searchContainer{
                margin-right: 0px;
            }
            .signIn{
                margin-right: 10px;
                border-radius: 12px;
                padding: 5px 15px ;
                font-size: 0.8rem;
            }
            .responsiveMenu{
                margin-right: 10px;
            }
            .headerStaticPages{
                font-size: 0.75em;
            }
        }
    }
`

export const ProfileImage = styled.div`

    width: 60px; 
    height: 60px;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-position: 50%;
    cursor: pointer;

    ${media.largeDesktop}{
        width: 50px; 
        height: 50px;
    }

    ${media.macbigdesktop}{
        width: 45px; 
        height: 45px;
        
    }
    
    ${media.desktop}{
        width: 40px; 
        height: 40px;
        
    }
    
    ${media.smallDesktop}{
        width: 38px; 
        height: 38px;
    }
`