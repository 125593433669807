import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-product-and-service';

export const ProductAPI = {

    createProduct: async (product) => {
        const response = await axiosInstance.post(`${BASE_URL}/product/create`, product);
        return response.data;
    },

    updateProduct: async (product) => {
        const response = await axiosInstance.put(`${BASE_URL}/product/update`, product);
        return response.data;
    },

    deleteProduct: async (productId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/product/delete/${productId}`);
        return response.data;
    }
};