import styled from 'styled-components';
import { colors } from "../../utils/styles/colors";


export const CheckboxContainer = styled.label`
  display: flex;
  align-items: ${(props) => (props.align === "top" ? "flex-start" : props.align === "center" ? "center" : "flex-end")};
  cursor: pointer;
  gap: 5px;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  background: #ffffff;
  border: 2px solid ${colors.grey};
  border-radius: 4px;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  position: relative;
  &:before {
        font-family: "FontAwesome";
        content: "\f00c";
        font-size: 15px;
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }
  &:checked {
    &::before {
      color: ${colors.hightlight} !important;
    }
  }
`;

export const CheckboxText = styled.span`
  font-size: 0.69rem;
  margin-top: ${(props) => (props.align === "top" ? "-2px" : "0px")};
  text-align: justify;
  text-justify: auto;
  color: ${colors.grey};
  a {
      color: ${colors.grey};
      text-decoration: underline;
    }
`;