import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-collaboration';

export const ItemAPI = {
    // Read
    getItem: async (itemId) => {
        const response = await axiosInstance.get(`${BASE_URL}/item/${itemId}`);
        return response.data;
    },

    getItems: async () => {
        const response = await axiosInstance.get(`${BASE_URL}/item`);
        return response.data;
    },

    getUserItems: async (userId) => {
        const response = await axiosInstance.get(`${BASE_URL}/item/${userId}`);
        return response.data;
    },



    // Update
    setItemContent: async (itemId, itemDTO) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/upload-content/${itemId}`, itemDTO);
        return response.data;
    },

    approveContent: async (itemId, itemDTO) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/approve-content/${itemId}`, itemDTO);
        return response.data;
    },

    rejectContent: async (itemId, itemDTO) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/reject-content/${itemId}`, itemDTO);
        return response.data;
    },

    publishContent: async (itemId, itemDTO) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/publish-content/${itemId}`, itemDTO);
        return response.data;
    },

    setItemPublicInfluencer: async (itemId, isPublic) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/set-public-influencer/${itemId}?public=${isPublic}`);
        return response.data;
    },

    setItemPublicCompany: async (itemId, isPublic) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/set-public-company/${itemId}?public=${isPublic}`);
        return response.data;
    },

    setPublishingDate: async (itemId, publishingDate) => {
        const response = await axiosInstance.put(`${BASE_URL}/item/set-publishing-date/${itemId}`, publishingDate);
        return response.data;
    },
};