import styled from 'styled-components';
import CentraleSans from './../../utils/styles/CentraleSansBold.otf';
import { colors } from '../../utils/styles/colors';
import { media } from './../../utils/styles/media';

export const HomeContainer = styled.div`

    @font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }
   
    .subtitle{
        display  : flex;
        justify-content: space-between;
        h2{
            font-size: 3rem;
            color: ${colors.title};
        }
        .seeMore{
            align-self: flex-end;
            font-weight: bold;
            font-size: 1.7rem;
            color: ${colors.text};
            text-decoration: underline;
            font-family: 'Centrale', sans-serif;
            cursor: pointer;
        }
        a{
            align-self: flex-end;
            font-weight: bold;
            font-size: 1.7rem;
            color: ${colors.text};
            text-decoration: underline;
            font-family: 'Centrale', sans-serif;
        }
    }

    
    .content{
        display: flex;
        flex-direction: column;
        
        .cards{
            display: flex;
            margin-left: -10px;
            margin-right: -10px;  
        }
        .seeMoreMobile{
            display: none;
        }
    }
    
    .sliderContainer{
        //width: 90vw;
        width: 98%;
        margin-top:15px;
        
    }
    .sliderContainerNetwork{
        margin-top:15px;
        
    }


    /**-------Responsive---------*/
    ${media.largeDesktop}{
        .subtitle{
            a{
                font-size: 1.5rem;
            }
            .seeMore{
                font-size: 1.5rem;
            }
        }
        .sliderContainer{
            //width: 87vw;
            width: 97%;
        }
    }

    ${media.macdesktop}{
        
        .subtitle{
            h2{
                font-size: 2.5rem;
            }
            a{
                font-size: 1.3rem;
            }
            .seeMore{
                font-size: 1.3rem;
            }
        }
        .sliderContainer{
            width: 96%;
            margin-top:14px;
            
        }
    }
    ${media.desktop}{
        
        .subtitle{
            h2{
                font-size: 2.3rem;
            }
            a{
                font-size: 1.2rem;
            }
            .seeMore{
                font-size: 1.2rem;
            }
        }
        .sliderContainer{
            //width: 88vw;
            width: 95.5%;
            margin-top:10px;
            
        }
        .sliderContainerNetwork{
            margin-top:10px;
        }
    }

    ${media.smallDesktop}{
        .sliderContainer{
            width: 94%;
            margin-top:8px;
            
        }
        .sliderContainerNetwork{
            margin-top:8px;
            
        }
    }

    ${media.laptop}{
        .subtitle{
            h2{
                font-size: 2rem;
            }
            .seeMore{
                font-size: 1.1rem;
                
            } 
            a{
                font-size: 1.1rem;
            }
        }  
        .sliderContainer, .sliderContainerNetwork{
           margin-top:4px;
           width: 94%;
        }
    }

    ${media.tablet}{
        .subtitle{
            h2{
                font-size: 1.6rem;
            }
            .seeMore{
                font-size: 0.9rem;
                
            } 
            a{
                font-size: 0.9rem;
            }
        }
        .sliderContainer, .sliderContainerNetwork{
           margin-top:6px;
           width: 93.5%;
        } 
           
    }
    ${media.smallTablet}{
        .subtitle{
            h2{
                font-size: 2rem;
            }
        }
        .sliderContainer, .sliderContainerNetwork{
           margin-top:20px;
           //width: 79vw;
           width: 93.5%;
        }   
    }
   
    ${media.largeMobile}{
        .subtitle{
            h2{
                font-size: 1.5rem;
                text-align: center;
            }
            a{
                align-self: center;
                font-size: 0.9rem;
                margin: 20px 0;
                display: none;
            }
            .seeMore{
                color: ${colors.darkGrey};
                align-self: center;
                font-size: 0.9rem;
                margin: 20px 0;
                display: none;
            }
        }
        .content{
            display: flex;
            //flex-direction: column-reverse;
            .seeMoreMobile{
                display: block;
                color: ${colors.darkGrey};
                align-self: center;
                font-size: 0.9rem;
                margin: 20px 0;
            }
        }
        .collaborations{
            .cards{
                margin-top: -20px;
            }
                
        }    
        .sliderContainer{
            width: 100vw;
            margin-top: 8px;
        }
        .sliderContainerNetwork{
            width: 100vw;
            margin-top: 8px;
        }
    }

`

export const FirstSectionContainer = styled.div`

    padding: 0 100px 0;
    display: flex;
    flex-direction: column;
    .cards{
        display: flex;
        flex-wrap: wrap;
    }

    /**--------Responsive------- */
    ${media.macdesktop}{
        padding: 0 80px 0;
        h2{
            //margin-bottom: 20px;
        }
    }
    ${media.desktop}{
        padding: 0 50px 0;
        h2{
            //margin-bottom: 20px;
        }
    }
    ${media.largeMobile}{
        padding: 0 20px 0;
        h2{
            margin-bottom: 5px;
        }
    }


`


export const SectionsContainer = styled.div`

    padding:  50px 100px 0;
    display: flex;
    flex-direction: column;
    .cards{
        display: flex;
        flex-wrap: wrap;
    }

    /**--------Responsive------- */
    ${media.macdesktop} {
        padding:  50px 80px 0;
    }

    ${media.desktop} {
        padding:  50px 50px 0;
    }
    ${media.largeMobile} {
        padding: 20px 20px 0;
        h2{
            margin-bottom: 5px;
        }
        .collaborationsTitle{
            margin-bottom: 25px;
        }
    }

`
export const CenterSliderContainer = styled.div`

    padding:  50px 100px 0;
    display: flex;
    flex-direction: column; 

    /**--------Responsive------- */
    ${media.macdesktop} {
        padding:  50px 80px 0;
    }

    ${media.desktop}{
        padding:  50px 50px 0;
    }
    ${media.largeMobile}{
        padding: 20px 0;
        .subtitle{
            padding: 0 20px;
        }
    }
    
`


export const LastSectionsContainer = styled.div`

    padding: 50px;
    display: flex;
    flex-direction: column;

    /**--------Responsive------- */
    ${media.largeMobile}{
        padding: 20px 0;
        .content{
            padding: 0 20px;
        }
        .subtitle{
            padding: 0 20px;
        }
    }
    
`

export const LastSliderContainer = styled.div`

    padding:  50px 100px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    /**--------Responsive------- */
    ${media.macdesktop} {
        padding:  50px 80px 0;
    }

    ${media.desktop}{
        padding:  50px 50px 0;
    }

    ${media.largeMobile}{
        padding: 20px 0;
        .subtitle{
            padding: 0 20px;
        }
    }
    
`

export const ArrowContainer = styled.div`
    display: block; 
    background-color: ${colors.grey};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35%;
    line-height: 0;
    i{
        color: #ffffff;
        font-size: 1.4rem;
    }

    /**----------Responsive----------*/
    ${media.desktop}{
        width: 35px;
        height: 35px;
        top: 34%;
        
        i{
            font-size: 1.2rem;
        }
    }

    ${media.laptop}{
        width: 33px;
        height: 33px;
        top: 30%;
        i{
            font-size: 1rem;
        }
    }
`
