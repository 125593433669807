import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContainer } from "./ErrorStyled";
import { FormattedMessage } from 'react-intl';

import cuatro from './../../utils/images/4Error.svg';
import camera from './../../utils/images/cameraError.svg';
import { URLplatform, fluincrCaresEN, fluincrCaresES } from "../../utils/constants/constants";
import { LangContext } from "../../context/langContext";


export default function Error() {

    const navigate = useNavigate();
    const { locale } = useContext(LangContext);

    return (
        <ErrorContainer>
            <section>
                <h3><FormattedMessage id="Error_title" defaultMessage="¡Lo sentimos!" /></h3>
                <p><FormattedMessage id="Error_text_1" defaultMessage="¿No nos encontraste?" /></p>
                <p><FormattedMessage id="Error_text_2" defaultMessage="Probablemente estamos grabando contenido para una nueva colaboración." /></p>
                <button onClick={() => navigate(-2)}><FormattedMessage id="Error_button" defaultMessage="Volver a la página anterior" /></button>
                <a href={`${URLplatform}/influencers`}><FormattedMessage id="Error_text_3" defaultMessage="Conozca a los nuevos creadores de contenidos" /></a>
                <a href="#"><FormattedMessage id="Error_text_4" defaultMessage="Crea una nueva colaboración" /></a>
                <a href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN}><FormattedMessage id="Error_text_5" defaultMessage="Más información en Fluincr Cares" /></a>

            </section>
            <section>
                <div className="errorLogo">
                    <img src={cuatro} alt="cuatro" />
                    <img src={camera} alt="camera" />
                    <img src={cuatro} alt="cuatro" />
                </div>
                <p className="errorMessage"><FormattedMessage id="Error_text_6" defaultMessage="Página no encontrada" /></p>

            </section>

        </ErrorContainer>
    )
}