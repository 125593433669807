//export const environment = "fluincrdev.tmssas.com";
export const environment = "fluincr.com";

export const URL = `http://209.145.51.75`;

export const postRestURL = `http://209.145.51.75:3001`;

export const backendURL = `${URL}/fluincrbackend/api`;

export const API_GATEWAY_URL = `https://test.fluincr.com/fluincrback`;

export const URLplatform = `https://fluincr.com`;

export const fluincrCaresES = `https://support.fluincr.com/portal/es/home`;
export const fluincrCaresEN = `https://support.fluincr.com/portal/en/home`;

export const contactES = `https://support.fluincr.com/portal/es/newticket`;
export const contactEN = `https://support.fluincr.com/portal/en/newticket`;
