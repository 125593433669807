import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axios from 'axios';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user';

export const UserAPI = {
    // Read
    getUserLoginData: async () => {
        const response = await axiosInstance.get(`${BASE_URL}/user/get-private-user-data`);
        return response.data;
    },

    getPublicUserData: async (username, addProfileData = false) => {
        const response = await axios.get(`${BASE_URL}/user/get-public-user-data/${username}?addProfileData=${addProfileData}`);
        return response.data;
    },

    generateUserDeletionEmail: async (email) => {
        const response = await axiosInstance.post(`${BASE_URL}/user/generate-user-deletion-code/${email}`);
        return response.data;
    },

    deleteUser: async (code, email) => {
        const response = await axios.put(`${BASE_URL}/user/delete`, {
            code: code,
            email: email
        });
        return response.data;
    },

    desactivateUser: async () => {
        const response = await axiosInstance.put(`${BASE_URL}/user/desactivate`);
        return response.data;
    },

    verifyEmailExisting: async (email) => {
        const response = await axios.get(`${BASE_URL}/user/check-existing-email/${email}`);
        return response.data;
    },

    verifyUsernameExisting: async (username) => {
        const response = await axios.get(`${BASE_URL}/user/check-existing-username/${username}`);
        return response.data;
    },

    changePassword: async (oldPassword, newPassword) => {
        const response = await axiosInstance.put(`${BASE_URL}/user/change-password`, { 
            old_password: oldPassword,
            new_password: newPassword
         });
        return response.data;
    },

    sendResetPasswordEmail: async (email) => {
        const response = await axios.post(`${BASE_URL}/user/generate-password-reset-code/${email}`);
        return response.data;
    },
    
    resetPassword: async (password, code, email) => {
        const response = await axios.put(`${BASE_URL}/user/reset-password`, { 
            reset_password_code: code,
            new_password: password,
            email: email
         });
        return response.data;
    },

    sendRegisterEmailVerificationCode: async (email, language) => {
        const response = await axios.post(`${BASE_URL}/user/generate-email-verification-code/${email}?language=${language}`);
        return response.data;
    },

    validateRegisterEmailVerificationCode: async (email, code) => {
        const response = await axios.post(`${BASE_URL}/code-validator/validate-email-verification-code`, {
            email: email,
            code: code
        });
        return response.data;
    },

    updateImageProfile: async (image) => {
        const response = await axiosInstance.put(`${BASE_URL}/user/image-profile?image=${image}`);
        return response.data;
    }
};