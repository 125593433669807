import React from "react";
import { NetworkIconContainer } from "./NetworkIconStyled";

export default function facebookIcon() {
    return (
        <NetworkIconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.199" height="39.582" viewBox="0 0 21.199 39.582">
                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M21.42,22.265l1.1-7.163H15.645V10.453c0-1.96.96-3.87,4.039-3.87h3.125V.484A38.106,38.106,0,0,0,17.262,0C11.6,0,7.9,3.431,7.9,9.642V15.1H1.609v7.163H7.9V39.582h7.744V22.265Z" transform="translate(-1.609)" fill="#fff" />
            </svg>
        </NetworkIconContainer>
    )
}