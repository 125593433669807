import { createContext, useContext, useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { saveToken, deleteToken } from '../../store/token/action';
import { UserAPI } from "../../api/user/user";
import Spinner from "../../components/Spinner/Spinner";
import Alert from "../../components/Alert/Alert";
import { fluincrCaresEN, fluincrCaresES } from "../../utils/constants/constants";
import { LangContext } from "../langContext";

export const UserContext2 = createContext();

function UserProvider2({ children, saveToken }) {
    const dispatch = useDispatch();

    const { locale } = useContext(LangContext);

    const [userToken, setUserToken] = useState(null);

    // Estado para almacenar los datos del usuario
    const [userData, setUserData] = useState(null);

    // Estado para almacenar el tipo de usuario
    const [userType, setUserType] = useState('');

    // Estado para controlar si se muestra la alerta de token inválido
    const [showAlertInvalidToken, setShowAlertInvalidToken] = useState(false);

    // Estado para confirmar eliminación de cuenta exitosa
    const [showAlertDeleteAccount, setShowAlertDeleteAccount] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    
    useEffect(() => {
        const tokenSession = JSON.parse(sessionStorage.getItem('fluincrToken'));
        const tokenLocal = JSON.parse(localStorage.getItem('fluincrToken'));    
        
        if(tokenSession || tokenLocal){
            const token = tokenSession || tokenLocal;
            dispatch(saveToken(token));
            

            UserAPI.getUserLoginData().then((response) => {
                const userTypeResponse = response['user_type']?.name;
    
                setUserData(response);
                setUserType(userTypeResponse);
                setIsLoading(false);
                setUserToken(token);
    
                sessionStorage.setItem('fluincrUser', JSON.stringify(userTypeResponse));
            }).catch((error) => {
                setUserData(null);
                setUserType('');
                setUserToken(null);
                localStorage.removeItem('fluincrToken');
                sessionStorage.removeItem('fluincrToken');
                setShowAlertInvalidToken(true);
                dispatch(deleteToken());
                setIsLoading(false);
            });
        }
    }, []);

    useContext(() => {
        localStorage.removeItem('fluincrToken');
        localStorage.removeItem('fluincrUser');
        sessionStorage.removeItem('fluincrToken');
        sessionStorage.removeItem('fluincrUser');
        setUserToken(null);
        setUserType('');
        setUserData(null);
    }, [showAlertDeleteAccount]);

    return (
        <UserContext2.Provider value={{
            userData,
            setUserData,
            userType,
            setUserType,
            userToken,
            setUserToken,
            setShowAlertInvalidToken,
            setShowAlertDeleteAccount,
        }}>
            {children}
            {   
                (showAlertInvalidToken && !showAlertDeleteAccount) && 
                (
                    <Alert
                        title={<FormattedMessage id="invalid_user_title" defaultMessage="Usuario invalido" />}
                        text={<FormattedMessage id="invalid_user_text" defaultMessage="Por favor verifique e intente de nuevo" />}
                        setShowAlert={setShowAlertInvalidToken}
                    />
                )

                
                (isLoading) && (<Spinner />)
            }
            {
                (showAlertDeleteAccount) &&
                (
                    <Alert
                        title={<FormattedMessage id="delete_account_title" defaultMessage="Cuenta eliminada" />}
                        text={
                            <FormattedMessage 
                                id="delete_account_text" 
                                defaultMessage="Tu cuenta ha sido eliminada exitosamente. Para más información visita Fluincr Cares." 
                                values={{
                                    fluincrCaresLink: (
                                        <a href={(locale === 'es-CO') ? fluincrCaresES : fluincrCaresEN} target="_blank">
                                            <FormattedMessage id="fluincr_cares_link_text" defaultMessage="Fluincr Cares" />
                                        </a>
                                    )
                                }}
                            />
                        }
                        confirmText={<FormattedMessage id="delete_account_button" defaultMessage="Cerrar" />}
                        onConfirm={() => { setShowAlertDeleteAccount(false); }}
                        setShowAlert={setShowAlertDeleteAccount}
                    />
                )

            }
        </UserContext2.Provider>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.tokenReducer.token
    };
};

const mapDispatchToProps = {
    saveToken,
    deleteToken
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProvider2);
