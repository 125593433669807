import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const CollaborationItemCardPrivateProfileContainer = styled.div`

            width: 23%;
            height: 21vw;
            margin-bottom: 20px;
            margin-right: 2%;
            .cardCollaboration{
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                position: relative;
            }
            .switchContainer{
                position: absolute;
                z-index: 50;
                margin-left: 30px;
                margin-top: 30px;
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 76px;
                    height: 36px;
                }

                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .switch .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    transition: .4s;
                    border-radius: 18px;
                }

                .switch .slider:before {
                    position: absolute;
                    content: "";
                    height: 30px;
                    width: 30px;
                    left: 4px;
                    bottom: 3px;
                    background-color: white;
                    transition: .4s;
                    border-radius: 50%;
                }

                input:checked + .slider { 
                    background-color: ${colors.blue}; 
                }  
                input:checked + .slider:before {
                    transform: translateX(38px);
                }
            }
            
            .colaborator{
                width: 150px;
                height: 150px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                position: absolute;
                bottom: 30px;
                right: 30px;
                border-radius: 50%;
            }

    /**------Responsive-------- */
    ${media.largeDesktop}{
        .switchContainer{
            margin-left: 20px;
            margin-top: 20px;
            .switch {
                width: 56px;
                height: 28px;
            }
            .switch .slider:before {
                height: 22px;
                width: 22px;
            }
            input:checked + .slider:before {
                transform: translateX(26px);
            }
        }
        .colaborator{
            width: 100px;
            height: 100px;
            bottom: 20px;
            right: 20px;
        }
    }

    ${media.specialScreen}{
        .switchContainer{
            .switch {
                width: 48px;
                height: 22px;
            }
            .switch .slider:before {
                height: 16px;
                width: 16px;
            }
            input:checked + .slider:before {
                transform: translateX(24px);
            }
        }
        .colaborator{
            width: 80px;
            height: 80px;
        }
    }

    ${media.desktop}{
        .switchContainer{
            margin-left: 20px;
            margin-top: 20px;
            .switch {
                width: 36px;
                height: 18px;
            }

            .switch .slider:before {
                height: 12px;
                width: 12px;
            }
                 
            input:checked + .slider:before {
                transform: translateX(16px);
            }
        }
        .colaborator{
            width: 70px;
            height: 70px;
        }
    }

    ${media.smallDesktop}{
        .switchContainer{
            margin-left: 10px;
            margin-top: 10px;
        }
        .colaborator{
            width: 60px;
            height: 60px;
            bottom: 10px;
            right: 10px;
        }
    }

    ${media.tablet}{
        width: 31%;
        height: 28vw;
        .colaborator{
                width: 50px;
                height: 50px;
                bottom: 10px;
                right: 10px;
            }
    }

    ${media.largeMobile}{
        width: 48%;
        height: 40vw;
    
    }
`