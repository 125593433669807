import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { VoucherContainer, Profile } from "./VoucherStyled";
import { FormattedMessage } from 'react-intl';

import back from './../../utils/images/arrow_back.svg';
import print from './../../utils/images/print.svg';
import avatar from './../../utils/images/avatar_fluincr.png';

import { fill, formatNumber } from "../../services/generalSevices";
import { newFormat } from "../../services/dateServices";
import Spinner from "../../components/Spinner/Spinner";
import CompanyCardVoucher from "../../components/CompanyCardVoucher/CompanyCardVoucher";

import ReactToPrint from 'react-to-print';
import { StaticDataContext } from "../../context/staticDataContext";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { UserContext2 } from "../../context/user/userContext";
import { CompanyAPI } from "../../api/user/company/company";
import { CollaborationsContext } from "../../context/collaborationsContext";

export default function Voucher() {

    const navigate = useNavigate();
    const { idCollaboration } = useParams();

    const divPrintRef = useRef(null);

    const { currentCollaboration, setCurrentCollaboration } = useContext(CollaborationsContext);

    const { countrySelectList, fluincrServicesList } = useContext(StaticDataContext);

    const { userToken, userData } = useContext(UserContext2);

    const {
        image_profile: imageProfileInfluencer,
        account: accountInfluencer,
        name: nameInfluencer,
        family_name: famiyNameInfluencer,
        legal_name: legalNameInfluencer,
        address: addressInfluencer,
        identification_number: identificationNumberInfluencer,
    } = userData;

    const [collaboration, setCollaboration] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [productData, setProductData] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const limit = 5;
    const [companies, setCompanies] = useState([]);
;

    useEffect(() => {
        CompanyAPI.getCompaniesFiltered({ size: limit }).then((companiesPageable) => {
            setCompanies(companiesPageable.content);
        });

        if (userToken) {

            if(currentCollaboration && currentCollaboration.id === idCollaboration){
                setCollaboration(currentCollaboration);
                setCompanyData(currentCollaboration.company);
                setProductData(currentCollaboration.product);
                setIsLoading(false);
                return;
            }

            CollaborationAPI.getCollaboration(idCollaboration).then((collaboration) => {
                setCollaboration(collaboration);
                setCompanyData(collaboration?.company);
                setProductData(collaboration?.product);
                setCurrentCollaboration(collaboration);
                setIsLoading(false);
            }).catch((error) => {
                navigate('/error');         
            });
        }
    }, [userToken, idCollaboration])

    return (
        <VoucherContainer>
            <hr className="firstLine" />
            <div className="goBack" onClick={() => navigate(-1)}  >
                <img src={back} alt="go back" className="backIcon" />
                <p>
                    <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                </p>
            </div>
            {(isLoading) ? (<Spinner />) : (
                <div className="voucherBody" id='voucherPrint'>
                    <section className="voucher" id='voucherPrint' >

                        <h2 className="voucherTitle"> <FormattedMessage id="voucher_title_b" defaultMessage="Voucher de tu colaboración" /></h2>

                        <div className="voucherData">

                            <div className="information">
                                <p className="subtitle"><FormattedMessage id="voucher_text_1_b" defaultMessage="Voucher #" />
                                    {fill(collaboration?.id, 8)}</p>
                                <div className="userData">
                                    <div className="profile">
                                        <Profile image={imageProfileInfluencer ?? avatar} />
                                        <p className="name">{`${nameInfluencer} ${famiyNameInfluencer}`}</p>
                                    </div>
                                    <div className="data">
                                        <p className="accountId"><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{accountInfluencer}</p>
                                        <p>{legalNameInfluencer}</p>
                                        <p>ID {(identificationNumberInfluencer) ?? '-'}</p>
                                        <p>{addressInfluencer?.street}</p>
                                        <p>{addressInfluencer?.city && `${addressInfluencer.city}, `}{addressInfluencer?.state && `${addressInfluencer.state}`}</p>
                                        <p>{countrySelectList.find((country) => country.value === addressInfluencer?.country_id)?.label}</p>
                                        <p>{addressInfluencer?.postal_code ?? '-'}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="prices">
                                <div className="subtitle">
                                    {(collaboration?.creation_date) && (<p className="date">{newFormat(collaboration?.creation_date)}</p>)}

                                    <ReactToPrint
                                        trigger={() => <button className="printButton"><img src={print} alt="print" className="print" /></button>}
                                        content={() => divPrintRef.current}
                                    />
                                </div>

                                <table>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_3" defaultMessage="Subtotal" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.base_amount - (collaboration?.gift_value || 0))}</td>

                                    </tr>

                                    <tr>
                                        <td><FormattedMessage id="voucher_text_4" defaultMessage="Impuestos" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.taxes)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_5" defaultMessage="Descuento" /></td>
                                        <td className="price">- $ {formatNumber(collaboration?.cupon_discount)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_6" defaultMessage="Total" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.total_amount)}</td>
                                    </tr>
                                    {
                                        (collaboration?.collaboration_payment_order_id) && 
                                        <tr>
                                            <td><FormattedMessage id="voucher_text_7" defaultMessage="Pago" /> #{collaboration?.collaboration_payment_order_id}</td>
                                            <td className="price">($ {formatNumber(collaboration?.total_amount)})</td>
                                        </tr>
                                    }
                                    {
                                        (collaboration?.collaboration_payment_order_external_id) && 
                                        <tr>
                                            <td>PayPal ID</td>
                                            <td className="price">{collaboration?.collaboration_payment_order_external_id}</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>

                        <hr />

                        <div className="companyData">
                            <div className="profile">
                                <Profile image={companyData?.image_profile ?? avatar} />
                                <p className="name">{companyData?.name}</p>
                            </div>
                            <div className="companyDetails">
                                <p className="accountId"><b><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{companyData?.account}</b></p>
                                <p>{companyData?.legal_name}</p>
                                <p>NIT: {companyData?.identification_number}</p>
                                <p>{companyData?.address?.street}</p>
                                <p>{companyData?.address?.city && `${companyData?.address?.city}, `}{companyData?.address?.state && `${companyData?.address?.state} `}</p>
                                <p>{countrySelectList.find((country) => country.value === companyData?.address?.country_id)?.label}</p>
                                <p>{companyData?.address?.postal_code ?? '-'}</p>
                            </div>
                        </div>
                        <div className="collaborationData">
                            <div className="profile">
                                <Profile image={(productData?.image) ?? avatar} />
                                <p className="name" style={{ textTransform: 'capitalize' }}>{productData?.name}</p>
                            </div>
                            <table>

                                {collaboration?.items?.map((item) => {
                                    const serviceName = fluincrServicesList.find((service) => service.id === item.service_id)?.name;
                                    return (<tr>
                                        <td><FormattedMessage id="voucher_text_9" defaultMessage="Contenido #" /> {fill(item.id)} <span>{serviceName}</span></td>
                                        <td className="price">$ {formatNumber(item.price)}</td>
                                    </tr>)
                                })}

                                <tr>
                                    <td><FormattedMessage id="voucher_text_11" defaultMessage="Producto/servicio gratuito" /></td>
                                    {(collaboration?.gift) ? (<td className="price">- $ {formatNumber(collaboration?.gift_value)}</td>) : (<td className="price">- $ 0</td>)}
                                </tr>
                            </table>

                        </div>
                    </section>

                    <hr className="verticalLine" />

                    <section className="featured">
                        <h3 className="subtitle"><FormattedMessage id="voucher_subtitle_1" defaultMessage="¿Estás listo para tu próxima colaboración?" /></h3>

                        <p className="featuredSubtitle"><FormattedMessage id="voucher_subtitle_3" defaultMessage="Influencers destacados" /></p>

                        <div className="content">
                            <Link to={"/companies"}>
                                <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
                            </Link>
                            <div className="cards influencers">

                                {companies.map((company) => (
                                    <CompanyCardVoucher company={company} key={company.id} page='voucher' />
                                ))}
                            </div>
                        </div>

                    </section>

                    {/**-----------TO PRINT------------ */}
                    <section className="voucherToPrint" ref={divPrintRef} >

                        <h2 className="voucherTitle"> <FormattedMessage id="voucher_title_b" defaultMessage="Voucher de tu colaboración" /></h2>
                        <br />
                        <div className="voucherData">

                            <div className="information">
                                <p className="subtitle"><FormattedMessage id="voucher_text_1_b" defaultMessage="Voucher #" />
                                    {fill(collaboration?.id, 8)}</p>
                                <div className="userData">
                                    <div className="profile">
                                        <Profile image={imageProfileInfluencer ?? avatar} />
                                        <p className="name">{`${nameInfluencer} ${famiyNameInfluencer}`}</p>
                                    </div>

                                    <div className="data">
                                        <p className="accountId"><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{accountInfluencer}</p>
                                        <p>{legalNameInfluencer}</p>
                                        <p>ID {(identificationNumberInfluencer) ?? '-'}</p>
                                        <p>{addressInfluencer?.street ?? '-'}</p>
                                        <p>{addressInfluencer?.city && `${addressInfluencer.city}, `}{addressInfluencer?.state && `${addressInfluencer.state}`}</p>
                                        <p>{countrySelectList.find((country) => country.value === addressInfluencer?.country_id)?.label}</p>
                                        <p>{addressInfluencer?.postal_code ?? '-'}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="prices">
                                <div className="subtitle">
                                    {(collaboration?.creation_date !== '') && (<p className="date">{newFormat(collaboration?.creation_date)}</p>
                                    )}
                                </div>

                                <table>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_3" defaultMessage="Subtotal" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.base_amount - (collaboration?.gift_value || 0))}</td>

                                    </tr>

                                    <tr>
                                        <td><FormattedMessage id="voucher_text_4" defaultMessage="Impuestos" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.taxes)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_5" defaultMessage="Descuento" /></td>
                                        <td className="price">- $ {formatNumber(collaboration?.cupon_discount)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_6" defaultMessage="Total" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.total_amount)}</td>
                                    </tr>
                                    {
                                        (collaboration?.collaboration_payment_order_id) && 
                                        <tr>
                                            <td><FormattedMessage id="voucher_text_7" defaultMessage="Pago" /> #{collaboration?.collaboration_payment_order_id}</td>
                                            <td className="price">($ {formatNumber(collaboration?.total_amount)})</td>
                                        </tr>
                                    }
                                    {
                                        (collaboration?.collaboration_payment_order_external_id) && 
                                        <tr>
                                            <td>PayPal ID</td>
                                            <td className="price">{collaboration?.collaboration_payment_order_external_id}</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="companyData">
                            <div className="profile">
                                <Profile image={companyData?.image_profile ?? avatar} />
                                <p className="name">{companyData?.name}</p>
                            </div>
                            <div className="companyDetails">
                                <p className="accountId"><b><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{companyData?.account}</b></p>
                                <p>{companyData?.legal_name}</p>
                                <p>NIT: {companyData?.identification_number}</p>
                                <p>{companyData?.address?.street}</p>
                                <p>{companyData?.address?.city && `${companyData?.address?.city}, `}{companyData?.address?.state && `${companyData?.address?.state} `}</p>
                                <p>{countrySelectList.find((country) => country.value === companyData?.address?.country_id)?.label}</p>
                                <p>{companyData?.address?.postal_code ?? '-'}</p>
                            </div>
                        </div>
                        <div className="collaborationData">
                            <div className="profile">
                                <Profile image={(productData?.image) ?? avatar} />
                                <p className="name" style={{ textTransform: 'capitalize' }}>{productData?.name}</p>
                            </div>
                            <table>

                                {collaboration?.items?.map((item) => {
                                    const serviceName = fluincrServicesList.find((service) => service.id === item.service_id)?.name;
                                    return (<tr>
                                        <td><FormattedMessage id="voucher_text_9" defaultMessage="Contenido #" /> {fill(item.id)} <span>{serviceName}</span></td>
                                        <td className="price">$ {formatNumber(item.price)}</td>
                                    </tr>)
                                })}

                                <tr>
                                    <td><FormattedMessage id="voucher_text_11" defaultMessage="Producto/servicio gratuito" /></td>
                                    {(collaboration?.gift) ? (<td className="price">- $ {formatNumber(collaboration?.gift_value)}</td>) : (<td className="price">- $ 0</td>)}
                                </tr>
                            </table>

                        </div>
                    </section>

                </div>
            )}

        </VoucherContainer>
    )
}