import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './../../../Bootstrap.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E8339E', // Cambia '#colorDeLaBarra' por el color que desees para la barra
    },
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate" 
          {...props} 
          sx={{ color: '#E8339E', bgcolor: '#F7C4E2', height: '10px' }} // Aquí cambiamos el color y la altura
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" className='fs-6' color="#8D8D8D">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({ porcentajeCarga }) {

  return (
    <ThemeProvider theme={theme}>
      <div className='position-fixed w-100 h-100 d-flex justify-content-center top-0 start-0'>
        
        <Box className='h-100 position-absolute top-50' sx={{ width: '50%' }}>
          <div className='d-flex flex-column p-3 pt-4 pb-1 border-3 fl-border bg-white'>
            <LinearProgressWithLabel value={porcentajeCarga} />
            <Typography variant="body2" className='fs-7 d-flex justify-content-center' color="#8D8D8D">
              <FormattedMessage id="linearLoaderText" defaultMessage="Cargando archivo… gracias por tu paciencia" />
            </Typography>
          </div>
          
        </Box>
      </div>
    </ThemeProvider>
    
  );
}
