import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { InvoiceContainer, Profile } from "./InvoiceStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import { LangContext } from "../../context/langContext";

import back from './../../utils/images/arrow_back.svg';
import print from './../../utils/images/print.svg';
import avatar from './../../utils/images/avatar_fluincr.png';

import InfluencerCardInvoice from '../../components/InfluencerCardInvoice/InfluencerCardInvoice';
import { fill, formatNumber } from "../../services/generalSevices";
import { formatDateLocal, fromUTCtoLocal, newFormat, toUTCString } from "../../services/dateServices";
import Spinner from "../../components/Spinner/Spinner";


import ReactToPrint from 'react-to-print';
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { UserContext2 } from "../../context/user/userContext";
import { StaticDataContext } from "../../context/staticDataContext";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CollaborationsContext } from "../../context/collaborationsContext";

export default function Invoice() {

    const navigate = useNavigate();
    const { idCollaboration } = useParams();

    const divPrintRef = useRef(null);

    const { currentCollaboration, setCurrentCollaboration } = useContext(CollaborationsContext);

    const { countrySelectList, fluincrServicesList } = useContext(StaticDataContext);

    const { userToken, userData } = useContext(UserContext2);

    const {
        image_profile: imageProfileCompany,
        account: accountCompany,
        name: nameCompany,
        legal_name: legalNameCompany,
        address: addressCompany,
        user_contact: userContactCompany,
        identification_type_id: identificationTypeIdCompany,
        identification_number: identificationNumberCompany,
    } = userData;

    const [collaboration, setCollaboration] = useState({});
    const [influencerData, setInfluencerData] = useState({});
    const [productData, setProductData] = useState({});


    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(5);
    const [featuredInfluencers, setFeaturedInfluencers] = useState([]);

    useEffect(() => {

        InfluencerAPI.getAllInfluencersFiltered({ featured: true, size: limit }).then((influencersPageables) => {
            setFeaturedInfluencers(influencersPageables.content);
        })

        if (userToken) {

            if(currentCollaboration && currentCollaboration.id === idCollaboration){
                setCollaboration(currentCollaboration);
                setInfluencerData(currentCollaboration?.influencer);
                setProductData(currentCollaboration?.product);
                setIsLoading(false);
                return;
            }

            CollaborationAPI.getCollaboration(idCollaboration).then((collaboration) => {
                console.log(collaboration);
                setCollaboration(collaboration);
                setInfluencerData(collaboration?.influencer);
                setProductData(collaboration?.product);
                setIsLoading(false);
            }).catch((error) => {
                navigate('/error');
            });
        }


    }, [userToken, idCollaboration])

    return (
        <InvoiceContainer>
            <div className="goBack" onClick={() => navigate(-1)}  >
                <img src={back} alt="go back" className="backIcon" />
                <p>
                    <FormattedMessage id="go_back" defaultMessage="ir atrás" />
                </p>
            </div>
            {(isLoading) ? (<Spinner />) : (
                <div className="invoiceBody" >

                    <section className="invoice" id='invoicePrint' >

                        <h2 className="invoiceTitle"> <FormattedMessage id="voucher_title_a" defaultMessage="Factura de tu colaboración" /></h2>

                        <div className="invoiceData">

                            <div className="information">
                                <p className="subtitle"><FormattedMessage id="voucher_text_1_a" defaultMessage="Factura #" />
                                    {fill(collaboration?.id, 8)}</p>
                                <div className="userData">
                                    <div className="profile">
                                        <Profile image={imageProfileCompany ?? avatar} />
                                        <p className="name">{nameCompany}</p>
                                    </div>
                                    <div className="data">
                                        <p className="accountId"><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{accountCompany}</p>
                                        <p>{legalNameCompany}</p>
                                        <p>ID {(identificationNumberCompany) ?? '-'}</p>
                                        <p>{addressCompany?.street}</p>
                                        <p>{addressCompany?.city && `${addressCompany.city}, `}{addressCompany?.state && `${addressCompany.state}`}</p>
                                        <p>{countrySelectList.find((country) => country.value === addressCompany?.country_id)?.label}</p>
                                        <p>{addressCompany?.postal_code ?? '-'}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="prices">
                                <div className="subtitle">
                                    {(collaboration?.creation_date) && (<p className="date">{newFormat(collaboration?.creation_date)}</p>)}

                                    <ReactToPrint
                                        trigger={() => <button className="printButton"><img src={print} alt="print" className="print" /></button>}
                                        content={() => divPrintRef.current}
                                    />

                                </div>

                                <table>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_3" defaultMessage="Subtotal" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.base_amount - (collaboration?.gift_value || 0))}</td>

                                    </tr>

                                    <tr>
                                        <td><FormattedMessage id="voucher_text_4" defaultMessage="Impuestos" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.taxes)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_5" defaultMessage="Descuento" /></td>
                                        <td className="price">- $ {formatNumber(collaboration?.cupon_discount)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_6" defaultMessage="Total" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.total_amount)}</td>
                                    </tr>
                                    {
                                        (collaboration?.collaboration_payment_order_id) && 
                                        <tr>
                                            <td><FormattedMessage id="voucher_text_7" defaultMessage="Pago" /> #{collaboration?.collaboration_payment_order_id}</td>
                                            <td className="price">($ {formatNumber(collaboration?.total_amount)})</td>
                                        </tr>
                                    }
                                    {
                                        (collaboration?.collaboration_payment_order_external_id) && 
                                        <tr>
                                            <td>PayPal ID</td>
                                            <td className="price">{collaboration?.collaboration_payment_order_external_id}</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>

                        <hr />

                        <div className="companyData">
                            <div className="profile">
                                <Profile image={influencerData.image_profile ?? avatar} />
                                <p className="name">{influencerData?.name} {influencerData?.family_name}</p>
                            </div>
                            <div className="companyDetails">
                                <p className="accountId"><b><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{influencerData?.account}</b></p>
                                <p>{influencerData?.legal_name}</p>
                                <p>ID {influencerData?.identification_number}</p>
                                <p>{influencerData?.address?.street}</p>
                                <p>{influencerData?.address?.city && `${influencerData?.address?.city}, `}{influencerData?.address?.state && `${influencerData?.address?.state} `}</p>
                                <p>{countrySelectList.find((country) => country.value === influencerData?.address?.country_id)?.label}</p>
                                <p>{influencerData?.address?.postal_code ?? '-'}</p>
                            </div>
                        </div>
                        <div className="collaborationData">
                            <div className="profile">
                                <Profile image={productData?.image ?? avatar} />
                                <p className="name" style={{ textTransform: 'capitalize' }}>{productData?.name}</p>
                            </div>
                            <table>
                                {collaboration?.items?.map((item) => {
                                    const serviceName = fluincrServicesList.find((service) => service.id === item.service_id)?.name;
                                    return (<tr>
                                        <td><FormattedMessage id="voucher_text_9" defaultMessage="Contenido #" /> {fill(item.id)} <span>{serviceName}</span></td>
                                        <td className="price">$ {formatNumber(item.price)}</td>
                                    </tr>)
                                })}

                                <tr>
                                    <td><FormattedMessage id="voucher_text_11" defaultMessage="Producto/servicio gratuito" /></td>
                                    {(collaboration?.gift) ? (<td className="price">- $ {formatNumber(collaboration?.gift_value)}</td>) : (<td className="price">- $ 0</td>)}
                                </tr>
                            </table>

                        </div>
                    </section>

                    <hr className="verticalLine" />

                    <section className="featured">
                        <h3 className="subtitle"><FormattedMessage id="voucher_subtitle_1" defaultMessage="¿Estás listo para tu próxima colaboración?" /></h3>

                        <p className="featuredSubtitle"><FormattedMessage id="voucher_subtitle_2" defaultMessage="Influencers destacados" /></p>

                        <div className="content">
                            <Link to={"/influencers"}>
                                <FormattedMessage id="home_more_text" defaultMessage="Ver más +" />
                            </Link>
                            <div className="cards influencers">

                                {featuredInfluencers.map((influencer) => (
                                    <InfluencerCardInvoice influencer={influencer} key={influencer.id} page='voucher' />
                                ))}
                            </div>
                        </div>

                    </section>

                    {/**-----------TO PRINT-------------- */}
                    <section className="invoiceToPrint" id='invoicePrint' ref={divPrintRef}>

                        <h2 className="invoiceTitle"> <FormattedMessage id="voucher_title_a" defaultMessage="Factura de tu colaboración" /></h2>
                        <br />
                        <div className="invoiceData">

                            <div className="information">
                            <p className="subtitle"><FormattedMessage id="voucher_text_1_a" defaultMessage="Factura #" />
                                    {fill(collaboration?.id, 8)}</p>
                                <div className="userData">
                                    <div className="profile">
                                        <Profile image={imageProfileCompany ?? avatar} />
                                        <p className="name">{nameCompany}</p>
                                    </div>
                                    <div className="data">
                                        <p className="accountId"><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{accountCompany}</p>
                                        <p>{legalNameCompany}</p>
                                        <p>ID {(identificationNumberCompany) ?? '-'}</p>
                                        <p>{addressCompany?.street}</p>
                                        <p>{addressCompany?.city && `${addressCompany.city}, `}{addressCompany?.state && `${addressCompany.state}`}</p>
                                        <p>{countrySelectList.find((country) => country.value === addressCompany?.country_id)?.label}</p>
                                        <p>{addressCompany?.postal_code ?? '-'}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="prices">
                                <div className="subtitle">
                                    {(collaboration?.creation_date) && (<p className="date">{newFormat(collaboration?.creation_date)}</p>)}
                                </div>

                                <table>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_3" defaultMessage="Subtotal" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.base_amount - (collaboration?.gift_value || 0))}</td>

                                    </tr>

                                    <tr>
                                        <td><FormattedMessage id="voucher_text_4" defaultMessage="Impuestos" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.taxes)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_5" defaultMessage="Descuento" /></td>
                                        <td className="price">- $ {formatNumber(collaboration?.cupon_discount)}</td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="voucher_text_6" defaultMessage="Total" /></td>
                                        <td className="price">$ {formatNumber(collaboration?.total_amount)}</td>
                                    </tr>
                                    {
                                        (collaboration?.collaboration_payment_order_id) && 
                                        <tr>
                                            <td><FormattedMessage id="voucher_text_7" defaultMessage="Pago" /> #{collaboration?.collaboration_payment_order_id}</td>
                                            <td className="price">($ {formatNumber(collaboration?.total_amount)})</td>
                                        </tr>
                                    }
                                    {
                                        (collaboration?.collaboration_payment_order_external_id) && 
                                        <tr>
                                            <td>PayPal ID</td>
                                            <td className="price">{collaboration?.collaboration_payment_order_external_id}</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="companyData">
                            <div className="profile">
                                <Profile image={influencerData.image_profile ?? avatar} />
                                <p className="name">{influencerData?.name} {influencerData?.family_name}</p>
                            </div>
                            <div className="companyDetails">
                                <p className="accountId"><b><FormattedMessage id="voucher_text_2" defaultMessage="Cuenta #" />{influencerData?.account}</b></p>
                                <p>{influencerData?.legal_name}</p>
                                <p>ID {influencerData?.identification_number}</p>
                                <p>{influencerData?.address?.street}</p>
                                <p>{influencerData?.address?.city && `${influencerData?.address?.city}, `}{influencerData?.address?.state && `${influencerData?.address?.state} `}</p>
                                <p>{countrySelectList.find((country) => country.value === influencerData?.address?.country_id)?.label}</p>
                                <p>{influencerData?.address?.postal_code ?? '-'}</p>
                            </div>
                        </div>
                        <br />
                        <div className="collaborationData">
                            <div className="profile">
                                <Profile image={productData?.image ?? avatar} />
                                <p className="name" style={{ textTransform: 'capitalize' }}>{productData?.name}</p>
                            </div>
                            <table>
                                {collaboration?.items?.map((item) => {
                                    const serviceName = fluincrServicesList.find((service) => service.id === item.service_id)?.name;
                                    return (<tr>
                                        <td><FormattedMessage id="voucher_text_9" defaultMessage="Contenido #" /> {fill(item.id)} <span>{serviceName}</span></td>
                                        <td className="price">$ {formatNumber(item.price)}</td>
                                    </tr>)
                                })}

                                <tr>
                                    <td><FormattedMessage id="voucher_text_11" defaultMessage="Producto/servicio gratuito" /></td>
                                    {(collaboration?.gift) ? (<td className="price">- $ {formatNumber(collaboration?.gift_value)}</td>) : (<td className="price">- $ 0</td>)}
                                </tr>
                            </table>
                        </div>
                    </section>
                </div>
            )}
        </InvoiceContainer>
    )
}