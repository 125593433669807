import React, { useState } from "react";
import { MyProfileGalleryContainer, ImageGallery } from "./MyProfileGalleryStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import camera from './../../utils/images/camera.svg';
import deleteIcon from './../../utils/images/close_circle.svg';
import { useContext } from "react";
import Spinner from "../Spinner/Spinner";
import { handleImageUpload } from "../../services/compress";
import { getBase64 } from "../../services/generalSevices";
import { useEffect } from "react";
import { uploadImageS3 } from "../../utils/common/s3";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { GalleryAPI } from "../../api/user/influencer/gallery";

export default function MyProfileGallery() {

    const [imagesToSend, setImagesToSend] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const { userData, setUserData, userType } = useContext(UserContext2);



    const saveImage = () => {

        setIsLoading(true);

        const imageBody = {
            base64File: imagesToSend[0].data_url.split(',')[1],
            formatFile: imagesToSend[0].file.split('/')[1],
        }
        if (userType === UserTypes.INFLUENCER) {
            imageBody.address = `fluincr/${userData?.account}/profile`
        } else {
            imageBody.address = `company/${userData?.account}/profile`
        }

        uploadImageS3(imageBody.address, 'image/' + imageBody.formatFile, imagesToSend[0].file).then((fileLink) => {
            if (fileLink) {
                GalleryAPI.addGalleryItem(fileLink).then((newGalleryItem) => {
                    setIsLoading(false);
                    setImagesToSend([]);
                    setUserData({
                        ...userData,
                        profile_data: {
                            ...userData?.profile_data,
                            gallery: [...userData?.profile_data.gallery, newGalleryItem]
                        }
                    });
                }).catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    }


    useEffect(() => {
        if (imagesToSend.length > 0) {
            saveImage()
        }
    }, [imagesToSend])


    const showCards = () => {
        const array = []

        for (let index = 0; index < (7 - userData?.profile_data?.gallery.length); index++) {
            array.push(
            <>
                <div className="uploadImage">
                    <label htmlFor="gallery-file-input" className="fileLabel">
                        <div className="card" >
                            <img src={camera} alt="camera" />
                            <p><FormattedMessage id="my_profile_text_16" defaultMessage="Cargar nueva imagen" /></p>
                        </div>
                    </label>

                    <input type="file" id="gallery-file-input" accept="image/png, image/jpeg" /* value={galleryImage}  */ onChange={(e) => {
                        /*setGalleryImage(e.target.value) */
                        setIsLoading(true);
                        handleImageUpload({ event: e, maxWidthOrHeight: 1024 }).then((compressedFile) => {
                            getBase64({ file: compressedFile }).then((image) => {
                                setImagesToSend([{ data_url: image, file: compressedFile.type }]);
                            })
                        })
                    }} />
                </div>
            </>

            )

        }
        return array
    }

    const deleteImage = (id, index) => {
        setIsLoading(true);

        GalleryAPI.deleteGalleryItem(id).then(() => {
            setIsLoading(false);
            setUserData({
                ...userData,
                gallery: userData?.gallery.filter((galleryItem) => galleryItem.id !== id)
            });
        }).catch((error) => {
            setIsLoading(false);
        });
    }


    return (
        <MyProfileGalleryContainer>
            <h2 className="title"><FormattedMessage id="my_profile_subtitle_3" defaultMessage="Contenido" /></h2>
            <div className="cardsContainer">
                {userData?.profile_data?.gallery.map((galleryItem, index) => {
                    return (
                        <div className="imageContainer">
                            <img src={deleteIcon} alt="delete" className="delete" onClick={() => { deleteImage(galleryItem?.id, index) }} />
                            <ImageGallery image={galleryItem?.url} />
                        </div>
                    )
                })}
                {showCards()}

                <div className="uploadImage">
                    <label htmlFor="gallery-file-input" className="fileLabel">
                        <div className="card" >
                            <img src={camera} alt="camera" />
                            <p><FormattedMessage id="my_profile_text_16" defaultMessage="Cargar nueva imagen" /></p>
                        </div>
                    </label>

                    <input type="file" id="gallery-file-input" accept="image/png, image/jpeg" /* value={galleryImage} */ onChange={(e) => {
                        //setGalleryImage(e.target.value)
                        setIsLoading(true);
                        handleImageUpload({ event: e, maxWidthOrHeight: 1024 }).then((compressedFile) => {

                            getBase64({ file: compressedFile }).then((image) => {

                                setImagesToSend([{ data_url: image, file: compressedFile.type }]);
                            })
                        })
                    }} />
                </div>

            </div>

            {(isLoading) && (<Spinner />)}

        </MyProfileGalleryContainer>
    )
}