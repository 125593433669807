import React, { useEffect, useState } from "react";
import { ModalChangePasswordContainer } from "./ModalChangePasswordStyled";
import { FormattedMessage } from 'react-intl';
import check_circle from './../../utils/images/check_circle.png';
import close from './../../utils/images/close_circle.svg';
import { useContext } from "react";
import Spinner from "../Spinner/Spinner";
import ModalSuccessfulChangePassword from "../ModalSuccessfulChangePassword/ModalSuccessfulChangePassword";
import { useNavigate } from "react-router-dom";
import { UserContext2 } from "../../context/user/userContext";
import { UserAPI } from "../../api/user/user";


export default function ModalChangePassword({ setShowEditPassword }) {

    const [actualPassword, setActualPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repetNewPassword, setRepetNewPassword] = useState('');

    const [validActualPassword, setValidActualPassword] = useState(false);
    const [validNewPassword, setValidNewPassword] = useState(false);
    const [validRepetNewPassword, setValidRepetNewPassword] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const [showErrorOldPassword, setShowErrorOldPassword] = useState(false);
    const [showErrorNewPassword, setShowErrorNewPassword] = useState(false);

    const [showSuccessfulPassword, setShowSuccessfulPassword] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const { userToken, setUserToken, userType, setUserType, setUserData } = useContext(UserContext2);

    const navigate = useNavigate();

    useEffect(() => {
        //setShowEmptyFieldsMessage(false);
        const expRegPassword = new RegExp(
            "^(?=.*?[A-ZÑÁÉÍÓÚ])(?=.*?[a-zñáéíóú])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$"
        );

        if (actualPassword.length > 7) {
            setValidActualPassword(true);
        } else {
            setValidActualPassword(false);
        }

        if (expRegPassword.test(newPassword)) {
            setValidNewPassword(true)
        } else {
            setValidNewPassword(false);
        }

        if (validNewPassword) {
            if (newPassword === repetNewPassword) {
                setValidRepetNewPassword(true)
            } else {
                setValidRepetNewPassword(false);
            }
        }

    }, [actualPassword, newPassword, repetNewPassword])


    useEffect(() => {
        setShowErrorNewPassword(false)
        setShowErrorOldPassword(false);

        if (validActualPassword && validNewPassword && validRepetNewPassword) {
            setDisabled(false);
        }
        else {
            setDisabled(true)
        }
    }, [validActualPassword, validNewPassword, validRepetNewPassword])

    const changePassword = () => {
        if (validActualPassword && validNewPassword && validRepetNewPassword) {
            setIsLoading(true);

            UserAPI.changePassword(actualPassword, newPassword).then((response) => {
                setIsLoading(false);
                setShowEditPassword(false);
                logout();
            }).catch((error) => {
                console.error(error);
                setIsLoading(false);
                setShowErrorNewPassword(true)
            });
        } else {
            setShowErrorNewPassword(true)
        }

    }

    const logout = () => {
        localStorage.removeItem('fluincrToken');
        localStorage.removeItem('fluincrUser');
        sessionStorage.removeItem('fluincrToken');
        sessionStorage.removeItem('fluincrUser');
        setUserData(null);
        setUserToken(null);
        setUserType('');
        navigate('/signin');
    }

    return (
        <ModalChangePasswordContainer>

            <img src={close} alt="close" className="close" onClick={() => { setShowEditPassword(false) }} />

            <div className="changeBody">
                <h3><FormattedMessage id="change_password_title" defaultMessage="Cambiar mi contraseña" /></h3>

                <label htmlFor="password" >
                    <FormattedMessage id="change_password_text_1" defaultMessage="Contraseña actual" />
                </label>
                <div className="inputContainer">
                    <input id="password" className="password" type="text" onChange={(event) => { setActualPassword(event.target.value) }} value={actualPassword} />
                    {(validActualPassword) && (<img src={check_circle} alt="password" className="check" />)}
                </div>
                {(showErrorOldPassword) && (<p className="error"><FormattedMessage id="change_password_text_4" defaultMessage="Contraseña incorrecta" /></p>)}

                <label htmlFor="newPassword" >
                    <FormattedMessage id="change_password_text_2" defaultMessage="Nueva contraseña" />
                </label>
                <div className="inputContainer">
                    <input id="newPassword" className="password" type="text" onChange={(event) => { setNewPassword(event.target.value) }} value={newPassword} />

                    {(validNewPassword) && (<img src={check_circle} alt="password" className="check" />)}
                </div>

                <label htmlFor="repetNewPassword" >
                    <FormattedMessage id="change_password_text_3" defaultMessage="Confirmar nueva contraseña" />
                </label>
                <div className="inputContainer">
                    <input id="repetNewPassword" className="password" type="text" onChange={(event) => { setRepetNewPassword(event.target.value) }} value={repetNewPassword} />

                    {(validRepetNewPassword) && (<img src={check_circle} alt="password" className="check" />)}
                </div>

                {(showErrorNewPassword) && (<div className="errorContainer"><p className="error"> <FormattedMessage id="sign_up_error_password_message" defaultMessage="La contraseña debe contener al menos una mayuscula a minuscula un numero y entre 8 y 10 caracteres" /> </p></div>)}

                <button className={`${(disabled) && "disabled"} save`} onClick={changePassword}>
                    <FormattedMessage id="change_password_button" defaultMessage="Guardar contraseña" />
                </button>

            </div>

            {(isLoading) && (<Spinner />)}

            {(showSuccessfulPassword) && (<ModalSuccessfulChangePassword setShowEditPassword={setShowEditPassword} setShowSuccessfulPassword={setShowSuccessfulPassword} />)}

        </ModalChangePasswordContainer>
    )
}