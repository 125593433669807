import React, { useContext, useEffect, useState } from "react";
import { CompanyCardVoucherContainer } from "./CompanyCardVoucherStyled";

import avatar from './../../utils/images/avatar_fluincr.png';
import { useNavigate } from 'react-router-dom';
import { StaticDataContext } from "../../context/staticDataContext";


export default function CompanyCardVoucher({ company, page }) {

    const navigate = useNavigate();

    const { countryList, categorySelectList } = useContext(StaticDataContext);

    const [country, setCountry] = useState(null);

    useEffect(() => {
        if (company?.address?.country_id) {
            const country = countryList?.find((element) => element.value === company?.address?.country_id);
            setCountry(country);
        }
    }, [company, countryList]);

    return (
        <CompanyCardVoucherContainer page={page} >

            <div className="image" style={{ backgroundImage: `url(${company?.image_profile ?? avatar})` }} >
            </div>

            <h3 className="name" onClick={() => {
                navigate(`/${company?.username}`)
            }}>{company?.name}</h3>

            <div className="companyData">
                <img src={country?.image} alt="" className="companyCountry" />
                <div className="categories">
                    {company?.categories?.map((categoryId, index) => {
                        const category = categorySelectList.find((element) => element.value === categoryId);
                        if (index < 2) {
                            return (
                                <span className="category">#{category?.label}</span>
                            )
                        }

                    })}
                </div>
            </div>
        </CompanyCardVoucherContainer>
    )
}