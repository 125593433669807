import React, { useState, useContext, useEffect, useRef } from "react";
import { ModalEditCollaborationContainer, ProfileImage } from "./ModalEditCollaborationStyled";

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber, getBase64, toUpperCase } from "../../services/generalSevices";
import Select from 'react-select';

import close from './../../utils/images/close_circle_outline.svg';
import back from './../../utils/images/arrow_back.svg';
import logo from './../../utils/images/name_logo_dark.svg';
import less from './../../utils/images/less.png';
import plus from './../../utils/images/plus.png';
import avatar from './../../utils/images/avatar_fluincr.png';
import tiktok from './../../utils/images/tiktok_opacity.svg';
import facebook from './../../utils/images/facebook_color.svg';
import instagram from './../../utils/images/instagram_color.svg';
import youtube from './../../utils/images/youtube_color.svg';
import twitter from './../../utils/images/twitter_color.svg';
import check from './../../utils/images/check_circle_darker.svg';
import star from './../../utils/images/star.svg';
import share from './../../utils/images/share_new.svg';
import gift from './../../utils/images/gift.svg';
import upload from './../../utils/images/upload.svg';
import shoppingCart from './../../utils/images/shopping_cart_white.svg';
import deleteIcon from './../../utils/images/delete_grey.svg';
import fileIcon from './../../utils/images/file_primary.png';

import Spinner from "../Spinner/Spinner";
import SocialMedia from "../SocialMedia/SocialMedia";
import { CurrencyContext } from "../../context/currencyContext";
import LikeInfluencer from "../LikeInfluencer/LikeInfluencer";
import SelectProduct from "../SelectProduct/SelectProduct";
import TooltipLight from "../TooltipLight/TooltipLight";
import ChargingSpinner from "../ChargingSpinner/ChargingSpinner";
import { UserAPI } from "../../api/user/user";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { StaticDataContext } from "../../context/staticDataContext";
import { uploadImageS3 } from "../../utils/common/s3";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import { CartAPI } from "../../api/collaboration/cart";
import { CartContext } from "../../context/cartContext";


export default function ModalEditCollaboration({ setShowEditCollaboration, collaboration }) {

    const shareRef = useRef(null);
    const { categorySelectList, countrySelectList, languageSelectList, fluincrServicesSelectList } = useContext(StaticDataContext);
    const { userData, userToken, userType } = useContext(UserContext2);
    const { setCart } = useContext(CartContext)
    const { currency, convertCurrency } = useContext(CurrencyContext);

    const navigate = useNavigate();
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);

    const [collaborationItems, setCollaborationItems] = useState(collaboration?.items ?? []);

    // Influncer data
    const [influencerPublicData, setInfluencerPublicData] = useState(null);
    const [influencerId, setInfluencerId] = useState(null);
    const [influencerImageProfile, setInfluencerImageProfile] = useState('');
    const [influencerUsername, setInfluencerUsername] = useState('');
    const [influencerName, setInfluencerName] = useState('');
    const [influencerFamilyName, setInfluencerFamilyName] = useState('');
    const [influencerAddress, setInfluencerAddress] = useState(null);
    const [influencerCategories, setInfluencerCategories] = useState([]);
    const [influencerLanguages, setInfluencerLanguages] = useState([]);
    const [influencerVerified, setInfluencerVerified] = useState(false);
    const [influencerRating, setInfluencerRating] = useState(null);
    const [influencerServices, setInfluencerServices] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState();
    const [sendGift, setSendGift] = useState(collaboration?.gift);

    const [showCommentsArea, setShowCommentsArea] = useState(false);
    const [comments, setComments] = useState(collaboration?.comment ?? '');
    const [base64File, setBase64File] = useState('');


    const [collaborationTotal, setCollaborationTotal] = useState(null);
    const [auxCollaborationTotal, setAuxCollaborationTotal] = useState(null);

    const [showNotServices, setShowNotServices] = useState(false);

    const [showSocialMedia, setShowSocialMedia] = useState(false);
    const url = `http://localhost:3000/`;
    const auxCurrency = currency.symbol;


    useEffect(() => {
        if (collaboration) {
            const collaborationProduct = userData?.profile_data?.products?.find((product) => product.id === collaboration.product_id) || true;

            setSelectedProduct(collaborationProduct);

            setIsLoading(true);

            UserAPI.getPublicUserData(collaboration?.influencer?.username).then((response) => {
                setInfluencerPublicData(response);
                setInfluencerId(response?.id);
                setInfluencerImageProfile(response.image_profile);
                setInfluencerUsername(response.username);
                setInfluencerName(response.name);
                setInfluencerFamilyName(response.family_name);
                setInfluencerAddress(response.address);
                setInfluencerCategories(response.categories);
                setInfluencerLanguages(response.languages);
                setInfluencerVerified(response.verified);
                setInfluencerRating(response.rating);
                setInfluencerServices(response.rate_by_service_list);
                setIsLoading(false);
            })
        }
    }, [])


    const addItem = (influencerService) => {
        setCollaborationItems([
            ...collaborationItems, influencerService
        ]);
        //setInfluencerServices(auxServices)
    }

    const removeItem = (influencerService) => {
        const indexToRemove = collaborationItems.findIndex(item => item.service_id === influencerService.service_id);
        if (indexToRemove !== -1) {
            const newItems = [...collaborationItems];
            newItems.splice(indexToRemove, 1);
            setCollaborationItems(newItems);
        }
    }

    useEffect(() => {
        if (collaborationTotal !== null) {
            setAuxCollaborationTotal(convertCurrency(collaborationTotal, auxCurrency).toFixed(2));
        }
    }, [collaborationTotal])

    useEffect(() => {
        let total = 0;
        
        collaborationItems.map((item) => {
            total = total + item?.price;
        })

        if (sendGift) {
            if (selectedProduct) {
                if (selectedProduct?.price < total) {
                    total = total - selectedProduct?.price;
                } else {
                    total = 0;
                }
            }
        }

        setCollaborationTotal(total);
    }, [collaborationItems, sendGift, selectedProduct])

    const editCollaboration = () => {

        const editCollaborationBody = {
            product_id: selectedProduct?.id,
            items: collaborationItems,
            comment: comments,
            gift: sendGift || false,
            gift_value: selectedProduct?.price ?? 0,
        }

        setIsLoading(true);

        if (base64File !== '') {

            const fileBody = {
                base64File: base64File.base64,
                formatFile: base64File.type,
                address: `company/${userData?.account}/collaboration`
            }

            uploadImageS3(fileBody.address, fileBody.formatFile, fileBody.base64File).then((fileLink) => {
                if(fileLink){
                    editCollaborationBody.document = fileLink;
                    CollaborationAPI.updateCollaboration(collaboration?.id, editCollaborationBody).then((response) => {
                        CartAPI.getCart(userData?.id).then((response) => {
                            setIsLoading(false);
                            setShowEditCollaboration(false);
                            setCart(response);
                        });
                    })
                }
            })
        } else {
            CollaborationAPI.updateCollaboration(collaboration?.id, editCollaborationBody).then((response) => {
                CartAPI.getCart(userData?.id).then((response) => {
                    setCart(response);
                    setIsLoading(false)
                    setShowEditCollaboration(false);
                });
            })
        }


    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setShowSocialMedia(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [shareRef])

    const [showSmallSpinner, setShowSmallSpinner] = useState(false);
    const showSpinner = () => {
        setShowSmallSpinner(true);
        setTimeout(() => {
            setShowSmallSpinner(false);
        }, 3000);
    }

    return (
        <ModalEditCollaborationContainer>

            <img src={close} alt="close" className="close" onClick={() => { setShowEditCollaboration(false) }} />

            <div className="collaborationBody">

                <div className="goBack" onClick={() => { setShowEditCollaboration(false) }}>
                    <img src={back} alt="back" className="backIcon" />
                    <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
                </div>
                <h3 className="mobileTitle"><FormattedMessage id="create_collaboration_title" defaultMessage="Crea tu colaboración " /> <img src={logo} alt="logo" className="logo" /></h3>

                <div className="influencerDataEdit">

                    <div className="profile">
                        <div className="likeProfile">
                            {(!userToken) && (<LikeInfluencer profileId={influencerId}/>)}
                        </div>

                        <ProfileImage image={influencerImageProfile ?? avatar} />

                        <div className="networks">
                            {/*(influencerTiktok !== undefined) && (<div className="network">
                                <img src={tiktok} alt="tiktok" className="networkIcon" onMouseOver={() => { setShowTiktok(true) }} onMouseOut={() => { setShowTiktok(false) }} />
                                {(showTiktok) && (<p>{(influencerTiktok < 999) ? influencerTiktok : (influencerTiktok < 999999) ? `${(influencerTiktok / 1000).toFixed(1)} k` : `${(influencerTiktok / 1000000).toFixed(1)} m`}</p>)}
                            </div>)}

                            {(influencerInstagram !== undefined) && (<div className="network">
                                <img src={instagram} alt="instagram" className="network" onMouseOver={() => { setShowInstagram(true) }} onMouseOut={() => { setShowInstagram(false) }} />
                                {(showInstagram) && (<p>{(influencerInstagram < 999) ? influencerInstagram : (influencerInstagram < 999999) ? `${(influencerInstagram / 1000).toFixed(1)} k` : `${(influencerInstagram / 1000000).toFixed(1)} m`}</p>)}

                            </div>)}

                            {(influencerFacebook !== undefined) && (<div className="network">
                                <img src={facebook} alt="facebook" className="network" onMouseOver={() => { setShowFacebook(true) }} onMouseOut={() => { setShowFacebook(false) }} />
                                {(showFacebook) && (<p>{(influencerFacebook < 999) ? influencerFacebook : (influencerFacebook < 999999) ? `${(influencerFacebook / 1000).toFixed(1)} k` : `${(influencerFacebook / 1000000).toFixed(1)} m`}</p>)}
                            </div>)}

                            {(influencerTwitter !== undefined) && (<div className="network">
                                <img src={twitter} alt="twitter" className="network" onMouseOver={() => { setShowTwitter(true) }} onMouseOut={() => { setShowTwitter(false) }} />
                                {(showTwitter) && (<p>{(influencerTwitter < 999) ? influencerTwitter : (influencerTwitter < 999999) ? `${(influencerTwitter / 1000).toFixed(1)} k` : `${(influencerTwitter / 1000000).toFixed(1)} m`}</p>)}
                            </div>)}

                            {(influencerYoutube !== undefined) && (<div className="network">
                                <img src={youtube} alt="youtube" className="network" onMouseOver={() => { setShowYoutube(true) }} onMouseOut={() => { setShowYoutube(false) }} />
                                {(showYoutube) && (<p>{(influencerYoutube < 999) ? influencerYoutube : (influencerYoutube < 999999) ? `${(influencerYoutube / 1000).toFixed(1)} k` : `${(influencerYoutube / 1000000).toFixed(1)} m`}</p>)}
                            </div>)*/}

                        </div>
                        <button className="seeProfile" onClick={() => { navigate(`/${influencerUsername}`) }}>
                            <FormattedMessage id="create_collaboration_text_8" defaultMessage="Ver perfil" />
                        </button>
                    </div>
                    <div className="dataProfile">
                        <div className="fileName">
                            <p className="name">{ `${influencerName} ${influencerFamilyName}` }</p>

                            {(influencerVerified) && (<img src={check} alt="check" className="check" />)}

                            <div className="subItem"><p className="score">{(influencerRating) ?? 'N/R'}</p><img src={star} alt="star" className="star" /></div>
                        </div>
                        <div className="fileCountry">
                            <p className="city">
                                {(influencerAddress) && (<p>
                                    {countrySelectList.find((country) => country.value === influencerAddress.country_id)?.label},
                                    {` ${influencerAddress.city}`}
                                </p>)
                                }
                            </p>
                            <p className="languages"> {
                                influencerLanguages?.map((languageId, index) => {
                                    const languageConvention = languageSelectList.find((language) => language.value === languageId)?.convention;
                                    if (index < 1) {
                                        return (languageConvention)
                                    } else {
                                        return (`/ ${languageConvention}`)
                                    }
                                })}
                            </p>

                            <div className="share" ref={shareRef}>
                                <img src={share} alt="edit" className="shareIcon" onClick={() => { setShowSocialMedia(!showSocialMedia) }} />
                                {(showSocialMedia) && (<SocialMedia shareUrl={url} />)}
                            </div>
                        </div>
                        <p className="fileCategories">
                            {
                                influencerCategories?.map((categoryId, index) => {
                                    if (index < 4) {
                                        const categoryName = categorySelectList.find((cat) => cat.value === categoryId)?.label;
                                        return `#${categoryName} `;
                                    }
                                })
                            }
                        </p>
                    </div>
                </div>

                <div className="collaborationData">
                    <div className="collaborationQuantity">
                        <div className="quantity">
                            <h3 className="collaborationTitle"><FormattedMessage id="create_collaboration_title" defaultMessage="Crea tu colaboración" /> <img src={logo} alt="logo" className="logo" /></h3>

                            <SelectProduct selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />

                            {(!influencerServices) && (<div className="notServices"><p><FormattedMessage id="create_collaboration_text_1" defaultMessage="El influencer no tiene ningún servicio" /></p></div>)}

                            {influencerServices?.filter(service => service?.price && service?.price > 0)
                                .map((serviceInfluencer, index) => {
                                    const serviceName = fluincrServicesSelectList.find((service) => service.id === serviceInfluencer.service_id)?.label;
                                    const quantity = collaborationItems.filter((item) => item.service_id === serviceInfluencer.service_id).length;
                                    return (
                                        <div className="file" key={serviceInfluencer?.service_id}>
                                            <p className="type">{serviceName}</p>
                                            <div className="right">
                                                <div className="buttons">
                                                    <button onClick={() => { removeItem(serviceInfluencer) }}><img src={less} alt="less" className="addIcon" /></button>
                                                    <span>{quantity}</span>
                                                    <button onClick={() => { addItem(serviceInfluencer) }}>
                                                        <img src={plus} alt="add" className="addIcon" />
                                                    </button>
                                                </div>
                                                <p className="price">{currency?.symbol} {formatNumber(convertCurrency(serviceInfluencer?.price, currency?.symbol))}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                        <div className="aditionalInfo">
                            <hr />
                            <div className="giftContainer">
                                <div className="giftInfo">
                                    <input type="checkbox" name="" id="" className="giftCheckbox" checked={sendGift} onChange={(event) => { setSendGift(event.target.checked) }} />
                                    <img src={gift} alt="gift" className="giftIcon" />
                                    <span className="giftText"><FormattedMessage id="create_collaboration_text_2" defaultMessage="Envio completo del producto al influencer" /></span>
                                </div>
                                {(sendGift) && (<p className="giftPrice">{(selectedProduct) && `(${currency.symbol} -${formatNumber(selectedProduct?.price)})`}</p>)}
                            </div>

                            <div className="commentContainer">
                                <div className="addComments">
                                    <button onClick={() => { setShowCommentsArea(!showCommentsArea) }}>
                                        <img src={plus} alt="add" className="plusIcon" />
                                    </button>
                                    <p className="commentText"><FormattedMessage id="create_collaboration_text_3" defaultMessage="Comentarios/ Agregar archivo" /></p>
                                </div>
                                {(showCommentsArea) && (
                                    <div className="comments">
                                        <textarea name="" id="" cols="30" rows="10" value={comments} onChange={(event) => { setComments(event.target.value) }}></textarea>

                                        {(base64File === '') && <label htmlFor="edit-collaboration-file-input" className="fileLabel">
                                            <img src={upload} alt="upload" className="uploadIcon" />
                                        </label>}
                                        
                                        {(showSmallSpinner) && (<div className="chargingContainer">
                                            <ChargingSpinner />
                                        </div>)}

                                        {(base64File !== '' && showSmallSpinner === false) && (
                                            <div>
                                                <div style={{display: 'flex', gap: '.25em'}}>
                                                    <TooltipLight title={base64File.name} placement="top">
                                                        <img src={fileIcon} alt="file" className="uploadIcon" />
                                                    </TooltipLight>
                                                    <img src={deleteIcon} alt="upload" className="uploadIcon" onClick={() => { setBase64File('') }} />
                                                </div>
                                                <p className="fileName">{base64File.name}</p>
                                            </div>
                                        )}

                                        <input type="file" id="collaboration-file-input" accept=".pdf, .txt, application/pdf, .doc, .docx, application/msword, .xls,.xlsx, application/vnd.ms-excel, .ppt, .pptx, application/vnd.ms-powerpoint, text/plain,.png, .jpg, .jpeg, image/png, image/jpg" onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file !== undefined) {
                                                    setBase64File({ base64: file, name: file.name, type: file.type || file.name.split('.').reverse()[0] });
                                                }
                                                showSpinner();
                                            }} 
                                        />
                                    </div>
                                )}
                            </div>

                            <button className={`addShoppingCart ${!(selectedProduct && collaborationItems.length > 0) && 'disabledButton'}`} onClick={editCollaboration} disabled={!(selectedProduct && collaborationItems.length > 0)}>
                                <span><FormattedMessage id="create_collaboration_text_7" defaultMessage="Añadir al carrito" />
                                    <img src={shoppingCart} alt="shopping cart" className="cart" /></span>
                                <span> {currency.symbol} {(collaborationTotal) && formatNumber(auxCollaborationTotal)}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {(isLoading) && (<Spinner />)}
        </ModalEditCollaborationContainer>
    )
}