import { grey } from "@mui/material/colors";
import styled from "styled-components";
import { media } from "../../utils/styles/media";
import { colors } from './../../utils/styles/colors';

export const ModalDisputeContainer = styled.div`
    position: fixed;
    //overflow-y: auto;
    top:0;
    left: 0; 
    height: 100vh;
    width: 100vw;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    align-items: center;
    z-index: 100;
    display: flex;
    align-self: center;
    justify-content: center;
    .disputeContainer{
        background-color: #ffffff;
        border-radius: 20px;
        padding: 40px;
        width: 80%;
        max-width: 1200px;
        position: relative;
        max-height: 90vh;
        overflow-y: auto;
        
        /* Cambia el color del thumb */
        ::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.5);
        } 

        /* Cambia el color del thumb solo en la parte superior e inferior */
        ::-webkit-scrollbar-thumb:vertical:after,
        ::-webkit-scrollbar-thumb:vertical:before {
        background-color: transparent;
        } 

        .close{
            width: 30px;
            position: absolute;
            right: 25px;
            cursor: pointer;
        }
        .goBack{
            display: none;
        }
        .title{
            font-size: 1.5rem;
            color: ${colors.greyInput};
        }
        .orderDetails{
            display: flex;
            .order, .date{
                color: ${colors.grey};
                margin-right: 30px;
            }
        }
        .disputeBody{
            display: flex;
            justify-content: space-between;
            section{
                width: 50%;
                .influencerDate{
                    display: flex;
                    align-items: center;
                    .influencerName{
                        color: ${colors.greyInput};
                        font-size: 1.3rem;
                        font-family: 'Centrale', 'sans-serif';
                        margin-right: 10px;
                        width: 70%;
                        .name{
                            text-transform: capitalize;
                        }
                    }
                    .check{
                        width: 20px;
                        margin-left: 8px;
                    }
                    .rating{
                        display: flex;
                        margin-left: 8px;
                        align-items: center;
                        .score{
                            font-size: 0.8rem;
                        }
                        .star{
                            width: 15px;
                            margin-left: 3px;
                        }
                    }
                }
                .productInformation{
                    display: flex;
                    color: ${colors.greyInput};
                    height: 200px;
                    margin-top: 25px;
                    .productImage{
                        width: 40%;
                        background-repeat: no-repeat;
                        background-position: 50% 50%; 
                        background-size: cover;
                    }
                    .description{
                        width: 58%;
                        text-align: justify;
                        margin-left: 2%;
                        .productName{
                            font-size: 1.4rem;
                            font-family: 'Centrale', 'sans-serif';
                            text-transform: capitalize;
                        }
                        .productPrice{
                            font-size: 1rem;
                            font-family: 'Centrale', 'sans-serif';
                            margin: 5px 0 10px;
                        }
                        .productDescription{
                            font-size: 0.9rem;
                            display: block;
                            display: -webkit-box;
                            max-width: 100%;
                            height: 130px;
                            margin: 0 auto;
                            line-height: 1;
                            -webkit-line-clamp: 9;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .commentsTitle{
                    color: ${colors.greyInput};
                    font-size: 1rem;
                    font-family: 'Centrale', 'sans-serif';
                    margin: 10px 0;
                }
                .commentsContainer{
                    display: flex;
                    align-items: center;
                    min-height: 3em;
                    border: 1px #bcbcbc solid;
                    padding: 0.625em;
                    border-radius: 0.5em;
                    margin-top: 0.75em;
                
                    .comments{
                        color: ${colors.greyInput};
                        font-size: 0.9rem;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .file{
                    color: ${colors.hightlight};
                    font-weight: 800;
                    cursor: pointer;
                    margin: 15px 0 10px;
                }
            }
            section:nth-child(2){
                margin-left: 10%;
                color: ${colors.greyInput};
                display: flex;
                flex-direction: column;
                .influencerData{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    h3{
                        margin-right: 20px;
                        color: ${colors.greyInput};
                        font-size: 1.3rem;
                        .name{
                            text-transform: capitalize;
                        }
                    }
                }
                p{
                    font-size: 0.8rem;
                    font-weight: 700;
                }
                .text2{
                    margin: 20px 0;
                }
                textarea{
                    width: 100%;
                    margin: 10px 0;
                    border: 1px solid ${colors.grey};
                    padding: 10px;
                    outline: none;
                    border-radius: 5px;
                    height: 150px;
                }
                .uploadFile{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    img{
                        width: 22px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    p{
                        font-weight: 700;
                        font-size: 1.1rem;
                    }
                    .chargingContainer{
                        width: 25px;
                        height: 25px;
                    }
                    input[type="file"]{
                        display: none;
                    }
                    .deleteIcon{
                        width: 15px;
                    }
                    .fileName{
                        font-size: 0.9rem;
                    }
                }
                .sendDisputeButton{
                    align-self: flex-end;
                    justify-self: end;
                    background-color: ${colors.hightlight};
                    color: #ffffff;
                    padding: 6px 30px;
                    font-size: 1.1rem;
                    font-weight: 700;
                    border: none;
                    border-radius: 10px;
                    margin-top: 20px;
                    cursor: pointer;
                }
            }
        }
    }
    ${media.laptop}{
        .disputeContainer{
            border-radius: 0px;
            width: 100vw;
            height: 100vh;
            max-height: 100vh;
            overflow-y: auto;
            .close{
                display: none;
            }
            .goBack{
                display: flex;
                align-items: center;
                width: 120px;
                cursor: pointer;
                margin-bottom: 10px ;
                .backIcon{
                    margin-right: 10px;
                    width: 18px;
                }
            }
            .disputeBody{
                flex-direction: column;
                section{
                    width: 100%;
                }
                section:nth-child(2){
                    margin-left: 0;
                    margin-top: 20px;
                    .influencerData{
                        h3{
                            font-size: 1.5rem;
                            font-weight: 800;
                        }
                    }
                    .sendDisputeButton{
                        align-self: center;
                        justify-self: center;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    ${media.largeMobile}{
        .disputeContainer{
            padding: 20px;
            .disputeBody{
                section{
                    .influencerDate{
                        margin-top: 10px;
                        .influencerName{
                            width: 60%;
                        }
                    }
                    .productInformation{
                        .description{
                            .productDescription{
                                display: block;
                                display: -webkit-box;
                                max-width: 100%;
                                height: 100px;
                                margin: 0 auto;
                                line-height: 1;
                                -webkit-line-clamp: 7;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    
                }
            }
        }
        
    }
`

export const ProfileImage = styled.div`

    width: 40px; 
    height: 40px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #ffffff;
`