const initialState = {
  token: null
}

export function tokenReducer(state = initialState, action) {
  if (action.type === "SAVE_TOKEN_STORE") {
    return {
      ...state,
      token: action.payload
    }
  }
  if (action.type === "DELETE_TOKEN_STORE") {
    return {
      ...state,
      token: initialState
    }
  }
  return state;
}

