import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const MyAccountContainer = styled.div`
  padding: 50px 100px;
  .goBack {
    display: flex;
    width: 150px;
    cursor: pointer;
    margin-bottom: 10px;
    .backIcon {
      margin-right: 10px;
      width: 28px;
    }
    .backText {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  p.nameTitle {
    font-size: 17.6px;
    color: rgb(112, 112, 112);
    font-weight: bold;
    margin-top: -3.5px;
  }

  .inputContainer2 {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${colors.grey};
    gap: 4px;
  }

  .inputContainer2Company {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: ${colors.grey};
    gap: 4px;
  }
  .capitalize {
    text-transform: capitalize;
  }

  .inputContainer {
    display: flex;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0;
    .icon {
      padding-right: 10px;
      width: 35px;
    }
    .check {
      //padding-right: 10px;
      height: 19px;
      width: 19px;
    }
  }

  .birthdayTitle {
    //padding-top: 5px;
    margin-bottom: 13px;
    font-weight: bold;
    font-size: 11px;
    margin-top: 1px;
  }

  .editNewFields {
    width: 16px;
    margin-left: 9px;
  }

  .editNewFields2 {
    width: 16px;
    display: flex;
    margin-left: 10px;
  }

  .myAccountInformation {
    padding-left: 30px;
    color: #3d3d3d;
    .title {
      font-size: 3.1rem;
      color: ${colors.greyInput};
    }
    .profile {
      display: flex;
      margin: 10px 0;
      .profileMainData {
        padding-left: 10px;
        .name {
          font-weight: bold;
          font-size: 2.5rem;
          .edit {
            margin-left: 10px;
            width: 19px;
          }
          input[type="text"] {
            border: none;
            color: ${colors.darkGrey};
            font-size: 1rem;
            outline: none;
          }
          /* .share{
                        position: relative;
                        margin-right: 20px;
                            .shareIcon{
                                //width: 20px;
                                width: 18px;
                                cursor: pointer;
                            }
                    } */
        }
        .edit {
          width: 20px;
        }
        .account {
          font-size: 1.3rem;
        }
      }
    }

    .inputAccept {
      accent-color: ${colors.hightlight};
      margin-top: 2px;
    }

    input.addressInpue::placeholder {
      justify-content: center;
      display: flex;
    }

    .label {
      font-size: 10px;
      color: ${colors.grey};
    }

    .labelCompany {
      font-size: 10px;
      color: ${colors.grey};
    }

    .edit {
      margin-left: 10px;
      width: 19px;
    }

    .informationContainer {
      display: flex;
      width: 70%;
      margin-bottom: 10px;
      font-size: 1.3rem;
      .information {
        display: flex;
        width: 80%;
        .share {
          margin-left: 10px;
        }
        .subtitle {
          width: 280px;
          font-weight: bold;
        }
        .error {
          color: red;
          font-size: 0.8rem;
          font-size: 1.1rem;
        }
        .notifications {
          display: flex;
          flex-direction: column;
          width: 62%;
          inline-size: 55%;
          overflow-wrap: break-word;
        }
        .password {
          display: flex;
          //flex-direction: column;
          .verification {
            margin-left: 20px;
            display: flex;
            align-items: end;
            gap: 5px;
          }
          input[type="password"],
          input[type="text"] {
            margin-bottom: 10px;
            font-size: 1.3rem;
            background-color: transparent;
          }
        }
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="number"] {
          background-color: transparent;
          border: none;
          color: ${colors.darkGrey};
          font-size: 1.3rem;
          outline: none;
        }
        .securityQuestionContainer {
          width: 40%;
        }
        .phoneContainer {
          display: flex;
        }
        .identificationContainer {
          display: flex;
          width: 50%;
          .identificationType {
            width: 70%;
          }
          .identification {
            width: 30%;
          }
        }

        .gendersContainer {
          display: flex;
          flex-direction: column;
          width: 50%;
          .genders {
            display: flex;
            //justify-content: space-between;
            .gender {
              padding: 6px 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid ${colors.grey};
              margin: 5px 0;
              display: flex;
              //width: 32%;
              justify-content: space-between;
              font-size: 0.9rem;
              font-size: 1.2rem;
              margin-right: 5px;
              input[type="radio"] {
                accent-color: ${colors.hightlight};
                margin-left: 8px;
              }
            }
          }
        }
        .genderName {
          text-transform: capitalize;
        }

        .label {
          input[type="checkbox"] {
            accent-color: ${colors.girlBackground};
          }
        }
        .span-2{
          grid-column: span 2;
        }
        .address {
          width: 100%;
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr 1fr;
        }
        .addressInfo {
          display: flex;
          flex-direction: column;
          inline-size: 55%;
          overflow-wrap: break-word;
        }
        .paymentMethod {
          text-transform: capitalize;
        }
      }
      .edit,
      .share {
        cursor: pointer;
        width: 19px;
        .shareIcon {
          width: 19px;
          cursor: pointer;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 18px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .switch .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 18px;
      }

      .switch .slider:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .slider {
        background-color: ${colors.girlBackground};
      }
      input:checked + .slider:before {
        transform: translateX(16px);
      }
    }
    .informationContainer:hover {
      .information {
        background-color: ${colors.greyOptions};
        border-radius: 5px;
        padding: 0 4px;
        .subtitle {
          text-decoration: underline;
        }
      }
      .edit {
        width: 17px;
      }
    }
  }
  .buttons {
    width: 70%;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    //padding-left: 30px;
    justify-content: space-between;
    button {
      border-radius: 20px;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 600;
    }
    .deactivate {
      background-color: #ffffff;
      color: ${colors.grey};
      border: 1px solid ${colors.grey};
      padding: 6px 40px;
    }
    .edit {
      background-color: ${colors.blue};
      color: #ffffff;
      border: none;
      padding: 6px 30px;
    }
    .save {
      background-color: ${colors.hightlight};
      color: #ffffff;
      border: none;
      padding: 6px 50px;
    }
  }

  /**-----------Responsive----------- */
  ${media.extralargeDesktop} {
    padding: 50px 80px;
    .goBack {
      width: 120px;
      .backIcon {
        margin-right: 10px;
        width: 18px;
      }
      .backText {
        font-size: 1.3rem;
      }
    }
    .myAccountInformation {
      padding-left: 30px;
      .title {
        font-size: 2.7rem;
      }
      .profile {
        margin: 10px 0;
        .profileMainData {
          padding-left: 10px;
          .name {
            font-size: 2.2rem;
            .edit {
              width: 18px;
            }
            input[type="text"] {
              padding: 8px;
              font-size: 1.2rem;
            }
          }
          .account {
            font-size: 1.2rem;
          }
        }
      }
      .informationContainer {
        font-size: 1.2rem;
        .information {
          . {
            width: 205px;
          }
          .error {
            font-size: 1rem;
          }
          .password {
            input[type="password"],
            input[type="text"] {
              font-size: 1.2rem;
            }
          }
          input[type="text"],
          input[type="email"],
          input[type="password"],
          input[type="number"] {
            font-size: 1.2rem;
          }
          .gendersContainer {
            .genders {
              .gender {
                font-size: 1.1rem;
              }
            }
          }
          .address {
            .addressData {
              .addressInput {
                ::placeholder {
                  font-size: 1.2rem;
                }
              }
            }
            .addressCountry {
              .city {
                ::placeholder {
                  font-size: 1.2rem;
                }
              }
            }
            .zipCode,
            .state {
              ::placeholder {
                font-size: 1.2rem;
              }
            }
          }
        }
        .edit,
        .share {
          width: 18px;
          .shareIcon {
            width: 16px;
          }
        }
      }
    }
    .buttons {
      button {
        font-size: 1.2rem;
      }
    }
  }

  ${media.largeDesktop} {
    padding: 50px 80px;
    .goBack {
      width: 120px;
      .backIcon {
        margin-right: 10px;
        width: 18px;
      }
      .backText {
        font-size: 1.2rem;
      }
    }
    .myAccountInformation {
      padding-left: 30px;
      .title {
        font-size: 2.4rem;
      }
      .profile {
        margin: 10px 0;
        .profileMainData {
          padding-left: 10px;
          .name {
            font-size: 2rem;
            .edit {
              width: 17px;
            }
            input[type="text"] {
              padding: 8px;
              font-size: 1.1rem;
            }
          }
          .account {
            font-size: 1.1rem;
          }
        }
      }
      .informationContainer {
        font-size: 1.1rem;
        .information {
          .subtitle {
            width: 280px;
          }
          .error {
            font-size: 0.9rem;
          }
          .password {
            input[type="password"],
            input[type="text"] {
              font-size: 1.1rem;
            }
          }
          input[type="text"],
          input[type="email"],
          input[type="password"],
          input[type="number"] {
            font-size: 1.1rem;
          }
          .gendersContainer {
            .genders {
              .gender {
                font-size: 1rem;
              }
            }
          }
          .address {
            .addressData {
              .addressInput {
                ::placeholder {
                  font-size: 1.1rem;
                }
              }
            }
            .addressCountry {
              .city {
                ::placeholder {
                  font-size: 1.1rem;
                }
              }
            }
            .zipCode,
            .state {
              ::placeholder {
                font-size: 1.1rem;
              }
            }
          }
        }
        .edit,
        .share {
          width: 17px;
          .shareIcon {
            width: 15px;
          }
        }
      }
    }
    .buttons {
      button {
        font-size: 1.1rem;
      }
    }
  }

  ${media.desktop} {
    padding: 50px;
    .goBack {
      width: 120px;
      .backIcon {
        margin-right: 10px;
        width: 18px;
      }
      .backText {
        font-size: 1.1rem;
      }
    }
    .myAccountInformation {
      padding-left: 30px;
      .title {
        font-size: 2.1rem;
      }
      .profile {
        margin: 10px 0;
        .profileMainData {
          padding-left: 10px;
          .name {
            font-size: 1.7rem;
            .edit {
              width: 16px;
            }
            input[type="text"] {
              padding: 8px;
              font-size: 1rem;
            }
          }
          .account {
            font-size: 1rem;
          }
        }
      }
      .informationContainer {
        font-size: 1rem;
        .information {
          .share {
            margin-left: 10px;
          }
          .subtitle {
            width: 280px;
          }
          .error {
            font-size: 0.8rem;
          }
          .password {
            input[type="password"],
            input[type="text"] {
              font-size: 1rem;
            }
          }
          input[type="text"],
          input[type="email"],
          input[type="password"],
          input[type="number"] {
            font-size: 1rem;
          }
          .gendersContainer {
            .genders {
              .gender {
                font-size: 0.9rem;
              }
            }
          }
          .address {
            .addressData {
              .addressInput {
                ::placeholder {
                  font-size: 1rem;
                }
              }
            }
            .addressCountry {
              .city {
                ::placeholder {
                  font-size: 1rem;
                }
              }
            }
            .zipCode,
            .state {
              ::placeholder {
                font-size: 1rem;
              }
            }
          }
        }
        .edit,
        .share {
          width: 16px;
          .shareIcon {
            width: 14px;
          }
        }
      }
    }
    .buttons {
      button {
        font-size: 1rem;
      }
    }
  }

  ${media.mediumDesktop} {
    .myAccountInformation {
      .informationContainer {
        .information {
          display: flex;
          width: 90%;
        }
      }
    }
  }
  ${media.smallDesktop} {
    .myAccountInformation {
      .informationContainer {
        width: 100%;
        .information {
          width: 75%;
          /* .identificationType{
                        width: 40%;
                    }
                    .identification{
                        width: 20%;
                    } */
        }
      }
    }
  }
  ${media.laptop} {
    .myAccountInformation {
      padding-left: 0;
      .informationContainer {
        //width: 100%;
        .information {
          display: flex;
          width: 80%;
          /* .subtitle{
                        width: 38%;
                       
                    } */
          .password {
            flex-direction: column;
            .verification {
              margin-left: 0px;
            }
          }
          .notifications {
            width: 62%;
            display: flex;
            flex-direction: column;
            inline-size: 55%;
            overflow-wrap: break-word;
          }
        }
      }
    }
    .buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
        .desactivateButtons {
          flex-direction: column;
          align-items: center;
          gap: 0 !important;
        }
        button {
          margin-bottom: 1.25em;
        }
    }
  }

  ${media.smallTablet} {
    .myAccountInformation {
      padding-left: 0;
      .informationContainer {
        //width: 100%;
        .information {
          display: flex;
          width: 90%;
        }
      }
    }
  }

  ${media.smallMobile} {
    .span-2{
      grid-column: span 1 !important;
    }
    .address {
      grid-template-columns: 1fr !important;
    }
  }

  ${media.largeMobile} {
    padding: 20px;
    .myAccountInformation {
      .profile {
        .profileMainData {
          width: calc(100% - 90px);
          .name {
            font-size: 1.5rem;
            input[type="text"] {
              width: 40%;
            }
          }
        }
      }
      .informationContainer {
        width: 100%;
        align-items: flex-start;
        .information {
          width: 100%;
          flex-direction: column;
          .notifications {
            width: 100%;
            display: flex;
            flex-direction: column;
            inline-size: 55%;
            overflow-wrap: break-word;
          }
          .identificationType {
            width: 50%;
          }
          .identification {
            width: 50%;
          }
          .gendersContainer {
            width: 90%;
          }
        }
      }
    }
    .buttons {
      button {
        padding: 10px 50px;
      }
    }
  }
`;

export const CustomInput = styled.input`
  border-bottom-style: solid;
  border-width: 2px;
  border-color: ${colors.grey};
`;

export const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-position: 50%;

  /**----------Responsive------------ */
  ${media.extralargeDesktop} {
    width: 90px;
    height: 90px;
  }

  ${media.largeDesktop} {
    width: 85px;
    height: 85px;
  }

  ${media.desktop} {
    width: 80px;
    height: 80px;
  }

  ${media.largeMobile} {
    width: 75px;
    height: 75px;
  }
`;
