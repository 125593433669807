export const formatFollowers = (followers) => {
    if (followers >= 1000000) {
        return (followers / 1000000).toFixed(1) + 'M'
    } else if (followers >= 1000) {
        return (followers / 1000).toFixed(1) + 'K'
    } else {
        return followers
    }
}

export const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const capitalizeFirstLetters = (str) => {
    return str
        .split(' ')                     // Divide la cadena en palabras
        .map(word => 
            word.charAt(0).toUpperCase() + // Capitaliza la primera letra
            word.slice(1).toLowerCase()    // Agrega el resto de la palabra en minúscula
        )
        .join(' ');                     // Vuelve a unir las palabras en una cadena
};