import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ChatContainer = styled.div`

    //width: 30%; 
    width: 400px;
    position: fixed;
    bottom: 0;
    right: 200px;
    z-index: 100;
    border-radius: 10px 10px 0 0px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px 0.5px rgba(0, 0, 0, 0.2);
     
    
    .chatHeader{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        height: 10vh;
        -webkit-box-shadow: 0px 11px 17px -9px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 11px 17px -9px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 11px 17px -9px rgba(0, 0, 0, 0.2);
        
        .userData{
            display: flex;
            align-items: center;
            .textData{
                margin-left: 10px;
                .userName{
                    text-transform: capitalize;
                    font-weight: 600;
                }
                .subtext{
                    font-size: 0.7rem;
                }
            }
        }
        .buttons{
            display: flex;
            align-items: center;
            img{
                width: 20px;
                margin-left: 10px;
                cursor: pointer;
            }
             .maxi{
                width:13px;
            } 
        }
    }
    .messages{
        padding: 10px;
        display: flex;
        flex-direction: column;
        height: ${(props) => props.showEmojiOptions ? '30vh' : '60vh'};
        overflow-y: auto;
    }
    .chatFooter{
        //padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //height: 10vh;
       cursor: ${(props) => (props.isAdmin) && 'not-allowed'};
        -webkit-box-shadow: -4px -14px 17px -9px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: -4px -14px 17px -9px rgba(0, 0, 0, 0.2);
        box-shadow: -4px -14px 17px -9px rgba(0, 0, 0, 0.2);
        .chatMenu{
            //padding: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 10vh;
            padding: 0 10px;
            .upload__image-wrapper{
                .imageUploadButton{
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    border: none; 
                }
            }
            input[type="file"]{
                display: none;
            }
            img{
                width: 20px;
            }
            .file, .videoFile, .imageFile{
                //cursor: pointer;
                cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};
            }
            .file{
                width: 17px;
            }
            .inputContainer{
                padding: 10px;
                background-color: ${colors.greyChat};
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
               // width: 70%;
                form{
                display: flex;
                    input{
                        margin-right: 10px;
                        border: none;
                        outline: none;
                        padding: 5px;
                        background-color: ${colors.greyChat};
                        width: 75%;
                        
                    }
                    .sendButton{
                        border: none;
                        background-color: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        //cursor: pointer;
                        cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};
                    }
                }
                .emoji{
                    //cursor: pointer;
                    cursor: ${(props) => (props.isAdmin) ? 'not-allowed' : 'pointer'};
                }
            }
        
        }
        .epr-main{
            margin-bottom: 1vh;
            .epr-preview{
                display: none;
            }
        }
        
        
    }

    /**----------Responsive-----------*/
    ${media.tablet}{
        width: 450px;
        .chatFooter{
            .chatMenu{
                height: 8vh;
            }
        }
    }

    ${media.largeMobile}{
        width: 100%;
        right: 0px;
        border-radius: 0;
        .messages{  
            //height: ${(props) => props.showEmojiOptions ? '44vh' : '80vh'};
            height: ${(props) => props.showEmojiOptions ? '44vh' : '73vh'};
        }
        .chatFooter{
            .chatMenu{
                height: 10vh;
            }
        }
    }
`

export const ProfileImage = styled.div`

    width: 50px; 
    height: 50px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #ffffff;
    
`

export const Card = styled.div`
    padding: 10px;
    width: 80%;
    align-self: ${(props) => props.typeUser === props.myUser ? 'flex-end' : 'flex-start'};
    display: flex;
    justify-content: ${(props) => props.typeUser === props.myUser ? 'flex-end' : 'flex-start'};
    .message{
        align-self: start;
        padding: 10px;
        margin: 0 5px;
        background-color: ${(props) => props.typeUser === props.myUser ? colors.hightlight : colors.greyChat};
        color: ${(props) => props.typeUser === props.myUser ? '#ffffff' : colors.darkGrey};
        border-radius: ${(props) => props.typeUser === props.myUser ? '20px 20px 0px 20px' : '20px 20px 20px 0'};
        .imagesContainer{
            display: flex;
            flex-direction: column;
        }
        .imageAttached{
            width: 200px;
            border-radius: 10px;
        }
        .file{
            background-color: #ffffff;
            border-radius: 10px;
            width: 200px;
            .cover{
                background-color: rgba(26, 120, 243, 0.3);
                border-radius: 10px 10px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 10px;
            }
            .name{
                padding: 10px;
                border-radius: 0 0 10px 10px ;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                color: ${colors.darkGrey};
                background-color: rgba(26, 120, 243, 0.2);

            }
        }
        .time{
            margin-top: 10px;
            font-size: 0.6rem;
            text-align: end;
        }
    }
`