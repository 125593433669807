import { useEffect } from "react";
import { useLocation } from "react-router";


const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;

export const toUpperCase = ({ fullName }) => {

    const arrayName = fullName.split(' ')
    const auxName = [];

    arrayName.forEach((name) => {
        auxName.push(name.charAt(0).toUpperCase() + name.slice(1))
    })
    return auxName.toString().replaceAll(',', ' ');
}

export const capitalize = (s = '') => {
    return s.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const getBase64 = ({ file }) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


export const fill = (number, len) => {
    return "0".repeat(len - number.toString().length) + number.toString();
}


export const lowerFare2 = (personalFares) => {

    const auxFares = [...personalFares];

    let values = [];
    auxFares.forEach((fare) => {
        if (fare.rate) {
            values.push(fare.rate)
        }
    })


    //const values = [auxType?.photo, auxType?.video30, auxType?.video60, auxType?.videoPlus]
    const orderValues = values.sort(function (a, b) {
        return b - a;
    });

    let auxPrice = 0
    orderValues.forEach((price) => {

        if (price !== 0) {
            auxPrice = price
        }
    })
    return auxPrice;
}

export const lowerFare3 = (personalFares) => {

    const auxFares = [...personalFares];
    const orderValues = auxFares.sort(function (a, b) {
        return b - a;
    });

    let auxPrice = 0
    orderValues.forEach((price) => {
        if (price !== 0) {
            auxPrice = price
        }
    })
    return auxPrice;
}


export const formatNumber = (number = 0, minDigits = 2, maxDigits = 2) => {
    if(number > 9999){
        return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
    }
    return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits }).format(number);
}
