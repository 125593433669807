import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalScoreCollaborationContainer = styled.div`
    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .scoreBody{
        padding: 20px 60px;
        background-color: #ffffff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${colors.darkGrey};
        span{
                text-transform: capitalize;
            }
        h2{
            margin: 10px 0;
            text-align: center;
        }
        .textScore{
            text-align: center;
        }
        
        .score{
            margin-top: 20px;
            .star{
                width: 40px;
                margin: 0 5px;
            }
            svg{
                width: 40px;
                margin: 0 5px;
                cursor: pointer;
            }
        }

        .questionText{
            font-size: 1em;
            margin-bottom: 1em;
            margin-top: 1em;
            max-width: 30em;
            text-align: center;
        }

        .options {
            

            button{
            
                font-size: 1em;
                text-transform: none;
                font-weight: 700;
                color: ${colors.darkGrey};
            }

            button:hover{
                color: ${colors.hightlight};
            
            }
        }
    }

    /**-----------Responsive------------ */
    ${media.tablet}{
        .scoreBody{
            max-width: 80vw;
        }
    }

    ${media.largeMobile}{
        .scoreBody{
            padding: 10px 50px;
        }
    }
`

export const Profile = styled.div`
    width: 120px; 
    height: 120px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    /**-------Responsive--------- */
    ${media.bigmacdesktop}{
        width: 100px; 
        height: 100px;
    }
`