import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const InvoiceContainer = styled.div`
    padding: 0px 100px 30px;
    .goBack{
        display: flex;
        align-items: center;
        width: 120px;
        cursor: pointer;
        font-size: 1.2rem;
        color: ${colors.text};
        font-weight: bold;
        .backIcon{
            margin-right: 10px;
            width: 22px;
        }
    }
    .invoiceBody{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: ${colors.darkGrey};
        .invoice, .invoiceToPrint{
            width: 42%;
            .invoiceTitle{
                font-size: 2rem;
            }
            .invoiceData{
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                font-size: 1.3rem;
                .information{
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    
                    .subtitle{
                        font-weight: 700;
                        font-size: 1.4rem;
                        margin-bottom: 10px;
                        height: 30px;
                        display: flex;
                        align-items: flex-end;
                    }
                    .userData{
                        display: flex;
                        .profile{
                            width: 120px;
                            margin-right: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .name{
                                font-weight: bold;
                                text-transform: capitalize;
                                text-align: center;
                                margin-top: 5px;
                            }
                        }
                        .data{
                            .accountId{
                                font-weight: 700; 
                            }
                            p{
                                font-size: .75em;
                                margin: 2px 0;
                            }
                        }
                    }
                }
                .prices{
                    //width: 40%;
                    display: flex;
                    flex-direction: column;
                    .subtitle{
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 1.4rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        height: 28px;
                        .date{
                            margin-right: 30px;
                            font-size: 1.2rem;
                        }
                        .printButton{
                            background-color: transparent;
                            border: none;
                        }
                        .print{
                            width: 25px;
                            cursor: pointer;
                        }
                    }
                    table{
                        .price{
                            text-align: end;
                        }
                    }
                }
                
            }
            
            hr{
                margin-top: 10px;
            }
            .companyData{
                display: flex;
                margin: 10px 0;
                font-size: 1.3rem;
                .profile{
                    width: 120px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .name{
                        font-weight: bold;
                        text-transform: capitalize;
                        text-align: center;
                        margin-top: 5px;
                    }
                }
                .companyDetails{
                    p{
                        font-size: .75em;
                        margin: 2px 0;
                    }
                }
            }
            .collaborationData{
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                font-size: 1.3rem;
                align-items: flex-start;
                .profile{
                    width: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 10px;
                    .name{
                        font-weight: bold;
                        text-transform: capitalize;
                        text-align: center;
                        margin-top: 5px;
                    }
                }
                table{
                    width: calc(100% - 130px);
                    .collaborationId{
                        font-weight: 700;
                    }
                    .price{
                        text-align: end;
                    }
                }
            }
        }
        .featured{
            width: 52%;
            .subtitle{
                font-size: 2.1rem;
                color: ${colors.greyInput};
            }
            .featuredSubtitle{
                margin: 30px 0 10px;
                font-weight: 700;
                font-size: 1.1rem;
            }
            .content{
                display: flex;
                flex-direction: column;
                a{
                    align-self: flex-end;
                    font-weight: bold;
                    font-size: 0.9rem;
                    color: ${colors.text};
                    text-decoration: underline;
                    font-family: 'Centrale', sans-serif;
                }
                .cards{
                    display: flex;
                    margin-left: -10px;
                    margin-right: -10px;
                    flex-wrap  :wrap ;
                }
            }
            
        }
        .invoiceToPrint{
            display: none;
        }
    }

    @media print {
        @page {
            margin: 25mm 20mm 25mm 25mm;
        }
        .invoiceToPrint{
            display:block;
        }
    }

    /**---------Responsive--------- */
    ${media.largeDesktop}{
        padding: 0px 80px 20px;
        .goBack{
            width: 120px;
            font-size: 1.1rem;
            margin: 25px 0px;
            .backIcon{
                width: 20px;
            }
        }
        .invoiceBody{
            .invoice{
                .invoiceTitle{
                    font-size: 1.8rem;
                }
                .invoiceData{
                    padding-top: 15px;
                    font-size: 1.1rem;
                    .information{
                        .subtitle{
                            font-size: 1.3rem;
                        }
                        .userData{
                            .profile{
                                width: 110px;
                            }
                        }
                    }
                    .prices{
                        .subtitle{
                            font-size: 1.3rem;
                            .print{
                                width: 23px;
                                cursor: pointer;
                            }
                            .date{
                                font-size: 1.1rem;
                            }
                        }

                    }
                }
                .companyData{
                    font-size: 1.1rem;
                    .profile{
                        width: 110px;
                    }
                }
                .collaborationData{
                    font-size: 1.1rem;
                    .profile{
                        width: 110px;
                    }
                    table{
                        width: calc(100% - 120px);
                    }
                }
            }
            .featured{
                .subtitle{
                    font-size: 1.9rem;
                }
            }
        }
    }

    ${media.macdesktop}{
        padding: 0px 50px 20px;
        .goBack{
            font-size: 1rem;
            margin: 20px 0px;
            .backIcon{
                width: 18px;
            }
        }
        .invoiceBody{
            .invoice{
                .invoiceTitle{
                    font-size: 1.6rem;
                }
                .invoiceData{
                    padding-top: 10px;
                    font-size: 0.9rem;
                    .information{
                        .subtitle{
                            font-size: 1.2rem;
                        }
                        .userData{
                            .profile{
                               width: 100px;
                            }
                        }
                    }
                    .prices{
                        .subtitle{
                            font-size: 1.2rem;
                            .print{
                                width: 20px;
                                cursor: pointer;
                            }
                            .date{
                                font-size: 1rem;
                            }
                        }
                    }
                }
                .companyData{
                    font-size: 0.9rem;
                    .profile{
                        width: 100px;
                    }
                }
                .collaborationData{
                    font-size: 0.9rem;
                    .profile{
                        width: 100px;
                    }
                    table{
                        width: calc(100% - 110px);
                    }
                
                }
            }
            .featured{
                .subtitle{
                    font-size: 1.7rem;
                }
            }
        }
    }

    ${media.desktop}{
        padding: 0px 50px 20px;
        .goBack{
            font-size: 1rem;
            margin: 20px 0px;
            .backIcon{
                width: 18px;
            }
        }
        .invoiceBody{
            .invoice{
                .invoiceTitle{
                    font-size: 1.6rem;
                }
                .invoiceData{
                    padding-top: 10px;
                    font-size: 0.9rem;
                    .information{
                        .subtitle{
                            font-size: 1.2rem;
                        }
                        .userData{
                            .profile{
                               width: 100px;
                            }
                        }
                    }
                    .prices{
                        .subtitle{
                            font-size: 1.2rem;
                            .print{
                                width: 20px;
                                cursor: pointer;
                            }
                            .date{
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
                .companyData{
                    font-size: 0.9rem;
                    .profile{
                        width: 100px;
                    }
                }
                .collaborationData{
                    font-size: 0.9rem;
                    .profile{
                        width: 100px;
                    }
                    table{
                        width: calc(100% - 110px);
                    }
                
                }
            }
            .featured{
                .subtitle{
                    font-size: 1.7rem;
                }
            }
        }
    }

    ${media.smallDesktop}{
        .invoiceBody{
            flex-direction: column;
            .invoice{
                width: 100%;
                margin-bottom: 40px;
            }
            .verticalLine{
                display: none;
            }
            .featured{
                width: 100%;
            }
        }
    }
    

    ${media.largeMobile}{
        padding: 10px 20px;
        .invoiceBody{
            .invoice{
                .invoiceTitle{
                    font-size: 1.4rem;
                }
                .invoiceData{
                    flex-direction: column;
                    .information, .prices{
                        width: 100%;
                        
                    }
                    .prices{
                        margin-top: 20px;
                    }
                }
                .collaborationData{
                    flex-direction: column;
                    table{
                        margin-top: 10px;
                        width: 100%;
                    }      
                }
            }
            .featured{
                .cards{
                    flex-wrap: wrap;
                }
            } 
        }
    }
`


export const Profile = styled.div`
    width: 100px; 
    height: 100px;
    border-radius: 50%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    /**---------Responsive--------- */
    ${media.largeDesktop}{
        width: 90px; 
        height: 90px;
    }

    ${media.desktop}{
        width: 80px; 
        height: 80px;
    }

    ${media.largeMobile}{
        width: 60px; 
        height: 60px;
    }
`