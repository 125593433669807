import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const NewPasswordContainer = styled.div`

    background-color: ${colors.modalBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    padding: 50px 0;
    min-height: calc(100vh - 575px);
    color: ${colors.title};
    .resetHeader{
        display: flex;
        color: ${colors.title};
        margin-bottom: 30px;
        h2{
            font-weight: 600;
            font-size: 2.5rem;
        }
    }
    form{
        width: 525px;
        background-color: white;
        border-radius: 20px;
        padding: 40px 30px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .firstText{
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .inputContainer{
            display: flex;
            border:1px solid ${colors.grey};
            border-radius: 10px;
            padding: 10px 15px;
            margin-bottom: 20px;
            .seeContainer{
                display: flex;
                align-items: center;
                justify-content: center;
                .iconSeePassword{
                    padding-right: 10px;
                    width: 45px;
                }
            }
            input[type="password"], input[type="text"]{
                width: 100%;
                outline: none;
                border: none;
                font-size: 1.5rem;
                font-family: 'Open Sans', sans-serif;
                ::placeholder{
                    font-weight: bold;
                    color: rgba(112,112,112,0.51);
                }
            }  
        }
        /* .password{
            width: 100%;
            outline: none;
            border: 1px solid ${colors.grey};
            font-family: 'Open Sans';
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 20px;
            font-size: 1.5rem;
            ::placeholder{
                font-weight: bold;
                color: rgba(112,112,112,0.51);
            }
        } */
        .error{
            color: red;
            font-size: 1.2rem;
        }
        .saveButton{
            padding: 10px 20px;
            background-color: ${colors.hightlight};
            color: #ffffff;
            border-radius: 10px;
            border: none;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            margin-top: 15px;
            font-size: 1.6rem;
        }
    }

    .goBack{
        display: flex;
        margin-top: 20px;
        width: 520px;
        text-align: end;
        font-size: 1.2rem;
        cursor: pointer;
        img{
            width: 8px;
            margin-right: 10px;
        }
    }

     /**-----------Responsive-------------- */
     ${media.largeDesktop}{
        .resetHeader{
            h2{
                font-size:2.3rem;
            }
        }
        form{
            width: 510px;
            .firstText{
                font-size: 1.4rem;
                
            }
            .inputContainer{
                margin-bottom: 15px;
                .seeContainer{
                    .iconSeePassword{
                        padding-right: 10px;
                        width: 43px;
                    }
                }
                input[type="password"], input[type="text"]{
                    font-size: 1.4rem;
                }  
            }
            /* .password{
                font-size: 1.4rem;
                margin-bottom: 15px;
            } */
            
            .error{
                font-size: 1rem;
            }
            .savetButton{
                font-size: 1.6rem;
            }
        }
        .goBack{
            width: 505px;
            font-size: 1.1rem;
        }
    }

    ${media.specialScreen}{
        .resetHeader{
            h2{
                font-size:2.1rem;
            }
        }
        form{
            width: 420px;
            .firstText{
                font-size: 1.2rem;
                margin-bottom: 20px;
            }
            .inputContainer{
                margin-bottom: 15px;
                .seeContainer{
                    .iconSeePassword{
                        padding-right: 10px;
                        width: 40px;
                    }
                }
                input[type="password"], input[type="text"]{
                    font-size: 1.2rem;
                }  
            }
            /* .password{
                font-size: 1.2rem;
                margin-bottom: 15px;
            } */
            .error{
                font-size: 0.9rem;
            }
            .saveButton{
                font-size: 1.3rem;
                margin-top: 10px;
            }
        }
        .goBack{
            width:415px;
            font-size: 1rem;
        }
    }

    ${media.macdesktop}{
        .resetHeader{
            h2{
                font-size:1.9rem;
            }
        }
        form{
            width: 390px;
            .firstText{
                font-size: 1.1rem;
            }
            .inputContainer{
                margin-bottom: 15px;
                .seeContainer{
                    .iconSeePassword{
                        padding-right: 10px;
                        width: 38px;
                    }
                }
                input[type="password"], input[type="text"]{
                    font-size: 1.1rem;
                }  
            }
            /* .password{
                font-size: 1.1rem;
            } */
            .error{
                font-size: 0.8rem;
            }
            .saveButton{
                font-size: 1.2rem;
            }
        }
        .goBack{
            width: 385px;
            font-size: 0.9rem;
        }
    }

    ${media.desktop}{
        .resetHeader{
            h2{
                font-size:1.8rem;
            }
        }
        form{
            width: 385px;
            .firstText{
                font-size: 1.1rem;
            }
            .inputContainer{
                margin-bottom: 15px;
                .seeContainer{
                    .iconSeePassword{
                        padding-right: 10px;
                        width: 35px;
                    }
                }
                input[type="password"], input[type="text"]{
                    font-size: 1rem;
                }  
            }
            /* .password{
                font-size: 1rem;
            } */
            .error{
                font-size: 0.8rem;
            }
            .saveButton{
                font-size: 1.1rem;
            }
        }
        .goBack{
            width: 380px;
            font-size: 0.9rem;
        }
    }

`