import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { FormattedMessage, useIntl } from 'react-intl';

import facebookButtonIcon from '../../../../utils/images/networks/facebook_button_logo.svg';
import '../../../../Bootstrap.css'
import './FacebookProfileLoginModalStyled.css';
import Alert from '../../../Alert/Alert';
import { UserContext2 } from '../../../../context/user/userContext';
import { FacebookAPI } from '../../../../api/social-network/facebook';

const FacebookProfileLoginModal = ({ open, setOpen }) => {

    const { userData, setUserData } = useContext(UserContext2);
    const navigate = useNavigate();


    const intl = useIntl();

    const [facebookProfileLinkModal, setFacebookProfileLinkModal] = useState('');
    const [facebookFollowersModal, setFacebookFollowersModal] = useState('');
    const [showAlertError, setShowAlertError] = useState(false);

    const saveFacebookData = (profileLink, facebookFollowers) => {
        if (profileLink && facebookFollowers) {
            FacebookAPI.manualInsert(userData?.id, profileLink, facebookFollowers).then((facebookSocialNetworkAccount) => {

                setUserData({
                    ...userData,
                    social_networks: [
                        ...userData?.social_networks,
                        facebookSocialNetworkAccount
                    ]
                });
                setOpen(false);
            });
        }
    }

    const responseFacebook = (response) => {
        if (response?.error) {
            setShowAlertError(true);
        } else {
            FacebookAPI.accessToken(userData?.id, response.accessToken, response.userID).then((facebookSocialNetworkAccount) => {
                setUserData({
                    ...userData,
                    social_networks: [
                        ...userData?.social_networks,
                        facebookSocialNetworkAccount
                    ]
                });
                navigate('/auth/facebook');
            }).catch(() => {
                navigate('/error')
            });
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog id="fl-dialog-container" className='mw-100' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className="dialogContentContainer pb-2 pt-1">
                    <DialogContent>
                        <div className="d-flex flex-row justify-content-between gap-4">
                            <div className="d-flex flex-column justif-content-center align-items-center w-50">
                                <h3 className="fl-text-primary">
                                    <FormattedMessage id="modalFacebookPersonalAccountTitle" defaultMessage="Cuenta de Facebook" />
                                </h3>
                                <TextField
                                    className="mt-2"
                                    label={intl.formatMessage({ id: "modalFacebookAccountLinkInputLabel" })}
                                    autoFocus
                                    margin="dense"
                                    id="fl-input"
                                    size="small"
                                    variant="standard"
                                    type="text"
                                    fullWidth
                                    onChange={(e) => setFacebookProfileLinkModal(e.target.value)}
                                />
                                <TextField
                                    className="mb-2"
                                    label={intl.formatMessage({ id: "modalFacebookAccountFollowersInputLabel" })}
                                    margin="dense"
                                    id="fl-input"
                                    size="small"
                                    variant="standard"
                                    type="number"
                                    fullWidth
                                    required
                                    onKeyPress={(e) => {
                                        // Prevenir la entrada de puntos y comas
                                        if (e.key === '.' || e.key === ',') {
                                            e.preventDefault();
                                        }
                                    }}
                                    helperText={intl.formatMessage({ id: "modalFacebookAccountFollowersInputHelperText" })}
                                    FormHelperTextProps={{ className: 'fl-helper-text' }}
                                    onChange={(e) => setFacebookFollowersModal(e.target.value)}
                                />
                            </div>

                            <div className="separator"></div>

                            <div className="d-flex flex-column justify-content-between align-items-center w-50 h-100">
                                <h3 className="fl-text-primary">
                                    <FormattedMessage id="modalFacebookPageTitle" defaultMessage="Página de Facebook" />
                                </h3>

                                <FacebookLogin
                                    appId="923213175700938"
                                    fields="name,email,picture"
                                    cssClass="facebookLoginButton w-100 border-0 fw-bold text-white cursor-pointer py-2 px-3 gap-2 rounded-3 mt-5"
                                    scope="public_profile,pages_show_list,business_management"
                                    //redirectUri="https://pruebas.tmsolutions.com.co/auth/facebook"
                                    textButton={
                                        <>
                                            <img src={facebookButtonIcon} width={20}/>
                                            <FormattedMessage id="modalFacebookPageLoginButton" defaultMessage="Continuar con Facebook" />
                                        </>
                                    }
                                    // autoLoad={false} 
                                    callback={responseFacebook}
                                    //onFailure={() => { setShowAlertError(true); }}
                                    cookiePolicy={'none'}
                                    response_type="token,userID"
                                    //cookie={false}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className='p-2 align-items-center justify-content-center'>
                        <button onClick={() => saveFacebookData(facebookProfileLinkModal, facebookFollowersModal)} className="fl-button-primary">
                            <FormattedMessage id="modalFacebookAccountButtonSave" defaultMessage="Guardar y cerrar" />
                        </button>
                    </DialogActions>
                </div>
            </Dialog>

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="alert_error_network_title" defaultMessage="Error" />}
                text={<FormattedMessage id="alert_error_facebook_text" defaultMessage="No pudimos conectar su cuenta de facebook, por favor intente mas tarde." />}
                setShowAlert={setShowAlertError}
            />)}
        </>
    )
}

export default FacebookProfileLoginModal