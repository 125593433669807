import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalCarouselContainer = styled.div`

    color: ${colors.greyLetter};
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 50; 
    
    .carouselBody{
        display: flex;
        flex-direction: column;
        width: 70%;
        background-color:#ffffff;
        overflow-y: auto;
        max-height: 95vh;
        min-height: 200px;
        border-radius: 2px;
        padding: 10px 15px;
        .carouselHeader{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 10px 0;
            .close{
                align-self: flex-end;
                width: 30px;
                cursor: pointer;
            }
        }

        .navButton{
            button{
                padding: 0;
                margin: 0;
                svg{
                    font-size :3rem ;
                    fill:${colors.greyIcon};
                }  
            }    
        }

        .imageContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
            //height: 80%;
            .imageCarousel{
                width: 100%; 
                height: 100%; 
                object-fit: contain;
                object-position: center center; 
                margin-bottom: 20px;
            }
            .videoCarousel{
                width: 100%; 
                height: 100%; 
                object-fit: contain;
                padding: 0;
            }
            
        }
        
    }


    /**-------Responseive------- */

    ${media.laptop} {
        z-index: 100; 
        .carouselBody{
            width:80% ;
        }
        
    }
    ${media.largeMobile} {
        .carouselBody{
            width:100% ;
            height: 100%;
            max-height: 100vh;
            .imageContainer{
                height: 80vh;
            }
        }
        
    }

`