import axios from 'axios';
import { API_GATEWAY_URL } from '../../../utils/constants/constants';
import axiosInstance from '../../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user';

export const InfluencerAPI = {

    createInfluencer: async (userData) => {
        const response = await axios.post(`${BASE_URL}/influencer/create`, userData);
        return response.data;
    },

    getAllInfluencers: async () => {
        const response = await axios.get(`${BASE_URL}/influencer`);
        return response.data;
    },

    getInfluencersFeatured: async () => {
        const response = await axios.get(`${BASE_URL}/influencer/featured`);
        return response.data;
    },

    // Devuelve un objeto Pageable con los influencers destacados en el atributo content
    getAllInfluencersFiltered: async (filters) => {
        try {
            const response = await axios.get(`${BASE_URL}/influencer/filters`, {
              params: filters
            });
            return response.data;
          } catch (error) {
            console.error('Error al obtener influencers:', error);
            throw error;
          }
    },

    updateUserInfluencer: async (data) => {
        const response = await axiosInstance.put(`${BASE_URL}/influencer`, data);
        return response.data;
    },

    likes: async (companyId) => {
        const response = await axiosInstance.put(`${BASE_URL}/influencer/likes/${companyId}`);
        return response.data;
    },

    updateCollaborators: async (collaborators) => {
        const response = await axiosInstance.put(`${BASE_URL}/influencer/collaborators`, collaborators);
        return response.data;
    },

    updateVideoProfile: async (videoProfile) => {
        const response = await axiosInstance.put(`${BASE_URL}/influencer/video-profile?video=${videoProfile}`);
        return response.data;
    },

    updateGallery: async (galleryImage) => {
        const response = await axiosInstance.put(`${BASE_URL}/influencer/gallery`, galleryImage);
        return response.data;
    },

    deleteGalleryImage: async (galleryImageId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/influencer/gallery/${galleryImageId}`);
        return response.data;
    }
};