import { ArrowContainer } from "./HomeStyled";

export const settingSliderCategories = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  /*  prevArrow: <SamplePrevArrow />, */
  accessibility: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
      },
    },
  ],
};

export const settingSliderNetwork = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  //prevArrow: <SamplePrevArrow />,
  accessibility: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
      },
    },
  ],
};

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <ArrowContainer onClick={onClick} style={{ left: "-50px" }}>
      <i className="fa-solid fa-angle-left"></i>
    </ArrowContainer>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <ArrowContainer onClick={onClick} style={{ right: "-50px" }}>
      <i className="fa-solid fa-angle-right"></i>
    </ArrowContainer>
  );
}
