import React, { useEffect, useState } from "react";
import avatar from "./../../utils/images/avatar_fluincr.png";
import video from "./../../utils/images/video.svg";

import ModalCarouselCollaborations from "../ModalCarouselCollaboration/ModalCarouselCollaboration";
import { CollaborationItemCardPublicProfileContainer } from "./CollaborationItemCardPublicProfileStyled";
import { StaticDataContext } from "../../context/staticDataContext";
import { useContext } from "react";
import { ContentTypes } from "../../utils/constants/item";
import { UserTypes } from "../../utils/constants/user";

export default function CollaborationItemCardPublicProfile({
  collaborationItem,
  influencer,
  company,
  profileUserType,
}) {
  const { fluincrServicesList } = useContext(StaticDataContext);

  const [contentType, setContentType] = useState(
    fluincrServicesList.find(
      (element) => element.id === collaborationItem.service_id
    )?.type
  );

  const [showContentModal, setShowContentModal] = useState(false);

  useEffect(() => {
    const contentService = fluincrServicesList.find(
      (service) => service.id === collaborationItem.service_id
    );
    setContentType(contentService?.type);
  }, []);

  return (
    <CollaborationItemCardPublicProfileContainer>
      <div
        className="cardCollaboration"
        style={{
          backgroundImage: `url(${
            collaborationItem.cover ?? collaborationItem.content
          })`,
        }}
        onClick={() => {
          setShowContentModal(true);
        }}
      >
        {contentType !== ContentTypes.IMAGE && (
          <img src={video} alt="videos" className="typeIcon" />
        )}

        {contentType === ContentTypes.IMAGE && <div className="photoIcon" />}

        {/* TODO: Validar si mostrar foto de perfil o no */}
        {profileUserType === UserTypes.COMPANY && influencer?.image_profile (
          <div
            className="colaborator"
            style={{
              backgroundImage: `url(${influencer?.image_profile ?? avatar})`,
            }}
          ></div>
        )}

        {profileUserType === UserTypes.INFLUENCER && company?.image_profile (
          <div
            className="colaborator"
            style={{
              backgroundImage: `url(${company?.image_profile ?? avatar}})`,
            }}
          ></div>
        )}
      </div>

      {showContentModal && (
        <ModalCarouselCollaborations
          content={[collaborationItem]}
          influencer={influencer}
          company={company}
          setShowModalCarousel={setShowContentModal}
          profileUserType={profileUserType}
        />
      )}
    </CollaborationItemCardPublicProfileContainer>
  );
}
