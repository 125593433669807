import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import "./FluincrDropdownStyled.css";
import "../../Bootstrap.css";

import { colors } from "../../utils/styles/colors";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const FluincrDropdown = ({ title, titleColor = colors.hightlight, children, maxHeight = "auto", fontSize = "1.25rem" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="d-flex align-items-center">
      <button
        id="fl-dropdown-button"
        className="pe-0 fw-bold h3 d-flex flex-row align-items-center justify-content-between"
        aria-describedby={id}
        onClick={handleClick}
        style={{
          color: titleColor,
          fontSize: fontSize,
          fontFamily: "Open Sans"
        }}
      >
        {title}
        {open ? (
            <ExpandLess sx={{ titleColor }} />
        ) : (
            <ExpandMore sx={{ titleColor }} />
        )}
      </button>
      
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ p: 2, pb: 0, pr: 1 }} style={{maxHeight: maxHeight, overflow: "scroll"}}>{children}</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FluincrDropdown;
