import React, { useState, useRef, useEffect, useContext } from "react";
import { SelectProductContainer } from "./SelectProductStyled";

import dropDownPink from './../../utils/images/arrow_down_pink.svg';
import dropDownPinkUp from './../../utils/images/arrow_down_pink_up.svg';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router-dom";
import Product from "./Product";
import ModalAddProduct from "../ModalAddProduct/ModalAddProduct";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";

export default function SelectProduct({ selectedProduct, setSelectedProduct, highlightSelect = false }) {

    const selectRef = useRef(null);
    const navigate = useNavigate();
    const { userToken, userType, userData } = useContext(UserContext2);

    const [showProductOptions, setShowProductOptions] = useState(false);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [productsOptions, setProductsOptions] = useState([]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setShowProductOptions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectRef])

    useEffect(() => {
        if(userData){
            setProductsOptions(userData?.profile_data?.products || [])
        }
    }, [userData, showAddProduct])

    return (
        <SelectProductContainer ref={selectRef} highlightSelect={highlightSelect}>

            {/*   <div className="selectContainer" ref={selectRef}> */}
            <div className="select" id="select" onClick={() => setShowProductOptions(!showProductOptions)}>
                <div className="selected" >

                    {
                        (selectedProduct) ? 
                            (<p>{selectedProduct.name}</p>)
                            : 
                            (<p><FormattedMessage id="create_collaboration_select" defaultMessage="Selecciona tu producto/campaña" /><span className="required"> *</span></p>) 
                    }

                </div>

                <img src={(showProductOptions) ? dropDownPinkUp : dropDownPink} alt="down" className="dropdown" onClick={() => setShowProductOptions(!showProductOptions)} />
            </div>
            {
                (!userToken) ? (
                    <p className="required">*<FormattedMessage id="create_collaboration_select_required" defaultMessage="Login requerido" /></p>
                ) : (
                    userType === UserTypes.COMPANY ? (
                        <p className="required">*<FormattedMessage id="create_collaboration_select_required_2" defaultMessage="Requerido" /></p>
                    ) : userType === UserTypes.INFLUENCER ? (
                        <p className="required">*<FormattedMessage id="create_collaboration_select_required_3" defaultMessage="Solo empresas" /></p>
                    ) : null
                )
            }
            {
                (showProductOptions) && (
                    <div className="options" id="options">
                        {(productsOptions.map((product, index) => {

                            return (
                                <div className="option" key={index} onClick={() => {
                                    setSelectedProduct(product)
                                    setShowProductOptions(false)
                                }}>
                                    <Product product={product} />
                                </div>
                            )
                        }
                        ))}
                        {(userType === UserTypes.COMPANY) && (<div className="add">
                            <p className="addText" onClick={() => {
                                setShowAddProduct(true);
                            }}><FormattedMessage id="create_collaboration_select_add" defaultMessage="Añadir nuevo producto" /></p>
                            <p className="plus">+</p>
                        </div>
                        )}

                    </div>
                )
            }

            {(showAddProduct) && (<ModalAddProduct setShowAddProduct={setShowAddProduct} />)}

        </SelectProductContainer>
    )
}