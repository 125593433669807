import { API_GATEWAY_URL } from '../../utils/constants/constants';
import axiosInstance from '../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-payments';

export const PaymentAPI = {

    getAuthorizarionUrl: async () => {
        const response = await axiosInstance.get(`${BASE_URL}/user-payment-method/authorization-url`);
        return response.data;
    },

    disconectPaymentMethod: async (userId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/user-payment-method/revoke`);
        return response.data;
    }
};