import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-color: ${colors.blueCheck};
  /* background-color: ${props => props.isValid ? colors.blueCheck : 'transparent'}; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: background-color 0.3s; */
`;

// Estilo para el ícono de verificación
export const CheckIcon = styled.i`
  color: white;
  scale: 0.8;
`;

export const ShowIcon = styled.i`
  color: ${colors.grey};
  scale: 1.2;
  margin-top: 2px;
`;

// Estilo para el input
export const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  border: 1px solid ${colors.grey};
  border-radius: 10px;
  padding: 10px;
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  width: 100%;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  ::placeholder {
        font-size: 1rem;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: rgba(112, 112, 112, 0.51);
  }
`;