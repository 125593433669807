import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import CentraleSans from './../../utils/styles/CentraleSansBold.otf';

export const ModalSuccessfulChangePasswordContainer = styled.div`

    @font-face {
    font-family: 'Centrale';
    src: url(${CentraleSans}) format('opentype');
    }

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    
    .changeBody{
        background-color: #ffffff;
        padding: 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${colors.text};
        .icon{
            width: 60px;
            margin-bottom: 10px;
        }
        h3{
            margin-bottom: 10px;
            font-size: 1.4rem;
            font-weight: bolder;
            //font-family: 'Centrale', sans-serif;
        }
        .firstText{
            font-weight: 700;
        }

        .back{
            margin-top: 20px;
            border: none;
            padding: 10px;
            font-weight: 600;
            border-radius: 12px;
            width: 50%;
            align-self: center;
            cursor: pointer;
            background-color: ${colors.hightlight};
            color: #ffffff;
        }

    }
`