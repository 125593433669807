export const colors = {
    hightlight: "#E8339E",
    girlBackground: "#DB62B5",
    girlSectionBackground: "#F7F7F7",
    footerBackground: "#1D1D1D",
    footerBackgroundOpacity: "rgba(29, 29, 29, 0.7)",

    title: "#434343",
    text: "#3D3D3D",

    grey: "#BCBCBC",
    greyOptions: "rgba(191, 191, 191, 0.1)",
    modalBackground: "#EEEEEE",
    modalBackgroundOpacity: "rgba(238, 238, 238, 0.7)",
    errorBackground: "#F5F2F2",
    greyLetter: "#77838F",
    darkGrey: "#605858",
    greyIcon: "#B8B8B8",
    greyInput: "#707070",
    greyPhotoPicker: "#383434",
    greyChat: "#E4E6EB",

    black: "#0D0D0D",

    blue: "#1B62BE",
    blueButton: "#1A78F3",
    blueDark: "#295085",
    blueCheck: "#33aae8",
    twitter: "#00acee",
    facebook: "#3D6AD6",
    tiktok: "#F00044",
    tiktok2: "#08FFF9",

    googleButton: '#4285F4',
    facebookButton: '#4267B2',
};