import { createStore, combineReducers } from 'redux';
import { tokenReducer } from './token/reducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
    tokenReducer
})

const persistConfig = {
    key: 'root',
    //key: 'primary',
    storage,
    blacklist: ['navigation']
}

const store = createStore(persistReducer(persistConfig, reducers));

const persistor = persistStore(store);

export { store, persistor };