import { useEffect } from "react";
import { createContext, useState } from "react";
import { ServiceAPI } from "../api/product-and-service/service";


export const ServiceContext = createContext();

export default function ServiceProvider({ children }) {

    const [services, setServices] = useState([]);

    useEffect(() => {
        ServiceAPI.getServices().then((response) => {
            setServices(response);
        })
    }, [])

    const getServiceById = (id) => {
        return services.find(service => service.id === id);
    }

    return (
        <ServiceContext.Provider value={{ services, getServiceById }}>
            {children}
        </ServiceContext.Provider >
    )
};