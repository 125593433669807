import { TranslateContainer } from "./TranslateStyled";
import arrow from './../../utils/images/arrow_down.png';
import { useState, useContext, useEffect, useRef } from "react";
import { LangContext } from './../../context/langContext';
import { FormattedMessage } from "react-intl";
import { CurrencyContext } from "../../context/currencyContext";


export default function Translate({ showList, setShowList }) {

    const { setLanguage, country, setCountry } = useContext(LangContext);
    const { currency, handlerCurrency, currenciesList } = useContext(CurrencyContext);

    const menuRef = useRef(null);

    useEffect(() => {

        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowList(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [menuRef])

    return (
        <TranslateContainer country={country} showList={showList} ref={menuRef}>
            {/* <div className="flag" country={country}></div> */}
            <div className="translate" onClick={() => {
                setShowList(!showList);
            }}>
                <p className="translateText">{country === 'Spanish' ? 'ES' : 'EN'}/{currency?.symbol}</p>
                <img className="arrow" src={arrow} alt="cambiar de idioma" />
            </div>

            {(showList) &&
                (<div className="dropDown" >
                    <div onChange={(event) => {
                        setLanguage(event.target.value);
                        setCountry(event.target.value);
                    }}>
                        <p>
                            <FormattedMessage id="header_title_list_1" defaultMessage="Cambiar idioma" /> </p>
                        <label htmlFor="english" className="option">
                            <input type="radio" id="english" name="language" value="English" checked={country === 'English'} onChange={() => { setCountry('English') }} />
                            <FormattedMessage id="header_option_1_list" defaultMessage="Ingles - EN" />
                        </label>
                        <label htmlFor="spanish" className="option">
                            <input type="radio" id="spanish" name="language" value="Spanish" checked={country === 'Spanish'} onChange={() => { setCountry("Spanish") }} />
                            <FormattedMessage id="header_option_2_list" defaultMessage="Español - ES" />
                        </label>
                    </div>

                    <hr className="separator" />

                    <div>
                        <p>
                            <FormattedMessage id="header_title_list_2" defaultMessage="Cambiar moneda" /> </p>
                        {currenciesList.map((curre) => {
                            if (curre.usedSymbol === true) {
                                return (
                                    <label htmlFor={curre.symbol} className="option">
                                        <input type="radio" id={curre.symbol} name="currency" value={curre} checked={curre.symbol === currency.symbol} onChange={() => {
                                            handlerCurrency(curre)
                                        }} />
                                        {curre.name} - {curre.symbol}
                                    </label>
                                )
                            }

                        })}
                    </div>

                </div>)
            }

        </TranslateContainer>
    )
}