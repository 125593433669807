import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const MyProfileCollaborationsContainer = styled.div`

    .title{
        color: ${colors.title};
        margin-bottom: 20px;
        font-size: 3.5rem;
    }

    .cardsContainer{
        display: flex;
        flex-wrap: wrap;
        .card{
            opacity: 0.6;
            width: 23%;
            height: 21vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${colors.greyIcon};
            margin-bottom: 20px;
            margin-right: 2%;
            padding: 50px;
            text-align: center;
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Open Sans',sans-serif;
            color: ${colors.greyPhotoPicker};
            img{
                width: 100px;
                margin-bottom: 5px;
            } 
        } 
        .notCollaborations{
            height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colors.greyLetter};
        }
    }

    /**--------Responsive--------- */
    ${media.largeDesktop}{
        .title{
            font-size: 2.6rem;
        }
        .cardsContainer{
             .card{
                font-size: 1.5rem;
                img{
                    width: 80px;
                } 
            }   
        }
    }

    ${media.specialScreen}{
        .cardsContainer{
             .card{
                font-size: 1.1rem;
                img{
                    width: 60px;
                } 
            }   
        }
    }

    ${media.desktop}{
        .title{
            font-size: 2.3rem;
        }
    }

    ${media.smallDesktop}{
        .title{
            font-size: 2.1rem;
        }
    }

    ${media.tablet}{
        .title{
            font-size:2.3rem;
        }
        .cardsContainer{  
             .card{
                width: 31%;
                height: 28vw;
            }
        } 
    }

    ${media.largeMobile}{
        .title{
            font-size:1.8rem;
        }
        .cardsContainer{
             .card{
                width: 48%;
                height: 35vw;
                font-size: 0.8rem;
                img{
                    width: 35px;
                    margin-bottom: 5px;
                } 
            }   
        }
    }

`