import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


export default function AdminBlockedRoute({ children }) {

    const isAdmin = sessionStorage.getItem('isAdmin');

    if (JSON.parse(isAdmin) === true) {
        return <Navigate to="/error" />
    }

    return (
        <>
            {children ? children : <Outlet />}
        </>
    )
}
