import React, { useState } from "react";
import { ModalUploadVideoContainer } from "./ModalUploadVideoStyled";
import camera from './../../utils/images/camera.svg';
import close from './../../utils/images/close_circle_outline.svg';
import { FormattedMessage } from 'react-intl';
import { getBase64 } from "../../services/generalSevices";
import { useContext } from "react";
import Spinner from "../Spinner/Spinner";
import edit from './../../utils/images/edit_white.svg';
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";
import { S3API } from "../../api/utils/s3";
import { InfluencerAPI } from "../../api/user/influencer/influencer";
import { CompanyAPI } from "../../api/user/company/company";

export default function ModalUploadVideo({ setShowModalVideo }) {

    const { userType, userData, setUserData } = useContext(UserContext2);

    const [isLoading, setIsLoading] = useState(false);

    const saveProfileVideo = (e) => {
        console.log(e.target.files[0]);
        setIsLoading(true);
        getBase64({ file: e.target.files[0] }).then((video) => {
            const videoBody = {
                base64File: video.split(',')[1],
                formatFile: e.target.files[0].type.split('/')[1] || 'mp4',
            }
            if (userType === UserTypes.INFLUENCER) {
                //videoBody.address = `fluincr/${uniqueName}/profile`
                videoBody.address = `fluincr/${userData?.account}/profile`
            } else {
                //videoBody.address = `company/${uniqueName}/profile`
                videoBody.address = `company/${userData?.account}/profile`
            }


            S3API.getPresignedUrl(videoBody.address, `video/${videoBody.formatFile}`).then(presignedUrl => {
                if(presignedUrl){
                    S3API.uploadVideoS3(presignedUrl, e.target.files[0], () => null).then(res => {

                        let fileLink = presignedUrl.split("?")[0];
                        let fileName = fileLink.split(".com/")[1];

                        S3API.putObjectPublic(fileName).then(res2 => {

                            if(userType === UserTypes.INFLUENCER){
                                InfluencerAPI.updateVideoProfile(fileLink).then(res => {
                                    setIsLoading(false);
                                    setUserData({
                                        ...userData,
                                        video_profile: fileLink
                                    });
                                    setShowModalVideo(false);
                                });
                            } else {
                                CompanyAPI.updateVideoProfile(fileLink).then(res => {
                                    setIsLoading(false);
                                    setUserData({
                                        ...userData,
                                        video_profile: fileLink
                                    });
                                    setShowModalVideo(false);
                                });
                            }
                        });
                    });
                }
            });  
        });
    }

    return (
        <ModalUploadVideoContainer>

            <img src={close} alt="close" onClick={() => { setShowModalVideo(false) }} className="close" />

            {(!userData?.video_profile) ? (<div className="uploadContainer">
                <label htmlFor="video-file-input" className="fileLabel">
                    <img src={camera} alt="camera" className="cameraIcon" />
                    <p className="subTitle"><FormattedMessage id="my_profile_text_13" defaultMessage="Carga tu video de intro" /></p>
                    <p><FormattedMessage id="my_profile_text_14" defaultMessage="Preséntate con tus seguidores y cuentanos de ti." /></p>
                    <p><FormattedMessage id="my_profile_text_15" defaultMessage="Formato mp4 - mov - flv" /></p>
                </label>

                <input type="file" id="video-file-input" accept="video/mp4,video/x-m4v, video/mov, video/*" onChange={(e) => {
                    saveProfileVideo(e)
                }} />
            </div>) : (<div className="videoContainer">
                <div className="videoEdit">
                    <label htmlFor="edit-video-file-input" className="fileLabel">
                        <img src={edit} alt="edit" className="editPhoto" />
                    </label>

                    <input type="file" id="edit-video-file-input" accept="video/mp4,video/x-m4v, video/mov, video/*" onChange={(e) => {
                        saveProfileVideo(e)

                    }} />
                </div>
                <video src={userData?.video_profile} className="video" controls={true}></video></div>)}



            {(isLoading) && (<Spinner />)}

        </ModalUploadVideoContainer>
    )
}