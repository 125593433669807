import React, { useState } from "react";
import { NewPasswordContainer } from "./NewPasswordStyled";

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import arrow from './../../utils/images/arrow_back_pink.svg';
import { useEffect } from "react";
import Alert from "../../components/Alert/Alert";
import Spinner from "../../components/Spinner/Spinner";
import eye from './../../utils/images/eye.svg';
import slashEye from './../../utils/images/slash_eye.svg';
import { UserAPI } from "../../api/user/user";

export default function NewPassword() {

    const intl = useIntl();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [repetNewPassword, setRepetNewPassword] = useState('');

    const [invalidPassword, setInvalidPassword] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);

    const [seePassword, setSeePassword] = useState(false);
    const [seeRePassword, setSeeRePassword] = useState(false);

    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    //Accedemos a los valores
    let email = urlParams.get('email');
    let code = urlParams.get('code');

    useEffect(() => {
        setInvalidPassword(false);
    }, [newPassword, repetNewPassword])

    const savePassword = (event) => {
        event.preventDefault()
        const expRegPassword = new RegExp(
            "^(?=.*?[A-ZÑÁÉÍÓÚ])(?=.*?[a-zñáéíóú])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$"
        );
        
        if (newPassword !== '' && repetNewPassword !== '') {
            if (expRegPassword.test(newPassword)) {
                if (newPassword === repetNewPassword) {

                    setInvalidPassword(false);
                    setIsLoading(true);

                    UserAPI.resetPassword(newPassword, code, email).then((response) => {
                        setIsLoading(false);
                        setShowAlert(true);
                    }).catch((error) => {
                        setIsLoading(false);
                        setShowAlertError(true);
                    });
                } else {
                    setInvalidPassword(true);
                }
            } else {
                setInvalidPassword(true);
            }
        } else {
            setShowEmptyError(true);
        }

    }

    return (
        <NewPasswordContainer>
            <div className="resetHeader">
                <h2><FormattedMessage id="new_password_title_a" defaultMessage="Crea una nueva " />
                    <strong><FormattedMessage id="new_password_title_b" defaultMessage="contraseña" /></strong></h2>

            </div>

            <form action="submit">
                <p className="firstText"><FormattedMessage id="new_password_text_1" defaultMessage="Crea una nueva contraseña, recuerda que debe tener minúsculas y mayúsculas. Al menos 8 caracteres." /></p>

                <div className="inputContainer">
                    <input type={(seePassword) ? "text" : "password"} placeholder={intl.formatMessage({ id: "new_password_text_2" })} value={newPassword} onChange={(event) => { setNewPassword(event.target.value) }} />
                    <div className="seeContainer">
                        <img src={(seePassword) ? eye : slashEye} alt="password" className="iconSeePassword" onClick={() => { setSeePassword(!seePassword) }} />
                    </div>
                </div>

                <div className="inputContainer">
                    <input type={(seeRePassword) ? "text" : "password"} placeholder={intl.formatMessage({ id: "new_password_text_3" })} value={repetNewPassword} onChange={(event) => { setRepetNewPassword(event.target.value) }} />
                    <div className="seeContainer">
                        <img src={(seeRePassword) ? eye : slashEye} alt="password" className="iconSeePassword" onClick={() => { setSeeRePassword(!seeRePassword) }} />
                    </div>
                </div>

                {(showEmptyError) && (<p className="error"><FormattedMessage id="sign_up_error_message" defaultMessage="* Completar todos los campos" /></p>)}

                {(invalidPassword) && (<p className="error"><FormattedMessage id="new_password_error" defaultMessage="Contraseña no valida" /></p>)}


                <button className="saveButton" onClick={savePassword}>
                    <FormattedMessage id="new_password_button" defaultMessage="Confirmar contraseña" />
                </button>

            </form>
            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={arrow} alt="go back" />
                <p ><FormattedMessage id="reset_password_text_3" defaultMessage="Volver atrás" /></p>
            </div>

            {(showAlert) && (<Alert
                title={<FormattedMessage id="alert_forward_title" defaultMessage="¡Exitoso!" />}
                text={<FormattedMessage id="new_password_success" defaultMessage="Tu contraseña ha sido cambiada exitosamente" />}

                setShowAlert={setShowAlert}
                confirmText={<FormattedMessage id="header_button_text" defaultMessage="Iniciar sesión" />}
                onConfirm={() => { navigate('/signin') }}

            />)}

            {(showAlertError) && (<Alert
                title={<FormattedMessage id="Error_title" defaultMessage="¡Lo sentimos!" />}
                text={<FormattedMessage id="resetpassword_error_text" defaultMessage="Tu solicitud no se pudó completar, por favor haga una nueva solicitud de restauración de contraseña" />}
                setShowAlert={setShowAlertError}
                confirmText={<FormattedMessage id="signin_text_3" defaultMessage="Olvidé mi contraseña" />}
                onConfirm={() => { navigate('/resetpassword') }}
            />)}

            {(isLoading) && (<Spinner />)}

        </NewPasswordContainer>
    )
}