import React from "react";
import { NetworkIconContainer } from "./NetworkIconStyled";

export default function InstagramIcon() {
    return (
        <NetworkIconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="34.653" height="34.645" viewBox="0 0 34.653 34.645">
                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M17.325,10.678a8.883,8.883,0,1,0,8.883,8.883A8.869,8.869,0,0,0,17.325,10.678Zm0,14.658A5.775,5.775,0,1,1,23.1,19.56a5.785,5.785,0,0,1-5.775,5.775ZM28.643,10.314a2.072,2.072,0,1,1-2.072-2.072A2.067,2.067,0,0,1,28.643,10.314Zm5.883,2.1c-.131-2.775-.765-5.234-2.8-7.259s-4.484-2.659-7.259-2.8c-2.86-.162-11.434-.162-14.294,0A10.306,10.306,0,0,0,2.915,5.15C.882,7.176.256,9.634.116,12.409c-.162,2.86-.162,11.434,0,14.294.131,2.775.765,5.234,2.8,7.259s4.484,2.659,7.259,2.8c2.86.162,11.434.162,14.294,0,2.775-.131,5.234-.765,7.259-2.8s2.659-4.484,2.8-7.259c.162-2.86.162-11.426,0-14.286Zm-3.7,17.356a5.847,5.847,0,0,1-3.293,3.293c-2.281.9-7.692.7-10.212.7s-7.94.2-10.212-.7A5.847,5.847,0,0,1,3.82,29.773c-.9-2.281-.7-7.692-.7-10.212s-.2-7.94.7-10.212A5.847,5.847,0,0,1,7.113,6.055c2.281-.9,7.692-.7,10.212-.7s7.94-.2,10.212.7a5.847,5.847,0,0,1,3.293,3.293c.9,2.281.7,7.692.7,10.212S31.735,27.5,30.831,29.773Z" transform="translate(0.005 -2.238)" fill="#fff" />
            </svg>
        </NetworkIconContainer>
    )
}