import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalChangePasswordContainer = styled.div`
    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    
    .close{
        position: absolute;
        top:20px;
        right: 20px;
        width: 40px;
        cursor: pointer;
    }
    .changeBody{
        background-color: #ffffff;
        padding: 30px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        h3{
            margin-bottom: 30px;
            text-align: center;
            font-size: 2.5rem;
        }

        label{
            color: ${colors.greyLetter};
            margin-top: 20px;
            font-size: 2rem;
        }
        .inputContainer{
            width: 550px;
            display: flex;
            align-items: center;
            border:1px solid ${colors.grey};
            border-radius: 5px;
            padding: 10px;
            margin-top: 8px;
            input{
                width: 100%;
                outline: none;
                border: none;
                font-size: 1.8rem;
            }
            .check{
                margin-right: 10px;
                height: 30px;
                width: 30px;
            }
        }
        .error{
            font-size: 1.5rem;
            color: red;
            margin-top: 3px;
        }
        .errorContainer{
            width: 550px;
        }
        
        .save{
            margin-top: 40px;
            border: none;
            padding: 15px 20px;
            font-weight: 600;
            border-radius: 12px;
            align-self: center;
            cursor: pointer;
            background-color: ${colors.hightlight};
            color: #ffffff;
            font-size: 2rem;
        }
        .disabled{
            cursor: not-allowed;
        }
    }


    /**------------Responsive---------- */
    ${media.largeDesktop}{
        .changeBody{
            padding: 30px;
            h3{
                margin-bottom: 25px;
                font-size: 2rem;
            }

            label{
                font-size: 1.6rem;
                margin-top: 15px;
            }
            .inputContainer{
                width: 450px;
                padding: 10px;
                margin-top: 12px;
                input{
                    font-size: 1.5rem;
                }
                .check{
                    margin-right: 10px;
                    height: 25px;
                    width: 25px;
                }
            }
            .error{
                font-size: 1.1rem;
            }
            .errorContainer{
                width: 450px;
            }
            
            .save{
                margin-top: 40px;
                padding: 15px 20px;
                font-size: 1.6rem;
            }
        }
    }

    ${media.specialScreen}{
        .changeBody{
            padding: 25px;
            h3{
                margin-bottom: 20px;
                font-size: 1.8rem;
            }

            label{
                font-size: 1.4rem;
                margin-top: 10px;
            }
            .inputContainer{
                width: 380px;
                padding: 10px;
                margin-top: 12px;
                input{
                    font-size: 1.3rem;
                }
                .check{
                    height: 22px;
                    width: 22px;
                }
            }
            .error{
                font-size: 0.9rem;
            }
            .errorContainer{
                width: 380px;
            }
            
            .save{
                margin-top: 30px;
                padding: 10px 15px;
                font-size: 1.4rem;
            }
        }
    }

    ${media.desktop}{
        .changeBody{
            padding: 20px;
            h3{
                margin-bottom: 15px;
                font-size: 1.5rem;
            }

            label{
                margin-top: 10px;
                font-size: 1rem;
            }
            .inputContainer{
                width: 300px;
                display: flex;
                border:1px solid ${colors.grey};
                border-radius: 5px;
                padding: 5px;
                margin-top: 4px;
                input{
                    width: 100%;
                    outline: none;
                    border: none;
                    font-size: 1rem;
                }
                .check{
                    margin-right: 10px;
                    height: 20px;
                    width: 20px;
                }
            }
            .error{
                font-size: 0.7rem;
                color: red;
                margin-top: 3px;
            }
            .errorContainer{
                width: 300px;
            }
            
            .save{
                margin-top: 20px;
                padding: 10px;
                border-radius: 12px;
                font-size: 1rem;
            }
        }
    }

`