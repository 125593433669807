import '../../../Bootstrap.css';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ModalConfirmacion = ({ open, setOpen, titleId, textId, handleConfirm, confirmParam }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const onConfirm = () => {
        handleConfirm(confirmParam);
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className='p-3 pt-1' style={{minWidth: '22vw'}}>
                <DialogTitle className="fl-text-primary d-flex fs-4" >
                    <FormattedMessage id={titleId || "default"} defaultMessage="Default title for confirmation modal." />
                </DialogTitle>
                <DialogContent className='my-2'>
                    <p>
                        <FormattedMessage id={textId || "default"} defaultMessage="Default text for confirmation modal." />
                    </p>
                </DialogContent>
                <DialogActions className='p-2'>
                    <button onClick={handleClose} className="fl-button-secondary">
                        <FormattedMessage id="modalConfirmationButtonCancel" defaultMessage="Cancelar" />
                    </button>
                    <button onClick={onConfirm} className="fl-button-primary">
                        <FormattedMessage id="modalConfirmationButtonConfirm" defaultMessage="Confirmar" />
                    </button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default ModalConfirmacion