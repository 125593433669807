import React from 'react';
import { CheckboxContainer, CheckboxInput, CheckboxText } from './CheckboxStyled';

const Checkbox = ({ label, checked, onChange, align="bottom", classNameContainer, ...others }) => {
    return (
      <CheckboxContainer align={align} className={classNameContainer}>
        <CheckboxInput
          {...others}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <CheckboxText>{label}</CheckboxText>
      </CheckboxContainer>
    );
  };
  
  export default Checkbox;