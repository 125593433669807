import React, { useState, useContext } from "react";
import { ModalPricesContainer, ProfileImage } from "./ModalPricesStyled";
import close from './../../utils/images/close_circle.svg';
import avatar from './../../utils/images/avatar_fluincr.png';

import './../../Bootstrap.css'

import { FormattedMessage } from 'react-intl';
import Spinner from "../Spinner/Spinner";
import Alert from "../Alert/Alert";
import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { CurrencyContext } from "../../context/currencyContext";
import { UserContext2 } from "../../context/user/userContext";
import { ServiceAPI } from "../../api/product-and-service/service";
import { StaticDataContext } from "../../context/staticDataContext";
import { RateByServiceAPI } from "../../api/product-and-service/rateByService";

export default function ModalPrices({ setShowModalPrices }) {

    const location = useLocation();
    const navigate = useNavigate();
    const queryParam = new URLSearchParams(location.search).get('complete');

    const { currency, currenciesList, convertCurrency, reconvertCurrency, handlerCurrency } = useContext(CurrencyContext);

    const {
        setShowCompleteProfileModalReminder,//TODO: Pendiente de refactorizar
        userData,
        setUserData
    } = useContext(UserContext2);

    const { fluincrServicesSelectList } = useContext(StaticDataContext); 
    const [services, setServices] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessfulAlert, setShowSuccessfulAlert] = useState(false);

    useEffect(() => {
        const userRates = userData?.rate_by_service_list;
        fluincrServicesSelectList.forEach(fluincrServiceType => {
            const hasService = userRates.some(service => service.service_id === fluincrServiceType.id);
            if(!hasService){
                userRates.push({
                    service_id: fluincrServiceType.id,
                    price: 0
                })
            }
        });

        setServices(userRates);
    }, [userData]);

    const saveChange = () => {
        setIsLoading(true);

        const servicesToUpdate = services.map(service => {
            return {
                ...service,
                price: Math.round(service.price)
            }
        });

        RateByServiceAPI.updateRates(userData?.id, servicesToUpdate).then((response) => {
            setIsLoading(false);
            setUserData({
                ...userData,
                rate_by_service_list: servicesToUpdate
            })
            setShowModalPrices(false)
            if(queryParam === 'rates'){
                navigate('/myprofile')
                let socialNetworksPendingAux = userData.social_networks.length === 0;

                setShowCompleteProfileModalReminder(servicesToUpdate.every(service => service.price <= 0) 
                    || !userData?.image_profile 
                    || socialNetworksPendingAux
                )
            }
        }).catch((error) => {
            setIsLoading(false);
            console.error(error)
        });
    }

    const handleOnClose = () => {
        setShowModalPrices(false)
        if(queryParam === 'rates'){
            navigate('/myprofile')

            let ratesPending = userData.rate_by_service_list.every(service => service.price <= 0);

            setShowCompleteProfileModalReminder(ratesPending);
        }
    }

    const handleInputChangeRate = (event, serviceId) => {
        let auxServices = [...services];
        let price = reconvertCurrency(event.target.value, currency.symbol);
        auxServices = auxServices.map(service => {
            if(service.service_id === serviceId){
                return {
                    ...service,
                    price: price,
                }
            }
            return service;
        });

        setServices(auxServices);
    }

    return (
        <ModalPricesContainer>

            <div className="pricesBody d-flex flex-column justify-content-center pb-2">
                <img src={close} alt="close" className="close" onClick={handleOnClose} />

                <h2 className="fl-text-primary my-4 text-center"><FormattedMessage id="modal_prices_subTitle_1" defaultMessage="Define tus precios" /></h2>

                <div className="headerTable">
                    <ProfileImage className="mb-5" image={(userData?.image_profile) ?? (avatar)} />
                </div>

                <ProfileImage className="position-absolute headerTableDesktop mt-4 ms-4" image={(userData?.image_profile) ?? (avatar)} />

                <div className="d-flex flex-column justify-content-center align-items-center p-0">
                    <div className="d-flex flex-row justify-content-center align-items-center gap-2 mb-2">
                        <FormattedMessage id="modal_prices_subTitle_2" defaultMessage="Moneda " />
                        <FormControl size="small">
                            <Select
                                defaultValue={currency.id}
                                onChange={(e) => { 
                                    const currency = currenciesList.find(currency => currency.id === e.target.value)
                                    handlerCurrency(currency); 
                                }}
                                variant="outlined"
                            >
                                {currenciesList.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.symbol}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {services.map((service, index) => {
                        const convertedRate = service?.price && service?.price > 0 ? convertCurrency(service.price, currency.symbol) : null;
                        const fluincrService = fluincrServicesSelectList.find(fluincrService => fluincrService.id === service.service_id);
                        return (
                            <div className="servicesPrices d-flex flex-row justify-content-center align-items-center gap-2 w-50">
                                <p className="serviceLabel text-capitalize w-25 fw-bold">{fluincrService?.label}</p>
                                <TextField
                                    id="outlined-start-adornment"
                                    sx={{ m: 1, width: '12ch' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    type="number"
                                    value={Math.round(convertedRate) || null}
                                    onChange={(event) => {
                                        if(event.target.value >= 0 && event.target.value <= 999999999) 
                                            handleInputChangeRate(event, fluincrService?.id) }
                                    }
                                    variant="standard"
                                />
                                <p className="text-nowrap w-25">≈ USD ${Math.round(service?.price || 0)}</p>
                            </div>
                        )
                    })}

                </div>

                <p className="w-100 text-center help-text fw-bold"><FormattedMessage id="modal_prices_text_6" defaultMessage="Para desactivar un servicio, establezca el precio en $0 o déjelo en blanco" /></p>

                <div className="buttonContainer">
                    <button className="saveButton" onClick={saveChange}><FormattedMessage id="modal_prices_button_2" defaultMessage="Guardar y cerrar" /></button>
                </div>

            </div>

            {(isLoading) && (<Spinner />)}

            {(showSuccessfulAlert) && (<Alert
                title={<FormattedMessage id="alert_forward_title" defaultMessage="¡Exitoso!" />}
                text={<FormattedMessage id="alert_change_successful_text" defaultMessage="Los cambios se han realizado con éxito." />}
                setShowAlert={setShowSuccessfulAlert}
            />)}
        </ModalPricesContainer>
    )
}