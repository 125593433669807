import React from "react";
import { ModalSuccessfulChangePasswordContainer } from "./ModalSuccessfulChangePasswordStyled";
import { FormattedMessage } from 'react-intl';
import check from './../../utils/images/check_circle_bigger.svg';


export default function ModalSuccessfulChangePassword({ setShowEditPassword, setShowSuccessfulPassword }) {

    const goBack = () => {
        setShowSuccessfulPassword(false);
        setShowEditPassword(false);
    }

    return (
        <ModalSuccessfulChangePasswordContainer>

            <div className="changeBody">
                <img src={check} alt="check" className="icon" />

                <h3><FormattedMessage id="successful_change_password_title" defaultMessage="¡Cambio Exitoso!" /></h3>

                <p className="firstText"> <FormattedMessage id="successful_change_password_text_1" defaultMessage="Tu cuenta ha cambiado su contraseña." /> </p>

                <p className="secondText"> <FormattedMessage id="successful_change_password_text_2" defaultMessage="¡En hora buena!" /> </p>

                <button className="back" onClick={goBack}><FormattedMessage id="successful_change_password_button" defaultMessage="Volver a mi cuenta" /></button>

            </div>

        </ModalSuccessfulChangePasswordContainer>
    )
}