import styled from "styled-components";
import { colors } from "../../utils/styles/colors";

export const ModalInfluencerVideoContainer = styled.div`
    position: fixed;
    overflow-y: auto;
    top:0;
    left: 0; 
    height: 100vh;
    width: 100vw;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    align-items: center;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .close{
        width: 40px;
        align-self: flex-end;
        cursor: pointer;
    }
    .notVideoContainer{
        cursor: pointer;
        margin-top: 30px;
        background-color: ${colors.grey};
        padding: 30px;
        min-height: 80%;
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .cameraIcon{
            width: 50px;
        }
        .subTitle{
            font-weight: 700;
            padding: 10px 0;
        }
    }
    .videoContainer{
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85%;
        .video{
            width:60%;
        }
    }

`