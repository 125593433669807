import { useState } from 'react'
import InstagramLogin from "react-instagram-login";
import { FormattedMessage } from 'react-intl';
import { URL, URLplatform, backendURL, fluincrCaresEN, fluincrCaresES } from "../../../../utils/constants/constants";


import InstagramLogo from "../../../../utils/images/networks/instagram.png";
import instagramGrey from '../../../../utils/images/instagram_grey.svg';
import edit from '../../../../utils/images/edit_white.svg';

import '../../../../Bootstrap.css'
import './InstagramProfileLoginStyled.css'
import ModalInstagram from './ModalInstagram/ModalInstagram';

const InstagramProfileLogin = ({setInstagramProfileLink, setInstagramFollowers}) => {

  const [openInstagramModal, setOpenInstagramModal] = useState(false);
  const manualLogin = true;

  const responseInstagram = (response) => {
      // console.log(response)
  }

  return (
    (!manualLogin) ? 
      <InstagramLogin
          clientId="587183222918269"
          fields="id,username,account_type"
          scope="user_profile"
          cssClass="bg-transparent border-0 cursor-pointer p-0"
          useRedirect={true}
          redirectUri={`${URLplatform}/auth/instagram`}
          onSuccess={responseInstagram}
          //onFailure={responseInstagram}
          //autoLoad={false}
          cookiePolicy={'none'}
      >
          <div className="instagramButtonDesktop fl-font-family d-flex align-items-center text-white fw-bold fs-5 gap-1" >
              <span>+</span>
              <img src={InstagramLogo} className="mb-1" width={25} height={25}/> 
              <FormattedMessage id="my_profile_text_1" defaultMessage="Conecta tu Instagram" />
          </div>
          <div className="instagramButtonMobile d-none p-2 rounded-circle">
              <img src={instagramGrey} width={22} height={22} />
              <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
          </div>
      </InstagramLogin>
      :
      <>
        <div className="instagramButtonDesktop fl-font-family d-flex align-items-center text-white fw-bold fs-5 gap-1" onClick={() => setOpenInstagramModal(true)}>
            <span>+</span>
            <img src={InstagramLogo} className="mb-1" width={25} height={25}/> 
            <FormattedMessage id="my_profile_text_1" defaultMessage="Conecta tu Instagram" />
        </div>
        <div className="instagramButtonMobile d-none p-2 rounded-circle">
            <img src={instagramGrey} width={22} height={22} onClick={() => setOpenInstagramModal(true)}/>
            <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
        </div>

        <ModalInstagram open={openInstagramModal} setOpen={setOpenInstagramModal} setInstagramFollowers={setInstagramFollowers} setInstagramProfileLink={setInstagramProfileLink}></ModalInstagram>
      </>
  )
}

export default InstagramProfileLogin