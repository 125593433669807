import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LikeCompanyContainer } from "./LikeCompanyStyled";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { UserAPI } from "../../api/user/user";
import { InfluencerAPI } from "../../api/user/influencer/influencer";

export default function LikeCompany({ profileId }) {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);

  const { userData, setUserData, userToken, userType } = useContext(UserContext2);

  useEffect(() => {
    if (userType === UserTypes.INFLUENCER && userData) {
      setIsFavorite(
        userData?.likes?.find((userId) => userId === profileId) || false
      );
    } else {
        setIsFavorite(false);
        const likes = userData?.likes || [];
        if (likes.includes(profileId)) {
          likes.splice(likes.indexOf(profileId), 1);
        } else {
          likes.push(profileId);
        }
        setUserData({ ...userData, likes: likes });
    }
  }, [userData, profileId]);

  const handlerLike = () => {
    if (!userToken) {
      navigate("/signin");
    } else {
      InfluencerAPI.likes(profileId).then((response) => {
        setIsFavorite(!isFavorite);
        const likes = userData?.likes || [];
        if (likes.includes(profileId)) {
          likes.splice(likes.indexOf(profileId), 1);
        } else {
          likes.push(profileId);
        }
        setUserData({ ...userData, likes: likes });
      });
    }
  };

  return (
    <LikeCompanyContainer onClick={handlerLike}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45.682"
        height="39.971"
        viewBox="0 0 45.682 39.971"
      >
        {isFavorite ? (
          <path
            id="Icon_awesome-heart"
            data-name="Icon awesome-heart"
            d="M41.247,4.981C36.357.814,29.086,1.564,24.6,6.194L22.841,8.005,21.083,6.194C16.6,1.564,9.324.814,4.434,4.981A12.811,12.811,0,0,0,3.551,23.53L20.815,41.356a2.8,2.8,0,0,0,4.042,0L42.121,23.53a12.8,12.8,0,0,0-.874-18.549Z"
            transform="translate(0.001 -2.248)"
            fill="#FF0000" /* fill="url(#grad1)" */
          />
        ) : (
          <path
            id="Icon_awesome-heart"
            data-name="Icon awesome-heart"
            d="M41.247,4.981C36.357.814,29.086,1.564,24.6,6.194L22.841,8.005,21.083,6.194C16.6,1.564,9.324.814,4.434,4.981A12.811,12.811,0,0,0,3.551,23.53L20.815,41.356a2.8,2.8,0,0,0,4.042,0L42.121,23.53a12.8,12.8,0,0,0-.874-18.549Z"
            transform="translate(0.001 -2.248)"
            fill="#fff"
          />
        )}
      </svg>
    </LikeCompanyContainer>
  );
}
