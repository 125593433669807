import React, { useState, useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { ContentDetailsCompanyContainer, Image } from "./ContentDetailsCompanyStyled";
import { FormattedMessage } from 'react-intl';
import photoVideo from './../../utils/images/photo_video.svg';
import Spinner from "../Spinner/Spinner";
import { Status } from "../ContentDetailsInfluencer/ContentDetailsInfluencerStyled";
import ItemStatusSelect from "../ItemStatusSelect/ItemStatusSelect";
import { ServiceContext } from "../../context/serviceContext";
import { ItemAPI } from "../../api/collaboration/item";
import { ContentTypes, ItemStatusId } from "../../utils/constants/item";
import downloadCircleIcon from './../../utils/images/download_circle_blue.png';
import ModalSelectPublishDate from "./ModalSelectPublishDate/ModalSelectPublishDate";
import editIcon from './../../utils/images/edit_grey.svg';
import { formatDateLocal, formatDateTimeLocal } from "../../services/dateServices";


export default function ContentDetailsCompany({ item, onChangeItem, collaborationStatus, setShowCarousel, setShowDispute }) {

    const { services } = useContext(ServiceContext);
    const { idCollaboration } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const isAdmin = sessionStorage.getItem('isAdmin');

    const {
        id,
        collaboration_id: collaborationId,
        status: statusItem,
        content: contentItem,
        cover: coverItem,
        price: priceItem,
        service_id: serviceId,
        modification_date: modificationDateItem,
        publishing_date: publishingDateItem,
    } = item;

    const [serviceType, setServiceType] = useState();

    const [auxContentItem, setAuxContentItem] = useState();
    const [auxCoverItem, setAuxCoverItem] = useState();
    const [auxStatusItemId, setAuxStatusItemId] = useState();

    const [uploadContentDate, setUploadContentDate] = useState(null);
    const [approveContentDate, setApproveContentDate] = useState(null);
    const [disputeWindowDate, setDisputeWindowDate] = useState(null);
    const [publishDate, setPublishDate] = useState(publishingDateItem);
    const [editPublishDate, setEditPublishDate] = useState(false);
    const [showModalPublishDate, setShowModalPublishDate] = useState(false);

    useEffect(() => {
        updateDeadlines(modificationDateItem);
    }, []);

    useEffect(() => {
        console.log("item", item)
        setAuxContentItem(contentItem);
        setAuxCoverItem(coverItem);
        setAuxStatusItemId(statusItem.id);
        setServiceType(services.find(service => service.id === serviceId)?.type);
    }, [item])

    const handlerApproveContent = (publishDateSelected) => {
        if(publishDateSelected){
            setIsLoading(true)
            ItemAPI.setPublishingDate(id, publishDateSelected).then((response) => {
                setPublishDate(publishDateSelected.toISOString());
                ItemAPI.approveContent(id, item).then((itemDTOResponse) => {
                    setAuxStatusItemId(itemDTOResponse.status.id);
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                });
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
        } else {
            setShowModalPublishDate(true);
        }
    }

    const editingPublishDate = () => {
        setEditPublishDate(true);
        setShowModalPublishDate(true);
    }

    const onEditPublishDate = (publishDateSelected) => {
        ItemAPI.setPublishingDate(id, publishDateSelected).then((response) => {
            setIsLoading(false)
            setPublishDate(publishDateSelected.toISOString());
        });
    }

    const handlerRequestModification = () => {
        setIsLoading(true)
        ItemAPI.rejectContent(id, item).then((itemDTOResponse) => {
            setAuxStatusItemId(itemDTOResponse.status.id);
            setAuxCoverItem(null);
            setAuxContentItem(null);
            setIsLoading(false);
        })
    }

    const updateDeadlines = (date) => {
        const uploadDate = new Date(date);
        uploadDate.setDate(uploadDate.getDate() + 10);
        console.log("uploadDate", uploadDate)
        setUploadContentDate(formatDateLocal(uploadDate.toISOString()));

        const approveDate = new Date(date);
        approveDate.setDate(approveDate.getDate() + 3)
        console.log("approveDate", approveDate)
        setApproveContentDate(formatDateLocal(approveDate.toISOString()));

        const disputeWindowDate = new Date(date);
        disputeWindowDate.setDate(disputeWindowDate.getDate() + 3);
        console.log("disputeWindowDate", disputeWindowDate)
        setDisputeWindowDate(formatDateLocal(disputeWindowDate.toISOString()));
    }

    return (
        <ContentDetailsCompanyContainer>
            {(JSON.parse(isAdmin) === true) ? (
                <div className="adminSelect">
                    <ItemStatusSelect item={item}></ItemStatusSelect>
                </div>) : (
                <div className="statusContainer">
                    {(auxStatusItemId === ItemStatusId.ACCEPTANCE_PENDING) ? (<div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_company_1" defaultMessage="Aceptación pendiente" /></p>
                    </div>
                    ) : (auxStatusItemId === ItemStatusId.DECLINED) ? (<div className="statusTextContainer">
                        <p className="status"><FormattedMessage id="collaboration_details_status_company_2" defaultMessage="Declinado (cerrado)" /></p>
                    </div>
                    ) : (auxStatusItemId === ItemStatusId.UPLOAD_CONTENT) ? (
                        <div className="statusTextContainer">
                            {/*<Status status={collaborationStatus}><FormattedMessage id="collaboration_details_status_influencer_3" defaultMessage="Subir contenido" /></Status>*/}
                            <p className="status"><FormattedMessage id="collaboration_details_status_company_3" defaultMessage="Creando contenido" /></p>
                            <p className="status"><FormattedMessage id="collaboration_details_status_influencer_3_b" defaultMessage="Subir antes del " />{uploadContentDate ?? ''}</p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.APPROVE_CONTENT) ? (<div className="statusTextContainer">
                        <div>
                            <p>
                                <span className="status statusButton" onClick={() => setShowModalPublishDate(true)}>
                                    <FormattedMessage id="collaboration_details_status_company_4_a" defaultMessage="Aprobar" />
                                </span>
                                <FormattedMessage id="collaboration_details_status_company_4_a_2" defaultMessage=" anted del " />
                                {approveContentDate ?? ''}
                            </p>
                            <p>
                                <FormattedMessage id="collaboration_details_status_company_4_b_2" defaultMessage="o " />
                                <span className="status statusButton" onClick={handlerRequestModification}>
                                    <FormattedMessage id="collaboration_details_status_company_4_b" defaultMessage="solicitar modificación" />
                                </span>
                            </p>
                        </div>
                    </div>
                    ) : (auxStatusItemId === ItemStatusId.PUBLISH_CONTENT) ? (
                        <div className="statusTextContainer">
                            <p className="status"><FormattedMessage id="collaboration_details_status_company_5" defaultMessage="Publicando contenido" /></p>
                            <p className="status">
                                {
                                    publishDate ?
                                        <>
                                            <FormattedMessage id="collaboration_details_status_company_5_b" defaultMessage="Fecha de publicación: " />
                                            {formatDateTimeLocal(publishDate)}
                                        </>
                                        :
                                        <FormattedMessage id="collaboration_details_status_company_5_c" defaultMessage="Consultar" />
                                }
                                <img src={editIcon} onClick={editingPublishDate} width={15} className="editButton"/>
                            </p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.DISPUTE_WINDOW) ? (
                        <div className="statusTextContainer">
                            <p className="status statusButton"><FormattedMessage id="collaboration_details_status_company_6" defaultMessage="Ventana de disputa" /></p>
                            <p className="status"><FormattedMessage id="collaboration_details_status_company_6_b" defaultMessage="hasta " />{disputeWindowDate ?? ''}</p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.ON_HOLD) ? (
                        <div className="statusTextContainer">
                            <p className="status"><FormattedMessage id="collaboration_details_status_company_7" defaultMessage="En espera" /></p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.COMPLETED) ? (
                        <div className="statusTextContainer">
                            <p className="status"><FormattedMessage id="collaboration_details_status_company_8" defaultMessage="Terminado" /></p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.CANCELLED) ? (
                        <div className="statusTextContainer">
                            <p className="status"><FormattedMessage id="collaboration_details_status_influencer_9" defaultMessage="Cancelado" /></p>
                        </div>
                    ) : (auxStatusItemId === ItemStatusId.OTHER) && (
                        <div className="statusTextContainer">
                            <p className="status"><FormattedMessage id="collaboration_details_status_influencer_10" defaultMessage="Otro" /></p>
                        </div>
                    )}
                </div>
            )}

            {(!auxContentItem) ? (
                <div className="notContent">
                    <img src={photoVideo} alt="video" />
                </div>
            ) : (
                <div className="content"  >
                    {(serviceType != ContentTypes.VIDEO || (serviceType == ContentTypes.VIDEO && auxCoverItem)) ? (<>{/* <a href={content} download> */}
                        <Image image={auxCoverItem ?? auxContentItem} onClick={() => {
                            setShowCarousel(true);
                        }} >
                            <a href={auxContentItem} download onClick={(event) => event.stopPropagation()}>
                                <img src={downloadCircleIcon} alt="video" onClick={(event) => {event.stopPropagation()}} />
                            </a>
                        </Image>
                        {/* </a> */} </>) : (
                        <>
                            {/* <a href={content} download> */}
                            <video
                                className="video"
                                //controls
                                src={auxContentItem}
                                onClick={() => {
                                    setShowCarousel(true);
                                }}
                            >
                                <img src={downloadCircleIcon} alt="video" style={{ position: 'relative', width: '1.5em' }}/>
                            </video>
                            
                            {/* </a>  */}
                        </>
                    )}
                    
                </div>
            )}
            {(isLoading) && (<Spinner />)}
            <ModalSelectPublishDate
                open={showModalPublishDate}
                setOpen={setShowModalPublishDate}
                publishDate={publishDate}
                onConfirm={handlerApproveContent}
                onEdit={onEditPublishDate}
                edit={editPublishDate}
            ></ModalSelectPublishDate>
        </ContentDetailsCompanyContainer>
    )
}