import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ModalReceivedDisputeContainer = styled.div`

    position: fixed;
    top:0;
    left: 0; 
    width: 100vw;
    height: 100vh;
    background-color: ${colors.footerBackgroundOpacity};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .close{
            width: 45px;
            align-self: flex-end;
            position: absolute;
            top:20px;
            right: 20px;
            cursor: pointer;
        }

    .disputeBody{
        background-color: #ffffff;
        border-radius: 15px;
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        color: ${colors.text};
        .timeIcon{
            width: 55px;
        }
        .title{
            margin: 10px 0;
            text-align: center;
        }
        .subtitle{
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }
        .text{
            text-align: center;
            a{
                //color: ${colors.hightlight};
                color: ${colors.grey};
                text-decoration: underline;
            }
            a:visited {
                //color: ${colors.hightlight};
                color: ${colors.grey};
            }
        }
        .perfectButton{
            align-self: center;
            outline: none;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            height: 35px;
            //padding: 10px 30px;
            padding: 0 30px;
            font-size: 1rem;
            background: ${colors.hightlight};
            color: #ffffff; 
            margin-top: 30px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
     
    }

    /**--------Responsive---------- */
    
    
    ${media.smallDesktop}{
        .disputeBody{
            width: 75%;
        }
    }
    
    ${media.tablet}{
        .close{
            display: none;
        }
    } 

    ${media.largeMobile}{
        .disputeBody{
            width: 90%;
            padding: 40px 30px;
        }
    }

`

