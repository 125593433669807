import styled from 'styled-components';
import { colors } from './../../utils/styles/colors';
import { media } from './../../utils/styles/media';


export const FooterContainer = styled.div`

    background-color: ${colors.footerBackground};
    margin-top: auto;
    padding: 50px 200px 40px;
    font-size: 1.3rem;
    .top{
        display: flex;
        justify-content: space-between;
        .subscribe{
            width: 35%;
            .logo{
                //width: 250px;
                width: 300px;
            }
            p{
                padding: 15px 0 ;
                width: 75%;
                color: ${colors.grey};
                font-weight: 300;
            }
            form{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                input{
                    width: 80%;
                    border-radius: 15px;
                    border: none;
                    outline: none;
                    height: 50px;
                    padding: 0 25px;
                    font-size: 1rem;
                }
                button{
                    margin-top: 20px;
                    margin-bottom: 10px;
                    height: 50px;
                    padding: 0 25px;
                    background-color: ${colors.hightlight};
                    border: none;
                    border-radius: 15px;
                    color: #ffffff;
                    font-size: 1.4rem;
                    font-weight: 300;
                    font-family: 'Montserrat', sans-serif;
                }
            }
        }
        .linksContainer{
            display: flex;
            color: ${colors.grey};
            padding-left: 60px;
            .links{
                padding: 10px 40px;
                .title{
                    padding-bottom: 10px;
                    text-transform: uppercase;
                }
                .list{
                    p{
                        padding: 5px 0;
                        text-transform: lowercase;
                        a{
                            color: ${colors.grey};
                            font-weight: 300;
                        }
                    }
                    p:first-letter{
                        text-transform: uppercase;
                    }
                    
                }
                
            }
        }
    }
    .bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${colors.grey};
        .functionalityContainer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .networks{
                align-self: flex-start;
                gap: 1.5em;
                display: flex;
                align-items: center;
                    .tiktok{
                        width: 48px;
                    }
                }
            form{
                margin-top: 20px;
                display: flex;
                
                .selectBox{
                    width: 360px;
                    position: relative;
                    margin: 0 5px;
                    .select{
                        width: 100%;
                        display: flex;
                        cursor: pointer;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 10px 13px;
                        border: 1px solid ${colors.grey};
                        border-radius:10px;
                        .selected{
                            color:${colors.grey};
                            display: flex;
                            align-items: center;
                            width: 100%;
                            justify-content: center;
                            
                            .flag{
                                width: 25px;
                                height: 25px;
                                margin-right: 10px;
                            }
                            .country{
                                font-size: 1.3rem;
                                color: #ffffff;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 400;
                                cursor: pointer;
                            }
                        }
                        .dropdown{
                            width: 20px;
                        }
                    }
                    .options{
                        width: 100%;
                        max-height: 300px;
                        overflow: auto;
                        background-color: ${colors.footerBackground};
                        border: 1px solid ${colors.grey};
                        border-radius:10px;
                        z-index: 50;
                        position: absolute;
                        bottom: 60px;
                        
                        ::-webkit-scrollbar {
                            display: none;
                        }
                        .option{
                            color:${colors.grey};
                            display: flex;
                            padding: 10px;
                            .flag{
                                width: 25px;
                                height: 25px;
                                margin-right: 10px;
                            }
                            .country{
                                font-size: 1.3rem;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 300;
                            }
                        }
                        .option:hover{
                            background-color: ${colors.greyOptions};
                        }
                    }
                }
                
            }
        }
        .copyright{
            padding-top: 20px;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
        }
    }

    /**----------Responsive---------- */
    ${media.largeDesktop}{
        padding: 50px 160px 35px;
        .top{
            .subscribe{
                width: 30%;
                .logo{
                    //width: 230px;
                    width: 260px;
                }
                p{
                    width: 85%;
                }
                form{
                    input{
                        width: 95%;
                        height: 45px;
                        padding: 0 20px;
                        ::placeholder{
                            //font-weight: bold;
                            color: rgba(112,112,112,0.51);
                            font-family: 'Open Sans', sans-serif;
                        }
                    }
                    button{
                        padding: 0 20px;
                        height: 45px;
                        font-size: 1.2rem;
                       
                    }
                }
            }
            .linksContainer{
                .links{
                    padding: 5px 40px;
                }
            }
        }

        .bottom{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: ${colors.grey};
            .functionalityContainer{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .networks{
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    img{
                        cursor: pointer;
                        width: 28px;
                    }
                    .tiktok{
                        width: 36px;
                    }
                    .youtube{
                        width: 2em;
                    }
                }
            }
        }
    }

    ${media.specialScreen}{
        padding: 50px 140px 30px;
        font-size: 1.2rem;
        .top{
            .subscribe{
                width: 30%;
                .logo{
                    width: 240px;
                }
                p{
                    width: 87%;
                }
                form{
                    input{
                        width: 95%;
                        height: 45px;
                        padding: 0 13px;
                        font-size: 1rem;
                    }
                    button{
                        padding: 0 12px;
                        height: 45px;
                        font-size: 1.2rem;
                       
                    }
                }
            }
            .linksContainer{
                .links{
                    padding: 20px 30px;
                    .list{
                        p{
                            padding: 4px 0;
                        }
                    }
                }
            }
        }
    }

    ${media.macdesktop}{
        padding: 50px 130px 30px;
        font-size: 1.1rem;
        .top{
            .subscribe{
                width: 30%;
                .logo{
                    width: 230px;
                }
                p{
                    width: 87%;
                }
                form{
                    input{
                        width: 95%;
                        height: 40px;
                        padding: 0 12px;
                    }
                    button{
                        padding: 0 12px;
                        height: 40px;
                        font-size: 1.1rem;
                       
                    }
                }
            }
            .linksContainer{
                .links{
                    padding: 20px 20px;
                    .list{
                        p{
                            padding: 4px 0;
                        }
                    }
                }
            }
        }

        .bottom{
            .functionalityContainer{
                .networks{
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    img{
                        width: 24px;
                    }
                    .tiktok{
                        width: 30px;
                    }
                }
                form{
                    .selectBox{
                        width: 290px;
                        .select{
                            padding: 7px 10px 9px;
                            .selected{
                                .flag{
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 1.1rem;
                                }
                            }
                        }

                        .options{
                            bottom: 45px;
                            .option{
                                .flag{
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 1.1rem;
                                }
                            }
                        }
                    } 
                }
            }
        }
    }

    ${media.desktop}{
        padding: 50px 100px 30px;
        font-size: 1rem;
        .top{
            .subscribe{
                width: 30%;
                .logo{
                    width: 220px;
                }
                p{
                    width: 88%;
                }
                form{
                    input{
                        width: 95%;
                        height: 40px;
                        padding: 0 12px;
                    }
                    button{
                        padding: 0 12px;
                        height: 40px;
                        font-size: 1rem;
                       
                    }
                }
            }
            .linksContainer{
                .links{
                    padding: 20px 20px;
                    .list{
                        p{
                            padding: 4px 0;
                        }
                    }
                }
            }
        }

        .bottom{
            .functionalityContainer{
                .networks{
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    img{
                        width: 22px;
                    }
                    .tiktok{
                        width: 30px;
                    }
                }
                form{
                    .selectBox{
                        //width: 19.5vw;
                        width: 260px;
                        .select{
                            padding: 7px 10px 9px;
                            .selected{
                                .flag{
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 1rem;
                                }
                            }
                        }

                        .options{
                            bottom: 40px;
                            
                            .option{
                                .flag{
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 1rem;
                                }
                            }
                        }

                    }
                    
                }

            }
        }
    }

    ${media.smallDesktop}{ 
        .top{
            .subscribe{  
                form{
                    input{
                        width: 100%;
                        height: 38px;
                    }
                    button{
                        height: 38px;                      
                    }
                }
            }
        }
        
    }


    ${media.laptop}{
        padding: 30px 50px;
        .top{
            
            .subscribe{
                width: 50%;
                .logo{
                    width: 120px;
                }
                p{
                    font-size: 0.8rem;
                }
                form{
                    margin-top: 10px;
                    input{
                        border-radius: 50px;
                        //padding: 5px;
                        height: 32px;
                    }
                    button{
                        margin: 20px 0;
                        //padding: 6px 12px;
                        border-radius: 50px;
                        font-size: 1rem;
                        height: 32px;
                    }
                }
            }
            .linksContainer{
                padding-left: 20px;
                .links{
                    .list{
                        p{
                            padding: initial;
                            a{
                                font-size: 0.8rem;
                            }
                        }
                        
                    }
                }
            }
        }
        .bottom{
            
            .functionalityContainer{
                form{
                    .selectBox{
                        //width: 22vw;
                        width: 200px;

                        .select{
                            border-radius:50px;
                            .selected{
                                .flag{
                                    width: 18px;
                                    margin-right: 5px;
                                }
                                .country{
                                    font-size: 0.7rem;
                                }
                            }
                            .dropdown{
                                width: 18px;
                            }
                        }
                        .options{
                            max-height: 250px;

                            .option{
                                .flag{
                                    width: 18px;
                                }
                                .country{
                                    font-size: 0.7rem;
                                }
                            }
                        }
                    }
                }
            }
            .copyright{
                font-size: 0.8rem;
            }
        }

    }

    ${media.tablet}{
        .bottom{
            .functionalityContainer{
                form{
                    .selectBox{
                        .select{
                            .selected{
                                .country{
                                    font-size: 0.6rem;
                                }
                            }
                            .dropdown{
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }

    }

    ${media.largeMobile}{
        padding: 30px 20px;
        .top{
            flex-direction: column;
            .subscribe{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .logo{
                    width: 180px;
                }
                 p{
                    padding: 20px 50px;
                    width: 100%;
                    text-align: center;
                } 
                form{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    padding: 0 30px;
                    input{
                        padding: 10px;
                        height: initial;
                    }
                    button{
                        padding: 8px 20px;
                        font-size: 1.2rem;
                        margin: 30px 0;
                        height: initial;
                    }
                }
            }
            .linksContainer{
                padding-left: 0px;
                .links{
                    .title{
                        display: none;
                    }
                    .list{
                        a{
                            font-size: 0.8rem;
                        }
                    }
                    
                }
            }
            
        }
        .bottom{
            .functionalityContainer{
                flex-direction: column-reverse;
                .networks{
                    align-self: center;
                    padding-top: 10px;
                }
                form{
                    flex-direction: column;
                    .selectBox{
                        width: 60vw;
                        margin: 5px 0;
                        .select{
                            border-radius:10px;
                            .selected{
                                .flag{
                                    width: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 0.8rem;
                                }
                            }
                            .dropdown{
                                width: 20px;
                            }
                        }
                        .options{
                            max-height: 350px;
                            .option{
                                .flag{
                                    width: 20px;
                                    margin-right: 10px;
                                }
                                .country{
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${media.smallMobile}{
        .top{
            .linksContainer{
                padding-left: 0px;
                .links{
                    padding: 10px;
                }
            }
            
        }

    }
`