import React from "react";

export function LanguageOption({ language, languagesId, setLanguagesId }) {

  return (
    <label htmlFor={language.label} className="option">
      <input
        type="checkbox"
        id={language.value}
        name="language"
        value={language.value}
        defaultChecked={languagesId?.includes(language.value)}
        onChange={(e) => {
            let numberValue = parseInt(e.target.value, 10);
            if (e.target.checked) {
                setLanguagesId([...languagesId, numberValue]);
            } else {
                setLanguagesId(languagesId?.filter((cat) => cat !== numberValue));
            }
        }}
      />
      {language.label}
    </label>
  );
}

export function CategoriesOption({ category, categoriesId, setCategoriesId }) { 
  return (
    <label htmlFor={category.label} className="option">
      <input
        type="checkbox"
        id={category.value}
        name="language"
        value={category.value}
        onClick={(e) => {
            let numberValue = parseInt(e.target.value, 10);
            if (e.target.checked) {
                setCategoriesId([...categoriesId, numberValue]);
            } else {
                setCategoriesId(categoriesId?.filter((cat) => cat !== numberValue));
            }
        }}
        defaultChecked={categoriesId?.includes(category.value)}
      />
      {category.label}
    </label>
  );
}
