import { useEffect } from "react";
import { useContext } from "react";
import { createContext, useState } from "react";
import { CartAPI } from "../api/collaboration/cart";
import { UserContext2 } from "./user/userContext";
import { UserTypes } from "../utils/constants/user";


export const CartContext = createContext();

export default function CartProvider({ children }) {

    const [shoppingCartCount, setShoppingCartCount] = useState(0);
    const [cart, setCart] = useState({ collaborations: []});
    const { userType, userToken, userData } = useContext(UserContext2);
    const [cartTotal, setCartTotal] = useState(0);

    const [discountCode, setDiscountCode] = useState('');
    const [cuponApply, setCuponApply] = useState(false);

    useEffect(() => {
        if (userType === UserTypes.COMPANY) {
            CartAPI.getCart(userData?.id).then((response) => {
                if(response)
                    setCart(response);
                else 
                    setCart({ collaborations: [] });
            }).catch((error) => {
                console.error(error);
                setCart({ collaborations: [] });
            });
        } else {
            setCart({ collaborations: [] });
        }

    }, [userData])

    useEffect(() => {
        setShoppingCartCount(cart.collaborations.length)
    }, [cart]);

    const getCartId = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (userType === UserTypes.COMPANY) {
                    if (cart?.id) {
                        resolve(cart.id);
                    } else {
                        const response = await CartAPI.createCart(userData?.id);
                        setCart(response);
                        resolve(response.id);
                    }
                } else {
                    reject(new Error("El tipo de usuario no es una empresa."));
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    

    return (
        <CartContext.Provider value={{ shoppingCartCount, setShoppingCartCount, cart, setCart, cartTotal, setCartTotal, discountCode, setDiscountCode, cuponApply, setCuponApply, getCartId }}>
            {children}
        </CartContext.Provider >
    )

};