import axios from 'axios';
import { API_GATEWAY_URL } from '../../utils/constants/constants';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user';

export const SignUpAPI = {
    // Read
    createUser: async (userData) => {
        const response = await axios.post(`${BASE_URL}/user/create`, userData);
        return response.data;
    },
};