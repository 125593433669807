import styled from "styled-components";
import starColor from './../../utils/images/star_color.png';
import starWhite from './../../utils/images/star_white.png';

export const LikeInfluencerContainer = styled.div`
  display: inline-block;
  cursor: pointer;

  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
  }

  img.favorite {
    &:hover {
      content: url(${starColor});
    }
  }

  img:not(.favorite) {
    &:hover {
      content: url(${starColor});
    }
  }
`;
