import React, { useState, useContext, useEffect } from "react";
import { ModalScoreCollaborationContainer, Profile } from "./ModalScoreCollaborationStyled";
import { FormattedMessage } from 'react-intl';

import { CollaborationsContext } from "../../context/collaborationsContext";

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useRef } from "react";
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";
import { CollaborationAPI } from "../../api/collaboration/collaboration";
import avatar from './../../utils/images/avatar_fluincr.png';
import { LangContext } from "../../context/langContext";
import { Button } from "@mui/material";
import { colors } from "../../utils/styles/colors";
import { replaceQuestionParams } from "../../utils/common/survey";
import { CollaborationSurveyAPI } from "../../api/collaboration/collaborationSurvey";

export default function ModalScoreCollaboration({ setShowRatePending }) {

    const cardRef = useRef(null);

    const { locale } = useContext(LangContext);

    const { ratePending, setRatePending } = useContext(CollaborationsContext);
    const { userType, userToken } = useContext(UserContext2);

    const [rate, setRate] = useState();
    const [rated, setRated] = useState(false);

    const {
        user,
        questions,
        influencer_id: influencerId,
        company_id: companyId
    } = ratePending ?? {};

    const [questionIndex, setQuestionIndex] = useState(0);

    useEffect(() => {

        function handleClickOutside(event) {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setShowRatePending(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [cardRef]);

    useEffect(() => {
        if(rated && questions?.length === questionIndex){
            setShowRatePending(false);
        }
    }, [questionIndex]);

    const sendRate = (event, newValue) => {
        CollaborationAPI.rateCollaboration(user?.collaboration_id, newValue).then((response) => {
            if(questions?.length === 0){
                setRatePending(null);
                setShowRatePending(false);
            } else {
                setRated(true);
            }
        }).catch((error) => {
            console.error(error);
            setRatePending(null);
            setShowRatePending(false);
        });
    }

    const saveResponse = (response) => {
        const responseDTO = {
            collaboration_id: user?.collaboration_id,
            influencer_id: influencerId,
            company_id: companyId,
            response: response,
            question_id: questions[questionIndex]?.id
        }

        CollaborationSurveyAPI.saveCollaborationSurveyResponse(user?.collaboration_id, responseDTO).then((response) => {
            setQuestionIndex(questionIndex + 1);
        }).catch((error) => {
            console.error(error);
            setShowRatePending(false);
        });
    }

    const questionsParams = {
        INFLUENCER_NAME: `${user?.name} ${user?.family_name}`
    }

    const getQuestionsText = () => {
        const question = questions[questionIndex];
        if(!question) return '';
        const text = locale === 'es-CO' ? 
            replaceQuestionParams(question?.pregunta, questionsParams) : 
            replaceQuestionParams(question?.question, questionsParams);
        return text;
    }


    return (
        <ModalScoreCollaborationContainer>

            <div className="scoreBody" ref={cardRef}>
                <Profile image={(user?.image_profile) ?? avatar } />

                {
                    !rated ?
                        <>
                            <h2>
                                <FormattedMessage id="modal_score_collaboration_title" defaultMessage="Colaboración con" /> 
                                <span>
                                    {
                                        (userType === UserTypes.INFLUENCER) ? 
                                            (user?.name)
                                            : 
                                            `${user?.name} ${user?.family_name}`
                                    }
                                </span>
                            </h2>
                            <p className="textScore"><FormattedMessage id="modal_score_collaboration_text" defaultMessage="¿Cómo calificas tu colaboración con" /> <span>{(userType === UserTypes.INFLUENCER) ? (user?.name) : `${user?.name} ${user?.family_name}`}</span>?</p>
                            <div className="score">
                                <Stack spacing={1}>
                                    {/* <Rating name="size-large" defaultValue={2} size="large" precision={0.5} /> */}

                                    <Rating size="large" value={rate} onChange={(event, newValue) => {
                                        sendRate(event, newValue)
                                    }}
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="65.839" height="61.243" viewBox="0 0 65.839 61.243">
                                            <path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star" d="M64.147,24.055H43.022L36.6,4.9a2.326,2.326,0,0,0-4.366,0L25.817,24.055H4.548a2.3,2.3,0,0,0-2.3,2.3,1.689,1.689,0,0,0,.043.388,2.208,2.208,0,0,0,.962,1.623L20.618,40.6,13.954,59.973a2.3,2.3,0,0,0,.79,2.585,2.222,2.222,0,0,0,1.293.56,2.815,2.815,0,0,0,1.436-.517L34.419,50.523,51.366,62.6a2.691,2.691,0,0,0,1.436.517,2.063,2.063,0,0,0,1.278-.56,2.276,2.276,0,0,0,.79-2.585L48.206,40.6,65.425,28.249l.416-.359a2.409,2.409,0,0,0,.747-1.537A2.432,2.432,0,0,0,64.147,24.055Z" transform="translate(-1.5 -2.625)" fill="#E8339E" stroke="#605858" strokeWidth="1.5" />
                                        </svg>}
                                        emptyIcon={<svg xmlns="http://www.w3.org/2000/svg" width="65.839" height="61.243" viewBox="0 0 65.839 61.243">
                                            <path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star" d="M64.147,24.055H43.022L36.6,4.9a2.326,2.326,0,0,0-4.366,0L25.817,24.055H4.548a2.3,2.3,0,0,0-2.3,2.3,1.689,1.689,0,0,0,.043.388,2.208,2.208,0,0,0,.962,1.623L20.618,40.6,13.954,59.973a2.3,2.3,0,0,0,.79,2.585,2.222,2.222,0,0,0,1.293.56,2.815,2.815,0,0,0,1.436-.517L34.419,50.523,51.366,62.6a2.691,2.691,0,0,0,1.436.517,2.063,2.063,0,0,0,1.278-.56,2.276,2.276,0,0,0,.79-2.585L48.206,40.6,65.425,28.249l.416-.359a2.409,2.409,0,0,0,.747-1.537A2.432,2.432,0,0,0,64.147,24.055Z" transform="translate(-1.5 -2.625)" fill="none" stroke="#605858" strokeWidth="1.5" />
                                        </svg>} />
                                </Stack>
                            </div>
                        </>
                        :
                        <>
                            {(questions?.length > 0 && questionIndex < questions?.length) &&
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: getQuestionsText()}} className="questionText"></div>
                                    <div className="options">
                                        <Button 
                                            vartiant="text" 
                                            color="inherit" 
                                            onClick={() => saveResponse('YES')}
                                        >{locale === 'es-CO' ? 'Sí' : 'Yes'}</Button>
                                        <Button vartiant="text" color="inherit" onClick={() => {saveResponse('NO')}}>No</Button>
                                    </div>
                            
                                </>
                             }   
                        </>
                }

            </div>

        </ModalScoreCollaborationContainer>
    )
}