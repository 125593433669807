import { normalizeString } from "./format";

export const getCountriesByInput = (countrySelectList, input) => {
  const normalizedInput = normalizeString(input.toLowerCase());

  return countrySelectList
    .filter((country) =>
      normalizeString(country.label.toLowerCase()).includes(normalizedInput)
    )
    .map((country) => country.value);
};

export const getCategoriesByInput = (categorySelectList, input) => {
  const normalizedInput = normalizeString(input.toLowerCase());

  return categorySelectList
    .filter((category) =>
      normalizeString(category.label.toLowerCase()).includes(normalizedInput)
    )
    .map((category) => category.value);
};