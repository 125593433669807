import React, { useEffect, useState } from "react";
import VideoThumbnailSelector from "../VideoThumbnailSelector/VideoThumbnailSelector";
import { ModalVideoCoverSelectorContainer } from "./ModalVideoCoverSelectorStyled";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ImageUploading from 'react-images-uploading';
import { uploadImageS3 } from "../../utils/common/s3";
import Spinner from "../Spinner/Spinner";

export default function ModalVideoCoverSelector({ setShowModalVideoCoverSelector, itemId, account, userToken, source, setItemCover }) {
    const [capturedImage, setCapturedImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const maxNumber = 1;

    useEffect(() => {
        if (images.length > 0) {
            setUploadedImage(images[0]);
        }
    }, [images]);

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const removeImage = () => {
        setUploadedImage(null);
        setImages([]);
    }

    const saveCover = () => {
        console.log('saveCover', capturedImage?.data)
        console.log('saveCover uploaded image', uploadedImage)
        const image = uploadedImage ?? capturedImage;

        if(image) {
            setLoading(true);

            const imageBody = uploadedImage ? 
            {
                base64File: image.data_url.split(',')[1],
                formatFile: image?.file?.type?.split('/')[1] || 'png',
                address: `fluincr/${account}/collaboration`
            } : {
                base64File: capturedImage.split(',')[1],
                formatFile: 'png',
                address: `fluincr/${account}/collaboration`
            }

            //TODO: Revisar esto
            const imageFile = uploadedImage ? image.file : capturedImage;

            uploadImageS3(imageBody.address, 'image/' + imageBody.formatFile, image.file).then((fileLink) => {
                setShowModalVideoCoverSelector(false);
                setLoading(false);
                setItemCover(fileLink);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        }
    }

    return (
        <ModalVideoCoverSelectorContainer>
            <div className="card">
                <div className="cardHeader">
                    <div className="cardTitleContainer">
                        <h5 className="cardTitle">
                            <FormattedMessage id="videoCoverSelectorCover" defaultMessage="Portada" />
                        </h5>
                    </div>
                    {
                        (!uploadedImage) ? 
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    <div className="uploadCoverButtonContainer upload__image-wrapper">
                                        <Button className="uploadCoverButton" variant="text" size="medium" onClick={onImageUpload}><FormattedMessage id="videoCoverSelectorButton" defaultMessage="Agregar del rollo fotográfico" /></Button>
                                    </div>
                                )}
                            </ImageUploading> : null
                    }
                </div>
                <div className="text">
                    <FormattedMessage id="videoCoverSelectorText" defaultMessage="Elige cómo se mostrará tu video a los demás. Selecciona un fotograma del video o una foto de tu rollo fotográfico como foto de portada." />
                </div>
                {
                    (!uploadedImage) ? 
                        <VideoThumbnailSelector source={source} onCapture={(image) => setCapturedImage(image)} /> :
                        <>
                            <img src={uploadedImage['data_url']} alt="" width={318} height={318} style={{padding: '1em', objectFit: 'contain'}}/>
                            <Button variant="text" onClick={removeImage}><FormattedMessage id="videoCoverSelectorRemove" defaultMessage="Remover" /></Button>
                        </>
                }

                <Button className="saveButton" variant="contained" size="large" onClick={() => saveCover()}><FormattedMessage id="videoCoverSelectorSave" defaultMessage="Guardar" /></Button>
            </div>

            {(loading) && <Spinner />}
        </ModalVideoCoverSelectorContainer>
    )
}