
import facebook from './../images/networks/facebook.png';
import instagram from './../images/networks/instagram.png';
import tiktok from './../images/networks/tiktok.png';
import youtube from './../images/networks/youtube-white-icon.png';

export const SocialNetworkId = {
    FACEBOOK: 1,
    INSTAGRAM: 2,
    YOUTUBE: 3,
    TIKTOK: 4,
}

export const SocialNetworkName = {
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    YOUTUBE: 'YOUTUBE',
    TIKTOK: 'TIKTOK',
    TWITTER: 'TWITTER',
    LINKEDIN: 'LINKEDIN',
}

export const getSocialNetworkIconBySocialNetworkId = (socialNetworkId) => {
    switch (socialNetworkId) {
        case SocialNetworkId.FACEBOOK:
            return facebook;
        case SocialNetworkId.INSTAGRAM:
            return instagram;
        case SocialNetworkId.YOUTUBE:
            return youtube;
        case SocialNetworkId.TIKTOK:
            return tiktok;
        default:
            return facebook;
    }
}