import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MyCollaborationsContainer, ProfileImage } from "./MyCollaborationsStyled";
import { FormattedMessage } from 'react-intl';

import { CollaborationsContext } from "../../context/collaborationsContext";

import back from './../../utils/images/arrow_back.svg';
import avatar from './../../utils/images/avatar_fluincr.png';

import CollaborationItem from "./CollaborationItem";
import Spinner from "../../components/Spinner/Spinner";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";


export default function MyCollaborations() {

    const navigate = useNavigate();

    const { userData } = useContext(UserContext2);
    const isAdmin = sessionStorage.getItem('isAdmin');

    const [isLoading, setIsLoading] = useState(false);

    const { userActiveCollaborations, userFinishedCollaborations, loadCollaborations } = useContext(CollaborationsContext);

    useEffect(() => {
        loadCollaborations(setIsLoading);
    }, [userData])

    const handlerSeeCollaboration = (collaborationId) => {
        navigate(`${collaborationId}`)
    }


    return (
        <MyCollaborationsContainer>
            <hr />
            <div className="goBack" onClick={() => navigate(-1)}>
                <img src={back} alt="back" className="backIcon" />
                <p className="backText" ><FormattedMessage id="go_back" defaultMessage="ir atrás" /></p>
            </div>
            
            <>
                <section>
                    <h3><FormattedMessage id="my_collaborations_title_1" defaultMessage="Colaboraciones actuales" /></h3>

                    <table>
                        {(userActiveCollaborations.length > 0) && (userActiveCollaborations?.map((collaboration) => {
                            return (
                                <CollaborationItem collaboration={collaboration} handlerSeeCollaboration={handlerSeeCollaboration} key={collaboration.id} />
                            )
                        }))}

                    </table>

                    {(userActiveCollaborations?.length < 1) && (<div className="notCollaborations">
                        <p><FormattedMessage id="my_collaborations_text_1" defaultMessage="No tienes colaboraciones activas" /></p>
                    </div>)}

                </section>
                <section >
                    <h3><FormattedMessage id="my_collaborations_title_2" defaultMessage="Colaboraciones pasadas" /></h3>
                    <table className="pastCollaborations">
                        {(userFinishedCollaborations.length > 0) && (userFinishedCollaborations?.map((collaboration) => {
                            return (
                                <CollaborationItem collaboration={collaboration} handlerSeeCollaboration={handlerSeeCollaboration} />)
                        }))}

                    </table>
                    {(userFinishedCollaborations?.length < 1) && (<div className="notCollaborations">
                        <p><FormattedMessage id="my_collaborations_text_2" defaultMessage="No tienes colaboraciones pasadas" /></p>
                    </div>)}

                </section>
            </>
               
            {(isLoading) && <Spinner />}

        </MyCollaborationsContainer>
    )
}