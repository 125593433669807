import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  ListItem,
  ListItemText,
  Box,
  Button,
  ListItemIcon,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FiltersContext } from "../../../context/filtersContext";
import { colors } from "../../../utils/styles/colors";
import avatar from "./../../../utils/images/avatar_fluincr.png";
import search from './../../../utils/images/search.png';
import { getCountriesByInput } from "../../../utils/common/filter";
import { StaticDataContext } from "../../../context/staticDataContext";
import { InfluencerAPI } from "../../../api/user/influencer/influencer";
import { getSocialNetworkIconBySocialNetworkId } from "../../../utils/constants/socialNetwork";
import { formatFollowers } from "../../../utils/common/format";
import '../../../Bootstrap.css';
import { FormattedMessage } from "react-intl";
import { capitalize } from "../../../services/generalSevices";

const SearchDropdown = ({}) => {
  const navigate = useNavigate();
  const { inputFilter, setInputFilter } = useContext(FiltersContext);
  const { countrySelectList, categorySelectList } = useContext(StaticDataContext);

  const [influencersSearchList, setInfluencersSearchList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

  const onResultClick = (influencer) => {
    setOpen(false);
    navigate(`/${influencer?.username}`);
  };

  const onSeeMoreClick = () => {
    setOpen(false);
    setInputFilter(searchText);
    navigate(`/influencers`);
  };

  const handlerFilter = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setInputFilter(searchText);
    setOpen(false);
    navigate('/influencers')
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText && searchText.length > 2) {
        searchInfluencers(searchText);
      } else if (searchText === "") {
        setInfluencersSearchList([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const searchInfluencers = async (search) => {
    const countriesSearch = getCountriesByInput(countrySelectList, search).join(",");
    const categoriesSearch = getCountriesByInput(categorySelectList, search).join(",");

    if(searchText.length < 3) 
        return;

    let filters = {
      ...(searchText && searchText.length > 2 && { searchText: encodeURIComponent(searchText) }),
      ...(categoriesSearch && { categoriesSearch: categoriesSearch }),
      ...(countriesSearch && { countriesSearch: countriesSearch }),
    };

    setIsLoading(true);
    InfluencerAPI.getAllInfluencersFiltered(filters)
      .then((response) => {
        setIsLoading(false);
        setInfluencersSearchList([...response?.content]);
        setOpen(true);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
        setOpen(false);
      });
  };

  const getInfluencerCategoriesLabel = (influencer) => {
    let categories = [];
    influencer?.categories?.slice(0, 2).forEach((categoryId) => {
      categories.push(
        ('#' + categorySelectList.find((cat) => cat.value === categoryId)?.label)
      );
    });

    return categories.join(" ");
  }

  const getInfluencerAddress = (influencer) => {
    const countryName = countrySelectList.find((country) => country.value === influencer?.address?.country_id)?.label;
    return countryName ? `${countryName}, ${capitalize(influencer?.address?.city)}` : `${capitalize(influencer?.address?.city)}`
  }

  const getMaxFollowers = (influencer) => {
    const mostFollowed = influencer?.social_networks?.reduce((max, network) => {
        const followers = network.followers || 0; // Si followers es null, lo consideramos como 0
        return followers > max.followers ? network : max;
    }, { followers: 0, social_network: 0 });

    return mostFollowed;
  }

  const SecondaryContent = ({ option }) => {
    const influencerAddress = getInfluencerAddress(option);
    const maxFollowers = getMaxFollowers(option);
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {influencerAddress ?? ''}
        {
            maxFollowers?.followers > 0 && (
                <div className="d-flex gap-1 rounded-pill me-0 ms-auto" style={{width: 'fit-content'}}>
                    <span style={{fontSize: '.875em!important'}}>{formatFollowers(maxFollowers?.followers)}</span>
                    <Box 
                        component="img"
                        src={getSocialNetworkIconBySocialNetworkId(maxFollowers?.social_network)} 
                        width={maxFollowers?.social_network <= 2 ? 14 : 16} 
                        alt="social network icon"
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'auto', 
                        filter: 'grayscale(70%) brightness(0.6)' 
                        }}
                    />
                    </div>
            )
        }
      </Box>
    );
  };

  return (
    <div className="searchContainer">
    
        <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            open={open}
            onOpen={() => {
                if (searchText) {
                    setOpen(true);
                }
            }}
            onClose={() => {
                setOpen(false);
            }}
            options={influencersSearchList}
            getOptionLabel={(option) => searchText}
            inputValue={searchText}
            onInputChange={(event, value) => setSearchText(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className="inputSearch"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                        padding: 0,
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        },
                    }}
                    sx={{
                        width: "100% !important",
                        padding: "0 .25em !important",
                        "& .MuiInputBase-root": {
                        padding: 0,
                        },
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {

                            event.preventDefault();
                            handlerFilter(event);
                            setOpen(false);
                        }
                    }}
                />
            )}
            renderOption={(props, option) => (
                <>
                    <ListItem button key={option?.id * 400} sx={{ py:0, borderTop: '.5px solid #eee' }} onClick={() => onResultClick(option)}>
                        <ListItemIcon>
                            <img
                                src={option.image_profile || avatar}
                                alt={option.name}
                                style={{ width: "2.5em", height: "2.5em", borderRadius: "50%" }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            sx={{
                                whiteSpace: 'nowrap', // Evita que el texto se divida en varias líneas
                                overflow: 'hidden', // Oculta cualquier texto que desborde del contenedor
                                textOverflow: 'ellipsis', // Muestra puntos suspensivos (...) cuando el texto desborda
                            }}
                            primary={`${option?.name} ${option?.family_name}`}
                            secondary={<SecondaryContent option={option} />}
                        />
                    </ListItem>
                </>
                
            )}
            ListboxProps={{
                style: {
                maxHeight: "250px",
                overflow: "auto",
                },
            }}
            popupIcon={null}
            PaperComponent={({ children }) => (
                <Paper>
                    <Box
                        sx={{ backgroundColor: "#fff", mt: ".625em", borderRadius: ".25em",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", minWidth: "220px !important",
                            overflow: "hidden"
                        }}
                    >
                        {!isLoading && influencersSearchList.length > 0 && (
                            <>
                                {children}

                                {influencersSearchList?.length > 0 && true && (
                                    <ListItem sx={{ p: 0, borderTop: '.5px solid #eee' }}>
                                        <Button
                                            onClick={(e) => {
                                                onSeeMoreClick();
                                            }}
                                            variant="text"
                                            sx={{
                                                color: colors.hightlight,
                                                margin: "auto",
                                                mr: 0,
                                                textTransform: "none",
                                                borderTop: ".5px solid #eee",
                                                zIndex: 100
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <FormattedMessage id="searchDropdownSeeMore" defaultMessage="Ver más" />
                                        </Button>
                                    </ListItem>
                                )}
                            </>
                        )}

                        {!isLoading && influencersSearchList.length === 0 && (
                            <ListItem sx={{ p: 2 }}>
                                <ListItemText primary="No se encontraron resultados" />
                            </ListItem>
                        )}

                        {isLoading && (
                            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                                <CircularProgress size={24} sx={{ color: colors.hightlight }}/>
                            </Box>
                        )}
                    </Box>
                </Paper>
                

            )}
        />
        <button className="searchButton">
            <img
                src={search}
                alt="search"
                className="searchIcon"
                onClick={handlerFilter}
            />
        </button>
    </div>
  );
};

export default SearchDropdown;
