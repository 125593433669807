import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const ResetPasswordContainer = styled.div`

    background-color: ${colors.modalBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    padding: 50px 0;
    min-height: calc(100vh - 575px);
    color: ${colors.title};
    .resetHeader{
        display: flex;
        color: ${colors.title};
        margin-bottom: 30px;
        h2{
            font-weight: 600;
            font-size:2.5rem;
        }
    }
    form{
        width: 525px;
        background-color: white;
        border-radius: 20px;
        padding: 40px 30px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .firstText{
            text-align: center;
            font-size: 1.5rem;
        }
        .email{
            width: 100%;
            outline: none;
            border: 1px solid ${colors.grey};
            font-family: 'Open Sans', sans-serif;
            padding: 10px;
            border-radius: 10px;
            margin-top: 35px;
            font-size: 1.5rem;
            ::placeholder{
                font-weight: bold;
                color: rgba(112,112,112,0.51);
            }
        }
        .error{
            color: red;
            font-size: 1.2rem;
        }
        .resetButton{
            padding: 10px 20px;
            background-color: ${colors.hightlight};
            color: #ffffff;
            border-radius: 10px;
            border: none;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 10px;
            margin-top: 35px;
            font-size: 1.6rem;
        }
    }

    .goBack{
        display: flex;
        margin-top: 20px;
        width: 290px;
        width: 520px;
        text-align: end;
        font-size: 0.85rem;
        font-size: 1.2rem;
        cursor: pointer;
        img{
            width: 8px;
            margin-right: 10px;
        }
    }

    /**-----------Responsive-------------- */
    ${media.largeDesktop}{
        .resetHeader{
            h2{
                font-size:2.3rem;
            }
        }
        form{
            width: 510px;
            .firstText{
                font-size: 1.4rem;
            }
            .email{
                font-size: 1.4rem;
            }
            .error{
                font-size: 1rem;
            }
            .resetButton{
                font-size: 1.6rem;
            }
        }
        .goBack{
            width: 505px;
            font-size: 1.1rem;
        }
    }

    ${media.specialScreen}{
        .resetHeader{
            h2{
                font-size:2.1rem;
            }
        }
        form{
            width: 420px;
            .firstText{
                font-size: 1.2rem;
            }
            .email{
                font-size: 1.2rem;
                margin-top: 25px;
            }
            .error{
                font-size: 0.9rem;
            }
            .resetButton{
                font-size: 1.3rem;
                margin-top: 25px;
            }
        }
        .goBack{
            width:415px;
            font-size: 1rem;
        }
    }

    ${media.macdesktop}{
        .resetHeader{
            h2{
                font-size:1.9rem;
            }
        }
        form{
            width: 390px;
            .firstText{
                font-size: 1.1rem;
            }
            .email{
                font-size: 1.1rem;
            }
            .error{
                font-size: 0.8rem;
            }
            .resetButton{
                font-size: 1.2rem;
            }
        }
        .goBack{
            width: 385px;
            font-size: 0.9rem;
        }
    }

    ${media.desktop}{
        .resetHeader{
            h2{
                font-size:1.8rem;
            }
        }
        form{
            width: 385px;
            .firstText{
                font-size: 1.1rem;
            }
            .email{
                font-size: 1rem;
                ::placeholder{
                    font-size: 1rem;
                }
            }
            .error{
                font-size: 0.8rem;
            }
            .resetButton{
                font-size: 1.1rem;
            }
        }
        .goBack{
            width: 380px;
            font-size: 0.9rem;
        }
    }

`