import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FiltersContext } from "../../context/filtersContext";
import { FilterCardContainer } from "./FilterCardStyled";


export default function FilterCard({ item, type }) {

    const navigate = useNavigate();

    const { categoryFilter, networkFilter, setNetworkFilter, setCategoryFilter } = useContext(FiltersContext);

    const handlerCategoryFilter = (categoryId) => {
        const auxCategoryFilter = [...categoryFilter];
        if (auxCategoryFilter.includes(categoryId)) {
            const newCategory = auxCategoryFilter.filter(cat => cat !== categoryId);
            setCategoryFilter(newCategory)
            navigate('/influencers')
        } else {
            auxCategoryFilter.push(categoryId);
            setCategoryFilter(auxCategoryFilter);
            navigate('/influencers')
        }
    }

    const handlerNetworkFilter = (network) => {

        const auxNetworkFilter = [...networkFilter];

        if (auxNetworkFilter.includes(network)) {
            const newNetwork = auxNetworkFilter.filter(net => net !== network);
            setNetworkFilter(newNetwork);
            navigate('/influencers');

        } else {
            auxNetworkFilter.push(network);
            setNetworkFilter(auxNetworkFilter);
            navigate('/influencers');
        }
    }

    return (
        <FilterCardContainer image={item?.image} onClick={() => {
            if (type === 'category') {
                handlerCategoryFilter(item?.value)
            } else {
                handlerNetworkFilter(item?.label)
            }

        }}>
            <div className="degrade">
                <p className="text">{item?.label}</p>
            </div>
        </FilterCardContainer>
    )
}