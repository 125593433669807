import axios from 'axios';
import { API_GATEWAY_URL } from '../../../utils/constants/constants';
import axiosInstance from '../../interceptor/axiosInterceptor';

const BASE_URL = API_GATEWAY_URL + '/fluincr-user';

export const GalleryAPI = {

    addGalleryItem: async (galleryImageUrl) => {
        const response = await axiosInstance.post(`${BASE_URL}/gallery-item`, {url: galleryImageUrl});
        return response.data;
    },

    deleteGalleryItem: async (galleryImageId) => {
        const response = await axiosInstance.delete(`${BASE_URL}/gallery-item/${galleryImageId}`);
        return response.data;
    }
};