import React, { useEffect } from "react";
import { MyProfileProductsContainer, ProductCard } from "./MyProfileProductsStyled";
import { FormattedMessage, useIntl } from 'react-intl';

import camera from './../../utils/images/camera.svg';
import edit from './../../utils/images/edit_grey.svg';
import ModalAddProduct from "../ModalAddProduct/ModalAddProduct";
import { useState } from "react";
import { useContext } from "react";
import ModalEditProduct from "../ModalEditProduct/ModalEditProduct";
import { UserContext2 } from "../../context/user/userContext";

export default function MyProfileProducts() {

    const [showAddProduct, setShowAddProduct] = useState(false);
    const [showEditProduct, setShowEditProduct] = useState(false);

    const { userData } = useContext(UserContext2);

    const [products, setProducts] = useState([]);

    const [product, setProduct] = useState();

    useEffect(() => {
        if (userData) {
            setProducts(userData?.profile_data?.products || [])
        }
    }, [userData])

    return (
        <MyProfileProductsContainer>
            <h2 className="title"><FormattedMessage id="my_profile_subtitle_1" defaultMessage="Productos/servicios" /></h2>
            <div className="cardsContainer">
                {products.map((product) => {
                    return <div className="productContainer" key={product.id}>
                        <img src={edit} alt="" className="edit" onClick={() => {
                            setProduct(product)
                            setShowEditProduct(true)
                        }} />
                        <ProductCard image={product.image} />
                    </div>
                })}
                <div className="card" onClick={() => { setShowAddProduct(true) }}>
                    <img src={camera} alt="camera" />
                    <p><FormattedMessage id="my_profile_text_11" defaultMessage="Cargar nuevo producto" /></p>
                </div>
                {(products.length < 1) && (<div className="card" onClick={() => { setShowAddProduct(true) }}>
                    <img src={camera} alt="camera" />
                    <p><FormattedMessage id="my_profile_text_11" defaultMessage="Cargar nuevo producto" /></p>
                </div>)}
                {(products.length < 2) && (<div className="card" onClick={() => { setShowAddProduct(true) }}>
                    <img src={camera} alt="camera" />
                    <p><FormattedMessage id="my_profile_text_11" defaultMessage="Cargar nuevo producto" /></p>
                </div>)}
                {(products.length < 3) && (<div className="card last" onClick={() => { setShowAddProduct(true) }}>
                    <img src={camera} alt="camera" />
                    <p><FormattedMessage id="my_profile_text_11" defaultMessage="Cargar nuevo producto" /></p>
                </div>)}

            </div>

            {(showAddProduct) && (<ModalAddProduct setShowAddProduct={setShowAddProduct} />)}

            {(showEditProduct) && (<ModalEditProduct setShowEditProduct={setShowEditProduct} product={product} />)}

        </MyProfileProductsContainer>
    )
}