import styled from "styled-components";
import { colors } from "../../utils/styles/colors";
import { media } from "../../utils/styles/media";

export const MyProfileGalleryContainer = styled.div`

    margin-top: 30px;
    .title{
        color: ${colors.title};
        margin-bottom: 20px;
        font-size: 3.5rem;
    }

    .cardsContainer{
        display: flex;
        flex-wrap: wrap;
        /* .upload__image-wrapper{
            width: 23%;
            height: 21vw;
            //margin: 0.5%;
            margin-bottom: 20px;
            margin-right: 2%;
            
            .imageUploadButton{
                width: 100%;
                height: 100%;
                padding: 0;
                border: none;
                cursor: pointer;
                .card{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: ${colors.greyIcon};
                    margin-bottom: 20px;
                    padding: 80px;
                    text-align: center;
                    font-size: 2rem;
                    font-weight: 600;
                    font-family: 'Open Sans',sans-serif;
                    color: ${colors.greyPhotoPicker};
                    img{
                        width: 100px;
                        margin-bottom: 10px;
                    }
                    
                }
            }
        } */

        .uploadImage{
            width: 23%;
            height: 21vw;
            margin-bottom: 20px;
            margin-right: 2%;
            input[type="file"]{
                display: none;
            }
            .card{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: ${colors.greyIcon};
                margin-bottom: 20px;
                padding: 80px;
                text-align: center;
                font-size: 2rem;
                font-weight: 600;
                font-family: 'Open Sans',sans-serif;
                color: ${colors.greyPhotoPicker};
                cursor: pointer;
                img{
                    width: 100px;
                    margin-bottom: 10px;
                }    
            }
        }

        .imageContainer{
            width: 23%;
            height: 21vw;
            margin-bottom: 20px;
            margin-right: 2%;
            position: relative;
            .delete{
                width: 45px;
                height: 45px;
                position: absolute;
                right: 0;
                margin: 10px;
                cursor: pointer;
            }
        }
        
    }

    /**--------Responsive--------- */
    ${media.largeDesktop}{
        .title{
            font-size: 2.6rem;
        }

        .cardsContainer{
            /* .upload__image-wrapper{
                .imageUploadButton{
                    .card{
                        font-size: 1.5rem;
                        img{
                            width: 80px;
                            margin-bottom: 8px;
                        }
                        
                    }
                }
            } */

            .uploadImage{
                .card{
                    font-size: 1.5rem;
                    img{
                        width: 80px;
                        margin-bottom: 8px;
                    }  
                }
            }

            .imageContainer{
                .delete{
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }

    ${media.specialScreen}{
        .cardsContainer{
            /* .upload__image-wrapper{
                .imageUploadButton{
                    .card{
                        font-size: 1.1rem;
                        img{
                            width: 60px;
                            margin-bottom: 5px;
                        }
                        
                    }
                }
            } */
            .uploadImage{
                .card{
                    font-size: 1.1rem;
                    img{
                        width: 60px;
                        margin-bottom: 5px;
                    }  
                }
            }
            .imageContainer{
                .delete{
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    ${media.desktop}{
        .title{
            font-size: 2.3rem;
        }
        .cardsContainer{
            /* .upload__image-wrapper{
                .imageUploadButton{
                    .card{
                        font-size: 1rem;
                        img{
                            width: 50px;
                            margin-bottom: 5px;
                        }
                        
                    }
                }
            } */
            .uploadImage{
                .card{
                    font-size: 1rem;
                    img{
                        width: 50px;
                        margin-bottom: 5px;
                    }  
                }
            }
            .imageContainer{
                .delete{
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    ${media.smallDesktop}{
        .title{
            font-size: 2.1rem;
        }
        .cardsContainer{
            /* .upload__image-wrapper{
                .imageUploadButton{
                    .card{
                        padding: 50px;
                        img{
                            width: 45px;
                        }
                        
                    }
                }
            } */
            .uploadImage{
                .card{
                    padding: 50px;
                    img{
                        width: 45px;
                    }  
                }
            }
        }
    }
    ${media.tablet}{
        .title{
            font-size:2.3rem;
        }
        .cardsContainer{
            /* .upload__image-wrapper{
                width: 31%;
                height: 28vw;
                margin-bottom: 1%;
                .imageUploadButton{
                    .card{
                        padding: 40px;
                    }
                }
            }
            .upload__image-wrapper:nth-child(8){
                display: none;
            }
            .upload__image-wrapper:nth-child(6){
                display: none;
            } */
            .imageContainer{
                width: 31%;
                height: 28vw;
            }
            .uploadImage{
                width: 31%;
                height: 28vw;
                margin-bottom: 1%;
                .card{
                    padding: 40px;  
                }
            } 
        }
    }
    ${media.largeMobile}{
        .title{
            font-size:1.8rem;
        }
        .cardsContainer{
            /* .upload__image-wrapper{
                width: 48%;
                height: 40vw;
                margin-bottom: 1%;
                .imageUploadButton{
                    .card{
                        padding: 30px;
                        font-size: 0.9rem;
                        img{
                            width: 35px;
                        }
                    }
                }
            }
            .upload__image-wrapper:nth-child(8){
                display: none;
            }
            .upload__image-wrapper:nth-child(6){
                display: none;
            } */
            .uploadImage{
                width: 48%;
                height: 40vw;
                margin-bottom: 1%;
                .card{
                    padding: 30px;
                    font-size: 0.9rem;
                    img{
                        width: 35px;
                    }
                }
            }
            .imageContainer{
                width: 48%;
                height: 40vw;
                .delete{
                    width: 22px;
                    height: 22px;
                }
            }           
        }

    }

`

export const ImageGallery = styled.div`
    width: 100%;
    height: 100%;
    background-image:url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

`