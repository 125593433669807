import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { CompaniesContainer } from "./CompaniesStyled";
import { FormattedMessage } from 'react-intl';
import { Radio } from "@mui/material";
import FilterMenuCompanies from ".//FilterMenuCompanies";
import Divider from '@mui/material/Divider';


import back from './../../utils/images/arrow_back.svg';
import dropDownPink from './../../utils/images/arrow_down_pink.svg';
import dropDownPinkUp from './../../utils/images/arrow_down_pink_up.svg';

import { getAllCompanies } from "../../api/companyApi";

import CompanyCard from "../../components/CompanyCard/CompanyCard";
import { LangContext } from "../../context/langContext";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Spinner from "../../components/Spinner/Spinner";
import { InfluencerContext } from "../../context/user/influencerContext";
import { CompanyAPI } from "../../api/user/company/company";
import { FiltersContext } from "../../context/filtersContext";
import { colors } from "../../utils/styles/colors";
import FluincrDropdown from "../../components/Dropdown/FluincrDropdown";
import { UserTypes } from "../../utils/constants/user";
import { UserContext2 } from "../../context/user/userContext";


export default function Companies() {
    const orderByRef = useRef(null);
    const navigate = useNavigate();

    const { userData, userType } = useContext(UserContext2);
    const { companiesList, setCompaniesList } = useContext(InfluencerContext);
    const {
      featuredFilter,
      newUserFilter,
      languageFilter,
      ratingFilter,
      verifiedFilter,
      genderFilter,
      ageFilter,
      networkFilter,
      categoryFilter,
      countryFilter,
      priceFilter,
      setPriceFilter,
      queryPrice,
      inputFilter,
      orderBy,
      setOrderBy,
      favoriteFilter
    } = useContext(FiltersContext);
  
    const [companies, setCompanies] = useState([]);
  
    const [isLoading, setIsLoading] = useState(false);
  
    const CARDS_PER_PAGE = 12;
    const [offset, setOffset] = useState(0);
  
    const [total, setTotal] = useState(0);
  
    const [showOrderByMenu, setShowOrderByMenu] = useState(false);
  
    const seeMore = () => {
      const newOffset = offset + 1;
      setOffset(newOffset);
      updateCompanies(newOffset);
    };
  
    useEffect(() => {
        if(!userData){
            navigate('/signin');
        } else if(userType === UserTypes.COMPANY){
            navigate('/');
        }
    }, []);
  
    useEffect(() => {
      if(offset > 0){
        setOffset(0);
      }
        updateCompanies(offset);
    }, [
      newUserFilter,
      featuredFilter,
      languageFilter,
      ratingFilter,
      verifiedFilter,
      genderFilter,
      ageFilter,
      networkFilter,
      categoryFilter,
      countryFilter,
      priceFilter,
      inputFilter,
      orderBy,
      favoriteFilter
    ]);
  
    useEffect(() => {
      function handleClickOutside(event) {
        if (orderByRef.current && !orderByRef.current.contains(event.target)) {
          setShowOrderByMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [orderByRef]);
  
    const updateCompanies = (page) => {
      setIsLoading(true);
      const languageQueryString =
        languageFilter.length > 0 ? languageFilter.join(",") : null;
      const categoryQueryString =
        categoryFilter.length > 0 ? categoryFilter.join(",") : null;
      const countryQueryString =
        countryFilter.length > 0 ? countryFilter.join(",") : null;

      const companiesFavorite = userData?.likes ? userData?.likes : [];
      const likesProductsCompanies = userData?.likes_products ? userData?.likes_products.map(likeProduct => likeProduct.company_id) : [];
      const likesCompanies = [...companiesFavorite, ...likesProductsCompanies];

      const favoriteQueryString =
        favoriteFilter ? (likesCompanies ? likesCompanies?.join(",") : []) : null;
  
      let filters = {
        ...(featuredFilter && { featured: featuredFilter }),
        ...(languageQueryString && { languages: languageQueryString }),
        ...(countryQueryString && { countries: countryQueryString }),
        ...(categoryQueryString && { categories: categoryQueryString }),
        ...(ratingFilter && { rating: ratingFilter }),
        ...(verifiedFilter && { verified: verifiedFilter }),
        ...(favoriteFilter && { likes: favoriteQueryString }),
        page: page,
        size: CARDS_PER_PAGE,
      };
  
      CompanyAPI.getCompaniesFiltered(filters)
        .then((response) => {
          setCompanies(response.content);
          setCompaniesList(response.content);
          setTotal(response.totalElements);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  
    return (
      <CompaniesContainer>
        <div className="companiesBody">
          <div className="menu">
            <FilterMenuCompanies />
          </div>
  
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginX: 2 }}
          />
  
          <div className="companiesContent">
            <div className="results">
              <p className="totalResults">
                {companies?.length}{" "}
                <FormattedMessage
                  id="companies_text_1"
                  defaultMessage="resultados"
                />
              </p>
              <div className="orderBy" ref={orderByRef}>
                <div className="orderByTitle">
                  <FluincrDropdown
                    title={
                      <FormattedMessage
                        id="companies_text_2"
                        defaultMessage="Ordenar por"
                      />
                    }
                  >
                    <div>
                      <Radio
                        value="name"
                        checked={orderBy === "name"}
                        onChange={(event) => {
                          setOrderBy(event.target.value);
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: colors.hightlight,
                          },
                        }}
                      />
                      <label htmlFor="lowerRange">
                        <FormattedMessage
                          id="companies_subtext_2_1"
                          defaultMessage="Nombre"
                        />
                      </label>
                    </div>
                    <div>
                      <Radio
                        value="lowerRange"
                        checked={orderBy === "lowerRange"}
                        onChange={(event) => {
                          setOrderBy(event.target.value);
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: colors.hightlight,
                          },
                        }}
                      />
                      <label htmlFor="lowerRange">
                        <FormattedMessage
                          id="companies_subtext_2_2"
                          defaultMessage="Menor rango"
                        />
                      </label>
                    </div>
                    <div>
                      <Radio
                        value="higherRange"
                        checked={orderBy === "higherRange"}
                        onChange={(event) => {
                          setOrderBy(event.target.value);
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: colors.hightlight,
                          },
                        }}
                      />
                      <label htmlFor="higherRange">
                        <FormattedMessage
                          id="companies_subtext_2_3"
                          defaultMessage="Mayor rango"
                        />
                      </label>
                    </div>
                  </FluincrDropdown>
                </div>
              </div>
            </div>
  
            <div className="cards">
              {companies?.length > 0 &&
                companies?.map((company) => (
                  <CompanyCard company={company} key={company.id} />
                ))}
            </div>
  
            {total === "0" && (
              <div className="noResults">
                <p>
                  <FormattedMessage
                    id="influncer_no_results"
                    defaultMessage="No se han encontrado resultados"
                  />
                </p>
              </div>
            )}
  
            {(CARDS_PER_PAGE * (offset + 1) < total) && (<p className="seeMore" onClick={seeMore}><FormattedMessage id="companies_text_3" defaultMessage="Ver más +" /></p>)}
          </div>
        </div>
        {isLoading && <Spinner />}
      </CompaniesContainer>
    );
  }