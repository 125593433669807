import React, { useContext, useState } from "react";
import { FormattedMessage } from 'react-intl';
import avatar from './../../utils/images/avatar_fluincr.png';

import { CurrencyContext } from "../../context/currencyContext";
import { useEffect } from "react";

import { ProfileImage } from "./MyCollaborationsStyled";
import { formatDateLocal, formatTimeLocal, fromUTCtoLocal, frontUTCtoLocal, newFormat, newFormatDateLocal, toUTCString } from "../../services/dateServices";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../services/generalSevices";
import gift from './../../utils/images/gift.svg';
import '../../Bootstrap.css';
import { UserContext2 } from "../../context/user/userContext";
import { UserTypes } from "../../utils/constants/user";

export default function CollaborationItem({ collaboration, handlerSeeCollaboration }) {

    const { currency, convertCurrency } = useContext(CurrencyContext);
    const navigate = useNavigate();
    const auxCurrency = currency.symbol;
    const [collaborationPrice, setCollaborationPrice] = useState(null);
    const [giftValue, setGiftValue] = useState(null);
    const [totalCollaboration, setTotalCollaboration] = useState(null)

    const { userType } = useContext(UserContext2);

    useEffect(() => {
        setTotalCollaboration(0);

        const newCollaborationPrice = convertCurrency(collaboration.total_amount, auxCurrency)?.toFixed(2);
        setCollaborationPrice(newCollaborationPrice);

        const newGiftValue = convertCurrency(collaboration.gift_value, auxCurrency).toFixed(2);

        if(collaboration.gift){
            setGiftValue(newGiftValue);
        } else {
            setGiftValue(0);
        }
        
        setTotalCollaboration(prevTotal => (prevTotal ? prevTotal : 0) + (collaboration.gift ? parseFloat(newGiftValue) : 0));
    }, [currency])

    const renderNotification = ({ collaboration }) => {

        if (userType === UserTypes.INFLUENCER && collaboration.readinfluencer === false) {
            return (
                <div className="notification"></div>
            )
        }
        if (userType === UserTypes.COMPANY && collaboration.readcompany === false) {
            return (
                <div className="notification"></div>
            )
        }
    }

    return (
        <tr className="justify-content-between">
            <td className="justify-content-start">
                <div className="companyName w-100 justify-content-start">
                    {(userType === UserTypes.INFLUENCER) ?
                        (<> <ProfileImage image={collaboration?.company?.image_profile ?? avatar} onClick={() => { navigate(`/${collaboration?.company?.username}`) }} />
                            <p className="name" onClick={() => { navigate(`/${collaboration?.company?.username}`) }}>{collaboration?.company?.name} </p></>) :
                        (<> <ProfileImage image={collaboration.influencer.image_profile ?? avatar} onClick={() => { navigate(`/${collaboration.influencer.username}`) }} />
                            <p className="name" onClick={() => { navigate(`/${collaboration.influencer.username}`) }}>{collaboration.influencer.name + ' ' + collaboration.influencer.family_name}</p></>)}
                </div>
            </td>
            
            <td><p className="date">{newFormat(collaboration.creation_date)}</p></td>

            <td> <p className="product" onClick={() => { navigate(`/${collaboration.company?.username}`) }}>{collaboration.product?.name}</p></td>

            <td><div className="w-100 justify-content-center">#{collaboration.id}</div></td>

            <td className="">
                <div className="collaborationPrice justify-content-center">
                    <p className="price">${formatNumber(totalCollaboration)}&nbsp;</p>
                    <p className="price d-flex justify-content-center align-items-center price-detail">
                        (${formatNumber(collaborationPrice)} + ${formatNumber(giftValue)}&nbsp;
                        <img width={16} height={16} src={gift}/>)
                    </p>
                </div>
            </td>
            <td>
                <div className="collaborationPrice  justify-content-center">
                    <div className="collaborationButtonContainer">
                        <div className="position-relative">
                            <button onClick={() => {
                                handlerSeeCollaboration(collaboration.id)
                            }} className="collaborationButton"><FormattedMessage id="my_collaborations_button" defaultMessage="Ver colaboración" />
                                
                            </button>
                            {renderNotification({ collaboration })}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}