import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { FormattedMessage } from 'react-intl';
import FacebookLogo from "../../../../utils/images/networks/facebook.png";
import facebookGrey from '../../../../utils/images/facebook_grey.svg';

import '../../../../Bootstrap.css'
import './FacebookProfileLoginStyled.css'
import edit from '../../../../utils/images/edit_white.svg';

import FacebookProfileLoginModal from '../FacebookProfileLoginModal/FacebookProfileLoginModal';
import { UserContext2 } from '../../../../context/user/userContext';
import { FacebookAPI } from '../../../../api/social-network/facebook';

const FacebookProfileLogin = ({ setFacebookProfileLink, setFacebookFollowers  }) => {

    const { userToken, userType, userData, setUserData } = useContext(UserContext2);
    const navigate = useNavigate();

    const [showAlertError, setShowAlertError] = useState(false);
    const [openFacebookModal, setOpenFacebookModal] = useState(false);
    const manualLogin = true;

    const responseFacebook = (response) => {
        if (response?.error) {
            setShowAlertError(true);
        } else {
            /*getAccessFacebook({ token: userToken, facebookToken: response.accessToken, facebookId: response.userID, userType: userType }).then((responseAccess) => {
                if (responseAccess.status === 200) {
                    navigate('/auth/facebook')
                }

            })*/

            FacebookAPI.accessToken(userData?.id, response.accessToken, response.userID).then((facebookSocialNetworkAccount) => {
                setUserData({
                    ...userData,
                    social_networks: [
                        ...userData?.social_networks,
                        facebookSocialNetworkAccount
                    ]
                });
                navigate('/auth/facebook');
            }).catch(() => {
                navigate('/error')
            });
        }
    }

    return (
        ((!manualLogin) || ["I6948159"].includes(userData?.account)) ? 
            <FacebookLogin
                appId="923213175700938"
                fields="name,email,picture"
                cssClass="bg-transparent border-0 cursor-pointer p-0"
                scope="public_profile,pages_show_list,business_management"
                //redirectUri="https://pruebas.tmsolutions.com.co/auth/facebook"
                textButton={
                    <>
                        <div className="facebookButtonDesktop fl-font-family d-flex align-items-center text-white fw-bold fs-5 cursor-pointer" >
                            <span>+</span>
                            <img src={FacebookLogo} width={25} height={25} style={{marginBottom: '.1875em'}}/> 
                            <FormattedMessage id="my_profile_text_2" defaultMessage="Conecta tu Facebook" />
                        </div>
                        <div className="facebookButtonMobile d-none p-2 rounded-circle cursor-pointer">
                            <img src={facebookGrey} width={10} height={20} />
                            <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
                        </div>
                    </>
                }
                // autoLoad={false} 
                callback={responseFacebook}
                //onFailure={() => { setShowAlertError(true); }}
                cookiePolicy={'none'}
                response_type="token,userID"
                //cookie={false}
            />
            :
            <>
                <div className="facebookButtonDesktop fl-font-family d-flex align-items-center text-white fw-bold fs-5 cursor-pointer" onClick={() => setOpenFacebookModal(true)}>
                    <span>+</span>
                    <img src={FacebookLogo} width={25} height={25} style={{marginBottom: '.1875em'}}/> 
                    <FormattedMessage id="my_profile_text_2" defaultMessage="Conecta tu Facebook" />
                </div>
                <div className="facebookButtonMobile d-none p-2 rounded-circle cursor-pointer">
                    <img src={facebookGrey} width={10} height={20} onClick={() => setOpenFacebookModal(true)}/>
                    <img src={edit} className="position-absolute ms-4 mb-4" width={14} height={14} />
                </div>

                <FacebookProfileLoginModal open={openFacebookModal} setOpen={setOpenFacebookModal} setFacebookFollowers={setFacebookFollowers} setFacebookProfileLink={setFacebookProfileLink}></FacebookProfileLoginModal>
            </>
    )
}

export default FacebookProfileLogin