import { createContext, useEffect, useState } from "react";
import { CompanyAPI } from "../../api/user/company/company";

export const CompanyContext = createContext();

export default function CompanyProvider({ children }) {
    
    const [companyList, setCompanyList] = useState([]);
    const [companySearchList, setCompanySearchList] = useState([]);

    return (
        <CompanyContext.Provider value={{
            companyList,
            setCompanyList,
            companySearchList,
        }}>
            {children}
        </CompanyContext.Provider>
    );
}