import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LangProvider from './context/langContext';
import UserProvider from './context/userContext';
import UserProvider2 from './context/user/userContext'
import CartProvider from './context/cartContext';
import CollaborationsProvider from './context/collaborationsContext';
import FiltersProvider from './context/filtersContext';
import CurrencyProvider from './context/currencyContext';

import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from './store/store';
import ServiceProvider from './context/serviceContext';
import StaticDataProvider from './context/staticDataContext';
import InfluencerProvider from './context/user/influencerContext';
import CompanyProvider from './context/user/companyContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LangProvider>
        <StaticDataProvider>
          <UserProvider2>
            <InfluencerProvider>
              <CompanyProvider>
                  <CurrencyProvider>
                    <UserProvider>
                      <FiltersProvider>
                        <CartProvider>
                          <CollaborationsProvider>
                            <ServiceProvider>
                              <App />
                            </ServiceProvider>
                          </CollaborationsProvider>
                        </CartProvider>
                      </FiltersProvider>
                    </UserProvider>
                  </CurrencyProvider>
              </CompanyProvider>
            </InfluencerProvider>
          </UserProvider2>
        </StaticDataProvider>
      </LangProvider>
    </PersistGate>
  </Provider >
);


